import {Injectable} from '@angular/core';
import { Component, Inject} from '@angular/core';


  
@Component({
    selector: 'app-root',
    templateUrl:  "app.component.html",
  
})
export class AppComponent {

}
