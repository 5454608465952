import { Injectable } from '@angular/core';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { LocalStorageService } from '../../service/localStorage.service';
import { DateService } from '../../service/date.service';
//import {AccordionModule} from '@ng-bootstrap/ng-bootstrap';
import {
  NgbAccordion,
  NgbAccordionConfig,
  NgbAccordionModule,
} from '@ng-bootstrap/ng-bootstrap';
import { QuestionModel } from '../../model/questionModel.model';
import { AnswerModel } from '../../model/answerModel.model';
import { UserIdentityModel } from '../../model/userIdentityModel.model';

import { DishRepository } from '../../repository/dish.repository';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { environment } from './../../../environments/environment';
import { map } from 'rxjs/operators';

@Component({
  selector: "dialog-root",
  templateUrl: 'dialog.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['dialog.component.css'],
})
export class DialogComponent {
  public accordionHeaderFlag1: boolean;
  public accordionHeaderFlag2: boolean;
  public accordionHeaderFlag3: boolean;
  public accordionHeaderFlag4: boolean;
  //private messages:string [];
  public message: string;
  public questionModel: QuestionModel;
  public idMessage: number;
  public delay: number;
  public imgArrowSrcUp: string;
  public imgArrowSrcDown: string;
  public imgArrowSrc1: string;
  public imgArrowSrc2: string;
  public imgArrowSrc3: string;
  public imgArrowSrc4: string;
  public userQuestionArray: QuestionModel[] = [];
  public currentUserQuestion: QuestionModel;
  public lastAnsweredUserQuestion: QuestionModel;
  public lastFavouriteQuestion: QuestionModel;
  public prevLastFavouriteQuestion: QuestionModel;

  public tempQuestion: QuestionModel;
  public currentUserAnswer: AnswerModel;
  public currentUserAnswerArray: AnswerModel[];
  public userIdentity: UserIdentityModel;
  public inAnswerAbsent: string;
  private currentUserId: string;
  public questionAnswersArr: any;
  public accordionHeaderFlagArr: boolean[] = [];
  constructor(
    private localStorageService: LocalStorageService,
    private dishRepository: DishRepository,
    private modalService: NgbModal
  ) {
    this.localStorageService.set('currentChildRoute', 'dialog');
  }

  ngOnInit() {
    this.currentUserId = this.localStorageService.get('savedUserId');
    console.log('[ngOnInit] this.currentUserId', this.currentUserId);

    this.userIdentity = new UserIdentityModel(0);
    this.currentUserAnswer = new AnswerModel(
      0,
      'For at ændre ordren skal du still dig på den leveringsdag som du gerne vil ændre ordren for. Her kan du trykke på ´+´ og ´-´ for at ændre ordren. Bemærk at du ikke kan ændre ordre hvis der er færre end 4 dage til leveringsdagen.'
    );
    this.currentUserAnswerArray = new Array(this.currentUserAnswer);
    this.currentUserQuestion = new QuestionModel(
      0,
      'Hvordan kan jeg ændre min ordre?',
      false,
      new Date(),
      this.userIdentity,
      this.currentUserAnswerArray
    );
    this.inAnswerAbsent = 'Tak for spørgsmålet. Vi svarer snart';
    this.lastAnsweredUserQuestion = new QuestionModel(
      0,
      'Hvordan kan jeg ændre min ordre?',
      false,
      new Date(),
      this.userIdentity,
      this.currentUserAnswerArray
    );
    this.lastFavouriteQuestion = new QuestionModel(
      0,
      'Hvordan kan jeg ændre min ordre?',
      false,
      new Date(),
      this.userIdentity,
      this.currentUserAnswerArray
    );
    this.prevLastFavouriteQuestion = new QuestionModel(
      0,
      'Hvordan kan jeg ændre min ordre?',
      false,
      new Date(),
      this.userIdentity,
      this.currentUserAnswerArray
    );

    this.getAllQuestionsFromOnlineSupport(); // get All Questions From OnlineSupport

    //this.getAnswerFromOnlineSupportById("1118")
    this.delay = 3000;
    this.accordionHeaderFlag1 = true;
    this.accordionHeaderFlag2 = false;
    this.accordionHeaderFlag3 = true;
    this.accordionHeaderFlag4 = false;

    //this.messages = [];
    this.message = '';
    this.idMessage = 0;
    this.imgArrowSrcUp = '../../../assets/img/Line2.png';
    this.imgArrowSrcDown = '../../../assets/img/Line2.3.png';
    this.imgArrowSrc1 = this.imgArrowSrcUp;
    this.imgArrowSrc2 = this.imgArrowSrcDown;
    this.imgArrowSrc3 = this.imgArrowSrcDown;
    this.imgArrowSrc4 = this.imgArrowSrcDown;

    if (!environment.debugMode) {
      console.clear();
    }
    window.scrollTo(0, 0);
  }

  ngAfterContentChecked() {
    if (!environment.debugMode) {
      console.clear();
    }
  }

  getMessage(): string {
    return this.message;
  }

  sendMessage(content) {
    this.questionModel = new QuestionModel(
      this.getMessageId(),
      this.getMessage()
    );
    console.log('this.questionModel', JSON.stringify(this.questionModel));
    this.dishRepository
      .sendMessage(this.questionModel)
      /*.pipe(
	  		map((response: any) => response)
	  		)*/
      //.map(response =>response.json())
      .subscribe(
        (data: any) => {
          this.message = '';
          console.log('data', data.id);

          //this.getAnswerFromOnlineSupportById(data.id)
          this.getAllQuestionsFromOnlineSupport();
          console.log('The mesaage is sent');
          this.showModalPopup(content);
        },
        (error) => {
          console.log('The message is not sent');
        }
      );
  }

  getMessageId() {
    return ++this.idMessage;
  }

  /* getAllQuestionsFromOnlineSupport(){

	  	this.dishRepository.getAllQuestionsFromOnlineSupport()
	  	.subscribe( (response) => {
	  		this.userQuestionArray = response;
	  		console.log("[getAllQuestionsFromOnlineSupport] this.userQuestionArray", this.userQuestionArray);
	  		//this.userQuestionArray = this.sortByDate(this.userQuestionArray);
	  		this.userQuestionArray = this.filterByUserId(JSON.parse(JSON.stringify(this.userQuestionArray)), this.currentUserId)
	  		console.log("[getAllQuestionsFromOnlineSupport] this.userQuestionArray", this.userQuestionArray);
			this.getAnswerFromOnlineSupportById(this.userQuestionArray[0]["id"])
			.subscribe( (response) => {
		  		;
		  		//console.log("response", JSON.stringify(response));
		  		this.currentUserQuestion = response;
		  		console.log("this.currentUserQuestion", this.currentUserQuestion);
	  			},
		        (error)=> {
		        console.log("The  getAllQuestionsFromOnlineSupport is not successfull");

		     	});

	  		//console.log("this.currentUserQuestion", this.currentUserQuestion);

       		 this.getAnswerFromOnlineSupportById(this.userQuestionArray[1]["id"])
	  		.subscribe( (response) => {
		  		//console.log("response", JSON.stringify(response));
		  		this.lastAnsweredUserQuestion = response;
		  		console.log("this.lastAnsweredUserQuestion", this.lastAnsweredUserQuestion);
	  			},
		        (error)=> {
		        console.log("The  getAllQuestionsFromOnlineSupport is not successfull");

		     	});
	  		//console.log("this.lastAnsweredUserQuestion", this.lastAnsweredUserQuestion);

        	let lastQuestionId = this.filterLastFavouriteQuestion(this.userQuestionArray);
        	if(lastQuestionId != 0){
        		this.getAnswerFromOnlineSupportById(lastQuestionId)
				.subscribe( (response) => {
			  		;
			  		//console.log("response", JSON.stringify(response));
			  		this.lastFavouriteQuestion = response;
			  		console.log("this.lastFavouriteQuestion", this.lastFavouriteQuestion);
		  			},
			        (error)=> {
			        console.log("The  getAllQuestionsFromOnlineSupport is not successfull");

			     	});

        	}


        	let prevLastQuestionId = this.filterPrevLastFavouriteQuestion(this.userQuestionArray);
        	if(prevLastQuestionId != 0){
        		this.getAnswerFromOnlineSupportById(prevLastQuestionId)
				.subscribe( (response) => {
			  		;
			  		//console.log("response", JSON.stringify(response));
			  		this.prevLastFavouriteQuestion = response;
			  		console.log("this.prevLastFavouriteQuestion", this.prevLastFavouriteQuestion);
		  			},
			        (error)=> {
			        console.log("The  getAllQuestionsFromOnlineSupport is not successfull");

			     	});
        	}

        },
        (error)=> {
        console.log("The  getAllQuestionsFromOnlineSupport is not successfull")}
        );

	  }	*/

  getAllQuestionsFromOnlineSupport() {
    this.dishRepository.getAllQuestionsFromOnlineSupport().subscribe(
      (response) => {
        this.userQuestionArray = response;
        console.log(
          '[getAllQuestionsFromOnlineSupport] this.userQuestionArray',
          this.userQuestionArray
        );
        //this.userQuestionArray = this.sortByDate(this.userQuestionArray);
        this.userQuestionArray = this.filterByUserId(
          JSON.parse(JSON.stringify(this.userQuestionArray)),
          this.currentUserId
        );
        console.log(
          '[getAllQuestionsFromOnlineSupport] this.userQuestionArray',
          this.userQuestionArray
        );
        this.getAllAnswerForCurrentUser(this.userQuestionArray).subscribe(
          (response) => {
            console.log(
              '[getAllQuestionsFromOnlineSupport] response',
              response
            );
            //this.currentUserQuestion = response;
            //console.log("this.currentUserQuestion", this.currentUserQuestion);
            this.questionAnswersArr = response;
            this.questionAnswersArr = this.sortByDate(this.questionAnswersArr);
            console.log(
              '[getAllQuestionsFromOnlineSupport] this.questionAnswersArr',
              this.questionAnswersArr
            );
            this.accordionHeaderFlagArr = new Array(
              this.questionAnswersArr.length
            );
            this.accordionHeaderFlagArr.fill(false);
            //this.accordionHeaderFlagArr[0]=true;
            console.log(
              '[getAllQuestionsFromOnlineSupport] this.accordionHeaderFlagArr',
              this.accordionHeaderFlagArr
            );
          },
          (error) => {
            console.log(
              'The  getAllQuestionsFromOnlineSupport is not successfull'
            );
          }
        );

        let lastQuestionId = this.filterLastFavouriteQuestion(
          this.userQuestionArray
        );
        if (lastQuestionId != 0) {
          this.getAnswerFromOnlineSupportById(lastQuestionId).subscribe(
            (response) => {
              //console.log("response", JSON.stringify(response));
              this.lastFavouriteQuestion = response;
              console.log(
                'this.lastFavouriteQuestion',
                this.lastFavouriteQuestion
              );
            },
            (error) => {
              console.log(
                'The  getAllQuestionsFromOnlineSupport is not successfull'
              );
            }
          );
        }

        let prevLastQuestionId = this.filterPrevLastFavouriteQuestion(
          this.userQuestionArray
        );
        if (prevLastQuestionId != 0) {
          this.getAnswerFromOnlineSupportById(prevLastQuestionId).subscribe(
            (response) => {
              //console.log("response", JSON.stringify(response));
              this.prevLastFavouriteQuestion = response;
              console.log(
                'this.prevLastFavouriteQuestion',
                this.prevLastFavouriteQuestion
              );
            },
            (error) => {
              console.log(
                'The  getAllQuestionsFromOnlineSupport is not successfull'
              );
            }
          );
        }
      },
      (error) => {
        console.log('The  getAllQuestionsFromOnlineSupport is not successfull');
      }
    );
  }

  getAnswerFromOnlineSupportById(questionId: number) {
    return this.dishRepository.getAnswerFromOnlineSupportById(questionId);
  }

  /*  filterLastUserQuestion(userQuestionArray: QuestionModel[]):QuestionModel {

	  	return userQuestionArray[0];	//  return last	question
	  }

	   filterLastAnsweredUserQuestionArray(userQuestionArray: QuestionModel[]):QuestionModel {
	  	for (let qa of userQuestionArray){
	  		//console.log("qa", JSON.stringify(qa));

	  		if(qa["isAnswered"] == true){			//if we have answered question
	  			console.log("qa", qa);
	  			return qa;							// return last answered question

	  		}
	  	}

	  	//console.log("userQuestionArray[1]", userQuestionArray[1]);
	  	return userQuestionArray[1];	//if no answered question return prelast
	  }
	*/

  filterLastFavouriteQuestion(userQuestionArray: QuestionModel[]): number {
    for (let qa of userQuestionArray) {
      //console.log("qa", JSON.stringify(qa));

      if (qa['isFavourite'] == true) {
        //if we have favourite question
        console.log('qa', qa);
        return qa.id; // return last answered question
      }
    }

    //console.log("userQuestionArray[1]", userQuestionArray[1]);
    //return userQuestionArray[0]["id"];	//if no favourite question return last
    return 0;
  }

  filterPrevLastFavouriteQuestion(userQuestionArray: QuestionModel[]): number {
    let previosQuestion = true;
    for (let qa of userQuestionArray) {
      //console.log("qa", JSON.stringify(qa));

      if (qa['isFavourite'] == true && previosQuestion) {
        //if we have favourite question

        previosQuestion = false;
        continue; // return last answered question
      } else if (qa['isFavourite'] == true && !previosQuestion) {
        console.log('qa', qa);
        return qa.id;
      }
    }

    //return userQuestionArray[1]["id"];	//if no favourite question return last
    return 0;
  }
  isExpanded(a: number) {
    console.log('[isExpanded]', a);

    if (a == 0) {
      console.log('[isExpanded]', true);
      return true;
    } else {
      console.log('[isExpanded]', false);
      return false;
    }
  }

  toggleaccordionHeader(a: number) {
    let prevFlagState = this.accordionHeaderFlagArr[a];
    for (let el in this.accordionHeaderFlagArr) {
      this.accordionHeaderFlagArr[el] = false;
    }
    //this.accordionHeaderFlagArr.forEach(function(x, i, a)­{­ a[i]=false;­ });
    this.accordionHeaderFlagArr[a] = !prevFlagState;

    console.log(
      '[toggleaccordionHeader] this.accordionHeaderFlagArr',
      this.accordionHeaderFlagArr
    );
  }

  toggleaccordionHeader1() {
    this.accordionHeaderFlag1 = !this.accordionHeaderFlag1;

    if (this.accordionHeaderFlag1) {
      this.imgArrowSrc1 = this.imgArrowSrcUp;
      this.imgArrowSrc2 = this.imgArrowSrcDown;
      /*this.imgArrowSrc3 = this.imgArrowSrcDown;
		this.imgArrowSrc4 = this.imgArrowSrcDown;*/

      this.accordionHeaderFlag2 = false;
    } else {
      this.imgArrowSrc1 = this.imgArrowSrcDown;
    }
  }
  toggleaccordionHeader2() {
    this.accordionHeaderFlag2 = !this.accordionHeaderFlag2;
    if (this.accordionHeaderFlag2) {
      this.imgArrowSrc1 = this.imgArrowSrcDown;
      this.imgArrowSrc2 = this.imgArrowSrcUp;
      /*this.imgArrowSrc3 = this.imgArrowSrcDown;
			this.imgArrowSrc4 = this.imgArrowSrcDown;*/
      this.accordionHeaderFlag1 = false;
    } else {
      this.imgArrowSrc2 = this.imgArrowSrcDown;
    }
  }
  toggleaccordionHeader3() {
    this.accordionHeaderFlag3 = !this.accordionHeaderFlag3;
    if (this.accordionHeaderFlag3) {
      /*this.imgArrowSrc1 = this.imgArrowSrcDown;
			this.imgArrowSrc2 = this.imgArrowSrcUp;*/
      this.imgArrowSrc3 = this.imgArrowSrcUp;
      this.imgArrowSrc4 = this.imgArrowSrcDown;
      this.accordionHeaderFlag4 = false;
    } else {
      this.imgArrowSrc3 = this.imgArrowSrcDown;
    }
  }
  toggleaccordionHeader4() {
    this.accordionHeaderFlag4 = !this.accordionHeaderFlag4;
    if (this.accordionHeaderFlag4) {
      /*this.imgArrowSrc1 = this.imgArrowSrcDown;
			this.imgArrowSrc2 = this.imgArrowSrcUp;*/
      this.imgArrowSrc3 = this.imgArrowSrcDown;
      this.imgArrowSrc4 = this.imgArrowSrcUp;
      this.accordionHeaderFlag3 = false;
    } else {
      this.imgArrowSrc4 = this.imgArrowSrcDown;
    }
  }

  showModalPopup(content) {
    //this.openVerticallyCentered(content);
    this.openWindowCustomClass(content);
    this.setTimer(this.delay).subscribe((update) => {
      console.log('time for modal is expired');
      this.modalService.dismissAll();
    });
  }

  setTimer(interval: number): Observable<number> {
    return timer(interval);
  }

  //-------------- Modal PopUp methods -----------------------

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  openWindowCustomClass(content) {
    this.modalService.open(content, {
      centered: true,
      windowClass: 'darkModalDialog',
    });
  }

  sortByDate(userQuestionArray: any) {
    return userQuestionArray.sort(this.compareQuestionsByDate);
  }

  //--------------------------Compare Func------------------
  compareQuestionsByDate(a, b) {
    if (a['createdOnUtc'] > b['createdOnUtc']) return -1;
    if (a['createdOnUtc'] == b['createdOnUtc']) return 0;
    if (a['createdOnUtc'] < b['createdOnUtc']) return 1;
  }

  filterByUserId(userQuestionArray: any, currentUserId: string) {
    console.log('[filterByUserId] currentUserId', currentUserId);
    let filteredUserQuestionArray: any = [];
    filteredUserQuestionArray = userQuestionArray.filter(function (el) {
      return el.createdBy.id == currentUserId;
    });

    return filteredUserQuestionArray;
  }
  // -----------------Get All Answers for User-----------------
  getAllAnswerForCurrentUser(userQuestionArray: any) {
    return this.dishRepository.getAllAnswerForCurrentUser(userQuestionArray);
  }
}
