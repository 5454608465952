import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocaleDaDKService {
  constructor() {}

  translateMonthToDanish(month: string) {
    let monthInDanish = '';
    switch (month) {
      case 'January':
        monthInDanish = 'Januar';
        break;
      case 'February':
        monthInDanish = 'Februar';
        break;
      case 'March':
        monthInDanish = 'Marts';

        break;
      case 'April':
        monthInDanish = 'April';
        break;
      case 'May':
        monthInDanish = 'Maj';

        break;
      case 'June':
        monthInDanish = 'Juni';
        break;
      case 'July':
        monthInDanish = 'Juli';
        break;
      case 'August':
        monthInDanish = 'August';
        break;
      case 'September':
        monthInDanish = 'September';
        break;
      case 'October':
        monthInDanish = 'Oktober';
        break;
      case 'November':
        monthInDanish = 'November';
        break;
      case 'December':
        monthInDanish = 'December';
        break;

      default: {
      }
    }
    return monthInDanish;
  }

  translateDayToDanishsShort(day: string) {
    let dayInDanish = '';
    switch (day) {
      case 'Tuesday':
        dayInDanish = 'Tirs.';
        break;
      case 'Wednesday':
        dayInDanish = 'Ons.';
        break;
      case 'Thursday':
        dayInDanish = 'Tors.';

        break;
      case 'Friday':
        dayInDanish = 'Fre.';
        break;
      case 'Saturday':
        dayInDanish = 'Lør.';

        break;
      case 'Sunday':
        dayInDanish = 'Søn.';
        break;
      case 'Monday':
        dayInDanish = 'Man.';
        break;
      default:
        dayInDanish = 'Tirs.';
    }
    return dayInDanish;
  }

  translateDayToDanishsFull(day: string) {
    let dayInDanish = '';
    switch (day) {
      case 'Tuesday':
        dayInDanish = 'Tirsdag';
        break;
      case 'Wednesday':
        dayInDanish = 'Onsdag';
        break;
      case 'Thursday':
        dayInDanish = 'Torsdag';

        break;
      case 'Friday':
        dayInDanish = 'Fredag';
        break;
      case 'Saturday':
        dayInDanish = 'Lørdag';

        break;
      case 'Sunday':
        dayInDanish = 'Søndag';
        break;
      case 'Monday':
        dayInDanish = 'Mandag';
        break;
      default:
        dayInDanish = 'Tirsdag';
    }
    return dayInDanish;
  }
}
