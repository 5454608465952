import { Injectable } from '@angular/core';
import { Component, Inject, EventEmitter, Input, Output } from '@angular/core';
import { LocalStorageService } from '../service/localStorage.service';
import { DataShareService } from '../service/dataShare.service';
import { Observable } from 'rxjs/Observable';
import { fromEvent } from 'rxjs';

@Component({
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.css'],
})
export class MenuComponent {
  admUserName: string;

  admUserNameMenu: string;
  private showUserName: boolean;
  public obsrv$: Observable<any>;
  constructor(
    private localStorageService: LocalStorageService,
    private dataShareService: DataShareService
  ) {
    this.admUserNameMenu = this.localStorageService.get('savedUsername');
    this.localStorageService.set('currentChildRoute', 'menu');

    this.addEventListeners();
  }

  ngAfterViewInit() {}

  clickMenuImg(itemMenu: string) {
    this.dataShareService.menuLoaded = true;
    console.log(
      'this.dataShareService.menuLoaded',
      this.dataShareService.menuLoaded
    );

    return this.dataShareService.sendFromEvent(this.obsrv$);
  }

  addEventListeners() {
    var elements = document.getElementsByClassName('containerMain');
    this.obsrv$ = fromEvent(elements, 'click');
  }
}
