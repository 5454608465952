import { TopMessage } from './top-message.model';

export class TopMessageTitleText extends TopMessage {

  constructor(
      public title: string,
      public text: string,
      public titleClass: string ='',
      public textClass: string = ''
  ) {
      super(
        title,
        titleClass,
        text,
        textClass
      )
    }

}
