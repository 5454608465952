export class MenuLine {
    constructor(
	            public	productNr:number,
				public	productName:string,
				public	line:number,
				public	dietTypeNr:number,
				public	dietTypeName:string,
				public	portionSize:number,
				public	quantity:number,
				public  enkeltPortion:boolean	
           	
           	) { }
}