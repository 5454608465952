import { NgModule } from "@angular/core";
import { Cart } from "./cart.model";
import { HttpModule } from "@angular/http";


@NgModule({
  
    imports: [HttpModule],
    providers: [Cart ]
 /*   providers: [ProductRepository, Cart, Order, OrderRepository,
        { provide: StaticDataSource, useClass: RestLocalDataSource }]
*/
  /*  providers: [ProductRepository, Cart, Order, OrderRepository,
    { provide: StaticDataSource, useClass: RestDataSource }]
*/
})
export class ModelModule { }
