import { Injectable } from '@angular/core';
import { Component, Inject } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  ActivationEnd,
} from '@angular/router';
import { LocalStorageService } from '../../service/localStorage.service';
import { Cart } from '../../cart/cart-model/cart.model';
import { DateService } from '../../service/date.service';
import { AllocatedDishModel } from '../../model/allocatedDishModel.model';
import { OrderDish } from '../../model/orderDish.model';
import { DishAllocationWeekDataBase } from '../../model/dishAllocationWeek.database';
import { DishRepository } from '../../repository/dish.repository';
import { RestDataSource } from '../../repository/rest.datasource';
import { ModelResolver } from '../../resolver/model.resolver';
import { timer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { of, from } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/concatAll';
import 'rxjs/add/operator/concatMap';
import { map } from 'rxjs/operators';
import { _ } from 'underscore';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { SafePipeModule } from 'safe-pipe';
import { AuthService } from '../../service/auth.service';
import { LocaleDaDKService } from '../../service/locale-da-dk.service';
import $ from 'jquery';
import { environment } from './../../../environments/environment';
import { DishAllocationsOrderDays } from '../../model/dishAllocationsOrderDays.model';
import { SimpleOderModel } from '../../model/simpleOder.model';
import { TopMessageTitleText } from 'src/app/model/top-message-title-text.model';

class DataControl {
  deadlineStatus: boolean;
  menuName: string;
  menuNr: number;
  orderNr: number;
  orderOperation: number;
  orderPermitStatus: number;
  customerNr: number;

  constructor(
    deadlineStatus: boolean,
    menuName: string,
    menuNr: number,
    orderNr: number,
    orderOperation: number,
    orderPermitStatus: number,
    customerNr: number
  ) {
    this.deadlineStatus = deadlineStatus;
    this.menuName = menuName;
    this.menuNr = menuNr;
    this.orderNr = orderNr;
    this.orderOperation = orderOperation;
    this.orderPermitStatus = orderPermitStatus;
    this.customerNr = customerNr;
  }
}

class DinamicCategoriesDays {
  Sunday: string[];
  Monday: string[];
  Tuesday: string[];
  Wednesday: string[];
  Thursday: string[];
  Friday: string[];
  Saturday: string[];

  constructor() {
    this.Sunday = [];
    this.Monday = [];
    this.Tuesday = [];
    this.Wednesday = [];
    this.Thursday = [];
    this.Friday = [];
    this.Saturday = [];
  }
}

@Component({
  selector: "extra-root",
  templateUrl: 'extra.component.html',
  styleUrls: ['extra.component.css'],
})
export class ExtraComponent {
  public sumOder: number;

  public weeks: string[];
  public dishAllocationsWeekResponse: any[] = [];
  public currentWeek: string;
  public lastIndex: number;
  obsSubscription: Subscription;
  public numberElementsInRow: number;
  public arrayDishesViewForDay: any[];
  public orderDishId: number;
  public orderDaysLeftInWeekArray: string[];
  public dishAllocationsOrderDays: DishAllocationsOrderDays;
  public dishAllocationsOrderDaysAll: DishAllocationsOrderDays;
  public currentToogledDay: string;
  public toggledMonday: boolean;
  public toggledWednesday: boolean;
  public toggledFriday: boolean;
  public toggledTuesday: boolean;
  public toggledThursday: boolean;
  public toggledSaturday: boolean;
  public SundayDate: string;
  public MondayDate: string;
  public TuesdayDate: string;
  public WednesdayDate: string;
  public ThursdayDate: string;
  public FridayDate: string;
  public SaturdayDate: string;
  public shiftWeekNumber: string;
  public shiftWeek: number;
  public needNgOninit: boolean;
  public simpleOderModelArray: SimpleOderModel[];
  public imgUrl: string;
  public tempArray: any[] = [];
  public categoryForFilter: any;
  public simpleOderModelArrayId: string;
  public orderingDates: any[];
  public userInfoOrderingModel: any[] = [];
  public currentDayNumber: number;
  public filterOn: boolean;
  public isOrderingTimeFlag: boolean;
  public toogledAllFlag: boolean;
  public toogledPastriesFlag: boolean;
  public toogledCakesFlag: boolean;
  public toogledCookiesFlag: boolean;
  public toogledFlanFlag: boolean;
  public toogledColonialFlag: boolean;
  public toogledChilledFlag: boolean;
  public toogledProteinDrinkFlag: boolean;

  public toogledKageFlag: boolean;
  public toogledBasserFlag: boolean;
  public toogledBollerFlag: boolean;
  public toogledTilvalgFlag: boolean;
  public toogledDognFlag: boolean;
  public toogledKageBrodFlag: boolean;
  public toogledFodselsdagsmenuFlag: boolean;
  public toogledGastekokFlag: boolean;
  public toogledJulemenuNytarsmenuFlag: boolean;

  public isEmptyMonday: boolean;
  public isEmptyTuesday: boolean;
  public isEmptyWednesday: boolean;
  public isEmptyThursday: boolean;
  public isEmptyFriday: boolean;
  public isEmptySaturday: boolean;
  public isEmptySunday: boolean;
  closeResult: string;
  private departmentId: number;
  public btnAllBackgroundColor: string;
  public btnAllTextColor: string;
  public btnAllTextWeight: string;
  private modalBtnFlag: string;
  public safeSrc: SafeResourceUrl;
  public modalBtnBackgroundColor: string;
  public modalBtnTextColor: string;
  public modalBtnTextWeight: string;
  private needUnsubscribe: boolean;
  private customerNr: number;
  public orderNotPermitForDay: boolean = true;
  public orderNotPermitForSubCategory: boolean = true;
  private mapDataControl: any;
  public currentToogledSubCategory: string;
  public currentRangeForBtn: any;
  public templateDays: string[];
  public isQuantitiesChanged: boolean = false;
  public disabledAfterOrder: boolean;
  public currentTabDay: string;
  public currentServingDay: string;
  public dinamicCategoriesDays: DinamicCategoriesDays;
  public currentDishSalePrice: number;
  public currencyValue: string;
  public priceGroup: number;
  public topMessageTitleText: TopMessageTitleText;

  constructor(
    private dataSource: RestDataSource,
    private localStorageService: LocalStorageService,
    private cart: Cart,
    private router: Router,
    private dateService: DateService,
    private dishRepository: DishRepository,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private auth: AuthService,
    private sanitizer: DomSanitizer,
    private localeDaDKService: LocaleDaDKService
  ) {
    this.localStorageService.set('currentChildRoute', 'extra');
    this.localStorageService.set('parentCartRoute', 'extra');

    this.departmentId = this.localStorageService.get('currentdepartmentId');
    this.needUnsubscribe = false;
    this.dishAllocationsOrderDays = new DishAllocationsOrderDays();

    this.dishAllocationsWeekResponse = [];
  }

  ngOnInit(): void {
    this.needNgOninit = false;
    this.currencyValue = environment.currency;
    //NavTabs init

    const toggledDay = this.localStorageService.get('toggledDayExtra');
    this.currentTabDay = toggledDay;
    switch (toggledDay) {
      case 'Tuesday':
        this.toggledTuesday = true;
        this.toggledThursday = false;
        this.toggledSaturday = false;
        this.templateDays = ['Tuesday'];
        this.currentServingDay = 'Tuesday';
        break;
      case 'Thursday':
        this.toggledTuesday = false;
        this.toggledThursday = true;
        this.toggledSaturday = false;
        this.templateDays = ['Thursday'];
        this.currentServingDay = 'Thursday';

        break;
      case 'Saturday':
        this.toggledTuesday = false;
        this.toggledThursday = false;
        this.toggledSaturday = true;
        this.templateDays = ['Sunday'];
        this.currentServingDay = 'Sunday';

        break;
      default:
        this.localStorageService.set('toggledDayExtra', 'Tuesday');
        this.toggledTuesday = true;
        this.toggledThursday = false;
        this.toggledSaturday = false;
        this.templateDays = ['Tuesday'];
        this.currentServingDay = 'Tuesday';
    }

    console.log('[ngOnInit] this.currentServingDay', this.currentServingDay);
    //this.sumOder = this.localStorageService.get('sumOder');

    //------------------- Nav Subcategory ---------------------------------

    this.toogledAllFlag = true;
    this.toogledPastriesFlag = false;
    this.toogledCakesFlag = false;
    this.toogledCookiesFlag = false;
    this.toogledFlanFlag = false;
    this.toogledColonialFlag = false;
    this.toogledChilledFlag = false;
    this.toogledProteinDrinkFlag = false;

    this.toogledKageFlag = false;
    this.toogledBasserFlag = false;
    this.toogledBollerFlag = false;

    //---------------------Date------------------------------------

    this.shiftWeek = this.localStorageService.get(
      'shiftWeek' + this.localStorageService.get('currentChildRoute')
    );

    this.currentDayNumber = this.dateService.getCurrentDayNumber();

    this.currentWeek = this.dateService.getCurrentWeek();

    this.shiftWeekNumber = this.dateService.getShiftWeek(this.shiftWeek);

    this.SundayDate = this.dateService.getISODateDDMMMforDay(
      'Sunday',
      this.shiftWeek
    );
    this.MondayDate = this.dateService.getISODateDDMMMforDay(
      'Monday',
      this.shiftWeek
    );
    this.TuesdayDate = this.dateService.getISODateDDMMMforDay(
      'Tuesday',
      this.shiftWeek
    );
    this.WednesdayDate = this.dateService.getISODateDDMMMforDay(
      'Wednesday',
      this.shiftWeek
    );
    this.ThursdayDate = this.dateService.getISODateDDMMMforDay(
      'Thursday',
      this.shiftWeek
    );
    this.FridayDate = this.dateService.getISODateDDMMMforDay(
      'Friday',
      this.shiftWeek
    );
    this.SaturdayDate = this.dateService.getISODateDDMMMforDay(
      'Saturday',
      this.shiftWeek
    );

    //--------------------- GET dishAllocations for All days in Week-----------------------------
    if (!this.needUnsubscribe) {
      this.obsSubscription = this.activatedRoute.data.subscribe((data) => {
        this.dishAllocationsWeekResponse = [];
        this.dishAllocationsOrderDays = new DishAllocationsOrderDays();
        this.mapDataControl = new Map();
        this.dinamicCategoriesDays = new DinamicCategoriesDays();
        this.dishAllocationsWeekResponse = data['dishes'];

        this.sumOder = 0;

        console.debug(
          'dishAllocationsWeekResponse=' +
            JSON.stringify(this.dishAllocationsWeekResponse)
        );

        this.departmentId = this.localStorageService.get('currentdepartmentId');
        if (
          this.dishAllocationsWeekResponse[0] &&
          this.dishAllocationsWeekResponse[0]['priceGroup']
        ) {
          this.priceGroup = this.dishAllocationsWeekResponse[0]['priceGroup'];
        }

        this.categoryForFilter = 'Ekstra';
        const toggledDay1 = this.localStorageService.get('toggledDayExtra');
        switch (toggledDay1) {
          case 'Tuesday':
            {
              //if(this.dishAllocationsWeekResponse[0]!==undefined && this.dishAllocationsWeekResponse[0]["menus"][0]!==undefined){
              if (
                this.dishAllocationsWeekResponse[0] &&
                this.dishAllocationsWeekResponse[0]['menus'][0] !== undefined
              ) {
                this.isEmptyTuesday = false;
                //this.dishAllocationsOrderDays["Tuesday"]=(this.categoryFilter(0,this.dishAllocationsWeekResponse, this.categoryForFilter));

                //this.dishAllocationsOrderDays["Tuesday"] = this.formDataController(this.dishAllocationsWeekResponse);
                this.dishAllocationsOrderDays[
                  'Tuesday'
                ] = this.dishAllocationsWeekResponse[0]['menus'][0];

                //this.dishAllocationsOrderDays["Tuesday"]["menuLines"]=JSON.parse(JSON.stringify(this.filterResponseByUndefined(this.dishAllocationsWeekResponse[0]["menus"][0]["menuLines"])));
                const itemMenus = this.formDataController(
                  this.dishAllocationsWeekResponse
                );

                console.log('[ngOnInit] itemMenus', itemMenus);

                this.dinamicCategoriesDays['Tuesday'] = this.getDinamicCatArr(
                  this.dishAllocationsWeekResponse[0]['menus']
                );

                this.dishAllocationsOrderDays['Tuesday'][
                  'menuLines'
                ] = JSON.parse(
                  JSON.stringify(
                    this.filterResponseByUndefined(itemMenus['menuLines'])
                  )
                );

                this.customerNr = this.dishAllocationsWeekResponse[0][
                  'customerNr'
                ];
                this.orderNotPermitForDay = this.checkPermittionsForDay(
                  this.dishAllocationsWeekResponse
                );
                //this.orderNotPermit = false;
                this.cart.cartOrderNotPermit = this.orderNotPermitForDay;
                console.log(
                  '[ngOnInit] this.orderNotPermitForDay',
                  this.orderNotPermitForDay
                );
                console.debug(
                  'this.dishAllocationsOrderDays[Tuesday]1',
                  JSON.stringify(this.dishAllocationsOrderDays['Tuesday'])
                );
                //-----------------------------------------Order----------------------------------------------------------------------------------
                this.simpleOderModelArrayId =
                  'simpleOderModelArray' +
                  this.localStorageService.get('currentChildRoute') +
                  this.shiftWeekNumber +
                  this.departmentId;
                this.simpleOderModelArray = this.localStorageService.get(
                  this.simpleOderModelArrayId
                );
                if (!this.simpleOderModelArray) {
                  this.simpleOderModelArray = new Array();
                  this.sumOder = 0;
                }

                if (_.isEmpty(this.dishAllocationsOrderDays['Tuesday'])) {
                  console.debug(
                    'this.dishAllocationsOrderDays[\'Tuesday\'].length==0'
                  );
                  this.isEmptyTuesday = true;
                }
              } else {
                this.isEmptyTuesday = true;
                console.warn('this.isEmptyTuesday', this.isEmptyTuesday);
              }
              if (
                this.dishAllocationsWeekResponse[1] &&
                this.dishAllocationsWeekResponse[1]['menus'][0] !== undefined
              ) {
                this.isEmptyWednesday = true;
                this.dishAllocationsOrderDays[
                  'Wednesday'
                ] = this.dishAllocationsWeekResponse[1]['menus'][0];
                const itemMenus = this.formDataController(
                  this.dishAllocationsWeekResponse
                );

                console.log('[ngOnInit] itemMenus', itemMenus);

                this.dinamicCategoriesDays['Wednesday'] = this.getDinamicCatArr(
                  this.dishAllocationsWeekResponse[1]['menus']
                );

                this.dishAllocationsOrderDays['Wednesday'][
                  'menuLines'
                ] = JSON.parse(
                  JSON.stringify(
                    this.filterResponseByUndefined(itemMenus['menuLines'])
                  )
                );

                if (_.isEmpty(this.dishAllocationsOrderDays['Wednesday'])) {
                  console.debug(
                    'this.dishAllocationsOrderDays[\'Wednesday\'].length==0'
                  );
                  this.isEmptyWednesday = true;
                }
              } else {
                this.isEmptyWednesday = true;
                console.warn('this.isEmptyWednesday', this.isEmptyWednesday);
              }
            }

            break;
          case 'Thursday': {
            if (
              this.dishAllocationsWeekResponse[0] &&
              this.dishAllocationsWeekResponse[0]['menus'][0] !== undefined
            ) {
              this.isEmptyThursday = false;
              this.dishAllocationsOrderDays[
                'Thursday'
              ] = this.dishAllocationsWeekResponse[0]['menus'][0];
              const itemMenus = this.formDataController(
                this.dishAllocationsWeekResponse
              );

              console.log('[ngOnInit] itemMenus', itemMenus);

              this.dinamicCategoriesDays['Thursday'] = this.getDinamicCatArr(
                this.dishAllocationsWeekResponse[0]['menus']
              );

              this.dishAllocationsOrderDays['Thursday'][
                'menuLines'
              ] = JSON.parse(
                JSON.stringify(
                  this.filterResponseByUndefined(itemMenus['menuLines'])
                )
              );

              this.customerNr = this.dishAllocationsWeekResponse[0][
                'customerNr'
              ];
              this.orderNotPermitForDay = this.checkPermittionsForDay(
                this.dishAllocationsWeekResponse
              ); //this.orderNotPermit = false;
              this.cart.cartOrderNotPermit = this.orderNotPermitForDay;
              console.log(
                '[ngOnInit] this.orderNotPermitForDay',
                this.orderNotPermitForDay
              );
              //-----------------------------------------Order----------------------------------------------------------------------------------
              this.simpleOderModelArrayId =
                'simpleOderModelArray' +
                this.localStorageService.get('currentChildRoute') +
                this.shiftWeekNumber +
                this.departmentId;
              this.simpleOderModelArray = this.localStorageService.get(
                this.simpleOderModelArrayId
              );
              if (!this.simpleOderModelArray) {
                this.simpleOderModelArray = new Array();
                this.sumOder = 0;
              }

              if (_.isEmpty(this.dishAllocationsOrderDays['Thursday'])) {
                console.debug(
                  'this.dishAllocationsOrderDays[\'Thursday\'].length==0'
                );
                this.isEmptyThursday = true;
              }
            } else {
              this.isEmptyThursday = true;
              console.warn('this.isEmptyThursday', this.isEmptyThursday);
            }

            if (
              this.dishAllocationsWeekResponse[1] &&
              this.dishAllocationsWeekResponse[1]['menus'][0] !== undefined
            ) {
              this.isEmptyFriday = true;
              this.dishAllocationsOrderDays[
                'Friday'
              ] = this.dishAllocationsWeekResponse[1]['menus'][0];

              const itemMenus = this.formDataController(
                this.dishAllocationsWeekResponse
              );

              console.log('[ngOnInit] itemMenus', itemMenus);

              this.dinamicCategoriesDays['Friday'] = this.getDinamicCatArr(
                this.dishAllocationsWeekResponse[1]['menus']
              );

              this.dishAllocationsOrderDays['Friday']['menuLines'] = JSON.parse(
                JSON.stringify(
                  this.filterResponseByUndefined(itemMenus['menuLines'])
                )
              );

              if (_.isEmpty(this.dishAllocationsOrderDays['Friday'])) {
                console.warn(
                  'this.dishAllocationsOrderDays[\'Friday\'].length==0'
                );
                this.isEmptyFriday = true;
              }
            } else {
              this.isEmptyFriday = true;
              console.warn('this.isEmptyFriday', this.isEmptyFriday);
            }

            if (
              this.dishAllocationsWeekResponse[2] &&
              this.dishAllocationsWeekResponse[2]['menus'][2] !== undefined
            ) {
              this.isEmptySaturday = false;
              this.dishAllocationsOrderDays[
                'Saturday'
              ] = this.dishAllocationsWeekResponse[2]['menus'][2];

              const itemMenus = this.formDataController(
                this.dishAllocationsWeekResponse
              );

              console.log('[ngOnInit] itemMenus', itemMenus);

              this.dinamicCategoriesDays['Saturday'] = this.getDinamicCatArr(
                this.dishAllocationsWeekResponse[2]['menus']
              );

              this.dishAllocationsOrderDays['Saturday'][
                'menuLines'
              ] = JSON.parse(
                JSON.stringify(
                  this.filterResponseByUndefined(itemMenus['menuLines'])
                )
              );

              this.customerNr = this.dishAllocationsWeekResponse[2][
                'customerNr'
              ];
              this.orderNotPermitForDay = this.checkPermittionsForDay(
                this.dishAllocationsWeekResponse
              );
              this.cart.cartOrderNotPermit = this.orderNotPermitForDay;
              console.log(
                '[ngOnInit] this.orderNotPermitForDay',
                this.orderNotPermitForDay
              );
              //-----------------------------------------Order----------------------------------------------------------------------------------
              this.simpleOderModelArrayId =
                'simpleOderModelArray' +
                this.localStorageService.get('currentChildRoute') +
                this.shiftWeekNumber +
                this.departmentId;
              this.simpleOderModelArray = this.localStorageService.get(
                this.simpleOderModelArrayId
              );
              if (!this.simpleOderModelArray) {
                this.simpleOderModelArray = new Array();
                this.sumOder = 0;
              }

              if (_.isEmpty(this.dishAllocationsOrderDays['Saturday'])) {
                console.warn(
                  'this.dishAllocationsOrderDays[\'Saturday\'].length==0'
                );
                this.isEmptySaturday = true;
              }
            } else {
              this.isEmptySaturday = true;
              console.warn('this.isEmptySaturday', this.isEmptySaturday);
            }

            break;
          }
          case 'Saturday': {
            if (
              this.dishAllocationsWeekResponse[0] &&
              this.dishAllocationsWeekResponse[0]['menus'][0] !== undefined
            ) {
              this.isEmptySaturday = false;
              this.dishAllocationsOrderDays[
                'Sunday'
              ] = this.dishAllocationsWeekResponse[0]['menus'][0];

              const itemMenus = this.formDataController(
                this.dishAllocationsWeekResponse
              );

              console.log('[ngOnInit] itemMenus', itemMenus);

              this.dinamicCategoriesDays['Sunday'] = this.getDinamicCatArr(
                this.dishAllocationsWeekResponse[0]['menus']
              );

              this.dishAllocationsOrderDays['Sunday']['menuLines'] = JSON.parse(
                JSON.stringify(
                  this.filterResponseByUndefined(itemMenus['menuLines'])
                )
              );

              this.customerNr = this.dishAllocationsWeekResponse[0][
                'customerNr'
              ];
              this.orderNotPermitForDay = this.checkPermittionsForDay(
                this.dishAllocationsWeekResponse
              );
              this.cart.cartOrderNotPermit = this.orderNotPermitForDay;
              console.log(
                '[ngOnInit] this.orderNotPermitForDay',
                this.orderNotPermitForDay
              );
              //-----------------------------------------Order----------------------------------------------------------------------------------
              this.simpleOderModelArrayId =
                'simpleOderModelArray' +
                this.localStorageService.get('currentChildRoute') +
                this.shiftWeekNumber +
                this.departmentId;
              this.simpleOderModelArray = this.localStorageService.get(
                this.simpleOderModelArrayId
              );
              if (!this.simpleOderModelArray) {
                this.simpleOderModelArray = new Array();
                this.sumOder = 0;
              }

              if (_.isEmpty(this.dishAllocationsOrderDays['Sunday'])) {
                console.warn(
                  'this.dishAllocationsOrderDays[\'Sunday\'].length==0'
                );
                this.isEmptySaturday = true;
              }
            } else {
              this.isEmptySaturday = true;
              console.warn('this.isEmptySaturday', this.isEmptySaturday);
            }

            if (
              this.dishAllocationsWeekResponse[1] &&
              this.dishAllocationsWeekResponse[1]['menus'][0] !== undefined
            ) {
              this.isEmptyMonday = true;
              this.dishAllocationsOrderDays[
                'Monday'
              ] = this.dishAllocationsWeekResponse[1]['menus'][0];
              const itemMenus = this.formDataController(
                this.dishAllocationsWeekResponse
              );

              console.log('[ngOnInit] itemMenus', itemMenus);

              this.dinamicCategoriesDays['Monday'] = this.getDinamicCatArr(
                this.dishAllocationsWeekResponse[1]['menus']
              );

              this.dishAllocationsOrderDays['Monday']['menuLines'] = JSON.parse(
                JSON.stringify(
                  this.filterResponseByUndefined(itemMenus['menuLines'])
                )
              );

              if (_.isEmpty(this.dishAllocationsOrderDays['Monday'])) {
                console.warn(
                  'this.dishAllocationsOrderDays[\'Monday\'].length==0'
                );
                this.isEmptyMonday = true;
              }
            } else {
              this.isEmptyMonday = true;
              console.warn('this.isEmptyMonday', this.isEmptyMonday);
            }

            break;
          }
          default: {
            if (
              this.dishAllocationsWeekResponse[0] &&
              this.dishAllocationsWeekResponse[0]['menus'][0] !== undefined
            ) {
              this.isEmptyTuesday = false;
              this.dishAllocationsOrderDays[
                'Tuesday'
              ] = this.dishAllocationsWeekResponse[0]['menus'][0];

              const itemMenus = this.formDataController(
                this.dishAllocationsWeekResponse
              );

              console.log('[ngOnInit] itemMenus', itemMenus);
              this.dishAllocationsOrderDays['Tuesday'][
                'menuLines'
              ] = JSON.parse(
                JSON.stringify(
                  this.filterResponseByUndefined(itemMenus['menuLines'])
                )
              );

              this.customerNr = this.dishAllocationsWeekResponse[0][
                'customerNr'
              ];
              this.orderNotPermitForDay = this.checkPermittionsForDay(
                this.dishAllocationsWeekResponse
              );
              this.cart.cartOrderNotPermit = this.orderNotPermitForDay;
              console.log(
                '[ngOnInit] this.orderNotPermitForDay',
                this.orderNotPermitForDay
              );
              //-----------------------------------------Order----------------------------------------------------------------------------------
              this.simpleOderModelArrayId =
                'simpleOderModelArray' +
                this.localStorageService.get('currentChildRoute') +
                this.shiftWeekNumber +
                this.departmentId;
              this.simpleOderModelArray = this.localStorageService.get(
                this.simpleOderModelArrayId
              );
              if (!this.simpleOderModelArray) {
                this.simpleOderModelArray = new Array();
                this.sumOder = 0;
              }

              if (_.isEmpty(this.dishAllocationsOrderDays['Tuesday'])) {
                console.warn(
                  'this.dishAllocationsOrderDays[\'Tuesday\'].length==0'
                );
                this.isEmptyTuesday = true;
              }
            } else {
              this.isEmptyTuesday = true;
              console.warn('this.isEmptyTuesday', this.isEmptyTuesday);
            }
            if (
              this.dishAllocationsWeekResponse[1] &&
              this.dishAllocationsWeekResponse[1]['menus'][0] !== undefined
            ) {
              this.isEmptyWednesday = true;
              this.dishAllocationsOrderDays[
                'Wednesday'
              ] = this.dishAllocationsWeekResponse[1]['menus'][0];

              const itemMenus = this.formDataController(
                this.dishAllocationsWeekResponse
              );

              console.log('[ngOnInit] itemMenus', itemMenus);
              this.dishAllocationsOrderDays['Wednesday'][
                'menuLines'
              ] = JSON.parse(
                JSON.stringify(
                  this.filterResponseByUndefined(itemMenus['menuLines'])
                )
              );

              if (_.isEmpty(this.dishAllocationsOrderDays['Wednesday'])) {
                console.warn(
                  'this.dishAllocationsOrderDays[\'Wednesday\'].length==0'
                );
                this.isEmptyWednesday = true;
              }
            } else {
              this.isEmptyWednesday = true;
              console.warn('this.isEmptyWednesday', this.isEmptyWednesday);
            }
            break;
          }
        }
        //------------------------------------Get Sum Oder Init value----------------------------------
        //this.sumOder = this.getSumOrderAllInit(toggledDay1, this.dishAllocationsOrderDays);

        //-----------------------------------Save All Dishes-----------------------------

        this.dishAllocationsOrderDaysAll = JSON.parse(
          JSON.stringify(this.dishAllocationsOrderDays)
        ); // save View All Dishes

        console.log(
          '[ngOnInit] this.dishAllocationsOrderDaysAll',
          JSON.stringify(this.dishAllocationsOrderDaysAll)
        );
        //--------------------------------------------------------------------------

        console.log(
          '[ngOnInit] this.dinamicCategoriesDays',
          this.dinamicCategoriesDays
        );
        //-------------------------------------Put ALL to LocalStorage from Response-----------
        /*this.toggleJulemenuNytarsmenu();
		this.toggleGastekok();
		this.toggleFodselsdagsmenu();
		this.toggleKageBrod();
		this.toggleDogn();*/
        for (const category in this.dinamicCategoriesDays[
          this.currentServingDay
        ]) {
          this.toggleCategory(
            this.dinamicCategoriesDays[this.currentServingDay][category]
          );
        }

        //--------------------------------Toogle Tilvalg----------------------------------
        /*	this.toggleTilvalg();
		this.currentToogledSubCategory ="Tilvalg" */
        this.toggleCategory(
          this.dinamicCategoriesDays[this.currentServingDay][0]
        );
        console.log(
          '[ngOnInit] this.dinamicCategoriesDays[toggledDay1][0]',
          this.dinamicCategoriesDays[this.currentServingDay][0]
        );
        this.currentToogledSubCategory = this.dinamicCategoriesDays[
          this.currentServingDay
        ][0];

        //---------------------- get ArrayDishesViewController for Order Days------------

        this.lastIndex = 0;
        this.numberElementsInRow = 3;
        this.arrayDishesViewForDay = new Array();

        for (const day in this.dishAllocationsOrderDays) {
          if (
            this.dishAllocationsOrderDays[day] !== undefined &&
            this.dishAllocationsOrderDays[day] != [] &&
            this.dishAllocationsOrderDays[day]['menuLines'] != undefined
          ) {
            this.dishAllocationsOrderDays[
              day
            ].arrayDishesViewForDay = this.getArrayDishesViewForDayController(
              this.numberElementsInRow,
              this.dishAllocationsOrderDays[day]['menuLines'].length
            );
          }
        }

        //-------------------------------------------------------------------------------------------------------
        this.needNgOninit = true;
        this.needUnsubscribe = true;

        console.debug(
          'this.dishAllocationsOrderDays22=' +
            JSON.stringify(this.dishAllocationsOrderDays)
        );
        //************ TopMessage ****************/
        let title = 'Bestillingsfrister';
        //let text = 'Tilvalg+ Kage/brød - 7 dage<br> Fødselsdagsmenu - 14 dage '
        let text = 'Tilvalg+ Kage/brød - 7 dage'
        
        this.topMessageTitleText = new TopMessageTitleText(
          title,
          text,
          'top-message-class-tilvalg-title',
          'top-message-class-tilvalg-text'
        );
      });
    }
    console.debug(
      'this.dishAllocationsOrderDays3=' +
        JSON.stringify(this.dishAllocationsOrderDays)
    );

    //-----------------------GET  UserInfoOrderingModel ---------------------------------------------------------

    this.userInfoOrderingModel = this.localStorageService.get(
      'userInfoOrderingModel'
    );
    this.orderingDates = this.userInfoOrderingModel['orderingDates'];
    console.debug(
      'FROKOCOMPONENT this.orderingDates=' + JSON.stringify(this.orderingDates)
    );

    //-------------------------------------------------------------------------------------------------------------
    this.orderDishId = 0;
    this.currentToogledDay = 'Tuesday';

    //-------------------------------------------Order------------------------------------------------------

    //this.isOrderingTimeFlag = false;  // !!! Temp
    this.isOrderingTimeFlag = true;
    this.simpleOderModelArrayId =
      'simpleOderModelArray' +
      this.localStorageService.get('currentChildRoute') +
      this.shiftWeekNumber +
      this.departmentId;

    this.simpleOderModelArray = this.localStorageService.get(
      this.simpleOderModelArrayId
    );
    if (!this.simpleOderModelArray) {
      this.simpleOderModelArray = new Array();
      this.sumOder = 0;
    } else {
      this.sumOder = 0;
      for (var oder in this.simpleOderModelArray) {
        if (this.simpleOderModelArray[oder]['Day'] == this.currentServingDay) {
          this.sumOder += this.simpleOderModelArray[oder].sumOder;
        }
      }
    }

    console.log('[ngOninit] this.sumOder', this.sumOder);

    console.debug(
      'this.isOrderingTimeFlag=' +
        this.isOrderingTime(this.userInfoOrderingModel, this.categoryForFilter)
    );
    //----------------------------------Btn Subcategory-------------------------
    //this.toggleAll();

    //this.btnBackgroundColor = '#FFFFFF';
    this.btnAllBackgroundColor = '#8FBF21';
    this.btnAllTextColor = '#FFFFFF';

    /*switch(this.localStorageService.get( 'toggledSubCategory')){

			case 'Tilvalg':


			break;
			case 'Døgn':


			break;

			default:


		}*/

    //----------------------Modal Window init -----------------------

    this.modalBtnFlag = 'dishInfo';
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube.com/embed/nOIwHaiojDI'
    );
    this.modalBtnBackgroundColor = '#FFFFFF';
    this.modalBtnTextColor = '#000000';
    this.modalBtnTextWeight = 'bold';

    //--------------------- disabledAfterOrder ------------------------------
    this.disabledAfterOrder = this.localStorageService.get(
      this.localStorageService.get('currentChildRoute') + 'disabledAfterOrder'
    );

    if (!environment.debugMode) {
      console.clear();
    }
  }
  //-----------------------------------------------------------------------------------------------------
  //*****************************************************************************************************
  //------------------------------- Component Internal Methods------------------------------------------
  ngAfterContentChecked() {
    if (this.needNgOninit) {
      this.ngOnInit();
    }

    if (!environment.debugMode) {
      console.clear();
    }
  }
  ngOnDestroy() {
    this.obsSubscription.unsubscribe();
  }

  //-----------------------------Methods--------------------------------------------------------------

  //-----------------------------Filter Methods-----------------------------------------------------
  filterResponseByUndefined(responseDayMenuLines: any[]) {
    //console.debug("responseDayMenuLines", responseDayMenuLines);
    let responseDayFiltered = [];
    responseDayFiltered = responseDayMenuLines.filter((el) => {
      if (
        el['menuLine'] !== undefined &&
        el['menuLine'] != [] &&
        el['dish'] !== undefined &&
        el['dish'] != []
      ) {
        //console.debug("eldish1", el["dish"]["dishPhotos"]);
        if (el['dish']['dishPhotos'].length == 0) {
          //console.debug("eldish2", el["dish"]["dishPhotos"]);
          el['dish']['dishPhotos'].push({ imageUrl: '' });
        }

        return true;
      }
    });

    console.debug('responseDayFiltered', responseDayFiltered);
    return responseDayFiltered;
  }

  //------------------------Form concat array and Data Controller---------------
  formDataController(dishAllocationsWeekResponse: any) {
    console.debug('[formDataController]');
    this.mapDataControl = new Map();
    const lengthInit =
      dishAllocationsWeekResponse[0]['menus'][0]['menuLines'].length;
    let lengthCurrent = dishAllocationsWeekResponse[0]['menus'][0].length;
    const dataControlInit = new DataControl(
      dishAllocationsWeekResponse[0]['menus'][0]['deadlineStatus'],
      dishAllocationsWeekResponse[0]['menus'][0]['menuName'],
      dishAllocationsWeekResponse[0]['menus'][0]['menuNr'],
      dishAllocationsWeekResponse[0]['menus'][0]['orderNr'],
      dishAllocationsWeekResponse[0]['menus'][0]['orderOperation'],
      dishAllocationsWeekResponse[0]['menus'][0]['orderPermitStatus'],
      dishAllocationsWeekResponse[0]['customerNr']
    );
    this.mapDataControl.set(lengthInit, dataControlInit);
    console.debug(
      '[formDataController] mapDataControl Init',
      this.mapDataControl
    );
    for (
      let itemMenus = 1;
      itemMenus < dishAllocationsWeekResponse[0]['menus'].length;
      itemMenus++
    ) {
      //console.debug("[formDataController] itemMenus", itemMenus);

      //console.debug("[formDataController] dishAllocationsWeekResponse[0]", dishAllocationsWeekResponse[0]["menus"][itemMenus]);
      if (
        dishAllocationsWeekResponse[0]['menus'][0] &&
        dishAllocationsWeekResponse[0]['menus'][0]['menuLines'].length != 0
      ) {
        if (
          dishAllocationsWeekResponse[0]['menus'][itemMenus] &&
          dishAllocationsWeekResponse[0]['menus'][itemMenus]['menuLines']
            .length != 0
        ) {
          //let lengthPrev = dishAllocationsWeekResponse[0]["menus"][0].length;
          //let lengthNext = dishAllocationsWeekResponse[0]["menus"][itemMenus].length;
          dishAllocationsWeekResponse[0]['menus'][0][
            'menuLines'
          ] = dishAllocationsWeekResponse[0]['menus'][0]['menuLines'].concat(
            dishAllocationsWeekResponse[0]['menus'][itemMenus]['menuLines']
          );

          //console.debug("[formDataController] dishAllocationsWeekResponse[0][menus][0]", dishAllocationsWeekResponse[0]["menus"][0]["menuLines"]);
          lengthCurrent =
            dishAllocationsWeekResponse[0]['menus'][0]['menuLines'].length;
          const dataControlCurrent = new DataControl(
            dishAllocationsWeekResponse[0]['menus'][itemMenus][
              'deadlineStatus'
            ],
            dishAllocationsWeekResponse[0]['menus'][itemMenus]['menuName'],
            dishAllocationsWeekResponse[0]['menus'][itemMenus]['menuNr'],
            dishAllocationsWeekResponse[0]['menus'][itemMenus]['orderNr'],
            dishAllocationsWeekResponse[0]['menus'][itemMenus][
              'orderOperation'
            ],
            dishAllocationsWeekResponse[0]['menus'][itemMenus][
              'orderPermitStatus'
            ],
            dishAllocationsWeekResponse[0]['customerNr']
          );

          this.mapDataControl.set(lengthCurrent, dataControlCurrent);
          //console.debug("[formDataController] this.mapDataControl", this.mapDataControl);
        }
      }
    }

    console.debug(
      '[formDataController] this.mapDataControl',
      this.mapDataControl
    );
    console.debug(
      '[formDataController] dishAllocationsWeekResponse',
      JSON.stringify(dishAllocationsWeekResponse)
    );
    return dishAllocationsWeekResponse[0]['menus'][0];
  }

  //--------------------------------get DataControl for Item MenuLine---------------------------------------
  getDataControlKeyForItemMenuLine(itemMenuLineNumber: any): number {
    let found = false;
    let keyForItemMenuLine = 0;
    this.mapDataControl.forEach(function (itemDataControl, key, ownerSet) {
      console.debug(
        '[getDataControlKeyForItemMenuLine] itemDataControl',
        itemDataControl
      );
      if (!found && key > itemMenuLineNumber) {
        keyForItemMenuLine = key;
        found = true;
      }
    });

    console.debug(
      '[getDataControlKeyForItemMenuLine] keyForItemMenuLine',
      keyForItemMenuLine
    );
    return keyForItemMenuLine;
  }

  //------------------------------get Dishes by MenuName--------------------------------
  getRangesToDeleteFromDataControlByMenuName(menuName: string) {
    const found = false;
    const keyRangesArr = [];
    const lastIndexForMenuName = 0;
    let startRangeIndex = 0;
    let lastRangeIndex = 0;

    for (const key of this.mapDataControl.keys()) {
      lastRangeIndex = key;
      keyRangesArr.push([startRangeIndex, lastRangeIndex - 1]);
      startRangeIndex = lastRangeIndex;
      const itemDataControl = this.mapDataControl.get(key);
      if (itemDataControl['menuName'] == menuName) {
        keyRangesArr.pop();
      }
    }

    console.log(
      '[getRangesToDeleteFromDataControlByMenuName] keyRangesArr',
      keyRangesArr
    );
    return keyRangesArr;
  }

  //---------------------------------------------------------------------------------
  //------------------------------get CurrentRanges by MenuName--------------------------------
  getCurrentRangesFromDataControlByMenuName(menuName: string) {
    const found = false;
    const keyRangesArr = [];
    const lastIndexForMenuName = 0;
    let startRangeIndex = 0;
    let lastRangeIndex = 0;

    for (const key of this.mapDataControl.keys()) {
      lastRangeIndex = key;
      keyRangesArr.push([startRangeIndex, lastRangeIndex - 1]);
      startRangeIndex = lastRangeIndex;
      const itemDataControl = this.mapDataControl.get(key);
      if (itemDataControl['menuName'] != menuName) {
        keyRangesArr.pop();
      }
    }

    console.log(
      '[getCurrentRangesFromDataControlByMenuName] keyRangesArr',
      keyRangesArr
    );
    return keyRangesArr;
  }

  //-------------------------------------------------------------------------------

  getRealElementNumber(element: number, currentRangesArr: any) {
    //console.debug("[getRealElementNumber] element", element);

    //console.debug("[getRealElementNumber] currentRangesArr", currentRangesArr);

    let realElNr: number;
    realElNr = element;
    for (const range in currentRangesArr) {
      realElNr += currentRangesArr[range][0];
      if (realElNr <= currentRangesArr[range][1]) {
        break;
      } else {
        realElNr -= currentRangesArr[range][1] + 1;
      }
    }

    //console.debug("[getRealElementNumber] realElNr", realElNr);
    return realElNr;
  }
  //-----------------------Get Permittions for SubCategory-----------------

  getPermittionsFromDataControlByMenuName(menuName: string) {
    const found = false;
    const keyRangesArr = [];
    const lastIndexForMenuName = 0;
    const startRangeIndex = 0;
    const lastRangeIndex = 0;
    let orderNumber: boolean = true;
    for (const key of this.mapDataControl.keys()) {
      const itemDataControl = this.mapDataControl.get(key);
      if (itemDataControl['menuName'] == menuName) {
        orderNumber = this.checkPermittionsForOrder(
          itemDataControl['deadlineStatus'],
          itemDataControl['orderPermitStatus'],
          itemDataControl['orderOperation']
        );
      }
    }

    console.log(
      '[getPermittionsFromDataControlByMenuName] orderNumber',
      orderNumber
    );
    return orderNumber;
  }
  //-----------------------Get Dinamic Categories ------------------------------
  getDinamicCatArr(dishAllocationsWeekResponseDayMenus: any) {
    console.log(
      '[getDinamicCatArr] dishAllocationsWeekResponseDayMenus',
      dishAllocationsWeekResponseDayMenus
    );
    const dinamicCatArr = [];
    for (const menuItem in dishAllocationsWeekResponseDayMenus) {
      dinamicCatArr.push(
        dishAllocationsWeekResponseDayMenus[menuItem]['menuName']
      );
    }

    console.log('[getDinamicCatArr] dinamicCatArr', dinamicCatArr);
    return dinamicCatArr;
  }
  //------------------------------Get Category Short Name----------------------

  getCategoryShortName(category: string): string {
    let categoryShort: string;
    switch (category) {
      case 'Tilvalg':
        categoryShort = 'Tilvalg';
        break;
      case 'Tilvalg - Døgn':
        categoryShort = 'Døgn';
        break;
      case 'Kage/Brød plan':
        categoryShort = 'Kage/Brød';
        break;
      case 'Fødselsdagsmenu':
        categoryShort = 'Fødselsdags';
        break;
      case 'Gæstekok':
        categoryShort = 'Gæstekok';
        break;
      case 'Julemenu/ nytårsmenu':
        categoryShort = 'Julemenu';
        break;
      default: {
        categoryShort = category;
      }
    }

    return categoryShort;
  }

  //---------------------------------------------------------------------------

  categoryFilter(day: number, response: any[], category: any) {
    var dishArrForCategoty = Object.create({ category: [], categories: [] });
    var exit_loops = false;
    var lowerCaseCategory = category.toLowerCase();

    console.debug(
      'In filterResponseByCategory lowerCaseCategory=' + lowerCaseCategory
    );
    for (var n = 0; n < response[day].length; n++) {
      //	console.debug("In filterResponseByCategory response[day][n][category][name]="+ response[day][n]["category"][name]);
      if (
        response[day][n]['category']['name'] == category ||
        response[day][n]['category']['name'] == lowerCaseCategory
      ) {
        //	console.debug("In categoryFilter. Category found")

        dishArrForCategoty.category = response[day][n]['category'];
        dishArrForCategoty.categories = response[day][n]['categories'];
        exit_loops = true;
        break;
      } else {
        //	console.debug("In categoryFilter. Category not found")
      }
    }

    // console.debug("RETURN" + JSON.stringify(dishArrForCategoty));
    return dishArrForCategoty;
  }
  arrayDishesViewForDayByCategoryFilter(
    day: string,
    dishAllocationsOrderDays: DishAllocationsOrderDays,
    category: any
  ) {
    var viewArrayForCategoty = new Array();
    var exit_loops = false;

    for (var cat in dishAllocationsOrderDays[day]['categories']) {
      for (var property in dishAllocationsOrderDays[day]['categories'][cat][
        'category'
      ]) {
        if (
          dishAllocationsOrderDays[day]['categories'][cat]['category'][
            property
          ] == category
        ) {
          viewArrayForCategoty =
            dishAllocationsOrderDays[day]['categories'][cat][
              'arrayDishesViewForDay'
            ];

          exit_loops = true;
          break;
        } else {
        }
      }

      if (exit_loops) break;
    }

    return viewArrayForCategoty;
  }

  filterByDaySubcategory(
    dishAllocationsOrderDays: DishAllocationsOrderDays,
    day: string,
    subcategory: string
  ) {
    //var filteredBySubcategoryArr = [];
    //	for(var day in dishAllocationsOrderDays){

    /*for(var subcat in dishAllocationsOrderDays[day]["categories"]){


     		}*/
    console.debug(
      'In filterByDaySubcategory' +
        JSON.stringify(
          dishAllocationsOrderDays[day]['categories'].filter(
            (el) => el['category']['name'] == subcategory
          )
        )
    );
    return dishAllocationsOrderDays[day]['categories'].filter(
      (el) => el['category']['name'] == subcategory
    );
    //	}
  }

  filterdishAllocationsOrderDaysBySubcategory(
    dishAllocationsOrderDays: DishAllocationsOrderDays,
    subcategory: string
  ) {
    //var dishArrForSubcategoty = [];
    //var exit_loops = false;
    console.debug('subcategory=' + subcategory);
    for (var day in dishAllocationsOrderDays) {
      if (
        dishAllocationsOrderDays[day] !== undefined ||
        dishAllocationsOrderDays[day].length != 0
      ) {
        for (var subcat in dishAllocationsOrderDays[day]['categories']) {
          if (
            dishAllocationsOrderDays[day]['categories'][subcat]['category'][
              'name'
            ] != subcategory
          ) {
            /*dishAllocationsOrderDays[day]["categories"]
					.splice(
					dishAllocationsOrderDays[day]["categories"].indexOf(dishAllocationsOrderDays[day]["categories"][subcat]), 1);*/
            delete dishAllocationsOrderDays[day]['categories'][
              dishAllocationsOrderDays[day]['categories'].indexOf(
                dishAllocationsOrderDays[day]['categories'][subcat]
              )
            ];
            console.debug(
              ' FILTER index=' +
                JSON.stringify(
                  dishAllocationsOrderDays[day]['categories'].indexOf(
                    dishAllocationsOrderDays[day]['categories'][subcat]
                  )
                )
            );
            console.debug(' FILTER=' + (subcat + 1));
            //console.debug(" FILTER="+JSON.stringify(dishAllocationsOrderDays));
          } else {
            continue;
          }
        }
        dishAllocationsOrderDays[day]['categories'] = _.compact(
          dishAllocationsOrderDays[day]['categories']
        );
      }
    }
    //dishAllocationsOrderDays=_.compact(dishAllocationsOrderDays);
    console.debug(' FILTERR=' + JSON.stringify(dishAllocationsOrderDays));
    return dishAllocationsOrderDays;
  }

  //--------------------Filter By category Menu -----------------------------------------

  filterdishAllocationsOrderDaysByCategoryMenu(
    dishAllocationsOrderDays: DishAllocationsOrderDays,
    subcategory: string
  ) {
    //var dishArrForSubcategoty = [];
    //var exit_loops = false;

    console.debug(
      '[filterdishAllocationsOrderDaysByCategoryMenu] subcategory=' +
        subcategory
    );
    const keyRangesArr = this.getRangesToDeleteFromDataControlByMenuName(
      subcategory
    );
    console.log(
      '[filterdishAllocationsOrderDaysByCategoryMenu] keyRangesArr',
      keyRangesArr
    );
    this.currentRangeForBtn = this.getCurrentRangesFromDataControlByMenuName(
      subcategory
    );
    console.log(
      '[filterdishAllocationsOrderDaysByCategoryMenu] this.currentRangeForBtn',
      this.currentRangeForBtn
    );

    for (const day in dishAllocationsOrderDays) {
      //	console.debug("[filterdishAllocationsOrderDaysByCategoryMenu] day", day);
      if (dishAllocationsOrderDays[day]['menuLines']) {
        for (const range in keyRangesArr) {
          console.debug(
            '[filterdishAllocationsOrderDaysByCategoryMenu] keyRangesArr[range]',
            keyRangesArr[range]
          );
          //dishAllocationsOrderDays[day]["menuLines"].splice(keyRangesArr[range][0], (keyRangesArr[range][1] + 1));

          for (const itemMenus in dishAllocationsOrderDays[day]['menuLines']) {
            console.debug(
              '[filterdishAllocationsOrderDaysByCategoryMenu] itemMenus',
              itemMenus
            );

            console.debug(
              '[filterdishAllocationsOrderDaysByCategoryMenu] keyRangesArr[range][0]',
              keyRangesArr[range][0]
            );

            console.debug(
              '[filterdishAllocationsOrderDaysByCategoryMenu] keyRangesArr[range][1]',
              keyRangesArr[range][1]
            );

            if (
              Number(itemMenus) >= Number(keyRangesArr[range][0]) &&
              Number(itemMenus) <= Number(keyRangesArr[range][1])
            ) {
              console.log(
                '[filterdishAllocationsOrderDaysByCategoryMenu] menuLine',
                dishAllocationsOrderDays[day]['menuLines'][itemMenus]
              );
              delete dishAllocationsOrderDays[day]['menuLines'][itemMenus];
            } else {
              continue;
            }
          }
        }

        dishAllocationsOrderDays[day]['menuLines'] = _.compact(
          dishAllocationsOrderDays[day]['menuLines']
        );
      }
    }

    console.debug(
      '[filterdishAllocationsOrderDaysByCategoryMenu] FILTERR=' +
        JSON.stringify(dishAllocationsOrderDays)
    );
    return dishAllocationsOrderDays;
  }

  //-------------------------------------------------------------------------------------------------
  sub(cat: any, element: number, nameDay: string) {
    this.isQuantitiesChanged = true;
    this.disabledAfterOrder = false;
    this.localStorageService.set(
      this.localStorageService.get('currentChildRoute') + 'disabledAfterOrder',
      this.disabledAfterOrder
    );

    //realElementNr = this.getRealElementNumber(element, this.currentRangeForBtn);
    console.debug('[sub] element', element);
    if (!(this.orderNotPermitForDay || this.orderNotPermitForSubCategory)) {
      let orderFound = false;
      let catNumber = 3;
      if (cat == 'Froko') {
        catNumber = 1;
      } else if (cat == 'Extra') {
        catNumber = 2;
      } else {
        catNumber +=  this.getCatNumbForDinamicCategory(cat, this.dinamicCategoriesDays, this.currentServingDay);
      }

      //var orderDishIdTCur = nameDay + cat + element.toString();
      var orderDishIdTCur = this.getOrderDishId(
        this.localStorageService.get('currentChildRoute'),
        this.shiftWeek,
        nameDay,
        catNumber,
        element
      );
      for (var oder in this.simpleOderModelArray) {
        if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
          orderFound = true;
          if (this.simpleOderModelArray[oder].orderNumber != 0) {
            this.simpleOderModelArray[oder].orderNumber -= 1;
            if (this.simpleOderModelArray[oder].orderNumber < 0) {
              this.simpleOderModelArray[oder].orderNumber = 0;
            }
            this.simpleOderModelArray[oder].sumOder -= 1;
            if (this.simpleOderModelArray[oder].sumOder < 0) {
              this.simpleOderModelArray[oder].sumOder = 0;
            }
          }
          this.simpleOderModelArray[oder].changed = true;
        }
      }

      this.localStorageService.set(
        this.simpleOderModelArrayId,
        this.simpleOderModelArray
      );

      //console.log("dishAllocationsOrderDays[nameDay][0][element]="+JSON.stringify(this.dishAllocationsOrderDays[nameDay][0][element]));

      //this.subProductToCart(this.dishAllocationsOrderDays[nameDay]["categories"][cat]["dishes"][element],1)
      this.updateCartFromLocalStorage();
      /*if(orderFound){
	 			this.sumOder-=1;
		  		if(this.sumOder<0){
		  		this.sumOder = 0;
		  		}
	 		}*/

      //this.sumOder = this.getSumOrder(this.simpleOderModelArray);
      this.sumOder = this.getSumOrderForCurrentServingDay(
        this.simpleOderModelArray,
        this.currentServingDay
      );
    }
  }

  add(cat: any, element: number, nameDay: string) {
    //console.log("nameDay", nameDay);
    //console.log("element", element);
    //console.log("cat", cat);
    this.isQuantitiesChanged = true;
    this.disabledAfterOrder = false;
    this.localStorageService.set(
      this.localStorageService.get('currentChildRoute') + 'disabledAfterOrder',
      this.disabledAfterOrder
    );

    if (!(this.orderNotPermitForDay || this.orderNotPermitForSubCategory)) {
      let catNumber = 3;
      if (cat == 'Froko') {
        catNumber = 1;
      } else if (cat == 'Extra') {
        catNumber = 2;
      } else {
        catNumber +=  this.getCatNumbForDinamicCategory(cat, this.dinamicCategoriesDays, this.currentServingDay);
      }

      var orderFound = false;
      const realElementNr = this.getRealElementNumber(
        element,
        this.currentRangeForBtn
      );
      console.debug('[add] realElementNr', realElementNr);
      const key = this.getDataControlKeyForItemMenuLine(realElementNr);
      const itemDataControlForElement = this.mapDataControl.get(key);
      const orderNotPermit = this.checkPermittionsForOrder(
        itemDataControlForElement['deadlineStatus'],
        itemDataControlForElement['orderPermitStatus'],
        itemDataControlForElement['orderOperation']
      );
      console.debug(
        '[add] itemDataControlForElement',
        itemDataControlForElement
      );
      //	var orderDishIdTCur = nameDay + cat + element.toString();

      var orderDishIdTCur = this.getOrderDishId(
        this.localStorageService.get('currentChildRoute'),
        this.shiftWeek,
        nameDay,
        catNumber,
        element
      );
      console.log('orderDishIdTCurNumber=' + orderDishIdTCur);

      if (this.simpleOderModelArray.length == 0) {
        //console.log("this.simpleOderModelArray.length="+this.simpleOderModelArray.length);
        this.simpleOderModelArray.push(
          new SimpleOderModel(
            orderDishIdTCur,
            1,
            1,
            nameDay,
            this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
              'menuLine'
            ]['productNr'],
            this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
              'menuLine'
            ]['productName'],
            this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
              'dish'
            ]['dishPhotos'][0]['imageUrl'],
            'Extra',
            cat,
            {
              dietTypeNr: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['dietTypeNr'],
              dietTypeName: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['dietTypeName'],
              portionSize: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['portionSize'],
              line: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['line'],
              orderNr: itemDataControlForElement['orderNr'],
              customerNr: itemDataControlForElement['customerNr'],
              menuNr: itemDataControlForElement['menuNr'],
              changed: true,
              orderNotPermit: orderNotPermit,
              enkeltPortion: this.dishAllocationsOrderDays[nameDay][
                'menuLines'
              ][element]['menuLine']['enkeltPortion'],
            }
          )
        );
      } else {
        for (var oder in this.simpleOderModelArray) {
          if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
            orderFound = true;
            this.simpleOderModelArray[oder].orderNumber += 1;
            this.simpleOderModelArray[oder].sumOder += 1;
            this.simpleOderModelArray[oder].changed = true;
          }
        }

        if (!orderFound) {
          //		console.log("Else="+element);
          this.simpleOderModelArray.push(
            new SimpleOderModel(
              orderDishIdTCur,
              1,
              1,
              nameDay,
              this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['productNr'],
              this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['productName'],
              this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'dish'
              ]['dishPhotos'][0]['imageUrl'],
              'Extra',
              cat,
              {
                dietTypeNr: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                  element
                ]['menuLine']['dietTypeNr'],
                dietTypeName: this.dishAllocationsOrderDays[nameDay][
                  'menuLines'
                ][element]['menuLine']['dietTypeName'],
                portionSize: this.dishAllocationsOrderDays[nameDay][
                  'menuLines'
                ][element]['menuLine']['portionSize'],
                line: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                  element
                ]['menuLine']['line'],
                orderNr: itemDataControlForElement['orderNr'],
                customerNr: itemDataControlForElement['customerNr'],
                menuNr: itemDataControlForElement['menuNr'],
                changed: true,
                orderNotPermit: orderNotPermit,
                enkeltPortion: this.dishAllocationsOrderDays[nameDay][
                  'menuLines'
                ][element]['menuLine']['enkeltPortion'],
              }
            )
          );
        }
      }

      this.localStorageService.set(
        this.simpleOderModelArrayId,
        this.simpleOderModelArray
      );

      //this.addProductToCart(this.dishAllocationsOrderDays[nameDay]["categories"][cat]["dishes"][element]);
      this.updateCartFromLocalStorage();
      //this.sumOder = this.getSumOrder(this.simpleOderModelArray);

      this.sumOder = this.getSumOrderForCurrentServingDay(
        this.simpleOderModelArray,
        this.currentServingDay
      );

      //this.sumOder+=1;
    }
  }

  updateQuantity(quantity: number, cat: any, element: number, nameDay: string) {
    console.debug('[updateQuantity] updateQuantity=' + quantity);
    this.isQuantitiesChanged = true;
    this.disabledAfterOrder = false;
    this.localStorageService.set(
      this.localStorageService.get('currentChildRoute') + 'disabledAfterOrder',
      this.disabledAfterOrder
    );

    const newQuantity = Number(quantity);
    if (!(this.orderNotPermitForDay || this.orderNotPermitForSubCategory)) {
      let catNumber = 3;
      if (cat == 'Froko') {
        catNumber = 1;
      } else if (cat == 'Extra') {
        catNumber = 2;
      } else {
        catNumber +=  this.getCatNumbForDinamicCategory(cat, this.dinamicCategoriesDays, this.currentServingDay);
      }

      var orderFound = false;
      const realElementNr = this.getRealElementNumber(
        element,
        this.currentRangeForBtn
      );
      console.debug('[add] realElementNr', realElementNr);
      const key = this.getDataControlKeyForItemMenuLine(realElementNr);
      const itemDataControlForElement = this.mapDataControl.get(key);
      const orderNotPermit = this.checkPermittionsForOrder(
        itemDataControlForElement['deadlineStatus'],
        itemDataControlForElement['orderPermitStatus'],
        itemDataControlForElement['orderOperation']
      );
      console.debug(
        '[add] itemDataControlForElement',
        itemDataControlForElement
      );
      //	var orderDishIdTCur = nameDay + cat + element.toString();

      var orderDishIdTCur = this.getOrderDishId(
        this.localStorageService.get('currentChildRoute'),
        this.shiftWeek,
        nameDay,
        catNumber,
        element
      );
      console.log('orderDishIdTCurNumber=' + orderDishIdTCur);

      if (this.simpleOderModelArray.length == 0) {
        //console.log("this.simpleOderModelArray.length="+this.simpleOderModelArray.length);
        this.simpleOderModelArray.push(
          new SimpleOderModel(
            orderDishIdTCur,
            newQuantity,
            newQuantity,
            nameDay,
            this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
              'menuLine'
            ]['productNr'],
            this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
              'menuLine'
            ]['productName'],
            this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
              'dish'
            ]['dishPhotos'][0]['imageUrl'],
            'Extra',
            cat,
            {
              dietTypeNr: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['dietTypeNr'],
              dietTypeName: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['dietTypeName'],
              portionSize: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['portionSize'],
              line: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['line'],
              orderNr: itemDataControlForElement['orderNr'],
              customerNr: itemDataControlForElement['customerNr'],
              menuNr: itemDataControlForElement['menuNr'],
              changed: true,
              orderNotPermit: orderNotPermit,
              enkeltPortion: this.dishAllocationsOrderDays[nameDay][
                'menuLines'
              ][element]['menuLine']['enkeltPortion'],
            }
          )
        );
      } else {
        for (var oder in this.simpleOderModelArray) {
          if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
            orderFound = true;
            this.simpleOderModelArray[oder].orderNumber = newQuantity;
            this.simpleOderModelArray[oder].sumOder = newQuantity;
            this.simpleOderModelArray[oder].changed = true;
          }
        }

        if (!orderFound) {
          //		console.log("Else="+element);
          this.simpleOderModelArray.push(
            new SimpleOderModel(
              orderDishIdTCur,
              newQuantity,
              newQuantity,
              nameDay,
              this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['productNr'],
              this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['productName'],
              this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'dish'
              ]['dishPhotos'][0]['imageUrl'],
              'Extra',
              cat,
              {
                dietTypeNr: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                  element
                ]['menuLine']['dietTypeNr'],
                dietTypeName: this.dishAllocationsOrderDays[nameDay][
                  'menuLines'
                ][element]['menuLine']['dietTypeName'],
                portionSize: this.dishAllocationsOrderDays[nameDay][
                  'menuLines'
                ][element]['menuLine']['portionSize'],
                line: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                  element
                ]['menuLine']['line'],
                orderNr: itemDataControlForElement['orderNr'],
                customerNr: itemDataControlForElement['customerNr'],
                menuNr: itemDataControlForElement['menuNr'],
                changed: true,
                orderNotPermit: orderNotPermit,
                enkeltPortion: this.dishAllocationsOrderDays[nameDay][
                  'menuLines'
                ][element]['menuLine']['enkeltPortion'],
              }
            )
          );
        }
      }

      this.localStorageService.set(
        this.simpleOderModelArrayId,
        this.simpleOderModelArray
      );

      //this.addProductToCart(this.dishAllocationsOrderDays[nameDay]["categories"][cat]["dishes"][element]);
      this.updateCartFromLocalStorage();
      //this.sumOder = this.getSumOrder(this.simpleOderModelArray);

      this.sumOder = this.getSumOrderForCurrentServingDay(
        this.simpleOderModelArray,
        this.currentServingDay
      );

      //this.sumOder+=1;
    }
  }

  getSumOrder(simpleOderModelArray: any) {
    let sumOder = 0;
    for (var oder in simpleOderModelArray) {
      sumOder += simpleOderModelArray[oder].sumOder;
    }
    return sumOder;
  }

  getSumOrderForCurrentServingDay(
    simpleOderModelArray: any,
    currentServingDay: string
  ) {
    let sumOder = 0;
    for (var oder in simpleOderModelArray) {
      if (simpleOderModelArray[oder]['Day'] == currentServingDay) {
        sumOder += simpleOderModelArray[oder].sumOder;
      }
    }
    return sumOder;
  }

  getSumOder(cat: any, element: number, nameDay: string) {
    //console.log("[getSumOder] nameDay", nameDay);
    //console.log("[getSumOder] element", element);
    //console.log("[getSumOder] cat", cat);
    //let realElementNr = this.getRealElementNumber(realElementNr, this.currentRangeForBtn);
    //console.debug("[getSumOder] getSumOder", element);

    let catNumber = 3;
    if (cat == 'Froko') {
      catNumber = 1;
    } else if (cat == 'Extra') {
      catNumber = 2;
    } else {
      catNumber +=  this.getCatNumbForDinamicCategory(cat, this.dinamicCategoriesDays, this.currentServingDay);
    }

    let sumOder = 0;
    //var orderDishIdTCur = nameDay + cat + element.toString();
    const orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      catNumber,
      element
    );
    if (this.simpleOderModelArray.length != 0) {
      for (const oder in this.simpleOderModelArray) {
        if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
          sumOder = this.simpleOderModelArray[oder].sumOder;
        }
        /*	else{
	  				sumOder = this.dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine']["quantity"];
	  			}
	  			*/
      }
    } else {
      sumOder = this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
        'menuLine'
      ]['quantity'];
    }

    //console.debug('[getSumOder] sumOder', sumOder);
    return sumOder;
  }
  //---------------------------------GetSum Order ALL----------------------
  getSumOrderAllInit(toggledDay: string, dishAllocationsOrderDays: any) {
    console.debug('[getSumOrderAllInit]');
    let sumOder = 0;
    for (const day in dishAllocationsOrderDays) {
      if (dishAllocationsOrderDays[day]['menuLines'] && day == toggledDay) {
        for (
          let i = 0;
          i < dishAllocationsOrderDays[day]['menuLines'].length;
          i++
        ) {
          console.debug('[getSumOrderAllInit] i', i);

          if (dishAllocationsOrderDays[day]['menuLines'][i]) {
            if (
              dishAllocationsOrderDays[day]['menuLines'][i]['menuLine'][
                'quantity'
              ] > 0
            ) {
              sumOder +=
                dishAllocationsOrderDays[day]['menuLines'][i]['menuLine'][
                  'quantity'
                ];
            }
          }
        }
      }
    }

    return sumOder;
  }
  //-----------------------------Update SimpleOrderModelArray from respnse---------------------

  updateOrdersInLocalStorageFromResponse(
    toggledDay: string,
    dishAllocationsOrderDays: any,
    subcategory: string
  ) {
    console.debug('[updateOrdersInLocalStorageFromResponse]');
    for (const day in dishAllocationsOrderDays) {
      if (dishAllocationsOrderDays[day]['menuLines'] && day == toggledDay) {
        for (
          let i = 0;
          i < dishAllocationsOrderDays[day]['menuLines'].length;
          i++
        ) {
          console.debug('[updateOrdersInLocalStorageFromResponse] i', i);

          if (dishAllocationsOrderDays[day]['menuLines'][i]) {
            if (
              dishAllocationsOrderDays[day]['menuLines'][i]['menuLine'][
                'quantity'
              ] > 0
            ) {
              //this.updateSimpleOderModelArrayFromResponse("Extra", i, day, dishAllocationsOrderDays);
              this.updateSimpleOderModelArrayFromResponse(
                subcategory,
                i,
                day,
                dishAllocationsOrderDays
              );
            }
          }
        }
      }
    }

    //this.sumOder = this.getSumOrder(this.simpleOderModelArray);

    this.sumOder = this.getSumOrderForCurrentServingDay(
      this.simpleOderModelArray,
      this.currentServingDay
    );
  }

  getCatNumbForDinamicCategory(cat: string, dinamicCategoriesDaysServingDay: DinamicCategoriesDays, currentServingDay: string) {
    console.log('🚀 ~ file: extra.component.ts ~ line 2088 ~ ExtraComponent ~ getCatNumbForDinamicCategory ~ currentServingDay', currentServingDay);
    console.log('🚀 ~ file: extra.component.ts ~ line 2088 ~ ExtraComponent ~ getCatNumbForDinamicCategory ~ dinamicCategoriesDaysServingDay', dinamicCategoriesDaysServingDay);
    console.log('🚀 ~ file: extra.component.ts ~ line 2088 ~ ExtraComponent ~ getCatNumbForDinamicCategory ~ cat', cat);
    let index: number;
    /*for (const day in dinamicCategoriesDaysServingDay){
      if (dinamicCategoriesDaysServingDay[day] == currentServingDay) {
        for (let i = 0; i < dinamicCategoriesDaysServingDay[day].length; i++) {
          if (dinamicCategoriesDaysServingDay[day][i] == cat) {
            index = i;
          }
        }
      }

    } */
    for (let i = 0; i < dinamicCategoriesDaysServingDay[currentServingDay].length; i++) {
          if (dinamicCategoriesDaysServingDay[currentServingDay][i] == cat) {
            index = i;
          }
        }
    return index;
  }
  updateSimpleOderModelArrayFromResponse(
    cat: any,
    element: number,
    nameDay: string,
    dishAllocationsOrderDays: any
  ) {
    console.debug('[updateSimpleOderModelArrayFromResponse]');
    console.debug('[updateSimpleOderModelArrayFromResponse] nameDay', nameDay);
    console.debug('[updateSimpleOderModelArrayFromResponse] element', element);
    console.debug('[updateSimpleOderModelArrayFromResponse] cat', cat);
    let catNumber = 3;
    if (cat == 'Froko') {
      catNumber = 1;
    } else if (cat == 'Extra') {
      catNumber = 2;
    } else {
      catNumber +=  this.getCatNumbForDinamicCategory(cat, this.dinamicCategoriesDays, this.currentServingDay);
    }
    console.log("🚀 ~ file: extra.component.ts ~ line 2136 ~ ExtraComponent ~ ngOnInit ~ catNumber", catNumber)
    var orderFound = false;

    //	var orderDishIdTCur = nameDay + cat + element.toString();
    const realElementNr = this.getRealElementNumber(
      element,
      this.currentRangeForBtn
    );
    console.debug(
      '[updateSimpleOderModelArrayFromResponse] realElementNr',
      realElementNr
    );

    const key = this.getDataControlKeyForItemMenuLine(realElementNr);
    console.debug('[updateSimpleOderModelArrayFromResponse] key', key);
    const itemDataControlForElement = this.mapDataControl.get(key);
    const orderNotPermit = this.checkPermittionsForOrder(
      itemDataControlForElement['deadlineStatus'],
      itemDataControlForElement['orderPermitStatus'],
      itemDataControlForElement['orderOperation']
    );
    console.debug(
      '[updateSimpleOderModelArrayFromResponse] itemDataControlForElement',
      itemDataControlForElement
    );
    console.debug(
      '[updateSimpleOderModelArrayFromResponse] itemDataControlForElement[orderPermitStatus]',
      itemDataControlForElement['orderPermitStatus']
    );
    var orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      catNumber,
      element
    );
    console.log(
      '[updateSimpleOderModelArrayFromResponse]  orderDishIdTCurNumber=' +
        orderDishIdTCur
    );

    if (this.simpleOderModelArray.length == 0) {
      //console.log("this.simpleOderModelArray.length="+this.simpleOderModelArray.length);
      this.simpleOderModelArray.push(
        new SimpleOderModel(
          orderDishIdTCur,
          dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
            'quantity'
          ],
          dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
            'quantity'
          ],
          nameDay,
          dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
            'productNr'
          ],
          dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
            'productName'
          ],
          dishAllocationsOrderDays[nameDay]['menuLines'][element]['dish'][
            'dishPhotos'
          ][0]['imageUrl'],
          'Extra',
          cat,
          {
            dietTypeNr:
              dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['dietTypeNr'],
            dietTypeName:
              dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['dietTypeName'],
            portionSize:
              dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['portionSize'],
            line:
              dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['line'],
            orderNr: itemDataControlForElement['orderNr'],
            customerNr: itemDataControlForElement['customerNr'],
            menuNr: itemDataControlForElement['menuNr'],
            changed: false,
            orderNotPermit: orderNotPermit,
            enkeltPortion: this.dishAllocationsOrderDays[nameDay]['menuLines'][
              element
            ]['menuLine']['enkeltPortion'],
          }
        )
      );
    } else {
      for (var oder in this.simpleOderModelArray) {
        if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
          orderFound = true;
          if (this.simpleOderModelArray[oder].changed != true) {
            this.simpleOderModelArray[oder].orderNumber =
              dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['quantity'];
            this.simpleOderModelArray[oder].sumOder =
              dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['quantity'];
            this.simpleOderModelArray[oder].changed = false;
          } else {
            this.isQuantitiesChanged = true;
            this.disabledAfterOrder = false;
            this.localStorageService.set(
              this.localStorageService.get('currentChildRoute') +
                'disabledAfterOrder',
              this.disabledAfterOrder
            );
          }
        }
      }

      if (!orderFound) {
        //		console.log("Else="+element);
        this.simpleOderModelArray.push(
          new SimpleOderModel(
            orderDishIdTCur,
            dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
              'quantity'
            ],
            dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
              'quantity'
            ],
            nameDay,
            dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
              'productNr'
            ],
            dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
              'productName'
            ],
            dishAllocationsOrderDays[nameDay]['menuLines'][element]['dish'][
              'dishPhotos'
            ][0]['imageUrl'],
            'Extra',
            cat,
            {
              dietTypeNr:
                dishAllocationsOrderDays[nameDay]['menuLines'][element][
                  'menuLine'
                ]['dietTypeNr'],
              dietTypeName:
                dishAllocationsOrderDays[nameDay]['menuLines'][element][
                  'menuLine'
                ]['dietTypeName'],
              portionSize:
                dishAllocationsOrderDays[nameDay]['menuLines'][element][
                  'menuLine'
                ]['portionSize'],
              line:
                dishAllocationsOrderDays[nameDay]['menuLines'][element][
                  'menuLine'
                ]['line'],
              orderNr: itemDataControlForElement['orderNr'],
              customerNr: itemDataControlForElement['customerNr'],
              menuNr: itemDataControlForElement['menuNr'],
              changed: false,
              orderNotPermit: orderNotPermit,
              enkeltPortion: this.dishAllocationsOrderDays[nameDay][
                'menuLines'
              ][element]['menuLine']['enkeltPortion'],
            }
          )
        );
      }
    }

    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );

    //this.addProductToCart(dishAllocationsOrderDays[nameDay]["categories"][cat]["dishes"][element]);
    this.updateCartFromLocalStorage();
    //-----------------------Add ---------------------------------------------
    //  	this.sumOder+=dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine']["quantity"];
    //-------------------------------------------------------------------------
  }

  prevWeek() {
    this.shiftWeek--;

    this.localStorageService.set(
      'shiftWeek' + this.localStorageService.get('currentChildRoute'),
      this.shiftWeek
    );

    this.updateCartFromLocalStorage();
    this.sumOder = 0;

    this.localStorageService.set('toggledDayExtra', 'Tuesday');
    this.toggledTuesday = true;
    this.toggledThursday = false;
    this.toggledSaturday = false;
    this.templateDays = ['Tuesday'];
    this.simpleOderModelArray = [];
    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );

    this.needNgOninit = true;
    //this.needUnsubscribe = false;

    this.router.navigateByUrl('/minmenu/extra');
  }
  nextWeek() {
    this.shiftWeek++;

    this.localStorageService.set(
      'shiftWeek' + this.localStorageService.get('currentChildRoute'),
      this.shiftWeek
    );

    this.updateCartFromLocalStorage();
    this.sumOder = 0;
    this.localStorageService.set('toggledDayExtra', 'Tuesday');

    this.localStorageService.set('toggledDayExtra', 'Tuesday');
    this.toggledTuesday = true;
    this.toggledThursday = false;
    this.toggledSaturday = false;
    this.templateDays = ['Tuesday'];

    this.simpleOderModelArray = [];
    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );

    this.needNgOninit = true;
    //this.needUnsubscribe = false;

    this.router.navigateByUrl('/minmenu/extra');
  }

  /*getImgUrl(imageUrl:string):string{


	  var imgUrl = this.dataSource.baseUrl + imageUrl

	 return imgUrl;
	}*/
  getImgUrl(dish: any): string {
    console.debug('[getImgUrl] dish:' + dish);
    let imgUrl;
    if (
      dish &&
      dish['dishPhotos'] &&
      dish['dishPhotos'].length != 0 &&
      dish['dishPhotos'][0] &&
      dish['dishPhotos'][0]['imageUrl'] != ''
    ) {
      imgUrl = this.dataSource.baseUrl + dish['dishPhotos'][0]['imageUrl'];
      console.debug('[getImgUrl] imgUrl', imgUrl);
      return imgUrl;
    } else {
      return 'assets/img/defaultImg.png';
    }
  }

  getTargetId(cat: any, nameDay: string, itemDish: any): string {
    var id = '#' + cat + nameDay + itemDish;

    return id.toString();
  }

  getId(cat: any, nameDay: string, itemDish: any): string {
    var id = cat + nameDay + itemDish;

    return id.toString();
  }

  /*  getOrderDishId(){
    	return this.orderDishId++;
    }
  */

  getOrderDishId(
    fromPage: string,
    shiftWeek: number,
    day: string,
    cat: number,
    dishPlace: number
  ) {
    /*console.debug("fromPage="+fromPage);
  		console.debug("shiftWeek="+shiftWeek);
  		console.debug("day="+day);
  		console.debug("dishPlace="+dishPlace);
  		console.debug("cat="+cat);*/

    var pageNumber: number;
    let orderDishId: number = 0;
    var week = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    switch (fromPage) {
      case 'frokostpakker': {
        pageNumber = 1;
        //	console.debug("frokostpakker");
        break;
      }
      case 'extra': {
        pageNumber = 2;
        //console.debug("extra");
        break;
      }
    }

    orderDishId = Number(
      pageNumber * 1000000 +
        (Number(this.dateService.getCurrentWeek()) + Number(shiftWeek)) * 1000 +
        Number(week.indexOf(day) * 1000) +
        cat * 100 +
        dishPlace
    );

    //console.debug("InorderDishId", orderDishId);
    return orderDishId;
  }

  updateCartFromLocalStorage() {
    this.cart.updateLinesFromLocalStorage(this.shiftWeekNumber);
  }

  accept() {
    this.router.navigateByUrl('/minmenu/cart');
  }

  getRowsNumberForDay(numberElementsInRow: number, dishesArraylength: number) {
    var rowsNumber = Math.trunc(dishesArraylength / numberElementsInRow);

    if (dishesArraylength % numberElementsInRow != 0) {
      rowsNumber++;
    }
    if (rowsNumber == 0) {
      rowsNumber = 1;
    }
    return rowsNumber;
  }

  getArrayDishesViewForDayController(
    numberElementsInRow: number,
    dishesArraylength: number
  ) {
    var rows = this.getRowsNumberForDay(numberElementsInRow, dishesArraylength);

    var arrayViewController = new Array(rows);
    for (var i = 0; i < arrayViewController.length; i++) {
      arrayViewController[i] = new Array(numberElementsInRow);
    }
    for (var i = 0; i < arrayViewController.length; i++) {
      for (var j = 0; j < arrayViewController[i].length; j++) {
        arrayViewController[i][j] = -1;
      }
    }

    var k = numberElementsInRow;
    var ref = 0;
    for (var i = 0; i < rows; i++) {
      if (dishesArraylength > numberElementsInRow) {
        if (i == rows - 1) {
          if (dishesArraylength % numberElementsInRow != 0) {
            k = dishesArraylength % numberElementsInRow;
            console.debug('k=' + k);

            arrayViewController[i].length = k; // trim last row to the number of items remainder
          }
        }
      } else {
        k = dishesArraylength;
        arrayViewController[i].length = k;
      }

      for (var j = 0; j < k; j++) {
        arrayViewController[i][j] = ref;
        ref++;
      }
    }

    return arrayViewController;
  }

  toggleMonday() {
    this.toggledMonday = true;
    this.toggledWednesday = false;
    this.toggledFriday = false;
  }

  toggleWednesday() {
    this.toggledMonday = false;
    this.toggledWednesday = true;
    this.toggledFriday = false;
  }

  toggleFriday() {
    this.toggledMonday = false;
    this.toggledWednesday = false;
    this.toggledFriday = true;
  }

  toggleTuesday() {
    this.toggledTuesday = true;
    this.toggledThursday = false;
    this.toggledSaturday = false;
    this.localStorageService.set('toggledDayExtra', 'Tuesday');
    this.templateDays = ['Tuesday'];
    this.needNgOninit = true;
    //this.needUnsubscribe = false;

    //this.localStorageService.set( 'toggledDayExtra', "Tuesday");
    this.router.navigateByUrl('/minmenu/extra');
  }

  toggleThursday() {
    this.toggledTuesday = false;
    this.toggledThursday = true;
    this.toggledSaturday = false;
    this.localStorageService.set('toggledDayExtra', 'Thursday');
    this.templateDays = ['Thursday'];
    this.needNgOninit = true;
    //this.needUnsubscribe = false;

    //this.localStorageService.set( 'toggledDayExtra', "Thursday");
    this.router.navigateByUrl('/minmenu/extra');
  }

  toggleSaturday() {
    this.toggledTuesday = false;
    this.toggledThursday = false;
    this.toggledSaturday = true;
    this.localStorageService.set('toggledDayExtra', 'Saturday');
    //this.templateDays = ["Saturday"];
    this.templateDays = ['Sunday'];
    this.needNgOninit = true;
    //this.needUnsubscribe = false;

    //this.localStorageService.set( 'toggledDayExtra', "Saturday");
    this.router.navigateByUrl('/minmenu/extra');
  }

  translateDayToDanish(day: string) {
    return this.localeDaDKService.translateDayToDanishsFull(day);
  }

  isDayForOrder(orderingDates: any[], nameDay: string, shiftWeek: number) {
    var isDayForOrderFlag = false;
    var date = this.dateService.getISODateYYYMMDDforDay(nameDay, shiftWeek);

    for (var day in this.orderingDates) {
      if (date == this.orderingDates[day]) {
        isDayForOrderFlag = true;
      }
    }

    //	return isDayForOrderFlag;

    return true; // !!!! Temporary
  }

  filterToogle() {
    this.filterOn = !this.filterOn;
  }

  /*isOrderingTime(userInfoOrderingModel:any[], categoryForFilter:string){

     	var startServingTime = "";
     	var endServingTime = "";
     	var currentTime=0;
     	for(var userCategory in userInfoOrderingModel["userCategories"]){

     		if(userInfoOrderingModel["userCategories"][userCategory]["category"]["name"]==categoryForFilter){

     			startServingTime = userInfoOrderingModel["userCategories"][userCategory]["startServingTime"];
     			var dateStart =  this.dateService.getDateFromTimeHHmmss(startServingTime);


     			endServingTime = userInfoOrderingModel["userCategories"][userCategory]["endServingTime"];

     			var dateEnd =  this.dateService.getDateFromTimeHHmmss(endServingTime);



     			currentTime = this.dateService.getCurrentTime();

     			if(this.dateService.returnCurrentDateForTimeHHmmss(dateStart)<=currentTime &&
     			currentTime<=this.dateService.returnCurrentDateForTimeHHmmss(dateEnd)){


     			return this.isOrderingTimeFlag=true;
     		//	}else return this.isOrderingTimeFlag=false;
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
     			}else return this.isOrderingTimeFlag=true;

     		}
     	}



     }
	*/

  isOrderingTime(userInfoOrderingModel: any[], categoryForFilter: string) {
    //	console.debug("In isOrderingTime");
    var startServingTime = '';
    var endServingTime = '';
    var currentTime = 0;
    for (var userCategory in userInfoOrderingModel['userCategories']) {
      //		console.debug("In isOrderingTime cat="+userInfoOrderingModel["userCategories"][userCategory]["category"]["name"]);
      if (
        userInfoOrderingModel['userCategories'][userCategory]['category'][
          'name'
        ] == categoryForFilter
      ) {
        startServingTime =
          userInfoOrderingModel['userCategories'][userCategory][
            'startServingTime'
          ];
        //	var dateStart =  this.dateService.getDateFromTimeHHmmss(startServingTime);

        //	console.debug("IsstartServingTime=" + this.dateService.returnCurrentDateForTimeHHmmss(dateStart));
        endServingTime =
          userInfoOrderingModel['userCategories'][userCategory][
            'endServingTime'
          ];

        //	var dateEnd =  this.dateService.getDateFromTimeHHmmss(endServingTime);

        //	console.debug("IsendServingTime=" + this.dateService.returnCurrentDateForTimeHHmmss(dateEnd));

        //	currentTime = this.dateService.getCurrentTime();
        //	console.debug("currentTime=" + currentTime);
        //	if(this.dateService.returnCurrentDateForTimeHHmmss(dateStart)<=currentTime &&
        //	currentTime<=this.dateService.returnCurrentDateForTimeHHmmss(dateEnd)){
        if (
          this.dateService.isCurrentTimeBetween(
            startServingTime,
            endServingTime
          )
        ) {
          return (this.isOrderingTimeFlag = true);
          //	}else return this.isOrderingTimeFlag=false;
        } else return (this.isOrderingTimeFlag = true);
      }
    }
  }

  openVerticallyCentered(content, itemDish: any, day: string) {
    console.log('[openVerticallyCentered] itemDish', itemDish);
    //---Start Spinner------------------------
    var loader = document.getElementById('loader');
    loader.style.visibility = 'visible';

    console.log(
      '[openVerticallyCentered] this.dishAllocationsOrderDays',
      this.dishAllocationsOrderDays
    );
    //let priceGroup = this.dishAllocationsOrderDays[day]['priceGroup'];
    const priceGroup = this.priceGroup;
    const productNr = itemDish['menuLine']['productNr'];
    const dietTypeNr = itemDish['menuLine']['dietTypeNr'];
    const portionSize = itemDish['menuLine']['portionSize'];

    this.dishRepository
      .getProductPrice(
        productNr,
        dietTypeNr,
        portionSize,
        priceGroup,
        this.shiftWeek,
        day
      )
      .subscribe(
        (response) => {
          console.log('response', response);
          if (response && response['salePrice']) {
            this.currentDishSalePrice = response['salePrice'];
          } else {
            this.currentDishSalePrice = 0;
          }
          //----------Stop spinner----------------------
          var loader = document.getElementById('loader');
          loader.style.visibility = 'hidden';

          this.modalService.open(content, { centered: true });
        },
        (error) => console.log('[getProductPrice] error', error)
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  toggleAll() {
    this.btnAllBackgroundColor = '#8FBF21';
    this.btnAllTextColor = '#FFFFFF';
    this.btnAllTextWeight = 'bold';
    this.toogledAllFlag = true;
    /*if(this.toogledAllFlag){
			this.toogledPastriesFlag= true;
			this.toogledCakesFlag= true;
			this.toogledCookiesFlag= true;
			this.toogledFlanFlag= true;
			this.toogledColonialFlag= true;
			this.toogledChilledFlag= true;
			this.toogledProteinDrinkFlag= true;

		}else{

			this.toogledPastriesFlag= false;
			this.toogledCakesFlag= false;
			this.toogledCookiesFlag= false;
			this.toogledFlanFlag= false;
			this.toogledColonialFlag= false;
			this.toogledChilledFlag= false;
			this.toogledProteinDrinkFlag= false;
		}*/
    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );
    console.debug(
      ' toggleAll=' + JSON.stringify(this.dishAllocationsOrderDaysAll)
    );
    this.localStorageService.set('toggledSubCategory', 'All');
  }

  //---------------NewSubCategory------------------------------------------
  toggleCategory(category: string) {
    console.debug('[toggleCategory]');

    /*switch(category){
 			case 'Tilvalg':
 			break;


 		}*/
    /*
 		this.btnAllBackgroundColor = '#8FBF21';
		this.btnAllTextColor = '#FFFFFF';
		this.btnAllTextWeight = 'bold';

 		this.toogledAllFlag = false;

 		this.toogledTilvalgFlag= true;*/
    this.currentToogledSubCategory = category;
    console.log(
      '[toggleCategory] this.currentToogledSubCategory',
      this.currentToogledSubCategory
    );
    /*
 		this.toogledKageFlag= false;
		this.toogledBasserFlag = false;
		this.toogledBollerFlag = false;

		this.toogledPastriesFlag = false;
		this.toogledCakesFlag= false;
		this.toogledCookiesFlag= false;
		this.toogledFlanFlag= false;
		this.toogledColonialFlag= false;
		this.toogledChilledFlag= false;
		this.toogledProteinDrinkFlag= false;
		*/

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Drikkevarer");

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(
        this.filterdishAllocationsOrderDaysByCategoryMenu(
          this.dishAllocationsOrderDays,
          category
        )
      )
    );

    //---------------------- get ArrayDishesViewController for Order Days------------

    this.lastIndex = 0;
    this.numberElementsInRow = 3;
    this.arrayDishesViewForDay = new Array();

    for (const day in this.dishAllocationsOrderDays) {
      if (
        this.dishAllocationsOrderDays[day] !== undefined &&
        this.dishAllocationsOrderDays[day] != [] &&
        this.dishAllocationsOrderDays[day]['menuLines'] != undefined
      ) {
        this.dishAllocationsOrderDays[
          day
        ].arrayDishesViewForDay = this.getArrayDishesViewForDayController(
          this.numberElementsInRow,
          this.dishAllocationsOrderDays[day]['menuLines'].length
        );
      }
    }

    // 	this.dishAllocationsOrderDaysAll = JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)); // save View All Dishes
    console.debug(
      '[toggleCategory] ViewController=' +
        JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //--------------------------------------------------------------------------------------------------------------
    //--------------------------------Update Local Storage From Response----------------------------------
    //let toggledDay = this.localStorageService.get( 'toggledDayExtra');
    //this.updateOrdersInLocalStorageFromResponse(toggledDay, JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)), "Tilvalg");

    this.updateOrdersInLocalStorageFromResponse(
      this.currentServingDay,
      JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)),
      category
    );
    //--------------------------------------------------------------------------------------------------------------

    this.orderNotPermitForSubCategory = this.checkPermittionsForSubCategory(
      category
    );
    console.debug(
      '[toggleCategory] toggleCategory=' +
        JSON.stringify(this.dishAllocationsOrderDays)
    );
    this.localStorageService.set(
      '[toggleCategory] toggledSubCategory',
      category
    );
  }

  toggleTilvalg() {
    console.debug('[toggleTilvalg]');

    /*this.btnAllBackgroundColor = '#FFFFFF';
 		this.btnAllTextColor = '#5B7B12';
 		this.btnAllTextWeight = 'normal';*/
    this.btnAllBackgroundColor = '#8FBF21';
    this.btnAllTextColor = '#FFFFFF';
    this.btnAllTextWeight = 'bold';

    this.toogledAllFlag = false;

    this.toogledTilvalgFlag = true;
    this.currentToogledSubCategory = 'Tilvalg';

    this.toogledKageFlag = false;
    this.toogledBasserFlag = false;
    this.toogledBollerFlag = false;

    this.toogledPastriesFlag = false;
    this.toogledCakesFlag = false;
    this.toogledCookiesFlag = false;
    this.toogledFlanFlag = false;
    this.toogledColonialFlag = false;
    this.toogledChilledFlag = false;
    this.toogledProteinDrinkFlag = false;

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Drikkevarer");

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(
        this.filterdishAllocationsOrderDaysByCategoryMenu(
          this.dishAllocationsOrderDays,
          'Tilvalg'
        )
      )
    );

    //---------------------- get ArrayDishesViewController for Order Days------------

    this.lastIndex = 0;
    this.numberElementsInRow = 3;
    this.arrayDishesViewForDay = new Array();

    for (const day in this.dishAllocationsOrderDays) {
      if (
        this.dishAllocationsOrderDays[day] !== undefined &&
        this.dishAllocationsOrderDays[day] != [] &&
        this.dishAllocationsOrderDays[day]['menuLines'] != undefined
      ) {
        this.dishAllocationsOrderDays[
          day
        ].arrayDishesViewForDay = this.getArrayDishesViewForDayController(
          this.numberElementsInRow,
          this.dishAllocationsOrderDays[day]['menuLines'].length
        );
      }
    }

    // 	this.dishAllocationsOrderDaysAll = JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)); // save View All Dishes
    console.debug(
      ' ViewController=' + JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //--------------------------------------------------------------------------------------------------------------
    //--------------------------------Update Local Storage From Response----------------------------------
    //let toggledDay = this.localStorageService.get( 'toggledDayExtra');
    //this.updateOrdersInLocalStorageFromResponse(toggledDay, JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)), "Tilvalg");

    this.updateOrdersInLocalStorageFromResponse(
      this.currentServingDay,
      JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)),
      'Tilvalg'
    );
    //--------------------------------------------------------------------------------------------------------------

    this.orderNotPermitForSubCategory = this.checkPermittionsForSubCategory(
      'Tilvalg'
    );
    console.debug(
      '[toggleTilvalg] toggleTilvalg=' +
        JSON.stringify(this.dishAllocationsOrderDays)
    );
    this.localStorageService.set('toggledSubCategory', 'Tilvalg');
  }

  toggleDogn() {
    console.log('[toggleDogn]');
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    this.toogledAllFlag = false;
    //--------------------------------------------------------------------
    //this.toogledDognFlag= true;
    this.currentToogledSubCategory = 'Tilvalg - Døgn';

    //this.toogledTilvalgFlag = false;
    this.toogledDognFlag = true;
    this.toogledKageBrodFlag = false;
    this.toogledFodselsdagsmenuFlag = false;
    this.toogledGastekokFlag = false;
    this.toogledJulemenuNytarsmenuFlag = false;
    //-------------------------------------------------------------------------------
    this.toogledKageFlag = false;
    this.toogledBasserFlag = false;
    this.toogledBollerFlag = false;

    this.toogledPastriesFlag = false;
    this.toogledCakesFlag = false;
    this.toogledCookiesFlag = false;
    this.toogledFlanFlag = false;
    this.toogledColonialFlag = false;
    this.toogledChilledFlag = false;
    this.toogledProteinDrinkFlag = false;

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Drikkevarer");

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(
        this.filterdishAllocationsOrderDaysByCategoryMenu(
          this.dishAllocationsOrderDays,
          'Tilvalg - Døgn'
        )
      )
    );

    //---------------------- get ArrayDishesViewController for Order Days------------

    this.lastIndex = 0;
    this.numberElementsInRow = 3;
    this.arrayDishesViewForDay = new Array();

    for (const day in this.dishAllocationsOrderDays) {
      if (
        this.dishAllocationsOrderDays[day] !== undefined &&
        this.dishAllocationsOrderDays[day] != [] &&
        this.dishAllocationsOrderDays[day]['menuLines'] != undefined
      ) {
        this.dishAllocationsOrderDays[
          day
        ].arrayDishesViewForDay = this.getArrayDishesViewForDayController(
          this.numberElementsInRow,
          this.dishAllocationsOrderDays[day]['menuLines'].length
        );
      }
    }

    // 	this.dishAllocationsOrderDaysAll = JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)); // save View All Dishes
    console.debug(
      ' ViewController=' + JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //--------------------------------------------------------------------------------------------------------------
    //--------------------------------Update Local Storage From Response----------------------------------
    //let toggledDay = this.localStorageService.get( 'toggledDayExtra');
    //this.updateOrdersInLocalStorageFromResponse(toggledDay, JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)), "Tilvalg - Døgn");
    this.updateOrdersInLocalStorageFromResponse(
      this.currentServingDay,
      JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)),
      'Tilvalg - Døgn'
    );
    //--------------------------------------------------------------------------------------------------------------
    this.orderNotPermitForSubCategory = this.checkPermittionsForSubCategory(
      'Tilvalg - Døgn'
    );
    console.debug(
      '[toggleDogn] toggleTilvalgDøgn=' +
        JSON.stringify(this.dishAllocationsOrderDays)
    );
    this.localStorageService.set('toggledSubCategory', 'Tilvalg - Døgn');
  }

  //----------------------------------Kage/Brød plan---------------------------------------
  toggleKageBrod() {
    console.log('[KageBrod]');
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    this.toogledAllFlag = false;

    //--------------------------------------------------------------------
    //this.toogledDognFlag= true;
    this.currentToogledSubCategory = 'Kage/Brød plan';

    //this.toogledTilvalgFlag = false;
    this.toogledDognFlag = false;
    this.toogledKageBrodFlag = true;
    this.toogledFodselsdagsmenuFlag = false;
    this.toogledGastekokFlag = false;
    this.toogledJulemenuNytarsmenuFlag = false;
    //-------------------------------------------------------------------------------

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Drikkevarer");

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(
        this.filterdishAllocationsOrderDaysByCategoryMenu(
          this.dishAllocationsOrderDays,
          'Kage/Brød plan'
        )
      )
    );

    //---------------------- get ArrayDishesViewController for Order Days------------

    this.lastIndex = 0;
    this.numberElementsInRow = 3;
    this.arrayDishesViewForDay = new Array();

    for (const day in this.dishAllocationsOrderDays) {
      if (
        this.dishAllocationsOrderDays[day] !== undefined &&
        this.dishAllocationsOrderDays[day] != [] &&
        this.dishAllocationsOrderDays[day]['menuLines'] != undefined
      ) {
        this.dishAllocationsOrderDays[
          day
        ].arrayDishesViewForDay = this.getArrayDishesViewForDayController(
          this.numberElementsInRow,
          this.dishAllocationsOrderDays[day]['menuLines'].length
        );
      }
    }

    // 	this.dishAllocationsOrderDaysAll = JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)); // save View All Dishes
    console.debug(
      '[KageBrod] ViewController=' +
        JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //--------------------------------------------------------------------------------------------------------------
    //--------------------------------Update Local Storage From Response----------------------------------
    //let toggledDay = this.localStorageService.get( 'toggledDayExtra');
    //this.updateOrdersInLocalStorageFromResponse(toggledDay, JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)), "Kage/Brød plan");
    this.updateOrdersInLocalStorageFromResponse(
      this.currentServingDay,
      JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)),
      'Kage/Brød plan'
    );
    //--------------------------------------------------------------------------------------------------------------
    this.orderNotPermitForSubCategory = this.checkPermittionsForSubCategory(
      'Kage/Brød plan'
    );
    console.debug(
      '[KageBrod] toggleTilvalgDøgn=' +
        JSON.stringify(this.dishAllocationsOrderDays)
    );
    this.localStorageService.set(
      '[KageBrod] toggledSubCategory',
      'Kage/Brød plan'
    );
  }

  //----------------------------------Fødselsdagsmenu---------------------------------------
  toggleFodselsdagsmenu() {
    console.log('[toggleFodselsdagsmenu]');
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    this.toogledAllFlag = false;

    //--------------------------------------------------------------------
    //this.toogledDognFlag= true;
    this.currentToogledSubCategory = 'Fødselsdagsmenu';

    //this.toogledTilvalgFlag = false;
    this.toogledDognFlag = false;
    this.toogledKageBrodFlag = false;
    this.toogledFodselsdagsmenuFlag = true;
    this.toogledGastekokFlag = false;
    this.toogledJulemenuNytarsmenuFlag = false;
    //-------------------------------------------------------------------------------

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Drikkevarer");

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(
        this.filterdishAllocationsOrderDaysByCategoryMenu(
          this.dishAllocationsOrderDays,
          'Fødselsdagsmenu'
        )
      )
    );

    //---------------------- get ArrayDishesViewController for Order Days------------

    this.lastIndex = 0;
    this.numberElementsInRow = 3;
    this.arrayDishesViewForDay = new Array();

    for (const day in this.dishAllocationsOrderDays) {
      if (
        this.dishAllocationsOrderDays[day] !== undefined &&
        this.dishAllocationsOrderDays[day] != [] &&
        this.dishAllocationsOrderDays[day]['menuLines'] != undefined
      ) {
        this.dishAllocationsOrderDays[
          day
        ].arrayDishesViewForDay = this.getArrayDishesViewForDayController(
          this.numberElementsInRow,
          this.dishAllocationsOrderDays[day]['menuLines'].length
        );
      }
    }

    // 	this.dishAllocationsOrderDaysAll = JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)); // save View All Dishes
    console.debug(
      '[toggleFodselsdagsmenu] ViewController=' +
        JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //--------------------------------------------------------------------------------------------------------------
    //--------------------------------Update Local Storage From Response----------------------------------
    //let toggledDay = this.localStorageService.get( 'toggledDayExtra');
    //this.updateOrdersInLocalStorageFromResponse(toggledDay, JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)), "Fødselsdagsmenu");
    this.updateOrdersInLocalStorageFromResponse(
      this.currentServingDay,
      JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)),
      'Fødselsdagsmenu'
    );
    //--------------------------------------------------------------------------------------------------------------
    this.orderNotPermitForSubCategory = this.checkPermittionsForSubCategory(
      'Fødselsdagsmenu'
    );
    console.debug(
      '[toggleFodselsdagsmenu] toggleTilvalgDøgn=' +
        JSON.stringify(this.dishAllocationsOrderDays)
    );
    this.localStorageService.set(
      '[toggleFodselsdagsmenu] toggledSubCategory',
      'Fødselsdagsmenu'
    );
  }

  //----------------------------------Gæstekok-------------------------------------------------
  toggleGastekok() {
    console.log('[toggleGastekok]');
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    this.toogledAllFlag = false;

    //--------------------------------------------------------------------
    //this.toogledDognFlag= true;
    this.currentToogledSubCategory = 'Gæstekok';

    //this.toogledTilvalgFlag = false;
    this.toogledDognFlag = false;
    this.toogledKageBrodFlag = false;
    this.toogledFodselsdagsmenuFlag = false;
    this.toogledGastekokFlag = true;
    this.toogledJulemenuNytarsmenuFlag = false;
    //-------------------------------------------------------------------------------

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Drikkevarer");

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(
        this.filterdishAllocationsOrderDaysByCategoryMenu(
          this.dishAllocationsOrderDays,
          'Gæstekok'
        )
      )
    );

    //---------------------- get ArrayDishesViewController for Order Days------------

    this.lastIndex = 0;
    this.numberElementsInRow = 3;
    this.arrayDishesViewForDay = new Array();

    for (const day in this.dishAllocationsOrderDays) {
      if (
        this.dishAllocationsOrderDays[day] !== undefined &&
        this.dishAllocationsOrderDays[day] != [] &&
        this.dishAllocationsOrderDays[day]['menuLines'] != undefined
      ) {
        this.dishAllocationsOrderDays[
          day
        ].arrayDishesViewForDay = this.getArrayDishesViewForDayController(
          this.numberElementsInRow,
          this.dishAllocationsOrderDays[day]['menuLines'].length
        );
      }
    }

    // 	this.dishAllocationsOrderDaysAll = JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)); // save View All Dishes
    console.debug(
      '[toggleGastekok] ViewController=' +
        JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //--------------------------------------------------------------------------------------------------------------
    //--------------------------------Update Local Storage From Response----------------------------------
    //let toggledDay = this.localStorageService.get( 'toggledDayExtra');
    //this.updateOrdersInLocalStorageFromResponse(toggledDay, JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)), "Gæstekok");
    this.updateOrdersInLocalStorageFromResponse(
      this.currentServingDay,
      JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)),
      'Gæstekok'
    );
    //--------------------------------------------------------------------------------------------------------------
    this.orderNotPermitForSubCategory = this.checkPermittionsForSubCategory(
      'Gæstekok'
    );
    console.debug(
      '[toggleGastekok] toggleTilvalgDøgn=' +
        JSON.stringify(this.dishAllocationsOrderDays)
    );
    this.localStorageService.set(
      '[toggleGastekok] toggledSubCategory',
      'Gæstekok'
    );
  }

  //----------------------------------Julemenu/ nytårsmenu-------------------------------------
  toggleJulemenuNytarsmenu() {
    console.log('[toggleJulemenuNytarsmenu]');
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    this.toogledAllFlag = false;

    //--------------------------------------------------------------------
    //this.toogledDognFlag= true;
    this.currentToogledSubCategory = 'Julemenu/ nytårsmenu';

    this.toogledTilvalgFlag = false;
    this.toogledDognFlag = false;
    this.toogledKageBrodFlag = false;
    this.toogledFodselsdagsmenuFlag = false;
    this.toogledGastekokFlag = false;
    this.toogledJulemenuNytarsmenuFlag = true;
    //-------------------------------------------------------------------------------

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Drikkevarer");

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(
        this.filterdishAllocationsOrderDaysByCategoryMenu(
          this.dishAllocationsOrderDays,
          'Julemenu/ nytårsmenu'
        )
      )
    );

    //---------------------- get ArrayDishesViewController for Order Days------------

    this.lastIndex = 0;
    this.numberElementsInRow = 3;
    this.arrayDishesViewForDay = new Array();

    for (const day in this.dishAllocationsOrderDays) {
      if (
        this.dishAllocationsOrderDays[day] !== undefined &&
        this.dishAllocationsOrderDays[day] != [] &&
        this.dishAllocationsOrderDays[day]['menuLines'] != undefined
      ) {
        this.dishAllocationsOrderDays[
          day
        ].arrayDishesViewForDay = this.getArrayDishesViewForDayController(
          this.numberElementsInRow,
          this.dishAllocationsOrderDays[day]['menuLines'].length
        );
      }
    }

    // 	this.dishAllocationsOrderDaysAll = JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)); // save View All Dishes
    console.debug(
      '[toggleJulemenuNytarsmenu] ViewController=' +
        JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //--------------------------------------------------------------------------------------------------------------
    //--------------------------------Update Local Storage From Response----------------------------------
    //let toggledDay = this.localStorageService.get( 'toggledDayExtra');
    //this.updateOrdersInLocalStorageFromResponse(toggledDay, JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)), "Julemenu/ nytårsmenu");

    this.updateOrdersInLocalStorageFromResponse(
      this.currentServingDay,
      JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)),
      'Julemenu/ nytårsmenu'
    );
    //--------------------------------------------------------------------------------------------------------------
    this.orderNotPermitForSubCategory = this.checkPermittionsForSubCategory(
      'Julemenu/ nytårsmenu'
    );
    console.debug(
      '[toggleJulemenuNytarsmenu] toggleJulemenuNytarsmenu=' +
        JSON.stringify(this.dishAllocationsOrderDays)
    );
    this.localStorageService.set('toggledSubCategory', 'Julemenu/ nytårsmenu');
  }

  //-------------------------------------------------------------------------------------

  toggleKage() {
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    this.toogledAllFlag = false;

    this.toogledKageFlag = true;
    this.toogledBasserFlag = false;
    this.toogledBollerFlag = false;

    this.toogledPastriesFlag = false;
    this.toogledCakesFlag = false;
    this.toogledCookiesFlag = false;
    this.toogledFlanFlag = false;
    this.toogledColonialFlag = false;
    this.toogledChilledFlag = false;
    this.toogledProteinDrinkFlag = false;

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Drikkevarer");

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(
        this.filterdishAllocationsOrderDaysBySubcategory(
          this.dishAllocationsOrderDays,
          'Kage'
        )
      )
    );
    console.debug(
      ' toggleKage=' + JSON.stringify(this.dishAllocationsOrderDays)
    );
    this.localStorageService.set('toggledSubCategory', 'Kage');
  }

  toggleBasser() {
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    this.toogledAllFlag = false;

    this.toogledKageFlag = false;
    this.toogledBasserFlag = true;
    this.toogledBollerFlag = false;

    this.toogledPastriesFlag = false;
    this.toogledCakesFlag = false;
    this.toogledCookiesFlag = false;
    this.toogledFlanFlag = false;
    this.toogledColonialFlag = false;
    this.toogledChilledFlag = false;
    this.toogledProteinDrinkFlag = false;

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Drikkevarer");

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(
        this.filterdishAllocationsOrderDaysBySubcategory(
          this.dishAllocationsOrderDays,
          'Basser'
        )
      )
    );
    console.debug(
      ' toggleBasser=' + JSON.stringify(this.dishAllocationsOrderDays)
    );
    this.localStorageService.set('toggledSubCategory', 'Basser');
  }

  toggleBoller() {
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    this.toogledAllFlag = false;

    this.toogledKageFlag = false;
    this.toogledBasserFlag = false;
    this.toogledBollerFlag = true;

    this.toogledPastriesFlag = false;
    this.toogledCakesFlag = false;
    this.toogledCookiesFlag = false;
    this.toogledFlanFlag = false;
    this.toogledColonialFlag = false;
    this.toogledChilledFlag = false;
    this.toogledProteinDrinkFlag = false;

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Drikkevarer");

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(
        this.filterdishAllocationsOrderDaysBySubcategory(
          this.dishAllocationsOrderDays,
          'Boller'
        )
      )
    );
    console.debug(
      ' toggleBoller=' + JSON.stringify(this.dishAllocationsOrderDays)
    );
    this.localStorageService.set('toggledSubCategory', 'Boller');
  }

  //-------------------------------------------------------------------------

  togglePastries() {
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    console.debug('In togglePastries');
    this.toogledAllFlag = false;
    this.toogledPastriesFlag = true;
    this.toogledCakesFlag = false;
    this.toogledCookiesFlag = false;
    this.toogledFlanFlag = false;
    this.toogledColonialFlag = false;
    this.toogledChilledFlag = false;
    this.toogledProteinDrinkFlag = false;

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Drikkevarer");
    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDaysAll,  "Bagværk");

    //console.debug(" Pastries="+JSON.stringify( this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Mellemmåltid")));
    //console.debug(" togglePastries="+JSON.stringify(this.dishAllocationsOrderDaysAll));
  }
  toggleCakes() {
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    this.toogledAllFlag = false;
    this.toogledPastriesFlag = false;
    this.toogledCakesFlag = true;
    this.toogledCookiesFlag = false;
    this.toogledFlanFlag = false;
    this.toogledColonialFlag = false;
    this.toogledChilledFlag = false;
    this.toogledProteinDrinkFlag = false;

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Drikkevarer");

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDaysAll,  "Kager");
  }
  toggleCookies() {
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    this.toogledAllFlag = false;
    this.toogledPastriesFlag = false;
    this.toogledCakesFlag = false;
    this.toogledCookiesFlag = true;
    this.toogledFlanFlag = false;
    this.toogledColonialFlag = false;
    this.toogledChilledFlag = false;
    this.toogledProteinDrinkFlag = false;
    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Mellemmåltid");

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDaysAll,  "Småkager");
  }
  toggleFlan() {
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    this.toogledAllFlag = false;
    this.toogledPastriesFlag = false;
    this.toogledCakesFlag = false;
    this.toogledCookiesFlag = false;
    this.toogledFlanFlag = true;
    this.toogledColonialFlag = false;
    this.toogledChilledFlag = false;
    this.toogledProteinDrinkFlag = false;
    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(
      this.dishAllocationsOrderDays,
      'Drikkevarer'
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDaysAll,  "Skærekage");
  }
  toggleColonial() {
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    this.toogledAllFlag = false;
    this.toogledPastriesFlag = false;
    this.toogledCakesFlag = false;
    this.toogledCookiesFlag = false;
    this.toogledFlanFlag = false;
    this.toogledColonialFlag = true;
    this.toogledChilledFlag = false;
    this.toogledProteinDrinkFlag = false;

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Mellemmåltid");

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDaysAll,  "Kolonial");
  }
  toggleChilled() {
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    this.toogledAllFlag = false;
    this.toogledPastriesFlag = false;
    this.toogledCakesFlag = false;
    this.toogledCookiesFlag = false;
    this.toogledFlanFlag = false;
    this.toogledColonialFlag = false;
    this.toogledChilledFlag = true;
    this.toogledProteinDrinkFlag = false;

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(
      this.dishAllocationsOrderDays,
      'Drikkevarer'
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDaysAll,  "Kølevarer");
  }
  toggleProteinDrink() {
    this.btnAllBackgroundColor = '#FFFFFF';
    this.btnAllTextColor = '#5B7B12';
    this.btnAllTextWeight = 'normal';

    this.toogledAllFlag = false;
    this.toogledPastriesFlag = false;
    this.toogledCakesFlag = false;
    this.toogledCookiesFlag = false;
    this.toogledFlanFlag = false;
    this.toogledColonialFlag = false;
    this.toogledChilledFlag = false;
    this.toogledProteinDrinkFlag = true;
    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDays,  "Mellemmåltid");

    //this.dishAllocationsOrderDays = this.filterdishAllocationsOrderDaysBySubcategory(this.dishAllocationsOrderDaysAll,  "Proteindrik");
  }

  dishInfo() {
    this.modalBtnBackgroundColor = '#FFFFFF';
    this.modalBtnTextColor = '#000000';
    this.modalBtnTextWeight = 'bold';
    this.modalBtnFlag = 'dishInfo';
    //console.debug("this.modalBtnFlag", this.modalBtnFlag);
  }

  dishRecipe() {
    this.modalBtnBackgroundColor = '#5B7B12';
    this.modalBtnTextColor = '#FFFFFF';
    this.modalBtnTextWeight = 'normal';
    this.modalBtnFlag = 'dishRecipe';
    //console.debug("this.modalBtnFlag", this.modalBtnFlag);
  }

  dishVideo() {
    this.modalBtnBackgroundColor = '#5B7B12';
    this.modalBtnTextColor = '#FFFFFF';
    this.modalBtnTextWeight = 'normal';
    this.modalBtnFlag = 'dishVideo';
    //console.debug("this.modalBtnFlag", this.modalBtnFlag);
  }

  //------------------------------Check Permittions-------------------------------------
  //-----------------------------Check Permittions for Order ---------------------------------
  checkPermittionsForOrder(
    deadlineStatus: boolean,
    orderPermitStatus: boolean,
    orderOperation: number
  ) {
    console.log('[checkPermittionsForOrder] In checkPermittionsForOrder');
    console.log('[checkPermittionsForOrder] deadlineStatus', deadlineStatus);
    console.log(
      '[checkPermittionsForOrder] orderPermitStatus',
      orderPermitStatus
    );
    if (!deadlineStatus && orderPermitStatus && orderOperation != 0) {
      return false;
    }

    return true;
  }

  //------------------------------Check Permittions for Day ----------------------------------------------
  checkPermittionsForDay(dishAllocationsWeekResponse: any) {
    let dayNotPermit: boolean = true;
    const permitDayArr = [];

    if (
      dishAllocationsWeekResponse &&
      dishAllocationsWeekResponse[0]['menus']
    ) {
      for (const itemMenus in dishAllocationsWeekResponse[0]['menus']) {
        permitDayArr.push(
          this.checkPermittionsForOrder(
            dishAllocationsWeekResponse[0]['menus'][itemMenus][
              'deadlineStatus'
            ],
            dishAllocationsWeekResponse[0]['menus'][itemMenus][
              'orderPermitStatus'
            ],
            dishAllocationsWeekResponse[0]['menus'][itemMenus]['orderOperation']
          )
        );
      }
    }
    dayNotPermit = permitDayArr.every((itemPermitDay) => itemPermitDay == true);
    console.debug(
      '[checkPermittionsForSubCategory] dayNotPermit',
      dayNotPermit
    );

    return dayNotPermit;
  }
  //------------------------------Check Permittions for SubCategory ----------------------------------------------
  checkPermittionsForSubCategory(subCategory: string) {
    return this.getPermittionsFromDataControlByMenuName(subCategory);
  }
  //-----------------Disable Image Batton +/- if no Permittions to order------------------------
  getSubBtnUrl() {
    let btnImageUrl;
    if (this.orderNotPermitForDay || this.orderNotPermitForSubCategory) {
      btnImageUrl = 'assets/img/SymbolSubBtnH.png';
    } else {
      btnImageUrl = 'assets/img/SymboSubBtn.png';
    }
    return btnImageUrl;
  }

  getAddBtnUrl() {
    let btnImageUrl;
    if (this.orderNotPermitForDay || this.orderNotPermitForSubCategory) {
      btnImageUrl = 'assets/img/SymbolBtnH.png';
    } else {
      btnImageUrl = 'assets/img/SymbolBackBtn.png';
    }
    return btnImageUrl;
  }

  isOrderNotPermit() {
    console.debug('[isOrderNotPermit]');
    let isOrderNotPermit: boolean = true;

    for (const key of this.mapDataControl.keys()) {
      const itemDataControlForElement = this.mapDataControl.get(key);
      if (
        !this.checkPermittionsForOrder(
          itemDataControlForElement['deadlineStatus'],
          itemDataControlForElement['orderPermitStatus'],
          itemDataControlForElement['orderOperation']
        )
      ) {
        isOrderNotPermit = false;
        break;
      }
    }

    console.debug('[isOrderNotPermit] isOrderNotPermit', isOrderNotPermit);
    return isOrderNotPermit;
  }
}
