import { Injectable } from '@angular/core';
import { Component, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  ActivationEnd,
  Event,
  NavigationCancel,
  NavigationError,
  NavigationStart,
} from '@angular/router';
import { LocalStorageService } from '../../../service/localStorage.service';

import { Cart } from '../../../cart/cart-model/cart.model';
import { DateService } from '../../../service/date.service';
import { LocaleDaDKService } from '../../../service/locale-da-dk.service';
import { AllocatedDishModel } from '../../../model/allocatedDishModel.model';
import { OrderDish } from '../../../model/orderDish.model';
import { DishAllocationWeekDataBase } from '../../../model/dishAllocationWeek.database';
import { DishRepository } from '../../../repository/dish.repository';
import { RestDataSource } from '../../../repository/rest.datasource';
import { ModelResolver } from '../../../resolver/model.resolver';
import { MenuLine } from '../../../model/menuLine.model';
import { timer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { of, from } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/concatAll';
import 'rxjs/add/operator/concatMap';
import { map } from 'rxjs/operators';
import { _ } from 'underscore';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { SafePipeModule } from 'safe-pipe';
import * as moment from 'moment';
import { DataShareService } from '../../../service/dataShare.service';

class DishAllocationsOrderDays {
  Sunday: any[];
  Monday: any[];
  Tuesday: any[];
  Wednesday: any[];
  Thursday: any[];
  Friday: any[];
  Saturday: any[];

  constructor() {
    this.Sunday = [];
    this.Monday = [];
    this.Tuesday = [];
    this.Wednesday = [];
    this.Thursday = [];
    this.Friday = [];
    this.Saturday = [];
  }
}

class MenuLineArrayOrderDays {
  Sunday: MenuLine[];
  Monday: MenuLine[];
  Tuesday: MenuLine[];
  Wednesday: MenuLine[];
  Thursday: MenuLine[];
  Friday: MenuLine[];
  Saturday: MenuLine[];

  constructor() {
    this.Sunday = [];
    this.Monday = [];
    this.Tuesday = [];
    this.Wednesday = [];
    this.Thursday = [];
    this.Friday = [];
    this.Saturday = [];
  }
}

class SimpleOderModel {
  //orderDishId:string;
  orderDishId: number;
  orderNumber: number;
  sumOder: number;
  Day: string;
  dishId: number;
  name: string;
  imageUrl: string;
  category: string;
  subcategory: string;

  constructor(
    orderDishId: number,
    orderNumber: number,
    sumOder: number,
    Day: string,
    dishId: number,
    name: string,
    imageUrl: string,
    category: string,
    subcategory: string
  ) {
    this.orderDishId = orderDishId;
    this.orderNumber = orderNumber;
    this.sumOder = sumOder;
    this.Day = Day;
    this.dishId = dishId;
    this.name = name;
    this.imageUrl = imageUrl;
    this.category = category;
    this.subcategory = subcategory;
  }
}

class SelectedWeekForPrintModel {
  week_0: string;

  week_1: string;

  week_2: string;

  week_3: string;

  week_4: string;

  constructor() {
    this.week_0 = undefined;

    this.week_1 = undefined;

    this.week_2 = undefined;

    this.week_3 = undefined;

    this.week_4 = undefined;
  }
}

class MainSideArr {
  mainDish: any[];
  sideDish: any[];

  constructor() {
    this.mainDish = [];
    this.sideDish = [];
  }
}

class DishMainSideOrderDays {
  Sunday: MainSideArr;
  Monday: MainSideArr;
  Tuesday: MainSideArr;
  Wednesday: MainSideArr;
  Thursday: MainSideArr;
  Friday: MainSideArr;
  Saturday: MainSideArr;

  constructor(
    Sunday: MainSideArr,
    Monday: MainSideArr,
    Tuesday: MainSideArr,
    Wednesday: MainSideArr,
    Thursday: MainSideArr,
    Friday: MainSideArr,
    Saturday: MainSideArr
  ) {
    this.Sunday = Sunday;
    this.Monday = Monday;
    this.Tuesday = Tuesday;
    this.Wednesday = Wednesday;
    this.Thursday = Thursday;
    this.Friday = Friday;
    this.Saturday = Saturday;
  }
}
class MainDishOnlyPrintDays {
  Tuesday: boolean;
  Thursday: boolean;
  Sunday: boolean;

  constructor() {
    this.Tuesday = false;
    this.Thursday = false;
    this.Sunday = false;
  }
}

@Component({
  templateUrl: 'printchoosebulk.component.html',
  styleUrls: ['printchoosebulk.component.css'],
})
export class PrintChooseBulkComponent {
  public sumOder: number;
  public weeks: string[];
  public dishAllocationsWeekResponse: any[] = [];
  public currentWeek: string;
  public lastIndex: number;
  obsSubscription: Subscription;
  public numberElementsInRow: number;
  public arrayDishesViewForDay: any[];
  public orderDishId: number;
  public orderDaysLeftInWeekArray: string[];
  public dishAllocationsOrderDays: DishAllocationsOrderDays;
  public dishAllocationsOrderDaysMainDishes: DishAllocationsOrderDays;
  public dishAllocationsOrderDaysSideDishes: DishAllocationsOrderDays;
  public dishMainSideOrderDays: DishMainSideOrderDays;
  public mainSideArr: MainSideArr;
  public currentToogledDay: string;
  public toggledMonday: boolean;
  public toggledWednesday: boolean;
  public toggledFriday: boolean;
  public toggledTuesday: boolean;
  public toggledThursday: boolean;
  public toggledSaturday: boolean;
  public SundayDate: string;
  public SundayNextWeekDate: string;
  public MondayDate: string;
  public MondayNextWeekDate: string;
  public TuesdayDate: string;
  public WednesdayDate: string;
  public ThursdayDate: string;
  public FridayDate: string;
  public SaturdayDate: string;
  public shiftWeekNumber: string;
  public shiftWeek: number;
  public needNgOninit: boolean;
  public simpleOderModelArray: SimpleOderModel[];
  public imgUrl: string;
  public tempArray: any[] = [];
  public categoryForFilter: any;
  public simpleOderModelArrayId: string;
  public orderingDates: any[];
  public userInfoOrderingModel: any[] = [];
  public currentDayNumber: number;
  public filterOn: boolean;
  public isOrderingTimeFlag: boolean;
  public isEmptyMonday: boolean;
  public isEmptyTuesday: boolean;
  public isEmptyWednesday: boolean;
  public isEmptyThursday: boolean;
  public isEmptyFriday: boolean;
  public isEmptySaturday: boolean;
  public isEmptySunday: boolean;
  private modalBtnFlag: string;
  public safeSrc: SafeResourceUrl;
  public modalBtnBackgroundColor: string;
  public modalBtnTextColor: string;
  public modalBtnTextWeight: string;
  public ifWeHaveCategory: boolean;
  private needUnsubscribe: boolean;
  public menuLineArrForFuldkost: MenuLineArrayOrderDays;
  public menuLineArrForEnrgitat: MenuLineArrayOrderDays;
  public menuLineArrForBlod: MenuLineArrayOrderDays;
  public menuLineArrForAndet: MenuLineArrayOrderDays;
  public menuLineArrForCategoryTemp: any[] = [];
  public contentFuldkostAntal: number = 0;
  public contentEnergitatAntalArr: number[] = [];
  public contentBlodAntalArr: number[] = [];
  public contentAndetAntalArr: number[] = [];
  public contentAndetAntalArr2: number[] = [];
  public currentDayForModal: string;
  public dishAllocationsOrderDaysInitTuesday: any;
  public dishAllocationsOrderDaysInitThursday: any;
  public dishAllocationsOrderDaysInitSaturday: any;
  public mainMenuLineTuesday: number;
  public mainMenuLineWednesday: number;
  public mainMenuLineThursday: number;
  public mainMenuLineFriday: number;
  public mainMenuLineSaturday: number;
  public mainMenuLineSunday: number;
  public mainMenuLineMonday: number;
  private departmentId: number;
  closeResult: string;
  public selectedKodFuldkost: string;
  public orderFromViewWeekPlan: boolean;
  public templateDays: string[];
  public servingDays: string[];
  public templateWeeks: string[];
  public isWeeks: boolean;
  public selectedWeekForPrintModel: any[] = [];
  public selectedWeekForPrintModelArr: any[] = [];
  public selectedWeeksArr: any[] = [];
  public selectedWeeksMainDishesArr: any[] = [];
  public selectedWeeksSideDishesArr: any[] = [];
  public shiftMonth: number;
  public isfAfterPageLoaded: boolean;
  public printViewWekplanAll: boolean;
  public mainDishOnlyPrintDays: MainDishOnlyPrintDays;

  constructor(
    private dataSource: RestDataSource,
    private localStorageService: LocalStorageService,
    private cart: Cart,
    private router: Router,
    private dateService: DateService,
    private dishRepository: DishRepository,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private dataShareService: DataShareService,
    private localeDaDKService: LocaleDaDKService
  ) {
    //this.localStorageService.set( 'currentChildRoute', "print/printweekplan");
    this.localStorageService.set(
      'currentChildRoute',
      'bulkorder/printweekplan/printchoosebulk'
    );
    this.localStorageService.set(
      'pageForOrderChildRoute',
      'bulkorder/viewweekplan'
    );
    //this.needUnsubscribe = false;
    this.isfAfterPageLoaded = true;
  }

  ngOnInit(): void {
    this.dataShareService.dispayIfPageForPrinting = 'none';

    this.ifWeHaveCategory = false;
    this.needNgOninit = false;
    this.orderFromViewWeekPlan = true;

    //------------------Main MenuLines Init ---------------------------------

    this.mainMenuLineTuesday = 0;
    this.mainMenuLineWednesday = 0;
    this.mainMenuLineThursday = 0;
    this.mainMenuLineFriday = 0;
    this.mainMenuLineSaturday = 0;
    this.mainMenuLineSunday = 0;
    this.mainMenuLineMonday = 0;

    //-----------------------------Depatments --------------------------------------------
    this.departmentId = this.localStorageService.get('currentdepartmentId');

    //-----------------------PrintAll-------------------------------------------------
    this.printViewWekplanAll = this.localStorageService.get(
      'printViewWekplanAll'
    );
    console.debug(
      '[ngOnInit] this.printViewWekplanAll',
      this.printViewWekplanAll
    );
    //-------------------NavTabs init ------------------------------------

    let toggledDay = this.localStorageService.get('toggledChooseDayBulkOrder');

    //---------------------Date------------------------------------
    this.shiftWeek = this.localStorageService.get(
      'shiftWeek' + this.localStorageService.get('parentPrintRoute')
    );

    console.log('this.shiftWeek=' + this.shiftWeek);
    this.currentDayNumber = this.dateService.getCurrentDayNumber();

    this.currentWeek = this.dateService.getCurrentWeek();

    this.shiftWeekNumber = this.dateService.getShiftWeek(this.shiftWeek);

    this.SundayDate = this.dateService.getISODateDDMMMforDay(
      'Sunday',
      this.shiftWeek
    );
    this.MondayDate = this.dateService.getISODateDDMMMforDay(
      'Monday',
      this.shiftWeek
    );
    this.SundayNextWeekDate = this.dateService.getISODateDDMMMforDay(
      'Sunday',
      //this.shiftWeek + 1
      this.shiftWeek
    );
    this.MondayNextWeekDate = this.dateService.getISODateDDMMMforDay(
      'Monday',
      this.shiftWeek + 1
    );
    this.TuesdayDate = this.dateService.getISODateDDMMMforDay(
      'Tuesday',
      this.shiftWeek
    );
    this.WednesdayDate = this.dateService.getISODateDDMMMforDay(
      'Wednesday',
      this.shiftWeek
    );
    this.ThursdayDate = this.dateService.getISODateDDMMMforDay(
      'Thursday',
      this.shiftWeek
    );
    this.FridayDate = this.dateService.getISODateDDMMMforDay(
      'Friday',
      this.shiftWeek
    );
    this.SaturdayDate = this.dateService.getISODateDDMMMforDay(
      'Saturday',
      this.shiftWeek
    );

    this.shiftMonth = this.localStorageService.get(
      'shiftMonth' + this.localStorageService.get('currentChildRoute')
    );
    if (this.shiftMonth == undefined || this.shiftMonth == null) {
      this.shiftMonth = 0;
    }
    //--------------------- GET dishAllocations for All days in Week-----------------------------
    if (!this.needUnsubscribe) {
      this.obsSubscription = this.activatedRoute.data.subscribe((data) => {
        this.dishAllocationsWeekResponse = [];
        this.dishAllocationsOrderDaysInitTuesday = [];
        this.dishAllocationsOrderDaysInitThursday = [];
        this.dishAllocationsOrderDaysInitSaturday = [];
        this.dishAllocationsOrderDays = new DishAllocationsOrderDays();
        this.dishAllocationsOrderDaysMainDishes = new DishAllocationsOrderDays();
        this.dishAllocationsOrderDaysSideDishes = new DishAllocationsOrderDays();
        let mainSideArr = new MainSideArr();
        this.dishMainSideOrderDays = new DishMainSideOrderDays(
          mainSideArr,
          mainSideArr,
          mainSideArr,
          mainSideArr,
          mainSideArr,
          mainSideArr,
          mainSideArr
        );
        this.isWeeks = false;
        this.selectedWeekForPrintModel = [];
        this.dishAllocationsWeekResponse = data['dishes'];
        console.log(
          'dishAllocationsWeekResponse=' +
            JSON.stringify(this.dishAllocationsWeekResponse)
        );

        this.mainDishOnlyPrintDays = new MainDishOnlyPrintDays();
        this.mainDishOnlyPrintDays = this.localStorageService.get(
          'mainDishOnlyPrintDays' +
            this.localStorageService.get('parentPrintRoute')
        );

        let toggledDay1 = this.localStorageService.get(
          'toggledChooseDayBulkOrder'
        );
        //-------------------------------------------Order------------------------------------------------------

        this.isOrderingTimeFlag = false;
        this.simpleOderModelArrayId =
          'simpleOderModelArray' +
          this.localStorageService.get('currentChildRoute') +
          this.shiftWeekNumber +
          this.departmentId;
        this.simpleOderModelArray = this.localStorageService.get(
          this.simpleOderModelArrayId
        );
        console.log('this.simpleOderModelArray', this.simpleOderModelArray);
        if (
          this.simpleOderModelArray == null ||
          this.simpleOderModelArray == undefined
        ) {
          this.simpleOderModelArray = new Array();
          this.sumOder = 0;
        } else {
          for (var oder in this.simpleOderModelArray) {
            this.sumOder += this.simpleOderModelArray[oder].sumOder;
          }
        }

        switch (toggledDay1) {
          case 'Tuesday':
            this.toggledTuesday = true;
            this.toggledThursday = false;
            this.toggledSaturday = false;
            if (!this.printViewWekplanAll) {
              this.templateDays = ['Tuesday'];
            } else {
              this.templateDays = ['Tuesday'];
            }
            if (this.dishAllocationsWeekResponse[0]) {
              if (this.orderFromViewWeekPlan) {
                this.dishAllocationsOrderDaysInitTuesday = JSON.parse(
                  JSON.stringify(
                    this.dishAllocationsWeekResponse[0]['bulkDishes']
                  )
                );
                console.log(
                  'this.dishAllocationsWeekResponse[0]',
                  JSON.stringify(
                    this.dishAllocationsWeekResponse[0]['bulkDishes']
                  )
                );
                console.log(
                  'this.dishAllocationsOrderDaysInitTuesday',
                  this.dishAllocationsOrderDaysInitTuesday
                );
                this.dishAllocationsOrderDays[
                  'Tuesday'
                ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];

                if (!this.printViewWekplanAll) {
                  this.dishAllocationsOrderDays[
                    'Tuesday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Tuesday']
                  );
                }

                this.dishAllocationsOrderDays[
                  'Tuesday'
                ] = this.sortMenuLineArrForCategory(
                  this.dishAllocationsOrderDays['Tuesday']
                );
                this.dishMainSideOrderDays[
                  'Tuesday'
                ] = this.divideByMainSideDishes(
                  this.dishAllocationsOrderDays['Tuesday'],
                  'Tuesday',
                  this.printViewWekplanAll,
                  this.mainDishOnlyPrintDays['Tuesday']
                );
                //------------------Wednesday is the dame------------------------
                this.dishMainSideOrderDays[
                  'Wednesday'
                ] = this.divideByMainSideDishes(
                  this.dishAllocationsOrderDays['Tuesday'],
                  'Tuesday',
                  this.printViewWekplanAll,
                  this.mainDishOnlyPrintDays['Tuesday']
                );

                console.log(
                  'this.dishAllocationsOrderDaysTuesday',
                  JSON.stringify(this.dishAllocationsOrderDays)
                );
              } else {
                this.dishAllocationsOrderDays[
                  'Tuesday'
                ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];
              }

              if (_.isEmpty(this.dishAllocationsOrderDays['Tuesday'])) {
                console.log(
                  'this.dishAllocationsOrderDays[\'Tuesday\'].length==0'
                );
                this.isEmptyTuesday = true;
              }
            }
            if (this.dishAllocationsWeekResponse[0]) {
              if (this.orderFromViewWeekPlan) {
                this.dishAllocationsOrderDays['Wednesday'] = JSON.parse(
                  JSON.stringify(this.dishAllocationsOrderDaysInitTuesday)
                );
                console.log(
                  'this.dishAllocationsOrderDaysInitTuesday',
                  this.dishAllocationsOrderDaysInitTuesday
                );
                console.log(
                  'this.dishAllocationsOrderDaysWednesday',
                  this.dishAllocationsOrderDays['Wednesday']
                );
                if (!this.printViewWekplanAll) {
                  this.dishAllocationsOrderDays[
                    'Wednesday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Wednesday']
                  );
                }
                this.isEmptyWednesday = true; // !!!!!!!!!
                console.log(
                  'this.dishAllocationsOrderDaysWednesday',
                  JSON.stringify(this.dishAllocationsOrderDays)
                );
              } else {
                this.dishAllocationsOrderDays[
                  'Wednesday'
                ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];
              }

              if (_.isEmpty(this.dishAllocationsOrderDays['Wednesday'])) {
                console.log(
                  'this.dishAllocationsOrderDays[\'Wednesday\'].length==0'
                );
                this.isEmptyWednesday = true;
              }
            }

            console.log(
              'dishAllocationsOrderDaysTW=' +
                JSON.stringify(this.dishAllocationsOrderDays)
            );

            break;
          case 'Thursday':
            this.toggledTuesday = false;
            this.toggledThursday = true;
            this.toggledSaturday = false;
            if (!this.printViewWekplanAll) {
              this.templateDays = ['Thursday'];
            } else {
              this.templateDays = ['Thursday'];
            }
            if (this.dishAllocationsWeekResponse[0]) {
              if (this.orderFromViewWeekPlan) {
                this.dishAllocationsOrderDaysInitThursday = JSON.parse(
                  JSON.stringify(
                    this.dishAllocationsWeekResponse[0]['bulkDishes']
                  )
                );
                console.log(
                  'this.dishAllocationsWeekResponse[0]Thursday',
                  JSON.stringify(
                    this.dishAllocationsWeekResponse[0]['bulkDishes']
                  )
                );
                this.dishAllocationsOrderDays[
                  'Thursday'
                ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];
                if (!this.printViewWekplanAll) {
                  this.dishAllocationsOrderDays[
                    'Thursday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Thursday']
                  );
                  console.log(
                    'this.productNrForDaysimpleOderModelArrayFilter',
                    this.productNrForDaysimpleOderModelArrayFilter(
                      this.simpleOderModelArray,
                      'Thursday'
                    )
                  );
                }
                this.dishAllocationsOrderDays[
                  'Thursday'
                ] = this.sortMenuLineArrForCategory(
                  this.dishAllocationsOrderDays['Thursday']
                );

                this.dishMainSideOrderDays[
                  'Thursday'
                ] = this.divideByMainSideDishes(
                  this.dishAllocationsOrderDays['Thursday'],
                  'Thursday',
                  this.printViewWekplanAll,
                  this.mainDishOnlyPrintDays['Thursday']
                );

                //------------------------Thursday and Saturday is the same-----------
                this.dishMainSideOrderDays[
                  'Friday'
                ] = this.divideByMainSideDishes(
                  this.dishAllocationsOrderDays['Thursday'],
                  'Thursday',
                  this.printViewWekplanAll,
                  this.mainDishOnlyPrintDays['Thursday']
                );
                this.dishMainSideOrderDays[
                  'Saturday'
                ] = this.divideByMainSideDishes(
                  this.dishAllocationsOrderDays['Thursday'],
                  'Thursday',
                  this.printViewWekplanAll,
                  this.mainDishOnlyPrintDays['Thursday']
                );

                console.log(
                  'this.dishAllocationsOrderDaysThursday',
                  JSON.stringify(this.dishAllocationsOrderDays)
                );
              } else {
                this.dishAllocationsOrderDays[
                  'Thursday'
                ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];
              }
              if (_.isEmpty(this.dishAllocationsOrderDays['Thursday'])) {
                console.log(
                  'this.dishAllocationsOrderDays[\'Thursday\'].length==0'
                );
                this.isEmptyThursday = true;
              }
            }

            if (this.dishAllocationsWeekResponse[0]) {
              if (this.orderFromViewWeekPlan) {
                this.dishAllocationsOrderDays['Friday'] = JSON.parse(
                  JSON.stringify(this.dishAllocationsOrderDaysInitThursday)
                );
                if (!this.printViewWekplanAll) {
                  this.dishAllocationsOrderDays[
                    'Friday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Friday']
                  );
                }
                this.isEmptyFriday = true; // !!!!!!!!!
                this.dishAllocationsOrderDays['Friday'] = [];
                console.log(
                  'this.dishAllocationsOrderDaysFriday',
                  JSON.stringify(this.dishAllocationsOrderDays)
                );
              } else {
                this.dishAllocationsOrderDays[
                  'Friday'
                ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];
              }

              if (_.isEmpty(this.dishAllocationsOrderDays['Friday'])) {
                console.log(
                  'this.dishAllocationsOrderDays[\'Friday\'].length==0'
                );
                this.isEmptyFriday = true;
              }
            }

            if (this.dishAllocationsWeekResponse[0]) {
              if (this.orderFromViewWeekPlan) {
                this.dishAllocationsOrderDays['Saturday'] = JSON.parse(
                  JSON.stringify(this.dishAllocationsOrderDaysInitThursday)
                );
                console.log(
                  'this.dishAllocationsOrderDays[Saturday]',
                  JSON.stringify(this.dishAllocationsOrderDays['Saturday'])
                );
                if (!this.printViewWekplanAll) {
                  this.dishAllocationsOrderDays[
                    'Saturday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Saturday']
                  );
                }
                this.isEmptySaturday = true; // !!!!!!!!!
                this.dishAllocationsOrderDays['Saturday'] = [];

                console.log(
                  'this.dishAllocationsOrderDaysSunday',
                  JSON.stringify(this.dishAllocationsOrderDays)
                );
              } else {
                this.dishAllocationsOrderDays[
                  'Saturday'
                ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];
              }

              if (_.isEmpty(this.dishAllocationsOrderDays['Saturday'])) {
                console.log(
                  'this.dishAllocationsOrderDays[\'Saturday\'].length==0'
                );

                this.isEmptySaturday = true;
              }
            }

            console.log(
              'dishAllocationsOrderDaysTS=' +
                JSON.stringify(this.dishAllocationsOrderDays)
            );

            break;
          case 'Saturday':
            //console.log("Saturday");
            this.toggledTuesday = false;
            this.toggledThursday = false;
            this.toggledSaturday = true;
            if (!this.printViewWekplanAll) {
              this.templateDays = ['Saturday'];
            } else {
              //this.templateDays = ["Sunday", "Monday"];
              this.templateDays = ['Sunday'];
            }
            if (this.dishAllocationsWeekResponse[0]) {
              if (this.orderFromViewWeekPlan) {
                console.log(
                  'this.dishAllocationsWeekResponse[0]Sunday',
                  JSON.stringify(
                    this.dishAllocationsWeekResponse[0]['bulkDishes']
                  )
                );

                this.dishAllocationsOrderDaysInitSaturday = JSON.parse(
                  JSON.stringify(
                    this.dishAllocationsWeekResponse[0]['bulkDishes']
                  )
                );
                console.log(
                  'this.dishAllocationsOrderDaysInitSunday',
                  JSON.stringify(this.dishAllocationsOrderDaysInitSaturday)
                );
                this.dishAllocationsOrderDays[
                  'Sunday'
                ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];
                if (!this.printViewWekplanAll) {
                  this.dishAllocationsOrderDays[
                    'Sunday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Sunday']
                  );
                }
                this.dishAllocationsOrderDays[
                  'Sunday'
                ] = this.sortMenuLineArrForCategory(
                  this.dishAllocationsOrderDays['Sunday']
                );

                this.dishMainSideOrderDays[
                  'Sunday'
                ] = this.divideByMainSideDishes(
                  this.dishAllocationsOrderDays['Sunday'],
                  'Sunday',
                  this.printViewWekplanAll,
                  this.mainDishOnlyPrintDays['Sunday']
                );

                //---------------------Monday is the same--------------------------
                this.dishMainSideOrderDays[
                  'Monday'
                ] = this.divideByMainSideDishes(
                  this.dishAllocationsOrderDays['Sunday'],
                  'Sunday',
                  this.printViewWekplanAll,
                  this.mainDishOnlyPrintDays['Sunday']
                );

                console.log(
                  'this.dishAllocationsOrderDaysSunday',
                  JSON.stringify(this.dishAllocationsOrderDays['Sunday'])
                );
              } else {
                this.dishAllocationsOrderDays[
                  'Sunday'
                ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];
              }

              if (_.isEmpty(this.dishAllocationsOrderDays['Sunday'])) {
                console.log(
                  'this.dishAllocationsOrderDays[\'Sunday\'].length==0'
                );
                this.isEmptySunday = true;
              }
            }

            if (this.dishAllocationsWeekResponse[0]) {
              if (this.orderFromViewWeekPlan) {
                this.dishAllocationsOrderDays['Monday'] = JSON.parse(
                  JSON.stringify(this.dishAllocationsOrderDaysInitSaturday)
                );
                console.log(
                  'this.dishAllocationsOrderDays[Monday]',
                  this.dishAllocationsOrderDays['Monday']
                );
                if (!this.printViewWekplanAll) {
                  this.dishAllocationsOrderDays[
                    'Monday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Monday']
                  );
                }
                this.isEmptyMonday = true; //!!!!!!!!!!!!!!!!
                this.dishAllocationsOrderDays['Monday'] = [];

                console.log(
                  'this.dishAllocationsOrderDaysMonday',
                  JSON.stringify(this.dishAllocationsOrderDays)
                );
              } else {
                this.dishAllocationsOrderDays[
                  'Monday'
                ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];
              }
              if (_.isEmpty(this.dishAllocationsOrderDays['Monday'])) {
                console.log(
                  'this.dishAllocationsOrderDays[\'Monday\'].length==0'
                );
                this.isEmptyMonday = true;
              }
            }

            console.log(
              'dishAllocationsOrderDaysSM=' +
                JSON.stringify(this.dishAllocationsOrderDays)
            );

            break;
          case 'Week':
            this.toggledTuesday = true;
            this.toggledThursday = true;
            this.toggledSaturday = true;
            //this.templateDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            this.templateDays = ['Tuesday', 'Thursday', 'Sunday'];
            if (this.dishAllocationsWeekResponse[0]) {
              if (this.orderFromViewWeekPlan) {
                this.dishAllocationsOrderDaysInitTuesday = JSON.parse(
                  JSON.stringify(
                    this.dishAllocationsWeekResponse[0]['bulkDishes']
                  )
                );
                console.log(
                  'this.dishAllocationsWeekResponse[0]',
                  JSON.stringify(
                    this.dishAllocationsWeekResponse[0]['bulkDishes']
                  )
                );
                console.log(
                  'this.dishAllocationsOrderDaysInitTuesday',
                  this.dishAllocationsOrderDaysInitTuesday
                );
                this.dishAllocationsOrderDays[
                  'Tuesday'
                ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];

                if (!this.printViewWekplanAll) {
                  this.dishAllocationsOrderDays[
                    'Tuesday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Tuesday']
                  );
                }
                this.dishAllocationsOrderDays[
                  'Tuesday'
                ] = this.sortMenuLineArrForCategory(
                  this.dishAllocationsOrderDays['Tuesday']
                );

                this.dishMainSideOrderDays[
                  'Tuesday'
                ] = this.divideByMainSideDishes(
                  this.dishAllocationsOrderDays['Tuesday'],
                  'Tuesday',
                  this.printViewWekplanAll,
                  this.mainDishOnlyPrintDays['Tuesday']
                );
                //-----------------------Wednesday is The Same----
                this.dishMainSideOrderDays[
                  'Wednesday'
                ] = this.divideByMainSideDishes(
                  this.dishAllocationsOrderDays['Tuesday'],
                  'Tuesday',
                  this.printViewWekplanAll,
                  this.mainDishOnlyPrintDays['Tuesday']
                );

                console.log(
                  'this.dishAllocationsOrderDaysTuesday',
                  JSON.stringify(this.dishAllocationsOrderDays)
                );
              } else {
                this.dishAllocationsOrderDays[
                  'Tuesday'
                ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];
              }

              if (_.isEmpty(this.dishAllocationsOrderDays['Tuesday'])) {
                console.log(
                  'this.dishAllocationsOrderDays[\'Tuesday\'].length==0'
                );
                this.isEmptyTuesday = true;
              }
            }
            if (this.dishAllocationsWeekResponse[0]) {
              if (this.orderFromViewWeekPlan) {
                this.dishAllocationsOrderDays['Wednesday'] = JSON.parse(
                  JSON.stringify(this.dishAllocationsOrderDaysInitTuesday)
                );
                console.log(
                  'this.dishAllocationsOrderDaysInitTuesday',
                  this.dishAllocationsOrderDaysInitTuesday
                );
                console.log(
                  'this.dishAllocationsOrderDaysWednesday',
                  this.dishAllocationsOrderDays['Wednesday']
                );
                if (!this.printViewWekplanAll) {
                  this.dishAllocationsOrderDays[
                    'Wednesday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Wednesday']
                  );
                }
                this.isEmptyWednesday = true; // !!!!!!!!!
                this.dishAllocationsOrderDays['Wednesday'] = [];

                console.log(
                  'this.dishAllocationsOrderDaysWednesday',
                  JSON.stringify(this.dishAllocationsOrderDays)
                );
              } else {
                this.dishAllocationsOrderDays[
                  'Wednesday'
                ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];
              }

              if (_.isEmpty(this.dishAllocationsOrderDays['Wednesday'])) {
                console.log(
                  'this.dishAllocationsOrderDays[\'Wednesday\'].length==0'
                );
                this.isEmptyWednesday = true;
              }
            }

            console.log(
              'dishAllocationsOrderDaysTW=' +
                JSON.stringify(this.dishAllocationsOrderDays)
            );

            if (this.dishAllocationsWeekResponse[1]) {
              if (this.orderFromViewWeekPlan) {
                this.dishAllocationsOrderDaysInitThursday = JSON.parse(
                  JSON.stringify(
                    this.dishAllocationsWeekResponse[1]['bulkDishes']
                  )
                );
                console.log(
                  'this.dishAllocationsWeekResponse[1]Thursday',
                  JSON.stringify(
                    this.dishAllocationsWeekResponse[1]['bulkDishes']
                  )
                );
                this.dishAllocationsOrderDays[
                  'Thursday'
                ] = this.dishAllocationsWeekResponse[1]['bulkDishes'];
                if (!this.printViewWekplanAll) {
                  this.dishAllocationsOrderDays[
                    'Thursday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Thursday']
                  );
                }
                this.dishAllocationsOrderDays[
                  'Thursday'
                ] = this.sortMenuLineArrForCategory(
                  this.dishAllocationsOrderDays['Thursday']
                );

                this.dishMainSideOrderDays[
                  'Thursday'
                ] = this.divideByMainSideDishes(
                  this.dishAllocationsOrderDays['Thursday'],
                  'Thursday',
                  this.printViewWekplanAll,
                  this.mainDishOnlyPrintDays['Thursday']
                );

                //-----------------------Other are The Same----
                this.dishMainSideOrderDays[
                  'Friday'
                ] = this.divideByMainSideDishes(
                  this.dishAllocationsOrderDays['Thursday'],
                  'Thursday',
                  this.printViewWekplanAll,
                  this.mainDishOnlyPrintDays['Thursday']
                );
                this.dishMainSideOrderDays[
                  'Saturday'
                ] = this.divideByMainSideDishes(
                  this.dishAllocationsOrderDays['Thursday'],
                  'Thursday',
                  this.printViewWekplanAll,
                  this.mainDishOnlyPrintDays['Thursday']
                );
                console.log(
                  'this.productNrForDaysimpleOderModelArrayFilter',
                  this.productNrForDaysimpleOderModelArrayFilter(
                    this.simpleOderModelArray,
                    'Thursday'
                  )
                );
                console.log(
                  'this.dishAllocationsOrderDaysThursday',
                  JSON.stringify(this.dishAllocationsOrderDays)
                );
              } else {
                this.dishAllocationsOrderDays[
                  'Thursday'
                ] = this.dishAllocationsWeekResponse[1]['bulkDishes'];
              }
              if (_.isEmpty(this.dishAllocationsOrderDays['Thursday'])) {
                console.log(
                  'this.dishAllocationsOrderDays[\'Thursday\'].length==0'
                );
                this.isEmptyThursday = true;
              }
            }

            if (this.dishAllocationsWeekResponse[1]) {
              if (this.orderFromViewWeekPlan) {
                this.dishAllocationsOrderDays['Friday'] = JSON.parse(
                  JSON.stringify(this.dishAllocationsOrderDaysInitThursday)
                );
                if (!this.printViewWekplanAll) {
                  this.dishAllocationsOrderDays[
                    'Friday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Friday']
                  );
                }
                this.isEmptyFriday = true; // !!!!!!!!!
                this.dishAllocationsOrderDays['Friday'] = [];
                console.log(
                  'this.dishAllocationsOrderDaysFriday',
                  JSON.stringify(this.dishAllocationsOrderDays)
                );
              } else {
                this.dishAllocationsOrderDays[
                  'Friday'
                ] = this.dishAllocationsWeekResponse[1]['bulkDishes'];
              }

              if (_.isEmpty(this.dishAllocationsOrderDays['Friday'])) {
                console.log(
                  'this.dishAllocationsOrderDays[\'Friday\'].length==0'
                );
                this.isEmptyFriday = true;
              }
            }

            if (this.dishAllocationsWeekResponse[1]) {
              if (this.orderFromViewWeekPlan) {
                this.dishAllocationsOrderDays['Saturday'] = JSON.parse(
                  JSON.stringify(this.dishAllocationsOrderDaysInitThursday)
                );
                console.log(
                  'this.dishAllocationsOrderDays[Saturday]',
                  JSON.stringify(this.dishAllocationsOrderDays['Saturday'])
                );
                if (!this.printViewWekplanAll) {
                  this.dishAllocationsOrderDays[
                    'Saturday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Saturday']
                  );
                }
                this.isEmptySaturday = true; // !!!!!!!!!
                this.dishAllocationsOrderDays['Saturday'] = [];
                console.log(
                  'this.dishAllocationsOrderDaysSunday',
                  JSON.stringify(this.dishAllocationsOrderDays)
                );
              } else {
                this.dishAllocationsOrderDays[
                  'Saturday'
                ] = this.dishAllocationsWeekResponse[1]['bulkDishes'];
              }

              if (_.isEmpty(this.dishAllocationsOrderDays['Saturday'])) {
                console.log(
                  'this.dishAllocationsOrderDays[\'Saturday\'].length==0'
                );

                this.isEmptySaturday = true;
              }
            }

            console.log(
              'dishAllocationsOrderDaysTS=' +
                JSON.stringify(this.dishAllocationsOrderDays)
            );

            if (this.dishAllocationsWeekResponse[2]) {
              if (this.orderFromViewWeekPlan) {
                console.log(
                  'this.dishAllocationsWeekResponse[2]Sunday',
                  JSON.stringify(
                    this.dishAllocationsWeekResponse[2]['bulkDishes']
                  )
                );

                this.dishAllocationsOrderDaysInitSaturday = JSON.parse(
                  JSON.stringify(
                    this.dishAllocationsWeekResponse[2]['bulkDishes']
                  )
                );
                console.log(
                  'this.dishAllocationsOrderDaysInitSunday',
                  JSON.stringify(this.dishAllocationsOrderDaysInitSaturday)
                );
                this.dishAllocationsOrderDays[
                  'Sunday'
                ] = this.dishAllocationsWeekResponse[2]['bulkDishes'];
                if (!this.printViewWekplanAll) {
                  this.dishAllocationsOrderDays[
                    'Sunday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Sunday']
                  );
                }
                this.dishAllocationsOrderDays[
                  'Sunday'
                ] = this.sortMenuLineArrForCategory(
                  this.dishAllocationsOrderDays['Sunday']
                );

                this.dishMainSideOrderDays[
                  'Sunday'
                ] = this.divideByMainSideDishes(
                  this.dishAllocationsOrderDays['Sunday'],
                  'Sunday',
                  this.printViewWekplanAll,
                  this.mainDishOnlyPrintDays['Sunday']
                );

                //-----------------------Other are The Same----
                this.dishMainSideOrderDays[
                  'Monday'
                ] = this.divideByMainSideDishes(
                  this.dishAllocationsOrderDays['Sunday'],
                  'Sunday',
                  this.printViewWekplanAll,
                  this.mainDishOnlyPrintDays['Sunday']
                );

                console.log(
                  'this.dishAllocationsOrderDaysSunday',
                  JSON.stringify(this.dishAllocationsOrderDays['Sunday'])
                );
              } else {
                this.dishAllocationsOrderDays[
                  'Sunday'
                ] = this.dishAllocationsWeekResponse[2]['bulkDishes'];
              }

              if (_.isEmpty(this.dishAllocationsOrderDays['Sunday'])) {
                console.log(
                  'this.dishAllocationsOrderDays[\'Sunday\'].length==0'
                );
                this.isEmptySunday = true;
              }
            }

            if (this.dishAllocationsWeekResponse[2]) {
              if (this.orderFromViewWeekPlan) {
                this.dishAllocationsOrderDays['Monday'] = JSON.parse(
                  JSON.stringify(this.dishAllocationsOrderDaysInitSaturday)
                );
                console.log(
                  'this.dishAllocationsOrderDays[Monday]',
                  this.dishAllocationsOrderDays['Monday']
                );
                if (!this.printViewWekplanAll) {
                  this.dishAllocationsOrderDays[
                    'Monday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Monday']
                  );
                }
                this.isEmptyMonday = true; //!!!!!!!!!!!!!!!!
                this.dishAllocationsOrderDays['Monday'] = [];
                console.log(
                  'this.dishAllocationsOrderDaysMonday',
                  JSON.stringify(this.dishAllocationsOrderDays)
                );
              } else {
                this.dishAllocationsOrderDays[
                  'Monday'
                ] = this.dishAllocationsWeekResponse[2]['bulkDishes'];
              }
              if (_.isEmpty(this.dishAllocationsOrderDays['Monday'])) {
                console.log(
                  'this.dishAllocationsOrderDays[\'Monday\'].length==0'
                );
                this.isEmptyMonday = true;
              }
            }

            console.log(
              'dishAllocationsOrderDaysSM=' +
                JSON.stringify(this.dishAllocationsOrderDays)
            );

            break;
          case 'WeeksVis':
          case 'Weeks':
            console.log('[ngOnInit] in Weeks');
            this.selectedWeekForPrintModelArr = [];
            this.selectedWeeksArr = [];
            this.selectedWeeksMainDishesArr = [];
            this.selectedWeeksSideDishesArr = [];

            this.isWeeks = true;
            if (toggledDay1 == 'WeeksVis') {
              this.selectedWeekForPrintModel = this.localStorageService.get(
                'selectedVisWeekForPrintModel' +
                  this.localStorageService.get('parentPrintRoute')
              );
            } else {
              this.selectedWeekForPrintModel = this.localStorageService.get(
                'selectedWeekForPrintModel' +
                  this.localStorageService.get('parentPrintRoute')
              );
            }

            console.log(
              '[ngOnInit] this.selectedWeekForPrintModel ',
              this.selectedWeekForPrintModel
            );
            let shiftWeekItem = 0;
            for (let week in this.selectedWeekForPrintModel) {
              console.log('[ngOnInit] shiftWeekItem', shiftWeekItem);

              this.selectedWeekForPrintModelArr.push(
                this.selectedWeekForPrintModel[week]
              );

              console.log(
                '[ngOnInit] this.selectedWeekForPrintModelArr',
                this.selectedWeekForPrintModelArr
              );

              this.toggledTuesday = true;
              this.toggledThursday = true;
              this.toggledSaturday = true;
              this.templateDays = [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
              ];
              this.dishAllocationsOrderDays = new DishAllocationsOrderDays();
              this.dishAllocationsOrderDaysMainDishes = new DishAllocationsOrderDays();
              this.dishAllocationsOrderDaysSideDishes = new DishAllocationsOrderDays();
              let mainSideArr = new MainSideArr();
              this.dishMainSideOrderDays = new DishMainSideOrderDays(
                mainSideArr,
                mainSideArr,
                mainSideArr,
                mainSideArr,
                mainSideArr,
                mainSideArr,
                mainSideArr
              );

              if (this.dishAllocationsWeekResponse[0 + shiftWeekItem + 1]) {
                if (this.orderFromViewWeekPlan) {
                  this.dishAllocationsOrderDaysInitTuesday = JSON.parse(
                    JSON.stringify(
                      this.dishAllocationsWeekResponse[0 + shiftWeekItem + 1][
                        'bulkDishes'
                      ]
                    )
                  );
                  this.dishAllocationsOrderDays[
                    'Tuesday'
                  ] = this.dishAllocationsWeekResponse[0 + shiftWeekItem + 1][
                    'bulkDishes'
                  ];

                  this.dishAllocationsOrderDays[
                    'Tuesday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Tuesday']
                  );
                  this.dishAllocationsOrderDays[
                    'Tuesday'
                  ] = this.sortMenuLineArrForCategory(
                    this.dishAllocationsOrderDays['Tuesday']
                  );

                  this.dishMainSideOrderDays[
                    'Tuesday'
                  ] = this.divideByMainSideDishes(
                    this.dishAllocationsOrderDays['Tuesday'],
                    'Tuesday',
                    this.printViewWekplanAll,
                    this.selectedWeekForPrintModel[week]['mainDishOnly']
                  );

                  console.log(
                    'this.dishAllocationsOrderDaysTuesday',
                    JSON.stringify(this.dishAllocationsOrderDays)
                  );
                } else {
                  this.dishAllocationsOrderDays[
                    'Tuesday'
                  ] = this.dishAllocationsWeekResponse[0 + shiftWeekItem][
                    'bulkDishes'
                  ];
                }

                if (_.isEmpty(this.dishAllocationsOrderDays['Tuesday'])) {
                  console.log(
                    'this.dishAllocationsOrderDays[\'Tuesday\'].length==0'
                  );
                  this.isEmptyTuesday = true;
                }
              }
              if (this.dishAllocationsWeekResponse[0 + shiftWeekItem + 1]) {
                if (this.orderFromViewWeekPlan) {
                  this.dishAllocationsOrderDays['Wednesday'] = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDaysInitTuesday)
                  );
                  this.dishAllocationsOrderDays[
                    'Wednesday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Wednesday']
                  );
                  this.isEmptyWednesday = true; // !!!!!!!!!
                  this.dishAllocationsOrderDays['Wednesday'] = [];

                  console.log(
                    'this.dishAllocationsOrderDaysWednesday',
                    JSON.stringify(this.dishAllocationsOrderDays)
                  );
                } else {
                  this.dishAllocationsOrderDays[
                    'Wednesday'
                  ] = this.dishAllocationsWeekResponse[0 + shiftWeekItem + 1][
                    'bulkDishes'
                  ];
                }

                if (_.isEmpty(this.dishAllocationsOrderDays['Wednesday'])) {
                  console.log(
                    'this.dishAllocationsOrderDays[\'Wednesday\'].length==0'
                  );
                  this.isEmptyWednesday = true;
                }
              }

              console.log(
                'dishAllocationsOrderDaysTW=' +
                  JSON.stringify(this.dishAllocationsOrderDays)
              );

              if (this.dishAllocationsWeekResponse[1 + shiftWeekItem + 1]) {
                if (this.orderFromViewWeekPlan) {
                  this.dishAllocationsOrderDaysInitThursday = JSON.parse(
                    JSON.stringify(
                      this.dishAllocationsWeekResponse[1 + shiftWeekItem + 1][
                        'bulkDishes'
                      ]
                    )
                  );
                  this.dishAllocationsOrderDays[
                    'Thursday'
                  ] = this.dishAllocationsWeekResponse[1 + shiftWeekItem + 1][
                    'bulkDishes'
                  ];
                  this.dishAllocationsOrderDays[
                    'Thursday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Thursday']
                  );
                  this.dishAllocationsOrderDays[
                    'Thursday'
                  ] = this.sortMenuLineArrForCategory(
                    this.dishAllocationsOrderDays['Thursday']
                  );

                  this.dishMainSideOrderDays[
                    'Thursday'
                  ] = this.divideByMainSideDishes(
                    this.dishAllocationsOrderDays['Thursday'],
                    'Thursday',
                    this.printViewWekplanAll,
                    this.selectedWeekForPrintModel[week]['mainDishOnly']
                  );
                  console.log(
                    'this.dishAllocationsOrderDaysThursday',
                    JSON.stringify(this.dishAllocationsOrderDays)
                  );
                } else {
                  this.dishAllocationsOrderDays[
                    'Thursday'
                  ] = this.dishAllocationsWeekResponse[1 + shiftWeekItem + 1][
                    'bulkDishes'
                  ];
                }
                if (_.isEmpty(this.dishAllocationsOrderDays['Thursday'])) {
                  console.log(
                    'this.dishAllocationsOrderDays[\'Thursday\'].length==0'
                  );
                  this.isEmptyThursday = true;
                }
              }

              if (this.dishAllocationsWeekResponse[1 + shiftWeekItem + 1]) {
                if (this.orderFromViewWeekPlan) {
                  this.dishAllocationsOrderDays['Friday'] = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDaysInitThursday)
                  );
                  this.dishAllocationsOrderDays[
                    'Friday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Friday']
                  );
                  this.isEmptyFriday = true; // !!!!!!!!!
                  this.dishAllocationsOrderDays['Friday'] = [];
                  console.log(
                    'this.dishAllocationsOrderDaysFriday',
                    JSON.stringify(this.dishAllocationsOrderDays)
                  );
                } else {
                  this.dishAllocationsOrderDays[
                    'Friday'
                  ] = this.dishAllocationsWeekResponse[1 + shiftWeekItem + 1][
                    'bulkDishes'
                  ];
                }

                if (_.isEmpty(this.dishAllocationsOrderDays['Friday'])) {
                  console.log(
                    'this.dishAllocationsOrderDays[\'Friday\'].length==0'
                  );
                  this.isEmptyFriday = true;
                }
              }

              if (this.dishAllocationsWeekResponse[1 + shiftWeekItem + 1]) {
                if (this.orderFromViewWeekPlan) {
                  this.dishAllocationsOrderDays['Saturday'] = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDaysInitThursday)
                  );
                  console.log(
                    'this.dishAllocationsOrderDays[Saturday]',
                    JSON.stringify(this.dishAllocationsOrderDays['Saturday'])
                  );
                  this.dishAllocationsOrderDays[
                    'Saturday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Saturday']
                  );
                  this.isEmptySaturday = true; // !!!!!!!!!
                  this.dishAllocationsOrderDays['Saturday'] = [];
                  console.log(
                    'this.dishAllocationsOrderDaysSunday',
                    JSON.stringify(this.dishAllocationsOrderDays)
                  );
                } else {
                  this.dishAllocationsOrderDays[
                    'Saturday'
                  ] = this.dishAllocationsWeekResponse[1 + shiftWeekItem + 1][
                    'bulkDishes'
                  ];
                }

                if (_.isEmpty(this.dishAllocationsOrderDays['Saturday'])) {
                  console.log(
                    'this.dishAllocationsOrderDays[\'Saturday\'].length==0'
                  );

                  this.isEmptySaturday = true;
                }
              }

              console.log(
                'dishAllocationsOrderDaysTS=' +
                  JSON.stringify(this.dishAllocationsOrderDays)
              );

              if (this.dishAllocationsWeekResponse[2 + shiftWeekItem + 1]) {
                if (this.orderFromViewWeekPlan) {
                  console.log(
                    'this.dishAllocationsWeekResponse[2+shiftWeekItem]Sunday',
                    JSON.stringify(
                      this.dishAllocationsWeekResponse[2 + shiftWeekItem + 1][
                        'bulkDishes'
                      ]
                    )
                  );

                  this.dishAllocationsOrderDaysInitSaturday = JSON.parse(
                    JSON.stringify(
                      this.dishAllocationsWeekResponse[2 + shiftWeekItem + 1][
                        'bulkDishes'
                      ]
                    )
                  );
                  this.dishAllocationsOrderDays[
                    'Sunday'
                  ] = this.dishAllocationsWeekResponse[2 + shiftWeekItem + 1][
                    'bulkDishes'
                  ];
                  this.dishAllocationsOrderDays[
                    'Sunday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Sunday']
                  );
                  let SundayDate = this.dateService.getISODateYYYYMMDDforDayMonth(
                    'Sunday',
                    this.selectedWeekForPrintModel[week]['week'],
                    this.selectedWeekForPrintModel[week]['yearYYYY']
                  );

                  this.dishAllocationsOrderDays['Sunday'] = this.filterForDay(
                    this.dishAllocationsOrderDays['Sunday'],
                    SundayDate
                  );

                  this.dishAllocationsOrderDays[
                    'Sunday'
                  ] = this.sortMenuLineArrForCategory(
                    this.dishAllocationsOrderDays['Sunday']
                  );

                  this.dishMainSideOrderDays[
                    'Sunday'
                  ] = this.divideByMainSideDishes(
                    this.dishAllocationsOrderDays['Sunday'],
                    'Sunday',
                    this.printViewWekplanAll,
                    this.selectedWeekForPrintModel[week]['mainDishOnly']
                  );

                  console.log(
                    'this.dishAllocationsOrderDaysSunday',
                    JSON.stringify(this.dishAllocationsOrderDays['Sunday'])
                  );
                } else {
                  this.dishAllocationsOrderDays[
                    'Sunday'
                  ] = this.dishAllocationsWeekResponse[2 + shiftWeekItem + 1][
                    'bulkDishes'
                  ];
                }

                if (_.isEmpty(this.dishAllocationsOrderDays['Sunday'])) {
                  console.log(
                    'this.dishAllocationsOrderDays[\'Sunday\'].length==0'
                  );
                  this.isEmptySunday = true;
                }
              }

              if (this.dishAllocationsWeekResponse[0 + shiftWeekItem]) {
                if (this.orderFromViewWeekPlan) {
                  console.log(
                    'this.dishAllocationsWeekResponse[0+shiftWeekItem]Monday',
                    JSON.stringify(
                      this.dishAllocationsWeekResponse[0 + shiftWeekItem][
                        'bulkDishes'
                      ]
                    )
                  );

                  this.dishAllocationsOrderDaysInitSaturday = JSON.parse(
                    JSON.stringify(
                      this.dishAllocationsWeekResponse[0 + shiftWeekItem][
                        'bulkDishes'
                      ]
                    )
                  );
                  console.log(
                    'this.dishAllocationsOrderDaysInitMonday',
                    JSON.stringify(this.dishAllocationsOrderDaysInitSaturday)
                  );
                  this.dishAllocationsOrderDays[
                    'Monday'
                  ] = this.dishAllocationsWeekResponse[0 + shiftWeekItem][
                    'bulkDishes'
                  ];
                  this.dishAllocationsOrderDays[
                    'Monday'
                  ] = this.filterForPrintDishes(
                    this.dishAllocationsOrderDays['Monday']
                  );
                  let MondayDate = this.dateService.getISODateYYYYMMDDforDayMonth(
                    'Monday',
                    this.selectedWeekForPrintModel[week]['week'],
                    this.selectedWeekForPrintModel[week]['yearYYYY']
                  );

                  this.dishAllocationsOrderDays['Monday'] = this.filterForDay(
                    this.dishAllocationsOrderDays['Monday'],
                    MondayDate
                  );
                  this.dishAllocationsOrderDays[
                    'Monday'
                  ] = this.sortMenuLineArrForCategory(
                    this.dishAllocationsOrderDays['Monday']
                  );

                  this.dishMainSideOrderDays[
                    'Monday'
                  ] = this.divideByMainSideDishes(
                    this.dishAllocationsOrderDays['Monday'],
                    'Monday',
                    this.printViewWekplanAll,
                    this.selectedWeekForPrintModel[week]['mainDishOnly']
                  );

                  console.log(
                    'this.dishAllocationsOrderDaysMonday',
                    JSON.stringify(this.dishAllocationsOrderDays)
                  );
                } else {
                  this.dishAllocationsOrderDays[
                    'Monday'
                  ] = this.dishAllocationsWeekResponse[0 + shiftWeekItem][
                    'bulkDishes'
                  ];
                }
                if (_.isEmpty(this.dishAllocationsOrderDays['Monday'])) {
                  console.log(
                    'this.dishAllocationsOrderDays[\'Monday\'].length==0'
                  );
                  this.isEmptyMonday = true;
                }
              }

              console.log(
                'dishAllocationsOrderDaysSM=' +
                  JSON.stringify(this.dishAllocationsOrderDays)
              );

              console.log(
                '[ngOnInit] this.dishMainSideOrderDays CYCLE',
                this.dishMainSideOrderDays
              );
              this.selectedWeeksArr.push(this.dishMainSideOrderDays);

              console.log(
                '[ngOnInit] this.selectedWeeksArr CYCLE',
                this.selectedWeeksArr
              );

              shiftWeekItem = shiftWeekItem + 4;
            }

            break;
          default:
            this.localStorageService.set(
              'toggledChooseDayBulkOrder',
              'Tuesday'
            );
        }

        console.log(
          'dishAllocationsOrderDays1=' +
            JSON.stringify(this.dishAllocationsOrderDays)
        );

        //}

        //--------------------------Loader -----------------------------
        this.router.events.subscribe((event: Event) => {
          switch (true) {
            case event instanceof NavigationEnd:
            case event instanceof NavigationCancel:
            case event instanceof NavigationError: {
              var loader = document.getElementById('loader');
              loader.style.visibility = 'hidden';
              //--------------------------Open printing--------------------------------------
              if (this.isfAfterPageLoaded) {
                window.print();
                this.isfAfterPageLoaded = false;
              }
              //------------------------------------------------------------------------------

              break;
            }
            default: {
              break;
            }
          }
        });

        //----------------------------------------------------------
        this.localStorageService.set(
          'currentChildRoute',
          this.localStorageService.get('parentPrintRoute')
        );
        this.needNgOninit = true;
        this.needUnsubscribe = true;

        console.debug('[ngOnInit] this.templateDays', this.templateDays);
        console.log(
          '[ngOnInit] this.dishAllocationsOrderDays22=' +
            JSON.stringify(this.dishAllocationsOrderDays)
        );

        console.log(
          '[ngOnInit] this.selectedWeeksArr AFTER',
          this.selectedWeeksArr
        );
      });
    }
  }

  ngAfterContentChecked() {
    if (this.needNgOninit) {
      this.ngOnInit();
    }
  }

  ngOnDestroy() {
    this.dataShareService.dispayIfPageForPrinting = '';

    this.obsSubscription.unsubscribe();
  }

  //-----------------------------Methods--------------------------------------------------------------

  categoryFilter(day: number, response: any[], category: any) {
    var dishArrForCategoty = Object.create({ category: [], categories: [] });
    var exit_loops = false;
    var lowerCaseCategory = category.toLowerCase();

    console.log(
      'In filterResponseByCategory lowerCaseCategory=' + lowerCaseCategory
    );
    for (var n = 0; n < response[day].length; n++) {
      //	console.log("In filterResponseByCategory response[day][n][category][name]="+ response[day][n]["category"][name]);
      if (
        response[day][n]['category']['name'] == category ||
        response[day][n]['category']['name'] == lowerCaseCategory
      ) {
        //	console.log("In categoryFilter. Category found")

        dishArrForCategoty.category = response[day][n]['category'];
        dishArrForCategoty.categories = response[day][n]['categories'];
        exit_loops = true;
        break;
      } else {
      }
    }
    return dishArrForCategoty;
  }

  categoryDietTypeArrCatFilter(
    day: number,
    response: any[],
    categoryArr: any[]
  ) {
    var menuLineArrForCategory = [];
    for (var n = 0; n < response[day]['mainDishes'].length; n++) {
      for (
        var k = 0;
        k < response[day]['mainDishes'][n]['dietTypeGroups'].length;
        k++
      ) {
        for (
          var m = 0;
          m <
          response[day]['mainDishes'][n]['dietTypeGroups'][k]['dietTypes']
            .length;
          m++
        ) {
          for (var catAndet in categoryArr) {
            if (
              response[day]['mainDishes'][n]['dietTypeGroups'][k]['dietTypes'][
                m
              ]['dietType']['name'] == categoryArr[catAndet]
            ) {
              menuLineArrForCategory.push(
                response[day]['mainDishes'][n]['dietTypeGroups'][k][
                  'dietTypes'
                ][m]['menuLine']
              );
            } else {
            }
          }
        }
      }
    }

    return menuLineArrForCategory;
  }

  categoryDietTypeFuldkostArrFilter(response: any[]) {
    var categoryArr = [
      'Fuldkost',
      'Fuldkost m. kogte kartof.',
      'Fuldkost m. rå kartof.',
      'Fuldkost m/mos',
      'Fuldkost % kartofler',
    ];
    var menuLineArrForCategory = [];
    for (var k = 0; k < response.length; k++) {
      for (var m = 0; m < response[k]['dietTypes'].length; m++) {
        for (var catAndet in categoryArr) {
          //console.log("catAndet", catAndet);
          if (
            response[k]['dietTypes'][m]['dietType']['name'] ==
            categoryArr[catAndet]
          ) {
            menuLineArrForCategory.push(
              response[k]['dietTypes'][m]['menuLine']
            );
          } else {
          }
        }
      }
    }

    console.log('RETURNFuldkost' + JSON.stringify(menuLineArrForCategory));
    this.menuLineArrForCategoryTemp = menuLineArrForCategory;
    return menuLineArrForCategory;
  }

  categoryDietTypeEnrgitatArrFilter(response: any[]) {
    console.log('response', response);
    var categoryArr = ['Energitæt', 'Energi m/ rå kart.'];
    var menuLineArrForCategory = [];
    for (var k = 0; k < response.length; k++) {
      for (var m = 0; m < response[k]['dietTypes'].length; m++) {
        for (var catAndet in categoryArr) {
          console.log('catAndet', catAndet);
          if (
            response[k]['dietTypes'][m]['dietType']['name'] ==
            categoryArr[catAndet]
          ) {
            console.log('In categoryFilter. Category found');

            menuLineArrForCategory.push(
              response[k]['dietTypes'][m]['menuLine']
            );
          } else {
          }
        }
      }
    }

    this.menuLineArrForCategoryTemp = menuLineArrForCategory;
    return menuLineArrForCategory;
  }

  categoryDietTypeBlodArrFilter(response: any[]) {
    var categoryArr = ['Blød'];
    var menuLineArrForCategory = [];
    for (var k = 0; k < response.length; k++) {
      for (var m = 0; m < response[k]['dietTypes'].length; m++) {
        for (var catAndet in categoryArr) {
          if (
            response[k]['dietTypes'][m]['dietType']['name'] ==
            categoryArr[catAndet]
          ) {
            menuLineArrForCategory.push(
              response[k]['dietTypes'][m]['menuLine']
            );
          } else {
          }
        }
      }
    }

    this.menuLineArrForCategoryTemp = menuLineArrForCategory;
    return menuLineArrForCategory;
  }

  categoryDietTypeAndetArrFilter(response: any[]) {
    var categoryArr = ['Gratinkost', 'Cremet energitæt'];
    var menuLineArrForCategory = [];
    for (var k = 0; k < response.length; k++) {
      for (var m = 0; m < response[k]['dietTypes'].length; m++) {
        for (var catAndet in categoryArr) {
          if (
            response[k]['dietTypes'][m]['dietType']['name'] ==
            categoryArr[catAndet]
          ) {
            menuLineArrForCategory.push(
              response[k]['dietTypes'][m]['menuLine']
            );
          } else {
          }
        }
      }
    }

    console.log('RETURNAndet' + JSON.stringify(menuLineArrForCategory));
    this.menuLineArrForCategoryTemp = menuLineArrForCategory;
    return menuLineArrForCategory;
  }

  productNrForDaysimpleOderModelArrayFilter(
    simpleOderModelArray: any,
    day: string
  ) {
    let productNrArray = [];
    for (let orderBulk in simpleOderModelArray) {
      if (
        simpleOderModelArray[orderBulk]['Day'] == day &&
        simpleOderModelArray[orderBulk]['sumOder'] != 0
      ) {
        productNrArray.push(simpleOderModelArray[orderBulk]['dishId']);
      }
    }

    console.log('productNrArray', productNrArray);
    return productNrArray;
  }

  productNrFilterForGetDishes(
    responseDay: any[],
    productNrArray: number[],
    mainMenuLine: number
  ) {
    let menuLineArrForCategory = [];

    for (let itemMenuLine in responseDay) {
      if (responseDay[itemMenuLine]['menuLine'] !== undefined) {
        if (mainMenuLine == 0) {
          for (let productNr in productNrArray) {
            console.log('In mainMenuLine == 0', itemMenuLine);
            if (
              responseDay[itemMenuLine]['menuLine']['productNr'] ==
              productNrArray[productNr]
            ) {
              menuLineArrForCategory.push(responseDay[itemMenuLine]);
            }
          }
        } else {
          if (responseDay[itemMenuLine]['menuLine']['line'] == mainMenuLine) {
            menuLineArrForCategory.push(responseDay[itemMenuLine]);
            console.log('In Line=1, itemMenuLine=', itemMenuLine);
          }
        }
      }
    }

    console.log('menuLineArrForCategory', menuLineArrForCategory);
    return menuLineArrForCategory;
  }

  //-------------------Filter for Printing Dishes--------------------------------
  filterForPrintDishes(responseDay: any[]) {
    let menuLineArrForCategory = [];

    for (let itemMenuLine in responseDay) {
      if (
        responseDay[itemMenuLine]['menuLine'] !== undefined &&
        responseDay[itemMenuLine]['print'] == true
      ) {
        menuLineArrForCategory.push(responseDay[itemMenuLine]);
      }
    }

    console.log(
      '[filterForPrintDishes] menuLineArrForCategory',
      menuLineArrForCategory
    );
    return menuLineArrForCategory;
  }

  //--------------------------Filter for Monday ----------------------------------
  filterForDay(responseDay: any[], DayDate: string) {
    console.log('[filterForDay] DayDate', DayDate);
    let menuLineArrForCategory = [];

    for (let itemMenuLine in responseDay) {
      if (
        responseDay[itemMenuLine]['menuLine'] !== undefined &&
        moment(responseDay[itemMenuLine]['servingDate']).isSame(DayDate) == true
      ) {
        menuLineArrForCategory.push(responseDay[itemMenuLine]);
      }
    }

    console.log(
      '[filterForDay] menuLineArrForCategory',
      menuLineArrForCategory
    );
    return menuLineArrForCategory;
  }
  //------------------------------------------------------------------------------

  arrayDishesViewForDayByCategoryFilter(
    day: string,
    dishAllocationsOrderDays: DishAllocationsOrderDays,
    category: any
  ) {
    var viewArrayForCategoty = new Array();
    var exit_loops = false;

    for (var cat in dishAllocationsOrderDays[day]['categories']) {
      for (var property in dishAllocationsOrderDays[day]['categories'][cat][
        'category'
      ]) {
        if (
          dishAllocationsOrderDays[day]['categories'][cat]['category'][
            property
          ] == category
        ) {
          viewArrayForCategoty =
            dishAllocationsOrderDays[day]['categories'][cat][
              'arrayDishesViewForDay'
            ];

          exit_loops = true;
          break;
        } else {
        }
      }

      if (exit_loops) break;
    }

    return viewArrayForCategoty;
  }

  prevWeek() {
    this.shiftWeek--;
    this.localStorageService.set(
      'shiftWeek' + this.localStorageService.get('currentChildRoute'),
      this.shiftWeek
    );
    this.needNgOninit = true;
    this.needUnsubscribe = false;
    this.updateCartFromLocalStorage();
    this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
  }
  nextWeek() {
    this.shiftWeek++;
    this.localStorageService.set(
      'shiftWeek' + this.localStorageService.get('currentChildRoute'),
      this.shiftWeek
    );
    this.needNgOninit = true;
    this.needUnsubscribe = false;
    this.updateCartFromLocalStorage();
    this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
  }

  getImgUrl(dish: any): string {
    console.debug('[getImgUrl] dish:' + dish);
    let imgUrl;
    if (
      dish &&
      dish['dishPhotos'] &&
      dish['dishPhotos'].length != 0 &&
      dish['dishPhotos'][0] &&
      dish['dishPhotos'][0]['imageUrl'] != ''
    ) {
      imgUrl = this.dataSource.baseUrl + dish['dishPhotos'][0]['imageUrl'];
      console.debug('[getImgUrl] imgUrl', imgUrl);
      return imgUrl;
    } else {
      return 'assets/img/defaultImg.png';
    }
  }
  getProdImg(prodImg: string) {
    var img = 'data:image/jpeg;base64,' + prodImg;

    return this.sanitizer.bypassSecurityTrustResourceUrl(img);
  }

  getTargetId(cat: any, nameDay: string, itemDish: any): string {
    var id = '#' + cat + nameDay + itemDish;

    return id.toString();
  }

  getId(cat: any, nameDay: string, itemDish: any): string {
    var id = cat + nameDay + itemDish;

    return id.toString();
  }

  getOrderDishId(
    fromPage: string,
    shiftWeek: number,
    day: string,
    cat: number,
    dishPlace: number
  ) {
    var pageNumber;
    var orderDishId: number;
    var week = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    switch (fromPage) {
      case 'frokostpakker': {
        pageNumber = 1;

        break;
      }
      case 'extra': {
        pageNumber = 2;

        break;
      }
    }

    orderDishId =
      pageNumber * 1000000 +
      Number(this.dateService.getCurrentWeek() + shiftWeek) * 1000 +
      week.indexOf(day) * 1000 +
      cat * 100 +
      dishPlace;
    return orderDishId;
  }

  updateCartFromLocalStorage() {
    this.cart.updateLinesFromLocalStorage(this.shiftWeekNumber);
  }

  getRowsNumberForDay(numberElementsInRow: number, dishesArraylength: number) {
    var rowsNumber = Math.trunc(dishesArraylength / numberElementsInRow);

    if (dishesArraylength % numberElementsInRow != 0) {
      rowsNumber++;
    }
    if (rowsNumber == 0) {
      rowsNumber = 1;
    }
    return rowsNumber;
  }

  getArrayDishesViewForDayController(
    numberElementsInRow: number,
    dishesArraylength: number
  ) {
    var rows = this.getRowsNumberForDay(numberElementsInRow, dishesArraylength);

    var arrayViewController = new Array(rows);
    for (var i = 0; i < arrayViewController.length; i++) {
      arrayViewController[i] = new Array(numberElementsInRow);
    }
    for (var i = 0; i < arrayViewController.length; i++) {
      for (var j = 0; j < arrayViewController[i].length; j++) {
        arrayViewController[i][j] = -1;
      }
    }

    var k = numberElementsInRow;
    var ref = 0;
    for (var i = 0; i < rows; i++) {
      if (dishesArraylength > numberElementsInRow) {
        if (i == rows - 1) {
          if (dishesArraylength % numberElementsInRow != 0) {
            k = dishesArraylength % numberElementsInRow;
            console.log('k=' + k);

            arrayViewController[i].length = k; // trim last row to the number of items remainder
          }
        }
      } else {
        k = dishesArraylength;
        arrayViewController[i].length = k;
      }

      for (var j = 0; j < k; j++) {
        arrayViewController[i][j] = ref;
        ref++;
      }
    }

    return arrayViewController;
  }

  isDayForOrder(orderingDates: any[], nameDay: string, shiftWeek: number) {
    var isDayForOrderFlag = false;
    var date = this.dateService.getISODateYYYMMDDforDay(nameDay, shiftWeek);

    for (var day in this.orderingDates) {
      if (date == this.orderingDates[day]) {
        isDayForOrderFlag = true;
      }
    }

    return isDayForOrderFlag;
  }

  getDateForDay(day: string) {
    return this.dateService.getISODateDDMMMforDay(day, this.shiftWeek);
  }

  getServingDateForProductNr(productNr: number, deliveryDay: string) {
    let servingDay = '';
    for (let item in this.dishAllocationsOrderDays[deliveryDay]) {
      if (
        this.dishAllocationsOrderDays[deliveryDay][item]['menuLine'][
          'productNr'
        ] == productNr
      ) {
        servingDay = this.dishAllocationsOrderDays[deliveryDay][item][
          'servingDate'
        ];
      }
    }

    console.log('[getServingDateForProductNr] servingDay', servingDay);
    return servingDay;
  }

  getServingDateForProductNrWeeks(
    productNr: number,
    deliveryDay: string,
    weekItemNumber: number,
    mainSdeDish: string
  ) {
    console.log(
      '[getServingDateForProductNrWeeks] selectedWeeksArr[weekItemNumber][deliveryDay]',
      this.selectedWeeksArr[weekItemNumber][deliveryDay]
    );
    console.log('[getServingDateForProductNrWeeks] mainSdeDish', mainSdeDish);

    let servingDay = '';
    for (let item in this.selectedWeeksArr[weekItemNumber][deliveryDay][
      mainSdeDish
    ]) {
      if (
        this.selectedWeeksArr[weekItemNumber][deliveryDay][mainSdeDish][
          item
        ] !== undefined
      ) {
        if (
          this.selectedWeeksArr[weekItemNumber][deliveryDay][mainSdeDish][item][
            'menuLine'
          ]['productNr'] == productNr
        ) {
          servingDay = this.selectedWeeksArr[weekItemNumber][deliveryDay][
            mainSdeDish
          ][item]['servingDate'];
        }
      }
    }

    console.log('[getServingDateForProductNrWeeks] servingDay', servingDay);
    return servingDay;
  }

  translateDayToDanishsShort(day: string) {
    return this.localeDaDKService.translateDayToDanishsShort(day);
  }

  translateDayToDanishsFull(day: string) {
    if (day == 'Sunday') {
      day = 'Saturday';
    }
    return this.localeDaDKService.translateDayToDanishsFull(day);
  }

  getNameServingDay(DateYYYYMMDD: string) {
    return this.dateService.getNameDayFromISODateYYYYMMDD(DateYYYYMMDD);
  }

  //------------------------------------Compare--------------------------------
  compareDates(a, b) {
    console.log('[filterTwoDishesForEachDay] a', a['servingDate']);
    console.log('[filterTwoDishesForEachDay] b', b['servingDate']);
    if (!moment(b['servingDate']).isAfter(a['servingDate']) == true) return 1;
    if (moment(b['servingDate']).isSame(a['servingDate']) == true) return 0;
    if (moment(b['servingDate']).isAfter(a['servingDate']) == true) return -1;
  }

  //-----------------------------------Sort----------------------------------
  sortMenuLineArrForCategory(menuLineArrForCategory: any) {
    if (menuLineArrForCategory[0] && menuLineArrForCategory[0]['servingDate']) {
      console.log(
        '[sortMenuLineArrForCategory] menuLineArrForCategory',
        menuLineArrForCategory
      );
      console.log(
        '[sortMenuLineArrForCategory] menuLineArrForCategory',
        menuLineArrForCategory[0]['servingDate']
      );

      console.log(
        '[sortMenuLineArrForCategory] return menuLineArrForCategory',
        menuLineArrForCategory
      );
      return menuLineArrForCategory.sort(this.compareDates);
    } else return menuLineArrForCategory;
  }

  filterMainDishes(menuLineArrForCategory: any) {
    console.log(
      '[filterMainDishes] menuLineArrForCategory',
      JSON.stringify(menuLineArrForCategory)
    );
    let menuLineMainDishArr = [];
    if (menuLineArrForCategory.length != 0) {
      menuLineMainDishArr = menuLineArrForCategory.filter((item) => {
        return item['sideDish'] != true;
      });
    }

    return menuLineMainDishArr;
  }

  filterSideDishes(menuLineArrForCategory: any) {
    console.log(
      '[filterSideDishes] menuLineArrForCategory',
      JSON.stringify(menuLineArrForCategory)
    );
    let menuLineMainDishArr = [];
    if (menuLineArrForCategory.length != 0) {
      menuLineMainDishArr = menuLineArrForCategory.filter((item) => {
        return item['sideDish'] == true;
      });
    }
    return menuLineMainDishArr;
  }

  getMontInDanish(DateYYYYMMDD: string) {
    let currentMonth = this.dateService.getMonthForISODateYYYYMMDD(
      DateYYYYMMDD
    );
    return this.translateMonthToDanish(currentMonth);
  }

  translateMonthToDanish(month: string) {
    let monthInDanish = '';
    switch (month) {
      case 'January':
        monthInDanish = 'Januar';
        break;
      case 'February':
        monthInDanish = 'Februar';
        break;
      case 'March':
        monthInDanish = 'Marts';

        break;
      case 'April':
        monthInDanish = 'April';
        break;
      case 'May':
        monthInDanish = 'Maj';

        break;
      case 'June':
        monthInDanish = 'Juni';
        break;
      case 'July':
        monthInDanish = 'Juli';
        break;
      case 'August':
        monthInDanish = 'August';
        break;
      case 'September':
        monthInDanish = 'September';
        break;
      case 'October':
        monthInDanish = 'Oktober';
        break;
      case 'November':
        monthInDanish = 'November';
        break;
      case 'December':
        monthInDanish = 'December';
        break;

      default: {
      }
    }
    return monthInDanish;
  }

  getWeekNumberForShiftWeek(shiftWeek: number) {
    console.log('[getWeekNumberForShiftWeek] shiftWeek', shiftWeek);

    console.log(
      '[getWeekNumberForShiftWeek] this.currentWeek',
      this.currentWeek
    );
    return Number(shiftWeek) + Number(this.currentWeek);
  }
  getDateOfLastWeekDay(shiftMonth: number, shiftWeek: number, day: string) {
    console.log('[getDateOfLastWeekDay] shiftMonth', shiftMonth);
    console.log('[getDateOfLastWeekDay] shiftWeek', shiftWeek);
    return this.dateService.getISODateYYYMMDDforDay('Monday', shiftWeek + 1);
  }

  getWeekForDay(servingDate: string) {
    console.log('[getWeekForDay] servingDate', servingDate);
    let week = '';
    week = this.dateService.getWeekForISODateYYYYMMDD(servingDate);
    console.log('[getWeekForDay] week', week);
    return week;
  }

  isNewWeek(lastWeekDay: any, currentServingDay: any) {
    console.log('[isNewWeek] lastWeekDay', lastWeekDay);
    console.log('[isNewWeek] currentServingDay', currentServingDay);
    if (!moment(currentServingDay).isAfter(lastWeekDay) == true) {
      console.log('currentServingDay isAfter', true);
      return true;
    } else {
      console.log('currentServingDay isAfter', false);
      return false;
    }
  }

  getWeekNumberForWeekItem(weekItem: number) {
    console.log('[getWeekNumberForWeekItem]  weekItem', weekItem);
    console.log(
      '[getWeekNumberForWeekItem] this.selectedWeekForPrintModelArr',
      this.selectedWeekForPrintModelArr
    );
    console.log(
      '[getWeekNumberForWeekItem] this.selectedWeekForPrintModelArr[weekItem]',
      this.selectedWeekForPrintModelArr[weekItem]
    );
    let weekNumber = Number(
      this.selectedWeekForPrintModelArr[weekItem]['week']
    );

    return weekNumber;
  }

  divideByMainSideDishes(
    dishAllocationsOrderDay: any,
    day: string,
    printViewWeekplanAll: boolean,
    isMainDishOnly: boolean
  ) {
    console.log(
      '[divideByMainSideDishes] dishAllocationsOrderDay',
      dishAllocationsOrderDay
    );
    console.log('[divideByMainSideDishes] isMainDishOnly', isMainDishOnly);
    let mainSideArr = new MainSideArr();
    let dishMainSideOrderDays = new DishMainSideOrderDays(
      mainSideArr,
      mainSideArr,
      mainSideArr,
      mainSideArr,
      mainSideArr,
      mainSideArr,
      mainSideArr
    );

    console.log(
      '[divideByMainSideDishes] dishMainSideOrderDays',
      dishMainSideOrderDays
    );

    for (let itemDish in dishAllocationsOrderDay) {
      if (dishAllocationsOrderDay[itemDish]['sideDish'] == false) {
        dishMainSideOrderDays[day]['mainDish'].push(
          dishAllocationsOrderDay[itemDish]
        );
        if (
          dishAllocationsOrderDay.find(
            (item) =>
              item['servingDate'] ==
                dishAllocationsOrderDay[itemDish]['servingDate'] &&
              item['sideDish'] == true
          )
        ) {
          if (isMainDishOnly == true) {
            dishMainSideOrderDays[day]['sideDish'].push(undefined);
          }
        } else {
          if (!(printViewWeekplanAll == true)) {
            dishMainSideOrderDays[day]['sideDish'].push(undefined);
          }
        }
      } else if (
        dishAllocationsOrderDay[itemDish]['sideDish'] == true &&
        isMainDishOnly == false
      ) {
        dishMainSideOrderDays[day]['sideDish'].push(
          dishAllocationsOrderDay[itemDish]
        );
        if (
          dishAllocationsOrderDay.find(
            (item) =>
              item['servingDate'] ==
                dishAllocationsOrderDay[itemDish]['servingDate'] &&
              item['sideDish'] != true
          )
        ) {
        } else {
          if (!(printViewWeekplanAll == true)) {
            dishMainSideOrderDays[day]['mainDish'].push(undefined);
          }
        }
      }
    }
    dishMainSideOrderDays[day] = this.alignMainSide(dishMainSideOrderDays[day]);
    console.log(
      '[divideByMainSideDishes] dishMainSideOrderDays',
      dishMainSideOrderDays[day]
    );
    return dishMainSideOrderDays[day];
  }

  alignMainSide(dishMainSideOrderDaysDay: any) {
    if (
      dishMainSideOrderDaysDay['mainDish'].length >
      dishMainSideOrderDaysDay['sideDish'].length
    ) {
      let delta =
        dishMainSideOrderDaysDay['mainDish'].length -
        dishMainSideOrderDaysDay['sideDish'].length;
      let addArr = new Array(delta).fill(undefined);
      console.log('[alignMainSide] addArr', addArr);
      dishMainSideOrderDaysDay['sideDish'] = dishMainSideOrderDaysDay[
        'sideDish'
      ].concat(addArr);
    } else if (
      dishMainSideOrderDaysDay['mainDish'].length <
      dishMainSideOrderDaysDay['sideDish'].length
    ) {
      let delta =
        dishMainSideOrderDaysDay['sideDish'].length -
        dishMainSideOrderDaysDay['mainDish'].length;
      let addArr = new Array(delta).fill(undefined);
      console.log('[alignMainSide] addArr', addArr);
      dishMainSideOrderDaysDay['mainDish'] = dishMainSideOrderDaysDay[
        'mainDish'
      ].concat(addArr);
    }

    console.log(
      '[alignMainSide] dishMainSideOrderDaysDay',
      dishMainSideOrderDaysDay
    );
    return dishMainSideOrderDaysDay;
  }
}
