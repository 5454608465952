import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { CartDetailComponent } from "./cartDetail.component";
import { RouterModule } from "@angular/router";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
    imports: [BrowserModule, FormsModule, RouterModule, NgbModule],
    declarations: [ CartDetailComponent],
    exports: [ CartDetailComponent]
})
export class CartModule { }
