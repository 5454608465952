import { Component, OnInit } from '@angular/core';
import { Cart } from '../../../../cart/cart-model/cart.model';
import { RestDataSource } from '../../../../repository/rest.datasource';
import { LocalStorageService } from '../../../../service/localStorage.service';
import { DateService } from '../../../../service/date.service';
import { LocaleDaDKService } from '../../../../service/locale-da-dk.service';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  ActivationEnd,
  Event,
  NavigationCancel,
  NavigationError,
  NavigationStart,
} from '@angular/router';
import { DishRepository } from '../../../../repository/dish.repository';
import { OrderModel } from '../../../../model/orderModel.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
//import {NgbToast, NgbToastConfig} from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { NavigationService } from '../../../../service/navigation.service';
import { DataShareService } from '../../../../service/dataShare.service';

@Component({
  selector: 'app-print-cart',
  templateUrl: './print-cart.component.html',
  styleUrls: ['./print-cart.component.scss'],
})
export class PrintCartComponent implements OnInit {
  public fromPage: string;
  public closeResult: string;
  public disabledAfterOrder: boolean;
  private delay: number;
  defaultImageUrl: string;
  public shiftWeek: number;
  public shiftWeekNumber: string;
  public simpleOderModelArrayId: string;
  private departmentId: number;
  public maxRetries: number;
  public currentDeliveryDay: string;
  public isfAfterPageLoaded: boolean;

  constructor(
    public cart: Cart,
    private dataSource: RestDataSource,
    private localStorageService: LocalStorageService,
    private dateService: DateService,
    private router: Router,
    private dishRepository: DishRepository,
    private modalService: NgbModal,
    private dataShareService: DataShareService,
    private localeDaDKService: LocaleDaDKService
  ) {
    this.localStorageService.set(
      'currentChildRoute',
      'print/printcartcomponent'
    );
  }

  ngOnInit() {
    this.dataShareService.dispayIfPageForPrinting = 'none';
    this.defaultImageUrl = 'assets/img/defaultImg.png';

    this.delay = 2000;
    this.maxRetries = 2;

    this.fromPage = this.localStorageService.get('parentCartRoute');

    console.log('(PrintCart) [ngOnInit] this.fromPage', this.fromPage);
    this.currentDeliveryDay = this.getCurrentDeliveryDay(this.fromPage);
    this.shiftWeek = this.localStorageService.get(
      'shiftWeek' + this.localStorageService.get('parentCartRoute')
    );
    var shiftWeekNumber = this.dateService.getShiftWeek(this.shiftWeek);
    this.shiftWeekNumber = shiftWeekNumber;

    //-----------------------ADD----------------------------------------------------------
    this.disabledAfterOrder = this.localStorageService.get(
      'parentCartRoute' + 'disabledAfterOrder'
    );
    console.log(
      '(PrintCartComponent) [ngOnInit] this.disabledAfterOrder',
      this.disabledAfterOrder
    );
    // this.disabledAfterOrder = false;
    this.departmentId = this.localStorageService.get('currentdepartmentId');
    this.simpleOderModelArrayId =
      'simpleOderModelArray' +
      this.localStorageService.get('parentCartRoute') +
      shiftWeekNumber +
      this.departmentId;

    //---------------------------------------------------------------------------------

    //this.cart.updateLinesFromLocalStorage(shiftWeekNumber);
    this.cart.updateLinesFromLocalStorageForDeliveryDay(shiftWeekNumber);

    this.isfAfterPageLoaded = true;
    //--------------------------Loader -----------------------------
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        /*case event instanceof NavigationStart: {
        this.loading = true;
          break;
        }*/

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          //  this.loading = false;
          // window.onload = function(){
          var loader = document.getElementById('loader');
          loader.style.visibility = 'hidden';
          //--------------------------Open printing--------------------------------------
          if (this.isfAfterPageLoaded) {
            window.print();
            this.isfAfterPageLoaded = false;
          }
          //------------------------------------------------------------------------------
          //	}
          //  this.localStorageService.set('loading',false);

          //  this.getDismissReason(1);
          break;
        }
        default: {
          break;
        }
      }
    });

    //----------------------------------------------------------
    //    this.localStorageService.set( 'currentChildRoute', this.localStorageService.get( 'parentPrintRoute'));
  }

  ngOnDestroy() {
    this.dataShareService.dispayIfPageForPrinting = '';
  }

  getCurrentDeliveryDay(fromPage: string) {
    //let deliveryDay:string;
    if (fromPage == 'extra') {
      // deliveryDay = this.localStorageService.get('toggledDayExtra');
      return this.localStorageService.get('toggledDayExtra');
    } else if (fromPage == 'frokostpakker') {
      // deliveryDay = this.localStorageService.get('toggledDayFroko');
      return this.localStorageService.get('toggledDayFroko');
    }
  }
  getImgUrl(imageUrl: string): string {
    // console.log("imageUrl:"+ imageUrl);
    let imgUrl: string = '';
    if (imageUrl) {
      imgUrl = this.dataSource.baseUrl + imageUrl;
      // console.log("IMG:"+ imgUrl);
    } else {
      imgUrl = this.defaultImageUrl;
    }
    return imgUrl;
  }
  /*
    returnToPageOrdering(){

       this.router.navigateByUrl("/minmenu/" + this.fromPage);
    }

    doOrder(content){
     //  this.dishRepository.doOrder(this.cart.formOrderModel(),  this.localStorageService.get( 'savedUserId'))
      this.dishRepository.sendBulkOrderMenuLine(this.shiftWeek, this.cart.formBulkOrderModel())
       .retryWhen(errors$ =>
          errors$.scan((errorCount, err) => {
              if(errorCount >= this.maxRetries) {
          throw err;
              }
              return errorCount + 1;
          }, 0)
          )
      .subscribe( (response) => {console.log("The order is done successfully");
      // this.toshowToast();
      // this.openVerticallyCentered(content);
       this.openWindowCustomClass(content);
//------------------------Add--------------------------------------------------
       let simpleOderModelArray = [];
       this.localStorageService.set(this.simpleOderModelArrayId, simpleOderModelArray);
       this.disabledAfterOrder = true;
       this.localStorageService.set( 'currentChildRoute'+ 'disabledAfterOrder', true);
//------------------------------------------------------------------------------
       this.setTimer(this.delay).subscribe(update => {

          console.log("time for modal is expired" );
          this.modalService.dismissAll();

        });

       },
        (error)=> {console.log("The order is invalid")});

    }

*/

  /*   setTimer(interval:number):Observable<number> {

   //  this.source$ = timer(interval);

   //  return this.source$;
     return timer(interval);

    }
  openVerticallyCentered(content) {
      this.modalService.open(content, { centered: true} );
      }

   openWindowCustomClass(content) {
    this.modalService.open(content, { centered: true, windowClass: 'darkModalCart' });
     }

  private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return  `with: ${reason}`;
        }
    }*/

  getDayInDanish(day: string) {
    return this.localeDaDKService.translateDayToDanishsFull(day);
  }

  rightNow(){
    let rightNow = this.dateService.getRightNow()
    return rightNow
  }
  
}
