import { Injectable } from '@angular/core';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { LocalStorageService } from '../../service/localStorage.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { environment } from './../../../environments/environment';
import { NewsItem } from '../../model/newsItemModel.model';
import { Subscription } from 'rxjs/Rx';
import { ActivatedRoute } from '@angular/router';

class FileNameItem {
  fileNameEl: string;
  fileStatus: string;

  constructor(fileNameElVar: string, fileStatusVar: string) {
    this.fileNameEl = fileNameElVar;
    this.fileStatus = fileStatusVar;
  }
}

@Component({
  selector: "news-root",
  templateUrl: 'news.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['news.component.css'],
})
export class NewsComponent {
  private closeResult: string;
  private file: any;
  private files: any[];
  private tempFiles: any[];
  private mes: string;
  private fileName: string;
  private fileType: string;
  private fileSize: number;
  private maxFileSize: number;
  private maxFileQuantity: number;
  private validFileStatus: string;
  private ValidFileTypePattern: RegExp;
  private fileNameArray: FileNameItem[];
  private tempItem: FileNameItem;
  private uploadImgPath: string;
  public pictureName: string;
  public topArr: NewsItem[];
  public newsArr: NewsItem[];
  private title: string;
  private text: string;
  public pictureText: string;
  obsSubscription: Subscription;

  constructor(
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute
  ) {
    this.localStorageService.set('currentChildRoute', 'news');
    this.mes = 'NO';
    this.maxFileSize = 1000000;
    this.maxFileQuantity = 100;
    this.ValidFileTypePattern = /.png|.jpg|.jpeg|.svg/;
    this.fileNameArray = [];
    this.tempItem = new FileNameItem('', '');
    this.uploadImgPath = '';
    this.newsArr = [];
    this.title = '';
    this.text = '';
    this.pictureText = 'Upload news picture here';
    this.obsSubscription = this.activatedRoute.data
      .subscribe(
        (data) => {
        console.log("🚀 ~ file: news.component.ts ~ line 75 ~ NewsComponent ~ data", data)
          this.loadNews(data['news']);
      }
    )
    window.scrollTo(0, 0);
  }

  ngAfterContentChecked() {
    if (!environment.debugMode) {
      console.clear();
    }
  }
  ngOnDestroy() {
    this.obsSubscription.unsubscribe();
  }

  loadNews(news: NewsItem[]) {
      for (const item of news) {
        item.imageUrl = environment.baseUrl +  item.imageUrl;

        if (item.pdfUrl) {
          item.pdfUrl = environment.baseUrl +  item.pdfUrl;
        }
      }

    /*news[0].text='validImage should be true if src= returns an image. Otherwise it would return false and only the second img tag should show. There are obvious work arounds like storing a list of all valid image sources, but Im thinking there is a better way to accomplish this. Any suggestions on the best way to implement this in Angular2 would be greatly appreciated. validImage should be true if src= returns an image. Otherwise it would return false and only the second img tag should show. There are obvious work arounds like storing a list of all valid image sources, but Im thinking there is a better way to accomplish this. Any suggestions on the best way to implement this in Angular2 would be greatly appreciated.'
    news = news.concat(news);
    news = news.concat(news);
    news.push(news[0]);
    news.push(news[0]);
    let topNews = JSON.parse(JSON.stringify(news[0]));
    topNews.isTopStory = true;
    topNews.title = 'Top news'
    news.push(topNews);*/
    this.newsArr = this.getNewsArr(news);
    this.topArr = this.getTopNewsArr(news)
  }
  getNewsImage(newsItem: NewsItem) {
    let imgUrl;
    if (newsItem && newsItem.imageUrl) {
      return newsItem.imageUrl;
    } else {
      return 'assets/img/defaultImg.png';
    }
  }

  getTopNewsArr(newsArr: NewsItem[]): NewsItem[] {
    console.log("🚀 ~ file: news.component.ts ~ line 122 ~ NewsComponent ~ getTopNewsItem ~ newsArr", newsArr)
    return newsArr.filter(
      newsItem => {
        return newsItem.isTopStory == true;
      }
    );
  }

  getNewsArr(newsArr: NewsItem[]): NewsItem[] {
    console.log("🚀 ~ file: news.component.ts ~ line 122 ~ NewsComponent ~ getTopNewsItem ~ newsArr", newsArr)
    return newsArr.filter(
      newsItem => {
        return newsItem.isTopStory == false;
      }
    );
  }

  getNewsDate(newsItem: NewsItem) {

  }
  openNewsEditor(content) {
    //this.openVerticallyCentered(content);
    this.openWindowCustomClass(content);
  }

  openVerticallyCentered(content) {
    // this.modalService.open(content, { centered: true} );
    this.modalService.open(content, { centered: true });
  }

  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal' });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onFileChange(event: any) {
    this.files = event.target.files;

    for (var i = 0; i < this.files.length; i++) {
      var f = this.files[i];
      this.fileName = f.name;
      this.fileType = f.type;
      this.fileSize = f.size;

      this.fileNameArray.push(
        new FileNameItem(f.name, this.isValidFile(this.fileName, this.fileSize))
      );
      console.log('this.fileNameArray' + JSON.stringify(this.fileNameArray));
    }
    this.isValidFilesQuantity();
    this.showPreviewImage(event);
    event.target.value = '';
  }

  isValidFile(fileNameVar: string, fileSizeVar: number): string {
    console.log('fileNameVar:' + fileNameVar);
    if (
      this.ValidFileTypePattern.test(fileNameVar) &&
      fileSizeVar < this.maxFileSize
    ) {
      this.validFileStatus = 'Ok';
      return 'Ok';
    } else {
      this.mes = 'NO';
      this.validFileStatus = 'Invalid file';
      return 'Invalid file';
    }
  }

  isValidFilesQuantity(): boolean {
    if (this.files.length <= this.maxFileQuantity) {
      this.mes = 'OK_FilesQuantity';
      console.log('this.files.length if OK:' + this.files.length);
      return true;
    } else {
      console.log('this.files.length Before:' + this.files.length);
      this.validFileStatus = 'Invalid files quantity';
      console.log('this.files.length After:' + this.files.length);
      return false;
    }
  }

  showPreviewImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.uploadImgPath = event.target.result;
        // console.log("this.uploadImgPath", this.uploadImgPath);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  save() {
    this.newsArr.push(new NewsItem(1, this.uploadImgPath, this.title, this.text, '', ''));
    //console.log("News is saved", JSON.stringify(this.newsArr));
    console.log('News is saved');
    this.modalService.dismissAll();
  }
  cansel() {
    this.fileNameArray = [];
    this.uploadImgPath = '';
    this.title = '';
    this.text = '';
    console.log(
      'In Cansel this.fileNameArray=' + JSON.stringify(this.fileNameArray)
    );
  }

  getImgUrl(uploadImgPath: string): string {
    console.debug('[getImgUrl] uploadImgPath:' + uploadImgPath);
    let imgUrl;
    if (uploadImgPath && uploadImgPath != '') {
      let img = document.getElementById('imageNews');
      img.style.opacity = '1';
      let roundBtn = document.getElementById('roundBtn');
      roundBtn.style.opacity = '0';
      imgUrl = uploadImgPath;
      console.debug('[getImgUrl] imgUrl', imgUrl);
      this.pictureText = 'Change picture';
      return imgUrl;
    } else {
      console.debug('[getImgUrl] imgUrl', 'assets/img/PictureNews.png');
      let img = document.getElementById('imageNews');
      img.style.opacity = '0';
      let roundBtn = document.getElementById('roundBtn');
      roundBtn.style.opacity = '1';
      this.pictureText = 'Upoload news picture here';
      return 'assets/img/PictureNews.png';
    }
  }
}
