import { Dish } from "./dish.model";
//import { DishDishGroup } from "./dishDishGroup.model";


export class PortionSize  {
    constructor(
    		public	normal:number=1,
            public	small:number=2				

           	) { }
}




