import { Component, ViewEncapsulation } from "@angular/core";
import { Cart } from "../cart-model/cart.model";
import { RestDataSource } from "../../repository/rest.datasource";
import {LocalStorageService} from "../../service/localStorage.service";
import {DateService} from "../../service/date.service";
import {LocaleDaDKService} from "../../service/locale-da-dk.service";
import { Router } from "@angular/router";
import {DishRepository} from "../../repository/dish.repository";
import { OrderModel } from "../../model/orderModel.model";
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { Observable } from "rxjs/Observable";
import {NavigationService} from "../../service/navigation.service";

@Component({
    templateUrl: "cartDetail.component.html",
    encapsulation: ViewEncapsulation.None,
    styleUrls: ["cartDetail.component.css"]

})
export class CartDetailComponent {
  public fromPage:string;
  public closeResult: string;
  public disabledAfterOrder: boolean;
  private delay:number;
  defaultImageUrl:string;
  public shiftWeek: number;
  public simpleOderModelArrayId:string;
  private departmentId: number;
  public maxRetries: number;
  public currentDeliveryDay:string;
  public reqestInProgress:boolean=false;

	constructor(public cart: Cart, private dataSource: RestDataSource,
		private localStorageService: LocalStorageService,
    private dateService:DateService, private router: Router,
    private dishRepository: DishRepository,
    private modalService: NgbModal,
    private localeDaDKService:LocaleDaDKService,
    public navigationService:NavigationService
    ) { }





    ngOnInit(){


        this.defaultImageUrl = 'assets/img/defaultImg.png'

        this.delay = 2000;
        this.maxRetries = 2;
        this.reqestInProgress = false;
        this.fromPage = this.localStorageService.get( 'parentCartRoute');
        this.currentDeliveryDay = this.getCurrentDeliveryDay(this.fromPage);
        this.shiftWeek = this.localStorageService.get('shiftWeek'+ this.localStorageService.get( 'parentCartRoute'));

        var shiftWeekNumber = this.dateService.getShiftWeek(this.shiftWeek);


//-----------------------ADD----------------------------------------------------------
       this.disabledAfterOrder = this.localStorageService.get( this.localStorageService.get( 'parentCartRoute') + 'disabledAfterOrder');
       console.log("(CartDetailComponent) [ngOnInit] this.disabledAfterOrder", this.disabledAfterOrder);
       this.departmentId = this.localStorageService.get('currentdepartmentId');
       this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'parentCartRoute') + shiftWeekNumber + this.departmentId;

//---------------------------------------------------------------------------------
       this.cart.updateLinesFromLocalStorageForDeliveryDay(shiftWeekNumber);
       this.localStorageService.set( 'currentChildRoute', "cart");

    }

     ngOnDestroy(){
     if(this.disabledAfterOrder){

     }
     else this.cart.deleteDishesWithZeroSumsFromLocalStorage();

    }

    getCurrentDeliveryDay(fromPage:string){
       if(fromPage=="extra"){
           return this.localStorageService.get('toggledDayExtra');
          }else if(fromPage=="frokostpakker"){
          return this.localStorageService.get('toggledDayFroko');
      }

    }
    getImgUrl(imageUrl:string):string{
      let imgUrl:string='';
      if(imageUrl){
        imgUrl = this.dataSource.baseUrl + imageUrl
       }else{
        imgUrl = this.defaultImageUrl
        }
       return imgUrl;
    }

    returnToPageOrdering(){

 //------------------------Add--------------------------------------------------
      if(this.disabledAfterOrder){
        let simpleOderModelArray = [];
       this.localStorageService.set(this.simpleOderModelArrayId, simpleOderModelArray);

      }
       //------------------------------------------------------------------------------
       this.router.navigateByUrl("/minmenu/" + this.fromPage);
    }

  doOrder(content) {
    console.log("CartDetailComponent -> doOrder -> doOrder")
    if (this.reqestInProgress == false) {
      this.reqestInProgress = true;
      this.dishRepository.sendBulkOrderMenuLine(this.shiftWeek, this.cart.formBulkOrderModel())

        .subscribe((response) => {
          console.log("The order is done successfully");
          this.openWindowCustomClass(content);
    //------------------------Add--------------------------------------------------
          this.disabledAfterOrder = true;
          this.localStorageService.set( this.localStorageService.get( 'parentCartRoute') + 'disabledAfterOrder', true);
          this.cart.isQuantityChanged = false;
    //------------------------------------------------------------------------------
          this.setTimer(this.delay).subscribe(update => {

              console.log("time for modal is expired" );
              this.reqestInProgress = false;
              this.modalService.dismissAll();
            });
       },
        (error)=> {
          console.log("The order is invalid")
    //------------------------Add--------------------------------------------------
          this.localStorageService.set( this.localStorageService.get( 'parentCartRoute') + 'disabledAfterOrder', true);
          this.cart.isQuantityChanged = false;
          this.reqestInProgress = false;
    //------------------------------------------------------------------------------

          });
    }
  }

  setTimer(interval:number):Observable<number> {

     return timer(interval);

    }
  openVerticallyCentered(content) {
      this.modalService.open(content, { centered: true} );
      }

   openWindowCustomClass(content) {
    this.modalService.open(content, { centered: true, windowClass: 'darkModalCart' });
     }

  private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return  `with: ${reason}`;
        }
    }

    getDayInDanish(day:string){
      return this.localeDaDKService.translateDayToDanishsFull(day);

    }

    goCartPrint(){
    console.log("[goCartPrint]");
    this.navigationService.goToLinkInNewTab('minmenu/print/printcartcomponent');
  }

}
