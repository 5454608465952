export class NewsItem {
  id: number;
  imageUrl: string;
  title: string;
  text: string;
  date: string;
  pdfUrl: string;
  isTopStory: boolean;
  createdAtUtc: string;

  constructor(
    id: number,
    imageUrl: string,
    title: string,
    newsText: string,
    createdAtUtc: string,
    pdfUrl: string,
    isTopStory?: boolean,

  ) {
    this.id = id;
    this.imageUrl = imageUrl;
    this.title = title;
    this.text = newsText;
    this.createdAtUtc = createdAtUtc;
    this.pdfUrl = pdfUrl;
    this.isTopStory = isTopStory;

  }
}
