import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class DataShareService {
  public onClickMenuImg$: Observable<any>;
  public dispayIfPageForPrinting: string;
  public menuLoaded: boolean;
  public canToogleFromPopUp: boolean = true;
  public disabledAfterOrderExtra: boolean = false;
  // public disabledAfterOrderFroko:boolean=false;

  constructor() {
    this.menuLoaded = false;
    this.dispayIfPageForPrinting = '';
  }

  sendFromEvent(obsrvTemp$: Observable<any>) {
    console.log('obsrvTemp$', JSON.stringify(obsrvTemp$));

    this.onClickMenuImg$ = obsrvTemp$.pluck('target', 'id');
  }
}
