import { Injectable } from "@angular/core";
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { AuthService } from "../service/auth.service";
import {LocalStorageService} from "../service/localStorage.service"; 
import { Observable } from "rxjs/Observable";

export interface ComponentCanDeactivate{
    canDeactivate: () => boolean | Observable<boolean>;
}

 @Injectable()
export class UnsavedGuard implements CanDeactivate<ComponentCanDeactivate>{
 
     public currentLocationPath:string;
     constructor(private router: Router,
                private localStorageService: LocalStorageService,
                private auth: AuthService,
                private location: Location,
               ) {
        //this.currentLocationPath =  this.getPath();
                 }

    /*canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
     
        if (!this.auth.isAuthenticated()) {
            console.log("LoginGuard: You are not authenticated ");
            this.router.navigateByUrl("/auth");
            return false;
        }
        
        return true;
               
    }*/

    canDeactivate(component: ComponentCanDeactivate, 
        currentRoute: ActivatedRouteSnapshot, 
        currentState: RouterStateSnapshot, 
        nextState?: RouterStateSnapshot):Observable<boolean> | boolean{
     if(component.canDeactivate()==true){
        console.log("[canDeactivate] nextState", nextState);
        return true;
      }else{
       // this.router.navigateByUrl(this.currentLocationPath);
        console.log("[canDeactivate] nextState", nextState.url);
        this.localStorageService.set("nextState", nextState.url);
        return false;
      }
      //return component.canDeactivate ? component.canDeactivate() : true;
    }
    getPath( ) {
          //console.log("this.location.path()="+this.location.path())
         // return this.location.path();
        

        }
  
}