

export class MessageModalWindowModel {
  constructor(
    public modalType: string = '',
    public modalTitle: string = '',
    public modalBodyText: string = '',
    public confirmBtnText: string = 'Ok',
    public modalData: any = []
  ) {}
}


