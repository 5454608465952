
export class SimpleOderModel {
  orderDishId: number;
  orderNumber: number;
  sumOder: number;
  Day: string;
  dishId: number;
  name: string;
  imageUrl: string;
  category: string;
  subcategory: string;
  print: boolean;
  doSend: boolean;
  menuNr: number;
  sideDish: boolean;
  line: number;
  dietTypeNr: number;
  dietTypeName: string;
  portionSize: number;
  orderNr: number;
  customerNr: number;
  changed: boolean;
  orderNotPermit: boolean;
  enkeltPortion: boolean;
  sended: boolean;


   constructor(orderDishId: number, orderNumber: number, sumOder: number, Day: string,
               dishId: number, name: string, imageUrl: string, category: string,
               subcategory: string,
               { menuNr: menuNr, line: line,  print: print, doSend: doSend, sideDish: sideDish,
                dietTypeNr: dietTypeNr, dietTypeName: dietTypeName, portionSize: portionSize,
                orderNr: orderNr, customerNr: customerNr, changed: changed, orderNotPermit: orderNotPermit,
                enkeltPortion: enkeltPortion, sended: sended}: {
                menuNr: number, line: number, print?: boolean,
                doSend?: boolean, sideDish?: boolean,
                dietTypeNr?: number, dietTypeName?: string, portionSize?: number,
                orderNr?: number, customerNr?: number, changed?: boolean, orderNotPermit?: boolean,
                enkeltPortion?: boolean, sended?: boolean
              }
   ) {

    this.orderDishId = orderDishId;
    this.orderNumber = orderNumber;
    this.sumOder = sumOder;
    this.Day = Day;
    this.dishId = dishId;
    this.name = name;
    this.imageUrl = imageUrl;
    this.category = category;
    this.subcategory = subcategory;
    this.print = print;
    this.doSend = doSend;
    this.menuNr = menuNr;
    this.sideDish = sideDish;
    this.line = line;
    this.dietTypeNr = dietTypeNr;
    this.dietTypeName = dietTypeName;
    this.portionSize = portionSize;
    this.orderNr = orderNr;
    this.customerNr = customerNr;
    this.changed = changed;
    this.orderNotPermit = orderNotPermit;
    this.enkeltPortion = enkeltPortion;
    this.sended = sended;
  }

}
