import { Input, Component,  ViewChild } from "@angular/core";
import { OnInit , DoCheck, OnDestroy} from '@angular/core';
import { AuthService } from "../service/auth.service";
import {AuthComponent} from "./auth.component";
import {MenuComponent} from "../minmenu/menu.component";
import { RestDataSource } from "../repository/rest.datasource";
import {LocalStorageService} from "../service/localStorage.service";
import {DataShareService} from "../service/dataShare.service";
import {DateService} from "../service/date.service";
import {DishRepository} from "../repository/dish.repository";
import { DepartmentModel } from "../model/departmentModel.model";
import {  ActivatedRoute,
		  Event,
		  NavigationCancel,
		  NavigationEnd,
		  NavigationError,
		  NavigationStart, Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { timer } from 'rxjs';
import { Http, Request, RequestMethod , Headers} from "@angular/http";
import {Subscription } from 'rxjs/Subscription';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../environments/environment';


@Component({

     templateUrl: "admin.component.html",
     styleUrls: ["admin.component.css"],


})
export class AdminComponent implements OnInit {

		admUserName: string;
		currentRoute: string;
		soarceLocalStorage: Observable<number>;
		showUserName: boolean;
		public userInfoResponse:any[] = [];
		public orderingDates:any[];
		obsUserInfoOrderingModelSubscription: Subscription;
		public userInfoOrderingModel:any[] = [];
		public loading:boolean;
		public closeResult: string;
		public toogledMenuFlag:boolean;
		public toogledBulkorderFlag:boolean;
		public toogledFrokostpakkerFlag:boolean;
		public toogledExtraFlag:boolean;
		public toogledPrintFlag:boolean;
		public toogledDialogFlag:boolean;
		public toogledNewsFlag:boolean;
		public dispayIfPageForPrinting;
		public selectedModeAdmin: any;
		public userDepartments: DepartmentModel[];
		public currentdepartmentId: number;
		public defaultDepartment:number;


	 constructor(private router: Router, private auth: AuthService,
	 	private localStorageService: LocalStorageService,
	 	private datasource: RestDataSource,
	 	private dateService:DateService, private dishRepository: DishRepository,
	 	private activatedAdminRoute: ActivatedRoute,
	 	private modalService: NgbModal,
	 	public dataShareService:DataShareService) {  }


	   ngOnInit(): void {

//------------------------Flags Init ------------------------------------//
		this.toogledMenuFlag = true;
		this.toogledBulkorderFlag = false;
		this.toogledFrokostpakkerFlag = false;
		this.toogledExtraFlag = false;
		this.toogledPrintFlag = false;
		this.toogledNewsFlag = false;
		this.toogledDialogFlag = false;
		this.toogledNewsFlag = false;
//----------------------Departments---------------------------------------
		if(this.localStorageService.get('currentdepartmentId')!=null){
		this.defaultDepartment = this.localStorageService.get('currentdepartmentId');

	}

	 	this.loading = false;

//---------------------Loader-------------------
		  var loader = document.getElementById('loader');
       	  loader.style.visibility = 'visible';

	    this.localStorageService.set('shiftWeek', 0 );
		console.debug("In AdminComponent ngOnInit() "  );
		this.currentRoute = this.localStorageService.get( 'currentChildRoute');

		this.router.navigateByUrl("/minmenu/"+ this.currentRoute);
		this.localStorageService.set('simpleOderModelArray', null);
		var id = this.localStorageService.get( 'savedUserId');

        this.dishRepository.getUserInfo(id)
        .subscribe(data => {


	      this.userInfoResponse.push(data);
	      this.admUserName = this.userInfoResponse[0]["userName"];
		  	this.localStorageService.set('user', data);
	      	this.localStorageService.set('admUserName', this.admUserName);
	      	this.userDepartments = this.userInfoResponse[0]['departments'];
	      	console.debug("this.userDepartments", this.userDepartments);
	      	this.currentdepartmentId = this.getDepartmentId();

	      	console.debug("this.currentdepartmentId", this.currentdepartmentId);
			
			this.datasource.getCompany(this.userDepartments[0].companyId).subscribe(
				(data) => {
				console.log(data)
				this.localStorageService.set('company',data);
				})

	      	},
	      	error => {
	      		console.debug("Authorization has been denied for this request");
	      		this.auth.authenticationClear()
	      		this.router.navigateByUrl("/auth");

	      }

	      	);

        //-----------------------GET  UserInfoOrderingModel ---------------------------------------------------------


 		this.obsUserInfoOrderingModelSubscription = this.dishRepository.getUserInfoOrderingModel(id)
 		.subscribe(data => {

	      this.userInfoOrderingModel=(data);
	    this.localStorageService.set('userInfoOrderingModel', this.userInfoOrderingModel); //save for other Components in LocalStorage

	      },
	      	error => {
	      		console.debug("getUserInfoOrderingModel error");
	      }

	      	);
/*-------------------------------------------------------------------------------*/
 	this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {

          this.loading = true;
        //-----------------Loader --------------------------------------
	   	var loader = document.getElementById('loader');
       	loader.style.visibility = 'visible';
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          var loader = document.getElementById('loader');
       	  loader.style.visibility = 'hidden';
          this.getDismissReason(1);
          break;
        }
        default: {
          break;
        }
      }
    });

    if(!environment.debugMode){
	 	console.clear();
	}
/*----------------------------------------------------------------------------*/

	   }

	   ngAfterContentChecked(){
		  this.onClickMenuImgBtnBar();
		  if(!environment.debugMode){
	 				console.clear();
	 			}
	   }

	   ngAfterViewInit(){

	   }

	   ngOnDestroy(){
	   	if(!((this.localStorageService.get( 'isRememberMe')) && (this.localStorageService.get( 'isRememberMe')==true))){

        	this.obsUserInfoOrderingModelSubscription.unsubscribe();
	   		this.localStorageService.set('shiftWeek', 0 );
	   		this.localStorageService.clear();
        }


	   }



		logOut(){
			if(this.localStorageService.get( 'isRememberMe')){
				this.router.navigateByUrl("/logout");
				}else{
					this.auth.logOut();
				}

		}
		toshowUserName(){
			return true;
		}
//-------------------Buttons LeftSideBar----------------------------------------------
		//onClickMenuImgBtnBar(itemMenu:string){
		onClickMenuImgBtnBar(){
			if(this.dataShareService.menuLoaded){

		  	this.dataShareService.onClickMenuImg$
				.subscribe(
				elementId => {
					if(elementId){
					var itemMenu = elementId;
					console.debug("itemMenu", elementId);

					switch(itemMenu){
					case 'bulkorderFigure':{
						this.toogleBulkorder();
						break;
					}
					case 'bulkorderImg':{
						this.toogleBulkorder();
						break;
					}
					case 'bulkorderFigcaption':{
						this.toogleBulkorder();
						break;
					}
					case 'bulkorderSpan':{
						this.toogleBulkorder();
						break;
					}

					case 'frokostpakkerFigure':{
						this.toogleFrokostpakker();
						break;
					}
					case 'frokostpakkerImg':{
						this.toogleFrokostpakker();
						break;
					}
					case 'frokostpakkerFigcaption':{
						this.toogleFrokostpakker();
						break;
					}
					case 'frokostpakkerSpan':{
						this.toogleFrokostpakker();
						break;
					}

					case 'extraFigure':{
						this.toogleExtra();
						break;
					}
					case 'extraImg':{
						this.toogleExtra();
						break;
					}
					case 'extraFigcaption':{
						this.toogleExtra();
						break;
					}
					case 'extraSpan':{
						this.toogleExtra();
						break;
					}


					case 'printFigure':{
						this.tooglePrint();
						break;
					}
					case 'printImg':{
						this.tooglePrint();
						break;
					}
					case 'printFigcaption':{
						this.tooglePrint();
						break;
					}
					case 'printSpan':{
						this.tooglePrint();
						break;
					}

					case 'newsFigure':{
						this.toogleNews();
						break;
					}
					case 'newsImg':{
						this.toogleNews();
						break;
					}
					case 'newsFigcaption':{
						this.toogleNews();
						break;
					}
					case 'newsSpan':{
						this.toogleNews();
						break;
					}


					case 'dialogFigure':{
						this.toogleDialog();
						break;
					}
					case 'dialogImg':{
						this.toogleDialog();
						break;
					}
					case 'dialogFigcaption':{
						this.toogleDialog();
						break;
					}
					case 'dialogSpan':{
						this.toogleDialog();
						break;
					}
					default: break;

				}
			  }
						});
		  this.dataShareService.menuLoaded = false;
		  }
		}


		toogleMenu(){
			if(this.dataShareService.canToogleFromPopUp){
				this.toogledMenuFlag = true;
				this.toogledBulkorderFlag = false;
				this.toogledFrokostpakkerFlag = false;
				this.toogledExtraFlag = false;
				this.toogledPrintFlag = false;
				this.toogledNewsFlag = false;
				this.toogledDialogFlag = false;
			}
			this.router.navigateByUrl("/minmenu/menu");
		}

		toogleBulkorder(){
			if(this.dataShareService.canToogleFromPopUp){
				this.toogledMenuFlag = false;
				this.toogledBulkorderFlag = true;
				this.toogledFrokostpakkerFlag = false;
				this.toogledExtraFlag = false;
				this.toogledPrintFlag = false;
				this.toogledNewsFlag = false;
				this.toogledDialogFlag = false;
			}

			this.router.navigateByUrl("/minmenu/bulkorder");
		}

		toogleFrokostpakker(){
			if(this.dataShareService.canToogleFromPopUp){
				this.toogledMenuFlag = false;
				this.toogledBulkorderFlag = false;
				this.toogledFrokostpakkerFlag = true;
				this.toogledExtraFlag = false;
				this.toogledPrintFlag = false;
				this.toogledNewsFlag = false;
				this.toogledDialogFlag = false;
			}

			this.router.navigateByUrl("/minmenu/frokostpakker");
		}

		toogleExtra(){
			if(this.dataShareService.canToogleFromPopUp){
				this.toogledMenuFlag = false;
				this.toogledBulkorderFlag = false;
				this.toogledFrokostpakkerFlag = false;
				this.toogledExtraFlag = true;
				this.toogledPrintFlag = false;
				this.toogledNewsFlag = false;
				this.toogledDialogFlag = false;
			}

			this.router.navigateByUrl("/minmenu/extra");
		}

		tooglePrint(){
			if(this.dataShareService.canToogleFromPopUp){
				this.toogledMenuFlag = false;
				this.toogledBulkorderFlag = false;
				this.toogledFrokostpakkerFlag = false;
				this.toogledExtraFlag = false;
				this.toogledPrintFlag = true;
				this.toogledNewsFlag = false;
				this.toogledDialogFlag = false;
			}

			this.router.navigateByUrl("/minmenu/print");
		}

		toogleNews(){
			if(this.dataShareService.canToogleFromPopUp){
				this.toogledMenuFlag = false;
				this.toogledBulkorderFlag = false;
				this.toogledFrokostpakkerFlag = false;
				this.toogledExtraFlag = false;
				this.toogledPrintFlag = false;
				this.toogledNewsFlag = true;
				this.toogledDialogFlag = false;
			}

			this.router.navigateByUrl("/minmenu/news");
		}

		toogleDialog(){
			if(this.dataShareService.canToogleFromPopUp){
				this.toogledMenuFlag = false;
			this.toogledBulkorderFlag = false;
			this.toogledFrokostpakkerFlag = false;
			this.toogledExtraFlag = false;
			this.toogledPrintFlag = false;
			this.toogledNewsFlag = false;
			this.toogledDialogFlag = true;
			}

			this.router.navigateByUrl("/minmenu/dialog");
		}


//---------------------------------------------------------------------------------

openVerticallyCentered(content) {
      this.modalService.open(content, { centered: true} );
      }

private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
        } else {
          return  `with: ${reason}`;
        }
    }



getDepartmentId(){
	console.debug("In getDepartmentId");

	let departmentId;
	if(this.localStorageService.get('currentdepartmentId')!=null){
		departmentId = this.localStorageService.get('currentdepartmentId');
		console.debug("userDepartmentsId1", departmentId);
		this.defaultDepartment = departmentId;
		console.debug("this.defaultDepartment1", this.defaultDepartment);
	}else{
		departmentId = this.userDepartments[0]["id"];  //default department
		console.debug("userDepartmentsId2", departmentId);
		this.localStorageService.set('currentdepartmentId', departmentId);
		this.defaultDepartment = departmentId;
		console.debug("this.defaultDepartment2", this.defaultDepartment);
	}

	console.debug("departmentId", departmentId);
	return departmentId;

}

setDepartmentId(userDepartments:DepartmentModel[], departmentId:number){
	console.debug("selectDepartment");

	for(let userDepartmentItem in userDepartments){

		if(userDepartments[userDepartmentItem]["id"] == departmentId){

			this.localStorageService.set('currentdepartmentId', userDepartments[userDepartmentItem]["id"]);
			console.debug("this.localStorageService.set", userDepartments[userDepartmentItem]["id"]);
			this.defaultDepartment = userDepartments[userDepartmentItem]["id"];
			console.debug("Set this.defaultDepartment", this.defaultDepartment);
		}


	}

}

selectModeAdmin(departmentId: any){
		console.debug("In selectModeAdmin, departmentName=", departmentId);
		this.setDepartmentId(this.userDepartments, departmentId);
		this.router.navigateByUrl("/minmenu/"+ this.localStorageService.get( 'currentChildRoute'));

	}

}
