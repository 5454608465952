import { Injectable, ViewChild, ViewEncapsulation } from '@angular/core';
import { Component, Inject } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  ActivationEnd,
  Event,
  NavigationCancel,
  NavigationError,
  NavigationStart,
} from '@angular/router';
import { LocalStorageService } from '../../service/localStorage.service';
import { Cart } from '../../cart/cart-model/cart.model';
import { DateService } from '../../service/date.service';
import { AllocatedDishModel } from '../../model/allocatedDishModel.model';
import { OrderDish } from '../../model/orderDish.model';
import { DishAllocationWeekDataBase } from '../../model/dishAllocationWeek.database';
import { DishRepository } from '../../repository/dish.repository';
import { RestDataSource } from '../../repository/rest.datasource';
import { ModelResolver } from '../../resolver/model.resolver';
import { timer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { of, from } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/concatAll';
import 'rxjs/add/operator/concatMap';
// import "rxjs/add/operator/map";
import { map } from 'rxjs/operators';
import { _ } from 'underscore';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { SafePipeModule } from 'safe-pipe';
import { AuthService } from '../../service/auth.service';
import { LocaleDaDKService } from '../../service/locale-da-dk.service';
import * as moment from 'moment';
import { environment } from './../../../environments/environment';
import { DishAllocationsOrderDays } from '../../model/dishAllocationsOrderDays.model';
import { SimpleOderModel } from '../../model/simpleOder.model';
import { TopMessage } from 'src/app/model/top-message.model';
import { TopMessageComponent } from 'src/app/components/top-message/top-message.component';
import { TopMessageSimple } from 'src/app/model/top-message-simple.model';

@Component({
  selector: "frokostpakker-root",
  templateUrl: 'frokostpakker.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['frokostpakker.component.css'],
})
export class FrokostpakkerComponent {
  public sumOder: number;

  public weeks: string[];
  public dishAllocationsWeekResponse: any[] = [];
  public currentWeek: string;
  public lastIndex: number;
  obsSubscription: Subscription;
  public numberElementsInRow: number;
  public arrayDishesViewForDay: any[];
  public orderDishId: number;
  public orderDaysLeftInWeekArray: string[];
  public dishAllocationsOrderDays: DishAllocationsOrderDays;
  public currentToogledDay: string;
  public toggledMonday: boolean;
  public toggledWednesday: boolean;
  public toggledFriday: boolean;
  public toggledTuesday: boolean;
  public toggledThursday: boolean;
  public toggledSaturday: boolean;
  public SundayDate: string;
  public MondayDate: string;
  public TuesdayDate: string;
  public WednesdayDate: string;
  public ThursdayDate: string;
  public FridayDate: string;
  public SaturdayDate: string;
  public shiftWeekNumber: string;
  public shiftWeek: number;
  public needNgOninit: boolean;
  public simpleOderModelArray: SimpleOderModel[];
  public imgUrl: string;
  public tempArray: any[] = [];
  public categoryForFilter: any;
  public simpleOderModelArrayId: string;
  public orderingDates: any[];
  public userInfoOrderingModel: any[] = [];
  public currentDayNumber: number;
  public filterOn: boolean;
  public isOrderingTimeFlag: boolean;
  public isEmptyMonday: boolean;
  public isEmptyTuesday: boolean;
  public isEmptyWednesday: boolean;
  public isEmptyThursday: boolean;
  public isEmptyFriday: boolean;
  public isEmptySaturday: boolean;
  public isEmptySunday: boolean;
  private modalBtnFlag: string;
  public safeSrc: SafeResourceUrl;
  public modalBtnBackgroundColor: string;
  public modalBtnTextColor: string;
  public modalBtnTextWeight: string;
  private needUnsubscribe: boolean;
  public orderNotPermit: boolean;
  closeResult: string;
  private departmentId: number;
  public templateDays: string[];
  public isQuantitiesChanged: boolean = false;
  public disabledAfterOrder: boolean;
  public currentTabDay: string;
  public currentServingDay: string;
  public currentDishSalePrice: number;
  public currencyValue: string;
  public topMessageSimple: TopMessageSimple;

  constructor(
    private dataSource: RestDataSource,
    private localStorageService: LocalStorageService,
    private cart: Cart,
    private router: Router,
    private dateService: DateService,
    private dishRepository: DishRepository,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private auth: AuthService,
    private localeDaDKService: LocaleDaDKService
  ) {
    this.localStorageService.set('currentChildRoute', 'frokostpakker');
    this.localStorageService.set('parentCartRoute', 'frokostpakker');

    // this.localStorageService.set( 'toggledDayFroko', 'Tuesday');
    this.departmentId = this.localStorageService.get('currentdepartmentId');
    this.needUnsubscribe = false;
    this.dishAllocationsOrderDays = new DishAllocationsOrderDays();

    this.dishAllocationsWeekResponse = [];
  }

  ngOnInit(): void {
    this.needNgOninit = false;
    this.currencyValue = environment.currency;
    // NavTabs init
    /*this.toggledMonday = true;
		this.toggledWednesday = false;
		this.toggledFriday = false;*/
    let toggledDay = this.localStorageService.get('toggledDayFroko');
    this.currentTabDay = toggledDay;
    switch (toggledDay) {
      case 'Tuesday':
        this.toggledTuesday = true;
        this.toggledThursday = false;
        this.toggledSaturday = false;
        this.templateDays = ['Tuesday'];
        this.currentServingDay = 'Tuesday';
        break;
      case 'Thursday':
        // console.log("Thursday");
        this.toggledTuesday = false;
        this.toggledThursday = true;
        this.toggledSaturday = false;
        this.templateDays = ['Thursday'];
        this.currentServingDay = 'Thursday';

        break;
      case 'Saturday':
        // console.log("Saturday");
        this.toggledTuesday = false;
        this.toggledThursday = false;
        this.toggledSaturday = true;
        // this.templateDays = ["Saturday"];
        this.templateDays = ['Sunday'];
        this.currentServingDay = 'Sunday';

        break;
      default:
        this.localStorageService.set('toggledDayFroko', 'Tuesday');
        this.toggledTuesday = true;
        this.toggledThursday = false;
        this.toggledSaturday = false;
        this.templateDays = ['Tuesday'];
        this.currentServingDay = 'Tuesday';
    }

    console.log('[ngOnInit] this.currentServingDay', this.currentServingDay);

    // this.sumOder = this.localStorageService.get('sumOder');

    // ---------------------Date------------------------------------

    // this.shiftWeek = 0;
    // if NgOnInit after chabging week load from LocalStorage
    // if(this.needNgOninit){
    this.shiftWeek = this.localStorageService.get(
      'shiftWeek' + this.localStorageService.get('currentChildRoute')
    );

    // 	}
    console.log('this.shiftWeek=' + this.shiftWeek);
    this.currentDayNumber = this.dateService.getCurrentDayNumber();

    this.currentWeek = this.dateService.getCurrentWeek();

    this.shiftWeekNumber = this.dateService.getShiftWeek(this.shiftWeek);

    this.SundayDate = this.dateService.getISODateDDMMMforDay(
      'Sunday',
      this.shiftWeek
    );
    this.MondayDate = this.dateService.getISODateDDMMMforDay(
      'Monday',
      this.shiftWeek
    );
    this.TuesdayDate = this.dateService.getISODateDDMMMforDay(
      'Tuesday',
      this.shiftWeek
    );
    this.WednesdayDate = this.dateService.getISODateDDMMMforDay(
      'Wednesday',
      this.shiftWeek
    );
    this.ThursdayDate = this.dateService.getISODateDDMMMforDay(
      'Thursday',
      this.shiftWeek
    );
    this.FridayDate = this.dateService.getISODateDDMMMforDay(
      'Friday',
      this.shiftWeek
    );
    this.SaturdayDate = this.dateService.getISODateDDMMMforDay(
      'Saturday',
      this.shiftWeek
    );

    // --------------------- GET dishAllocations for All days in Week-----------------------------

    if (!this.needUnsubscribe) {
      this.obsSubscription = this.activatedRoute.data
        /*.pipe(
	  				map((data: any) => {console.error('Message', data)})
	  			)*/
        .subscribe(
          (data) => {
            this.dishAllocationsWeekResponse = [];
            this.dishAllocationsOrderDays = new DishAllocationsOrderDays();
            /*var t3 = moment().valueOf();	*/
            this.dishAllocationsWeekResponse = data['dishes'];

            this.sumOder = 0;

            console.log(
              'dishAllocationsWeekResponse=' +
                JSON.stringify(this.dishAllocationsWeekResponse)
            );
            this.departmentId = this.localStorageService.get(
              'currentdepartmentId'
            );

            if (this.dishAllocationsWeekResponse[0]) {
              this.orderNotPermit = this.checkPermittionsForOrder(
                this.dishAllocationsWeekResponse[0]['deadlineStatus'],
                this.dishAllocationsWeekResponse[0]['orderPermitStatus'],
                this.dishAllocationsWeekResponse[0]['orderOperation']
              );
              // this.orderNotPermit = false;
              this.cart.cartOrderNotPermit = this.orderNotPermit;

              console.log(
                '[checkPermittionsForOrder] this.orderNotPermit',
                this.orderNotPermit
              );
            }
            this.categoryForFilter = 'Frokost';

            let toggledDay1 = this.localStorageService.get('toggledDayFroko');

            switch (toggledDay1) {
              case 'Tuesday':
                {
                  /*if(this.dishAllocationsWeekResponse[0]===null || this.dishAllocationsWeekResponse[0]==undefined){
					this.isEmptyTuesday=true;
			      	console.log("this.isEmptyTuesday", this.isEmptyTuesday);
					}else{
					*/
                  if (this.dishAllocationsWeekResponse[0]) {
                    this.isEmptyTuesday = false;
                    // this.dishAllocationsOrderDays["Tuesday"]=(this.categoryFilter(0,this.dishAllocationsWeekResponse, this.categoryForFilter));
                    this.dishAllocationsOrderDays[
                      'Tuesday'
                    ] = this.dishAllocationsWeekResponse[0];
                    this.dishAllocationsOrderDays['Tuesday'][
                      'menuLines'
                    ] = JSON.parse(
                      JSON.stringify(
                        this.filterResponseByUndefined(
                          this.dishAllocationsWeekResponse[0]['menuLines']
                        )
                      )
                    );
                    // -----------------------------------------Order----------------------------------------------------------------------------------
                    this.simpleOderModelArrayId =
                      'simpleOderModelArray' +
                      this.localStorageService.get('currentChildRoute') +
                      this.shiftWeekNumber +
                      this.departmentId;
                    this.simpleOderModelArray = this.localStorageService.get(
                      this.simpleOderModelArrayId
                    );
                    if (!this.simpleOderModelArray) {
                      this.simpleOderModelArray = new Array();
                      this.sumOder = 0;
                    }

                    this.updateOrdersInLocalStorageFromResponse(
                      toggledDay1,
                      JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
                    );

                    if (_.isEmpty(this.dishAllocationsOrderDays['Tuesday'])) {
                      console.log(
                        'this.dishAllocationsOrderDays[\'Tuesday\'].length==0'
                      );
                      this.isEmptyTuesday = true;
                    }
                  } else {
                    this.isEmptyTuesday = true;
                    this.sumOder = 0;
                    console.log('this.isEmptyTuesday', this.isEmptyTuesday);
                  }
                  if (this.dishAllocationsWeekResponse[1]) {
                    // this.isEmptyWednesday=false;
                    this.isEmptyWednesday = true;
                    // this.dishAllocationsOrderDays["Wednesday"]=(this.categoryFilter(1,this.dishAllocationsWeekResponse, this.categoryForFilter));
                    this.dishAllocationsOrderDays[
                      'Wednesday'
                    ] = this.dishAllocationsWeekResponse[1];
                    this.dishAllocationsOrderDays['Wednesday'][
                      'menuLines'
                    ] = JSON.parse(
                      JSON.stringify(
                        this.filterResponseByUndefined(
                          this.dishAllocationsWeekResponse[1]['menuLines']
                        )
                      )
                    );
                    if (_.isEmpty(this.dishAllocationsOrderDays['Wednesday'])) {
                      console.log(
                        'this.dishAllocationsOrderDays[\'Wednesday\'].length==0'
                      );
                      this.isEmptyWednesday = true;
                    }
                  } else {
                    this.isEmptyWednesday = true;
                  }
                }

                break;
              case 'Thursday': {
                if (this.dishAllocationsWeekResponse[0]) {
                  this.isEmptyThursday = false;
                  // this.dishAllocationsOrderDays["Thursday"]=(this.categoryFilter(0,this.dishAllocationsWeekResponse, this.categoryForFilter));
                  this.dishAllocationsOrderDays[
                    'Thursday'
                  ] = this.dishAllocationsWeekResponse[0];
                  this.dishAllocationsOrderDays['Thursday'][
                    'menuLines'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.filterResponseByUndefined(
                        this.dishAllocationsWeekResponse[0]['menuLines']
                      )
                    )
                  );
                  // -----------------------------------------Order----------------------------------------------------------------------------------
                  this.simpleOderModelArrayId =
                    'simpleOderModelArray' +
                    this.localStorageService.get('currentChildRoute') +
                    this.shiftWeekNumber +
                    this.departmentId;
                  this.simpleOderModelArray = this.localStorageService.get(
                    this.simpleOderModelArrayId
                  );
                  if (!this.simpleOderModelArray) {
                    this.simpleOderModelArray = new Array();
                    this.sumOder = 0;
                  }

                  this.updateOrdersInLocalStorageFromResponse(
                    toggledDay1,
                    JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
                  );

                  if (_.isEmpty(this.dishAllocationsOrderDays['Thursday'])) {
                    console.log(
                      'this.dishAllocationsOrderDays[\'Thursday\'].length==0'
                    );
                    this.isEmptyThursday = true;
                  }
                } else {
                  this.isEmptyThursday = true;
                  this.sumOder = 0;
                }

                if (this.dishAllocationsWeekResponse[1]) {
                  // this.isEmptyFriday=false;
                  this.isEmptyFriday = true;
                  // this.dishAllocationsOrderDays["Friday"]=(this.categoryFilter(1,this.dishAllocationsWeekResponse, this.categoryForFilter));
                  this.dishAllocationsOrderDays[
                    'Friday'
                  ] = this.dishAllocationsWeekResponse[1];
                  this.dishAllocationsOrderDays['Friday'][
                    'menuLines'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.filterResponseByUndefined(
                        this.dishAllocationsWeekResponse[1]['menuLines']
                      )
                    )
                  );
                  if (_.isEmpty(this.dishAllocationsOrderDays['Friday'])) {
                    console.log(
                      'this.dishAllocationsOrderDays[\'Friday\'].length==0'
                    );
                    this.isEmptyFriday = true;
                  }
                } else {
                  this.isEmptyFriday = true;
                }

                if (this.dishAllocationsWeekResponse[0]) {
                  this.isEmptySaturday = false;
                  // this.dishAllocationsOrderDays["Saturday"]=(this.categoryFilter(2,this.dishAllocationsWeekResponse, this.categoryForFilter));
                  this.dishAllocationsOrderDays[
                    'Saturday'
                  ] = this.dishAllocationsWeekResponse[0];
                  this.dishAllocationsOrderDays['Saturday'][
                    'menuLines'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.filterResponseByUndefined(
                        this.dishAllocationsWeekResponse[0]['menuLines']
                      )
                    )
                  );
                  // -----------------------------------------Order----------------------------------------------------------------------------------
                  this.simpleOderModelArrayId =
                    'simpleOderModelArray' +
                    this.localStorageService.get('currentChildRoute') +
                    this.shiftWeekNumber +
                    this.departmentId;
                  this.simpleOderModelArray = this.localStorageService.get(
                    this.simpleOderModelArrayId
                  );
                  if (!this.simpleOderModelArray) {
                    this.simpleOderModelArray = new Array();
                    this.sumOder = 0;
                  }

                  this.updateOrdersInLocalStorageFromResponse(
                    toggledDay1,
                    JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
                  );

                  if (_.isEmpty(this.dishAllocationsOrderDays['Saturday'])) {
                    console.log(
                      'this.dishAllocationsOrderDays[\'Saturday\'].length==0'
                    );
                    this.isEmptySaturday = true;
                  }
                } else {
                  this.isEmptySaturday = true;
                  this.sumOder = 0;
                }

                break;
              }
              case 'Saturday': {
                if (this.dishAllocationsWeekResponse[0]) {
                  this.isEmptySaturday = false;
                  // this.dishAllocationsOrderDays["Saturday"]=(this.categoryFilter(2,this.dishAllocationsWeekResponse, this.categoryForFilter));
                  this.dishAllocationsOrderDays[
                    'Sunday'
                  ] = this.dishAllocationsWeekResponse[0];
                  this.dishAllocationsOrderDays['Sunday'][
                    'menuLines'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.filterResponseByUndefined(
                        this.dishAllocationsWeekResponse[0]['menuLines']
                      )
                    )
                  );
                  // -----------------------------------------Order----------------------------------------------------------------------------------
                  this.simpleOderModelArrayId =
                    'simpleOderModelArray' +
                    this.localStorageService.get('currentChildRoute') +
                    this.shiftWeekNumber +
                    this.departmentId;
                  this.simpleOderModelArray = this.localStorageService.get(
                    this.simpleOderModelArrayId
                  );
                  if (!this.simpleOderModelArray) {
                    this.simpleOderModelArray = new Array();
                    this.sumOder = 0;
                  }

                  this.updateOrdersInLocalStorageFromResponse(
                    'Sunday',
                    JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
                  );

                  if (_.isEmpty(this.dishAllocationsOrderDays['Sunday'])) {
                    console.log(
                      'this.dishAllocationsOrderDays[\'Saturday\'].length==0'
                    );
                    this.isEmptySaturday = true;
                  }
                } else {
                  this.isEmptySaturday = true;
                  this.sumOder = 0;
                }

                /*if(this.dishAllocationsWeekResponse[0]){
			      		//this.isEmptySunday=false;
			      		this.isEmptySunday=true;
			      		//this.dishAllocationsOrderDays["Sunday"]=(this.categoryFilter(0,this.dishAllocationsWeekResponse, this.categoryForFilter));
				      	this.dishAllocationsOrderDays["Sunday"]=this.dishAllocationsWeekResponse[0];
				      	this.dishAllocationsOrderDays["Sunday"]["menuLines"]=JSON.parse(JSON.stringify(this.filterResponseByUndefined(this.dishAllocationsWeekResponse[0]["menuLines"])));
				      	if(_.isEmpty(this.dishAllocationsOrderDays["Sunday"])){
				      		console.log("this.dishAllocationsOrderDays['Sunday'].length==0")

							this.isEmptySunday=true;
				      	}
			      	}else{
			      		this.isEmptySunday=true;

			      	}*/

                if (this.dishAllocationsWeekResponse[1]) {
                  // this.isEmptyMonday=false;
                  this.isEmptyMonday = true;
                  // this.dishAllocationsOrderDays["Monday"]=(this.categoryFilter(1,this.dishAllocationsWeekResponse, this.categoryForFilter));
                  this.dishAllocationsOrderDays[
                    'Monday'
                  ] = this.dishAllocationsWeekResponse[1];
                  this.dishAllocationsOrderDays['Monday'][
                    'menuLines'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.filterResponseByUndefined(
                        this.dishAllocationsWeekResponse[1]['menuLines']
                      )
                    )
                  );
                  if (_.isEmpty(this.dishAllocationsOrderDays['Monday'])) {
                    console.log(
                      'this.dishAllocationsOrderDays[\'Monday\'].length==0'
                    );
                    this.isEmptyMonday = true;
                  }
                } else {
                  this.isEmptyMonday = true;
                }

                break;
              }
              default:
                {
                  if (this.dishAllocationsWeekResponse[0]) {
                    this.isEmptyTuesday = false;
                    // this.dishAllocationsOrderDays["Tuesday"]=(this.categoryFilter(0,this.dishAllocationsWeekResponse, this.categoryForFilter));
                    this.dishAllocationsOrderDays[
                      'Tuesday'
                    ] = this.dishAllocationsWeekResponse[0];
                    this.dishAllocationsOrderDays['Tuesday'][
                      'menuLines'
                    ] = JSON.parse(
                      JSON.stringify(
                        this.filterResponseByUndefined(
                          this.dishAllocationsWeekResponse[0]['menuLines']
                        )
                      )
                    );
                    // -----------------------------------------Order----------------------------------------------------------------------------------
                    this.simpleOderModelArrayId =
                      'simpleOderModelArray' +
                      this.localStorageService.get('currentChildRoute') +
                      this.shiftWeekNumber +
                      this.departmentId;
                    this.simpleOderModelArray = this.localStorageService.get(
                      this.simpleOderModelArrayId
                    );
                    if (!this.simpleOderModelArray) {
                      this.simpleOderModelArray = new Array();
                      this.sumOder = 0;
                    }

                    this.updateOrdersInLocalStorageFromResponse(
                      toggledDay1,
                      JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
                    );

                    if (_.isEmpty(this.dishAllocationsOrderDays['Tuesday'])) {
                      console.log(
                        'this.dishAllocationsOrderDays[\'Tuesday\'].length==0'
                      );
                      this.isEmptyTuesday = true;
                    }
                  }
                  if (this.dishAllocationsWeekResponse[1]) {
                    this.isEmptyWednesday = false;
                    // this.dishAllocationsOrderDays["Wednesday"]=(this.categoryFilter(1,this.dishAllocationsWeekResponse, this.categoryForFilter));
                    this.dishAllocationsOrderDays[
                      'Wednesday'
                    ] = this.dishAllocationsWeekResponse[1];
                    this.dishAllocationsOrderDays['Wednesday'][
                      'menuLines'
                    ] = JSON.parse(
                      JSON.stringify(
                        this.filterResponseByUndefined(
                          this.dishAllocationsWeekResponse[1]['menuLines']
                        )
                      )
                    );
                    if (_.isEmpty(this.dishAllocationsOrderDays['Wednesday'])) {
                      console.log(
                        'this.dishAllocationsOrderDays[\'Wednesday\'].length==0'
                      );
                      this.isEmptyWednesday = true;
                    }
                  }
                }
                break;
            }
            console.log(
              'dishAllocationsOrderDays1=' +
                JSON.stringify(this.dishAllocationsOrderDays)
            );

            // ---------------------- get ArrayDishesViewController for Order Days------------

            this.lastIndex = 0;
            this.numberElementsInRow = 3;
            this.arrayDishesViewForDay = new Array();

            for (let day in this.dishAllocationsOrderDays) {
              if (
                this.dishAllocationsOrderDays[day] !== undefined &&
                this.dishAllocationsOrderDays[day] != [] &&
                this.dishAllocationsOrderDays[day]['menuLines'] != undefined
              ) {
                this.dishAllocationsOrderDays[
                  day
                ].arrayDishesViewForDay = this.getArrayDishesViewForDayController(
                  this.numberElementsInRow,
                  this.dishAllocationsOrderDays[day]['menuLines'].length
                );
              }
            }

            this.needNgOninit = true;
            this.needUnsubscribe = true;

            console.log(
              'this.dishAllocationsOrderDays22=' +
                JSON.stringify(this.dishAllocationsOrderDays)
            );
              //************ TopMessage ****************/
            let title = 'Bestillingsfrist<br> 3 hverdage';
            this.topMessageSimple = new TopMessageSimple(title);

          }
          // ,
          // error =>  console.log(`Caught: ${error}`)

      );

    }

    console.log(
      'this.dishAllocationsOrderDays3=' +
        JSON.stringify(this.dishAllocationsOrderDays)
    );
    // -----------------------GET  UserInfoOrderingModel ---------------------------------------------------------

    this.userInfoOrderingModel = this.localStorageService.get(
      'userInfoOrderingModel'
    );
    // console.log("FROKOCOMPONENT userInfoOrderingModel="+JSON.stringify( this.userInfoOrderingModel));
    this.orderingDates = this.userInfoOrderingModel['orderingDates'];
    console.log(
      'FROKOCOMPONENT this.orderingDates=' + JSON.stringify(this.orderingDates)
    );

    // this.filterOn = false;

    // -------------------------------------------------------------------------------------------------------------
    this.orderDishId = 0;

    // this.currentToogledDay = "Mandag";
    this.currentToogledDay = 'Tuesday';

    // -------------------------------------------Order------------------------------------------------------

    // this.isOrderingTimeFlag = false;  // !!! Temp
    this.isOrderingTimeFlag = true;
    this.simpleOderModelArrayId =
      'simpleOderModelArray' +
      this.localStorageService.get('currentChildRoute') +
      this.shiftWeekNumber +
      this.departmentId;
    // 	console.log("FROCO this.simpleOderModelArrayId="+this.simpleOderModelArrayId);
    this.simpleOderModelArray = this.localStorageService.get(
      this.simpleOderModelArrayId
    );
    if (!this.simpleOderModelArray) {
      this.simpleOderModelArray = new Array();
      this.sumOder = 0;
    } else {
      this.sumOder = 0;
      for (var oder in this.simpleOderModelArray) {
        if (this.simpleOderModelArray[oder]['Day'] == this.currentServingDay) {
          this.sumOder += this.simpleOderModelArray[oder].sumOder;
        }
      }
    }
    // console.log("orderingDates"+this.orderingDates);

    // console.log("isDayForOrder MONDAY" +this.isDayForOrder(this.orderingDates, "Monday", this.currentDayNumber, -this.shiftWeek*7));

    console.log(
      'this.isOrderingTimeFlag=' +
        this.isOrderingTime(this.userInfoOrderingModel, this.categoryForFilter)
    );

    /*	var t2 = moment().valueOf();

	 	console.log("1st block="+t2);
	 	console.log("1st block="+(t2-t1));*/
    // ----------------------Modal Window init -----------------------

    this.modalBtnFlag = 'dishInfo';
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube.com/embed/nOIwHaiojDI'
    );
    this.modalBtnBackgroundColor = '#FFFFFF';
    this.modalBtnTextColor = '#000000';
    this.modalBtnTextWeight = 'bold';

    // --------------------Button +/----------------------------------------

    // --------------------- disabledAfterOrder ------------------------------
    this.disabledAfterOrder = this.localStorageService.get(
      this.localStorageService.get('currentChildRoute') + 'disabledAfterOrder'
    );
    // this.disabledAfterOrder = this.localStorageService.get( 'parentCartRoute'+ 'disabledAfterOrder');


    if (!environment.debugMode) {
      console.clear();
    }
  }
  // -----------------------------------------------------------------------------------------------------
  // *****************************************************************************************************
  // ------------------------------- Component Internal Methods------------------------------------------

  ngAfterContentChecked() {
    if (this.needNgOninit) {
      this.ngOnInit();
    }
    if (!environment.debugMode) {
      console.clear();
    }
  }
  ngOnDestroy() {
    this.obsSubscription.unsubscribe();
  }

  // -----------------------------Methods--------------------------------------------------------------

  filterResponseByUndefined(responseDayMenuLines: any[]) {
    console.log('responseDayMenuLines', responseDayMenuLines);
    let responseDayFiltered = [];
    responseDayFiltered = responseDayMenuLines.filter((el) => {
      if (
        el['menuLine'] !== undefined &&
        el['menuLine'] != [] &&
        el['dish'] !== undefined &&
        el['dish'] != []
      ) {
        console.log('eldish1', el['dish']['dishPhotos']);
        if (el['dish']['dishPhotos'].length == 0) {
          console.log('eldish2', el['dish']['dishPhotos']);
          el['dish']['dishPhotos'].push({ imageUrl: '' });
        }

        return true;
      }
    });

    console.log('responseDayFiltered', responseDayFiltered);
    return responseDayFiltered;
  }

  categoryFilter(day: number, response: any[], category: any) {
    var dishArrForCategoty = Object.create({ category: [], categories: [] });
    var exit_loops = false;
    var lowerCaseCategory = category.toLowerCase();

    console.log(
      'In filterResponseByCategory lowerCaseCategory=' + lowerCaseCategory
    );
    for (var n = 0; n < response[day].length; n++) {
      // 	console.log("In filterResponseByCategory response[day][n][category][name]="+ response[day][n]["category"][name]);
      if (
        response[day][n]['category']['name'] == category ||
        response[day][n]['category']['name'] == lowerCaseCategory
      ) {
        // 	console.log("In categoryFilter. Category found")

        dishArrForCategoty.category = response[day][n]['category'];
        dishArrForCategoty.categories = response[day][n]['categories'];
        exit_loops = true;
        break;
      } else {
        // 	console.log("In categoryFilter. Category not found")
      }
    }

    // console.log("RETURN" + JSON.stringify(dishArrForCategoty));
    return dishArrForCategoty;
  }

  arrayDishesViewForDayByCategoryFilter(
    day: string,
    dishAllocationsOrderDays: DishAllocationsOrderDays,
    category: any
  ) {
    var viewArrayForCategoty = new Array();
    var exit_loops = false;

    for (var cat in dishAllocationsOrderDays[day]['categories']) {
      for (var property in dishAllocationsOrderDays[day]['categories'][cat][
        'category'
      ]) {
        if (
          dishAllocationsOrderDays[day]['categories'][cat]['category'][
            property
          ] == category
        ) {
          // console.log("Category is Found ");

          viewArrayForCategoty =
            dishAllocationsOrderDays[day]['categories'][cat][
              'arrayDishesViewForDay'
            ];

          exit_loops = true;
          break;
        } else {
          // console.log("Category is NotFound ");
        }
      }

      if (exit_loops) break;
    }

    return viewArrayForCategoty;
  }

  sub(cat: any, element: number, nameDay: string) {
    this.isQuantitiesChanged = true;
    this.disabledAfterOrder = false;
    this.localStorageService.set(
      this.localStorageService.get('currentChildRoute') + 'disabledAfterOrder',
      this.disabledAfterOrder
    );
    // this.localStorageService.set( 'parentCartRoute' + 'disabledAfterOrder', this.disabledAfterOrder);
    if (!this.orderNotPermit) {
      let orderFound = false;
      let catNumber;
      if (cat == 'Froko') {
        catNumber = 1;
      } else if (cat == 'Extra') {
        catNumber = 2;
      }
      // var orderDishIdTCur = nameDay + cat + element.toString();
      var orderDishIdTCur = this.getOrderDishId(
        this.localStorageService.get('currentChildRoute'),
        this.shiftWeek,
        nameDay,
        catNumber,
        element
      );
      for (var oder in this.simpleOderModelArray) {
        if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
          orderFound = true;
          if (this.simpleOderModelArray[oder].orderNumber != 0) {
            this.simpleOderModelArray[oder].orderNumber -= 1;
            if (this.simpleOderModelArray[oder].orderNumber < 0) {
              this.simpleOderModelArray[oder].orderNumber = 0;
            }
            this.simpleOderModelArray[oder].sumOder -= 1;
            if (this.simpleOderModelArray[oder].sumOder < 0) {
              this.simpleOderModelArray[oder].sumOder = 0;
            }
          }
          this.simpleOderModelArray[oder].changed = true;
        }
      }

      this.localStorageService.set(
        this.simpleOderModelArrayId,
        this.simpleOderModelArray
      );

      // console.log("dishAllocationsOrderDays[nameDay][0][element]="+JSON.stringify(this.dishAllocationsOrderDays[nameDay][0][element]));

      // this.subProductToCart(this.dishAllocationsOrderDays[nameDay]["categories"][cat]["dishes"][element],1)
      this.updateCartFromLocalStorage();
      /*if(orderFound){
	 			this.sumOder-=1;
		  		if(this.sumOder<0){
		  		this.sumOder = 0;
		  		}
	 		}*/
      // this.sumOder = this.getSumOrder(this.simpleOderModelArray);
      //
      this.sumOder = this.getSumOrderForCurrentServingDay(
        this.simpleOderModelArray,
        this.currentServingDay
      );
    }
  }

  add(cat: any, element: number, nameDay: string) {
    // console.log("nameDay", nameDay);
    // console.log("element", element);
    // console.log("cat", cat);
    this.isQuantitiesChanged = true;
    this.disabledAfterOrder = false;
    this.localStorageService.set(
      this.localStorageService.get('currentChildRoute') + 'disabledAfterOrder',
      this.disabledAfterOrder
    );
    // this.localStorageService.set( 'parentCartRoute'+ 'disabledAfterOrder', this.disabledAfterOrder);

    if (!this.orderNotPermit) {
      let catNumber;
      if (cat == 'Froko') {
        catNumber = 1;
      } else if (cat == 'Extra') {
        catNumber = 2;
      }
      var orderFound = false;

      // 	var orderDishIdTCur = nameDay + cat + element.toString();

      var orderDishIdTCur = this.getOrderDishId(
        this.localStorageService.get('currentChildRoute'),
        this.shiftWeek,
        nameDay,
        catNumber,
        element
      );
      console.log('orderDishIdTCurNumber=' + orderDishIdTCur);

      if (this.simpleOderModelArray.length == 0) {
        // console.log("this.simpleOderModelArray.length="+this.simpleOderModelArray.length);
        this.simpleOderModelArray.push(
          new SimpleOderModel(
            orderDishIdTCur,
            1,
            1,
            nameDay,
            this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
              'menuLine'
            ]['productNr'],
            this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
              'menuLine'
            ]['productName'],
            this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
              'dish'
            ]['dishPhotos'][0]['imageUrl'],
            'no categoreis',
            'no subcategoreis',
            {
              dietTypeNr: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['dietTypeNr'],
              dietTypeName: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['dietTypeName'],
              portionSize: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['portionSize'],
              line: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['line'],
              orderNr: this.dishAllocationsOrderDays[nameDay]['orderNr'],
              customerNr: this.dishAllocationsOrderDays[nameDay]['customerNr'],
              menuNr: this.dishAllocationsOrderDays[nameDay]['menuNr'],
              changed: true,
              orderNotPermit: this.orderNotPermit,
              enkeltPortion: this.dishAllocationsOrderDays[nameDay][
                'menuLines'
              ][element]['menuLine']['enkeltPortion'],
            }
          )
        );
      } else {
        for (var oder in this.simpleOderModelArray) {
          if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
            orderFound = true;
            this.simpleOderModelArray[oder].orderNumber += 1;
            this.simpleOderModelArray[oder].sumOder += 1;
            this.simpleOderModelArray[oder].changed = true;
          }
        }

        if (!orderFound) {
          // 		console.log("Else="+element);
          this.simpleOderModelArray.push(
            new SimpleOderModel(
              orderDishIdTCur,
              1,
              1,
              nameDay,
              this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['productNr'],
              this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['productName'],
              this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'dish'
              ]['dishPhotos'][0]['imageUrl'],
              'no categoreis',
              'no subcategoreis',
              {
                dietTypeNr: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                  element
                ]['menuLine']['dietTypeNr'],
                dietTypeName: this.dishAllocationsOrderDays[nameDay][
                  'menuLines'
                ][element]['menuLine']['dietTypeName'],
                portionSize: this.dishAllocationsOrderDays[nameDay][
                  'menuLines'
                ][element]['menuLine']['portionSize'],
                line: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                  element
                ]['menuLine']['line'],
                orderNr: this.dishAllocationsOrderDays[nameDay]['orderNr'],
                customerNr: this.dishAllocationsOrderDays[nameDay][
                  'customerNr'
                ],
                menuNr: this.dishAllocationsOrderDays[nameDay]['menuNr'],
                changed: true,
                orderNotPermit: this.orderNotPermit,
                enkeltPortion: this.dishAllocationsOrderDays[nameDay][
                  'menuLines'
                ][element]['menuLine']['enkeltPortion'],
              }
            )
          );
        }
      }

      this.localStorageService.set(
        this.simpleOderModelArrayId,
        this.simpleOderModelArray
      );

      // this.addProductToCart(this.dishAllocationsOrderDays[nameDay]["categories"][cat]["dishes"][element]);
      this.updateCartFromLocalStorage();
      // this.sumOder = this.getSumOrder(this.simpleOderModelArray);
      //
      this.sumOder = this.getSumOrderForCurrentServingDay(
        this.simpleOderModelArray,
        this.currentServingDay
      );

      // this.sumOder+=1;
    }
  }

  updateQuantity(quantity: number, cat: any, element: number, nameDay: string) {
    console.debug('[updateQuantity] updateQuantity=' + quantity);
    let newQuantity = Number(quantity);
    // console.log("nameDay", nameDay);
    // console.log("element", element);
    // console.log("cat", cat);
    this.isQuantitiesChanged = true;
    this.disabledAfterOrder = false;
    this.localStorageService.set(
      this.localStorageService.get('currentChildRoute') + 'disabledAfterOrder',
      this.disabledAfterOrder
    );
    // this.localStorageService.set( 'parentCartRoute'+ 'disabledAfterOrder', this.disabledAfterOrder);

    if (!this.orderNotPermit) {
      let catNumber;
      if (cat == 'Froko') {
        catNumber = 1;
      } else if (cat == 'Extra') {
        catNumber = 2;
      }
      var orderFound = false;

      // 	var orderDishIdTCur = nameDay + cat + element.toString();

      var orderDishIdTCur = this.getOrderDishId(
        this.localStorageService.get('currentChildRoute'),
        this.shiftWeek,
        nameDay,
        catNumber,
        element
      );
      console.log('orderDishIdTCurNumber=' + orderDishIdTCur);

      if (this.simpleOderModelArray.length == 0) {
        // console.log("this.simpleOderModelArray.length="+this.simpleOderModelArray.length);
        this.simpleOderModelArray.push(
          new SimpleOderModel(
            orderDishIdTCur,
            newQuantity,
            newQuantity,
            nameDay,
            this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
              'menuLine'
            ]['productNr'],
            this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
              'menuLine'
            ]['productName'],
            this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
              'dish'
            ]['dishPhotos'][0]['imageUrl'],
            'no categoreis',
            'no subcategoreis',
            {
              dietTypeNr: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['dietTypeNr'],
              dietTypeName: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['dietTypeName'],
              portionSize: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['portionSize'],
              line: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                element
              ]['menuLine']['line'],
              orderNr: this.dishAllocationsOrderDays[nameDay]['orderNr'],
              customerNr: this.dishAllocationsOrderDays[nameDay]['customerNr'],
              menuNr: this.dishAllocationsOrderDays[nameDay]['menuNr'],
              changed: true,
              orderNotPermit: this.orderNotPermit,
              enkeltPortion: this.dishAllocationsOrderDays[nameDay][
                'menuLines'
              ][element]['menuLine']['enkeltPortion'],
            }
          )
        );
      } else {
        for (var oder in this.simpleOderModelArray) {
          if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
            orderFound = true;
            this.simpleOderModelArray[oder].orderNumber = newQuantity;
            this.simpleOderModelArray[oder].sumOder = newQuantity;
            this.simpleOderModelArray[oder].changed = true;
          }
        }

        if (!orderFound) {
          // 		console.log("Else="+element);
          this.simpleOderModelArray.push(
            new SimpleOderModel(
              orderDishIdTCur,
              newQuantity,
              newQuantity,
              nameDay,
              this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['productNr'],
              this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['productName'],
              this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'dish'
              ]['dishPhotos'][0]['imageUrl'],
              'no categoreis',
              'no subcategoreis',
              {
                dietTypeNr: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                  element
                ]['menuLine']['dietTypeNr'],
                dietTypeName: this.dishAllocationsOrderDays[nameDay][
                  'menuLines'
                ][element]['menuLine']['dietTypeName'],
                portionSize: this.dishAllocationsOrderDays[nameDay][
                  'menuLines'
                ][element]['menuLine']['portionSize'],
                line: this.dishAllocationsOrderDays[nameDay]['menuLines'][
                  element
                ]['menuLine']['line'],
                orderNr: this.dishAllocationsOrderDays[nameDay]['orderNr'],
                customerNr: this.dishAllocationsOrderDays[nameDay][
                  'customerNr'
                ],
                menuNr: this.dishAllocationsOrderDays[nameDay]['menuNr'],
                changed: true,
                orderNotPermit: this.orderNotPermit,
                enkeltPortion: this.dishAllocationsOrderDays[nameDay][
                  'menuLines'
                ][element]['menuLine']['enkeltPortion'],
              }
            )
          );
        }
      }

      this.localStorageService.set(
        this.simpleOderModelArrayId,
        this.simpleOderModelArray
      );

      // this.addProductToCart(this.dishAllocationsOrderDays[nameDay]["categories"][cat]["dishes"][element]);
      this.updateCartFromLocalStorage();
      // this.sumOder = this.getSumOrder(this.simpleOderModelArray);
      //
      this.sumOder = this.getSumOrderForCurrentServingDay(
        this.simpleOderModelArray,
        this.currentServingDay
      );

      // this.sumOder+=1;
    }
  }

  getSumOrder(simpleOderModelArray: any) {
    let sumOder = 0;
    for (var oder in simpleOderModelArray) {
      sumOder += simpleOderModelArray[oder].sumOder;
    }
    return sumOder;
  }

  getSumOrderForCurrentServingDay(
    simpleOderModelArray: any,
    currentServingDay: string
  ) {
    let sumOder = 0;
    for (var oder in simpleOderModelArray) {
      if (simpleOderModelArray[oder]['Day'] == currentServingDay) {
        sumOder += simpleOderModelArray[oder].sumOder;
      }
    }
    return sumOder;
  }

  getSumOder(cat: any, element: number, nameDay: string) {
    let catNumber;
    if (cat == 'Froko') {
      catNumber = 1;
    } else if (cat == 'Extra') {
      catNumber = 2;
    }
    let sumOder = 0;
    // var orderDishIdTCur = nameDay + cat + element.toString();
    let orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      catNumber,
      element
    );
    if (this.simpleOderModelArray.length != 0) {
      for (let oder in this.simpleOderModelArray) {
        if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
          sumOder = this.simpleOderModelArray[oder].sumOder;
        }
      }
    } else {
      sumOder = this.dishAllocationsOrderDays[nameDay]['menuLines'][element][
        'menuLine'
      ]['quantity'];
    }

    return sumOder;
  }

  // -----------------------------Update SimpleOrderModelArray from respnse---------------------

  updateOrdersInLocalStorageFromResponse(
    toggledDay: string,
    dishAllocationsOrderDays: any
  ) {
    console.debug('[updateOrdersInLocalStorageFromResponse]');
    for (let day in dishAllocationsOrderDays) {
      if (dishAllocationsOrderDays[day]['menuLines'] && day == toggledDay) {
        for (
          let i = 0;
          i < dishAllocationsOrderDays[day]['menuLines'].length;
          i++
        ) {
          console.debug('[updateOrdersInLocalStorageFromResponse] i', i);

          if (dishAllocationsOrderDays[day]['menuLines'][i]) {
            if (
              dishAllocationsOrderDays[day]['menuLines'][i]['menuLine'][
                'quantity'
              ] > 0
            ) {
              this.updateSimpleOderModelArrayFromResponse(
                'Froko',
                i,
                day,
                dishAllocationsOrderDays
              );
            }
          }
        }
      }
    }
    // this.sumOder = this.getSumOrder(this.simpleOderModelArray);

    this.sumOder = this.getSumOrderForCurrentServingDay(
      this.simpleOderModelArray,
      this.currentServingDay
    );
  }

  updateSimpleOderModelArrayFromResponse(
    cat: any,
    element: number,
    nameDay: string,
    dishAllocationsOrderDays: any
  ) {
    console.debug('[updateSimpleOderModelArrayFromResponse]');
    console.debug('[updateSimpleOderModelArrayFromResponse] nameDay', nameDay);
    console.debug('[updateSimpleOderModelArrayFromResponse] element', element);
    console.debug('[updateSimpleOderModelArrayFromResponse] cat', cat);
    let catNumber;
    if (cat == 'Froko') {
      catNumber = 1;
    } else if (cat == 'Extra') {
      catNumber = 2;
    }
    var orderFound = false;

    // 	var orderDishIdTCur = nameDay + cat + element.toString();

    var orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      catNumber,
      element
    );
    console.log('orderDishIdTCurNumber=' + orderDishIdTCur);

    if (this.simpleOderModelArray.length == 0) {
      // console.log("this.simpleOderModelArray.length="+this.simpleOderModelArray.length);
      this.simpleOderModelArray.push(
        new SimpleOderModel(
          orderDishIdTCur,
          dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
            'quantity'
          ],
          dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
            'quantity'
          ],
          nameDay,
          dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
            'productNr'
          ],
          dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
            'productName'
          ],
          dishAllocationsOrderDays[nameDay]['menuLines'][element]['dish'][
            'dishPhotos'
          ][0]['imageUrl'],
          'no categoreis',
          'no subcategoreis',
          {
            dietTypeNr:
              dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['dietTypeNr'],
            dietTypeName:
              dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['dietTypeName'],
            portionSize:
              dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['portionSize'],
            line:
              dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['line'],
            orderNr: dishAllocationsOrderDays[nameDay]['orderNr'],
            customerNr: dishAllocationsOrderDays[nameDay]['customerNr'],
            menuNr: dishAllocationsOrderDays[nameDay]['menuNr'],
            changed: false,
            orderNotPermit: this.orderNotPermit,
            enkeltPortion: this.dishAllocationsOrderDays[nameDay]['menuLines'][
              element
            ]['menuLine']['enkeltPortion'],
          }
        )
      );
    } else {
      for (var oder in this.simpleOderModelArray) {
        if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
          orderFound = true;
          if (this.simpleOderModelArray[oder].changed != true) {
            this.simpleOderModelArray[oder].orderNumber =
              dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['quantity'];
            this.simpleOderModelArray[oder].sumOder =
              dishAllocationsOrderDays[nameDay]['menuLines'][element][
                'menuLine'
              ]['quantity'];
            this.simpleOderModelArray[oder].changed = false;
          } else {
            // simpleOderModelArray has changed
            this.isQuantitiesChanged = true;
            this.disabledAfterOrder = !this.isQuantitiesChanged;
            this.localStorageService.set(
              this.localStorageService.get('currentChildRoute') +
                'disabledAfterOrder',
              this.disabledAfterOrder
            );
            // this.localStorageService.set( 'parentCartRoute'+ 'disabledAfterOrder', this.disabledAfterOrder);
          }
        }
      }

      if (!orderFound) {
        // 		console.log("Else="+element);
        this.simpleOderModelArray.push(
          new SimpleOderModel(
            orderDishIdTCur,
            dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
              'quantity'
            ],
            dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
              'quantity'
            ],
            nameDay,
            dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
              'productNr'
            ],
            dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine'][
              'productName'
            ],
            dishAllocationsOrderDays[nameDay]['menuLines'][element]['dish'][
              'dishPhotos'
            ][0]['imageUrl'],
            'no categoreis',
            'no subcategoreis',
            {
              dietTypeNr:
                dishAllocationsOrderDays[nameDay]['menuLines'][element][
                  'menuLine'
                ]['dietTypeNr'],
              dietTypeName:
                dishAllocationsOrderDays[nameDay]['menuLines'][element][
                  'menuLine'
                ]['dietTypeName'],
              portionSize:
                dishAllocationsOrderDays[nameDay]['menuLines'][element][
                  'menuLine'
                ]['portionSize'],
              line:
                dishAllocationsOrderDays[nameDay]['menuLines'][element][
                  'menuLine'
                ]['line'],
              orderNr: dishAllocationsOrderDays[nameDay]['orderNr'],
              customerNr: dishAllocationsOrderDays[nameDay]['customerNr'],
              menuNr: dishAllocationsOrderDays[nameDay]['menuNr'],
              changed: false,
              orderNotPermit: this.orderNotPermit,
              enkeltPortion: this.dishAllocationsOrderDays[nameDay][
                'menuLines'
              ][element]['menuLine']['enkeltPortion'],
            }
          )
        );
      }
    }

    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );

    // this.addProductToCart(dishAllocationsOrderDays[nameDay]["categories"][cat]["dishes"][element]);
    this.updateCartFromLocalStorage();
    // this.sumOder+=dishAllocationsOrderDays[nameDay]['menuLines'][element]['menuLine']["quantity"];
  }

  prevWeek() {
    this.shiftWeek--;

    this.localStorageService.set(
      'shiftWeek' + this.localStorageService.get('currentChildRoute'),
      this.shiftWeek
    );
    // this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'parentCartRoute'), this.shiftWeek );

    this.updateCartFromLocalStorage();
    this.sumOder = 0;

    this.localStorageService.set('toggledDayFroko', 'Tuesday');
    this.toggledTuesday = true;
    this.toggledThursday = false;
    this.toggledSaturday = false;
    this.templateDays = ['Tuesday'];
    // this.orderNotPermit = true;
    this.needNgOninit = true;
    // 	this.needUnsubscribe = false;
    // 	this.localStorageService.set(this.simpleOderModelArrayId, null);
    this.router.navigateByUrl('/minmenu/frokostpakker');
    // this.ngOnInit();
  }
  nextWeek() {
    this.shiftWeek++;

    this.localStorageService.set(
      'shiftWeek' + this.localStorageService.get('currentChildRoute'),
      this.shiftWeek
    );

    // this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'parentCartRoute'), this.shiftWeek );

    this.updateCartFromLocalStorage();
    this.sumOder = 0;

    this.localStorageService.set('toggledDayFroko', 'Tuesday');
    this.toggledTuesday = true;
    this.toggledThursday = false;
    this.toggledSaturday = false;
    this.templateDays = ['Tuesday'];
    // this.orderNotPermit = true;

    this.needNgOninit = true;
    // 	this.needUnsubscribe = false;
    // 	this.localStorageService.set(this.simpleOderModelArrayId, null);
    this.router.navigateByUrl('/minmenu/frokostpakker');
    // this.ngOnInit();
  }

  /*getImgUrl(imageUrl:string):string{

	  console.log("IMAGEUrl:"+ imageUrl);
	  if(imageUrl!="assets/img/defaultImg.png"){
	  	var imgUrl = this.dataSource.baseUrl + imageUrl
	  }

	  //	console.log("IMG:"+ imgUrl);
	 // 	return imgUrl.toString();
	 return imgUrl;
	}*/

  getImgUrl(dish: any): string {
    console.debug('[getImgUrl] dish:' + dish);
    let imgUrl;
    if (
      dish &&
      dish['dishPhotos'] &&
      dish['dishPhotos'].length != 0 &&
      dish['dishPhotos'][0] &&
      dish['dishPhotos'][0]['imageUrl'] != ''
    ) {
      imgUrl = this.dataSource.baseUrl + dish['dishPhotos'][0]['imageUrl'];
      console.debug('[getImgUrl] imgUrl', imgUrl);
      return imgUrl;
    } else {
      console.debug('[getImgUrl] imgUrl', 'assets/img/defaultImg.png');
      return 'assets/img/defaultImg.png';
    }
  }
  /*	public getSantizeUrl(imageUrl : string) {

    //return this.sanitizer.bypassSecurityTrustUrl(this.getImgUrl(url));
   // return 'https://testapi.minmenu.com/Content/Upload/DishImages/331/496197e5-4161-4aab-bee3-7f66d5c1fe78.jpg'

	var imgUrl = this.dataSource.baseUrl + imageUrl
	//  	console.log("IMG:"+ imgUrl);
	  	return imgUrl;

	}
*/

  getTargetId(cat: any, nameDay: string, itemDish: any): string {
    //  console.log("In getTargetId cat:"+ cat);
    //  console.log("In getTargetId nameDay:"+ nameDay);
    //  console.log("In getTargetId itemDish:"+ itemDish);
    var id = '#' + cat + nameDay + itemDish;
    //  	console.log("In getTargetId IMG:"+ id);
    return id.toString();
  }

  getId(cat: any, nameDay: string, itemDish: any): string {
    var id = cat + nameDay + itemDish;
    //  	console.log("In getId IMG:"+ id);
    return id.toString();
  }

  /*
    addProductToCart(dish: SimpleOderModel) {
        this.cart.addLine(dish);


    }
*/
  /* getOrderDishId(){
    	return this.orderDishId++;
    }
  */

  getOrderDishId(
    fromPage: string,
    shiftWeek: number,
    day: string,
    cat: number,
    dishPlace: number
  ) {
    /*console.log("fromPage="+fromPage);
  		console.log("shiftWeek="+shiftWeek);
  		console.log("day="+day);
  		console.log("dishPlace="+dishPlace);
  		console.log("cat="+cat);*/

    var pageNumber: number;
    let orderDishId: number = 0;
    var week = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    switch (fromPage) {
      case 'frokostpakker': {
        pageNumber = 1;
        // 	console.log("frokostpakker");
        break;
      }
      case 'extra': {
        pageNumber = 2;
        // console.log("extra");
        break;
      }
    }

    orderDishId = Number(
      pageNumber * 1000000 +
        (Number(this.dateService.getCurrentWeek()) + Number(shiftWeek)) * 1000 +
        Number(week.indexOf(day) * 1000) +
        cat * 100 +
        dishPlace
    );

    // console.log("InorderDishId", orderDishId);
    return orderDishId;
  }

  /*
    subProductToCart(dish: SimpleOderModel, quantity: number){
     	this.cart.subLine(dish, quantity);
    }
*/
  updateCartFromLocalStorage() {
    this.cart.updateLinesFromLocalStorage(this.shiftWeekNumber);
  }

  accept() {
    // this.shiftWeek--;
    // this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'currentChildRoute'), this.shiftWeek );
    // this.needNgOninit = true;
    // this.updateCartFromLocalStorage();
    // 	this.localStorageService.set(this.simpleOderModelArrayId, null);
    // this.isQuantitiesChanged = false;
    this.router.navigateByUrl('/minmenu/cart');
  }

  /*    getRowsFordishesArray(numberElementsInRow:number){
    	var rowsNumber = Math.trunc(this.dishesArray.length/numberElementsInRow);
    	if(this.dishesArray.length%numberElementsInRow!=0){
    		rowsNumber++;
    	}
    	return new Array(rowsNumber);

    	///return [1, 2, 3]
    }
*/
  /*    getRowsNumber(numberElementsInRow:number){
    	var rowsNumber = Math.trunc(this.dishesArray.length/numberElementsInRow);
    	if(this.dishesArray.length%numberElementsInRow!=0){
    		rowsNumber++;
    	}
    	return rowsNumber;


    }
*/
  getRowsNumberForDay(numberElementsInRow: number, dishesArraylength: number) {
    var rowsNumber = Math.trunc(dishesArraylength / numberElementsInRow);
    // 	console.log("In getRowsNumberForDay rowsNumber="+rowsNumber);

    if (dishesArraylength % numberElementsInRow != 0) {
      rowsNumber++;
    }
    if (rowsNumber == 0) {
      rowsNumber = 1;
    }
    return rowsNumber;
  }

  /*    getArrayDishesViewController(numberElementsInRow:number, dishesArraylength:number){

    	var rows=this.getRowsNumber(numberElementsInRow);
    	console.log("rows="+rows);


    	var arrayViewController = new Array(rows);

		for (var i = 0; i < arrayViewController.length; i++) {
		  arrayViewController[i] = new Array(numberElementsInRow);
		}
		for (var i = 0; i < arrayViewController.length; i++){
			for (var j = 0; j < arrayViewController[i].length; j++){
				arrayViewController[i][j] = -1;
			}
		}

	//	console.log("array="+JSON.stringify(arrayViewController));
    	var k = numberElementsInRow;
    	var ref = 0;
    	for(var i=0; i<rows; i++){
    		if(i == rows-1){
    			if(dishesArraylength%numberElementsInRow!=0){
    			k = dishesArraylength%numberElementsInRow;
    			console.log("k="+k);

    			arrayViewController[i].length = k;      // trim last row to the number of items remainder
    			}
    		}
    		for(var j=0; j<k; j++){

    			arrayViewController[i][j]=ref;
    			ref++;
    		}

    	}

    	console.log("array="+JSON.stringify(arrayViewController));

    return arrayViewController;
    }

	*/
  getArrayDishesViewForDayController(
    numberElementsInRow: number,
    dishesArraylength: number
  ) {
    console.log('dishesArraylength', dishesArraylength);
    var rows = this.getRowsNumberForDay(numberElementsInRow, dishesArraylength);
    // 	console.log("rows="+rows);

    var arrayViewController = new Array(rows);
    for (var i = 0; i < arrayViewController.length; i++) {
      arrayViewController[i] = new Array(numberElementsInRow);
    }
    for (var i = 0; i < arrayViewController.length; i++) {
      for (var j = 0; j < arrayViewController[i].length; j++) {
        arrayViewController[i][j] = -1;
      }
    }

    // 	console.log("array="+JSON.stringify(arrayViewController));

    var k = numberElementsInRow;
    var ref = 0;
    for (var i = 0; i < rows; i++) {
      if (dishesArraylength > numberElementsInRow) {
        if (i == rows - 1) {
          if (dishesArraylength % numberElementsInRow != 0) {
            k = dishesArraylength % numberElementsInRow;
            console.log('k=' + k);

            arrayViewController[i].length = k; // trim last row to the number of items remainder
          }
        }
      } else {
        k = dishesArraylength;
        arrayViewController[i].length = k;
      }

      for (var j = 0; j < k; j++) {
        arrayViewController[i][j] = ref;
        ref++;
      }
    }

    // 	console.log("array="+JSON.stringify(arrayViewController));

    return arrayViewController;
  }

  /*    toggleMonday(){
    this.toggledMonday = true;
	this.toggledWednesday = false;
	this.toggledFriday = false;
	//console.log("Monday Wednesday Friday" + this.toggledMonday+this.toggledWednesday+this.toggledFriday);

    }

     toggleWednesday(){
     	this.toggledMonday = false;
		this.toggledWednesday = true;
		this.toggledFriday = false;
	//	console.log("Monday Wednesday Friday" + this.toggledMonday+this.toggledWednesday+this.toggledFriday);
     }


     toggleFriday(){
     	this.toggledMonday = false;
		this.toggledWednesday = false;
		this.toggledFriday = true;
	//	console.log("Monday Wednesday Friday" + this.toggledMonday+this.toggledWednesday+this.toggledFriday);

     }
*/
  toggleTuesday() {
    this.toggledTuesday = true;
    this.toggledThursday = false;
    this.toggledSaturday = false;
    this.localStorageService.set('toggledDayFroko', 'Tuesday');
    this.templateDays = ['Tuesday'];
    this.needNgOninit = true;
    // this.needUnsubscribe = false;
    // this.localStorageService.set( 'toggledDayFroko', "Tuesday");
    this.router.navigateByUrl('/minmenu/frokostpakker');
  }

  toggleThursday() {
    this.toggledTuesday = false;
    this.toggledThursday = true;
    this.toggledSaturday = false;
    this.localStorageService.set('toggledDayFroko', 'Thursday');
    this.templateDays = ['Thursday'];
    this.needNgOninit = true;
    // this.needUnsubscribe = false;
    // this.localStorageService.set( 'toggledDayFroko', "Thursday");
    this.router.navigateByUrl('/minmenu/frokostpakker');
  }

  toggleSaturday() {
    this.toggledTuesday = false;
    this.toggledThursday = false;
    this.toggledSaturday = true;
    this.localStorageService.set('toggledDayFroko', 'Saturday');
    // this.templateDays = ["Saturday"];
    this.templateDays = ['Sunday'];
    this.needNgOninit = true;
    // this.needUnsubscribe = false;
    // this.localStorageService.set( 'toggledDayFroko', "Saturday");
    this.router.navigateByUrl('/minmenu/frokostpakker');
  }

  translateDayToDanish(day: string) {
    console.log('[translateDayToDanish] day', day);
    return this.localeDaDKService.translateDayToDanishsFull(day);
  }
  isDayForOrder(orderingDates: any[], nameDay: string, shiftWeek: number) {
    // 	if(this.filterOn){
    var isDayForOrderFlag = false;
    var date = this.dateService.getISODateYYYMMDDforDay(nameDay, shiftWeek);
    // 	console.log("DATE ="+date);
    for (var day in this.orderingDates) {
      if (date == this.orderingDates[day]) {
        isDayForOrderFlag = true;
      }
    }

    // 	return isDayForOrderFlag;

    return true; // !!!! Temporary
  }

  filterToogle() {
    this.filterOn = !this.filterOn;
  }

  isOrderingTime(userInfoOrderingModel: any[], categoryForFilter: string) {
    // 	console.log("In isOrderingTime");
    var startServingTime = '';
    var endServingTime = '';
    var currentTime = 0;
    for (var userCategory in userInfoOrderingModel['userCategories']) {
      // 		console.log("In isOrderingTime cat="+userInfoOrderingModel["userCategories"][userCategory]["category"]["name"]);
      if (
        userInfoOrderingModel['userCategories'][userCategory]['category'][
          'name'
        ] == categoryForFilter
      ) {
        startServingTime =
          userInfoOrderingModel['userCategories'][userCategory][
            'startServingTime'
          ];
        // 	var dateStart =  this.dateService.getDateFromTimeHHmmss(startServingTime);

        // 	console.log("IsstartServingTime=" + this.dateService.returnCurrentDateForTimeHHmmss(dateStart));
        endServingTime =
          userInfoOrderingModel['userCategories'][userCategory][
            'endServingTime'
          ];

        // 	var dateEnd =  this.dateService.getDateFromTimeHHmmss(endServingTime);

        // 	console.log("IsendServingTime=" + this.dateService.returnCurrentDateForTimeHHmmss(dateEnd));

        // 	currentTime = this.dateService.getCurrentTime();
        // 	console.log("currentTime=" + currentTime);
        // 	if(this.dateService.returnCurrentDateForTimeHHmmss(dateStart)<=currentTime &&
        // 	currentTime<=this.dateService.returnCurrentDateForTimeHHmmss(dateEnd)){
        if (
          this.dateService.isCurrentTimeBetween(
            startServingTime,
            endServingTime
          )
        ) {
          return (this.isOrderingTimeFlag = true);
          // 	}else return this.isOrderingTimeFlag=false;
        } else return (this.isOrderingTimeFlag = true);
      }
    }
  }

  /*    open(content) {
		    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
		      this.closeResult = `Closed with: ${result}`;
		    }, (reason) => {
		    //  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		    });
  		}
*/
  openVerticallyCentered(content, itemDish: any, day: string) {
    console.log('[openVerticallyCentered] itemDish', itemDish);
    // ---Start Spinner------------------------
    var loader = document.getElementById('loader');
    loader.style.visibility = 'visible';

    let priceGroup = this.dishAllocationsOrderDays[day]['priceGroup'];
    let productNr = itemDish['menuLine']['productNr'];
    let dietTypeNr = itemDish['menuLine']['dietTypeNr'];
    let portionSize = itemDish['menuLine']['portionSize'];

    this.dishRepository
      .getProductPrice(
        productNr,
        dietTypeNr,
        portionSize,
        priceGroup,
        this.shiftWeek,
        day
      )
      .subscribe(
        (response) => {
          console.log('response', response);
          if (response && response['salePrice']) {
            this.currentDishSalePrice = response['salePrice'];
          } else {
            this.currentDishSalePrice = 0;
          }
          // ----------Stop spinner----------------------
          var loader = document.getElementById('loader');
          loader.style.visibility = 'hidden';

          this.modalService.open(content, { centered: true });
        },
        (error) => console.log('[getProductPrice] error', error)
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  dishInfo() {
    this.modalBtnBackgroundColor = '#FFFFFF';
    this.modalBtnTextColor = '#000000';
    this.modalBtnTextWeight = 'bold';
    this.modalBtnFlag = 'dishInfo';
    console.log('this.modalBtnFlag', this.modalBtnFlag);
  }

  dishRecipe() {
    this.modalBtnBackgroundColor = '#5B7B12';
    this.modalBtnTextColor = '#FFFFFF';
    this.modalBtnTextWeight = 'normal';
    this.modalBtnFlag = 'dishRecipe';
    console.log('this.modalBtnFlag', this.modalBtnFlag);
  }

  dishVideo() {
    this.modalBtnBackgroundColor = '#5B7B12';
    this.modalBtnTextColor = '#FFFFFF';
    this.modalBtnTextWeight = 'normal';
    this.modalBtnFlag = 'dishVideo';
    console.log('this.modalBtnFlag', this.modalBtnFlag);
  }

  // ------------------------------Check Permittions-------------------------------------

  checkPermittionsForOrder(
    deadlineStatus: boolean,
    orderPermitStatus: boolean,
    orderOperation: number
  ) {
    console.log('[checkPermittionsForOrder] In checkPermittionsForOrder');

    if (!deadlineStatus && orderPermitStatus && orderOperation != 0) {
      return false;
    }

    return true;
  }
  // -----------------Disable Image Batton +/- if no Permittions to order------------------------
  getSubBtnUrl() {
    let btnImageUrl;
    if (this.orderNotPermit) {
      btnImageUrl = 'assets/img/SymbolSubBtnH.png';
    } else {
      btnImageUrl = 'assets/img/SymboSubBtn.png';
    }
    return btnImageUrl;
  }

  getAddBtnUrl() {
    let btnImageUrl;
    if (this.orderNotPermit) {
      btnImageUrl = 'assets/img/SymbolBtnH.png';
    } else {
      btnImageUrl = 'assets/img/SymbolBackBtn.png';
    }
    return btnImageUrl;
  }

  /*goCartPrint(){
		this.router.navigateByUrl("/minmenu/print/printcartcomponent");
	}*/

  getTextFromRTF(ingredientsRTF: any) {
    // return deEncapsulateSync(ingredientsRTF, { decode: iconvLite.decode });
    return ingredientsRTF;
  }
}
