import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, Observer, Subscription, interval } from 'rxjs';
import { DishRepository } from '../repository/dish.repository';
import { RestDataSource } from '../repository/rest.datasource';
import { AllocatedDishModel } from '../model/allocatedDishModel.model';
import { LocalStorageService } from '../service/localStorage.service';
import { DateService } from '../service/date.service';
import { environment } from '../../environments/environment';
import { NewsService } from '../service/news.service';

@Injectable()
export class ModelResolver implements Resolve<AllocatedDishModel[]> {
  private resolverArray: AllocatedDishModel[] = [];

  constructor(
    private localStorageService: LocalStorageService,

    private dishRepository: DishRepository,
    private router: Router,
    private dateService: DateService,
    private newsService: NewsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<AllocatedDishModel[]>
    | Promise<AllocatedDishModel[]>
    | AllocatedDishModel[] {
    // -----------------Loader-----------------------------------------------
    var loader = document.getElementById('loader');
    loader.style.visibility = 'visible';
    //---------------------------------------------------------------------
    //var shiftWeek = this.localStorageService.get('shiftWeek');
    //var shiftWeek = this.localStorageService.get('shiftWeek'+ this.localStorageService.get( 'currentChildRoute'));

    console.info('In model.resolver');
    console.debug('route.url', route.url);

    //if((route.url[0].path=="frokostpakker")|| (route.url[0].path=="extra") ){
    if (route.url[0].path == 'frokostpakker') {
      var shiftWeek = this.localStorageService.get(
        'shiftWeek' + 'frokostpakker'
      );
      //console.debug("this.localStorageService.get( 'currentChildRoute')", this.localStorageService.get( 'currentChildRoute'));
      //console.debug("shiftWeek", shiftWeek);
      let toggledDay = this.localStorageService.get('toggledDayFroko');

      switch (toggledDay) {
        case 'Tuesday':
          console.debug('Tuesday');
          //return this.dishRepository.getDishAllocationsDaysTuesdayShiftWeek(shiftWeek, 'Tuesday', 2);
          return this.dishRepository.getBulkOrderBreakfastTuesdayShiftWeek(
            shiftWeek,
            'Tuesday',
            1
          );

          break;
        case 'Thursday':
          console.debug('Thursday');
          //return this.dishRepository.getDishAllocationsDaysThursdayShiftWeek(shiftWeek, 'Thursday', 3);
          return this.dishRepository.getBulkOrderBreakfastThursdayShiftWeek(
            shiftWeek,
            'Thursday',
            1
          );
          break;
        case 'Saturday':
          console.debug('Saturday');
          //return this.dishRepository.getDishAllocationsDaysSaturdayShiftWeek((shiftWeek + 1), 'Sunday', 2);
          //return this.dishRepository.getBulkOrderBreakfastSaturdayShiftWeek((shiftWeek +1), 'Sunday',2);
          return this.dishRepository.getBulkOrderBreakfastSaturdayShiftWeek(
            shiftWeek,
            'Saturday',
            1
          );
          break;
        default:
          console.debug('default');
          //return this.dishRepository.getDishAllocationsDaysTuesdayShiftWeek(shiftWeek, 'Tuesday', 2);
          return this.dishRepository.getBulkOrderBreakfastTuesdayShiftWeek(
            shiftWeek,
            'Tuesday',
            1
          );
      }
    } else if (route.url[0].path == 'extra') {
      var shiftWeek = this.localStorageService.get('shiftWeek' + 'extra');
      //return this.dishRepository.getDishAllocationsAlltShiftWeek(shiftWeek);
      let toggledDay = this.localStorageService.get('toggledDayExtra');

      switch (toggledDay) {
        case 'Tuesday':
          console.debug('Tuesday');
          //return this.dishRepository.getDishAllocationsDaysTuesdayShiftWeek(shiftWeek, 'Tuesday', 2);
          return this.dishRepository.getBulkOrderOptionalTuesdayShiftWeek(
            shiftWeek,
            'Tuesday',
            1
          );
          break;
        case 'Thursday':
          console.debug('Thursday');
          //return this.dishRepository.getDishAllocationsDaysThursdayShiftWeek(shiftWeek, 'Thursday', 3);
          return this.dishRepository.getBulkOrderOptionalThursdayShiftWeek(
            shiftWeek,
            'Thursday',
            1
          );
          //return this.dishRepository.getBulkOrderOptionalTuesdayShiftWeek(shiftWeek-1, 'Tuesday',3);
          break;
        case 'Saturday':
          console.debug('Saturday');
          //return this.dishRepository.getDishAllocationsDaysSaturdayShiftWeek((shiftWeek + 1), 'Sunday', 2);
          //return this.dishRepository.getBulkOrderOptionalSaturdayShiftWeek((shiftWeek +1), 'Sunday',2);
          //return this.dishRepository.getBulkOrderOptionalTuesdayShiftWeek(shiftWeek -1 , 'Tuesday',2);
          return this.dishRepository.getBulkOrderOptionalSaturdayShiftWeek(
            shiftWeek,
            'Saturday',
            1
          );
          break;
        default:
          console.debug('default');
          //return this.dishRepository.getDishAllocationsDaysTuesdayShiftWeek(shiftWeek, 'Tuesday', 1);
          return this.dishRepository.getBulkOrderOptionalTuesdayShiftWeek(
            shiftWeek,
            'Tuesday',
            1
          );
      }
    } else if (
      route.url[1] !== undefined &&
      route.url[1].path == 'viewweekplan'
    ) {
      console.debug('In viewweekplan');
      var shiftWeek = this.localStorageService.get(
        'shiftWeek' + 'bulkorder/viewweekplan'
      );
      console.debug('[resolver] shiftWeek', shiftWeek);
      //shiftWeek = 1;
      //return this.dishRepository.getBulkDishAlltShiftWeek(shiftWeek);
      let toggledDayBulk = this.localStorageService.get('toggledDayBulk');

      switch (toggledDayBulk) {
        case 'Tuesday':
          console.debug('Tuesday');
          if (environment.setAllDeliveryDaysOrderBug == true) {
            return this.dishRepository.getBulkDishThreeDaysShiftWeek(
              shiftWeek,
              'Tuesday'
            );
          } else {
            return this.dishRepository.getBulkDishTuesdayShiftWeek(
              shiftWeek,
              'Tuesday',
              1
            );
          }

          break;
        case 'Thursday':
          console.debug('Thursday');
          if (environment.setAllDeliveryDaysOrderBug == true) {
            return this.dishRepository.getBulkDishThreeDaysShiftWeek(
              shiftWeek,
              'Tuesday'
            );
          } else {
            return this.dishRepository.getBulkDishThursdayShiftWeek(
              shiftWeek,
              'Thursday',
              1
            );
          }
          break;
        case 'Saturday':
          console.debug('Saturday');
          if (environment.setAllDeliveryDaysOrderBug == true) {
            return this.dishRepository.getBulkDishThreeDaysShiftWeek(
              shiftWeek,
              'Tuesday'
            );
          } else {
            return this.dishRepository.getBulkDishSaturdayShiftWeek(
              shiftWeek,
              'Saturday',
              1
            );
          }
          break;
        default:
          console.debug('default');
          if (environment.setAllDeliveryDaysOrderBug == true) {
            return this.dishRepository.getBulkDishThreeDaysShiftWeek(
              shiftWeek,
              'Tuesday'
            );
          } else {
            return this.dishRepository.getBulkDishTuesdayShiftWeek(
              shiftWeek,
              'Tuesday',
              1
            );
          }
      }
    } else if (
      route.url[1] !== undefined &&
      route.url[1].path == 'editweekplan'
    ) {
      console.debug('In editweekplan');
      var shiftWeek = this.localStorageService.get(
        'shiftWeek' + 'bulkorder/editweekplan'
      );
      //return this.dishRepository.getBulkDishAlltShiftWeek(shiftWeek);
      let toggledDayBulk = this.localStorageService.get('toggledDayBulkOrder');

      switch (toggledDayBulk) {
        case 'Tuesday':
          console.debug('Tuesday');
          return this.dishRepository.getBulkOrderThreeDaysShiftWeek(
            shiftWeek,
            'Tuesday'
          );

          break;
        case 'Thursday':
          console.debug('Thursday');
          return this.dishRepository.getBulkOrderThreeDaysShiftWeek(
            shiftWeek,
            'Tuesday'
          );

          break;
        case 'Saturday':
          console.debug('Saturday');
          return this.dishRepository.getBulkOrderThreeDaysShiftWeek(
            shiftWeek,
            'Tuesday'
          );

          break;
        default:
          console.debug('default');
          return this.dishRepository.getBulkOrderThreeDaysShiftWeek(
            shiftWeek,
            'Tuesday'
          );
      }
    } else if (
      route.url[0].path == 'bulkorder' &&
      route.url[1].path == 'printweekplan' &&
      route.url[2] !== undefined &&
      (route.url[2].path == 'printtableveiw' ||
        route.url[2].path == 'printconfirmtable')
    ) {
      console.debug('In printtableveiw');
      //var shiftWeek = this.localStorageService.get('shiftWeek'+ 'bulkorder/printweekplan/printtableveiw');
      var shiftWeek = this.localStorageService.get(
        'shiftWeek' + this.localStorageService.get('parentPrintRoute')
      );
      //return this.dishRepository.getBulkDishAlltShiftWeek(shiftWeek);
      let toggledPrintTableDayBulk = this.localStorageService.get(
        'toggledPrintTableDayBulkOrder'
      );

      switch (toggledPrintTableDayBulk) {
        case 'Tuesday':
          console.debug('Tuesday');
          return this.dishRepository.getBulkOrderTuesdayShiftWeek(
            shiftWeek,
            'Tuesday',
            1
          );
          break;
        case 'Thursday':
          console.debug('Thursday');
          return this.dishRepository.getBulkOrderThursdayShiftWeek(
            shiftWeek,
            'Thursday',
            1
          );

          break;
        case 'Saturday':
          console.debug('Saturday');
          return this.dishRepository.getBulkOrderSaturdayShiftWeek(
            shiftWeek,
            'Saturday',
            1
          );

          break;

        case 'Week':
          return this.dishRepository.getBulkOrderThreeDaysShiftWeek(
            shiftWeek,
            'Tuesday'
          );

          break;
        default:
          console.debug('default');
          return this.dishRepository.getBulkOrderTuesdayShiftWeek(
            shiftWeek,
            'Tuesday',
            1
          );
      }
    } else if (
      route.url[0].path == 'print' &&
      route.url[1] !== undefined &&
      route.url[1].path == 'printweekplan'
    ) {
      // var shiftWeek = this.localStorageService.get('shiftWeek'+ 'print/printweekplan');
      var shiftWeek = this.localStorageService.get(
        'shiftWeek' + this.localStorageService.get('parentPrintRoute')
      );
      return this.dishRepository.getBulkDishThreeDaysShiftWeek(
        shiftWeek,
        'Tuesday'
      );
    } else if (
      route.url[0].path == 'bulkorder' &&
      route.url[1].path == 'printweekplan' &&
      route.url[2] !== undefined &&
      route.url[2].path == 'printchoosebulk'
    ) {
      /*   if(this.localStorageService.get( 'parentPrintRoute') == "print/printselectweek"){

                let selectedWeekForPrintModel = this.localStorageService.get('selectedWeekForPrintModel'+ this.localStorageService.get( 'parentPrintRoute'));

                console.log("[resolve] selectedWeekForPrintModel", selectedWeekForPrintModel);
              //  for (let itemWeek in selectedWeekForPrintModel){

                  //let shiftWeek = this.localStorageService.get('shiftWeek'+ this.localStorageService.get( 'parentPrintRoute'));

                // let shiftWeek = selectedWeekForPrintModel[itemWeek];

                console.log("[resolve] shiftWeek", shiftWeek);
                  let toggledChooseDay = this.localStorageService.get( 'toggledChooseDayBulkOrder');

                  switch(toggledChooseDay){
                    case 'Tuesday':
                      return this.dishRepository.getBulkDishCurrentDayShiftWeek(shiftWeek, "Tuesday", 1);
                    break;

                    case 'Thursday':
                      return this.dishRepository.getBulkDishCurrentDayShiftWeek(shiftWeek, "Thursday", 1);

                    break;

                    case 'Saturday':
                     return this.dishRepository.getBulkDishCurrentDayShiftWeek(shiftWeek, "Saturday", 1);

                    break;

                    case 'Week':
                     // return this.dishRepository.getBulkDishThreeDaysShiftWeek(shiftWeek, 'Tuesday');
                       return this.dishRepository.getBulkDishWeeksShiftWeek(shiftWeek, 'Tuesday', selectedWeekForPrintModel);

                    break;
                    default:
                    {
                      return this.dishRepository.getBulkDishCurrentDayShiftWeek(shiftWeek, "Tuesday", 1);
                    }
                }

            //  }

                }else{ */
      let toggledChooseDay = this.localStorageService.get(
        'toggledChooseDayBulkOrder'
      );

      //  let selectedWeekForPrintModel = this.localStorageService.get('selectedWeekForPrintModel'+ this.localStorageService.get( 'parentPrintRoute'));
      let selectedWeekForPrintModel;
      if (toggledChooseDay == 'WeeksVis') {
        selectedWeekForPrintModel = this.localStorageService.get(
          'selectedVisWeekForPrintModel' +
            this.localStorageService.get('parentPrintRoute')
        );
      } else {
        selectedWeekForPrintModel = this.localStorageService.get(
          'selectedWeekForPrintModel' +
            this.localStorageService.get('parentPrintRoute')
        );
      }
      console.log(
        '[resolve] selectedWeekForPrintModel',
        selectedWeekForPrintModel
      );

      //var shiftWeek = this.localStorageService.get('shiftWeek'+ 'bulkorder/printweekplan/printchoosebulk');
      var shiftWeek = this.localStorageService.get(
        'shiftWeek' + this.localStorageService.get('parentPrintRoute')
      );

      //let currentDayString = this.dateService.getCurrentDayString();

      switch (toggledChooseDay) {
        case 'Tuesday':
          return this.dishRepository.getBulkDishCurrentDayShiftWeek(
            shiftWeek,
            'Tuesday',
            1
          );
          break;
        /*case 'Wednesday':
                  return this.dishRepository.getBulkDishCurrentDayShiftWeek(shiftWeek, "Tuesday", 1);
                break;
                */
        case 'Thursday':
          return this.dishRepository.getBulkDishCurrentDayShiftWeek(
            shiftWeek,
            'Thursday',
            1
          );

          break;
        /*case 'Friday':
                  return this.dishRepository.getBulkDishCurrentDayShiftWeek(shiftWeek, "Thursday", 1);

                break;
                */
        case 'Saturday':
          return this.dishRepository.getBulkDishCurrentDayShiftWeek(
            shiftWeek,
            'Saturday',
            1
          );

          break;
        /* case 'Sunday':
                 return this.dishRepository.getBulkDishCurrentDayShiftWeek(shiftWeek, "Saturday", 1);

                break;
                case 'Monday':
                  return this.dishRepository.getBulkDishCurrentDayShiftWeek(shiftWeek, "Saturday", 1);
                break;
                */
        case 'Week':
          return this.dishRepository.getBulkDishThreeDaysShiftWeek(
            shiftWeek,
            'Tuesday'
          );

          break;
        case 'WeeksVis':
        case 'Weeks':
          // return this.dishRepository.getBulkDishThreeDaysShiftWeek(shiftWeek, 'Tuesday');
          // let shiftMonth = this.localStorageService.get('shiftMonth'+ this.localStorageService.get( 'parentPrintRoute'));

          return this.dishRepository.getBulkDishWeeksShiftWeek(
            'Tuesday',
            selectedWeekForPrintModel
          );
          //return this.dishRepository.getBulkDishWeeksShiftWeek('Monday', selectedWeekForPrintModel);

          break;
        default: {
          return this.dishRepository.getBulkDishCurrentDayShiftWeek(
            shiftWeek,
            'Tuesday',
            1
          );
        }
      }
      //   }
    } else if (
      route.url[0].path == 'print' &&
      route.url[1] !== undefined &&
      route.url[1].path == 'printtodaysmenu'
    ) {
      //let  shiftWeek = this.localStorageService.get('shiftWeek'+ 'print/printtodaysmenu');
      let currentDayString = this.dateService.getCurrentDayString();

      let shiftWeek = this.localStorageService.get(
        'shiftWeek' + this.localStorageService.get('parentPrintRoute')
      );
      if (shiftWeek == undefined || shiftWeek == null) {
        shiftWeek = 0;
      }
      /*
      if (currentDayString == 'Sunday') {
        shiftWeek = shiftWeek - 1;
      }*/
      console.debug('[resolve] printtodaysmenu shiftWeek', shiftWeek);

      //currentDayString = "Tuesday";

      switch (currentDayString) {
        case 'Tuesday':
          return this.dishRepository.getBulkDishCurrentDayShiftWeek(
            shiftWeek,
            'Tuesday',
            1
          );
          break;
        case 'Wednesday':
          return this.dishRepository.getBulkDishCurrentDayShiftWeek(
            shiftWeek,
            'Tuesday',
            1
          );
          break;
        case 'Thursday':
          return this.dishRepository.getBulkDishCurrentDayShiftWeek(
            shiftWeek,
            'Thursday',
            1
          );

          break;
        case 'Friday':
          return this.dishRepository.getBulkDishCurrentDayShiftWeek(
            shiftWeek,
            'Thursday',
            1
          );

          break;
        case 'Saturday':
          return this.dishRepository.getBulkDishCurrentDayShiftWeek(
            shiftWeek,
            'Thursday',
            1
          );

          break;
        case 'Sunday':
          return this.dishRepository.getBulkDishCurrentDayShiftWeek(
            shiftWeek,
            'Saturday',
            1
          );

          break;
        case 'Monday':
          return this.dishRepository.getBulkDishCurrentDayShiftWeek(
            shiftWeek,
            'Saturday',
            1
          );

          break;
        default: {
        }
      }
      
    } else if (route.url[0].path == 'news') {
    console.log("🚀 ~ file: model.resolver.ts ~ line 566 ~ ModelResolver ~ route.url[0].path", route.url[0].path)

      return this.newsService.getNews();
    }
  }
}
