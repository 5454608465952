

export class UserIdentityModel {
    constructor(
            public	id:number,
			public	userName?:string,
			public	firstName?:boolean,
			public	lastName?:boolean,
			public	userPictureUrl?:string,
			//public	anovaCustomer?:AnovaCustomerIdentityModel
			
           	) { }
}