import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spDietTextBold',
})
export class SpDietTextBoldPipe implements PipeTransform {

  transform(
    value: string
  ) {
    let length = value.length;
    console.log("SpDietTextBoldPipe -> length", length)
    let index = value.indexOf(':');
    console.log("SpDietTextBoldPipe -> index", index)
    let spDietText = value.substr(0, index);
    console.log("SpDietTextBoldPipe -> spDietText", spDietText)
    let description = value.substr(index, (length - 1));
    console.log("SpDietTextBoldPipe -> description", description)

    return `<b>${spDietText}</b>${description}`;
  }
}
