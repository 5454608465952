import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MessageModalWindowModel } from 'src/app/model/message-modal-window-model'
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})

export class MessageModalComponent implements OnInit {

  public messageModalWindowModel: MessageModalWindowModel;
  
  

  @ViewChild('modalPopUp', { static: false }) modalPopUp: ElementRef;
  @Output() onSubmit = new EventEmitter<any>();

  constructor(
    private modalService: NgbModal,
   
              ) { }

  ngOnInit(): void {}

  initEmptyModal() {
    this.messageModalWindowModel = new MessageModalWindowModel();

  }
  initModal(
    modalType: string,
    modalTitle: string,
    modalBodyText: string = '',
    confirmBtnText: string = 'Fortsæt',
    modalData: any = []
  ): void {

    this.messageModalWindowModel = new MessageModalWindowModel(
      modalType,
      modalTitle,
      modalBodyText,
      confirmBtnText,
      modalData
    );

  }
  submit(): void {
    this.onSubmit.emit(true);
    this.close();
  }
  openModalPopUp() {
    this.openWindowCustomClass(this.modalPopUp);
  }
  openVerticallyCentered(content) {
    console.log('more content')
    console.log(content)

    this.modalService.open(content, { centered: true });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openWindowCustomClass(content) {

    this.modalService.open(content, {
      centered: true,
      windowClass: 'ask-approval-doc-modal-window',
    }).result.then((result) => { }, (reason) => {
      if (reason === ModalDismissReasons.ESC || // if you want to check ESC as well
        reason === ModalDismissReasons.BACKDROP_CLICK) {
        this.close();
      }
    });
  }
  close() {
    this.modalService.dismissAll('Close infoAccept modal window');
  }

  

  
}
