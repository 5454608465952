import { Injectable } from '@angular/core';
import {
  Component,
  Inject,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  ActivationEnd,
  Event,
  NavigationCancel,
  NavigationError,
  NavigationStart,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { LocalStorageService } from '../../service/localStorage.service';

import { Cart } from '../../cart/cart-model/cart.model';
import { DateService } from '../../service/date.service';
import { AllocatedDishModel } from '../../model/allocatedDishModel.model';
import { OrderDish } from '../../model/orderDish.model';
import { DishAllocationWeekDataBase } from '../../model/dishAllocationWeek.database';
import { DishRepository } from '../../repository/dish.repository';
import { RestDataSource } from '../../repository/rest.datasource';
import { ModelResolver } from '../../resolver/model.resolver';
import { MenuLine } from '../../model/menuLine.model';
import { timer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { of, from } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/concatAll';
import 'rxjs/add/operator/concatMap';
//import "rxjs/add/operator/map";
import { map } from 'rxjs/operators';
import { _ } from 'underscore';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { SafePipeModule } from 'safe-pipe';
import { AuthService } from '../../service/auth.service';
import * as moment from 'moment';
import { ComponentCanDeactivate } from '../../guard/unsaved.guard';
import { DataShareService } from '../../service/dataShare.service';
import { LocaleDaDKService } from '../../service/locale-da-dk.service';
import { environment } from './../../../environments/environment';
import { NavigationService } from '../../service/navigation.service';
import { DishAllocationsOrderDays } from '../../model/dishAllocationsOrderDays.model';
import { SimpleOderModel } from '../../model/simpleOder.model';
import { TopMessageSimple } from 'src/app/model/top-message-simple.model';
import { GuestChefModel } from 'src/app/model/guestChefModel';
import { GuestChefService } from 'src/app/service/guest-chef.service';
import { DishResponseService } from 'src/app/service/dish-response.service';

class MenuLineArrayOrderDays {
  Sunday: MenuLine[];
  Monday: MenuLine[];
  Tuesday: MenuLine[];
  Wednesday: MenuLine[];
  Thursday: MenuLine[];
  Friday: MenuLine[];
  Saturday: MenuLine[];

  constructor() {
    this.Sunday = [];
    this.Monday = [];
    this.Tuesday = [];
    this.Wednesday = [];
    this.Thursday = [];
    this.Friday = [];
    this.Saturday = [];
  }
}

class OrderMenuLineDay {
  menuLine: MenuLine;
  menuLineDay: string;
  orderNr: number;
  customerNr: number;
  menuNr: number;
  orderNotPermit: boolean;
  constructor(
    menuLine: MenuLine,
    menuLineDay: string,
    orderNr: number,
    customerNr: number,
    menuNr: number,
    orderNotPermit: boolean
  ) {
    this.menuLine = menuLine;
    this.menuLineDay = menuLineDay;
    this.orderNr = orderNr;
    this.customerNr = customerNr;
    this.menuNr = menuNr;
    this.orderNotPermit = orderNotPermit;
  }
}
class BulkOrderMenuLineEditModelDays {
  menuLines: OrderMenuLineDay[];

  constructor(menuLines: OrderMenuLineDay[]) {
    this.menuLines = menuLines;
  }
}

class SumMatrixForDays {
  Sunday: number[];
  Monday: number[];
  Tuesday: number[];
  Wednesday: number[];
  Thursday: number[];
  Friday: number[];
  Saturday: number[];

  constructor() {
    this.Sunday = [];
    this.Monday = [];
    this.Tuesday = [];
    this.Wednesday = [];
    this.Thursday = [];
    this.Friday = [];
    this.Saturday = [];
  }
}

class TibehorFuldkost {
  tibehorCategory: string;
  tibehorQuantity: number;

  constructor(tibehorCategory: string, tibehorQuantity: number) {
    this.tibehorCategory = tibehorCategory;
    this.tibehorQuantity = tibehorQuantity;
  }
}

class DeliveryDaysOrdered {
  Tuesday: boolean;
  Wednesday: boolean;
  Thursday: boolean;
  Friday: boolean;
  Saturday: boolean;
  Sunday: boolean;
  Monday: boolean;

  constructor(
    Tuesday: boolean,
    Wednesday: boolean,
    Thursday: boolean,
    Friday: boolean,
    Saturday: boolean,
    Sunday: boolean,
    Monday: boolean
  ) {
    this.Tuesday = Tuesday;
    this.Wednesday = Wednesday;
    this.Thursday = Thursday;
    this.Friday = Friday;
    this.Saturday = Saturday;
    this.Sunday = Sunday;
    this.Monday = Monday;
  }
}

class SideDishesStartDays {
  Sunday: any;
  Monday: any;
  Tuesday: any;
  Wednesday: any;
  Thursday: any;
  Friday: any;
  Saturday: any;

  constructor() {
    this.Sunday = undefined;
    this.Monday = undefined;
    this.Tuesday = undefined;
    this.Wednesday = undefined;
    this.Thursday = undefined;
    this.Friday = undefined;
    this.Saturday = undefined;
  }
}
class DeliveryDaysNumberOrdered {
  Tuesday: number;
  Thursday: number;
  Saturday: number;

  constructor(Tuesday: number, Thursday: number, Saturday: number) {
    this.Tuesday = Tuesday;
    this.Thursday = Thursday;
    this.Saturday = Saturday;
  }
}

class ServingDaysNumberOrdered {
  Tuesday: boolean;
  Wednesday: boolean;
  Thursday: boolean;
  Friday: boolean;
  Saturday: boolean;
  Sunday: boolean;
  Monday: boolean;

  constructor(
    Tuesday: boolean,
    Wednesday: boolean,
    Thursday: boolean,
    Friday: boolean,
    Saturday: boolean,
    Sunday: boolean,
    Monday: boolean
  ) {
    this.Tuesday = Tuesday;
    this.Wednesday = Wednesday;
    this.Thursday = Thursday;
    this.Friday = Friday;
    this.Saturday = Saturday;
    this.Sunday = Sunday;
    this.Monday = Monday;
  }
}

class SubCatSpDietRange {
  subCategory: string;
  range: number[];
  constructor(subCategory: string, range: number[]) {
    this.subCategory = subCategory;
    this.range = range;
  }
}

class SubCatSpDietMatrix {
  subCatSpDietRange: SubCatSpDietRange[];
  constructor(subCatSpDietRange: SubCatSpDietRange[]) {
    this.subCatSpDietRange = subCatSpDietRange;
  }
}

@Component({
  selector: "editweekplan-root",
  templateUrl: 'editweekplan.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['editweekplan.component.css'],
})
export class EditweekplanComponent implements ComponentCanDeactivate {
  public sumOder: number;
  public weeks: string[];
  public dishAllocationsWeekResponse: any[] = [];
  public currentWeek: string;
  public lastIndex: number;
  obsSubscription: Subscription;
  public numberElementsInRow: number;
  public arrayDishesViewForDay: any[];
  public orderDishId: number;
  public orderDaysLeftInWeekArray: string[];
  public dishAllocationsOrderDays: DishAllocationsOrderDays;
  public dishAllocationsOrderDaysAll: DishAllocationsOrderDays;
  public currentToogledDay: string;
  public toggledMonday: boolean;
  public toggledWednesday: boolean;
  public toggledFriday: boolean;
  public toggledTuesday: boolean;
  public toggledThursday: boolean;
  public toggledSaturday: boolean;
  public SundayDate: string;
  public SundayNextWeekDate: string;
  public MondayDate: string;
  public MondayNextWeekDate: string;
  public TuesdayDate: string;
  public WednesdayDate: string;
  public ThursdayDate: string;
  public FridayDate: string;
  public SaturdayDate: string;
  public shiftWeekNumber: string;
  public shiftWeek: number;
  public needNgOninit: boolean;
  public simpleOderModelArray: SimpleOderModel[];
  public imgUrl: string;
  public tempArray: any[] = [];
  public categoryForFilter: any;
  public simpleOderModelArrayId: string;
  public orderingDates: any[];
  public userInfoOrderingModel: any[] = [];
  public currentDayNumber: number;
  public filterOn: boolean;
  public isOrderingTimeFlag: boolean;
  public isEmptyMonday: boolean;
  public isEmptyTuesday: boolean;
  public isEmptyWednesday: boolean;
  public isEmptyThursday: boolean;
  public isEmptyFriday: boolean;
  public isEmptySaturday: boolean;
  public isEmptySunday: boolean;
  private modalBtnFlag: string;
  public safeSrc: SafeResourceUrl;
  public modalBtnBackgroundColorBulk: string;
  public modalBtnBackgroundColorEnkel: string;
  public modalBtnTextColorBulk: string;
  public modalBtnTextColorEnkel: string;
  public modalBtnTextWeight: string;
  public ifWeHaveCategory: boolean;
  private needUnsubscribe: boolean;
  public menuLineArrForFuldkost: MenuLineArrayOrderDays;
  public menuLineArrForEnrgitat: MenuLineArrayOrderDays;
  public menuLineArrForBlod: MenuLineArrayOrderDays;
  public menuLineArrForAndet: MenuLineArrayOrderDays;
  public menuLineArrForCategoryTemp: any[] = [];
  public contentFuldkostAntal: number = 0;
  public contentFuldkostAntalNew: number = 0;
  public contentEnergitatAntalArr: number[] = [];
  public contentBlodAntalArr: number[] = [];
  public contentAndetAntalArr: number[] = [];
  public contentAndetAntalArr2: number[] = [];
  public currentDayForModal: string;
  public sumMatrixForDays: SumMatrixForDays;
  public dishAllocationsOrderDaysInitTuesday: any;
  public dishAllocationsOrderDaysInitThursday: any;
  public dishAllocationsOrderDaysInitSaturday: any;
  public isProductNrFilterOn: boolean;
  closeResult: string;
  public selectedKodFuldkost: string;
  public selectedStivelseFuldkost: string;
  public orderFromViewWeekPlan: boolean;
  private departmentId: number;
  public modalKodCategoryMap: any;
  public modalKodCategoryArray: any;
  public modalKodCategoryArrayFiltered: any;
  public defaultKod: string;
  public defaultTilbehor: string;
  public currentTilbehorArr: string[];
  public subLinesSetArr: string[];
  public subLinesSetKodArr: string[];
  public subLinesSetTilbehorArr: string[];
  public subLinesSetGrontArr: string[];
  public subLinesSetSovsArr: string[];
  public subLinesSetArrEnkel: string[];
  public toogledModalBulk: boolean;
  public toogledModalEnkel: boolean;
  public dishAllDayFilteredDietTypeGroups: DishAllocationsOrderDays;
  public dishEnergitatAllDayFilteredDietTypeGroups: DishAllocationsOrderDays;
  public dishBlodAllDayFilteredDietTypeGroups: DishAllocationsOrderDays;
  public currentItemMenuLines: any;
  private orderNr: number;
  private customerNr: number;
  private menuNr: number;
  private fuldkostCategoryArr: string[];
  public disabledAfterOrder: boolean;
  private delay: number;
  public orderNotPermit: boolean = true;
  public isModalKodCategoryMapNonEmpty: boolean;
  public sumTdFuldkostAll: number;
  public isEnergitatEmptyTuesday: boolean = false;
  public isBlodEmptyTuesday: boolean = false;
  public isEnergitatEmptyWednesday: boolean = false;
  public isBlodEmptyWednesday: boolean = false;
  public isEnergitatEmptyThursday: boolean = false;
  public isBlodEmptyThursday: boolean = false;
  public isEnergitatEmptyFriday: boolean = false;
  public isBlodEmptyFriday: boolean = false;
  public isEnergitatEmptySaturday: boolean = false;
  public isBlodEmptySaturday: boolean = false;
  public isEnergitatEmptySunday: boolean = false;
  public isBlodEmptySunday: boolean = false;
  public isEnergitatEmptyMonday: boolean = false;
  public isBlodEmptyMonday: boolean = false;
  public isQuantitiesChanged: boolean = false;
  public continueSwitching: boolean = false;
  public nextDay: string;
  public isLeavingPage: boolean = false;
  public isClosedPopUp: boolean = false;
  public isContinuePopUp: boolean = false;
  public templateDays: string[];
  public deliveryDaysOrdered: DeliveryDaysOrdered;
  public curIndexDietTypeGroupsBlod: number;
  public isSideDish: boolean;
  public sideDishesStartDays: SideDishesStartDays;
  public curSideDishName: string;
  public prevShiftWeek: number;
  public changedShiftWeek: boolean;
  public nextShiftWeek: number;
  public deliveryDaysOrderedId: string;
  public changedDay: boolean;
  public continueIfChangedWeek: boolean;
  public isSaved: boolean;
  public maxRetries: number;
  public deliveryDaysNumberOrdered: DeliveryDaysNumberOrdered;
  public servingDaysNumberOrdered: ServingDaysNumberOrdered;
  public deliveryDaysNumberOrderedId: string;
  public numberOfSelectedServDays: number;
  public isDietTypeNr30: boolean = false;
  public isDietTypeNr1AndNotLine1: boolean = false;
  public reqestInProgress: boolean = false;
  public hideBulkEnkeltBtns: boolean = false;
  public mainMenuLineDietTypeNr1LineNumber: number;
  public topMessageSimple: TopMessageSimple;
  public guestChefModel: GuestChefModel;
  public servingDay: string;
  public user:any
  private selectedGuesChefDishes:any
  private currentGuestChefDate:any

  @ViewChild('contentPopUp', { static: false }) contentPopUp: ElementRef;

  constructor(
    private dataSource: RestDataSource,
    private localStorageService: LocalStorageService,
    private cart: Cart,
    private router: Router,
    private dateService: DateService,
    private dishRepository: DishRepository,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private auth: AuthService,
    public dataShareService: DataShareService,
    public navigationService: NavigationService,
    private localeDaDKService: LocaleDaDKService,
    public guestChefService: GuestChefService,
    public dishResponseService: DishResponseService
  ) {
    this.localStorageService.set('currentChildRoute', 'bulkorder/editweekplan');
    this.localStorageService.set(
      'pageForOrderChildRoute',
      'bulkorder/viewweekplan'
    );
    this.localStorageService.set('parentPrintRoute', 'bulkorder/editweekplan');
    this.localStorageService.set('fromPage', 'bulkorder');
    this.departmentId = this.localStorageService.get('currentdepartmentId');
    this.user = this.localStorageService.get('user');
    this.needUnsubscribe = false;
    this.dishAllocationsOrderDays = new DishAllocationsOrderDays();
    this.menuLineArrForFuldkost = new MenuLineArrayOrderDays();
    this.menuLineArrForEnrgitat = new MenuLineArrayOrderDays();
    this.menuLineArrForBlod = new MenuLineArrayOrderDays();
    this.menuLineArrForAndet = new MenuLineArrayOrderDays();
    this.disabledAfterOrder = false;

    this.changedShiftWeek = false;
  }

  ngOnInit(): void {
    this.ifWeHaveCategory = false;
    this.needNgOninit = false;
    this.orderFromViewWeekPlan = true;
    this.isProductNrFilterOn = false;
    //max attempts to send oder when fail
    this.maxRetries = 2;
    this.isDietTypeNr30 = false;
    this.reqestInProgress = false;
    //-------------------NavTabs init ------------------------------------

    let toggledDay = this.localStorageService.get('toggledDayBulkOrder');
    this.initTabs(toggledDay);

    //----------------------------------------------------------------------------------
    this.fuldkostCategoryArr = [
      'Fuldkost',
      'Fuldkost m. kogte kartof.',
      'Fuldkost m. rå kartof.',
      'Fuldkost m/mos',
      'Fuldkost % kartofler',
    ];
    //----------------------------------------------------------------------------

    this.sumOder = this.localStorageService.get('sumOder');

    //---------------------Date------------------------------------
    this.initDate();

    //--------------------- GET dishAllocations for All days in Week-----------------------------
    if (!this.needUnsubscribe) {
      this.obsSubscription = this.activatedRoute.data.subscribe((data) => {
        console.log('data')
        console.log(data)
        this.dishAllocationsWeekResponse = [];
        this.dishAllocationsOrderDaysInitTuesday = [];
        this.dishAllocationsOrderDaysInitThursday = [];
        this.dishAllocationsOrderDaysInitSaturday = [];
        this.dishAllocationsOrderDays = new DishAllocationsOrderDays();
        this.dishAllDayFilteredDietTypeGroups = new DishAllocationsOrderDays();
        this.dishEnergitatAllDayFilteredDietTypeGroups = new DishAllocationsOrderDays();
        this.dishBlodAllDayFilteredDietTypeGroups = new DishAllocationsOrderDays();
        this.sideDishesStartDays = new SideDishesStartDays();
        this.dishAllocationsWeekResponse = data['dishes'];
        console.log('---***************************this.dishAllocationsWeekResponse')
        console.log(this.dishAllocationsWeekResponse)
        console.log('---***************************this.dishAllocationsWeekResponse')
        for(let i1=0;i1<this.dishAllocationsWeekResponse.length;i1++){
          for(let i2=0;i2<this.dishAllocationsWeekResponse[i1].servings.length;i2++){
            if(this.dishAllocationsWeekResponse[i1].servings[i2].isGuestChef){
              console.log('bingo bongo ' + this.dishAllocationsWeekResponse[i1].servings[i2].servingDate)
              this.currentGuestChefDate = this.dishAllocationsWeekResponse[i1].servings[i2].servingDate
            }
          } 
        }

        this.departmentId = this.localStorageService.get('currentdepartmentId');

        //------------------------------------------Order Changed-----------------------------------------------
        this.checkIfOrderChanged();

        console.log(
          '[ngOnInit] dishAllocationsWeekResponse AFTER=' +
            JSON.stringify(this.dishAllocationsWeekResponse)
        );

        //-----------------------------Merge the same lines--------------------------------------------
          this.dishAllocationsWeekResponse[0] = this.getResponseWithMergedLines(this.dishAllocationsWeekResponse[0])
        //----------------------------------------------------------
        if (this.dishAllocationsWeekResponse[0]) {
          this.orderNotPermit = this.checkPermittionsForOrder(this.dishAllocationsWeekResponse);

        }


        let toggledDay1 = this.localStorageService.get('toggledDayBulkOrder');
        //------------------------------------------Order Changed-----------------------------------------------

        //-------------------------------------------Order------------------------------------------------------

        this.isOrderingTimeFlag = false;
        this.simpleOderModelArrayId =
          'simpleOderModelArray' +
          this.localStorageService.get('currentChildRoute') +
          this.shiftWeekNumber +
          this.departmentId;
        console.debug(
          'EDIT this.simpleOderModelArrayId=' + this.simpleOderModelArrayId
        );
        this.simpleOderModelArray = this.localStorageService.get(
          this.simpleOderModelArrayId
        );
        console.debug('this.simpleOderModelArray', this.simpleOderModelArray);
        if (
          this.simpleOderModelArray == null ||
          this.simpleOderModelArray == undefined ||
          this.simpleOderModelArray.length == 0
        ) {
          this.simpleOderModelArray = new Array();
          this.sumOder = 0;
        } else {
          for (var oder in this.simpleOderModelArray) {
            this.sumOder += this.simpleOderModelArray[oder].sumOder;
          }
        }

        this.dishAllocationsWeekResponse = this.initDishAllocationsWeekResponse(toggledDay1, this.dishAllocationsWeekResponse);
        
        console.log('***************************this.dishAllocationsWeekResponse')
        console.log(this.dishAllocationsWeekResponse)
        console.log('***************************this.dishAllocationsWeekResponse')

        console.info(
          'dishAllocationsOrderDays1=' +
            JSON.stringify(this.dishAllocationsOrderDays)
        );

        //}

        console.debug(
          '[ngOninit] this.dishEnergitatAllDayFilteredDietTypeGroups',
          this.dishEnergitatAllDayFilteredDietTypeGroups
        );

        console.debug(
          '[ngOninit] this.dishBlodAllDayFilteredDietTypeGroups',
          this.dishBlodAllDayFilteredDietTypeGroups
        );

        //-----------------------------------Save All Dishes------------------------------------------

        this.dishAllocationsOrderDaysAll = JSON.parse(
          JSON.stringify(this.dishAllocationsOrderDays)
        ); // save View All Dishes
        //--------------------------------------------------------------------------------------------------

        //---------------------------------------------------------------------------------------

        this.needNgOninit = true;
        this.needUnsubscribe = true;

        console.debug(
          '[ngOnInit] this.sideDishesStartDays',
          this.sideDishesStartDays
        );
        console.info(
          '[ngOnInit] this.dishAllocationsOrderDays22=' +
            JSON.stringify(this.dishAllocationsOrderDays)
        );
          //************ TopMessage ****************/
            let title = 'Bestillingsfrist<br> 3 hverdage';
            this.topMessageSimple = new TopMessageSimple(title);

      });
    }
    //--------------------------Filter -----------------------------------------------

    //-----------------------GET  UserInfoOrderingModel ---------------------------------------------------------

    this.userInfoOrderingModel = this.localStorageService.get(
      'userInfoOrderingModel'
    );
    this.orderingDates = this.userInfoOrderingModel['orderingDates'];
    console.debug(
      'EDITWEEKPLAN this.orderingDates=' + JSON.stringify(this.orderingDates)
    );

    //-------------------------------------------------------------------------------------------------------------
    this.orderDishId = 0;
    this.currentToogledDay = 'Tuesday';

    console.debug(
      'this.isOrderingTimeFlag=' +
        this.isOrderingTime(this.userInfoOrderingModel, this.categoryForFilter)
    );

    //----------------------Modal Window init -----------------------

    this.modalBtnFlag = 'dishInfo';
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://www.youtube.com/embed/nOIwHaiojDI'
    );
    this.modalBtnBackgroundColorBulk = '#5B7B12';
    this.modalBtnBackgroundColorEnkel = '#FFFFFF';
    this.modalBtnTextColorBulk = '#FFFFFF';
    this.modalBtnTextColorEnkel = '#000000';
    this.modalBtnTextWeight = 'bold';

    //--------------------------Modal Fuldkost------------------------

    this.currentDayForModal = 'Tuesday';
    this.toogledModalBulk = true;
    this.toogledModalEnkel = false;

    //--------------------------- Sum I alt --------------------------------
    this.sumMatrixForDays = new SumMatrixForDays();
    this.sumMatrixForDays = this.createSumMatrixForDays(
      this.dishAllocationsOrderDays
    );
    console.info('this.sumMatrixForDaysINIT', this.sumMatrixForDays);

    //----------------------------Update quontity from LocalStorage ---------------------

    for (let day in this.dishAllocationsOrderDays) {
      console.debug('[ngOninit] dayUpdate', day);

      for (let itemMainDishes in this.dishAllocationsOrderDays[day][
        'mainDishes'
      ]) {
        this.dishAllocationsOrderDays[day]['mainDishes'][
          itemMainDishes
        ] = JSON.parse(
          JSON.stringify(
            this.updateQuontityFromSimpleOderModelArray(
              this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes],
              this.simpleOderModelArray,
              day
            )
          )
        );

        //console.log('[ngOninit] this.dishEnergitatAllDayFilteredDietTypeGroups', this.dishEnergitatAllDayFilteredDietTypeGroups)
        //if(this.dishEnergitatAllDayFilteredDietTypeGroups[day] && this.dishEnergitatAllDayFilteredDietTypeGroups[day].length!=0){
        this.dishEnergitatAllDayFilteredDietTypeGroups[day]['mainDishes'][
          itemMainDishes
        ] = JSON.parse(
          JSON.stringify(
            this.updateQuontityFromSimpleOderModelArray(
              this.dishEnergitatAllDayFilteredDietTypeGroups[day]['mainDishes'][
                itemMainDishes
              ],
              this.simpleOderModelArray,
              day
            )
          )
        );

        //}
        //if(this.dishBlodAllDayFilteredDietTypeGroups[day] && this.dishBlodAllDayFilteredDietTypeGroups[day].length!=0){
        this.dishBlodAllDayFilteredDietTypeGroups[day]['mainDishes'][
          itemMainDishes
        ] = JSON.parse(
          JSON.stringify(
            this.updateQuontityFromSimpleOderModelArray(
              this.dishBlodAllDayFilteredDietTypeGroups[day]['mainDishes'][
                itemMainDishes
              ],
              this.simpleOderModelArray,
              day
            )
          )
        );

        this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][
          itemMainDishes
        ] = JSON.parse(
          JSON.stringify(
            this.updateQuontityFromSimpleOderModelArray(
              this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][
                itemMainDishes
              ],
              this.simpleOderModelArray,
              day
            )
          )
        );

        //}
      }
    }
    console.info(
      '[ngOnInit] Updated dishAllocationsOrderDays',
      this.dishAllocationsOrderDays
    );

    //----------------------------Modal Done----------------------------------------
    this.delay = 2000;
    ////---------------------------------------------------------------------------------
    if (!environment.debugMode) {
      console.clear();
    }
    this.getSelectedGuesChefDishes()
  }

  //-----------------------------------------------------------------------------------------------------
  //*****************************************************************************************************
  //------------------------------- Component Internal Methods------------------------------------------

  ngAfterContentChecked() {
    if (this.needNgOninit) {
      this.ngOnInit();
    }

    if (!environment.debugMode) {
      console.clear();
    }
  }
  ngOnDestroy() {
    this.obsSubscription.unsubscribe();
  }

  //-----------------------------Methods--------------------------------------------------------------
  initTabs(toggledDay: string): void {
    switch (toggledDay) {
      case 'Tuesday':
        this.toggledTuesday = true;
        this.toggledThursday = false;
        this.toggledSaturday = false;
        this.templateDays = ['Tuesday', 'Wednesday'];
        break;
      case 'Thursday':
        this.toggledTuesday = false;
        this.toggledThursday = true;
        this.toggledSaturday = false;
        this.templateDays = ['Thursday', 'Friday', 'Saturday'];

        break;
      case 'Saturday':
        //console.debug("Saturday");
        this.toggledTuesday = false;
        this.toggledThursday = false;
        this.toggledSaturday = true;
        this.templateDays = ['Sunday', 'Monday'];

        break;
      default:
        this.localStorageService.set('toggledDayBulkOrder', 'Tuesday');
        this.toggledTuesday = true;
        this.toggledThursday = false;
        this.toggledSaturday = false;
        this.templateDays = ['Tuesday', 'Wednesday'];
    }
  }
  initDate() {
    this.shiftWeek = this.localStorageService.get(
      'shiftWeek' + this.localStorageService.get('currentChildRoute')
    );

    console.debug('this.shiftWeek=' + this.shiftWeek);
    this.currentDayNumber = this.dateService.getCurrentDayNumber();

    this.currentWeek = this.dateService.getCurrentWeek();

    this.shiftWeekNumber = this.dateService.getShiftWeek(this.shiftWeek);

    this.SundayDate = this.dateService.getISODateDDMMMforDay(
      'Sunday',
      this.shiftWeek
    );
    this.MondayDate = this.dateService.getISODateDDMMMforDay(
      'Monday',
      this.shiftWeek
    );
    this.SundayNextWeekDate = this.dateService.getISODateDDMMMforDay(
      'Sunday',
      this.shiftWeek + 1
    );
    this.MondayNextWeekDate = this.dateService.getISODateDDMMMforDay(
      'Monday',
      this.shiftWeek + 1
    );
    this.TuesdayDate = this.dateService.getISODateDDMMMforDay(
      'Tuesday',
      this.shiftWeek
    );
    this.WednesdayDate = this.dateService.getISODateDDMMMforDay(
      'Wednesday',
      this.shiftWeek
    );
    this.ThursdayDate = this.dateService.getISODateDDMMMforDay(
      'Thursday',
      this.shiftWeek
    );
    this.FridayDate = this.dateService.getISODateDDMMMforDay(
      'Friday',
      this.shiftWeek
    );
    this.SaturdayDate = this.dateService.getISODateDDMMMforDay(
      'Saturday',
      this.shiftWeek
    );
  }

  checkIfOrderChanged() {
    this.guestChefModel = this.initGuestChefModel(this.dishAllocationsWeekResponse);

    this.servingDaysNumberOrdered = new ServingDaysNumberOrdered(
          false,
          false,
          false,
          false,
          false,
          false,
          false
        );
    this.deliveryDaysNumberOrdered = new DeliveryDaysNumberOrdered(0, 0, 0);

        //---------------------------------------------------------------------------------------------
        if (
          this.dishAllocationsWeekResponse[0] &&
          this.dishAllocationsWeekResponse[0]['servings']
        ) {
          //--------------------------------------------Serving Days Ordered-------------------------------
          this.deliveryDaysNumberOrderedId =
            'deliveryDaysNumberOrdered' +
            this.localStorageService.get('currentChildRoute') +
            this.shiftWeekNumber +
            this.departmentId;

          this.deliveryDaysNumberOrdered[
            'Tuesday'
          ] = this.getNumberOfSelectedHovedretFromResponse(
            this.dishAllocationsWeekResponse[0]['servings'],
            'Tuesday'
          );

          console.debug(
            '[ngOnInit] this.deliveryDaysNumberOrdered[Tuesday]',
            this.deliveryDaysNumberOrdered['Tuesday']
          );

          if (
            this.dishAllocationsWeekResponse[1] &&
            this.dishAllocationsWeekResponse[1]['servings']
          ) {
            this.deliveryDaysNumberOrdered[
              'Thursday'
            ] = this.getNumberOfSelectedHovedretFromResponse(
              this.dishAllocationsWeekResponse[1]['servings'],
              'Thursday'
            );
            console.debug(
              '[ngOnInit] this.deliveryDaysNumberOrdered[Thursday]',
              this.deliveryDaysNumberOrdered['Thursday']
            );
          } else {
            this.deliveryDaysNumberOrdered['Thursday'] = 0;
          }

          if (
            this.dishAllocationsWeekResponse[2] &&
            this.dishAllocationsWeekResponse[2]['servings']
          ) {
            this.deliveryDaysNumberOrdered[
              'Saturday'
            ] = this.getNumberOfSelectedHovedretFromResponse(
              this.dishAllocationsWeekResponse[2]['servings'],
              'Saturday'
            );

            console.debug(
              '[ngOnInit] this.deliveryDaysNumberOrdered[Saturday]',
              this.deliveryDaysNumberOrdered['Saturday']
            );
          } else {
            this.deliveryDaysNumberOrdered['Saturday'] = 0;
          }

          console.debug(
            '[ngOnInit] this.deliveryDaysNumberOrdered',
            this.deliveryDaysNumberOrdered
          );
        }

        let deliveryDay = this.localStorageService.get('toggledDayBulkOrder');
        switch (deliveryDay) {
          case 'Tuesday':
            break;
          case 'Thursday':
            this.dishAllocationsWeekResponse[0] = this.dishAllocationsWeekResponse[1];
            break;
          case 'Saturday':
            this.dishAllocationsWeekResponse[0] = this.dishAllocationsWeekResponse[2];

            break;
          default: {
          }
        }
    this.dishAllocationsWeekResponse.pop();
    this.dishAllocationsWeekResponse.pop();
  }

  initDishAllocationsWeekResponse(toggledDay: string, dishResponse: any) {
    let dishAllocationsWeekResponse = JSON.parse(JSON.stringify(dishResponse));
    switch (toggledDay) {
          case 'Tuesday': {
            if (
              dishAllocationsWeekResponse[0] &&
              dishAllocationsWeekResponse[0]['servings']
            ) {
              dishAllocationsWeekResponse[0]['servings'] = JSON.parse(
                JSON.stringify(
                  this.filterResponseByUndefined(
                    dishAllocationsWeekResponse
                  )
                )
              );
              if (
                dishAllocationsWeekResponse[0] &&
                dishAllocationsWeekResponse[0]['servings'].length != 0 &&
                dishAllocationsWeekResponse[0]['servings']['0'] !==
                  undefined &&
                dishAllocationsWeekResponse[0]['servings']['0'][
                  'servingDate'
                ] ==
                  this.dateService.getISODateYYYMMDDforDay(
                    'Tuesday',
                    this.shiftWeek
                  )
              ) {
                if (this.orderFromViewWeekPlan) {
                  if (
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ] &&
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ].length == 0
                  ) {
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ] = dishAllocationsWeekResponse[0]['servings']['0'][
                      'sideDishes'
                    ];
                    this.sideDishesStartDays['Tuesday'] = 0;
                  } else if (
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ].length != 0 &&
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'sideDishes'
                    ].length != 0
                  ) {
                    this.sideDishesStartDays[
                      'Tuesday'
                    ] = dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                      ].length;

                    //-------- concat mainDises and sideDishes------------

                    dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'] = dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ].concat(
                      dishAllocationsWeekResponse[0]['servings']['0'][
                        'sideDishes'
                      ]
                    );


                  }


                  //-------------------------------------------------------------
                  dishAllocationsWeekResponse[0][
                    'servings'
                  ][0].customerNr = dishAllocationsWeekResponse[0][
                    'customerNr'
                  ];
                  /*dishAllocationsWeekResponse[0][
                    'servings'
                  ][0].orderNr = dishAllocationsWeekResponse[0]['orderNr'];

                  dishAllocationsWeekResponse[0][
                    'servings'
                  ][0].menuNr = dishAllocationsWeekResponse[0]['menuNr'];*/
                  //-------------------------------------------------------------
                  this.dishAllocationsOrderDays['Tuesday'] = dishAllocationsWeekResponse[0]['servings'][0];

                  // filter current dishAllocationsOrderDays by productNr from simpleOderModelArray
                  this.dishAllocationsOrderDays['Tuesday']['mainDishes'] = this.productNrFilter(
                    this.dishAllocationsOrderDays['Tuesday'],
                    this.productNrForDaysimpleOderModelArrayFilter(
                      this.simpleOderModelArray,
                      'Tuesday'
                    )
                  );
                  this.sortResponseDay(
                    this.dishAllocationsOrderDays['Tuesday']
                  );
                  //---------Concat DietTypeName and NameProductName--------

                  this.concatDietTypeNameProductNameServDay(this.dishAllocationsOrderDays['Tuesday'])

                  let dishAllocationsOrderDaysEnergitat = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDays['Tuesday'])
                  );
                  this.dishEnergitatAllDayFilteredDietTypeGroups[
                    'Tuesday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
                        dishAllocationsOrderDaysEnergitat,
                        'Energitæt'
                      )
                    )
                  );
                  if (
                    this.dishEnergitatAllDayFilteredDietTypeGroups['Tuesday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'] &&
                    this.dishEnergitatAllDayFilteredDietTypeGroups['Tuesday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'].length == 0
                  ) {
                    this.isEnergitatEmptyTuesday = true;
                    console.debug(
                      '[ngOninit]  this.isEnergitatEmptyTuesday',
                      this.isEnergitatEmptyTuesday
                    );
                  } else {
                    this.isEnergitatEmptyTuesday = false;
                  }
                  let dishAllocationsOrderDaysBlod = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDays['Tuesday'])
                  );
                  this.dishBlodAllDayFilteredDietTypeGroups[
                    'Tuesday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
                        dishAllocationsOrderDaysBlod,
                        'Blød'
                      )
                    )
                  );
                  if (
                    this.dishBlodAllDayFilteredDietTypeGroups['Tuesday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'] &&
                    this.dishBlodAllDayFilteredDietTypeGroups['Tuesday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'].length == 0
                  ) {
                    this.isBlodEmptyTuesday = true;
                    console.debug(
                      '[ngOninit] this.isBlodEmptyTuesday',
                      this.isBlodEmptyTuesday
                    );
                  } else {
                    this.isBlodEmptyTuesday = false;
                  }
                  console.debug(
                    '[ngOninit] BlodAllDayFilteredDietTypeGroups',
                    this.dishBlodAllDayFilteredDietTypeGroups['Tuesday']
                  );
                  let indexStartSideDishForCurDay = this.sideDishesStartDays['Tuesday']

                  //----get dishAllDayFilteredDietTypeGroups struct for Andet------

                  this.dishAllDayFilteredDietTypeGroups['Tuesday'] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(
                        this.dishAllocationsOrderDays['Tuesday'],
                        indexStartSideDishForCurDay
                      )
                    )
                  );
                  console.debug(
                    '[ngOninit] this.dishAllocationsOrderDaysTuesday',
                    JSON.stringify(this.dishAllocationsOrderDays)
                  );
                  console.debug(
                    '[ngOninit] BlodAllDayFilteredDietTypeGroups2',
                    this.dishBlodAllDayFilteredDietTypeGroups['Tuesday']
                  );
                } else {
                  this.dishAllocationsOrderDays[
                    'Tuesday'
                  ] = dishAllocationsWeekResponse[0]['servings'][0];
                }
                //-----------------------------------------Order----------------------------------------------------------------------------------

                this.simpleOderModelArrayId =
                  'simpleOderModelArray' +
                  this.localStorageService.get('currentChildRoute') +
                  this.shiftWeekNumber +
                  this.departmentId;
                this.simpleOderModelArray = this.localStorageService.get(
                  this.simpleOderModelArrayId
                );
                if (!this.simpleOderModelArray) {
                  this.simpleOderModelArray = new Array();
                  this.sumOder = 0;
                }

                this.updateOrdersInLocalStorageFromResponse(
                  'Tuesday',
                  JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
                );
                if (_.isEmpty(this.dishAllocationsOrderDays['Tuesday'])) {
                  console.debug(
                    'this.dishAllocationsOrderDays[\'Tuesday\'].length==0'
                  );
                  this.isEmptyTuesday = true;
                }
              } else {
                this.isEmptyTuesday = true;
              }

              if (
                (dishAllocationsWeekResponse[0]! &&
                  dishAllocationsWeekResponse[0]['servings'].length != 0 &&
                  dishAllocationsWeekResponse[0]['servings']['1'] !==
                    undefined &&
                  dishAllocationsWeekResponse[0]['servings']['1'][
                    'servingDate'
                  ] ==
                    this.dateService.getISODateYYYMMDDforDay(
                      'Wednesday',
                      this.shiftWeek
                    )) ||
                (dishAllocationsWeekResponse[0] !== undefined &&
                  dishAllocationsWeekResponse[0]['servings'].length != 0 &&
                  dishAllocationsWeekResponse[0]['servings']['0'][
                    'servingDate'
                  ] ==
                    this.dateService.getISODateYYYMMDDforDay(
                      'Wednesday',
                      this.shiftWeek
                    ))
              ) {
                let servingDayIndex = 1;
                console.debug(
                  'WednesdayISO',
                  this.dateService.getISODateYYYMMDDforDay(
                    'Wednesday',
                    this.shiftWeek
                  )
                );
                if (
                  dishAllocationsWeekResponse[0]['servings']['0'] &&
                  dishAllocationsWeekResponse[0]['servings']['0'][
                    'servingDate'
                  ] ==
                    this.dateService.getISODateYYYMMDDforDay(
                      'Wednesday',
                      this.shiftWeek
                    )
                ) {
                  servingDayIndex = 0;
                }

                console.debug('servingDayIndex', servingDayIndex);

                if (this.orderFromViewWeekPlan) {
                  if (
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'] &&
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'].length == 0
                  ) {
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'] = dishAllocationsWeekResponse[0][
                      'servings'
                    ][servingDayIndex]['sideDishes'];
                    this.sideDishesStartDays['Wednesday'] = 0;
                  } else if (
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'].length != 0 &&
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['sideDishes'].length != 0
                  ) {
                    this.sideDishesStartDays[
                      'Wednesday'
                    ] = dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'].length;

                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'] = dishAllocationsWeekResponse[0][
                      'servings'
                    ][servingDayIndex]['mainDishes'].concat(
                      dishAllocationsWeekResponse[0]['servings'][
                        servingDayIndex
                      ]['sideDishes']
                    );

                    console.debug(
                      'dishAllocationsWeekResponse[0][servings][1]',
                      JSON.stringify(
                        dishAllocationsWeekResponse[0]['servings'][
                          servingDayIndex
                        ]
                      )
                    );
                  }
                  //-------------------------------------------------------------
                  dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ].customerNr = dishAllocationsWeekResponse[0][
                    'customerNr'
                  ];
                  /*dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ].orderNr = dishAllocationsWeekResponse[0]['orderNr'];

                  dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ].menuNr = dishAllocationsWeekResponse[0]['menuNr'];*/

                  //-------------------------------------------------------------
                  this.dishAllocationsOrderDays[
                    'Wednesday'
                  ] = dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ];
                  this.dishAllocationsOrderDays['Wednesday'][
                    'mainDishes'
                  ] = this.productNrFilter(
                    this.dishAllocationsOrderDays['Wednesday'],
                    this.productNrForDaysimpleOderModelArrayFilter(
                      this.simpleOderModelArray,
                      'Wednesday'
                    )
                  );
                  this.sortResponseDay(
                    this.dishAllocationsOrderDays['Wednesday']
                  );
                  //---------Concat DietType and NameProductName
                  this.concatDietTypeNameProductNameServDay(this.dishAllocationsOrderDays['Wednesday'])

                  let dishAllocationsOrderDaysEnergitat = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDays['Wednesday'])
                  );
                  this.dishEnergitatAllDayFilteredDietTypeGroups[
                    'Wednesday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
                        dishAllocationsOrderDaysEnergitat,
                        'Energitæt'
                      )
                    )
                  );
                  if (
                    this.dishEnergitatAllDayFilteredDietTypeGroups['Wednesday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'] &&
                    this.dishEnergitatAllDayFilteredDietTypeGroups['Wednesday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'].length == 0
                  ) {
                    this.isEnergitatEmptyWednesday = true;
                    console.debug(
                      '[ngOninit]  this.isEnergitatEmptyWednesday',
                      this.isEnergitatEmptyTuesday
                    );
                  } else {
                    this.isEnergitatEmptyWednesday = false;
                  }
                  let dishAllocationsOrderDaysBlod = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDays['Wednesday'])
                  );
                  this.dishBlodAllDayFilteredDietTypeGroups[
                    'Wednesday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
                        dishAllocationsOrderDaysBlod,
                        'Blød'
                      )
                    )
                  );
                  if (
                    this.dishBlodAllDayFilteredDietTypeGroups['Wednesday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'] &&
                    this.dishBlodAllDayFilteredDietTypeGroups['Wednesday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'].length == 0
                  ) {
                    this.isBlodEmptyWednesday = true;
                    console.debug(
                      '[ngOninit]  this.isEnergitatEmptyWednesday',
                      this.isEnergitatEmptyTuesday
                    );
                  } else {
                    this.isBlodEmptyWednesday = false;
                  }
                  let indexStartSideDishForCurDay = this.sideDishesStartDays['Wednesday']
                  this.dishAllDayFilteredDietTypeGroups[
                    'Wednesday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(
                        this.dishAllocationsOrderDays['Wednesday'],
                        indexStartSideDishForCurDay
                      )
                    )
                  );
                  console.debug(
                    'this.dishAllocationsOrderDaysTuesday',
                    JSON.stringify(this.dishAllocationsOrderDays)
                  );
                } else {
                  this.dishAllocationsOrderDays[
                    'Wednesday'
                  ] = dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ];
                }
                //-----------------------------------------Order----------------------------------------------------------------------------------
                this.simpleOderModelArrayId =
                  'simpleOderModelArray' +
                  this.localStorageService.get('currentChildRoute') +
                  this.shiftWeekNumber +
                  this.departmentId;
                this.simpleOderModelArray = this.localStorageService.get(
                  this.simpleOderModelArrayId
                );
                if (!this.simpleOderModelArray) {
                  this.simpleOderModelArray = new Array();
                  this.sumOder = 0;
                }

                this.updateOrdersInLocalStorageFromResponse(
                  'Wednesday',
                  JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
                );
                if (_.isEmpty(this.dishAllocationsOrderDays['Wednesday'])) {
                  console.debug(
                    'this.dishAllocationsOrderDays[\'Wednesday\'].length==0'
                  );
                  this.isEmptyWednesday = true;
                }
              } else {
                this.isEmptyWednesday = true;
              }
              break;
            }
          }

          case 'Thursday': {
            if (
              dishAllocationsWeekResponse[0] &&
              dishAllocationsWeekResponse[0]['servings']
            ) {
              dishAllocationsWeekResponse[0]['servings'] = JSON.parse(
                JSON.stringify(
                  this.filterResponseByUndefined(
                    dishAllocationsWeekResponse
                  )
                )
              );
              if (
                dishAllocationsWeekResponse[0] &&
                dishAllocationsWeekResponse[0]['servings'].length != 0 &&
                dishAllocationsWeekResponse[0]['servings']['0'] !==
                  undefined &&
                dishAllocationsWeekResponse[0]['servings']['0'][
                  'servingDate'
                ] ==
                  this.dateService.getISODateYYYMMDDforDay(
                    'Thursday',
                    this.shiftWeek
                  )
              ) {
                if (this.orderFromViewWeekPlan) {
                  if (
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ] &&
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ].length == 0
                  ) {
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ] = dishAllocationsWeekResponse[0]['servings']['0'][
                      'sideDishes'
                    ];
                    this.sideDishesStartDays['Thursday'] = 0;
                  } else if (
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ].length != 0 &&
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'sideDishes'
                    ].length != 0
                  ) {
                    this.sideDishesStartDays[
                      'Thursday'
                    ] = dishAllocationsWeekResponse[0]['servings'][0][
                      'mainDishes'
                    ].length;
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ] = dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ].concat(
                      dishAllocationsWeekResponse[0]['servings']['0'][
                        'sideDishes'
                      ]
                    );

                    console.debug(
                      'dishAllocationsWeekResponse[0][servings][0]',
                      JSON.stringify(
                        dishAllocationsWeekResponse[0]['servings'][0]
                      )
                    );
                  }

                  //-------------------------------------------------------------
                  dishAllocationsWeekResponse[0][
                    'servings'
                  ][0].customerNr = dishAllocationsWeekResponse[0][
                    'customerNr'
                  ];
                  /*dishAllocationsWeekResponse[0][
                    'servings'
                  ][0].orderNr = dishAllocationsWeekResponse[0]['orderNr'];

                  dishAllocationsWeekResponse[0][
                    'servings'
                  ][0].menuNr = dishAllocationsWeekResponse[0]['menuNr'];*/

                  //-------------------------------------------------------------
                  this.dishAllocationsOrderDays[
                    'Thursday'
                  ] = dishAllocationsWeekResponse[0]['servings'][0];
                  this.dishAllocationsOrderDays['Thursday'][
                    'mainDishes'
                  ] = this.productNrFilter(
                    this.dishAllocationsOrderDays['Thursday'],
                    this.productNrForDaysimpleOderModelArrayFilter(
                      this.simpleOderModelArray,
                      'Thursday'
                    )
                  );
                  this.sortResponseDay(
                    this.dishAllocationsOrderDays['Thursday']
                  );
                  //---------Concat DietType and NameProductName
                  this.concatDietTypeNameProductNameServDay(this.dishAllocationsOrderDays['Thursday'])

                  let dishAllocationsOrderDaysEnergitat = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDays['Thursday'])
                  );
                  this.dishEnergitatAllDayFilteredDietTypeGroups[
                    'Thursday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
                        dishAllocationsOrderDaysEnergitat,
                        'Energitæt'
                      )
                    )
                  );
                  if (
                    this.dishEnergitatAllDayFilteredDietTypeGroups['Thursday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'] &&
                    this.dishEnergitatAllDayFilteredDietTypeGroups['Thursday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'].length == 0
                  ) {
                    this.isEnergitatEmptyThursday = true;
                    console.debug(
                      '[ngOninit]  this.isEnergitatEmptyThursday',
                      this.isEnergitatEmptyTuesday
                    );
                  } else {
                    this.isEnergitatEmptyThursday = false;
                  }
                  let dishAllocationsOrderDaysBlod = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDays['Thursday'])
                  );
                  this.dishBlodAllDayFilteredDietTypeGroups[
                    'Thursday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
                        dishAllocationsOrderDaysBlod,
                        'Blød'
                      )
                    )
                  );
                  if (
                    this.dishBlodAllDayFilteredDietTypeGroups['Thursday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'] &&
                    this.dishBlodAllDayFilteredDietTypeGroups['Thursday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'].length == 0
                  ) {
                    this.isBlodEmptyThursday = true;
                    console.debug(
                      '[ngOninit]  this.isEnergitatEmptyThursday',
                      this.isEnergitatEmptyTuesday
                    );
                  } else {
                    this.isBlodEmptyThursday = false;
                  }
                  let indexStartSideDishForCurDay = this.sideDishesStartDays['Thursday'];
                  this.dishAllDayFilteredDietTypeGroups[
                    'Thursday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(
                        this.dishAllocationsOrderDays['Thursday'],
                        indexStartSideDishForCurDay
                      )
                    )
                  );
                  console.debug(
                    'this.dishAllocationsOrderDaysTuesday',
                    JSON.stringify(this.dishAllocationsOrderDays)
                  );
                } else {
                  this.dishAllocationsOrderDays[
                    'Thursday'
                  ] = dishAllocationsWeekResponse[0]['servings'][0];
                }
                //-----------------------------------------Order----------------------------------------------------------------------------------
                this.simpleOderModelArrayId =
                  'simpleOderModelArray' +
                  this.localStorageService.get('currentChildRoute') +
                  this.shiftWeekNumber +
                  this.departmentId;
                this.simpleOderModelArray = this.localStorageService.get(
                  this.simpleOderModelArrayId
                );
                if (!this.simpleOderModelArray) {
                  this.simpleOderModelArray = new Array();
                  this.sumOder = 0;
                }

                this.updateOrdersInLocalStorageFromResponse(
                  'Thursday',
                  JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
                );
                if (_.isEmpty(this.dishAllocationsOrderDays['Thursday'])) {
                  console.debug(
                    'this.dishAllocationsOrderDays[\'Thursday\'].length==0'
                  );
                  this.isEmptyThursday = true;
                }
              } else {
                this.isEmptyThursday = true;
              }

              if (
                (dishAllocationsWeekResponse[0] &&
                  dishAllocationsWeekResponse[0]['servings'].length != 0 &&
                  dishAllocationsWeekResponse[0]['servings']['1'] !==
                    undefined &&
                  dishAllocationsWeekResponse[0]['servings']['1'][
                    'servingDate'
                  ] ==
                    this.dateService.getISODateYYYMMDDforDay(
                      'Friday',
                      this.shiftWeek
                    )) ||
                (dishAllocationsWeekResponse[0] !== undefined &&
                  dishAllocationsWeekResponse[0]['servings'].length != 0 &&
                  dishAllocationsWeekResponse[0]['servings']['0'][
                    'servingDate'
                  ] ==
                    this.dateService.getISODateYYYMMDDforDay(
                      'Friday',
                      this.shiftWeek
                    ))
              ) {
                console.debug(
                  'Err',
                  dishAllocationsWeekResponse[0]['servings']
                );
                let servingDayIndex = 1;
                console.debug(
                  'FridayISO',
                  this.dateService.getISODateYYYMMDDforDay(
                    'Friday',
                    this.shiftWeek
                  )
                );
                if (
                  dishAllocationsWeekResponse[0]['servings']['0'] !==
                    undefined &&
                  dishAllocationsWeekResponse[0]['servings']['0'][
                    'servingDate'
                  ] ==
                    this.dateService.getISODateYYYMMDDforDay(
                      'Friday',
                      this.shiftWeek
                    )
                ) {
                  servingDayIndex = 0;
                }

                console.debug('servingDayIndex', servingDayIndex);

                if (this.orderFromViewWeekPlan) {
                  if (
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'] &&
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'].length == 0
                  ) {
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'] = dishAllocationsWeekResponse[0][
                      'servings'
                    ][servingDayIndex]['sideDishes'];
                    this.sideDishesStartDays['Friday'] = 0;
                  } else if (
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'].length != 0 &&
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['sideDishes'].length != 0
                  ) {
                    this.sideDishesStartDays[
                      'Friday'
                    ] = dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'].length;
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'] = dishAllocationsWeekResponse[0][
                      'servings'
                    ][servingDayIndex]['mainDishes'].concat(
                      dishAllocationsWeekResponse[0]['servings'][
                        servingDayIndex
                      ]['sideDishes']
                    );

                    console.debug(
                      'dishAllocationsWeekResponse[0][servings][servingDayIndex]',
                      JSON.stringify(
                        dishAllocationsWeekResponse[0]['servings'][
                          servingDayIndex
                        ]
                      )
                    );
                  }

                  console.debug(
                    'this.dishAllocationsOrderDaysInitTuesday',
                    this.dishAllocationsOrderDaysInitTuesday
                  );
                  //-------------------------------------------------------------
                  dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ].customerNr = dishAllocationsWeekResponse[0][
                    'customerNr'
                  ];
                  /*dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ].orderNr = dishAllocationsWeekResponse[0]['orderNr'];

                  dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ].menuNr = dishAllocationsWeekResponse[0]['menuNr'];*/


                  //-------------------------------------------------------------
                  this.dishAllocationsOrderDays[
                    'Friday'
                  ] = dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ];
                  this.dishAllocationsOrderDays['Friday'][
                    'mainDishes'
                  ] = this.productNrFilter(
                    this.dishAllocationsOrderDays['Friday'],
                    this.productNrForDaysimpleOderModelArrayFilter(
                      this.simpleOderModelArray,
                      'Friday'
                    )
                  );
                  this.sortResponseDay(this.dishAllocationsOrderDays['Friday']);

                  //---------Concat DietType and NameProductName
                  this.concatDietTypeNameProductNameServDay(this.dishAllocationsOrderDays['Friday']);

                  let dishAllocationsOrderDaysEnergitat = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDays['Friday'])
                  );
                  this.dishEnergitatAllDayFilteredDietTypeGroups[
                    'Friday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
                        dishAllocationsOrderDaysEnergitat,
                        'Energitæt'
                      )
                    )
                  );
                  if (
                    this.dishEnergitatAllDayFilteredDietTypeGroups['Friday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'] &&
                    this.dishEnergitatAllDayFilteredDietTypeGroups['Friday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'].length == 0
                  ) {
                    this.isEnergitatEmptyFriday = true;
                    console.debug(
                      '[ngOninit]  this.isEnergitatEmptyFriday',
                      this.isEnergitatEmptyTuesday
                    );
                  } else {
                    this.isEnergitatEmptyFriday = false;
                  }
                  let dishAllocationsOrderDaysBlod = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDays['Friday'])
                  );
                  this.dishBlodAllDayFilteredDietTypeGroups[
                    'Friday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
                        dishAllocationsOrderDaysBlod,
                        'Blød'
                      )
                    )
                  );
                  if (
                    this.dishBlodAllDayFilteredDietTypeGroups['Friday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'] &&
                    this.dishBlodAllDayFilteredDietTypeGroups['Friday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'].length == 0
                  ) {
                    this.isBlodEmptyFriday = true;
                    console.debug(
                      '[ngOninit]  this.isBlodEmptyFriday',
                      this.isEnergitatEmptyTuesday
                    );
                  } else {
                    this.isBlodEmptyFriday = false;
                  }
                  let indexStartSideDishForCurDay = this.sideDishesStartDays['Friday'];
                  this.dishAllDayFilteredDietTypeGroups['Friday'] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(
                        this.dishAllocationsOrderDays['Friday'],
                        indexStartSideDishForCurDay
                      )
                    )
                  );
                  console.debug(
                    'this.dishAllocationsOrderDaysTuesday',
                    JSON.stringify(this.dishAllocationsOrderDays)
                  );
                } else {
                  this.dishAllocationsOrderDays[
                    'Friday'
                  ] = dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ];
                }
                //-----------------------------------------Order----------------------------------------------------------------------------------
                this.simpleOderModelArrayId =
                  'simpleOderModelArray' +
                  this.localStorageService.get('currentChildRoute') +
                  this.shiftWeekNumber +
                  this.departmentId;
                this.simpleOderModelArray = this.localStorageService.get(
                  this.simpleOderModelArrayId
                );
                if (!this.simpleOderModelArray) {
                  this.simpleOderModelArray = new Array();
                  this.sumOder = 0;
                }

                this.updateOrdersInLocalStorageFromResponse(
                  'Friday',
                  JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
                );

                if (_.isEmpty(this.dishAllocationsOrderDays['Friday'])) {
                  console.debug(
                    'this.dishAllocationsOrderDays[\'Friday\'].length==0'
                  );
                  this.isEmptyFriday = true;
                }
              } else {
                this.isEmptyFriday = true;
              }

              //----------------------------------------------------------------------------------

              if (
                (dishAllocationsWeekResponse[0] &&
                  dishAllocationsWeekResponse[0]['servings'].length != 0 &&
                  dishAllocationsWeekResponse[0]['servings']['2'] !==
                    undefined &&
                  dishAllocationsWeekResponse[0]['servings']['2'][
                    'servingDate'
                  ] ==
                    this.dateService.getISODateYYYMMDDforDay(
                      'Saturday',
                      this.shiftWeek
                    )) ||
                (dishAllocationsWeekResponse[0] !== undefined &&
                  dishAllocationsWeekResponse[0]['servings'].length != 0 &&
                  dishAllocationsWeekResponse[0]['servings']['1'] !==
                    undefined &&
                  dishAllocationsWeekResponse[0]['servings']['1'][
                    'servingDate'
                  ] ==
                    this.dateService.getISODateYYYMMDDforDay(
                      'Saturday',
                      this.shiftWeek
                    )) ||
                (dishAllocationsWeekResponse[0] !== undefined &&
                  dishAllocationsWeekResponse[0]['servings'].length != 0 &&
                  dishAllocationsWeekResponse[0]['servings']['0'][
                    'servingDate'
                  ] ==
                    this.dateService.getISODateYYYMMDDforDay(
                      'Saturday',
                      this.shiftWeek
                    ))
              ) {
                let servingDayIndex = 2;
                console.debug(
                  'SaturdayISO',
                  this.dateService.getISODateYYYMMDDforDay(
                    'Saturday',
                    this.shiftWeek
                  )
                );
                if (
                  dishAllocationsWeekResponse[0]['servings']['0'] &&
                  dishAllocationsWeekResponse[0]['servings']['0'][
                    'servingDate'
                  ] ==
                    this.dateService.getISODateYYYMMDDforDay(
                      'Saturday',
                      this.shiftWeek
                    )
                ) {
                  servingDayIndex = 0;
                } else if (
                  dishAllocationsWeekResponse[0]['servings']['1'] &&
                  dishAllocationsWeekResponse[0]['servings']['1'][
                    'servingDate'
                  ] ==
                    this.dateService.getISODateYYYMMDDforDay(
                      'Saturday',
                      this.shiftWeek
                    )
                ) {
                  servingDayIndex = 1;
                }

                console.debug('servingDayIndex', servingDayIndex);
                if (this.orderFromViewWeekPlan) {
                  if (
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'] &&
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'].length == 0
                  ) {
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'] = dishAllocationsWeekResponse[0][
                      'servings'
                    ][servingDayIndex]['sideDishes'];
                    this.sideDishesStartDays['Saturday'] = 0;
                  } else if (
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'].length != 0 &&
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['sideDishes'].length != 0
                  ) {
                    this.sideDishesStartDays[
                      'Saturday'
                    ] = dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'].length;
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'] = dishAllocationsWeekResponse[0][
                      'servings'
                    ][servingDayIndex]['mainDishes'].concat(
                      dishAllocationsWeekResponse[0]['servings'][
                        servingDayIndex
                      ]['sideDishes']
                    );

                    console.debug(
                      'dishAllocationsWeekResponse[0][servings][servingDayIndex]',
                      JSON.stringify(
                        dishAllocationsWeekResponse[0]['servings'][
                          servingDayIndex
                        ]
                      )
                    );
                  }

                  console.debug(
                    'this.dishAllocationsOrderDaysInitTuesday',
                    this.dishAllocationsOrderDaysInitTuesday
                  );
                  //-------------------------------------------------------------
                  dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ].customerNr = dishAllocationsWeekResponse[0][
                    'customerNr'
                  ];
                  /*dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ].orderNr = dishAllocationsWeekResponse[0]['orderNr'];

                  dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ].menuNr = dishAllocationsWeekResponse[0]['menuNr'];*/



                  //-------------------------------------------------------------
                  this.dishAllocationsOrderDays[
                    'Saturday'
                  ] = dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ];
                  this.dishAllocationsOrderDays['Saturday'][
                    'mainDishes'
                  ] = this.productNrFilter(
                    this.dishAllocationsOrderDays['Saturday'],
                    this.productNrForDaysimpleOderModelArrayFilter(
                      this.simpleOderModelArray,
                      'Saturday'
                    )
                  );
                  this.sortResponseDay(
                    this.dishAllocationsOrderDays['Saturday']
                  );
                  //---------Concat DietType and NameProductName
                  this.concatDietTypeNameProductNameServDay(this.dishAllocationsOrderDays['Saturday']);

                  let dishAllocationsOrderDaysEnergitat = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDays['Saturday'])
                  );
                  this.dishEnergitatAllDayFilteredDietTypeGroups[
                    'Saturday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
                        dishAllocationsOrderDaysEnergitat,
                        'Energitæt'
                      )
                    )
                  );
                  if (
                    this.dishEnergitatAllDayFilteredDietTypeGroups['Saturday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'] &&
                    this.dishEnergitatAllDayFilteredDietTypeGroups['Saturday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'].length == 0
                  ) {
                    this.isEnergitatEmptySaturday = true;
                    console.debug(
                      '[ngOninit]  this.isEnergitatEmptySaturday',
                      this.isEnergitatEmptyTuesday
                    );
                  } else {
                    this.isEnergitatEmptySaturday = false;
                  }
                  let dishAllocationsOrderDaysBlod = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDays['Saturday'])
                  );
                  this.dishBlodAllDayFilteredDietTypeGroups[
                    'Saturday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
                        dishAllocationsOrderDaysBlod,
                        'Blød'
                      )
                    )
                  );
                  if (
                    this.dishBlodAllDayFilteredDietTypeGroups['Saturday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'] &&
                    this.dishBlodAllDayFilteredDietTypeGroups['Saturday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'].length == 0
                  ) {
                    this.isBlodEmptySaturday = true;
                    console.debug(
                      '[ngOninit]  this.isEnergitatEmptySaturday',
                      this.isEnergitatEmptyTuesday
                    );
                  } else {
                    this.isBlodEmptySaturday = false;
                  }
                  let indexStartSideDishForCurDay = this.sideDishesStartDays['Saturday'];
                  this.dishAllDayFilteredDietTypeGroups[
                    'Saturday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(
                        this.dishAllocationsOrderDays['Saturday'],
                        indexStartSideDishForCurDay
                      )
                    )
                  );
                  console.debug(
                    'this.dishAllocationsOrderDaysTuesday',
                    JSON.stringify(this.dishAllocationsOrderDays)
                  );
                } else {
                  this.dishAllocationsOrderDays[
                    'Saturday'
                  ] = dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ];
                }
                //-----------------------------------------Order----------------------------------------------------------------------------------
                this.simpleOderModelArrayId =
                  'simpleOderModelArray' +
                  this.localStorageService.get('currentChildRoute') +
                  this.shiftWeekNumber +
                  this.departmentId;
                this.simpleOderModelArray = this.localStorageService.get(
                  this.simpleOderModelArrayId
                );
                if (!this.simpleOderModelArray) {
                  this.simpleOderModelArray = new Array();
                  this.sumOder = 0;
                }

                this.updateOrdersInLocalStorageFromResponse(
                  'Saturday',
                  JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
                );

                if (_.isEmpty(this.dishAllocationsOrderDays['Saturday'])) {
                  console.debug(
                    'this.dishAllocationsOrderDays[\'Saturday\'].length==0'
                  );
                  this.isEmptyFriday = true;
                }
              } else {
                this.isEmptyFriday = true;
              }

              break;
            }
          }
          case 'Saturday': {
            if (
              dishAllocationsWeekResponse[0] &&
              dishAllocationsWeekResponse[0]['servings']
            ) {
              dishAllocationsWeekResponse[0]['servings'] = JSON.parse(
                JSON.stringify(
                  this.filterResponseByUndefined(
                    dishAllocationsWeekResponse
                  )
                )
              );
              if (
                dishAllocationsWeekResponse[0] &&
                dishAllocationsWeekResponse[0]['servings'].length != 0 &&
                dishAllocationsWeekResponse[0]['servings']['0'] !==
                  undefined &&
                dishAllocationsWeekResponse[0]['servings']['0'][
                  'servingDate'
                ] ==
                  this.dateService.getISODateYYYMMDDforDay(
                    'Sunday',
                   // this.shiftWeek + 1
                     this.shiftWeek
                  )
              ) {
                if (this.orderFromViewWeekPlan) {
                  if (
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ] &&
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ].length == 0
                  ) {
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ] = dishAllocationsWeekResponse[0]['servings']['0'][
                      'sideDishes'
                    ];
                    this.sideDishesStartDays['Sunday'] = 0;
                  } else if (
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ].length != 0 &&
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'sideDishes'
                    ].length != 0
                  ) {
                    this.sideDishesStartDays[
                      'Sunday'
                    ] = dishAllocationsWeekResponse[0]['servings'][0][
                      'mainDishes'
                    ].length;
                    dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ] = dishAllocationsWeekResponse[0]['servings']['0'][
                      'mainDishes'
                    ].concat(
                      dishAllocationsWeekResponse[0]['servings']['0'][
                        'sideDishes'
                      ]
                    );

                    console.debug(
                      'dishAllocationsWeekResponse[0][servings][0]',
                      JSON.stringify(
                        dishAllocationsWeekResponse[0]['servings'][0]
                      )
                    );
                  }

                  //-------------------------------------------------------------
                  dishAllocationsWeekResponse[0][
                    'servings'
                  ][0].customerNr = dishAllocationsWeekResponse[0][
                    'customerNr'
                  ];
                  /*dishAllocationsWeekResponse[0][
                    'servings'
                  ][0].orderNr = dishAllocationsWeekResponse[0]['orderNr'];

                  dishAllocationsWeekResponse[0][
                    'servings'
                  ][0].menuNr = dishAllocationsWeekResponse[0]['menuNr'];*/



                  //-------------------------------------------------------------
                  this.dishAllocationsOrderDays[
                    'Sunday'
                  ] = dishAllocationsWeekResponse[0]['servings'][0];
                  this.sortResponseDay(this.dishAllocationsOrderDays['Sunday']);
                  //---------Concat DietType and NameProductName
                  this.concatDietTypeNameProductNameServDay(this.dishAllocationsOrderDays['Sunday']);

                  let dishAllocationsOrderDaysEnergitat = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDays['Sunday'])
                  );
                  this.dishEnergitatAllDayFilteredDietTypeGroups[
                    'Sunday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
                        dishAllocationsOrderDaysEnergitat,
                        'Energitæt'
                      )
                    )
                  );
                  if (
                    this.dishEnergitatAllDayFilteredDietTypeGroups['Sunday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'] &&
                    this.dishEnergitatAllDayFilteredDietTypeGroups['Sunday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'].length == 0
                  ) {
                    this.isEnergitatEmptySunday = true;
                    console.debug(
                      '[ngOninit]  this.isEnergitatEmptySunday',
                      this.isEnergitatEmptyTuesday
                    );
                  } else {
                    this.isEnergitatEmptySunday = false;
                  }
                  let dishAllocationsOrderDaysBlod = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDays['Sunday'])
                  );
                  this.dishBlodAllDayFilteredDietTypeGroups[
                    'Sunday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
                        dishAllocationsOrderDaysBlod,
                        'Blød'
                      )
                    )
                  );
                  if (
                    this.dishBlodAllDayFilteredDietTypeGroups['Sunday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'] &&
                    this.dishBlodAllDayFilteredDietTypeGroups['Sunday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'].length == 0
                  ) {
                    this.isBlodEmptySunday = true;
                    console.debug(
                      '[ngOninit]  this.isEnergitatEmptySunday',
                      this.isEnergitatEmptyTuesday
                    );
                  } else {
                    this.isBlodEmptySunday = false;
                  }
                  let indexStartSideDishForCurDay = this.sideDishesStartDays['Sunday'];
                  this.dishAllDayFilteredDietTypeGroups['Sunday'] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(
                        this.dishAllocationsOrderDays['Sunday'],
                        indexStartSideDishForCurDay
                      )
                    )
                  );
                  console.debug(
                    'this.dishAllocationsOrderDaysSunday',
                    JSON.stringify(this.dishAllocationsOrderDays)
                  );
                } else {
                  this.dishAllocationsOrderDays[
                    'Sunday'
                  ] = dishAllocationsWeekResponse[0]['servings'][0];
                }
                //-----------------------------------------Order----------------------------------------------------------------------------------
                this.simpleOderModelArrayId =
                  'simpleOderModelArray' +
                  this.localStorageService.get('currentChildRoute') +
                  this.shiftWeekNumber +
                  this.departmentId;
                this.simpleOderModelArray = this.localStorageService.get(
                  this.simpleOderModelArrayId
                );
                if (!this.simpleOderModelArray) {
                  this.simpleOderModelArray = new Array();
                  this.sumOder = 0;
                }

                this.updateOrdersInLocalStorageFromResponse(
                  'Sunday',
                  JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
                );

                if (_.isEmpty(this.dishAllocationsOrderDays['Sunday'])) {
                  console.debug(
                    'this.dishAllocationsOrderDays[\'Sunday\'].length==0'
                  );
                  this.isEmptySunday = true;
                }
              } else {
                this.isEmptySunday = true;
              }

              if (
                (dishAllocationsWeekResponse[0] &&
                  dishAllocationsWeekResponse[0]['servings'].length != 0 &&
                  dishAllocationsWeekResponse[0]['servings']['1'] !==
                    undefined &&
                  dishAllocationsWeekResponse[0]['servings']['1'][
                    'servingDate'
                  ] ==
                    this.dateService.getISODateYYYMMDDforDay(
                      'Monday',
                      this.shiftWeek + 1
                    )) ||
                (dishAllocationsWeekResponse[0] !== undefined &&
                  dishAllocationsWeekResponse[0]['servings'].length != 0 &&
                  dishAllocationsWeekResponse[0]['servings']['0'][
                    'servingDate'
                  ] ==
                    this.dateService.getISODateYYYMMDDforDay(
                      'Monday',
                      this.shiftWeek + 1
                    ))
              ) {
                let servingDayIndex = 1;
                console.debug(
                  'MondayISO',
                  this.dateService.getISODateYYYMMDDforDay(
                    'Monday',
                    this.shiftWeek
                  )
                );
                if (
                  dishAllocationsWeekResponse[0]['servings']['0'] &&
                  dishAllocationsWeekResponse[0]['servings']['0'][
                    'servingDate'
                  ] ==
                    this.dateService.getISODateYYYMMDDforDay(
                      'Monday',
                      this.shiftWeek + 1
                    )
                ) {
                  servingDayIndex = 0;
                }

                if (this.orderFromViewWeekPlan) {
                  if (
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'] &&
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'].length == 0
                  ) {
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'] = dishAllocationsWeekResponse[0][
                      'servings'
                    ][servingDayIndex]['sideDishes'];
                    this.sideDishesStartDays['Monday'] = 0;
                  } else if (
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'].length != 0 &&
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['sideDishes'].length != 0
                  ) {
                    this.sideDishesStartDays[
                      'Monday'
                    ] = dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'].length;
                    dishAllocationsWeekResponse[0]['servings'][
                      servingDayIndex
                    ]['mainDishes'] = dishAllocationsWeekResponse[0][
                      'servings'
                    ][servingDayIndex]['mainDishes'].concat(
                      dishAllocationsWeekResponse[0]['servings'][
                        servingDayIndex
                      ]['sideDishes']
                    );

                    console.debug(
                      'dishAllocationsWeekResponse[0][servings][servingDayIndex]',
                      JSON.stringify(
                        dishAllocationsWeekResponse[0]['servings'][
                          servingDayIndex
                        ]
                      )
                    );
                  }

                  //-------------------------------------------------------------
                  dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ].customerNr = dishAllocationsWeekResponse[0][
                    'customerNr'
                  ];
                  /*dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ].orderNr = dishAllocationsWeekResponse[0]['orderNr'];

                  dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ].menuNr = dishAllocationsWeekResponse[0]['menuNr'];*/


                  //-------------------------------------------------------------
                  this.dishAllocationsOrderDays[
                    'Monday'
                  ] = dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ];
                  this.dishAllocationsOrderDays['Monday'][
                    'mainDishes'
                  ] = this.productNrFilter(
                    this.dishAllocationsOrderDays['Monday'],
                    this.productNrForDaysimpleOderModelArrayFilter(
                      this.simpleOderModelArray,
                      'Monday'
                    )
                  );
                  this.sortResponseDay(this.dishAllocationsOrderDays['Monday']);
                  //---------Concat DietType and NameProductName
                  this.concatDietTypeNameProductNameServDay(this.dishAllocationsOrderDays['Monday']);

                  let dishAllocationsOrderDaysEnergitat = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDays['Monday'])
                  );
                  this.dishEnergitatAllDayFilteredDietTypeGroups[
                    'Monday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
                        dishAllocationsOrderDaysEnergitat,
                        'Energitæt'
                      )
                    )
                  );
                  if (
                    this.dishEnergitatAllDayFilteredDietTypeGroups['Monday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'] &&
                    this.dishEnergitatAllDayFilteredDietTypeGroups['Monday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'].length == 0
                  ) {
                    this.isEnergitatEmptyMonday = true;
                    console.debug(
                      '[ngOninit]  this.isEnergitatEmptyMonday',
                      this.isEnergitatEmptyTuesday
                    );
                  } else {
                    this.isEnergitatEmptyMonday = false;
                  }
                  let dishAllocationsOrderDaysBlod = JSON.parse(
                    JSON.stringify(this.dishAllocationsOrderDays['Monday'])
                  );
                  this.dishBlodAllDayFilteredDietTypeGroups[
                    'Monday'
                  ] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
                        dishAllocationsOrderDaysBlod,
                        'Blød'
                      )
                    )
                  );
                  if (
                    this.dishBlodAllDayFilteredDietTypeGroups['Monday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'] &&
                    this.dishBlodAllDayFilteredDietTypeGroups['Monday'][
                      'mainDishes'
                    ][0]['dietTypeGroups'].length == 0
                  ) {
                    this.isBlodEmptyMonday = true;
                    console.debug(
                      '[ngOninit]  this.isEnergitatEmptyMonday',
                      this.isEnergitatEmptyTuesday
                    );
                  } else {
                    this.isBlodEmptyMonday = false;
                  }
                  let indexStartSideDishForCurDay = this.sideDishesStartDays['Monday'];
                  this.dishAllDayFilteredDietTypeGroups['Monday'] = JSON.parse(
                    JSON.stringify(
                      this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(
                        this.dishAllocationsOrderDays['Monday'],
                        indexStartSideDishForCurDay
                      )
                    )
                  );
                  console.debug(
                    'this.dishAllocationsOrderDaysMonday',
                    JSON.stringify(this.dishAllocationsOrderDays)
                  );
                } else {
                  this.dishAllocationsOrderDays[
                    'Monday'
                  ] = dishAllocationsWeekResponse[0]['servings'][
                    servingDayIndex
                  ];
                }
                //-----------------------------------------Order----------------------------------------------------------------------------------
                this.simpleOderModelArrayId =
                  'simpleOderModelArray' +
                  this.localStorageService.get('currentChildRoute') +
                  this.shiftWeekNumber +
                  this.departmentId;
                this.simpleOderModelArray = this.localStorageService.get(
                  this.simpleOderModelArrayId
                );
                if (!this.simpleOderModelArray) {
                  this.simpleOderModelArray = new Array();
                  this.sumOder = 0;
                }

                this.updateOrdersInLocalStorageFromResponse(
                  'Monday',
                  JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
                );

                if (_.isEmpty(this.dishAllocationsOrderDays['Monday'])) {
                  console.debug(
                    'this.dishAllocationsOrderDays[\'Monday\'].length==0'
                  );
                  this.isEmptyMonday = true;
                }
              } else {
                this.isEmptyMonday = true;
              }

              break;
            }
          }
          default: {
            if (dishAllocationsWeekResponse[0]) {
              //this.dishAllocationsOrderDays["Tuesday"]=(this.categoryFilter(0,dishAllocationsWeekResponse, this.categoryForFilter));
              this.sideDishesStartDays[
                'Tuesday'
              ] = dishAllocationsWeekResponse[0]['servings'][0]['mainDishes'].length;
              // concat mainDishes and sideDishes
              dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'] = dishAllocationsWeekResponse[0]['servings']['0'][
                'mainDishes'
              ].concat(
                dishAllocationsWeekResponse[0]['servings']['0'][
                  'sideDishes'
                ]
              );

              console.debug(
                'dishAllocationsWeekResponse[0][servings][0]',
                JSON.stringify(
                  dishAllocationsWeekResponse[0]['servings'][0]
                )
              );
              //-------------------------------------------------------------
              dishAllocationsWeekResponse[0][
                'servings'
              ][0].customerNr = dishAllocationsWeekResponse[0][
                'customerNr'
              ];
              /*dishAllocationsWeekResponse[0][
                'servings'
              ][0].orderNr = dishAllocationsWeekResponse[0]['orderNr'];

              dishAllocationsWeekResponse[0][
                'servings'
              ][0].menuNr = dishAllocationsWeekResponse[0]['menuNr'];*/

              //-------------------------------------------------------------
              this.dishAllocationsOrderDays['Tuesday'] = dishAllocationsWeekResponse[0]['servings'][0];

              this.dishAllocationsOrderDays['Tuesday']['mainDishes'] = this.productNrFilter(
                this.dishAllocationsOrderDays['Tuesday'],
                this.productNrForDaysimpleOderModelArrayFilter(
                  this.simpleOderModelArray,
                  'Tuesday'
                )
              );
              this.sortResponseDay(this.dishAllocationsOrderDays['Tuesday']);
              //---------Concat DietType and NameProductName
              this.concatDietTypeNameProductNameServDay(this.dishAllocationsOrderDays['Tuesday']);

              console.debug(
                'this.dishAllocationsOrderDaysTuesday',
                JSON.stringify(this.dishAllocationsOrderDays)
              );

              //-----------------------------------------Order----------------------------------------------------------------------------------
              this.simpleOderModelArrayId =
                'simpleOderModelArray' +
                this.localStorageService.get('currentChildRoute') +
                this.shiftWeekNumber +
                this.departmentId;
              this.simpleOderModelArray = this.localStorageService.get(
                this.simpleOderModelArrayId
              );
              if (!this.simpleOderModelArray) {
                this.simpleOderModelArray = new Array();
                this.sumOder = 0;
              }

              this.updateOrdersInLocalStorageFromResponse(
                'Tuesday',
                JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
              );

              if (_.isEmpty(this.dishAllocationsOrderDays['Tuesday'])) {
                console.debug(
                  'this.dishAllocationsOrderDays[\'Tuesday\'].length==0'
                );
                this.isEmptyTuesday = true;
              }
            }
            if (dishAllocationsWeekResponse[0]) {
              this.sideDishesStartDays[
                'Wednesday'
              ] = dishAllocationsWeekResponse[0]['servings'][0]['mainDishes'].length;

              dishAllocationsWeekResponse[0]['servings']['1']['mainDishes'] = dishAllocationsWeekResponse[0]['servings']['1'][
                'mainDishes'
              ].concat(
                dishAllocationsWeekResponse[0]['servings']['1'][
                  'sideDishes'
                ]
              );

              console.debug(
                'dishAllocationsWeekResponse[0][servings][1]',
                JSON.stringify(
                  dishAllocationsWeekResponse[0]['servings'][1]
                )
              );
              //-------------------------------------------------------------
              dishAllocationsWeekResponse[0][
                'servings'
              ][1].customerNr = dishAllocationsWeekResponse[0][
                'customerNr'
              ];
              /*dishAllocationsWeekResponse[0][
                'servings'
              ][1].orderNr = dishAllocationsWeekResponse[0]['orderNr'];

              dishAllocationsWeekResponse[0][
                'servings'
              ][1].menuNr = dishAllocationsWeekResponse[0]['menuNr'];*/

              //-------------------------------------------------------------
              this.dishAllocationsOrderDays[
                'Wednesday'
              ] = dishAllocationsWeekResponse[0]['servings'][1];
              this.dishAllocationsOrderDays['Wednesday'][
                'mainDishes'
              ] = this.productNrFilter(
                this.dishAllocationsOrderDays['Wednesday'],
                this.productNrForDaysimpleOderModelArrayFilter(
                  this.simpleOderModelArray,
                  'Wednesday'
                )
              );
              this.sortResponseDay(this.dishAllocationsOrderDays['Wednesday']);

              //---------Concat DietType and NameProductName
              this.concatDietTypeNameProductNameServDay(this.dishAllocationsOrderDays['Wednesday']);

              console.debug(
                'this.dishAllocationsOrderDaysWednesday',
                JSON.stringify(this.dishAllocationsOrderDays)
              );

              //-----------------------------------------Order----------------------------------------------------------------------------------
              this.simpleOderModelArrayId =
                'simpleOderModelArray' +
                this.localStorageService.get('currentChildRoute') +
                this.shiftWeekNumber +
                this.departmentId;
              this.simpleOderModelArray = this.localStorageService.get(
                this.simpleOderModelArrayId
              );
              if (!this.simpleOderModelArray) {
                this.simpleOderModelArray = new Array();
                this.sumOder = 0;
              }

              this.updateOrdersInLocalStorageFromResponse(
                'Wednesday',
                JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
              );

              if (_.isEmpty(this.dishAllocationsOrderDays['Wednesday'])) {
                console.debug(
                  'this.dishAllocationsOrderDays[\'Wednesday\'].length==0'
                );
                this.isEmptyWednesday = true;
              }
            }
            break;
          }
    }
    return dishAllocationsWeekResponse;
  }

  initGuestChefModel(dishAllocationsWeekResponse: any): GuestChefModel {
    return this.guestChefService.initGuestChefModelServings(dishAllocationsWeekResponse);
  }
  setGuestChefModelDay(guestChefModel: GuestChefModel, day: string, value: boolean): void {
    this.guestChefService.setGuestChefModelDay(guestChefModel, day, value);
  }
  getGuestChefModelDay(guestChefModel: GuestChefModel, day: string): boolean {
    return this.guestChefService.getGuestChefModelDay(guestChefModel, day);
  }

  getResponseWithMergedLines(dishAllocationsWeekResponse: any) {
    if (dishAllocationsWeekResponse && dishAllocationsWeekResponse.servings) {
      for (let servDay in dishAllocationsWeekResponse.servings) {
        if (dishAllocationsWeekResponse.servings[servDay] && dishAllocationsWeekResponse.servings[servDay].mainDishes) {
          for (let mainDishesItem in dishAllocationsWeekResponse.servings[servDay].mainDishes) {
            if (dishAllocationsWeekResponse.servings[servDay].mainDishes[mainDishesItem]) {
              for (let dietTypeGroupsItem in dishAllocationsWeekResponse.servings[servDay].mainDishes[mainDishesItem]['dietTypeGroups']){
                if (dishAllocationsWeekResponse.servings[servDay].mainDishes[mainDishesItem]['dietTypeGroups'][dietTypeGroupsItem]
                  && dishAllocationsWeekResponse.servings[servDay].mainDishes[mainDishesItem]['dietTypeGroups'][dietTypeGroupsItem]['dietTypes']) {

                  dishAllocationsWeekResponse.servings[servDay].mainDishes[mainDishesItem]['dietTypeGroups'][dietTypeGroupsItem]['dietTypes'] = JSON.parse(JSON.stringify(this.getMergeDietTypes(dishAllocationsWeekResponse.servings[servDay].mainDishes[mainDishesItem]['dietTypeGroups'][dietTypeGroupsItem]['dietTypes'])));

                }
              }
            }
          }
        }
        if (dishAllocationsWeekResponse.servings[servDay] && dishAllocationsWeekResponse.servings[servDay].sideDishes) {
          for (let sideDishesItem in dishAllocationsWeekResponse.servings[servDay].sideDishes) {
            if (dishAllocationsWeekResponse.servings[servDay].sideDishes[sideDishesItem]) {
              for (let dietTypeGroupsItem in dishAllocationsWeekResponse.servings[servDay].sideDishes[sideDishesItem]['dietTypeGroups']){
                if (dishAllocationsWeekResponse.servings[servDay].sideDishes[sideDishesItem]['dietTypeGroups'][dietTypeGroupsItem]
                  && dishAllocationsWeekResponse.servings[servDay].sideDishes[sideDishesItem]['dietTypeGroups'][dietTypeGroupsItem]['dietTypes']) {

                  dishAllocationsWeekResponse.servings[servDay].sideDishes[sideDishesItem]['dietTypeGroups'][dietTypeGroupsItem]['dietTypes'] = JSON.parse(JSON.stringify(this.getMergeDietTypes(dishAllocationsWeekResponse.servings[servDay].sideDishes[sideDishesItem]['dietTypeGroups'][dietTypeGroupsItem]['dietTypes'])));

                }
              }
            }
          }
        }
      }

    }
      return dishAllocationsWeekResponse;

  }

  getMergeDietTypes(dietTypes: any[]) {
    let dietTypesUniq: any[] = [];
    for (let dietTypesItem in dietTypes){
      if (dietTypesUniq.length == 0) {
        dietTypesUniq.push(dietTypes[dietTypesItem]);
      } else {
        let index = dietTypesUniq.findIndex(
          (item) => {
            if ((item.menuLine.productNr == dietTypes[dietTypesItem].menuLine.productNr)
              && (item.menuLine.line == dietTypes[dietTypesItem].menuLine.line)
              && (item.menuLine.dietTypeNr == dietTypes[dietTypesItem].menuLine.dietTypeNr)) {
              return true;
            } else {
              return false;
            }
          }
        )
        if (index != -1) {
          dietTypesUniq[index].menuLine.quantity += dietTypes[dietTypesItem].menuLine.quantity;
        } else {
          dietTypesUniq.push(dietTypes[dietTypesItem]);
        }

      }
    }

    return dietTypesUniq;
  }

  filterResponseByUndefined(responseDay: any) {
    let responseDayFiltered = [];

    if (responseDay && responseDay[0] && responseDay[0]['servings']) {
      //console.debug("IfUnd");
      responseDayFiltered = responseDay[0]['servings'].filter((el) => {
        if (
          ((el['mainDishes'] !== undefined && el['sideDishes'] != undefined) ||
            (el['mainDishes'] == undefined && el['sideDishes'] != undefined) ||
            (el['mainDishes'] !== undefined &&
              el['sideDishes'] == undefined)) &&
          ((el['mainDishes'].length != 0 && el['sideDishes'].length != 0) ||
            (el['mainDishes'].length != 0 && el['sideDishes'].length == 0) ||
            (el['mainDishes'].length == 0 && el['sideDishes'].length != 0))
        ) {
          return true;
        }
      });
    }

    return responseDayFiltered;
  }
  concatDietTypeNameProductName(dishAllocationsOrderDays: DishAllocationsOrderDays
  ) {
    if (dishAllocationsOrderDays) {
      for (let servingDay in dishAllocationsOrderDays) {
        this.concatDietTypeNameProductNameDietTypeGroups(dishAllocationsOrderDays[servingDay], 'mainDishes');
        this.concatDietTypeNameProductNameDietTypeGroups(dishAllocationsOrderDays[servingDay], 'sideDishes');

        this.concatDietTypeNameProductNameMenuLine(dishAllocationsOrderDays[servingDay], 'mainDishes');
        this.concatDietTypeNameProductNameMenuLine(dishAllocationsOrderDays[servingDay], 'sideDishes');
      }
    }
  }

  concatDietTypeNameProductNameServDay(servingDay: any) {
    if (servingDay) {
      this.concatDietTypeNameProductNameDietTypeGroups(servingDay, 'mainDishes');
      this.concatDietTypeNameProductNameDietTypeGroups(servingDay, 'sideDishes');

      this.concatDietTypeNameProductNameMenuLine(servingDay, 'mainDishes');
      this.concatDietTypeNameProductNameMenuLine(servingDay, 'sideDishes');

    }
  }

  concatDietTypeNameProductNameMenuLine(servingDay: any, dishesType: string) {
    if (servingDay && servingDay[dishesType]) {
          for (let mainDishItem of servingDay[dishesType]) {
                if (mainDishItem && mainDishItem.menuLine) {
                  let dietTypeName = mainDishItem.menuLine.dietTypeName + ': '
                  mainDishItem.menuLine.productName = dietTypeName.concat(mainDishItem.menuLine.productName);
                }
          }
        }
  }
  concatDietTypeNameProductNameDietTypeGroups(servingDay: any, dishesType: string) {
    if (servingDay && servingDay[dishesType]) {
        for (let mainDishItem of servingDay[dishesType]) {
          if (mainDishItem && mainDishItem.dietTypeGroups) {
              for (let dietTypeGroupsItem of mainDishItem.dietTypeGroups) {
                if (dietTypeGroupsItem && dietTypeGroupsItem.dietTypes) {
                  for (let dietTypeItem of dietTypeGroupsItem.dietTypes){
                    if (dietTypeItem && dietTypeItem.menuLine) {
                          let dietTypeName = dietTypeItem.menuLine.dietTypeName + ': '
                          dietTypeItem.menuLine.productName = dietTypeName.concat(dietTypeItem.menuLine.productName);
                        }
                    }
                  }
                }
          }
        }
      }


  }
  categoryFilter(day: number, response: any[], category: any) {
    var dishArrForCategoty = Object.create({ category: [], categories: [] });
    var exit_loops = false;
    var lowerCaseCategory = category.toLowerCase();

    for (var n = 0; n < response[day].length; n++) {
      if (
        response[day][n]['category']['name'] == category ||
        response[day][n]['category']['name'] == lowerCaseCategory
      ) {
        //	console.debug("In categoryFilter. Category found")

        dishArrForCategoty.category = response[day][n]['category'];
        dishArrForCategoty.categories = response[day][n]['categories'];
        exit_loops = true;
        break;
      } else {
      }
    }

    return dishArrForCategoty;
  }

  getKodCategoryArrayForMenuLine(menuLineDayForModal: any, productNr: number) {

    let categoryArr = [
      'Fuldkost',
      'Fuldkost m. kogte kartof.',
      'Fuldkost m. rå kartof.',
      'Fuldkost m/mos',
      'Fuldkost % kartofler',
    ];
    let starchRange = [
      [16, 25],
      [166, 175],
      [316, 325],
      [466, 475],
      [616, 625],
    ];
    let mapKodCategoryArray = new Map();
    let meatSet = new Set();
    let isDietTypeNr30 = this.isDietTypeNr30;
    //for(let itemMenuLine in menuLineDayForModal["mainDishes"]){
    for (let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']) {
      for (let cat in categoryArr) {
        if (
          menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
            'dietTypeGroup'
          ]['name'] == categoryArr[cat]
        ) {
          for (let itemDietType in menuLineDayForModal['dietTypeGroups'][
            itemdietTypeGroups
          ]['dietTypes']) {
            for (let cat in categoryArr) {
              if (
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['dietType']['meat'] !== undefined ||
                  isDietTypeNr30 == true) &&
                //&& (menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["dietTypeName"]==categoryArr[cat])

                (!(
                  (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] >= starchRange[0][0] &&
                    menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                      'dietTypes'
                    ][itemDietType]['menuLine']['line'] <= starchRange[0][1]) ||
                  (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] >= starchRange[1][0] &&
                    menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                      'dietTypes'
                    ][itemDietType]['menuLine']['line'] <= starchRange[1][1]) ||
                  (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] >= starchRange[2][0] &&
                    menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                      'dietTypes'
                    ][itemDietType]['menuLine']['line'] <= starchRange[2][1]) ||
                  (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] >= starchRange[3][0] &&
                    menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                      'dietTypes'
                    ][itemDietType]['menuLine']['line'] <= starchRange[3][1]) ||
                  (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] >= starchRange[4][0] &&
                    menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                      'dietTypes'
                    ][itemDietType]['menuLine']['line'] <= starchRange[4][1])
                ) ||
                  isDietTypeNr30 == true) &&
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productNr'] == productNr
              ) {
                meatSet.add(
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['dietType']['meat']
                );
              }
            }
          }

          meatSet.forEach(function (itemMeatSet, key, ownerSet) {
            let starchSet = new Set();
            let tibehorFuldkostArr: TibehorFuldkost[];
            tibehorFuldkostArr = [];
            for (let itemDietType in menuLineDayForModal['dietTypeGroups'][
              itemdietTypeGroups
            ]['dietTypes']) {
              for (let cat in categoryArr) {
                let itemLineSetArr = [];
                if (
                  (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['dietType']['meat'] == itemMeatSet ||
                    isDietTypeNr30 == true) &&
                  //&& (menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["dietTypeName"]==categoryArr[cat])

                  (!(
                    (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                      'dietTypes'
                    ][itemDietType]['menuLine']['line'] >= starchRange[0][0] &&
                      menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                        'dietTypes'
                      ][itemDietType]['menuLine']['line'] <=
                        starchRange[0][1]) ||
                    (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                      'dietTypes'
                    ][itemDietType]['menuLine']['line'] >= starchRange[1][0] &&
                      menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                        'dietTypes'
                      ][itemDietType]['menuLine']['line'] <=
                        starchRange[1][1]) ||
                    (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                      'dietTypes'
                    ][itemDietType]['menuLine']['line'] >= starchRange[2][0] &&
                      menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                        'dietTypes'
                      ][itemDietType]['menuLine']['line'] <=
                        starchRange[2][1]) ||
                    (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                      'dietTypes'
                    ][itemDietType]['menuLine']['line'] >= starchRange[3][0] &&
                      menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                        'dietTypes'
                      ][itemDietType]['menuLine']['line'] <=
                        starchRange[3][1]) ||
                    (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                      'dietTypes'
                    ][itemDietType]['menuLine']['line'] >= starchRange[4][0] &&
                      menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                        'dietTypes'
                      ][itemDietType]['menuLine']['line'] <= starchRange[4][1])
                  ) ||
                    isDietTypeNr30 == true) &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['productNr'] == productNr
                ) {
                  starchSet.add(
                    menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                      'dietTypes'
                    ][itemDietType]['dietType']['starch']
                  );
                }
              }
            }


            starchSet.forEach(function (itemStarchSet, key, ownerSet) {
              tibehorFuldkostArr.push(new TibehorFuldkost(itemStarchSet, 0));
            });
            mapKodCategoryArray.set(itemMeatSet, tibehorFuldkostArr);
          });
        }
      }
    }


    return mapKodCategoryArray;
  }

  getFilteredMapByQuantities(modalKodCategoryMap: any, itemMenuLines: any) {


    modalKodCategoryMap.forEach(function (value, key, ownerMap) {
      value = value.filter((itemTibehor) => {
        if (itemTibehor['tibehorQuantity'] != 0) {
          return true;
        } else {
          return false;
        }
      });


      ownerMap.set(key, value);
      if (ownerMap.get(key).length == 0) {

        ownerMap.delete(key);
      }
    }, this);


    return modalKodCategoryMap;
  }

  getFilteredMapByQuantitiesSoft(
    modalKodCategoryMap: any,
    itemMenuLines: any,
    isSideDish: boolean
  ) {

    let filteredMap = new Map();
    modalKodCategoryMap.forEach(function (value, key, ownerMap) {
      let valueArr = [];
      value = value.forEach((itemTibehor) => {
        /*if(itemTibehor["tibehorQuantity"]!=0){
				 valueArr.push(itemTibehor);
				}*/
        if (itemTibehor['tibehorQuantity'] != 0 || isSideDish) {
          valueArr.push(itemTibehor);
        }
      });
      //if(valueArr.length!=0){
      filteredMap.set(key, valueArr);
      //}
    }, this);


    return filteredMap;
  }

  updateQuontityFromSimpleOderModelArray(
    menuLineDayForModal: any,
    simpleOderModelArray: any,
    day: string
  ) {
    for (let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']) {
      if (
        menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups] &&
        menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups] !== undefined
      ) {
        for (let itemDietType in menuLineDayForModal['dietTypeGroups'][
          itemdietTypeGroups
        ]['dietTypes']) {
          //let itemLineSetArr = [];
          for (let order in simpleOderModelArray) {
            if (
              menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine'] !== undefined &&
              menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine']['productNr'] ==
                simpleOderModelArray[order]['dishId'] &&
              menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine']['line'] ==
                simpleOderModelArray[order]['line'] &&
              menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine']['productName'] ==
                simpleOderModelArray[order]['name'] &&
              day == simpleOderModelArray[order]['Day']
            ) {

              menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine']['quantity'] =
                simpleOderModelArray[order]['sumOder'];
            }
          }
        }
      }

      //	}

      //}
    }

    return menuLineDayForModal;
  }

  getSubLinesSetForMenuLineFuldcostBulk(menuLineDayForModal: any) {

    let categoryArr = [
      'Fuldkost',
      'Fuldkost m. kogte kartof.',
      'Fuldkost m. rå kartof.',
      'Fuldkost m/mos',
      'Fuldkost % kartofler',
    ];
    let category = menuLineDayForModal['menuLine']['line'];
    let starchRange = [
      [122, 129],
      [272, 279],
      [422, 426],
      [572, 579],
      [722, 729],
      [132, 140],
      [282, 290],
      [432, 440],
      [582, 590],
      [732, 740],
      [144, 147],
      [294, 297],
      [444, 447],
      [594, 597],
      [744, 747],
      [141],
      [291],
      [441],
      [591],
      [741],
    ];
    let lineSet = new Set();

    for (let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']) {
      for (let cat in categoryArr) {
        if (
          menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
            'dietTypeGroup'
          ]['name'] == categoryArr[cat]
        ) {
          for (let itemDietType in menuLineDayForModal['dietTypeGroups'][
            itemdietTypeGroups
          ]['dietTypes']) {
            let itemLineSetArr = [];

            if (
              menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine'] !== undefined &&
              ((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine']['line'] >= starchRange[0][0] &&
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] <= starchRange[0][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[1][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[1][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[2][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[2][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[3][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[3][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[4][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[4][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[5][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[5][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[6][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[6][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[7][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[7][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[8][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[8][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[9][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[9][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[10][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[10][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[11][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[11][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[12][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[12][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[13][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[13][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[14][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[14][1]) ||
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] == starchRange[15] ||
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] == starchRange[16] ||
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] == starchRange[17] ||
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] == starchRange[18] ||
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] == starchRange[19])
            ) {

              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productName']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeNr']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['quantity']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productNr']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeName']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['portionSize']
              );

              lineSet.add(itemLineSetArr);
            } else {
            }
          }

        }
      }
    }

    return lineSet;
  }

  updateMenuLineByProductNr(
    menuLineDayForModal: any,
    categoryArr: string[],
    subLinesSetRow: any
  ) {
    let menuLine;
    if (subLinesSetRow) {
      for (let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']) {
        for (let cat in categoryArr) {
          if (
            menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
              'dietTypeGroup'
            ]['name'] == categoryArr[cat]
          ) {
            for (let itemDietType in menuLineDayForModal['dietTypeGroups'][
              itemdietTypeGroups
            ]['dietTypes']) {
              let itemLineSetArr = [];

              if (
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine'] !== undefined &&
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] == subLinesSetRow[0] &&
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productName'] ==
                  subLinesSetRow[1] &&
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productNr'] == subLinesSetRow[4]
              ) {

                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['quantity'] = subLinesSetRow[3];
                menuLine =
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine'];
              }
            }
          }
        }
      }
    }


    return menuLine;
  }
  getSubLinesSetForMenuLineFuldcostKod(
    menuLineDayForModal: any,
    zeroQuantityPermit: boolean
  ) {

    let categoryArr = [
      'Fuldkost',
      'Fuldkost m. kogte kartof.',
      'Fuldkost m. rå kartof.',
      'Fuldkost m/mos',
      'Fuldkost % kartofler',
    ];
    let category = menuLineDayForModal['menuLine']['line'];
    let kodRange = [
      [122, 129],
      [272, 279],
      [422, 429],
      [572, 579],
      [722, 729],
    ];

    let lineSet = new Set();

    //for(let itemMenuLine in menuLineDayForModal["mainDishes"]){
    for (let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']) {
      for (let cat in categoryArr) {
        if (
          menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
            'dietTypeGroup'
          ]['name'] == categoryArr[cat]
        ) {
          for (let itemDietType in menuLineDayForModal['dietTypeGroups'][
            itemdietTypeGroups
          ]['dietTypes']) {
            let itemLineSetArr = [];

            if (
              menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine'] !== undefined &&
              (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine']['quantity'] != 0 ||
                zeroQuantityPermit) &&
              ((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine']['line'] >= kodRange[0][0] &&
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] <= kodRange[0][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= kodRange[1][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= kodRange[1][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= kodRange[2][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= kodRange[2][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= kodRange[3][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= kodRange[3][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= kodRange[4][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= kodRange[4][1]))
            ) {
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productName']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeNr']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['quantity']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productNr']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeName']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['portionSize']
              );

              lineSet.add(itemLineSetArr);
            } else {
            }
          }

        }
      }
    }

    return lineSet;
  }

  getSubLinesSetForMenuLineFuldcostTilbehor(
    menuLineDayForModal: any,
    zeroQuantityPermit: boolean
  ) {


    let categoryArr = [
      'Fuldkost',
      'Fuldkost m. kogte kartof.',
      'Fuldkost m. rå kartof.',
      'Fuldkost m/mos',
      'Fuldkost % kartofler',
    ];
    let category = menuLineDayForModal['menuLine']['line'];
    let tilbehorRange = [
      [132, 140],
      [282, 290],
      [432, 440],
      [582, 590],
      [732, 740],
    ];
    let lineSet = new Set();

    for (let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']) {
      for (let cat in categoryArr) {
        if (
          menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
            'dietTypeGroup'
          ]['name'] == categoryArr[cat]
        ) {
          for (let itemDietType in menuLineDayForModal['dietTypeGroups'][
            itemdietTypeGroups
          ]['dietTypes']) {
            let itemLineSetArr = [];

            if (
              menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine'] !== undefined &&
              (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine']['quantity'] != 0 ||
                zeroQuantityPermit) &&
              ((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine']['line'] >= tilbehorRange[0][0] &&
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] <= tilbehorRange[0][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= tilbehorRange[1][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= tilbehorRange[1][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= tilbehorRange[2][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= tilbehorRange[2][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= tilbehorRange[3][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= tilbehorRange[3][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= tilbehorRange[4][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= tilbehorRange[4][1]))
            ) {
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productName']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeNr']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['quantity']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productNr']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeName']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['portionSize']
              );

              lineSet.add(itemLineSetArr);
            } else {
            }
          }

        }
      }
    }

    return lineSet;
  }

  getSubLinesSetForMenuLineFuldcostGront(
    menuLineDayForModal: any,
    zeroQuantityPermit: boolean
  ) {
    let categoryArr = [
      'Fuldkost',
      'Fuldkost m. kogte kartof.',
      'Fuldkost m. rå kartof.',
      'Fuldkost m/mos',
      'Fuldkost % kartofler',
    ];
    let category = menuLineDayForModal['menuLine']['line'];
    let grontRange = [
      [144, 147],
      [294, 297],
      [444, 447],
      [594, 597],
      [744, 747],
    ];
    let lineSet = new Set();

    for (let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']) {
      for (let cat in categoryArr) {
        if (
          menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
            'dietTypeGroup'
          ]['name'] == categoryArr[cat]
        ) {
          for (let itemDietType in menuLineDayForModal['dietTypeGroups'][
            itemdietTypeGroups
          ]['dietTypes']) {
            let itemLineSetArr = [];

            if (
              menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine'] !== undefined &&
              (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine']['quantity'] != 0 ||
                zeroQuantityPermit) &&
              ((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine']['line'] >= grontRange[0][0] &&
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] <= grontRange[0][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= grontRange[1][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= grontRange[1][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= grontRange[2][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= grontRange[2][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= grontRange[3][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= grontRange[3][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= grontRange[4][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= grontRange[4][1]))
            ) {
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productName']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeNr']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['quantity']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productNr']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeName']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['portionSize']
              );

              lineSet.add(itemLineSetArr);
            } else {
            }
          }

        }
      }
    }

    return lineSet;
  }

  getSubLinesSetForMenuLineFuldcostSovs(
    menuLineDayForModal: any,
    zeroQuantityPermit: boolean
  ) {

    let categoryArr = [
      'Fuldkost',
      'Fuldkost m. kogte kartof.',
      'Fuldkost m. rå kartof.',
      'Fuldkost m/mos',
      'Fuldkost % kartofler',
    ];
    let category = menuLineDayForModal['menuLine']['line'];
    let SovsRange = [[141], [291], [441], [591], [741]];
    let lineSet = new Set();

    for (let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']) {
      for (let cat in categoryArr) {
        if (
          menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
            'dietTypeGroup'
          ]['name'] == categoryArr[cat]
        ) {
          for (let itemDietType in menuLineDayForModal['dietTypeGroups'][
            itemdietTypeGroups
          ]['dietTypes']) {
            let itemLineSetArr = [];

            if (
              menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine'] !== undefined &&
              (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine']['quantity'] != 0 ||
                zeroQuantityPermit) &&
              (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine']['line'] == SovsRange[0] ||
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] == SovsRange[1] ||
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] == SovsRange[2] ||
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] == SovsRange[3] ||
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] == SovsRange[4])
            ) {
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productName']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeNr']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['quantity']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productNr']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeName']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['portionSize']
              );

              lineSet.add(itemLineSetArr);
            } else {
            }
          }

        }
      }
    }

    return lineSet;
  }

  getSubLinesSetForMenuLineFuldkostEnkel(menuLineDayForModal: any) {

    let categoryArr = [
      'Fuldkost',
      'Fuldkost m. kogte kartof.',
      'Fuldkost m. rå kartof.',
      'Fuldkost m/mos',
      'Fuldkost % kartofler',
    ];
    let category = menuLineDayForModal['menuLine']['line'];
    let starchRange = [
      [16, 25],
      [166, 175],
      [316, 325],
      [466, 475],
      [616, 625],
    ];
    let lineSet = new Set();

    for (let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']) {
      for (let cat in categoryArr) {
        if (
          menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
            'dietTypeGroup'
          ]['name'] == categoryArr[cat]
        ) {
          for (let itemDietType in menuLineDayForModal['dietTypeGroups'][
            itemdietTypeGroups
          ]['dietTypes']) {
            let itemLineSetArr = [];

            if (
              menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine'] !== undefined &&
              ((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                'dietTypes'
              ][itemDietType]['menuLine']['line'] >= starchRange[0][0] &&
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] <= starchRange[0][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[1][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[1][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[2][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[2][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[3][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[3][1]) ||
                (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] >= starchRange[4][0] &&
                  menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine']['line'] <= starchRange[4][1]) ||
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeName'] == 'Energitæt' ||
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeName'] ==
                  'Energitæt m/mos' ||
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeName'] ==
                  'Energi m/ rå kart.')
            ) {

              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productName']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeNr']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['quantity']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productNr']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeName']
              );
              itemLineSetArr.push(
                menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['portionSize']
              );

              lineSet.add(itemLineSetArr);
            } else {
            }
          }

        }
      }
    }

    return lineSet;
  }
  //---------------------------Get Side Dishes Arr --------------------------------------------------

  //------------------------------------------------------------------------------------------------
  productNrForDaysimpleOderModelArrayFilter(
    simpleOderModelArray: any,
    day: string
  ) {
    let productNrArray = [];
    for (let orderBulk in simpleOderModelArray) {
      if (
        simpleOderModelArray[orderBulk]['Day'] == day &&
        simpleOderModelArray[orderBulk]['sumOder'] != 0
      ) {
        productNrArray.push(simpleOderModelArray[orderBulk]['dishId']);
      }
    }


    return productNrArray;
  }
  productNrFilter(responseDay: any[], productNrArray: number[]) {

    if (this.isProductNrFilterOn) {
      let menuLineArrForCategory = [];

      for (let itemMenuLine in responseDay['mainDishes']) {
        if (responseDay['mainDishes'][itemMenuLine]['menuLine'] !== undefined) {
          for (let productNr in productNrArray) {
            if (
              responseDay['mainDishes'][itemMenuLine]['menuLine'][
                'productNr'
              ] == productNrArray[productNr]
            ) {

              menuLineArrForCategory.push(
                responseDay['mainDishes'][itemMenuLine]
              );
            } else {
            }
          }
        }
      }


      return menuLineArrForCategory;
    } else {

      return responseDay['mainDishes'];
    }
  }

  sortResponseDay(responseDay: any[]) {

    for (let itemMenuLine in responseDay['mainDishes']) {
      responseDay['mainDishes'][itemMenuLine] = this.sortResponseDayMainDish(
        responseDay['mainDishes'][itemMenuLine]
      );
    }
  }

  sortResponseDayMainDish(responseDayMainDish: any[]) {

    let responseDayMainDishAndet = JSON.parse(
      JSON.stringify(responseDayMainDish)
    );
    let menuLineArrForCategory = new Array(3);
    menuLineArrForCategory.fill(undefined);


    if (responseDayMainDishAndet['dietTypeGroups'] !== undefined) {
      responseDayMainDishAndet['dietTypeGroups'] = responseDayMainDishAndet[
        'dietTypeGroups'
      ].filter((el) => {
        if (
          el['dietTypeGroup']['name'] != 'Fuldkost' &&
          el['dietTypeGroup']['name'] != 'Energitæt' &&
          el['dietTypeGroup']['name'] != 'Blød'
        ) {
          return true;
        } else {
          switch (el['dietTypeGroup']['name']) {
            case 'Fuldkost':
              menuLineArrForCategory[0] = el;
              break;
            case 'Energitæt':
              menuLineArrForCategory[1] = el;
              break;
            case 'Blød':
              menuLineArrForCategory[2] = el;
              break;
            default: {
            }
          }

          return false;
        }
      });
    }
    menuLineArrForCategory = _.compact(menuLineArrForCategory);
    responseDayMainDish['dietTypeGroups'] = menuLineArrForCategory;

    responseDayMainDish['dietTypeGroups'] = responseDayMainDish[
      'dietTypeGroups'
    ].concat(responseDayMainDishAndet['dietTypeGroups']);

    return responseDayMainDish;
  }

  //---------------------------Get CategoryName---------------------------------
  getCategoryName() {
    let categoryName = 'Boller i Karry';
    return categoryName;
  }
  //-------------------------------------------------------------------------------------------------
  sub(cat: any, element: number, nameDay: string) {
    let catNumber;
    if (cat == 'Froko') {
      catNumber = 1;
    } else if (cat == 'Extra') {
      catNumber = 2;
    } else if ((cat = 'Editweekplan')) {
      catNumber = 3;
    }
    var orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      catNumber,
      element
    );
    for (var oder in this.simpleOderModelArray) {
      if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
        if (this.simpleOderModelArray[oder].orderNumber != 0) {
          this.simpleOderModelArray[oder].orderNumber -= 1;
          if (this.simpleOderModelArray[oder].orderNumber < 0) {
            this.simpleOderModelArray[oder].orderNumber = 0;
          }
          this.simpleOderModelArray[oder].sumOder -= 1;
          if (this.simpleOderModelArray[oder].sumOder < 0) {
            this.simpleOderModelArray[oder].sumOder = 0;
          }
        }
        this.simpleOderModelArray[oder].changed = true;
      }
    }

    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );

    this.updateCartFromLocalStorage();

    this.sumOder = this.getSumOrder(this.simpleOderModelArray);
  }

  add(cat: any, element: number, nameDay: string) {
    let catNumber;
    if (cat == 'Froko') {
      catNumber = 1;
    } else if (cat == 'Extra') {
      catNumber = 2;
    } else if ((cat = 'Editweekplan')) {
      catNumber = 3;
    }
    var orderFound = false;

    var orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      catNumber,
      element
    );

    //---------------------------- Get CategoryName-----------------

    let categoryName = this.getCategoryName();

    //--------------------------------------------------------------

    if (this.simpleOderModelArray.length == 0) {
      this.simpleOderModelArray.push(
        new SimpleOderModel(
          orderDishIdTCur,
          1,
          1,
          nameDay,
          this.dishAllocationsOrderDays[nameDay]['mainDishes'][element][
            'menuLine'
          ]['productNr'],
          this.dishAllocationsOrderDays[nameDay]['mainDishes'][element][
            'menuLine'
          ]['productName'],
          this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['dish'][
            'dishPhotos'
          ][0]['imageUrl'],
          categoryName,
          'no subcategoreis',
          {
            dietTypeNr: this.dishAllocationsOrderDays[nameDay]['mainDishes'][
              element
            ]['menuLine']['dietTypeNr'],
            dietTypeName: this.dishAllocationsOrderDays[nameDay]['mainDishes'][
              element
            ]['menuLine']['dietTypeName'],
            portionSize: this.dishAllocationsOrderDays[nameDay]['mainDishes'][
              element
            ]['menuLine']['portionSize'],
            line: this.dishAllocationsOrderDays[nameDay]['mainDishes'][element][
              'menuLine'
            ]['line'],
            orderNr: this.dishAllocationsOrderDays[nameDay].orderNr,
            customerNr: this.dishAllocationsOrderDays[nameDay].customerNr,
            menuNr: this.dishAllocationsOrderDays[nameDay].menuNr,
            changed: true,
            orderNotPermit: this.orderNotPermit,
            enkeltPortion: this.dishAllocationsOrderDays[nameDay]['mainDishes'][
              element
            ]['menuLine']['enkeltPortion'],
            sended: false,
          }
        )
      );
    } else {
      for (var oder in this.simpleOderModelArray) {
        if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
          orderFound = true;
          this.simpleOderModelArray[oder].orderNumber += 1;
          this.simpleOderModelArray[oder].sumOder += 1;
          this.simpleOderModelArray[oder].changed = true;
        }
      }

      if (!orderFound) {
        this.simpleOderModelArray.push(
          new SimpleOderModel(
            orderDishIdTCur,
            1,
            1,
            nameDay,
            this.dishAllocationsOrderDays[nameDay]['mainDishes'][element][
              'menuLine'
            ]['productNr'],
            this.dishAllocationsOrderDays[nameDay]['mainDishes'][element][
              'menuLine'
            ]['productName'],
            this.dishAllocationsOrderDays[nameDay]['mainDishes'][element][
              'dish'
            ]['dishPhotos'][0]['imageUrl'],
            categoryName,
            'no subcategoreis',
            {
              dietTypeNr: this.dishAllocationsOrderDays[nameDay]['mainDishes'][
                element
              ]['menuLine']['dietTypeNr'],
              dietTypeName: this.dishAllocationsOrderDays[nameDay][
                'mainDishes'
              ][element]['menuLine']['dietTypeName'],
              portionSize: this.dishAllocationsOrderDays[nameDay]['mainDishes'][
                element
              ]['menuLine']['portionSize'],
              line: this.dishAllocationsOrderDays[nameDay]['mainDishes'][
                element
              ]['menuLine']['line'],
              orderNr: this.dishAllocationsOrderDays[nameDay].orderNr,
              customerNr: this.dishAllocationsOrderDays[nameDay].customerNr,
              menuNr: this.dishAllocationsOrderDays[nameDay].menuNr,
              changed: true,
              orderNotPermit: this.orderNotPermit,
              enkeltPortion: this.dishAllocationsOrderDays[nameDay][
                'mainDishes'
              ][element]['menuLine']['enkeltPortion'],
              sended: false,
            }
          )
        );
      }
    }

    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );

    this.updateCartFromLocalStorage();

    this.sumOder = this.getSumOrder(this.simpleOderModelArray);
  }

  updateSimpleOderModelArray(
    cat: any,
    menuLine: any,
    nameDay: string,
    changed: boolean
  ) {


    let catNumber;
    if (cat == 'Froko') {
      catNumber = 1;
    } else if (cat == 'Extra') {
      catNumber = 2;
    } else if ((cat = 'Editweekplan')) {
      catNumber = 3;
    }
    var orderFound = false;

    let element = Number(menuLine['productNr']) + Number(menuLine['line']);
    var orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      catNumber,
      element
    );

    //---------------------------- Get CategoryName-----------------

    let categoryName = this.getCategoryName();

    //--------------------------------------------------------------

    if (this.simpleOderModelArray.length == 0 && menuLine['quantity'] != 0) {

      this.simpleOderModelArray.push(
        new SimpleOderModel(
          orderDishIdTCur,
          Number(menuLine['quantity']),
          //3,
          Number(menuLine['quantity']),
          //3,
          nameDay,
          menuLine['productNr'],
          menuLine['productName'],
          '',
          categoryName,
          'no subcategoreis',
          {
            dietTypeNr: menuLine['dietTypeNr'],
            dietTypeName: menuLine['dietTypeName'],
            portionSize: menuLine['portionSize'],
            line: menuLine['line'],
            orderNr: this.dishAllocationsOrderDays[nameDay].orderNr,
            customerNr: this.dishAllocationsOrderDays[nameDay].customerNr,
            menuNr: this.dishAllocationsOrderDays[nameDay].menuNr,
            changed: changed,
            orderNotPermit: this.orderNotPermit,
            enkeltPortion: menuLine['enkeltPortion'],
            sended: false,
          }
        )
      );

    } else {
      for (var oder in this.simpleOderModelArray) {
        if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {

          orderFound = true;
          this.simpleOderModelArray[oder].orderNumber = Number(
            menuLine['quantity']
          );
          this.simpleOderModelArray[oder].sumOder = Number(
            menuLine['quantity']
          );

          this.simpleOderModelArray[oder].changed = changed;
        }
      }

      if (!orderFound && menuLine['quantity'] != 0) {

        this.simpleOderModelArray.push(
          new SimpleOderModel(
            orderDishIdTCur,
            Number(menuLine['quantity']),
            Number(menuLine['quantity']),
            nameDay,
            menuLine['productNr'],
            menuLine['productName'],
            '',
            categoryName,
            'no subcategoreis',
            {
              dietTypeNr: menuLine['dietTypeNr'],
              dietTypeName: menuLine['dietTypeName'],
              portionSize: menuLine['portionSize'],
              line: menuLine['line'],
              orderNr: this.dishAllocationsOrderDays[nameDay].orderNr,
              customerNr: this.dishAllocationsOrderDays[nameDay].customerNr,
              menuNr: this.dishAllocationsOrderDays[nameDay].menuNr,
              changed: changed,
              orderNotPermit: this.orderNotPermit,
              enkeltPortion: menuLine['enkeltPortion'],
              sended: false,
            }
          )
        );
      }
    }

    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );


  }

  checkQuantitiesChanged(simpleOderModelArray: any) {
    let isQuantitiesChanged: boolean = false;
    let toggledDay = this.localStorageService.get('toggledDayBulkOrder');
    switch (toggledDay) {
      case 'Tuesday':
        for (var oder in simpleOderModelArray) {
          if (
            simpleOderModelArray[oder]['Day'] == 'Tuesday' ||
            simpleOderModelArray[oder]['Day'] == 'Wednesday'
          )
            if (simpleOderModelArray[oder]['changed'] == true) {

              isQuantitiesChanged = true;
              break;
            }
        }
        break;
      case 'Thursday':
        for (var oder in simpleOderModelArray) {
          if (
            simpleOderModelArray[oder]['Day'] == 'Thursday' ||
            simpleOderModelArray[oder]['Day'] == 'Friday' ||
            simpleOderModelArray[oder]['Day'] == 'Saturday'
          )
            if (simpleOderModelArray[oder]['changed'] == true) {

              isQuantitiesChanged = true;
              break;
            }
        }

        break;
      case 'Saturday':
        for (var oder in simpleOderModelArray) {
          if (
            simpleOderModelArray[oder]['Day'] == 'Sunday' ||
            simpleOderModelArray[oder]['Day'] == 'Monday'
          )
            if (simpleOderModelArray[oder]['changed'] == true) {

              isQuantitiesChanged = true;
              break;
            }
        }

        break;
      default: {
      }
    }


    return isQuantitiesChanged;
  }

  clearChangedOrder(simpleOderModelArray: any, toggledDay: string) {
    switch (toggledDay) {
      case 'Tuesday':
        for (var oder in simpleOderModelArray) {
          if (
            simpleOderModelArray[oder]['Day'] == 'Tuesday' ||
            simpleOderModelArray[oder]['Day'] == 'Wednesday'
          )
            if (simpleOderModelArray[oder]['changed'] == true) {
              simpleOderModelArray[oder]['changed'] = false;
            }
        }
        break;
      case 'Thursday':
        for (var oder in simpleOderModelArray) {
          if (
            simpleOderModelArray[oder]['Day'] == 'Thursday' ||
            simpleOderModelArray[oder]['Day'] == 'Friday' ||
            simpleOderModelArray[oder]['Day'] == 'Saturday'
          )
            if (simpleOderModelArray[oder]['changed'] == true) {
              simpleOderModelArray[oder]['changed'] = false;
            }
        }

        break;
      case 'Saturday':
        for (var oder in simpleOderModelArray) {
          if (
            simpleOderModelArray[oder]['Day'] == 'Sunday' ||
            simpleOderModelArray[oder]['Day'] == 'Monday'
          )
            if (simpleOderModelArray[oder]['changed'] == true) {
              simpleOderModelArray[oder]['changed'] = false;
            }
        }

        break;
      default: {
      }
    }


    return simpleOderModelArray;
  }

  setSendedOrderClearChanged(simpleOderModelArray: any, toggledDay: string) {
    switch (toggledDay) {
      case 'Tuesday':
        for (var oder in simpleOderModelArray) {
          if (
            simpleOderModelArray[oder]['Day'] == 'Tuesday' ||
            simpleOderModelArray[oder]['Day'] == 'Wednesday'
          )
            if (simpleOderModelArray[oder]['changed'] == true) {
              simpleOderModelArray[oder]['changed'] = false;
              simpleOderModelArray[oder]['sended'] = true;
            }
        }
        break;
      case 'Thursday':
        for (var oder in simpleOderModelArray) {
          if (
            simpleOderModelArray[oder]['Day'] == 'Thursday' ||
            simpleOderModelArray[oder]['Day'] == 'Friday' ||
            simpleOderModelArray[oder]['Day'] == 'Saturday'
          )
            if (simpleOderModelArray[oder]['changed'] == true) {
              simpleOderModelArray[oder]['changed'] = false;
              simpleOderModelArray[oder]['sended'] = true;
            }
        }

        break;
      case 'Saturday':
        for (var oder in simpleOderModelArray) {
          if (
            simpleOderModelArray[oder]['Day'] == 'Sunday' ||
            simpleOderModelArray[oder]['Day'] == 'Monday'
          )
            if (simpleOderModelArray[oder]['changed'] == true) {
              simpleOderModelArray[oder]['changed'] = false;
              simpleOderModelArray[oder]['sended'] = true;
            }
        }

        break;
      default: {
      }
    }


    return simpleOderModelArray;
  }

  setSendedOrderClearChangedAll(simpleOderModelArray: any) {
    for (var oder in simpleOderModelArray) {
      if (simpleOderModelArray[oder]['changed'] == true) {
        simpleOderModelArray[oder]['changed'] = false;
        simpleOderModelArray[oder]['sended'] = true;
      }
    }

    return simpleOderModelArray;
  }

  clearChangedAll(simpleOderModelArray: any) {
    for (var oder in simpleOderModelArray) {
      if (simpleOderModelArray[oder]['changed'] == true) {
        simpleOderModelArray[oder]['changed'] = false;
        //simpleOderModelArray[oder]["sended"] = true;
      }
    }

    return simpleOderModelArray;
  }

  getSumOrder(simpleOderModelArray: any) {
    let sumOder = 0;
    for (var oder in simpleOderModelArray) {
      sumOder += simpleOderModelArray[oder].sumOder;
    }
    return sumOder;
  }

  getSumOder(cat: any, element: number, nameDay: string) {
    let catNumber;
    if (cat == 'Froko') {
      catNumber = 1;
    } else if (cat == 'Extra') {
      catNumber = 2;
    } else if ((cat = 'Editweekplan')) {
      catNumber = 3;
    }
    var sumOder = 0;
    var orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      catNumber,
      element
    );
    for (var oder in this.simpleOderModelArray) {
      if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
        sumOder = this.simpleOderModelArray[oder].sumOder;
      }
    }

    return sumOder;
  }

  //-----------------------------Update SimpleOrderModelArray from respnse---------------------

  updateOrdersInLocalStorageFromResponse(
    toggledDay: string,
    dishAllocationsOrderDays: any
  ) {

    for (let day in dishAllocationsOrderDays) {
      if (dishAllocationsOrderDays[day]['mainDishes'] && day == toggledDay) {
        for (
          let i = 0;
          i < dishAllocationsOrderDays[day]['mainDishes'].length;
          i++
        ) {


          for (let itemdietTypeGroups in dishAllocationsOrderDays[day][
            'mainDishes'
          ][i]['dietTypeGroups']) {
            for (let itemDietType in dishAllocationsOrderDays[day][
              'mainDishes'
            ][i]['dietTypeGroups'][itemdietTypeGroups]['dietTypes']) {
              console.debug(
                '[updateOrdersInLocalStorageFromResponse] menuLine ',
                dishAllocationsOrderDays[day]['mainDishes'][i][
                  'dietTypeGroups'
                ][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']
              );

              if (
                dishAllocationsOrderDays[day]['mainDishes'][i][
                  'dietTypeGroups'
                ][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine'] &&
                dishAllocationsOrderDays[day]['mainDishes'][i][
                  'dietTypeGroups'
                ][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine'][
                  'quantity'
                ] > 0
              ) {
                this.updateSimpleOderModelArrayFromResponse(
                  'Editweekplan',
                  dishAllocationsOrderDays[day]['mainDishes'][i][
                    'dietTypeGroups'
                  ][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine'],
                  day,
                  false
                );
              }
            }
          }
        }
      }
    }
    this.sumOder = this.getSumOrder(this.simpleOderModelArray);
  }

  //-------------------------Update SimpleOrderModelArray from response If Exist---------------------

  updateOrdersInLocalStorageFromResponseIfExist(
    toggledDay: string,
    dishAllocationsOrderDays: any
  ) {
    console.debug(
      '[updateOrdersInLocalStorageFromResponse] toggledDay',
      toggledDay
    );

    console.debug(
      '[updateOrdersInLocalStorageFromResponse] dishAllocationsOrderDays',
      dishAllocationsOrderDays
    );

    for (let day in dishAllocationsOrderDays) {
      if (dishAllocationsOrderDays[day]['mainDishes'] && day == toggledDay) {
        for (
          let i = 0;
          i < dishAllocationsOrderDays[day]['mainDishes'].length;
          i++
        ) {
          console.debug(
            '[updateOrdersInLocalStorageFromResponse] mainDishes[i]',
            i
          );

          for (let itemdietTypeGroups in dishAllocationsOrderDays[day][
            'mainDishes'
          ][i]['dietTypeGroups']) {
            for (let itemDietType in dishAllocationsOrderDays[day][
              'mainDishes'
            ][i]['dietTypeGroups'][itemdietTypeGroups]['dietTypes']) {
              console.debug(
                '[updateOrdersInLocalStorageFromResponse] menuLine ',
                dishAllocationsOrderDays[day]['mainDishes'][i][
                  'dietTypeGroups'
                ][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']
              );

              if (
                dishAllocationsOrderDays[day]['mainDishes'][i][
                  'dietTypeGroups'
                ][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']
              ) {
                this.updateSimpleOderModelArrayFromResponseIfExist(
                  'Editweekplan',
                  dishAllocationsOrderDays[day]['mainDishes'][i][
                    'dietTypeGroups'
                  ][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine'],
                  day,
                  false
                );
              }
            }
          }
        }
      }
    }
    this.sumOder = this.getSumOrder(this.simpleOderModelArray);
  }

  updateSimpleOderModelArrayFromResponse(
    cat: any,
    menuLine: any,
    nameDay: string,
    changed: boolean
  ) {
    /*console.debug('[updateSimpleOderModelArrayFromResponse]');
    console.debug('[updateSimpleOderModelArrayFromResponse] nameDay', nameDay);
    console.debug('[updateSimpleOderModelArrayFromResponse] cat', cat);
    console.info('[updateSimpleOderModelArray] menuLine', menuLine);
    */
    let catNumber;
    if (cat == 'Froko') {
      catNumber = 1;
    } else if (cat == 'Extra') {
      catNumber = 2;
    } else if ((cat = 'Editweekplan')) {
      catNumber = 3;
    }
    var orderFound = false;

    let element = Number(menuLine['productNr']) + Number(menuLine['line']);
    var orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      catNumber,
      element
    );
    /*console.debug(
      '[updateSimpleOderModelArrayFromResponse] orderDishIdTCurNumber=' +
        orderDishIdTCur
    );*/
    //---------------------------- Get CategoryName-----------------

    let categoryName = this.getCategoryName();

    //--------------------------------------------------------------

    if (this.simpleOderModelArray.length == 0 && menuLine['quantity'] != 0) {
      /*console.debug(
        '[updateSimpleOderModelArrayFromResponse] this.simpleOderModelArray.length=',
        menuLine
      );
      console.debug(
        '[updateSimpleOderModelArrayFromResponse] menuLine[quantity]',
        menuLine['quantity']
      );*/
      this.simpleOderModelArray.push(
        new SimpleOderModel(
          orderDishIdTCur,
          Number(menuLine['quantity']),

          Number(menuLine['quantity']),

          nameDay,
          menuLine['productNr'],
          menuLine['productName'],
          '',
          categoryName,
          'no subcategoreis',
          {
            dietTypeNr: menuLine['dietTypeNr'],
            dietTypeName: menuLine['dietTypeName'],
            portionSize: menuLine['portionSize'],
            line: menuLine['line'],
            orderNr: this.dishAllocationsOrderDays[nameDay].orderNr,
            customerNr: this.dishAllocationsOrderDays[nameDay].customerNr,
            menuNr: this.dishAllocationsOrderDays[nameDay].menuNr,
            changed: changed,
            orderNotPermit: this.orderNotPermit,
            enkeltPortion: menuLine['enkeltPortion'],
            sended: false,
          }
        )
      );
      /*console.debug(
        '[updateSimpleOderModelArrayFromResponse] length==0 this.simpleOderModelArray',
        this.simpleOderModelArray
      );*/
    } else {
      for (var oder in this.simpleOderModelArray) {
        if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
          /*console.debug(
            '[updateSimpleOderModelArrayFromResponse] orderFound',
            menuLine
          );*/
          orderFound = true;
          if (
            this.simpleOderModelArray[oder].changed != true &&
            this.simpleOderModelArray[oder].sended != true
          ) {
            this.simpleOderModelArray[oder].orderNumber = Number(
              menuLine['quantity']
            );
            this.simpleOderModelArray[oder].sumOder = Number(
              menuLine['quantity']
            );
            /*console.log(
              '[updateSimpleOderModelArrayFromResponse] this.simpleOderModelArray[oder].sumOder',
              this.simpleOderModelArray[oder].sumOder
            );*/
            this.simpleOderModelArray[oder].changed = changed;
          }
        }
      }

      if (!orderFound) {
        /*console.debug(
          '[updateSimpleOderModelArrayFromResponse] !orderFound',
          menuLine
        );*/
        this.simpleOderModelArray.push(
          new SimpleOderModel(
            orderDishIdTCur,
            Number(menuLine['quantity']),
            Number(menuLine['quantity']),
            nameDay,
            menuLine['productNr'],
            menuLine['productName'],
            '',
            categoryName,
            'no subcategoreis',
            {
              dietTypeNr: menuLine['dietTypeNr'],
              dietTypeName: menuLine['dietTypeName'],
              portionSize: menuLine['portionSize'],
              line: menuLine['line'],
              orderNr: this.dishAllocationsOrderDays[nameDay].orderNr,
              customerNr: this.dishAllocationsOrderDays[nameDay].customerNr,
              menuNr: this.dishAllocationsOrderDays[nameDay].menuNr,
              changed: changed,
              orderNotPermit: this.orderNotPermit,
              enkeltPortion: menuLine['enkeltPortion'],
              sended: false,
            }
          )
        );
      }
    }

    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );

   /* console.debug(
      '[updateSimpleOderModelArrayFromResponse] this.simpleOderModelArray',
      this.simpleOderModelArray
    );*/
  }
  //------------------------------------------------------------------------------------------------------------

  updateSimpleOderModelArrayFromResponseIfExist(
    cat: any,
    menuLine: any,
    nameDay: string,
    changed: boolean
  ) {
    /*
    console.debug('[updateSimpleOderModelArrayFromResponseIfExist]');
    console.debug(
      '[updateSimpleOderModelArrayFromResponseIfExist] nameDay',
      nameDay
    );
    console.debug('[updateSimpleOderModelArrayFromResponseIfExist] cat', cat);
    console.info(
      '[updateSimpleOderModelArrayFromResponseIfExist] menuLine',
      menuLine
    );
      */
    let catNumber;
    if (cat == 'Froko') {
      catNumber = 1;
    } else if (cat == 'Extra') {
      catNumber = 2;
    } else if ((cat = 'Editweekplan')) {
      catNumber = 3;
    }
    var orderFound = false;

    let element = Number(menuLine['productNr']) + Number(menuLine['line']);
    var orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      catNumber,
      element
    );
    /*console.debug(
      '[updateSimpleOderModelArrayFromResponseIfExist] orderDishIdTCurNumber=' +
        orderDishIdTCur
    );
     */
    for (var oder in this.simpleOderModelArray) {
      if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
        /*console.debug(
          '[updateSimpleOderModelArrayFromResponseIfExist] orderFound',
          menuLine
        );*/
        orderFound = true;
        if ((this.simpleOderModelArray[oder].changed = true)) {
          this.simpleOderModelArray[oder].orderNumber = Number(
            menuLine['quantity']
          );
          this.simpleOderModelArray[oder].sumOder = Number(
            menuLine['quantity']
          );
          this.simpleOderModelArray[oder].changed = changed;
        }
      }
    }

    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );

    /*console.debug(
      '[updateSimpleOderModelArrayFromResponseIfExist] this.simpleOderModelArray',
      this.simpleOderModelArray
    );
    console.debug(
      '[updateSimpleOderModelArrayFromResponseIfExist] this.simpleOderModelArray',
      JSON.stringify(this.simpleOderModelArray)
    );*/
  }

  ////----------------------------------------------------------------------------------------------------------

  prevWeek(content: any) {
    this.nextShiftWeek = this.shiftWeek - 1;
    this.isQuantitiesChanged = this.checkQuantitiesChanged(
      this.simpleOderModelArray
    );
    if (this.isQuantitiesChanged) {
      this.changedShiftWeek = true;
      this.openWindowCustomClass(content);
    } else {
      //this.switchPrevWeek();
      this.switchWeek(this.nextShiftWeek);
    }
  }

  switchWeek(nextShiftWeek: number) {
    if (!this.isQuantitiesChanged || this.continueSwitching) {
      //this.shiftWeek--;
      this.shiftWeek = nextShiftWeek;
      this.localStorageService.set(
        'shiftWeek' + this.localStorageService.get('currentChildRoute'),
        this.shiftWeek
      );
      this.updateCartFromLocalStorage();

      this.localStorageService.set('toggledDayBulkOrder', 'Tuesday');
      this.toggledTuesday = true;
      this.toggledThursday = false;
      this.toggledSaturday = false;
      this.templateDays = ['Tuesday', 'Wednesday'];

      this.needNgOninit = true;
      //this.needUnsubscribe = false;

      this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
      //this.ngOnInit();
    }
  }
  nextWeek(content: any) {
    this.nextShiftWeek = this.shiftWeek + 1;
    this.isQuantitiesChanged = this.checkQuantitiesChanged(
      this.simpleOderModelArray
    );
    if (this.isQuantitiesChanged) {
      this.changedShiftWeek = true;
      this.openWindowCustomClass(content);
    } else {
      //this.switchNextWeek();
      this.switchWeek(this.nextShiftWeek);
    }
  }

  /*  switchNextWeek(){
    	if((!this.isQuantitiesChanged) || this.continueSwitching){
			this.shiftWeek++;
			this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'currentChildRoute'), this.shiftWeek );
			this.updateCartFromLocalStorage();
			this.needNgOninit = true;
			//this.needUnsubscribe = false;

			this.router.navigateByUrl("/minmenu/bulkorder/editweekplan");
			//this.ngOnInit();
		}
    }*/

  getImgUrl(imageUrl: string): string {
    //	console.debug("IMAGEUrl:"+ imageUrl);
    var imgUrl = this.dataSource.baseUrl + imageUrl;
    //	console.debug("IMG:"+ imgUrl);
    // 	return imgUrl.toString();
    return imgUrl;
  }

  getTargetId(cat: any, nameDay: string, itemDish: any): string {
    var id = '#' + cat + nameDay + itemDish;
    return id.toString();
  }

  getId(cat: any, nameDay: string, itemDish: any): string {
    var id = cat + nameDay + itemDish;
    //  	console.debug("In getId IMG:"+ id);
    return id.toString();
  }

  getOrderDishId(
    fromPage: string,
    shiftWeek: number,
    day: string,
    cat: number,
    dishPlace: number
  ) {
    var pageNumber: number;
    let orderDishId: number = 0;
    var week = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    switch (fromPage) {
      case 'frokostpakker': {
        pageNumber = 1;
        //	console.debug("frokostpakker");
        break;
      }
      case 'extra': {
        pageNumber = 2;
        //console.debug("extra");
        break;
      }
      case 'bulkorder/editweekplan': {
        pageNumber = 3;
        //console.debug("extra");
        break;
      }
    }

    orderDishId = Number(
      pageNumber * 1000000 +
        (Number(this.dateService.getCurrentWeek()) + Number(shiftWeek)) * 1000 +
        Number(week.indexOf(day) * 1000) +
        cat * 100 +
        dishPlace
    );

    console.debug('InorderDishId', orderDishId);
    return orderDishId;
  }

  updateCartFromLocalStorage() {
    this.cart.updateLinesFromLocalStorage(this.shiftWeekNumber);
  }

  accept() {
    this.router.navigateByUrl('/minmenu/cart');
  }

  getRowsNumberForDay(numberElementsInRow: number, dishesArraylength: number) {
    var rowsNumber = Math.trunc(dishesArraylength / numberElementsInRow);
    //	console.debug("In getRowsNumberForDay rowsNumber="+rowsNumber);

    if (dishesArraylength % numberElementsInRow != 0) {
      rowsNumber++;
    }
    if (rowsNumber == 0) {
      rowsNumber = 1;
    }
    return rowsNumber;
  }

  createSumMatrixForDays(dishAllocationsOrderDays: any) {
    let sumMatrixForDaes = new SumMatrixForDays();
    let numberElementsInRow = 5;
    console.debug(
      '[createSumMatrixForDays] dishAllocationsOrderDaysSum',
      dishAllocationsOrderDays
    );
    for (let curDay in dishAllocationsOrderDays) {
      if (
        dishAllocationsOrderDays[curDay] !== undefined &&
        dishAllocationsOrderDays[curDay]['mainDishes'] !== undefined
      ) {
        for (
          let i = 0;
          i < dishAllocationsOrderDays[curDay]['mainDishes'].length;
          i++
        ) {
          sumMatrixForDaes[curDay][i] = new Array(numberElementsInRow);
        }
        for (let i = 0; i < sumMatrixForDaes[curDay].length; i++) {
          for (let j = 0; j < sumMatrixForDaes[curDay][i].length; j++) {
            sumMatrixForDaes[curDay][i][j] = 0;
          }
        }
      }
    }

    console.debug('RETURNsumMatrixForDays', sumMatrixForDaes);
    return sumMatrixForDaes;
  }

  createSumMatrixForCurrentDay(dishAllocationsOrderDays: any, day: string) {
    let sumMatrixForDaes = new SumMatrixForDays();
    let numberElementsInRow = 5;
    for (let curDay in dishAllocationsOrderDays) {
      if (dishAllocationsOrderDays[curDay]['mainDishes'] !== undefined) {
        for (
          let i = 0;
          i < dishAllocationsOrderDays[curDay]['mainDishes'].length;
          i++
        ) {
          sumMatrixForDaes[curDay][i] = new Array(numberElementsInRow);
        }
        for (let i = 0; i < sumMatrixForDaes[curDay].length; i++) {
          for (let j = 0; j < sumMatrixForDaes[curDay][i].length; j++) {
            sumMatrixForDaes[curDay][i][j] = 0;
          }
        }
      }
    }

    console.debug('RETURNsumMatrixForDays', sumMatrixForDaes);
    return sumMatrixForDaes;
  }
  getArrayDishesViewForDayController(
    numberElementsInRow: number,
    dishesArraylength: number
  ) {
    var rows = this.getRowsNumberForDay(numberElementsInRow, dishesArraylength);
    //	console.debug("rows="+rows);

    var arrayViewController = new Array(rows);
    for (var i = 0; i < arrayViewController.length; i++) {
      arrayViewController[i] = new Array(numberElementsInRow);
    }
    for (var i = 0; i < arrayViewController.length; i++) {
      for (var j = 0; j < arrayViewController[i].length; j++) {
        arrayViewController[i][j] = -1;
      }
    }

    var k = numberElementsInRow;
    var ref = 0;
    for (var i = 0; i < rows; i++) {
      if (dishesArraylength > numberElementsInRow) {
        if (i == rows - 1) {
          if (dishesArraylength % numberElementsInRow != 0) {
            k = dishesArraylength % numberElementsInRow;
            console.debug('k=' + k);

            arrayViewController[i].length = k; // trim last row to the number of items remainder
          }
        }
      } else {
        k = dishesArraylength;
        arrayViewController[i].length = k;
      }

      for (var j = 0; j < k; j++) {
        arrayViewController[i][j] = ref;
        ref++;
      }
    }

    return arrayViewController;
  }

  toggleMonday() {
    this.toggledMonday = true;
    this.toggledWednesday = false;
    this.toggledFriday = false;
  }

  toggleWednesday() {
    this.toggledMonday = false;
    this.toggledWednesday = true;
    this.toggledFriday = false;
  }

  toggleFriday() {
    this.toggledMonday = false;
    this.toggledWednesday = false;
    this.toggledFriday = true;
  }

  toggleTuesday(content: any) {
    this.nextDay = 'Tuesday';
    //this.needNgOninit = true;
    //this.needUnsubscribe = false;

    this.isQuantitiesChanged = this.checkQuantitiesChanged(
      this.simpleOderModelArray
    );
    if (this.isQuantitiesChanged) {
      this.changedDay = true;
      this.openWindowCustomClass(content);
    } else {
      this.switchTuesday();
    }
  }

  switchTuesday() {
    //	if((!this.isQuantitiesChanged) || this.continueSwitching){
    this.toggledTuesday = true;
    this.toggledThursday = false;
    this.toggledSaturday = false;
    //---------------------DelivaryDay is saved---------------------------------

    /*let day = this.localStorageService.get( 'toggledDayBulkOrder');
			this.deliveryDaysNumberOrdered[day]=this.getNumberOfSelectedHovedret(this.sumMatrixForDays, day)
			this.localStorageService.set(this.deliveryDaysNumberOrderedId, this.deliveryDaysNumberOrdered);
			*/
    this.localStorageService.set('toggledDayBulkOrder', 'Tuesday');
    this.templateDays = ['Tuesday', 'Wednesday'];
    this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
    //	}
  }

  toggleThursday(content: any) {
    this.nextDay = 'Thursday';
    //this.needNgOninit = true;
    //this.needUnsubscribe = false;

    this.isQuantitiesChanged = this.checkQuantitiesChanged(
      this.simpleOderModelArray
    );
    if (this.isQuantitiesChanged) {
      //this.changedDay = true;
      this.openWindowCustomClass(content);
    } else {
      this.switchThursday();
    }
  }
  switchThursday() {
    if (!this.isQuantitiesChanged || this.continueSwitching) {
      this.toggledTuesday = false;
      this.toggledThursday = true;
      this.toggledSaturday = false;
      //---------------------DelivaryDay is saved---------------------------------
      /*
			let day = this.localStorageService.get( 'toggledDayBulkOrder');
			this.deliveryDaysNumberOrdered[day]=this.getNumberOfSelectedHovedret(this.sumMatrixForDays, day)
			this.localStorageService.set(this.deliveryDaysNumberOrderedId, this.deliveryDaysNumberOrdered);
			*/
      this.localStorageService.set('toggledDayBulkOrder', 'Thursday');
      this.templateDays = ['Thursday', 'Friday', 'Saturday'];
      this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
    }
  }

  toggleSaturday(content: any) {
    this.nextDay = 'Saturday';
    //this.needNgOninit = true;
    //this.needUnsubscribe = false;

    this.isQuantitiesChanged = this.checkQuantitiesChanged(
      this.simpleOderModelArray
    );
    if (this.isQuantitiesChanged) {
      //this.changedDay = true;
      this.openWindowCustomClass(content);
    } else {
      this.switchSaturday();
    }
  }

  switchSaturday() {
    if (!this.isQuantitiesChanged || this.continueSwitching) {
      this.toggledTuesday = false;
      this.toggledThursday = false;
      this.toggledSaturday = true;
      //---------------------DelivaryDay is saved---------------------------------
      /*
			let day = this.localStorageService.get( 'toggledDayBulkOrder');
			this.deliveryDaysNumberOrdered[day]=this.getNumberOfSelectedHovedret(this.sumMatrixForDays, day)
			this.localStorageService.set(this.deliveryDaysNumberOrderedId, this.deliveryDaysNumberOrdered);
			*/
      this.localStorageService.set('toggledDayBulkOrder', 'Saturday');
      this.templateDays = ['Sunday', 'Monday'];
      this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
    }
  }

  translateDayToDanish(day: string) {
    return this.localeDaDKService.translateDayToDanishsFull(day);
  }

  getDayDate(day: string) {
    if (day != 'Monday') {
      return this.dateService.getISODateDDMMMforDayTest(day, this.shiftWeek);
    } else {
      return this.dateService.getISODateDDMMMforDayTest(
        day,
        this.shiftWeek + 1
      );
    }
  }
  //-------------------------Test Delivery Day if ordered-------------------------
  testDeliveryDayIfOrdered(simpleOderModelArray: any) {
    let deliveryDaysOrdered = new DeliveryDaysOrdered(
      false,
      false,
      false,
      false,
      false,
      false,
      false
    );
    for (var oder in simpleOderModelArray) {
      switch (simpleOderModelArray[oder]['Day']) {
        case 'Tuesday':
          deliveryDaysOrdered['Tuesday'] = true;
          break;
        case 'Wednesday':
          deliveryDaysOrdered['Wednesday'] = true;
          break;
        case 'Thursday':
          deliveryDaysOrdered['Thursday'] = true;

          break;
        case 'Friday':
          deliveryDaysOrdered['Friday'] = true;
          break;
        case 'Saturday':
          deliveryDaysOrdered['Saturday'] = true;

          break;
        case 'Sunday':
          deliveryDaysOrdered['Sunday'] = true;
          break;
        case 'Monday':
          deliveryDaysOrdered['Monday'] = true;
          break;
        default: {
        }
      }
    }

    return deliveryDaysOrdered;
  }

  //------------------------------------------------------------------------------
  isDayForOrder(orderingDates: any[], nameDay: string, shiftWeek: number) {
    //	if(this.filterOn){
    var isDayForOrderFlag = false;
    var date = this.dateService.getISODateYYYMMDDforDay(nameDay, shiftWeek);
    //	console.debug("DATE ="+date);
    for (var day in this.orderingDates) {
      if (date == this.orderingDates[day]) {
        isDayForOrderFlag = true;
      }
    }

    return isDayForOrderFlag;
  }

  filterToogle() {
    this.filterOn = !this.filterOn;
  }

  isOrderingTime(userInfoOrderingModel: any[], categoryForFilter: string) {
    //	console.debug("In isOrderingTime");
    var startServingTime = '';
    var endServingTime = '';
    var currentTime = 0;
    for (var userCategory in userInfoOrderingModel['userCategories']) {
      //		console.debug("In isOrderingTime cat="+userInfoOrderingModel["userCategories"][userCategory]["category"]["name"]);
      if (
        userInfoOrderingModel['userCategories'][userCategory]['category'][
          'name'
        ] == categoryForFilter
      ) {
        startServingTime =
          userInfoOrderingModel['userCategories'][userCategory][
            'startServingTime'
          ];
        //	var dateStart =  this.dateService.getDateFromTimeHHmmss(startServingTime);

        //	console.debug("IsstartServingTime=" + this.dateService.returnCurrentDateForTimeHHmmss(dateStart));
        endServingTime =
          userInfoOrderingModel['userCategories'][userCategory][
            'endServingTime'
          ];

        if (
          this.dateService.isCurrentTimeBetween(
            startServingTime,
            endServingTime
          )
        ) {
          return (this.isOrderingTimeFlag = true);
        } else return (this.isOrderingTimeFlag = true);
      }
    }
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openWindowCustomClass(content) {
    this.modalService.open(content, {
      centered: true,
      windowClass: 'modalWindowEditWeekPlan',
    });
  }

  editTableWeekplan(content: any, itemMenuLines: any, day: string, j: number) {
    this.hideBulkEnkeltBtns = false;
    if (
      (this.sideDishesStartDays[day] !== undefined &&
        j >= this.sideDishesStartDays[day]) ||
      itemMenuLines['menuLine']['dietTypeNr'] == 30
    ) {
      this.isSideDish = true;
      this.curSideDishName = itemMenuLines['menuLine']['productName'];
      if (itemMenuLines['menuLine']['dietTypeName'] =='Dagens grønt') {
        this.hideBulkEnkeltBtns = true;
      }
    } else {
      this.isSideDish = false;
    }
    if (this.guestChefService.isGuestChefModelDay(this.guestChefModel, day)) {
      this.hideBulkEnkeltBtns = true;
      this.isSideDish = true;
      this.curSideDishName = itemMenuLines['menuLine']['productName'];

    }
    this.servingDay = day;
    if (
      itemMenuLines &&
      itemMenuLines['menuLine'] &&
      itemMenuLines['menuLine']['dietTypeNr'] &&
      itemMenuLines['menuLine']['dietTypeNr'] == 30
    ) {
      this.isDietTypeNr30 = true;

    } else {
      this.isDietTypeNr30 = false;
    }

    this.currentDayForModal = day;

    this.currentItemMenuLines = itemMenuLines;

    //------------------------------GET modalKodCategoryMap---------------------------------
    this.modalKodCategoryMap = this.getKodCategoryArrayForMenuLine(
      itemMenuLines,
      itemMenuLines['menuLine']['productNr']
    );

    console.debug(
      '[editTableWeekplan] this.modalKodCategoryMap',
      this.modalKodCategoryMap
    );
    console.debug(
      '[editTableWeekplan] map.size',
      this.modalKodCategoryMap.size
    );
    if (this.modalKodCategoryMap.size != 0) {
      this.isModalKodCategoryMapNonEmpty = true;
      this.modalKodCategoryMap.forEach(function (value, key, ownerSet) {
        for (let itemTibehor in value) {
          let itemSelected = this.findBtKodTilbehorFromMenuLine(
            itemMenuLines,
            key,
            value[itemTibehor]['tibehorCategory'],
            itemMenuLines['dietTypeGroups'][0]['dietTypes'][0]['menuLine'][
              'productNr'
            ],
            0
          );
          value[itemTibehor]['tibehorQuantity'] = Number(
            itemSelected['quantity']
          );
          //value[itemTibehor]["tibehorQuantity"]=100;
        }
      }, this);

      console.debug(
        '[editTableWeekplan] modalKodCategoryMap2',
        this.modalKodCategoryMap
      );

      this.modalKodCategoryArrayFiltered = Array.from(
        this.getFilteredMapByQuantitiesSoft(
          this.modalKodCategoryMap,
          itemMenuLines,
          this.isSideDish
        )
      );

      console.debug(
        '[editTableWeekplan] modalKodCategoryArrayFiltered',
        this.modalKodCategoryArrayFiltered
      );

      console.debug(
        '[editTableWeekplan] this.modalKodCategoryMap After Filter',
        this.modalKodCategoryMap
      );

      this.modalKodCategoryArray = Array.from(this.modalKodCategoryMap);
      console.debug(
        '[editTableWeekplan] modalKodCategoryArray',
        this.modalKodCategoryArray
      );

      this.defaultKod = this.modalKodCategoryArray[0][0];

      this.selectedKodFuldkost = this.defaultKod;

      this.currentTilbehorArr = this.modalKodCategoryArray[0][1]; //Kod Category
      console.debug(
        '[editTableWeekplan] this.currentTilbehorArr',
        this.currentTilbehorArr
      );

      this.defaultTilbehor = this.currentTilbehorArr[0]['tibehorCategory'];

      this.selectedStivelseFuldkost = this.defaultTilbehor;

      console.debug('[editTableWeekplan] itemMenuLines', itemMenuLines);

      this.contentFuldkostAntal = this.currentTilbehorArr[0]['tibehorQuantity'];
      console.debug('this.content', content);
    } else {
      this.isModalKodCategoryMapNonEmpty = false;
      this.currentTilbehorArr = [];
      this.modalKodCategoryArray = [];
      this.contentFuldkostAntal = 0;
    }

    //------------------Lines Arr--------------------------------------------

    this.subLinesSetArr = Array.from(
      this.getSubLinesSetForMenuLineFuldcostBulk(itemMenuLines)
    );
    console.debug('[editTableWeekplan] subLinesSetArr', this.subLinesSetArr);
    this.subLinesSetKodArr = Array.from(
      this.getSubLinesSetForMenuLineFuldcostKod(itemMenuLines, true)
    );
    console.debug(
      '[editTableWeekplan] subLinesSetKodArr',
      this.subLinesSetKodArr
    );
    this.subLinesSetTilbehorArr = Array.from(
      this.getSubLinesSetForMenuLineFuldcostTilbehor(itemMenuLines, true)
    );
    console.debug(
      '[editTableWeekplan] subLinesSetTilbehorArr',
      this.subLinesSetTilbehorArr
    );
    this.subLinesSetGrontArr = Array.from(
      this.getSubLinesSetForMenuLineFuldcostGront(itemMenuLines, true)
    );
    console.debug(
      '[editTableWeekplan] subLinesSetGrontArr',
      this.subLinesSetGrontArr
    );
    this.subLinesSetSovsArr = Array.from(
      this.getSubLinesSetForMenuLineFuldcostSovs(itemMenuLines, true)
    );
    console.debug(
      '[editTableWeekplan] subLinesSetSovsArr',
      this.subLinesSetSovsArr
    );

    this.subLinesSetArrEnkel = Array.from(
      this.getSubLinesSetForMenuLineFuldkostEnkel(itemMenuLines)
    );
    console.debug(
      '[editTableWeekplan] subLinesSetArrEnkel',
      this.subLinesSetArrEnkel
    );

    this.openWindowCustomClass(content);
  }

  createSubLinesSetKodArr(itemMenuLines: any) {
    let subLinesSetKodArr = Array.from(
      this.getSubLinesSetForMenuLineFuldcostKod(itemMenuLines, false)
    );
    return subLinesSetKodArr;
  }

  createSubLinesSetTilbehorArr(itemMenuLines: any) {
    let subLinesSetTilbehorArr = Array.from(
      this.getSubLinesSetForMenuLineFuldcostTilbehor(itemMenuLines, false)
    );
    return subLinesSetTilbehorArr;
  }

  createSubLinesSetGrontArr(itemMenuLines: any) {
    let subLinesSetGrontArr = Array.from(
      this.getSubLinesSetForMenuLineFuldcostGront(itemMenuLines, false)
    );
    return subLinesSetGrontArr;
  }

  createSubLinesSetSovsArr(itemMenuLines: any) {
    let subLinesSetSovsArr = Array.from(
      this.getSubLinesSetForMenuLineFuldcostSovs(itemMenuLines, false)
    );
    return subLinesSetSovsArr;
  }

  editTableWeekplanEnergitat(content: any, itemMenuLines: any, day?: string) {
    this.currentDayForModal = day;
    this.currentItemMenuLines = itemMenuLines;

    for (let itemMainDishes in this.sumMatrixForDays[day]) {
      this.contentEnergitatAntalArr[itemMainDishes] = new Array(
        this.sumMatrixForDays[this.currentDayForModal].length
      );

      if (
        this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][
          itemMainDishes
        ]['dietTypeGroups'][1]
      ) {
        for (let itemDietTypes in this.dishAllocationsOrderDays[day][
          'mainDishes'
        ][itemMainDishes]['dietTypeGroups'][1]['dietTypes']) {
          if (
            this.dishAllocationsOrderDays[this.currentDayForModal][
              'mainDishes'
            ][itemMainDishes]['dietTypeGroups'][1]['dietTypes'][
              itemDietTypes
            ] != undefined
          ) {
            this.contentEnergitatAntalArr[itemMainDishes][
              itemDietTypes
            ] = this.dishAllocationsOrderDays[this.currentDayForModal][
              'mainDishes'
            ][itemMainDishes]['dietTypeGroups'][1]['dietTypes'][itemDietTypes][
              'menuLine'
            ]['quantity'];
          } else {
            this.contentEnergitatAntalArr[itemMainDishes][itemDietTypes] = 0;
          }
        }
      }
    }
    this.openWindowCustomClass(content);
  }

  editTableWeekplanBlod(
    content: any,
    itemMenuLines: any,
    day?: string,
    indexDietTypeGroups?: number
  ) {
    this.curIndexDietTypeGroupsBlod = indexDietTypeGroups;

    this.currentDayForModal = day;
    this.currentItemMenuLines = itemMenuLines;

    for (let itemMainDishes in this.sumMatrixForDays[day]) {
      this.contentBlodAntalArr[itemMainDishes] = new Array(
        this.sumMatrixForDays[this.currentDayForModal].length
      );

      if (
        this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][
          itemMainDishes
        ]['dietTypeGroups'][indexDietTypeGroups]
      ) {
        for (let itemDietTypes in this.dishAllocationsOrderDays[day][
          'mainDishes'
        ][itemMainDishes]['dietTypeGroups'][indexDietTypeGroups]['dietTypes']) {
          if (
            this.dishAllocationsOrderDays[this.currentDayForModal][
              'mainDishes'
            ][itemMainDishes]['dietTypeGroups'][indexDietTypeGroups][
              'dietTypes'
            ][itemDietTypes] !== undefined
          ) {
            this.contentBlodAntalArr[itemMainDishes][
              itemDietTypes
            ] = this.dishAllocationsOrderDays[this.currentDayForModal][
              'mainDishes'
            ][itemMainDishes]['dietTypeGroups'][indexDietTypeGroups][
              'dietTypes'
            ][itemDietTypes]['menuLine']['quantity'];
          } else {
            this.contentBlodAntalArr[itemMainDishes][itemDietTypes] = 0;
          }
        }
      }
    }
    this.openWindowCustomClass(content);
  }

  editTableWeekplanAndet(content: any, itemMenuLines: any, day?: string) {
    this.currentDayForModal = day;
    this.currentItemMenuLines = itemMenuLines;

    for (let itemMainDishes in this.dishAllDayFilteredDietTypeGroups[day][
      'mainDishes'
    ]) {
      this.contentAndetAntalArr[itemMainDishes] = new Array(
        this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][
          itemMainDishes
        ]['dietTypeGroups'].length
      );
      console.debug('this.contentAndetAntalArr1', this.contentAndetAntalArr);

      for (let itemdietTypeGroups in this.dishAllDayFilteredDietTypeGroups[day][
        'mainDishes'
      ][itemMainDishes]['dietTypeGroups']) {
        if (
          this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][
            itemMainDishes
          ]['dietTypeGroups'][itemdietTypeGroups] !== undefined
        ) {
          this.contentAndetAntalArr[itemMainDishes][
            itemdietTypeGroups
          ] = new Array(
            this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][
              itemMainDishes
            ]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'].length
          );
          console.debug('this.contentAndetAntalArr', this.contentAndetAntalArr);
          for (let itemDietTypes in this.dishAllDayFilteredDietTypeGroups[day][
            'mainDishes'
          ][itemMainDishes]['dietTypeGroups'][itemdietTypeGroups][
            'dietTypes'
          ]) {
            if (
              this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][
                itemMainDishes
              ]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][
                itemDietTypes
              ] != undefined
            ) {
              this.contentAndetAntalArr[itemMainDishes][itemdietTypeGroups][
                itemDietTypes
              ] = this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][
                itemMainDishes
              ]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][
                itemDietTypes
              ]['menuLine']['quantity'];
            } else {
              this.contentAndetAntalArr[itemMainDishes][itemdietTypeGroups][
                itemDietTypes
              ] = 0;
            }
          }
        }
      }
    }
    this.openWindowCustomClass(content);
  }

  getDishAllocationsOrderDayFilteredDietTypeGroupsCat(
    dishAllocationsOrderDay: any,
    category: string
  ) {
    let filteredDietTypeGroups = [];
    filteredDietTypeGroups = JSON.parse(
      JSON.stringify(dishAllocationsOrderDay)
    );
    for (let itemMainDishes in dishAllocationsOrderDay['mainDishes']) {
      let dietTypeGroupsArr = [];
      dietTypeGroupsArr = dishAllocationsOrderDay['mainDishes'][itemMainDishes][
        'dietTypeGroups'
      ].filter((el) => {
        if (el['dietTypeGroup']['name'] == category) {
          return true;
        } else {
          return false;
        }
      });

      filteredDietTypeGroups['mainDishes'][itemMainDishes][
        'dietTypeGroups'
      ] = JSON.parse(JSON.stringify(dietTypeGroupsArr));
    }


    return filteredDietTypeGroups;
  }

  isContainPersonalemadArrDietTypes(dietTypeGroupsItem: any[]): boolean {
    return dietTypeGroupsItem['dietTypes'].some(
            dietTypeItem => {
              if (this.isLineInPersonalemadBulkArr(dietTypeItem['menuLine']['line'])) {
                return true;
              } else if (this.isLineInPersonalemadEnkeltArr(dietTypeItem['menuLine']['line'])) {
                return true;
              } else {
                return false;
              }
            }
          )
  }
  getDietTypesGroupsArrContainsDietTypesFromPersonalemadArr(dietTypeGroups: any[]) {
    let dietTypesGroupsArrWithPersonalemad = [];
    let dietTypeGroupsArr = JSON.parse(JSON.stringify(dietTypeGroups));
    dietTypesGroupsArrWithPersonalemad = dietTypeGroupsArr.filter(
      dietTypeGroupsItem => {
        let dietTypesWithPersonalemad = [];
        if (dietTypeGroupsItem.dietTypes) {
          dietTypesWithPersonalemad = dietTypeGroupsItem['dietTypes'].filter(
            dietTypeItem => {
              if (this.isLineInPersonalemadBulkArr(dietTypeItem['menuLine']['line'])) {
               dietTypeItem['menuLine']['dietTypeName'] = 'Personalemad Bulk';
                return true;
              } else if (this.isLineInPersonalemadEnkeltArr(dietTypeItem['menuLine']['line'])) {
                dietTypeItem['menuLine']['dietTypeName'] = 'Personalemad  Enkelt';
                return true;
              } else {
                return false;
              }
            }
          )
        }
        if (dietTypesWithPersonalemad && dietTypesWithPersonalemad.length != 0) {
          dietTypeGroupsItem.dietTypes = dietTypesWithPersonalemad;
          dietTypeGroupsItem['dietTypeGroup']['name']='Personalemad'
          return true;
        } else {
          return false;
        }
      }
    )
    //------------------Transform in one struct --------------------
    dietTypesGroupsArrWithPersonalemad = this.getTypesGroupsArrWithPersonalemadConcated(dietTypesGroupsArrWithPersonalemad);

    return dietTypesGroupsArrWithPersonalemad;
  }
  getTypesGroupsArrWithPersonalemadConcated(dietTypesGroupsArrWithPersonalemad: any[]) {
    let dietTypesGroupsArrWithPersonalemadArr = [];
    if (dietTypesGroupsArrWithPersonalemad && dietTypesGroupsArrWithPersonalemad[0]) {
      dietTypesGroupsArrWithPersonalemadArr[0] = dietTypesGroupsArrWithPersonalemad.shift();
      for (let personalemadItem of dietTypesGroupsArrWithPersonalemad) {
         dietTypesGroupsArrWithPersonalemadArr[0]['dietTypes'].concat(personalemadItem['dietTypes'])
      }
    }
    return dietTypesGroupsArrWithPersonalemadArr;
  }
  isLineInPersonalemadBulkArr(line: number): boolean {
    let personalemadArr = [118, 268, 418, 568, 718, 845, 895, 945]
    return personalemadArr.some(
      personalemadLine => personalemadLine == line
    )
  }
  isLineInPersonalemadEnkeltArr(line: number): boolean {
    let personalemadArr = [119, 269, 419, 569, 719, 846, 896, 946]
    return personalemadArr.some(
      personalemadLine => personalemadLine == line
    )
  }


  getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(
    dishAllocationsOrderDay: any,
    indexStartSideDishForCurDay: number
  ) {
    let dishAllocationsOrderDayCopy = JSON.parse(
      JSON.stringify(dishAllocationsOrderDay)
    );
    let filteredDietTypeGroups = [];
    let mainMenuLineDietTypeNr1LineNumber: number;
    filteredDietTypeGroups = JSON.parse(
      JSON.stringify(dishAllocationsOrderDayCopy)
    );

    for (let itemMainDishes in dishAllocationsOrderDayCopy['mainDishes']) {
      let dietTypeGroupsArr = [];
      let curItemMainDishesIndex = Number(itemMainDishes)
      if (dishAllocationsOrderDayCopy['mainDishes'][itemMainDishes]['menuLine']['dietTypeNr'] == 1
        && (curItemMainDishesIndex >= indexStartSideDishForCurDay)) {
        mainMenuLineDietTypeNr1LineNumber = dishAllocationsOrderDayCopy['mainDishes'][itemMainDishes]['menuLine']['line'];
      } else {
        mainMenuLineDietTypeNr1LineNumber = undefined;
      }
      //----get dietTypesGroupsArr With Personalemad lines----

      let dietTypesGroupsArrWithPersonalemad = this.getDietTypesGroupsArrContainsDietTypesFromPersonalemadArr(
        dishAllocationsOrderDayCopy['mainDishes'][itemMainDishes]['dietTypeGroups']);


      console.log("🚀 ~ file: editweekplan.component.ts ~ line 5873 ~ isDayForOrder ~ dietTypesGroupsArrWithPersonalemad", dietTypesGroupsArrWithPersonalemad)

      dietTypeGroupsArr = dishAllocationsOrderDayCopy['mainDishes'][itemMainDishes][
        'dietTypeGroups'
      ].filter((el) => {
        if (
          (el['dietTypeGroup']['name'] != 'Fuldkost'
            || (el['dietTypeGroup'].id == 1
                && (curItemMainDishesIndex >= indexStartSideDishForCurDay)
                && !this.isContainPersonalemadArrDietTypes(el)
            )
          )
          && el['dietTypeGroup']['name'] != 'Energitæt'
          && el['dietTypeGroup']['name'] != 'Blød'
        ) {
          return true;
        } else {
          return false;
        }
      });
      if (mainMenuLineDietTypeNr1LineNumber !== undefined) {
        for (let itemDietGroup in dietTypeGroupsArr){
          dietTypeGroupsArr[itemDietGroup]['dietTypes'] = dietTypeGroupsArr[itemDietGroup]['dietTypes'].filter(
            (itemDietTypes) => {
              if (itemDietTypes['menuLine']['dietTypeNr'] == 1
                && itemDietTypes['menuLine']['line'] == mainMenuLineDietTypeNr1LineNumber) {
                return false;
              } else {
                return true;
              }
            }
          )
        }
      }

      //--Add dietTypesGroupsArr With Personalemad lines
      dietTypeGroupsArr = dietTypesGroupsArrWithPersonalemad.concat(dietTypeGroupsArr);

      filteredDietTypeGroups['mainDishes'][itemMainDishes][
        'dietTypeGroups'
      ] = JSON.parse(JSON.stringify(dietTypeGroupsArr));
    }

    console.debug('filteredDietTypeGroups', filteredDietTypeGroups);
    return filteredDietTypeGroups;
  }

  subNumberFuldkost() {
    let row = 0;
    let menuLineNumber = 0;
    console.debug('In subNumberFuldkost');
    let quantity = 0;
    if (true) {
      if (this.contentFuldkostAntalNew == 2) {
        quantity = this.contentFuldkostAntalNew - 2;
      } else {
        quantity = this.contentFuldkostAntalNew - 1;
      }
    } else {
      quantity = this.contentFuldkostAntalNew - 1;
    }

    if (quantity < 0) {
      quantity = 0;
    }
    this.updateQuantityFuldkost(quantity);
  }

  addNumberFuldkost() {
    let row = 0;
    let menuLineNumber = 0;
    console.debug('[addNumberFuldkost] In addNumberEnergitat');
    let quantity = 0;
    if (true) {
      if (this.contentFuldkostAntalNew == 0) {
        quantity = this.contentFuldkostAntalNew + 2;
      } else {
        quantity = this.contentFuldkostAntalNew + 1;
      }
    } else {
      quantity = this.contentFuldkostAntalNew + 1;
    }

    this.updateQuantityFuldkost(quantity);
  }

  subNumberFilteredFuldkost(
    selectedKodFuldkost: string,
    selectedStivelseFuldkost: string
  ) {
    let row = 0;
    let menuLineNumber = 0;
    let quantity = 0;
    console.debug('[subNumberFilteredFuldkost] In subNumberFilteredFuldkost');

    let value = this.modalKodCategoryMap.get(selectedKodFuldkost);
    for (let itemTibehor in value) {
      if (value[itemTibehor]['tibehorCategory'] == selectedStivelseFuldkost) {
        //if(this.isSpecialDiets(dietTypeLine, dietTypeName) == true){
        if (true) {
          if (value[itemTibehor]['tibehorQuantity'] == 2) {
            quantity = value[itemTibehor]['tibehorQuantity'] - 2;
          } else {
            quantity = value[itemTibehor]['tibehorQuantity'] - 1;
          }
        } else {
          quantity = value[itemTibehor]['tibehorQuantity'] - 1;
        }

        if (quantity < 0) {
          quantity = 0;
        }
        value[itemTibehor]['tibehorQuantity'] = quantity;

      }
    }
    //this.modalKodCategoryMap.set(this.selectedKodFuldkost, value);
    this.modalKodCategoryMap.set(selectedKodFuldkost, value);
    console.debug(
      '[subNumberFilteredFuldkost] this.modalKodCategoryMap',
      this.modalKodCategoryMap
    );
  }

  addNumberFilteredFuldkost(
    selectedKodFuldkost: string,
    selectedStivelseFuldkost: string
  ) {
    let row = 0;
    let menuLineNumber = 0;

    let value = this.modalKodCategoryMap.get(selectedKodFuldkost);
    for (let itemTibehor in value) {
      if (value[itemTibehor]['tibehorCategory'] == selectedStivelseFuldkost) {
        //let quantity=0;
        //if(this.isSpecialDiets(dietTypeLine, dietTypeName) == true){
        if (true) {
          if (value[itemTibehor]['tibehorQuantity'] == 0) {
            value[itemTibehor]['tibehorQuantity'] =
              value[itemTibehor]['tibehorQuantity'] + 2;
          } else {
            value[itemTibehor]['tibehorQuantity'] =
              value[itemTibehor]['tibehorQuantity'] + 1;
          }
        } else {
          value[itemTibehor]['tibehorQuantity'] =
            value[itemTibehor]['tibehorQuantity'] + 1;
        }

      }
    }
    //this.modalKodCategoryMap.set(this.selectedKodFuldkost, value);
    this.modalKodCategoryMap.set(selectedKodFuldkost, value);
    console.debug(
      '[addNumberFilteredFuldkost] this.modalKodCategoryMap',
      this.modalKodCategoryMap
    );
  }

  subNumberLineFuldkost(subcat: string, row: number) {
    console.debug('In subNumberLineFuldkost');
    switch (subcat) {
      case 'Kød':
        let quantityKod = this.subLinesSetKodArr[row]['3'] - 1;
        if (quantityKod < 0) {
          quantityKod = 0;
        }
        this.updateQuantityLinesFuldkos(quantityKod, subcat, row);
        break;
      case 'Tilbehør':
        let quantityTilbehor = this.subLinesSetTilbehorArr[row]['3'] - 1;
        if (quantityTilbehor < 0) {
          quantityTilbehor = 0;
        }
        this.updateQuantityLinesFuldkos(quantityTilbehor, subcat, row);

        break;
      case 'Grønt':
        let quantityGront = this.subLinesSetGrontArr[row]['3'] - 1;
        if (quantityGront < 0) {
          quantityGront = 0;
        }
        this.updateQuantityLinesFuldkos(quantityGront, subcat, row);

        break;
      case 'Sovs':
        let quantitySovs = this.subLinesSetSovsArr[row]['3'] - 1;
        if (quantitySovs < 0) {
          quantitySovs = 0;
        }
        this.updateQuantityLinesFuldkos(quantitySovs, subcat, row);

        break;
      default: {
      }
    }
  }

  addNumberLineFuldkost(subcat: string, row: number) {
    console.debug('In addNumberLineFuldkost');
    switch (subcat) {
      case 'Kød':
        let quantityKod = this.subLinesSetKodArr[row]['3'] + 1;

        this.updateQuantityLinesFuldkos(quantityKod, subcat, row);
        break;
      case 'Tilbehør':
        let quantityTilberhor = this.subLinesSetTilbehorArr[row]['3'] + 1;

        this.updateQuantityLinesFuldkos(quantityTilberhor, subcat, row);

        break;
      case 'Grønt':
        let quantityGront = this.subLinesSetGrontArr[row]['3'] + 1;

        this.updateQuantityLinesFuldkos(quantityGront, subcat, row);

        break;
      case 'Sovs':
        let quantitySovs = this.subLinesSetSovsArr[row]['3'] + 1;

        this.updateQuantityLinesFuldkos(quantitySovs, subcat, row);

        break;
      default: {
      }
    }
  }

  subNumberLineFuldkostEnkel(row: number) {
    console.debug('In subNumberLineFuldkostEnkel');

    let quantity = this.subLinesSetArrEnkel[row]['3'] - 1;
    if (quantity < 0) {
      quantity = 0;
    }
    this.updateQuantityLinesFuldkosEnkel(quantity, row);
  }

  addNumberLineFuldkostEnkel(row: number) {
    console.debug('In addNumberLineFuldkostEnkel');
    let quantity = this.subLinesSetArrEnkel[row]['3'] + 1;

    this.updateQuantityLinesFuldkosEnkel(quantity, row);
  }

  subNumberEnergitat(row: number, menuLineNumber: number) {
    console.debug('In subNumberFuldkost');
    let quantity = 0;

    console.debug('[subNumberEnergitat]');

    if (
      this.isSpecialDiets(
        this.currentItemMenuLines['dietTypeGroups'][1]['dietTypes'][
          menuLineNumber
        ]['menuLine']['line'],
        this.currentItemMenuLines['dietTypeGroups'][1]['dietTypes'][
          menuLineNumber
        ]['menuLine']['dietTypeName']
      ) == true
    ) {
      if (this.contentEnergitatAntalArr[row][menuLineNumber] == 2) {
        quantity = this.contentEnergitatAntalArr[row][menuLineNumber] - 2;
      } else {
        quantity = this.contentEnergitatAntalArr[row][menuLineNumber] - 1;
      }
    } else {
      quantity = this.contentEnergitatAntalArr[row][menuLineNumber] - 1;
    }

    if (quantity < 0) {
      quantity = 0;
    }
    this.updateQuantityEnergitat(quantity, row, menuLineNumber);
  }

  addNumberEnergitat(row: number, menuLineNumber: number) {
    console.debug('In addNumberEnergitat');
    let quantity = 0;

    if (
      this.isSpecialDiets(
        this.currentItemMenuLines['dietTypeGroups'][1]['dietTypes'][
          menuLineNumber
        ]['menuLine']['line'],
        this.currentItemMenuLines['dietTypeGroups'][1]['dietTypes'][
          menuLineNumber
        ]['menuLine']['dietTypeName']
      ) == true
    ) {
      if (this.contentEnergitatAntalArr[row][menuLineNumber] == 0) {
        quantity = this.contentEnergitatAntalArr[row][menuLineNumber] + 2;
      } else {
        quantity = this.contentEnergitatAntalArr[row][menuLineNumber] + 1;
      }
    } else {
      quantity = this.contentEnergitatAntalArr[row][menuLineNumber] + 1;
    }

    this.updateQuantityEnergitat(quantity, row, menuLineNumber);
  }

  subNumberBlod(row: number, menuLineNumber: number) {
    console.log('a')

    let quantity = 0;
    if (
      this.isSpecialDiets(
        this.currentItemMenuLines['dietTypeGroups'][
          this.curIndexDietTypeGroupsBlod
        ]['dietTypes'][menuLineNumber]['menuLine']['line'],
        this.currentItemMenuLines['dietTypeGroups'][
          this.curIndexDietTypeGroupsBlod
        ]['dietTypes'][menuLineNumber]['menuLine']['dietTypeName']
      ) == true
    ) {
      
      console.log('b')
      if (this.contentBlodAntalArr[row][menuLineNumber] == 2) {
        quantity = this.contentBlodAntalArr[row][menuLineNumber] - 2;
      } else {
        quantity = this.contentBlodAntalArr[row][menuLineNumber] - 1;
      }
    } else {
      quantity = this.contentBlodAntalArr[row][menuLineNumber] - 1;
    }

    console.log('c')
    if (quantity < 0) {
      quantity = 0;
    }
    this.updateQuantityBlod(quantity, row, menuLineNumber);
  }

  addNumberBlod(row: number, menuLineNumber: number) {
    console.debug('In addNumberBlod');
    let quantity = 0;
    if (
      this.isSpecialDiets(
        this.currentItemMenuLines['dietTypeGroups'][
          this.curIndexDietTypeGroupsBlod
        ]['dietTypes'][menuLineNumber]['menuLine']['line'],
        this.currentItemMenuLines['dietTypeGroups'][
          this.curIndexDietTypeGroupsBlod
        ]['dietTypes'][menuLineNumber]['menuLine']['dietTypeName']
      ) == true
    ) {
      //if(true){
      if (this.contentBlodAntalArr[row][menuLineNumber] == 0) {
        quantity = this.contentBlodAntalArr[row][menuLineNumber] + 2;
      } else {
        quantity = this.contentBlodAntalArr[row][menuLineNumber] + 1;
      }
    } else {
      quantity = this.contentBlodAntalArr[row][menuLineNumber] + 1;
    }

    this.updateQuantityBlod(quantity, row, menuLineNumber);
  }

  subNumberAndet(row: number, indexTypeGroups: number, menuLineNumber: number) {
    console.debug('In subNumberAndet');
    let quantity = 0;
    if (
      this.isSpecialDiets(
        this.currentItemMenuLines['dietTypeGroups'][indexTypeGroups][
          'dietTypes'
        ][menuLineNumber]['menuLine']['line'],
        this.currentItemMenuLines['dietTypeGroups'][indexTypeGroups][
          'dietTypes'
        ][menuLineNumber]['menuLine']['dietTypeName']
      ) == true
    ) {
      //if(true){
      if (
        this.contentAndetAntalArr[row][indexTypeGroups][menuLineNumber] == 2
      ) {
        quantity =
          this.contentAndetAntalArr[row][indexTypeGroups][menuLineNumber] - 2;
      } else {
        quantity =
          this.contentAndetAntalArr[row][indexTypeGroups][menuLineNumber] - 1;
      }
    } else {
      quantity =
        this.contentAndetAntalArr[row][indexTypeGroups][menuLineNumber] - 1;
    }

    if (quantity < 0) {
      quantity = 0;
    }
    this.updateQuantityAndet(quantity, row, indexTypeGroups, menuLineNumber);
  }

  addNumberAndet(row: number, indexTypeGroups: number, menuLineNumber: number) {
    console.debug('In addNumberAndet');
    let quantity = 0;
    if (
      this.isSpecialDiets(
        this.currentItemMenuLines['dietTypeGroups'][indexTypeGroups][
          'dietTypes'
        ][menuLineNumber]['menuLine']['line'],
        this.currentItemMenuLines['dietTypeGroups'][indexTypeGroups][
          'dietTypes'
        ][menuLineNumber]['menuLine']['dietTypeName']
      ) == true
    ) {
      //if(true){
      if (
        this.contentAndetAntalArr[row][indexTypeGroups][menuLineNumber] == 0
      ) {
        quantity =
          this.contentAndetAntalArr[row][indexTypeGroups][menuLineNumber] + 2;
      } else {
        quantity =
          this.contentAndetAntalArr[row][indexTypeGroups][menuLineNumber] + 1;
      }
    } else {
      quantity =
        this.contentAndetAntalArr[row][indexTypeGroups][menuLineNumber] + 1;
    }

    this.updateQuantityAndet(quantity, row, indexTypeGroups, menuLineNumber);
  }

  subNumberAndet2(row: number, menuLineNumber: number) {
    console.debug('In subNumberAndet');
    let quantity = 0;
    if (true) {
      if (this.contentAndetAntalArr2[row][menuLineNumber] == 2) {
        quantity = this.contentAndetAntalArr2[row][menuLineNumber] - 2;
      } else {
        quantity = this.contentAndetAntalArr2[row][menuLineNumber] - 1;
      }
    } else {
      quantity = this.contentAndetAntalArr2[row][menuLineNumber] - 1;
    }

    if (quantity < 0) {
      quantity = 0;
    }
    this.updateQuantityAndet2(quantity, row, menuLineNumber);
  }

  addNumberAndet2(row: number, menuLineNumber: number) {
    console.debug('In addNumberAndet');
    let quantity = 0;
    if (true) {
      if (this.contentAndetAntalArr2[row][menuLineNumber] == 0) {
        quantity = this.contentAndetAntalArr2[row][menuLineNumber] + 2;
      } else {
        quantity = this.contentAndetAntalArr2[row][menuLineNumber] + 1;
      }
    } else {
      quantity = this.contentAndetAntalArr2[row][menuLineNumber] + 1;
    }

    this.updateQuantityAndet2(quantity, row, menuLineNumber);
  }

  updateQuantityFuldkost(quantity: number) {
    console.debug('[updateQuantityFuldkost] updateQuantity=' + quantity);
    if (true) {
      if (quantity == 1) {
        quantity = 0;
      }
    }

    this.contentFuldkostAntalNew = Number(quantity);
    console.debug(
      '[updateQuantityFuldkost] this.modalKodCategoryMap',
      this.modalKodCategoryMap
    );
  }

  updateQuantityFilteredFuldkost(
    quantity: number,
    selectedKodFuldkost: string,
    selectedStivelseFuldkost: string
  ) {
    console.debug('[updateQuantityFuldkost] updateQuantity=' + quantity);
    if (true) {
      if (quantity == 1) {
        quantity = 0;
      }
    }
    let value = this.modalKodCategoryMap.get(selectedKodFuldkost);
    for (let itemTibehor in value) {
      if (value[itemTibehor]['tibehorCategory'] == selectedStivelseFuldkost) {
        value[itemTibehor]['tibehorQuantity'] = Number(quantity);

      }
    }
    //this.modalKodCategoryMap.set(this.selectedKodFuldkost, value);
    this.modalKodCategoryMap.set(selectedKodFuldkost, value);
    console.debug(
      '[updateQuantityFuldkost] this.modalKodCategoryMap',
      this.modalKodCategoryMap
    );
  }

  updateQuantityLinesFuldkos(quantity: number, subcat: string, row: number) {
    switch (subcat) {
      case 'Kød':
        this.subLinesSetKodArr[row]['3'] = Number(quantity);
        console.debug('this.subLinesSetKodArr', this.subLinesSetKodArr);
        break;
      case 'Tilbehør':
        this.subLinesSetTilbehorArr[row]['3'] = Number(quantity);
        console.debug(
          'this.subLinesSetTilbehorArr',
          this.subLinesSetTilbehorArr
        );

        break;
      case 'Grønt':
        this.subLinesSetGrontArr[row]['3'] = Number(quantity);
        console.debug('this.subLinesSetGrontArr', this.subLinesSetGrontArr);

        break;
      case 'Sovs':
        this.subLinesSetSovsArr[row]['3'] = Number(quantity);
        console.debug('this.subLinesSetSovsArr', this.subLinesSetSovsArr);

        break;
      default: {
      }
    }
  }

  updateQuantityLinesFuldkosEnkel(quantity: number, row: number) {
    this.subLinesSetArrEnkel[row]['3'] = Number(quantity);
    console.debug('this.subLinesSetArrEnkel', this.subLinesSetArrEnkel);
  }

  updateQuantityEnergitat(
    quantity: number,
    row: number,
    menuLineNumber: number
  ) {
    console.debug('menuLineNumber', menuLineNumber);
    console.debug('updateQuantity=' + quantity);
    if (
      this.isSpecialDiets(
        this.currentItemMenuLines['dietTypeGroups'][1]['dietTypes'][
          menuLineNumber
        ]['menuLine']['line'],
        this.currentItemMenuLines['dietTypeGroups'][1]['dietTypes'][
          menuLineNumber
        ]['menuLine']['dietTypeName']
      ) == true
    ) {
      //if(true){
      if (quantity == 1) {
        quantity = 0;
      }
    }
    this.contentEnergitatAntalArr[row][menuLineNumber] = Number(quantity);
    console.debug(
      'this.contentEnergitatAntalArr',
      this.contentEnergitatAntalArr
    );
  }

  updateQuantityBlod(quantity: number, row: number, menuLineNumber: number) {
    console.debug('menuLineNumberBlod', menuLineNumber);
    console.debug('updateQuantityBlod=' + quantity);
    if (
      this.isSpecialDiets(
        this.currentItemMenuLines['dietTypeGroups'][
          this.curIndexDietTypeGroupsBlod
        ]['dietTypes'][menuLineNumber]['menuLine']['line'],
        this.currentItemMenuLines['dietTypeGroups'][
          this.curIndexDietTypeGroupsBlod
        ]['dietTypes'][menuLineNumber]['menuLine']['dietTypeName']
      ) == true
    ) {
      //if(true){
      if (quantity == 1) {
        quantity = 0;
      }
    }
    this.contentBlodAntalArr[row][menuLineNumber] = Number(quantity);
    console.debug('this.contentBlodAntalArr', this.contentBlodAntalArr);
  }
  updateQuantityAndet(
    quantity: number,
    row: number,
    indexTypeGroups: number,
    menuLineNumber: number
  ) {
    if (
      this.isSpecialDiets(
        this.currentItemMenuLines['dietTypeGroups'][indexTypeGroups][
          'dietTypes'
        ][menuLineNumber]['menuLine']['line'],
        this.currentItemMenuLines['dietTypeGroups'][indexTypeGroups][
          'dietTypes'
        ][menuLineNumber]['menuLine']['dietTypeName']
      ) == true
    ) {
      //if(true){
      if (quantity == 1) {
        quantity = 0;
      }
    }
    this.contentAndetAntalArr[row][indexTypeGroups][menuLineNumber] = Number(
      quantity
    );
    console.debug(
      'this.contentAndetAntalArr',
      JSON.stringify(this.contentAndetAntalArr)
    );
  }

  updateQuantityAndet2(quantity: number, row: number, menuLineNumber: number) {
    console.debug('menuLineNumberAndet', menuLineNumber);
    console.debug('updateQuantityAndet=' + quantity);
    if (true) {
      if (quantity == 1) {
        quantity = 0;
      }
    }
    this.contentAndetAntalArr2[row][menuLineNumber] = Number(quantity);
    console.debug(
      'this.contentAndetAntalArr',
      JSON.stringify(this.contentAndetAntalArr2)
    );
  }

  updateMenuLineBtKodTilbehor(
    itemMainDishes: any,
    itemMeat: string,
    itemStarch: string,
    productNr: number,
    quantity: number,
    day: string
  ) {
    console.debug(
      '[updateMenuLineBtKodTilbehor] itemMainDishes',
      itemMainDishes
    );
    console.debug('[updateMenuLineBtKodTilbehor] itemMeat', itemMeat);
    console.debug('[updateMenuLineBtKodTilbehor] itemStarch', itemStarch);
    console.debug('[updateMenuLineBtKodTilbehor] productNr', productNr);
    console.debug('[updateMenuLineBtKodTilbehor] quantity', quantity);

    let itemMenuLine;
    for (let itemMainDishes in this.dishAllocationsOrderDays[day][
      'mainDishes'
    ]) {
      for (let itemDietTypes in this.dishAllocationsOrderDays[day][
        'mainDishes'
      ][itemMainDishes]['dietTypeGroups'][0]['dietTypes']) {
        if (
          this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes][
            'dietTypeGroups'
          ][0]['dietTypes'][itemDietTypes] &&
          this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes][
            'dietTypeGroups'
          ][0]['dietTypes'][itemDietTypes]['menuLine']['productNr'] ==
            productNr &&
          this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes][
            'dietTypeGroups'
          ][0]['dietTypes'][itemDietTypes]['dietType']['meat'] == itemMeat &&
          this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes][
            'dietTypeGroups'
          ][0]['dietTypes'][itemDietTypes]['dietType']['starch'] == itemStarch
        ) {
          itemMenuLine = [];
          this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes][
            'dietTypeGroups'
          ][0]['dietTypes'][itemDietTypes]['menuLine']['quantity'] = Number(
            quantity
          );

          console.debug(
            '[updateMenuLineBtKodTilbehor] this.dishAllocationsOrderDays[mainDishes][itemMainDishes]',
            this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes][
              'dietTypeGroups'
            ][0]['dietTypes'][itemDietTypes]['menuLine']
          );
          itemMenuLine = JSON.parse(
            JSON.stringify(
              this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes][
                'dietTypeGroups'
              ][0]['dietTypes'][itemDietTypes]['menuLine']
            )
          );
          console.debug(
            '[updateMenuLineBtKodTilbehor] itemMenuLine',
            itemMenuLine
          );
          return itemMenuLine;
        }
      }
    }
  }

  findBtKodTilbehorFromMenuLine(
    itemMainDishes: any,
    itemMeat: string,
    itemStarch: string,
    productNr: number,
    quantity: number
  ) {
    console.debug(
      '[updateBtKodTilbehorFromMenuLine] itemMainDishes',
      itemMainDishes
    );
    console.debug('[updateBtKodTilbehorFromMenuLine] itemMeat', itemMeat);
    console.debug('[updateBtKodTilbehorFromMenuLine] itemStarch', itemStarch);
    console.debug('[updateBtKodTilbehorFromMenuLine] productNr', productNr);
    console.debug('[updateBtKodTilbehorFromMenuLine] quantity', quantity);

    let itemMenuLine;
    for (let itemDietTypes in itemMainDishes['dietTypeGroups'][0][
      'dietTypes'
    ]) {
      if (
        itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes] &&
        itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes][
          'menuLine'
        ]['productNr'] == productNr &&
        itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes][
          'dietType'
        ]['meat'] == itemMeat &&
        itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes][
          'dietType'
        ]['starch'] == itemStarch
      ) {
        itemMenuLine = [];
        itemMenuLine =
          itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes][
            'menuLine'
          ];
        console.debug('[getMenuLineBtKodTilbehor] itemMenuLine', itemMenuLine);
        return itemMenuLine;
      }
    }
  }

  clearFuldkost() {
    this.contentFuldkostAntal = this.dishAllocationsOrderDays[
      this.currentDayForModal
    ]['mainDishes'][0]['menuLine']['quantity'];

    this.selectedKodFuldkost = '';
    this.modalService.dismissAll();
  }

  saveFuldkost(day: string, itemMainDishes: any) {
    console.debug('[saveFuldkost]');
    this.currentDayForModal = day;


    //-------------------put to LocalStorage Bulk Antal quantity---------------------


    this.modalKodCategoryMap.forEach(function (value, key, ownerSet) {
      for (let itemTibehor in value) {
        let itemSelected = this.findBtKodTilbehorFromMenuLine(
          itemMainDishes,
          key,
          value[itemTibehor]['tibehorCategory'],
          itemMainDishes['dietTypeGroups'][0]['dietTypes'][0]['menuLine'][
            'productNr'
          ],
          0
        );

        itemSelected['quantity'] = value[itemTibehor]['tibehorQuantity'];
        console.debug('[saveFuldkost] itemSelected', itemSelected);
        this.updateSimpleOderModelArray(
          'Editweekplan',
          itemSelected,
          day,
          true
        );
        //value[itemTibehor]["tibehorQuantity"]=100;
      }
    }, this);

    this.contentFuldkostAntalNew = 0;

    //-------------------put to LocalStorage Kod quantity-----------------
    if (this.subLinesSetKodArr.length != 0) {
      for (let row = 0; row < this.subLinesSetKodArr.length; row++) {
        this.updateSimpleOderModelArray(
          'Editweekplan',
          this.updateMenuLineByProductNr(
            itemMainDishes,

            this.fuldkostCategoryArr,
            this.subLinesSetKodArr[row]
          ),

          day,
          true
        );
      }
    }

    //---------------- put to LocalStorage Tilbehor quantity-----------------
    if (this.subLinesSetTilbehorArr.length != 0) {
      console.debug(
        '[saveFuldkost] this.subLinesSetTilbehorArr',
        this.subLinesSetTilbehorArr
      );
      for (let row = 0; row < this.subLinesSetTilbehorArr.length; row++) {
        this.updateSimpleOderModelArray(
          'Editweekplan',
          this.updateMenuLineByProductNr(
            itemMainDishes,
            this.fuldkostCategoryArr,
            this.subLinesSetTilbehorArr[row]
          ),
          day,
          true
        );
      }
    }

    //---------------- put to LocalStorage Gront quantity-----------------
    if (this.subLinesSetGrontArr.length != 0) {
      for (let row = 0; row < this.subLinesSetGrontArr.length; row++) {
        this.updateSimpleOderModelArray(
          'Editweekplan',
          this.updateMenuLineByProductNr(
            itemMainDishes,
            this.fuldkostCategoryArr,
            this.subLinesSetGrontArr[row]
          ),
          day,
          true
        );
      }
    }

    //---------------- put to LocalStorage Sovs quantity-----------------
    if (this.subLinesSetSovsArr.length != 0) {
      for (let row = 0; row < this.subLinesSetSovsArr.length; row++) {
        this.updateSimpleOderModelArray(
          'Editweekplan',
          this.updateMenuLineByProductNr(
            itemMainDishes,
            this.fuldkostCategoryArr,
            this.subLinesSetSovsArr[row]
          ),
          day,
          true
        );
      }
    }

    //-------------------put to LocalStorage Enkel quantity-----------------
    if (this.subLinesSetArrEnkel.length != 0) {
      for (let row = 0; row < this.subLinesSetArrEnkel.length; row++) {
        this.updateSimpleOderModelArray(
          'Editweekplan',
          this.updateMenuLineByProductNr(
            itemMainDishes,
            this.fuldkostCategoryArr,
            this.subLinesSetArrEnkel[row]
          ),
          day,
          true
        );
      }
    }

    console.debug(
      '[saveFuldkost] this.dishAllocationsOrderDaysQuantity',
      this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][0][
        'menuLine'
      ]['quantity']
    );
    console.debug(
      '[saveFuldkost] this.contentFuldkostAntal',
      this.contentFuldkostAntal
    );
    this.dataShareService.canToogleFromPopUp = !this.checkQuantitiesChanged(
      this.simpleOderModelArray
    );
    if (!this.dataShareService.canToogleFromPopUp) {
      //this.deliveryDaysOrdered[day]=true;
      //this.localStorageService.set(this.deliveryDaysOrderedId, this.deliveryDaysOrdered);
      //console.debug("[saveFuldkost] this.deliveryDaysOrdered", this.deliveryDaysOrdered);
    }
    //-----------------------------!!!-------------------------------
    this.isQuantitiesChanged = true;
    //---------------------------------------------------------------
    this.modalService.dismissAll();
  }

  clearEnergitat() {
    this.modalService.dismissAll();
    this.contentEnergitatAntalArr = [];
  }

  saveEnergitat(day: string, itemMainDishes: any) {
    console.debug('[saveEnergitat]');
    this.currentDayForModal = day;

    console.debug(
      '[saveEnergitat] this.contentEnergitatAntalArr',
      this.contentEnergitatAntalArr
    );
    for (let itemMainDishes in this.contentEnergitatAntalArr) {
      console.debug(
        '[saveEnergitat] itemMainDishes',
        this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][
          itemMainDishes
        ]
      );

     // console.log("this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups']")

     console.log("this.dishAllocationsOrderDays")
     console.log(this.dishAllocationsOrderDays)
     console.log("---------------------")

      
     console.log('this.currentDayForModal')
     console.log(this.currentDayForModal)
      
     console.log('itemMainDishes')
     console.log(itemMainDishes)
     
       console.log(this.dishAllocationsOrderDays[
        this.currentDayForModal
       ]['mainDishes'][itemMainDishes]['dietTypeGroups'])
       
       if (!this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][1]){
        continue
       }


      for (let menuLines in this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][1]['dietTypes']) {
        
        this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][1]['dietTypes'][menuLines]['menuLine']['quantity'] = this.contentEnergitatAntalArr[itemMainDishes][menuLines];
          
        this.updateSimpleOderModelArray('Editweekplan',this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][1]['dietTypes'][menuLines]['menuLine'],day,true);
        console.debug('this.dishAllocationsOrderDaysSave',this.dishAllocationsOrderDays);
      
      }

      this.dataShareService.canToogleFromPopUp = !this.checkQuantitiesChanged(
        this.simpleOderModelArray
      );
      if (!this.dataShareService.canToogleFromPopUp) {
        //this.deliveryDaysOrdered[day]=true;
        //this.localStorageService.set(this.deliveryDaysOrderedId, this.deliveryDaysOrdered);
      }
      //-----------------------------!!!-------------------------------
      this.isQuantitiesChanged = true;
      //---------------------------------------------------------------

      this.modalService.dismissAll();
    }
  }

  clearBlod() {
    this.modalService.dismissAll();
    this.contentBlodAntalArr = [];
  }

  saveBlod(day: string, curIndexDietTypeGroupsBlod: number) {
    console.debug('[saveBlod] day', day);
    console.debug(
      '[saveBlod] curIndexDietTypeGroupsBlod',
      curIndexDietTypeGroupsBlod
    );
    this.currentDayForModal = day;

    for (let itemMainDishes in this.contentBlodAntalArr) {
      if (
        this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][
          itemMainDishes
        ]['dietTypeGroups'][curIndexDietTypeGroupsBlod]
      ) {
        for (let menuLines in this.dishAllocationsOrderDays[
          this.currentDayForModal
        ]['mainDishes'][itemMainDishes]['dietTypeGroups'][
          curIndexDietTypeGroupsBlod
        ]['dietTypes']) {

          this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][
            itemMainDishes
          ]['dietTypeGroups'][curIndexDietTypeGroupsBlod]['dietTypes'][
            menuLines
          ]['menuLine']['quantity'] = this.contentBlodAntalArr[itemMainDishes][
            menuLines
          ];
          this.updateSimpleOderModelArray(
            'Editweekplan',
            this.dishAllocationsOrderDays[this.currentDayForModal][
              'mainDishes'
            ][itemMainDishes]['dietTypeGroups'][curIndexDietTypeGroupsBlod][
              'dietTypes'
            ][menuLines]['menuLine'],
            day,
            true
          );
          //console.debug("[saveBlod] this.dishAllocationsOrderDaysSave", this.dishAllocationsOrderDays);
        }
      }
    }
    this.dataShareService.canToogleFromPopUp = !this.checkQuantitiesChanged(
      this.simpleOderModelArray
    );
    if (!this.dataShareService.canToogleFromPopUp) {
      //this.deliveryDaysOrdered[day]=true;
      //this.localStorageService.set(this.deliveryDaysOrderedId, this.deliveryDaysOrdered);
    }

    //-----------------------------!!!-------------------------------
    this.isQuantitiesChanged = true;
    //---------------------------------------------------------------

    this.modalService.dismissAll();
  }

  isMenuLineinAndetCategory(currentItemMenuLinesProductNr: number, menuLine: any): boolean {
    if (currentItemMenuLinesProductNr == menuLine.productNr) {
      return true;
    } else {
      return false;
    }
  }
  clearAndet() {
    this.modalService.dismissAll();
    this.contentAndetAntalArr = [];
  }

  saveAndet(day: string) {
    this.currentDayForModal = day;

    for (let itemMainDishes in this.dishAllDayFilteredDietTypeGroups[day][
      'mainDishes'
    ]) {
      for (let indexDietTypeGroups in this.dishAllDayFilteredDietTypeGroups[
        day
      ]['mainDishes'][itemMainDishes]['dietTypeGroups']) {
        for (let menuLines in this.dishAllDayFilteredDietTypeGroups[day][
          'mainDishes'
        ][itemMainDishes]['dietTypeGroups'][indexDietTypeGroups]['dietTypes']) {
          for (let itemDietTypeGroups in this.dishAllocationsOrderDays[
            this.currentDayForModal
          ]['mainDishes'][itemMainDishes]['dietTypeGroups']) {
            for (let itemDietType in this.dishAllocationsOrderDays[
              this.currentDayForModal
            ]['mainDishes'][itemMainDishes]['dietTypeGroups'][
              itemDietTypeGroups
            ]['dietTypes']) {
              if (
                this.dishAllocationsOrderDays[this.currentDayForModal][
                  'mainDishes'
                ][itemMainDishes]['dietTypeGroups'][itemDietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['dietTypeNr'] ==
                  this.dishAllDayFilteredDietTypeGroups[
                    this.currentDayForModal
                  ]['mainDishes'][itemMainDishes]['dietTypeGroups'][
                    indexDietTypeGroups
                  ]['dietTypes'][menuLines]['menuLine']['dietTypeNr'] &&
                this.dishAllocationsOrderDays[this.currentDayForModal][
                  'mainDishes'
                ][itemMainDishes]['dietTypeGroups'][itemDietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['productNr'] ==
                  this.dishAllDayFilteredDietTypeGroups[
                    this.currentDayForModal
                  ]['mainDishes'][itemMainDishes]['dietTypeGroups'][
                    indexDietTypeGroups
                  ]['dietTypes'][menuLines]['menuLine']['productNr'] &&
                this.dishAllocationsOrderDays[this.currentDayForModal][
                  'mainDishes'
                ][itemMainDishes]['dietTypeGroups'][itemDietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine']['line'] ==
                  this.dishAllDayFilteredDietTypeGroups[
                    this.currentDayForModal
                  ]['mainDishes'][itemMainDishes]['dietTypeGroups'][
                    indexDietTypeGroups
                  ]['dietTypes'][menuLines]['menuLine']['line']
              ) {
                this.dishAllocationsOrderDays[this.currentDayForModal][
                  'mainDishes'
                ][itemMainDishes]['dietTypeGroups'][itemDietTypeGroups][
                  'dietTypes'
                ][itemDietType]['menuLine'][
                  'quantity'
                ] = this.contentAndetAntalArr[itemMainDishes][
                  indexDietTypeGroups
                ][menuLines];
                this.dishAllDayFilteredDietTypeGroups[this.currentDayForModal][
                  'mainDishes'
                ][itemMainDishes]['dietTypeGroups'][indexDietTypeGroups][
                  'dietTypes'
                ][menuLines]['menuLine'][
                  'quantity'
                ] = this.contentAndetAntalArr[itemMainDishes][
                  indexDietTypeGroups
                ][menuLines];
                this.updateSimpleOderModelArray(
                  'Editweekplan',
                  this.dishAllocationsOrderDays[this.currentDayForModal][
                    'mainDishes'
                  ][itemMainDishes]['dietTypeGroups'][itemDietTypeGroups][
                    'dietTypes'
                  ][itemDietType]['menuLine'],
                  day,
                  true
                );
              }
            }
          }
        }
      }

      console.debug(
        '[saveAndet] this.dishAllDayFilteredDietTypeGroups',
        this.dishAllDayFilteredDietTypeGroups
      );
      console.debug(
        '[saveAndet] this.dishAllocationsOrderDaysSave',
        this.dishAllocationsOrderDays
      );
    }

    this.dataShareService.canToogleFromPopUp = !this.checkQuantitiesChanged(
      this.simpleOderModelArray
    );
    if (!this.dataShareService.canToogleFromPopUp) {
      //this.deliveryDaysOrdered[day]=true;
      //this.localStorageService.set(this.deliveryDaysOrderedId, this.deliveryDaysOrdered);
    }

    //-----------------------------!!!-------------------------------
    this.isQuantitiesChanged = true;
    //---------------------------------------------------------------

    this.modalService.dismissAll();
  }

  //-------------Modal Fildkost---------------------------------------------------

  selectKodFuldkost(kod: any) {
    this.selectedKodFuldkost = kod;
    this.currentTilbehorArr = Array.from(this.modalKodCategoryMap.get(kod));
    this.defaultTilbehor = this.currentTilbehorArr[0]['tibehorCategory'];

    let value = this.modalKodCategoryMap.get(this.selectedKodFuldkost);
    for (let itemTibehor in value) {
      if (
        value[itemTibehor]['tibehorCategory'] == this.selectedStivelseFuldkost
      ) {
        this.contentFuldkostAntalNew = value[itemTibehor]['tibehorQuantity'];
      }
    }

  }

  selectStivelseFuldkost(stivelse: any) {
    this.selectedStivelseFuldkost = stivelse;

    let value = this.modalKodCategoryMap.get(this.selectedKodFuldkost);
    for (let itemTibehor in value) {
      if (
        value[itemTibehor]['tibehorCategory'] == this.selectedStivelseFuldkost
      ) {
        this.contentFuldkostAntalNew = value[itemTibehor]['tibehorQuantity'];
      }
    }

  }

  checkTibehorFuldkost(event: any) {

  }

  sumAndet(
    energitatQuontityAndet1: number,
    energitatQuontityAndet2: number
  ): number {
    return Number(energitatQuontityAndet1) + Number(energitatQuontityAndet2);
  }

  sumTd(itemDietTypes: any, day: string, j: number, cat: number): number {

    var sumItemDietTypes = 0;
    for (var itemDietType in itemDietTypes) {
     sumItemDietTypes += Number(
        itemDietTypes[itemDietType]['menuLine']['quantity']
      );
    }

   this.sumMatrixForDays[day][j][cat] = Number(sumItemDietTypes);
    return Number(sumItemDietTypes);
  }
  sumTdFromResponse(itemDietTypes: any): number {
    var sumItemDietTypes = 0;
    for (var itemDietType in itemDietTypes) {
     sumItemDietTypes += Number(
        itemDietTypes[itemDietType]['menuLine']['quantity']
      );
    }
    return Number(sumItemDietTypes);
  }
  isMenuLineDietTyeNr1InAndet(
    line: number,
    dishAllDayFilteredDietTypeGroups: any,
    day: string,
    j: number
  ): boolean {
    let isLineExistsInAndet: boolean = false;
    if (dishAllDayFilteredDietTypeGroups[day] && dishAllDayFilteredDietTypeGroups[day]['mainDishes']) {
        for (let itemdietTypeGroups in dishAllDayFilteredDietTypeGroups[day]['mainDishes'][j]['dietTypeGroups']){
        for (let itemDietTypes in  dishAllDayFilteredDietTypeGroups[day]['mainDishes'][j]['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){
          if (dishAllDayFilteredDietTypeGroups[day]['mainDishes'][j]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietTypes]['menuLine']['dietTypeNr'] == 1
            && dishAllDayFilteredDietTypeGroups[day]['mainDishes'][j]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietTypes]['menuLine']['line'] == line) {
            isLineExistsInAndet = true;
          }
        }
      }
    }
    return isLineExistsInAndet;
  }
  sumTdFuldkost(
    itemDietTypes: any,
    day: string,
    j: number,
    cat: number
  ): number {
    let startRangeForDietTypeNr1NotLine1 = 801;
    let starchRange = [
      [122, 129],
      [272, 279],
      [422, 429],
      [572, 579],
      [722, 729],
      [132, 140],
      [282, 290],
      [432, 440],
      [582, 590],
      [732, 740],
      [144, 147],
      [294, 297],
      [444, 447],
      [594, 597],
      [744, 747],
      [141],
      [291],
      [441],
      [591],
      [741],
    ];


    let sumItemDietTypes = 0;
    for (let itemDietType in itemDietTypes) {
      let isPersonalBulk = this.isLineInPersonalemadBulkArr(itemDietTypes[itemDietType]['menuLine']['line']);
      let isPersonalEnkelt = this.isLineInPersonalemadEnkeltArr(itemDietTypes[itemDietType]['menuLine']['line']);
      if (
        !(isPersonalBulk || isPersonalEnkelt)
          &&
        !(
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[0][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[0][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[1][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[1][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[2][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[2][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[3][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[3][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[4][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[4][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[5][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[5][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[6][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[6][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[7][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[7][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[8][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[8][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[9][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[9][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[10][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[10][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[11][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[11][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[12][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[12][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[13][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[13][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[14][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[14][1]) ||
          itemDietTypes[itemDietType]['menuLine']['line'] == starchRange[15] ||
          itemDietTypes[itemDietType]['menuLine']['line'] == starchRange[16] ||
          itemDietTypes[itemDietType]['menuLine']['line'] == starchRange[17] ||
          itemDietTypes[itemDietType]['menuLine']['line'] == starchRange[18] ||
          itemDietTypes[itemDietType]['menuLine']['line'] == starchRange[19] ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >= startRangeForDietTypeNr1NotLine1
           && this.isMenuLineDietTyeNr1InAndet(
              itemDietTypes[itemDietType]['menuLine']['line'],
              this.dishAllDayFilteredDietTypeGroups,
              day,
              j
            ))
        )
      ) {
        sumItemDietTypes += Number(
          itemDietTypes[itemDietType]['menuLine']['quantity']
        );
      }
    }

    this.sumMatrixForDays[day][j][cat] = Number(sumItemDietTypes);

    return Number(sumItemDietTypes);
  }

  sumTdFuldkostFromResponse(itemDietTypes: any, day: string, j: number): number {
    let startRangeForDietTypeNr1NotLine1 = 801;
    let starchRange = [
      [122, 129],
      [272, 279],
      [422, 429],
      [572, 579],
      [722, 729],
      [132, 140],
      [282, 290],
      [432, 440],
      [582, 590],
      [732, 740],
      [144, 147],
      [294, 297],
      [444, 447],
      [594, 597],
      [744, 747],
      [141],
      [291],
      [441],
      [591],
      [741],
    ];
    let sumItemDietTypes = 0;
    for (let itemDietType in itemDietTypes) {
      if (
        !(
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[0][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[0][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[1][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[1][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[2][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[2][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[3][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[3][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[4][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[4][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[5][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[5][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[6][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[6][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[7][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[7][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[8][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[8][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[9][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[9][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[10][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[10][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[11][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[11][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[12][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[12][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[13][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[13][1]) ||
          (itemDietTypes[itemDietType]['menuLine']['line'] >=
            starchRange[14][0] &&
            itemDietTypes[itemDietType]['menuLine']['line'] <=
              starchRange[14][1]) ||
          itemDietTypes[itemDietType]['menuLine']['line'] == starchRange[15] ||
          itemDietTypes[itemDietType]['menuLine']['line'] == starchRange[16] ||
          itemDietTypes[itemDietType]['menuLine']['line'] == starchRange[17] ||
          itemDietTypes[itemDietType]['menuLine']['line'] == starchRange[18] ||
          itemDietTypes[itemDietType]['menuLine']['line'] == starchRange[19] ||
           (itemDietTypes[itemDietType]['menuLine']['line'] >= startRangeForDietTypeNr1NotLine1
           && this.isMenuLineDietTyeNr1InAndet(
              itemDietTypes[itemDietType]['menuLine']['line'],
              this.dishAllDayFilteredDietTypeGroups,
              day,
              j
            ))
        )
      ) {
        sumItemDietTypes += Number(
          itemDietTypes[itemDietType]['menuLine']['quantity']
        );
      }
    }

    return Number(sumItemDietTypes);
  }
  getSumFuldkostAll() {}

  sumTdAndet(
    itemDietTypes: any,
    day: string,
    j: number,
    cat: number,
    indexDietTypes?: number
  ): number {
    var sumItemDietTypes = 0;
    for (let itemDietType in itemDietTypes) {
      sumItemDietTypes = Number(
        itemDietTypes[indexDietTypes]['menuLine']['quantity']
      );
    }

    let sumAllfilteredItemDietTypes = 0;
    for (let itemDietTypeGroups in this.dishAllDayFilteredDietTypeGroups[day][
      'mainDishes'
    ][j]['dietTypeGroups']) {
      for (let itemDietTypes in this.dishAllDayFilteredDietTypeGroups[day][
        'mainDishes'
      ][j]['dietTypeGroups'][itemDietTypeGroups]['dietTypes']) {
        sumAllfilteredItemDietTypes += this.dishAllDayFilteredDietTypeGroups[
          day
        ]['mainDishes'][j]['dietTypeGroups'][itemDietTypeGroups]['dietTypes'][
          itemDietTypes
        ]['menuLine']['quantity'];
      }
    }

    this.sumMatrixForDays[day][j][cat] = Number(sumAllfilteredItemDietTypes);


    return Number(sumItemDietTypes);
  }

  sumRow(itemDdietTypeGroups: any): number {

    let fuldkostQuontity =
      itemDdietTypeGroups[0]['dietTypes'][0]['menuLine']['quantity'];
    var sumEnergitatQuontity = 0;
    for (var itemDietType in itemDdietTypeGroups[1]['dietTypes']) {
      sumEnergitatQuontity += Number(
        itemDdietTypeGroups[1]['dietTypes'][itemDietType]['menuLine'][
          'quantity'
        ]
      );
    }
    var sumBlodQuontity = 0;
    for (var itemDietType in itemDdietTypeGroups[2]['dietTypes']) {
      sumBlodQuontity += Number(
        itemDdietTypeGroups[2]['dietTypes'][itemDietType]['menuLine'][
          'quantity'
        ]
      );
    }

    var sumAndet1Quontity = 0;
    if (
      itemDdietTypeGroups[3]['dietTypes'] !== undefined &&
      itemDdietTypeGroups[3]['dietTypes'][itemDietType] !== undefined &&
      itemDdietTypeGroups[3]['dietTypes'][itemDietType]['menuLine'] !==
        undefined
    ) {
      for (var itemDietType in itemDdietTypeGroups[3]['dietTypes']) {

        sumAndet1Quontity += Number(
          itemDdietTypeGroups[3]['dietTypes'][itemDietType]['menuLine'][
            'quantity'
          ]
        );
      }
    }

    var sumAndet2Quontity = 0;
    if (
      itemDdietTypeGroups[4]['dietTypes'] !== undefined &&
      itemDdietTypeGroups[4]['dietTypes'][itemDietType] !== undefined &&
      itemDdietTypeGroups[4]['dietTypes'][itemDietType]['menuLine'] !==
        undefined
    ) {
      for (var itemDietType in itemDdietTypeGroups[4]['dietTypes']) {

        sumAndet2Quontity += Number(
          itemDdietTypeGroups[4]['dietTypes'][itemDietType]['menuLine'][
            'quantity'
          ]
        );
      }
    }

    return (
      Number(fuldkostQuontity) +
      Number(sumEnergitatQuontity) +
      Number(sumBlodQuontity) +
      Number(sumAndet1Quontity) +
      Number(sumAndet2Quontity)
    );
  }

  sumRowMatrix(day: string, j: number) {
    if (this.sumMatrixForDays !== undefined) {
      let sumRowIalt = 0;
      for (let col = 0; col < 5; col++) {
        sumRowIalt += this.sumMatrixForDays[day][j][col];
      }

      return Number(sumRowIalt);
    } else {
      return 0;
    }
  }

  sumIaltFuldcost(day: string) {
    let sumColIalt = 0;
    for (let i = 0; i < this.sumMatrixForDays[day].length; i++) {
      sumColIalt += this.sumMatrixForDays[day][i][0];
    }
    return Number(sumColIalt);
  }
  sumIaltEnergitat(day: string) {
    let sumColIalt = 0;
    for (let i = 0; i < this.sumMatrixForDays[day].length; i++) {
      sumColIalt += this.sumMatrixForDays[day][i][1];
    }
    return Number(sumColIalt);
  }
  sumIaltBlod(day: string) {
    let sumColIalt = 0;
    for (let i = 0; i < this.sumMatrixForDays[day].length; i++) {
      sumColIalt += this.sumMatrixForDays[day][i][2];
    }
    return Number(sumColIalt);
  }
  sumIaltAndet(day: string) {
    let sumColIalt = 0;
    for (let i = 0; i < this.sumMatrixForDays[day].length; i++) {
      sumColIalt +=
        this.sumMatrixForDays[day][i][3] + this.sumMatrixForDays[day][i][4];
    }
    return Number(sumColIalt);
  }

  toogleModalBulk() {
    this.toogledModalBulk = true;
    this.toogledModalEnkel = false;
    this.modalBtnBackgroundColorBulk = '#5B7B12';
    this.modalBtnBackgroundColorEnkel = '#FFFFFF';
    this.modalBtnTextColorBulk = '#FFFFFF';
    this.modalBtnTextColorEnkel = '#000000';
    this.modalBtnTextWeight = 'bold';
  }

  toogleModalEnkel() {
    this.toogledModalBulk = false;
    this.toogledModalEnkel = true;
    this.modalBtnBackgroundColorBulk = '#FFFFFF';
    this.modalBtnBackgroundColorEnkel = '#5B7B12';
    this.modalBtnTextColorBulk = '#000000';
    this.modalBtnTextColorEnkel = '#FFFFFF';
    this.modalBtnTextWeight = 'normal';
  }

  formMenuLineModelArr() {
    let orderDishModelArr = new Array();

    for (var i = 0; i < this.simpleOderModelArray.length; i++) {
      let orderMenuLineDay = new OrderMenuLineDay(
        new MenuLine(
          this.simpleOderModelArray[i]['dishId'],
          this.simpleOderModelArray[i]['name'],
          this.simpleOderModelArray[i]['line'],
          this.simpleOderModelArray[i]['dietTypeNr'],
          this.simpleOderModelArray[i]['dietTypeName'],
          this.simpleOderModelArray[i]['portionSize'],
          this.simpleOderModelArray[i]['sumOder'],
          this.simpleOderModelArray[i]['enkeltPortion']
        ),
        this.simpleOderModelArray[i]['Day'],
        this.simpleOderModelArray[i]['orderNr'],
        this.simpleOderModelArray[i]['customerNr'],
        this.simpleOderModelArray[i]['menuNr'],
        this.simpleOderModelArray[i]['orderNotPermit']
      );


      orderDishModelArr.push(orderMenuLineDay);
    }
    return orderDishModelArr;
  }

  formBulkOrderModel() {
    let orderDishModelArr = this.formMenuLineModelArr();
    let orderModel = new BulkOrderMenuLineEditModelDays(orderDishModelArr);

    return orderModel;
  }

  doOrder(content) {
    if (this.reqestInProgress == false) {
      this.reqestInProgress = true;

      this.dishRepository
      .sendBulkOrderMenuLine(this.shiftWeek, this.formBulkOrderModel(), this.guestChefModel)
      .subscribe(
        (response) => {

          //---------------------DelivaryDay is saved---------------------------------
          this.openWindowCustomClassDone(content);
          this.disabledAfterOrder = true;

          this.simpleOderModelArray = this.setSendedOrderClearChangedAll(
            this.simpleOderModelArray
          );
          this.localStorageService.set(
            this.simpleOderModelArrayId,
            this.simpleOderModelArray
          );

          //------------------------Add----------------------------------------
          this.simpleOderModelArray = [];
          this.localStorageService.set(
            this.simpleOderModelArrayId,
            this.simpleOderModelArray
          );

          //---------------------------------------------------------------
          this.setTimer(this.delay).subscribe((update) => {
            this.reqestInProgress = false;
            this.modalService.dismissAll();
            this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
          });
        },
        (error) => {
          console.log('The order is invalid');
          //---------------------DelivaryDay is saved---------------------------------

          this.disabledAfterOrder = true;
          this.simpleOderModelArray = this.setSendedOrderClearChangedAll(
            this.simpleOderModelArray
          );
          this.localStorageService.set(
            this.simpleOderModelArrayId,
            this.simpleOderModelArray
          );

          //------------------------Add----------------------------------------
          this.simpleOderModelArray = [];
          this.localStorageService.set(
            this.simpleOderModelArrayId,
            this.simpleOderModelArray
          );
          this.reqestInProgress = false;
          this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
          //---------------------------------------------------------------
        }
      );
    }

  }

  //-----------------------------Save()-----------------------------------------
  save(content) {

    this.dishRepository
      .sendBulkOrderMenuLine(this.shiftWeek, this.formBulkOrderModel(), this.guestChefModel)
      /* .retryWhen(errors$ =>
          errors$.scan((errorCount, err) => {
              if(errorCount >= this.maxRetries) {
          throw err;
              }
              return errorCount + 1;
          }, 0)
          )*/
      .subscribe(
        (response) => {
          this.disabledAfterOrder = true;

          this.simpleOderModelArray = this.setSendedOrderClearChangedAll(
            this.simpleOderModelArray
          );
          this.localStorageService.set(
            this.simpleOderModelArrayId,
            this.simpleOderModelArray
          );
          this.isQuantitiesChanged = false;
          //------------------------Add----------------------------------------
          this.simpleOderModelArray = [];
          this.localStorageService.set(
            this.simpleOderModelArrayId,
            this.simpleOderModelArray
          );
          this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
          //---------------------------------------------------------------
        },
        (error) => {
          console.log('The order is invalid');
        }
      );
  }
//------------------------------Go to Byg-----------------------------------------
  goToByg() {
    this.router.navigateByUrl('/minmenu/bulkorder/viewweekplan');

  }
  //---------------------------Go for Print View---------------------------------

  goPrintTableDay() {

    let toggledDay = this.localStorageService.get('toggledDayBulkOrder');
    this.localStorageService.set('toggledPrintTableDayBulkOrder', toggledDay);
    this.navigationService.goToLinkInNewTab(
      'minmenu/bulkorder/printweekplan/printtableveiw'
    );
  }

  setTimer(interval: number): Observable<number> {
    return timer(interval);
  }
  openWindowCustomClassDone(content) {
    this.modalService.open(content, {
      centered: true,
      windowClass: 'darkModalCart',
    });
  }
  //------------------------------ addNewItemMap ---------------------------------------------------
  addNewItemMap(day: string, itemMenuLines: any) {
    if (this.contentFuldkostAntalNew != 0) {
      let value = JSON.parse(
        JSON.stringify(this.modalKodCategoryMap.get(this.selectedKodFuldkost))
      );
      for (let itemTibehor in value) {
        if (
          value[itemTibehor]['tibehorCategory'] == this.selectedStivelseFuldkost
        ) {
          value[itemTibehor]['tibehorQuantity'] = this.contentFuldkostAntalNew;
        }
      }
      this.modalKodCategoryMap.set(this.selectedKodFuldkost, value);
      this.modalKodCategoryArrayFiltered = Array.from(
        this.getFilteredMapByQuantitiesSoft(
          this.modalKodCategoryMap,
          itemMenuLines,
          this.isSideDish
        )
      );
      this.contentFuldkostAntalNew = 0;
    }

    return false;
  }
  //------------------------------------------------------------------------------------
  //------------------------------Check Permittions-------------------------------------

  checkPermittionsForOrder(
    dishAllocationsWeekResponse: any
  ) {
    return this.dishResponseService.checkPermittionsForOrder(dishAllocationsWeekResponse);
  }
  //---------------------------PopUp-----------------------------------

  goBack() {
    this.continueSwitching = false;
    if (this.isLeavingPage) {
      this.dataShareService.canToogleFromPopUp = false;
    }

    this.changedShiftWeek == false;
    this.isLeavingPage = false;
    this.modalService.dismissAll();
  }

  continue() {
    let toggledDay = this.localStorageService.get('toggledDayBulkOrder');

    //----------------------------Clear All flags changed-----------------------------
    //	this.simpleOderModelArray = this.clearChangedOrder(this.simpleOderModelArray, toggledDay);
    //	this.localStorageService.set(this.simpleOderModelArrayId, this.simpleOderModelArray);
    //---------------------------------------------------------------------------------------

    //--------------------------------------Not Clear switching------------------------
    //this.deliveryDaysOrdered = new DeliveryDaysOrdered(false, false, false, false, false, false, false);
    // 	this.localStorageService.set(this.deliveryDaysOrderedId, this.deliveryDaysOrdered);

    //------------------------------------------------------------------------------------

    this.dishAllocationsOrderDays = JSON.parse(
      JSON.stringify(this.dishAllocationsOrderDaysAll)
    );
    switch (toggledDay) {
      case 'Tuesday':
        this.updateOrdersInLocalStorageFromResponseIfExist(
          'Tuesday',
          JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
        );
        this.updateOrdersInLocalStorageFromResponseIfExist(
          'Wednesday',
          JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
        );
        break;
      case 'Thursday':
        this.updateOrdersInLocalStorageFromResponseIfExist(
          'Thursday',
          JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
        );
        this.updateOrdersInLocalStorageFromResponseIfExist(
          'Friday',
          JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
        );
        this.updateOrdersInLocalStorageFromResponseIfExist(
          'Saturday',
          JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
        );

        break;
      case 'Saturday':
        this.updateOrdersInLocalStorageFromResponseIfExist(
          'Sunday',
          JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
        );
        this.updateOrdersInLocalStorageFromResponseIfExist(
          'Monday',
          JSON.parse(JSON.stringify(this.dishAllocationsOrderDays))
        );

        break;
      default: {
      }
    }

    console.debug(
      '[continue] this.dishAllocationsOrderDays',
      JSON.stringify(this.simpleOderModelArray)
    );
    this.continueSwitching = true;
    if (!this.isLeavingPage && !this.changedShiftWeek) {
      switch (this.nextDay) {
        case 'Tuesday':
          this.switchTuesday();
          this.continueSwitching = false;
          break;
        case 'Thursday':
          this.switchThursday();
          this.continueSwitching = false;
          break;
        case 'Saturday':
          this.switchSaturday();
          this.continueSwitching = false;

          break;
        default: {
        }
      }
      //this.changedDay = false;
      this.dataShareService.canToogleFromPopUp = true;
      this.modalService.dismissAll();
    } else if (this.changedShiftWeek) {
      this.changedShiftWeek = false;
      console.debug('[continue]', this.nextShiftWeek);
      this.switchWeek(this.nextShiftWeek);
      this.dataShareService.canToogleFromPopUp = true;
      this.continueSwitching = false;
      this.modalService.dismissAll();
    } else {
      this.isLeavingPage = false;
      this.dataShareService.canToogleFromPopUp = true;
      let nextStateUrl = this.localStorageService.get('nextState');
      console.log('[continue] nextState', nextStateUrl);
      this.modalService.dismissAll();
      this.router.navigateByUrl(nextStateUrl);
    }
  }
  //--------------------------------------------------------------------

  canDeactivate(): boolean | Observable<boolean> {
    console.log('[canDeactivate] this.changedDay', this.changedDay);
    console.log('[canDeactivate] this.changedShiftWeek', this.changedShiftWeek);
    this.isLeavingPage = true;
    this.isQuantitiesChanged = this.checkQuantitiesChanged(
      this.simpleOderModelArray
    );
    if (this.isQuantitiesChanged) {
      //    if(this.isQuantitiesChanged && this.isLeavingPage &&(!(this.changedDay || this.changedShiftWeek)) ){
      console.log('[canDeactivate] openWindowCustomClass');
      this.openWindowCustomClass(this.contentPopUp);

      return false;
    } else {
      this.isLeavingPage = false;
      return true;
    }
  }

  //------------------------GetNumberOfSelected-----------------------------
  getNumberOfSelectedHovedret(
    sumMatrixForDays: any,
    deliveryDay: string
  ): number {
    console.debug(
      '[getNumberOfSelectedHovedret] this.sumMatrixForDays',
      this.sumMatrixForDays
    );
    console.log('[getNumberOfSelectedHovedret] deliveryDay', deliveryDay);
    let sumOder = 0;
    switch (deliveryDay) {
      case 'Tuesday':
        let sumOderTuesday = 0;
        let sumOderWednesday = 0;
        for (let day in sumMatrixForDays) {
          if (day == 'Tuesday') {
            for (let row in sumMatrixForDays[day]) {
              sumOderTuesday += sumMatrixForDays[day][row][0];
            }
          }
        }
        for (let day in sumMatrixForDays) {
          if (day == 'Wednesday') {
            for (let row in sumMatrixForDays[day]) {
              sumOderWednesday += sumMatrixForDays[day][row][0];
            }
          }
        }

        if (sumOderTuesday > 1 && sumOderWednesday > 1) {
          sumOder = 2;
        } else if (
          (sumOderTuesday > 1 || sumOderWednesday > 1) &&
          !(sumOderTuesday > 1 && sumOderWednesday > 1)
        ) {
          sumOder = 1;
        } else {
          sumOder = 0;
        }
        break;
      case 'Thursday':
        let sumOderThursday = 0;
        let sumOderFriday = 0;
        let sumOderSaturday = 0;
        for (let day in sumMatrixForDays) {
          if (day == 'Thursday') {
            for (let row in sumMatrixForDays[day]) {
              sumOderThursday += sumMatrixForDays[day][row][0];
            }
          }
        }
        console.log(
          '[getNumberOfSelectedHovedret] sumOderThursday',
          sumOderThursday
        );
        for (let day in sumMatrixForDays) {
          console.log(
            '[getNumberOfSelectedHovedret] sumMatrixForDays[day]',
            sumMatrixForDays[day]
          );
          console.log('[getNumberOfSelectedHovedret] day', day);

          if (day == 'Friday') {
            for (let row in sumMatrixForDays[day]) {
              console.log(
                '[getNumberOfSelectedHovedret] sumMatrixForDays[day][row][0]',
                sumMatrixForDays[day][row][0]
              );

              sumOderFriday += sumMatrixForDays[day][row][0];
            }
          }
        }
        console.log(
          '[getNumberOfSelectedHovedret] sumOderFriday',
          sumOderFriday
        );
        for (let day in sumMatrixForDays) {
          if (day == 'Saturday') {
            for (let row in sumMatrixForDays[day]) {
              sumOderSaturday += sumMatrixForDays[day][row][0];
            }
          }
        }
        console.log(
          '[getNumberOfSelectedHovedret] sumOderSaturday',
          sumOderSaturday
        );
        if (sumOderThursday > 1 && sumOderFriday > 1 && sumOderSaturday > 1) {
          sumOder = 2;
        } else if (
          (sumOderThursday > 1 || sumOderFriday > 1 || sumOderSaturday > 1) &&
          !(sumOderThursday > 1 && sumOderFriday > 1 && sumOderSaturday > 1)
        ) {
          sumOder = 1;
        } else {
          sumOder = 0;
        }

        break;
      case 'Saturday':
        let sumOderSunday = 0;
        let sumOderMonday = 0;

        for (let day in sumMatrixForDays) {
          if (day == 'Sunday') {
            for (let row in sumMatrixForDays[day]) {
              sumOderSunday += sumMatrixForDays[day][row][0];
            }
          }
        }
        for (let day in sumMatrixForDays) {
          if (day == 'Monday') {
            for (let row in sumMatrixForDays[day]) {
              sumOderMonday += sumMatrixForDays[day][row][0];
            }
          }
        }

        if (sumOderSunday > 1 && sumOderMonday > 1) {
          sumOder = 2;
        } else if (
          (sumOderSunday > 1 || sumOderMonday > 1) &&
          !(sumOderSunday > 1 && sumOderMonday > 1)
        ) {
          sumOder = 1;
        } else {
          sumOder = 0;
        }
        break;
      default: {
        console.error('Wrong Day');
      }
    }

    console.log('[getNumberOfSelectedHovedret] sumOder', sumOder);
    return sumOder;
  }
  getNumberOfSelectedHovedretFromResponseOld(
    dishAllocationsResponseDeliveryDay: any,
    deliveryDay: string
  ) {
    console.log(
      '[getNumberOfSelectedHovedretFromResponse] dishAllocationsResponseDeliveryDay',
      dishAllocationsResponseDeliveryDay
    );

    console.log(
      '[getNumberOfSelectedHovedretFromResponse] deliveryDay',
      deliveryDay
    );
    let sumOder = 0;
    let sumOderServDay = 0;
    let n = 0;
    switch (deliveryDay) {
      case 'Tuesday':
        n = 2;
        break;
      case 'Thursday':
        n = 3;
        break;
      case 'Saturday':
        n = 2;
        break;
      default: {
      }
    }
    for (let servDay in dishAllocationsResponseDeliveryDay) {
      //n++;

      if (
        dishAllocationsResponseDeliveryDay[servDay] !== undefined &&
        dishAllocationsResponseDeliveryDay[servDay]['servingDate'] !== undefined
      ) {
        sumOder = 0;
        for (let servDayDish in dishAllocationsResponseDeliveryDay[servDay][
          'mainDishes'
        ]) {
          sumOder +=
            dishAllocationsResponseDeliveryDay[servDay]['mainDishes'][
              servDayDish
            ]['menuLine']['quantity'];
        }
        for (let servDayDish in dishAllocationsResponseDeliveryDay[servDay][
          'sideDishes'
        ]) {
          sumOder +=
            dishAllocationsResponseDeliveryDay[servDay]['sideDishes'][
              servDayDish
            ]['menuLine']['quantity'];
        }
      }
      if (sumOder > 1) {
        sumOderServDay++;
      }
    }
    console.log('[getNumberOfSelectedHovedretFromResponse] n', n);
    console.log(
      '[getNumberOfSelectedHovedretFromResponse] sumOderServDay BEFORE',
      sumOderServDay
    );

    if (sumOderServDay == n) {
      sumOderServDay = 3;
    } else if (sumOderServDay > 0) {
      sumOderServDay = 1;
    } else {
      sumOderServDay = 0;
    }
    console.log(
      '[getNumberOfSelectedHovedretFromResponse] sumOderServDay AFTER',
      sumOderServDay
    );

    return sumOderServDay;
  }
//----------- getNumberOfSelectedHovedretFromResponse -------------------------------
  getNumberOfSelectedHovedretFromResponse(
    dishAllocationsResponseDeliveryDay: any,
    deliveryDay: string
  ) {

    let servDayDishSum = 1;
    let sumOder = 0;
    let sumOderServDay = 0;
    let n = 0;
    switch (deliveryDay) {
      case 'Tuesday':
        n = 2;
        break;
      case 'Thursday':
        n = 3;
        break;
      case 'Saturday':
        n = 2;
        break;
      default: {
      }
    }
    for (let servDay in dishAllocationsResponseDeliveryDay) {
      if (
        dishAllocationsResponseDeliveryDay[servDay] !== undefined &&
        dishAllocationsResponseDeliveryDay[servDay]['servingDate'] !== undefined
      ) {
       // sumOder = 0;
        for (let servDayDish in dishAllocationsResponseDeliveryDay[servDay]['mainDishes']) {
          sumOder = 0;
          if (
            dishAllocationsResponseDeliveryDay[servDay]['mainDishes'][servDayDish]['menuLine']['quantity'] > 0
          ) {
            sumOder += dishAllocationsResponseDeliveryDay[servDay]['mainDishes'][servDayDish]['menuLine']['quantity'];

          } else {
            for (let itemDietTypeGroups in dishAllocationsResponseDeliveryDay[servDay]['mainDishes'][servDayDish]['dietTypeGroups']) {


              if (itemDietTypeGroups == '0') {
                sumOder += Number(
                  this.sumTdFuldkostFromResponse(
                    dishAllocationsResponseDeliveryDay[servDay]['mainDishes'][servDayDish]['dietTypeGroups'][0]['dietTypes'],
                    servDay,
                    Number(servDayDish)
                  )
                );

              } else {
                sumOder += Number(
                  this.sumTdFromResponse(
                    dishAllocationsResponseDeliveryDay[servDay]['mainDishes'][servDayDish]['dietTypeGroups'][itemDietTypeGroups]['dietTypes']
                  )
                );

              }
            }
          }
          if (sumOder == 0) {
            servDayDishSum = 0;
          }
        }


        for (let servDayDish in dishAllocationsResponseDeliveryDay[servDay]['sideDishes']) {
          sumOder = 0;
          if (
            dishAllocationsResponseDeliveryDay[servDay]['sideDishes'][servDayDish]['menuLine']['quantity'] > 0
          ) {
            sumOder +=
              dishAllocationsResponseDeliveryDay[servDay]['sideDishes'][servDayDish]['menuLine']['quantity'];


          } else {
            for (let itemDietTypeGroups in dishAllocationsResponseDeliveryDay[
              servDay
            ]['sideDishes'][servDayDish]['dietTypeGroups']) {

              if (itemDietTypeGroups == '0') {
                sumOder += Number(
                  this.sumTdFuldkostFromResponse(
                    dishAllocationsResponseDeliveryDay[servDay]['sideDishes'][
                      servDayDish
                    ]['dietTypeGroups'][0]['dietTypes'],
                    servDay,
                    Number(servDayDish)
                  )
                );

              } else {
                sumOder += Number(
                  this.sumTdFromResponse(
                    dishAllocationsResponseDeliveryDay[servDay]['sideDishes'][servDayDish
                    ]['dietTypeGroups'][itemDietTypeGroups]['dietTypes']
                  )
                );

              }
            }
          }
          if (sumOder == 0) {
            servDayDishSum = 0;
          }
        }

      }

      if (sumOder > 0) {
        sumOderServDay++;

      }
    }


    if (sumOderServDay == n) {
      sumOderServDay = 3;
    } else if (sumOderServDay > 0) {
      sumOderServDay = 1;
    } else {
      sumOderServDay = 0;
    }


    return servDayDishSum;
  }
// -----------------------------------------------------------------------------
  checkCategoryFuldkost(dietTypeGroup: any) {

    let isFuldkost: boolean = false;
    for (let name in this.fuldkostCategoryArr) {
      if (dietTypeGroup['name'] == this.fuldkostCategoryArr[name]) {
        isFuldkost = true;
      }
    }
    return isFuldkost;
  }

  isSpecialDiets(dietTypeLine: number, dietTypeName: string): boolean {
    let isSpecialDiet: boolean = false;
    let startRangeForDietTypeNr1NotLine1 = 801;
    let subCatSpRangeArr = [
      new SubCatSpDietRange('Bulk', [801, 851, 901]),
      new SubCatSpDietRange('Energitæt', [
        26,
        176,
        326,
        476,
        626,
        802,
        852,
        902,
      ]),
      new SubCatSpDietRange('Energitæt m/mos', [27, 177, 326, 477, 627]),
      new SubCatSpDietRange('Energitæt m/rå kartofler', [
        30,
        180,
        330,
        480,
        630,
      ]),
      new SubCatSpDietRange('Blød', [38, 188, 338, 488, 638, 810, 860, 910]),
      new SubCatSpDietRange('Blød energitæt', [
        26,
        176,
        326,
        476,
        626,
        811,
        861,
        911,
        191,
      ]),
      new SubCatSpDietRange('Blød energi', [41, 191, 341, 491, 641]),
      new SubCatSpDietRange('Normalkost Pæd MT', [77, 227, 377, 527, 677]),
      new SubCatSpDietRange('Julemenu Personale', [
        118,
        268,
        418,
        568,
        718,
        845,
        895,
        945,
      ]),
      new SubCatSpDietRange('Pædagogisk MT', [
        26,
        176,
        326,
        476,
        626,
        849,
        899,
        949,
      ]),
    ];
    let subCatSpDietMatrix = new SubCatSpDietMatrix(subCatSpRangeArr);

    console.log('[isSpecialDiets] subCatSpDietMatrix', subCatSpDietMatrix);
    for (let spDietItem in subCatSpDietMatrix['subCatSpDietRange']) {
      if (
        subCatSpDietMatrix['subCatSpDietRange'][0]['range'].includes(
          dietTypeLine
        ) ||
        (subCatSpDietMatrix['subCatSpDietRange'][spDietItem]['subCategory'] ==
          dietTypeName &&
          subCatSpDietMatrix['subCatSpDietRange'][spDietItem]['range'].includes(
            dietTypeLine
          )
        || (dietTypeName == 'Fuldkost' &&  dietTypeLine >= startRangeForDietTypeNr1NotLine1))
      ) {
        console.log(`[isSpecialDiets] dietTypeLine № ${dietTypeLine} is found`);
        isSpecialDiet = true;
      }
    }

    console.log('[isSpecialDiets] isSpecialDiet', isSpecialDiet);
    return isSpecialDiet;
  }


  gustChefDayAndGuestChefSelected(dayObject:any){
    if(dayObject.isGuestChef){
      if(!this.selectedGuesChefDishes){
        return false
      }
    }
    return true
  }
  displayThisDish(dayObject:any,dish:any){
    /*console.log('displayDish')
    console.log(isGuestChef)*/

    console.log('dayObject')
    console.log(dayObject)
    console.log(dish)

    console.log('this.selectedGuesChefDishes')
    console.log(this.selectedGuesChefDishes)
    
    if(dayObject.isGuestChef){

      if(!this.selectedGuesChefDishes){
        //show info text about nothing selected
        console.log('show info text about nothing selected')
        return false
      }

      if(this.selectedGuesChefDishes.mainDish){
        if(dish.menuLine.productNr == this.selectedGuesChefDishes.mainDish.menuLine.productNr){
          return true
        }
      }
      if(this.selectedGuesChefDishes.sideDish){
        if(dish.menuLine.productNr == this.selectedGuesChefDishes.sideDish.menuLine.productNr){
          return true
        }
      }
      
      return false
    }

    return true
    
  }


  getSelectedGuesChefDishes(){
    console.log('***************************this.dishAllocationsWeekResponse')
        console.log(this.dishAllocationsWeekResponse)
        console.log('***************************this.dishAllocationsWeekResponse')

    //let dateYYYYMMDD =  this.dateService.getISODateYYYMMDDforDay(this.localStorageService.get('toggledDayBulk'),this.shiftWeek)
    let dateYYYYMMDD = this.currentGuestChefDate
    this.dataSource.getSelection(dateYYYYMMDD,this.departmentId).subscribe(
      (data) => {
      if(data){
        console.log('can-4')
        console.log(data)
        if (data){
          this.selectedGuesChefDishes = data.data
        }
       
      }
      
    })

  }
      

}
