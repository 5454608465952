import {Injectable} from '@angular/core';
import {Component, Inject, ViewEncapsulation, ViewChild,
		ElementRef} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router,
		 ActivatedRoute,
		 NavigationEnd,
		 ActivationEnd,
  		 Event,
		 NavigationCancel,
		 NavigationError,
		 NavigationStart,
		 ActivatedRouteSnapshot } from '@angular/router';
import {LocalStorageService} from '../../../service/localStorage.service';
import { Product } from '../../../model/product.model';

import { Cart } from '../../../cart/cart-model/cart.model';
import {DateService} from '../../../service/date.service';
import { AllocatedDishModel } from '../../../model/allocatedDishModel.model';
import { OrderDish } from '../../../model/orderDish.model';
import { DishAllocationWeekDataBase } from '../../../model/dishAllocationWeek.database';
import {DishRepository} from '../../../repository/dish.repository';
import { RestDataSource } from '../../../repository/rest.datasource';
import {ModelResolver} from '../../../resolver/model.resolver';
import {MenuLine} from '../../../model/menuLine.model';
import { timer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import {of, from } from 'rxjs';
import {Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/concatAll';
import 'rxjs/add/operator/concatMap';
//import "rxjs/add/operator/map";
import { map } from 'rxjs/operators';
import { _ } from 'underscore';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { SafePipeModule } from 'safe-pipe';
import { AuthService } from '../../../service/auth.service';
import * as moment from 'moment';
import {ComponentCanDeactivate} from '../../../guard/unsaved.guard';
import {DataShareService} from '../../../service/dataShare.service';
import {LocaleDaDKService} from '../../../service/locale-da-dk.service';
import { GuestChefService } from 'src/app/service/guest-chef.service';
import { DishResponseService } from 'src/app/service/dish-response.service';


	class DishAllocationsOrderDays{
	Sunday:	any[];
    Monday: any[];
    Tuesday:any[];
    Wednesday: any[];
    Thursday:any[];
    Friday:any[];
    Saturday:any[];

    constructor() {

        this.Sunday = [];
        this.Monday = [];
        this.Tuesday = [];
        this.Wednesday = [];
        this.Thursday = [];
        this.Friday = [];
        this.Saturday = [];

    }

}

class MenuLineArrayOrderDays{

	Sunday:	MenuLine[];
    Monday: MenuLine[];
    Tuesday:MenuLine[];
    Wednesday: MenuLine[];
    Thursday:MenuLine[];
    Friday:MenuLine[];
    Saturday:MenuLine[];

    constructor() {

        this.Sunday = [];
        this.Monday = [];
        this.Tuesday = [];
        this.Wednesday = [];
        this.Thursday = [];
        this.Friday = [];
        this.Saturday = [];

    }
}

class OrderMenuLineDay{
  menuLine:MenuLine;
  menuLineDay:string;
  orderNr:number;
  customerNr:number;
  menuNr:number;
  orderNotPermit:boolean;
  constructor(menuLine:MenuLine,  menuLineDay:string,
    orderNr:number, customerNr:number, menuNr:number,
    orderNotPermit:boolean){
    this.menuLine = menuLine;
    this.menuLineDay = menuLineDay;
    this.orderNr = orderNr;
    this.customerNr = customerNr;
    this.menuNr = menuNr;
    this.orderNotPermit = orderNotPermit;


  }
}
class BulkOrderMenuLineEditModelDays{

  menuLines:OrderMenuLineDay[];

  constructor(menuLines:OrderMenuLineDay[]){

    this.menuLines = menuLines;

  }
}

class SimpleOderModel{
	//orderDishId:string;
	orderDishId:number;    //
	orderNumber:number;
	sumOder:number;			//quontity
	Day:string;
	dishId:number;			//productNr
	name:string;			//productName
	imageUrl:string;
	category:string;		//dietTypeNr
	subcategory:string;
	dietTypeNr:number;		//dietTypeNr
	dietTypeName:string;		//dietTypeName
	portionSize:number;
	line:number;
	orderNr:number;
    customerNr:number;
    menuNr:number;
    changed:boolean;
	orderNotPermit:boolean;
	sended:boolean;


	constructor(orderDishId:number, orderNumber:number, sumOder:number, Day:string,
	 	dishId:number, name:string, imageUrl:string, category:string, subcategory:string,
	 	dietTypeNr:number, dietTypeName:string, portionSize:number, line:number,
	 	orderNr:number, customerNr:number, menuNr:number, changed:boolean,
	 	orderNotPermit:boolean, sended:boolean) {
	 	this.orderDishId = orderDishId;
	 	this.orderNumber = orderNumber;
	 	this.sumOder = sumOder;
	 	this.Day = Day;
	 	this.dishId = dishId;
	 	this.name = name;
	 	this.imageUrl = imageUrl;
	 	this.category = category;
	 	this.subcategory = subcategory;
	 	this.dietTypeNr = dietTypeNr;
	 	this.dietTypeName = dietTypeName;
	 	this.portionSize = portionSize;
	 	this.line = line;
	 	this.orderNr = orderNr;
	 	this.customerNr = customerNr;
	 	this.menuNr = menuNr;
	 	this.changed = changed;
	 	this.orderNotPermit = orderNotPermit;
	 	this.sended = sended;


	 }

}

class SumMatrixForDays{

	Sunday:	number[];
    Monday: number[];
    Tuesday:number[];
    Wednesday: number[];
    Thursday:number[];
    Friday:number[];
    Saturday:number[];

    constructor() {

        this.Sunday = [];
        this.Monday = [];
        this.Tuesday = [];
        this.Wednesday = [];
        this.Thursday = [];
        this.Friday = [];
        this.Saturday = [];

    }
}

class TibehorFuldkost{

	tibehorCategory:string;
	tibehorQuantity:number;


    constructor(tibehorCategory:string, tibehorQuantity:number) {

        this.tibehorCategory = tibehorCategory;
        this.tibehorQuantity = tibehorQuantity;

    }
}

/*class DeliveryDaysOrdered{

    Tuesday:boolean;
    Wednesday:boolean;
    Thursday:boolean;
    Friday:boolean;
    Saturday:boolean;
    Sunday:boolean;
    Monday:boolean;

  constructor( Tuesday:boolean, Wednesday:boolean,
  	Thursday:boolean, Friday:boolean, Saturday:boolean,
  	Sunday:boolean, Monday:boolean){

    this.Tuesday = Tuesday;
    this.Wednesday = Wednesday;
    this.Thursday = Thursday;
    this.Friday = Friday;
    this.Saturday = Saturday;
    this.Sunday = Sunday;
    this.Monday = Monday;

  }
}
*/

@Component({
    selector: 'printconfirmtable-root',
    templateUrl:  'printconfirmtable.component.html',
    encapsulation: ViewEncapsulation.None,
  	styleUrls: ['printconfirmtable.component.css']
})
export class PrintConfirmTable implements ComponentCanDeactivate {

	public sumOder:number;

	public weeks:string[];
	//private dishesArray: AllocatedDishModel[] = [];
	//private dishesForDayArray: any[] = [];
	public dishAllocationsWeekResponse: any[] = [];
	//private orderDishArray: OrderDish[] = [];

    public currentWeek:string;
	//private showUserName: boolean;
	public lastIndex:number;
	//private rowArray:any[];
	//private itemArray:any[];
	obsSubscription: Subscription;
	public numberElementsInRow:number;
	//private arrayDishesView: number[];
	public arrayDishesViewForDay: any[];
	public orderDishId:number;
	public orderDaysLeftInWeekArray:string[];
	public dishAllocationsOrderDays:DishAllocationsOrderDays;
	public dishAllocationsOrderDaysAll:DishAllocationsOrderDays;
	//private dishAllocationsOrderDaysCategory:DishViewController[] = [];
	public currentToogledDay:string;
	public toggledMonday: boolean;
	public toggledWednesday: boolean;
	public toggledFriday: boolean;
	public toggledTuesday: boolean;
	public toggledThursday: boolean;
	public toggledSaturday: boolean;
	public SundayDate:string;
	public SundayNextWeekDate:string;
	public MondayDate:string;
	public MondayNextWeekDate:string;
	public TuesdayDate:string;
	public WednesdayDate:string;
	public ThursdayDate:string;
	public FridayDate:string;
	public SaturdayDate:string;
	public shiftWeekNumber: string;
	public shiftWeek: number;
	public needNgOninit:boolean;
	public  simpleOderModelArray: SimpleOderModel[];
	public imgUrl:string;
	public tempArray:any[]=[];
	public categoryForFilter:any;
	//private ToogledDays:string;
	public simpleOderModelArrayId:string;
	public orderingDates:any[];
	public userInfoOrderingModel:any[] = [];
	//obsUserInfoOrderingModelSubscription: Subscription;
	public currentDayNumber:number;
	public filterOn:boolean;

	public isOrderingTimeFlag: boolean;
	public isEmptyMonday:boolean;
	public isEmptyTuesday:boolean;
	public isEmptyWednesday:boolean;
	public isEmptyThursday:boolean;
	public isEmptyFriday:boolean;
	public isEmptySaturday:boolean;
	public isEmptySunday:boolean;
	private modalBtnFlag: string;
	public safeSrc: SafeResourceUrl;
	public modalBtnBackgroundColor:string;
	public modalBtnTextColor:string;
	public modalBtnTextWeight:string;
	public ifWeHaveCategory:boolean;
	private needUnsubscribe: boolean;
	//public menuLineArrForFuldkost: any[] = [];
	public menuLineArrForFuldkost: MenuLineArrayOrderDays;
	public menuLineArrForEnrgitat: MenuLineArrayOrderDays;
	public menuLineArrForBlod: MenuLineArrayOrderDays;
	public menuLineArrForAndet: MenuLineArrayOrderDays;
	public menuLineArrForCategoryTemp: any[] = [];
	public contentFuldkostAntal: number = 0;
	public contentFuldkostAntalNew: number = 0;
	public contentEnergitatAntalArr: number[] = [];
	public contentBlodAntalArr: number[] = [];
	public contentAndetAntalArr: number[] = [];
	public contentAndetAntalArr2: number[] = [];
	public currentDayForModal: string;
	public sumMatrixForDays: SumMatrixForDays;
	public dishAllocationsOrderDaysInitTuesday:any;
	public dishAllocationsOrderDaysInitThursday:any;
	public dishAllocationsOrderDaysInitSaturday:any;
	public isProductNrFilterOn: boolean;
	closeResult: string;
	public selectedKodFuldkost:string;
	public selectedStivelseFuldkost:string;
	public orderFromViewWeekPlan: boolean;
	private departmentId: number;
	public  modalKodCategoryMap:any;
	public  modalKodCategoryArray:any;
	public  modalKodCategoryArrayFiltered:any;
	public defaultKod: string;
	public defaultTilbehor:  string;
	public currentTilbehorArr: string[];
	public subLinesSetArr:string[];
	public subLinesSetKodArr:string[];
	public subLinesSetTilbehorArr:string[];
	public subLinesSetGrontArr:string[];
	public subLinesSetSovsArr:string[];
	public subLinesSetArrEnkel:string[];
	public toogledModalBulk: boolean;
	public toogledModalEnkel: boolean;
	public dishAllDayFilteredDietTypeGroups: DishAllocationsOrderDays;
	public dishEnergitatAllDayFilteredDietTypeGroups: DishAllocationsOrderDays;
	public dishBlodAllDayFilteredDietTypeGroups: DishAllocationsOrderDays;
	public currentItemMenuLines:any;
	private orderNr:number;
    private customerNr:number;
    private menuNr:number;
    private fuldkostCategoryArr:string[];
    public disabledAfterOrder:boolean;
    private delay:number;
    public orderNotPermit: boolean=true;
    public isModalKodCategoryMapNonEmpty: boolean;
    public sumTdFuldkostAll:number;
    public isEnergitatEmptyTuesday:boolean=false;
    public isBlodEmptyTuesday:boolean=false;
    public isEnergitatEmptyWednesday:boolean=false;
    public isBlodEmptyWednesday:boolean=false;
    public isEnergitatEmptyThursday:boolean=false;
    public isBlodEmptyThursday:boolean=false;
    public isEnergitatEmptyFriday:boolean=false;
    public isBlodEmptyFriday:boolean=false;
    public isEnergitatEmptySaturday:boolean=false;
    public isBlodEmptySaturday:boolean=false;
    public isEnergitatEmptySunday:boolean=false;
    public isBlodEmptySunday:boolean=false;
    public isEnergitatEmptyMonday:boolean=false;
    public isBlodEmptyMonday:boolean=false;
    public isQuantitiesChanged:boolean=false;
    public continueSwitching:boolean=false;
    public nextDay:string;
    public isLeavingPage:boolean=false;
    public isClosedPopUp:boolean=false;
    public isContinuePopUp:boolean=false;
    public templateDays: string[];
    public isfAfterPageLoaded:boolean;
    public  simpleOderModelBulkArray: SimpleOderModel[];
    public  simpleOderModelOtherArray: SimpleOderModel[];

    //public deliveryDaysOrdered: DeliveryDaysOrdered;



	@ViewChild('contentPopUp', {static: false}) contentPopUp: ElementRef;

	constructor(  private dataSource: RestDataSource,
		            private localStorageService: LocalStorageService,
                private cart: Cart,
                private router: Router,
                private dateService: DateService,
                private dishRepository: DishRepository,
                private activatedRoute: ActivatedRoute,
                private modalService: NgbModal,
                private sanitizer: DomSanitizer,
                private auth: AuthService,
                public dataShareService:DataShareService,
                private localeDaDKService: LocaleDaDKService,
                public guestChefService: GuestChefService,
                public dishResponseService: DishResponseService) {



	 	//this.localStorageService.set( 'currentChildRoute', "bulkorder/editweekplan");
	 	//this.localStorageService.set( 'pageForOrderChildRoute', "bulkorder/viewweekplan");
	 	this.localStorageService.set( 'currentChildRoute', 'bulkorder/printweekplan/printconfirmtable');
	 	this.localStorageService.set( 'pageForOrderChildRoute', 'bulkorder/viewweekplan');
	 	this.departmentId = this.localStorageService.get('currentdepartmentId');
	 	this.needUnsubscribe = false;
	 	this.dishAllocationsOrderDays = new DishAllocationsOrderDays();
	 	this.menuLineArrForFuldkost = new MenuLineArrayOrderDays();
	 	this.menuLineArrForEnrgitat = new MenuLineArrayOrderDays();
		this.menuLineArrForBlod = new MenuLineArrayOrderDays();
		this.menuLineArrForAndet = new MenuLineArrayOrderDays();
		this.disabledAfterOrder = false;
	 	//this.dishAllocationsWeekResponse = [];
	 	//this.sumMatrixForDays = new SumMatrixForDays();
	 	this.isfAfterPageLoaded = true;
	 	}

	ngOnInit(): void{

		//this.ifWeHaveCategory = false;
		//this.needNgOninit = false;
		//this.orderFromViewWeekPlan = true;
		this.isProductNrFilterOn = false;
//----------------------Print Init----------------------------------------------
		this.dataShareService.dispayIfPageForPrinting = 'none';

		this.ifWeHaveCategory = false;
		this.needNgOninit = false;
		this.orderFromViewWeekPlan = true;


//-------------------NavTabs init ------------------------------------


		let toggledDay = this.localStorageService.get( 'toggledPrintTableDayBulkOrder');
		this.initTabs(toggledDay);


//-----------------------------Depatments --------------------------------------------
		//this.departmentId = this.localStorageService.get('currentdepartmentId');

//----------------------------------------------------------------------------------
this.fuldkostCategoryArr = ['Fuldkost', 'Fuldkost m. kogte kartof.', 'Fuldkost m. rå kartof.', 'Fuldkost m/mos', 'Fuldkost % kartofler'];
//----------------------------------------------------------------------------

	 	this.sumOder = this.localStorageService.get('sumOder');




//---------------------Date------------------------------------
  this.initDate();


	    //--------------------- GET dishAllocations for All days in Week-----------------------------
	    if(!this.needUnsubscribe){
	    	this.obsSubscription = this.activatedRoute.data.subscribe(data => {
	   		this.dishAllocationsWeekResponse = [];
	   		this.dishAllocationsOrderDaysInitTuesday = [];
	   		this.dishAllocationsOrderDaysInitThursday = [];
	   		this.dishAllocationsOrderDaysInitSaturday = [];
	   		this.dishAllocationsOrderDays = new DishAllocationsOrderDays();
	   		this.dishAllDayFilteredDietTypeGroups = new DishAllocationsOrderDays();
	   		this.dishEnergitatAllDayFilteredDietTypeGroups = new DishAllocationsOrderDays();
	   		this.dishBlodAllDayFilteredDietTypeGroups = new DishAllocationsOrderDays();
	   		this.dishAllocationsWeekResponse=(data['dishes']);

      if (this.dishAllocationsWeekResponse[0]) {
        this.orderNotPermit = this.checkPermittionsForOrder(this.dishAllocationsWeekResponse);
      }
      let toggledDay1 = this.localStorageService.get('toggledPrintTableDayBulkOrder');
	//-------------------------------------------Order------------------------------------------------------

		this.isOrderingTimeFlag = false;
		//this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'pageForOrderChildRoute') + this.shiftWeekNumber + this.departmentId;
		this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
		console.debug('EDIT this.simpleOderModelArrayId='+this.simpleOderModelArrayId);
		this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
		console.debug('this.simpleOderModelArray', this.simpleOderModelArray);
		//console.debug(`OrderBulk simpleOderModelArray= ${this.simpleOderModelArray}`);
		if((this.simpleOderModelArray==null) || (this.simpleOderModelArray==undefined || (this.simpleOderModelArray.length==0))){
			this.simpleOderModelArray = new Array();
			this.sumOder = 0;
		}else{

			for(var oder in this.simpleOderModelArray){
	  		 	this.sumOder += this.simpleOderModelArray[oder].sumOder;
	  		  	}
		}

    this.dishAllocationsWeekResponse = this.initDishAllocationsWeekResponse(toggledDay1, this.dishAllocationsWeekResponse);


			console.info('dishAllocationsOrderDays1='+JSON.stringify(this.dishAllocationsOrderDays));

	     	//}





console.debug('[ngOninit] this.dishEnergitatAllDayFilteredDietTypeGroups', this.dishEnergitatAllDayFilteredDietTypeGroups);

console.debug('[ngOninit] this.dishBlodAllDayFilteredDietTypeGroups', this.dishBlodAllDayFilteredDietTypeGroups);

//-----------------------------------Save All Dishes------------------------------------------

	this.dishAllocationsOrderDaysAll = JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)); // save View All Dishes
//--------------------------------------------------------------------------------------------------
//--------------------------Loader -----------------------------
	this.router.events.subscribe((event: Event) => {
      switch (true) {
        /*case event instanceof NavigationStart: {
        this.loading = true;
          break;
        }*/

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
        //  this.loading = false;
         // window.onload = function(){
       	  var loader = document.getElementById('loader');
       	  loader.style.visibility = 'hidden';
  //--------------------------Open printing--------------------------------------
		if(this.isfAfterPageLoaded){
			//window.print();
			this.isfAfterPageLoaded = false
			}
//------------------------------------------------------------------------------
    	//	}
        //  this.localStorageService.set('loading',false);

        //  this.getDismissReason(1);
          break;
        }
        default: {
          break;
        }
      }
    });

		this.localStorageService.set( 'currentChildRoute', this.localStorageService.get( 'parentPrintRoute'));
//--------------------------------------Add get BulkArr and OtherArr----------------
				      	this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get((this.simpleOderModelArrayId + 'PrConf'));
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}
						this.simpleOderModelBulkArray = this.getSimpleOderModelBulkArray(this.simpleOderModelArray);
						console.debug('simpleOderModelBulkArray', this.simpleOderModelBulkArray);
						this.simpleOderModelOtherArray = this.getSimpleOderModelOtherArray(this.simpleOderModelArray);
						console.debug('simpleOderModelOtherArray', this.simpleOderModelOtherArray);

//--------------------------------------------------------------------------------

	    this.needNgOninit = true;
	    this.needUnsubscribe = true;

	  	console.info('this.dishAllocationsOrderDays22='+JSON.stringify(this.dishAllocationsOrderDays));

	      	});
		}
//--------------------------Filter -----------------------------------------------


//-----------------------GET  UserInfoOrderingModel ---------------------------------------------------------

		this.userInfoOrderingModel = this.localStorageService.get('userInfoOrderingModel');
		//console.debug("FROKOCOMPONENT userInfoOrderingModel="+JSON.stringify( this.userInfoOrderingModel));
	 	this.orderingDates =  this.userInfoOrderingModel['orderingDates'];
	 	console.debug('EDITWEEKPLAN this.orderingDates='+JSON.stringify( this.orderingDates));



//-------------------------------------------------------------------------------------------------------------
	  	this.orderDishId = 0;
	  	//this.currentToogledDay = "Mandag";
	  	this.currentToogledDay = 'Tuesday';




	 	console.debug('this.isOrderingTimeFlag=' +this.isOrderingTime(this.userInfoOrderingModel, this.categoryForFilter));


	//----------------------Modal Window init -----------------------

	this.modalBtnFlag = 'dishInfo';
	this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/nOIwHaiojDI');
	this.modalBtnBackgroundColor = '#FFFFFF';
	this.modalBtnTextColor = '#000000';
	this.modalBtnTextWeight = 'bold'

//--------------------------Modal Fuldkost------------------------
	//this.defaultKod = "first";

	this.currentDayForModal = 'Tuesday';
	this.toogledModalBulk = true;
	this.toogledModalEnkel = false;
//---------------------------------Modal Andet ------------------------
	//this.dishAllDayFilteredDietTypeGroups = [];
//--------------------------- Sum I alt --------------------------------
	this.sumMatrixForDays = new SumMatrixForDays();
	this.sumMatrixForDays = this.createSumMatrixForDays(this.dishAllocationsOrderDays);
	console.info('this.sumMatrixForDaysINIT', this.sumMatrixForDays);
	//this.contentEnergitatAntalArr = this.sumMatrixForDays
//----------------------------Update quontity from LocalStorage ---------------------
/*
for(let day in this.dishAllocationsOrderDays){
	console.debug("[ngOninit] dayUpdate", day);

	for(let itemMainDishes in this.dishAllocationsOrderDays[day]["mainDishes"]){
	this.dishAllocationsOrderDays[day]["mainDishes"][itemMainDishes] =
	JSON.parse(JSON.stringify(this.updateQuontityFromSimpleOderModelArray(this.dishAllocationsOrderDays[day]["mainDishes"][itemMainDishes], this.simpleOderModelArray, day)));

	this.dishEnergitatAllDayFilteredDietTypeGroups[day]["mainDishes"][itemMainDishes] =
	JSON.parse(JSON.stringify(this.updateQuontityFromSimpleOderModelArray(this.dishEnergitatAllDayFilteredDietTypeGroups[day]["mainDishes"][itemMainDishes], this.simpleOderModelArray, day)));

	this.dishBlodAllDayFilteredDietTypeGroups[day]["mainDishes"][itemMainDishes] =
	JSON.parse(JSON.stringify(this.updateQuontityFromSimpleOderModelArray(this.dishBlodAllDayFilteredDietTypeGroups[day]["mainDishes"][itemMainDishes], this.simpleOderModelArray, day)));

	this.dishAllDayFilteredDietTypeGroups[day]["mainDishes"][itemMainDishes] =
	JSON.parse(JSON.stringify(this.updateQuontityFromSimpleOderModelArray(this.dishAllDayFilteredDietTypeGroups[day]["mainDishes"][itemMainDishes], this.simpleOderModelArray, day)));
	}

}
console.info("[ngOnInit] Updated dishAllocationsOrderDays", this.dishAllocationsOrderDays);
*/
//----------------------------Modal Done----------------------------------------
 this.delay = 2000;
////---------------------------------------------------------------------------------
/*-------------------------------------------------------------------------------*/
 /*	this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          	this.isQuantitiesChanged = this.checkQuantitiesChanged(this.simpleOderModelArray);
			if(this.isQuantitiesChanged){
				this.openWindowCustomClass(this.contentPopUp);
			}
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {

          break;
        }
        default: {
          break;
        }
      }
    });*/
	 	}

//-----------------------------------------------------------------------------------------------------
//*****************************************************************************************************
//------------------------------- Component Internal Methods------------------------------------------
	 	ngAfterContentChecked() {

	 		if(this.needNgOninit){

	 			this.ngOnInit();

	 		}

		}
	 	 ngOnDestroy() {

	 	 	this.obsSubscription.unsubscribe();
	 	 	this.dataShareService.dispayIfPageForPrinting = '';

	 	 }
//-------------------Init-----------------------
  initTabs(toggledDay: string): void  {
    switch (toggledDay) {
        case 'Tuesday':
          this.toggledTuesday = true;
          this.toggledThursday = false;
          this.toggledSaturday = false;
          this.templateDays = ['Tuesday', 'Wednesday'];
        break;
        case 'Thursday':
        //console.log("Thursday");
          this.toggledTuesday = false;
          this.toggledThursday = true;
          this.toggledSaturday = false;
          this.templateDays = ['Thursday', 'Friday', 'Saturday'];

        break;
        case 'Saturday':
        //console.log("Saturday");
          this.toggledTuesday = false;
          this.toggledThursday = false;
          this.toggledSaturday = true;
          this.templateDays = ['Sunday', 'Monday'];

        break;
        case 'Week':

          this.toggledTuesday = true;
          this.toggledThursday = true;
          this.toggledSaturday = true;
          this.templateDays = ['Tuesday', 'Wednesday','Thursday', 'Friday', 'Saturday','Sunday', 'Monday'];
        break;
        default:
        this.localStorageService.set( 'toggledPrintTableDayBulkOrder','Tuesday');
        this.toggledTuesday = true;
        this.toggledThursday = false;
        this.toggledSaturday = false;
        this.templateDays = ['Tuesday', 'Wednesday'];

    }
  }

  initDate() {
    this.shiftWeek = this.localStorageService.get('shiftWeek'+ this.localStorageService.get( 'parentPrintRoute'));

		this.currentDayNumber = this.dateService.getCurrentDayNumber();

     	this.currentWeek = this.dateService.getCurrentWeek();

     	this.shiftWeekNumber = this.dateService.getShiftWeek(this.shiftWeek);


	 	this.SundayDate = this.dateService.getISODateDDMMMforDay('Sunday', this.shiftWeek);

	 	this.MondayDate = this.dateService.getISODateDDMMMforDay('Monday',  this.shiftWeek);

	 	this.SundayNextWeekDate = this.dateService.getISODateDDMMMforDay('Sunday', this.shiftWeek+1);

	 	this.MondayNextWeekDate = this.dateService.getISODateDDMMMforDay('Monday',  this.shiftWeek+1);

	 	this.TuesdayDate = this.dateService.getISODateDDMMMforDay('Tuesday',  this.shiftWeek);

	 	this.WednesdayDate = this.dateService.getISODateDDMMMforDay('Wednesday',  this.shiftWeek);

	 	this.ThursdayDate = this.dateService.getISODateDDMMMforDay('Thursday',  this.shiftWeek);

	 	this.FridayDate = this.dateService.getISODateDDMMMforDay('Friday',  this.shiftWeek);

	 	this.SaturdayDate = this.dateService.getISODateDDMMMforDay('Saturday',  this.shiftWeek);

  }
  initDishAllocationsWeekResponse(toggledDay: string, dishResponse: any) {
    let dishAllocationsWeekResponse = JSON.parse(JSON.stringify(dishResponse));
    switch (toggledDay) {
			case 'Tuesday':
        {
          if (dishAllocationsWeekResponse[0] && dishAllocationsWeekResponse[0]['servings']) {

					//dishAllocationsWeekResponse[0]["servings"] = JSON.parse(JSON.stringify(this.filterResponseByUndefined(this.dishAllocationsWeekResponse)));
					if(dishAllocationsWeekResponse[0] && dishAllocationsWeekResponse[0]['servings']!=[]
						&& dishAllocationsWeekResponse[0]['servings']['0']!==undefined
						&& dishAllocationsWeekResponse[0]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Tuesday', this.shiftWeek)){

				      	if(this.orderFromViewWeekPlan){

				      		if(dishAllocationsWeekResponse[0]['servings']['0']['mainDishes']==[]){

				      			dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'] = dishAllocationsWeekResponse[0]['servings']['0']['sideDishes'];
				      		}else if(dishAllocationsWeekResponse[0]['servings']['0']['mainDishes']!=[]
				      			&& dishAllocationsWeekResponse[0]['servings']['0']['sideDishes']!=[]){
			      				dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'] = dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'].concat(dishAllocationsWeekResponse[0]['servings']['0']['sideDishes']);

				      		console.debug('dishAllocationsWeekResponse[0][servings][0]', JSON.stringify(dishAllocationsWeekResponse[0]['servings'][0]));
			      			}

				      		console.debug('this.dishAllocationsOrderDaysInitTuesday', this.dishAllocationsOrderDaysInitTuesday);
				      		//-------------------------------------------------------------
				      	/*dishAllocationsWeekResponse[0]['servings'][0].orderNr = dishAllocationsWeekResponse[0]['orderNr'];
				      	dishAllocationsWeekResponse[0]['servings'][0].customerNr = dishAllocationsWeekResponse[0]['customerNr'];
				      	dishAllocationsWeekResponse[0]['servings'][0].menuNr = dishAllocationsWeekResponse[0]['menuNr'];
                  */
				      	//-------------------------------------------------------------
				      		this.dishAllocationsOrderDays['Tuesday']=dishAllocationsWeekResponse[0]['servings'][0];
				      		this.dishAllocationsOrderDays['Tuesday']['mainDishes']=this.productNrFilter(this.dishAllocationsOrderDays['Tuesday'], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Tuesday'));
				      		this.sortResponseDay(this.dishAllocationsOrderDays['Tuesday']);
				      		let dishAllocationsOrderDaysEnergitat=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Tuesday']));
				      		this.dishEnergitatAllDayFilteredDietTypeGroups['Tuesday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysEnergitat, 'Energitæt')));
				      		if(this.dishEnergitatAllDayFilteredDietTypeGroups['Tuesday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isEnergitatEmptyTuesday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyTuesday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isEnergitatEmptyTuesday = false;
				      		}
				      		let dishAllocationsOrderDaysBlod=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Tuesday']));
				      		this.dishBlodAllDayFilteredDietTypeGroups['Tuesday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysBlod, 'Blød')));
				      		if(this.dishBlodAllDayFilteredDietTypeGroups['Tuesday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isBlodEmptyTuesday = true;
				      			console.debug('[ngOninit] this.isBlodEmptyTuesday', this.isBlodEmptyTuesday);
				      		}else{
				      			this.isBlodEmptyTuesday = false;
				      		}
console.debug('[ngOninit] BlodAllDayFilteredDietTypeGroups', this.dishBlodAllDayFilteredDietTypeGroups['Tuesday']);
				      		this.dishAllDayFilteredDietTypeGroups['Tuesday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays['Tuesday'])));
				      		console.debug('[ngOninit] this.dishAllocationsOrderDaysTuesday', JSON.stringify(this.dishAllocationsOrderDays));
console.debug('[ngOninit] BlodAllDayFilteredDietTypeGroups2', this.dishBlodAllDayFilteredDietTypeGroups['Tuesday']);
				      	}else{

				      		this.dishAllocationsOrderDays['Tuesday']=dishAllocationsWeekResponse[0]['servings'][0];

				      	}
				      	//-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Tuesday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));
				      	if(_.isEmpty(this.dishAllocationsOrderDays['Tuesday'])){

				      		console.debug('this.dishAllocationsOrderDays[\'Tuesday\'].length==0')
				      		this.isEmptyTuesday=true;
				  		}



			      }else{
			      	this.isEmptyTuesday=true;
			      }


				if((dishAllocationsWeekResponse[0]!
					&& dishAllocationsWeekResponse[0]['servings'].length!=0
			      	&& dishAllocationsWeekResponse[0]['servings']['1']!== undefined
					&& dishAllocationsWeekResponse[0]['servings']['1']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Wednesday', this.shiftWeek))
					 || (dishAllocationsWeekResponse[0]!==undefined
					&& dishAllocationsWeekResponse[0]['servings'].length!=0
					&& dishAllocationsWeekResponse[0]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Wednesday', this.shiftWeek))){
						let servingDayIndex = 1;
						//this.dateService.getISODateYYYMMDDforDay("Wednesday", this.shiftWeek)
						console.debug('WednesdayISO', this.dateService.getISODateYYYMMDDforDay('Wednesday', this.shiftWeek));
						if(dishAllocationsWeekResponse[0]['servings']['0'] &&
						 dishAllocationsWeekResponse[0]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Wednesday', this.shiftWeek)){
							servingDayIndex = 0
						}

						console.debug('servingDayIndex', servingDayIndex);
						//console.debug("dishAllocationsWeekResponseW", this.dishAllocationsWeekResponse);
			      		//this.dishAllocationsOrderDays["Wednesday"]=dishAllocationsWeekResponse[0];

			      		if(this.orderFromViewWeekPlan){
			      			if(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes']==[]){


				      			dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'] = dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes'];
				      		}else if(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes']!=[]

				      			&& dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes']!=[]){
			      				dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'] = dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'].concat(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes']);

				      		console.debug('dishAllocationsWeekResponse[0][servings][1]', JSON.stringify(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]));
			      			}
				      		//-------------------------------------------------------------
				      	/*dishAllocationsWeekResponse[0]['servings'][servingDayIndex].orderNr = dishAllocationsWeekResponse[0]['orderNr'];
				      	dishAllocationsWeekResponse[0]['servings'][servingDayIndex].customerNr = dishAllocationsWeekResponse[0]['customerNr'];
				      	dishAllocationsWeekResponse[0]['servings'][servingDayIndex].menuNr = dishAllocationsWeekResponse[0]['menuNr'];
                  */
				      	//-------------------------------------------------------------
				      		this.dishAllocationsOrderDays['Wednesday']=dishAllocationsWeekResponse[0]['servings'][servingDayIndex];
				      		this.dishAllocationsOrderDays['Wednesday']['mainDishes']=this.productNrFilter(this.dishAllocationsOrderDays['Wednesday'], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Wednesday'));
				      		this.sortResponseDay(this.dishAllocationsOrderDays['Wednesday']);
				      		let dishAllocationsOrderDaysEnergitat=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Wednesday']));
				      		this.dishEnergitatAllDayFilteredDietTypeGroups['Wednesday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysEnergitat, 'Energitæt')));
				      		if(this.dishEnergitatAllDayFilteredDietTypeGroups['Wednesday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isEnergitatEmptyWednesday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyWednesday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isEnergitatEmptyWednesday = false;
				      		}
				      		let dishAllocationsOrderDaysBlod=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Wednesday']));
				      		this.dishBlodAllDayFilteredDietTypeGroups['Wednesday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysBlod, 'Blød')));
				      		if(this.dishBlodAllDayFilteredDietTypeGroups['Wednesday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isBlodEmptyWednesday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyWednesday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isBlodEmptyWednesday = false;
				      		}
				      		this.dishAllDayFilteredDietTypeGroups['Wednesday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays['Wednesday'])));
				      		console.debug('this.dishAllocationsOrderDaysTuesday', JSON.stringify(this.dishAllocationsOrderDays));
				      	}else{

				      		//this.dishAllocationsOrderDays["Wednesday"]=this.dishAllocationsWeekResponse[1];
				      		this.dishAllocationsOrderDays['Wednesday']=dishAllocationsWeekResponse[0]['servings'][servingDayIndex];
				      	}
				      	//-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Wednesday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));
			      		if(_.isEmpty(this.dishAllocationsOrderDays['Wednesday'])){
			      		console.debug('this.dishAllocationsOrderDays[\'Wednesday\'].length==0')
			      		this.isEmptyWednesday=true;
			      		}

					}else{
			      	this.isEmptyWednesday=true;
			      }
			      console.debug('[ngOninit] this.dishAllocationsOrderDays[\'Tuesday\']', this.dishAllocationsOrderDays)
			    break;

				    }

		      }


			case 'Thursday':
				{	if(dishAllocationsWeekResponse[0] && dishAllocationsWeekResponse[0]['servings']){

					//dishAllocationsWeekResponse[0]["servings"] = JSON.parse(JSON.stringify(this.filterResponseByUndefined(this.dishAllocationsWeekResponse)));
					if(dishAllocationsWeekResponse[0] && dishAllocationsWeekResponse[0]['servings']!=[]
						&& dishAllocationsWeekResponse[0]['servings']['0']!==undefined
						&& dishAllocationsWeekResponse[0]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Thursday', this.shiftWeek)){

			      	//this.dishAllocationsOrderDays["Thursday"]=dishAllocationsWeekResponse[0];
			      	 if(this.orderFromViewWeekPlan){
			      	if(dishAllocationsWeekResponse[0]['servings']['0']['mainDishes']==[]){

				      			dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'] = dishAllocationsWeekResponse[0]['servings']['0']['sideDishes'];
				      		}else if(dishAllocationsWeekResponse[0]['servings']['0']['mainDishes']!=[]

				      			&& dishAllocationsWeekResponse[0]['servings']['0']['sideDishes']!=[]){
			      				dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'] = dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'].concat(dishAllocationsWeekResponse[0]['servings']['0']['sideDishes']);

				      		console.debug('dishAllocationsWeekResponse[0][servings][0]', JSON.stringify(dishAllocationsWeekResponse[0]['servings'][0]));
			      			}

				      		//-------------------------------------------------------------
				      	/*dishAllocationsWeekResponse[0]['servings'][0].orderNr = dishAllocationsWeekResponse[0]['orderNr'];
				      	dishAllocationsWeekResponse[0]['servings'][0].customerNr = dishAllocationsWeekResponse[0]['customerNr'];
				      	dishAllocationsWeekResponse[0]['servings'][0].menuNr = dishAllocationsWeekResponse[0]['menuNr'];
                  */
				      	//-------------------------------------------------------------
				      		this.dishAllocationsOrderDays['Thursday']=dishAllocationsWeekResponse[0]['servings'][0];
				      		this.dishAllocationsOrderDays['Thursday']['mainDishes']=this.productNrFilter(this.dishAllocationsOrderDays['Thursday'], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Thursday'));
				      		this.sortResponseDay(this.dishAllocationsOrderDays['Thursday']);
				      		let dishAllocationsOrderDaysEnergitat=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Thursday']));
				      		this.dishEnergitatAllDayFilteredDietTypeGroups['Thursday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysEnergitat, 'Energitæt')));
				      		if(this.dishEnergitatAllDayFilteredDietTypeGroups['Thursday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isEnergitatEmptyThursday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyThursday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isEnergitatEmptyThursday = false;
				      		}
				      		let dishAllocationsOrderDaysBlod=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Thursday']));
				      		this.dishBlodAllDayFilteredDietTypeGroups['Thursday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysBlod, 'Blød')));
				      		if(this.dishBlodAllDayFilteredDietTypeGroups['Thursday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isBlodEmptyThursday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyThursday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isBlodEmptyThursday = false;
				      		}
				      		this.dishAllDayFilteredDietTypeGroups['Thursday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays['Thursday'])));
				      		console.debug('this.dishAllocationsOrderDaysTuesday', JSON.stringify(this.dishAllocationsOrderDays));
				      	}else{

				      		this.dishAllocationsOrderDays['Thursday']=dishAllocationsWeekResponse[0]['servings'][0];
				      	}
				    //-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Thursday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));
			      	if(_.isEmpty(this.dishAllocationsOrderDays['Thursday'])){
			      		console.debug('this.dishAllocationsOrderDays[\'Thursday\'].length==0')
			      		this.isEmptyThursday=true;
			      	}
					}else{
			      	this.isEmptyThursday=true;
			      }


			      	if((dishAllocationsWeekResponse[0]
			      	&& dishAllocationsWeekResponse[0]['servings'].length!=0
			      	&& dishAllocationsWeekResponse[0]['servings']['1']!== undefined
			      	&& dishAllocationsWeekResponse[0]['servings']['1']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Friday', this.shiftWeek))
					 || (dishAllocationsWeekResponse[0]!==undefined
					&& dishAllocationsWeekResponse[0]['servings'].length!=0
					&& dishAllocationsWeekResponse[0]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Friday', this.shiftWeek))){

			      		console.debug('Err', dishAllocationsWeekResponse[0]['servings']);
			      		let servingDayIndex = 1;
						//this.dateService.getISODateYYYMMDDforDay("Friday", this.shiftWeek)
						console.debug('FridayISO', this.dateService.getISODateYYYMMDDforDay('Friday', this.shiftWeek));
						if(dishAllocationsWeekResponse[0]['servings']['0']!==undefined &&
							dishAllocationsWeekResponse[0]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Friday', this.shiftWeek)){
							servingDayIndex = 0
						}

						console.debug('servingDayIndex', servingDayIndex);
				    //this.dishAllocationsOrderDays["Friday"]=dishAllocationsWeekResponse[0];
				    if(this.orderFromViewWeekPlan){
				   if(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes']==[]){

				      			dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'] = dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes'];
				      		}else if(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes']!=[]

				      			&& dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes']!=[]){
			      				dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'] = dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'].concat(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes']);

				      		console.debug('dishAllocationsWeekResponse[0][servings][servingDayIndex]', JSON.stringify(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]));
			      			}


				      		console.debug('this.dishAllocationsOrderDaysInitTuesday', this.dishAllocationsOrderDaysInitTuesday);
				      		//-------------------------------------------------------------
				      	/*dishAllocationsWeekResponse[0]['servings'][servingDayIndex].orderNr = dishAllocationsWeekResponse[0]['orderNr'];
				      	dishAllocationsWeekResponse[0]['servings'][servingDayIndex].customerNr = dishAllocationsWeekResponse[0]['customerNr'];
				      	dishAllocationsWeekResponse[0]['servings'][servingDayIndex].menuNr = dishAllocationsWeekResponse[0]['menuNr'];
                */
				      	//-------------------------------------------------------------
				      		this.dishAllocationsOrderDays['Friday']=dishAllocationsWeekResponse[0]['servings'][servingDayIndex];
				      		this.dishAllocationsOrderDays['Friday']['mainDishes']=this.productNrFilter(this.dishAllocationsOrderDays['Friday'], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Friday'));
				      		this.sortResponseDay(this.dishAllocationsOrderDays['Friday']);
				      		let dishAllocationsOrderDaysEnergitat=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Friday']));
				      		this.dishEnergitatAllDayFilteredDietTypeGroups['Friday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysEnergitat, 'Energitæt')));
				      		if(this.dishEnergitatAllDayFilteredDietTypeGroups['Friday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isEnergitatEmptyFriday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyFriday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isEnergitatEmptyFriday = false;
				      		}
				      		let dishAllocationsOrderDaysBlod=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Friday']));
				      		this.dishBlodAllDayFilteredDietTypeGroups['Friday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysBlod, 'Blød')));
				      		if(this.dishBlodAllDayFilteredDietTypeGroups['Friday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isBlodEmptyFriday = true;
				      			console.debug('[ngOninit]  this.isBlodEmptyFriday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isBlodEmptyFriday = false;
				      		}
				      		this.dishAllDayFilteredDietTypeGroups['Friday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays['Friday'])));
				      		console.debug('this.dishAllocationsOrderDaysTuesday', JSON.stringify(this.dishAllocationsOrderDays));
				      	}else{

				      		this.dishAllocationsOrderDays['Friday']=dishAllocationsWeekResponse[0]['servings'][servingDayIndex];
				      	}
			      		//-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Friday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));

				      	if(_.isEmpty(this.dishAllocationsOrderDays['Friday'])){
				      		console.debug('this.dishAllocationsOrderDays[\'Friday\'].length==0')
				      		this.isEmptyFriday=true;
				      	}
			      	}else{
			      	this.isEmptyFriday=true;
			      }

//----------------------------------------------------------------------------------

			      	if((dishAllocationsWeekResponse[0]
			      	&& dishAllocationsWeekResponse[0]['servings'].length!=0
			      	&& dishAllocationsWeekResponse[0]['servings']['2']!== undefined
			      	&& dishAllocationsWeekResponse[0]['servings']['2']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Saturday', this.shiftWeek))
					||(this.dishAllocationsWeekResponse[1]!==undefined
					&& dishAllocationsWeekResponse[0]['servings'].length!=0
			      	&& dishAllocationsWeekResponse[0]['servings']['1']!== undefined
					&& dishAllocationsWeekResponse[0]['servings']['1']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Saturday', this.shiftWeek))
					 || (dishAllocationsWeekResponse[0]!==undefined
					&& dishAllocationsWeekResponse[0]['servings'].length!=0
					&& dishAllocationsWeekResponse[0]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Saturday', this.shiftWeek))){
			      		let servingDayIndex = 2;
						//this.dateService.getISODateYYYMMDDforDay("Saturday", this.shiftWeek)
						console.debug('FridayISO', this.dateService.getISODateYYYMMDDforDay('Saturday', this.shiftWeek));
						if(dishAllocationsWeekResponse[0]['servings']['0'] &&
							dishAllocationsWeekResponse[0]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Saturday', this.shiftWeek)){
							servingDayIndex = 0
						}else if(dishAllocationsWeekResponse[0]['servings']['1'] &&
							dishAllocationsWeekResponse[0]['servings']['1']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Saturday', this.shiftWeek)){
							servingDayIndex = 1
						}

						console.debug('servingDayIndex', servingDayIndex);
				    //this.dishAllocationsOrderDays["Saturday"]=dishAllocationsWeekResponse[0];
				    if(this.orderFromViewWeekPlan){
				   if(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes']==[]){

				      			dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'] = dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes'];
				      		}else if(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes']!=[]

				      			&& dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes']!=[]){
			      				dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'] = dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'].concat(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes']);

				      		console.debug('dishAllocationsWeekResponse[0][servings][servingDayIndex]', JSON.stringify(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]));
			      			}


				      		console.debug('this.dishAllocationsOrderDaysInitTuesday', this.dishAllocationsOrderDaysInitTuesday);
				      		//-------------------------------------------------------------
				      	/*dishAllocationsWeekResponse[0]['servings'][servingDayIndex].orderNr = dishAllocationsWeekResponse[0]['orderNr'];
				      	dishAllocationsWeekResponse[0]['servings'][servingDayIndex].customerNr = dishAllocationsWeekResponse[0]['customerNr'];
				      	dishAllocationsWeekResponse[0]['servings'][servingDayIndex].menuNr = dishAllocationsWeekResponse[0]['menuNr'];
                  */
				      	//-------------------------------------------------------------
				      		this.dishAllocationsOrderDays['Saturday']=dishAllocationsWeekResponse[0]['servings'][servingDayIndex];
				      		this.dishAllocationsOrderDays['Saturday']['mainDishes']=this.productNrFilter(this.dishAllocationsOrderDays['Saturday'], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Saturday'));
				      		this.sortResponseDay(this.dishAllocationsOrderDays['Saturday']);
				      		let dishAllocationsOrderDaysEnergitat=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Saturday']));
				      		this.dishEnergitatAllDayFilteredDietTypeGroups['Saturday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysEnergitat, 'Energitæt')));
				      		if(this.dishEnergitatAllDayFilteredDietTypeGroups['Saturday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isEnergitatEmptySaturday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptySaturday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isEnergitatEmptySaturday = false;
				      		}
				      		let dishAllocationsOrderDaysBlod=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Saturday']));
				      		this.dishBlodAllDayFilteredDietTypeGroups['Saturday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysBlod, 'Blød')));
				      		if(this.dishBlodAllDayFilteredDietTypeGroups['Saturday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isBlodEmptySaturday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptySaturday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isBlodEmptySaturday = false;
				      		}
				      		this.dishAllDayFilteredDietTypeGroups['Saturday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays['Saturday'])));
				      		console.debug('this.dishAllocationsOrderDaysTuesday', JSON.stringify(this.dishAllocationsOrderDays));
				      	}else{

				      		this.dishAllocationsOrderDays['Saturday']=dishAllocationsWeekResponse[0]['servings'][servingDayIndex];
				      	}
			      		//-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Saturday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));

				      	if(_.isEmpty(this.dishAllocationsOrderDays['Saturday'])){
				      		console.debug('this.dishAllocationsOrderDays[\'Saturday\'].length==0')
				      		this.isEmptyFriday=true;
				      	}
			      	}else{
			      	this.isEmptyFriday=true;
			      }


					break;
				  }

				}
			case 'Saturday':
				{	if(dishAllocationsWeekResponse[0] && dishAllocationsWeekResponse[0]['servings']){

					//dishAllocationsWeekResponse[0]["servings"] = JSON.parse(JSON.stringify(this.filterResponseByUndefined(this.dishAllocationsWeekResponse)));
					if(dishAllocationsWeekResponse[0] && dishAllocationsWeekResponse[0]['servings']!=[]
						&& dishAllocationsWeekResponse[0]['servings']['0']!==undefined
						&& dishAllocationsWeekResponse[0]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Sunday', this.shiftWeek +1)){

				    //this.dishAllocationsOrderDays["Saturday"]=dishAllocationsWeekResponse[0];
				   if(this.orderFromViewWeekPlan){
				      	 if(dishAllocationsWeekResponse[0]['servings']['0']['mainDishes']==[]){

				      			dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'] = dishAllocationsWeekResponse[0]['servings']['0']['sideDishes'];
				      		}else if(dishAllocationsWeekResponse[0]['servings']['0']['mainDishes']!=[]

				      			&& dishAllocationsWeekResponse[0]['servings']['0']['sideDishes']!=[]){
			      				dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'] = dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'].concat(dishAllocationsWeekResponse[0]['servings']['0']['sideDishes']);

				      		console.debug('dishAllocationsWeekResponse[0][servings][0]', JSON.stringify(dishAllocationsWeekResponse[0]['servings'][0]));
			      			}


				      		//console.debug("this.dishAllocationsOrderDaysInitSunday", this.dishAllocationsOrderDaysInitTuesday);
				      		//-------------------------------------------------------------
				      	/*dishAllocationsWeekResponse[0]['servings'][0].orderNr = dishAllocationsWeekResponse[0]['orderNr'];
				      	dishAllocationsWeekResponse[0]['servings'][0].customerNr = dishAllocationsWeekResponse[0]['customerNr'];
				      	dishAllocationsWeekResponse[0]['servings'][0].menuNr = dishAllocationsWeekResponse[0]['menuNr'];
                  */
				      	//-------------------------------------------------------------
				      		this.dishAllocationsOrderDays['Sunday']=dishAllocationsWeekResponse[0]['servings'][0];
				      		this.sortResponseDay(this.dishAllocationsOrderDays['Sunday']);
				      		let dishAllocationsOrderDaysEnergitat=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Sunday']));
				      		this.dishEnergitatAllDayFilteredDietTypeGroups['Sunday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysEnergitat, 'Energitæt')));
				      		if(this.dishEnergitatAllDayFilteredDietTypeGroups['Sunday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isEnergitatEmptySunday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptySunday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isEnergitatEmptySunday = false;
				      		}
				      		let dishAllocationsOrderDaysBlod=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Sunday']));
				      		this.dishBlodAllDayFilteredDietTypeGroups['Sunday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysBlod, 'Blød')));
				      		if(this.dishBlodAllDayFilteredDietTypeGroups['Sunday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isBlodEmptySunday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptySunday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isBlodEmptySunday = false;
				      		}
				      		this.dishAllDayFilteredDietTypeGroups['Sunday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays['Sunday'])));
				      		console.debug('this.dishAllocationsOrderDaysSunday', JSON.stringify(this.dishAllocationsOrderDays));
				      	}else{

				      		this.dishAllocationsOrderDays['Sunday']=dishAllocationsWeekResponse[0]['servings'][0];
				    }
				    	//-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Sunday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));

				      	if(_.isEmpty(this.dishAllocationsOrderDays['Sunday'])){
				      		console.debug('this.dishAllocationsOrderDays[\'Sunday\'].length==0')
				      		this.isEmptySunday=true;
				      	}
					}else{
			      	this.isEmptySunday=true;
			      }

			      if((dishAllocationsWeekResponse[0]
			      	&& dishAllocationsWeekResponse[0]['servings'].length!=0
			      	&& dishAllocationsWeekResponse[0]['servings']['1']!== undefined
			      	&& dishAllocationsWeekResponse[0]['servings']['1']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Monday', this.shiftWeek+1))
					 || (dishAllocationsWeekResponse[0]!==undefined
					&& dishAllocationsWeekResponse[0]['servings'].length!=0
					&& dishAllocationsWeekResponse[0]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Monday', this.shiftWeek+1))){
			      		let servingDayIndex = 1;
						//this.dateService.getISODateYYYMMDDforDay("Monday", this.shiftWeek)
						console.debug('MondayISO', this.dateService.getISODateYYYMMDDforDay('Monday', this.shiftWeek));
						if(dishAllocationsWeekResponse[0]['servings']['0'] &&
							dishAllocationsWeekResponse[0]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Monday', this.shiftWeek+1)){
							servingDayIndex = 0
						}
				     //this.dishAllocationsOrderDays["Monday"]=dishAllocationsWeekResponse[0];
				    if(this.orderFromViewWeekPlan){
				    if(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes']==[]){

				      			dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'] = dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes'];
				      		}else if(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes']!=[]

				      			&& dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes']!=[]){
			      				dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'] = dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'].concat(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes']);

				      		console.debug('dishAllocationsWeekResponse[0][servings][servingDayIndex]', JSON.stringify(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]));
			      			}


				      		//console.debug("this.dishAllocationsOrderDaysInitMonday", this.dishAllocationsOrderDaysInitTuesday);
				      		//-------------------------------------------------------------
				      	/*dishAllocationsWeekResponse[0]['servings'][servingDayIndex].orderNr = dishAllocationsWeekResponse[0]['orderNr'];
				      	dishAllocationsWeekResponse[0]['servings'][servingDayIndex].customerNr = dishAllocationsWeekResponse[0]['customerNr'];
				      	dishAllocationsWeekResponse[0]['servings'][servingDayIndex].menuNr = dishAllocationsWeekResponse[0]['menuNr'];
                  */
				      	//-------------------------------------------------------------
				      		this.dishAllocationsOrderDays['Monday']=dishAllocationsWeekResponse[0]['servings'][servingDayIndex];
				      		this.dishAllocationsOrderDays['Monday']['mainDishes']=this.productNrFilter(this.dishAllocationsOrderDays['Monday'], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Monday'));
				      		this.sortResponseDay(this.dishAllocationsOrderDays['Monday']);
				      		let dishAllocationsOrderDaysEnergitat=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Monday']));
				      		this.dishEnergitatAllDayFilteredDietTypeGroups['Monday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysEnergitat, 'Energitæt')));
				      		if(this.dishEnergitatAllDayFilteredDietTypeGroups['Monday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isEnergitatEmptyMonday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyMonday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isEnergitatEmptyMonday = false;
				      		}
				      		let dishAllocationsOrderDaysBlod=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Monday']));
				      		this.dishBlodAllDayFilteredDietTypeGroups['Monday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysBlod, 'Blød')));
				      		if(this.dishBlodAllDayFilteredDietTypeGroups['Monday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isBlodEmptyMonday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyMonday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isBlodEmptyMonday = false;
				      		}
				      		this.dishAllDayFilteredDietTypeGroups['Monday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays['Monday'])));
				      		console.debug('this.dishAllocationsOrderDaysMonday', JSON.stringify(this.dishAllocationsOrderDays));
				      	}else{

				      		this.dishAllocationsOrderDays['Monday']=dishAllocationsWeekResponse[0]['servings'][servingDayIndex];
				    }
				    //-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Monday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));

				      	if(_.isEmpty(this.dishAllocationsOrderDays['Monday'])){
				      		console.debug('this.dishAllocationsOrderDays[\'Monday\'].length==0')
				      		this.isEmptyMonday=true;
				      	}
			      	}else{
			      	this.isEmptyMonday=true;
			      }


					break;

					}

				}
				//case 'Week':

				case 'Week':
				{
					console.debug('[ngOninit] In WEEK', this.dishAllocationsWeekResponse)
				//{
					if(dishAllocationsWeekResponse[0] && dishAllocationsWeekResponse[0]['servings']){

					//dishAllocationsWeekResponse[0]["servings"] = JSON.parse(JSON.stringify(this.filterResponseByUndefined(this.dishAllocationsWeekResponse)));
					if(dishAllocationsWeekResponse[0] && dishAllocationsWeekResponse[0]['servings']!=[]
						&& dishAllocationsWeekResponse[0]['servings']['0']!==undefined
						&& dishAllocationsWeekResponse[0]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Tuesday', this.shiftWeek)){

				      	if(this.orderFromViewWeekPlan){

				      		if(dishAllocationsWeekResponse[0]['servings']['0']['mainDishes']==[]){

				      			dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'] = dishAllocationsWeekResponse[0]['servings']['0']['sideDishes'];
				      		}else if(dishAllocationsWeekResponse[0]['servings']['0']['mainDishes']!=[]
				      			&& dishAllocationsWeekResponse[0]['servings']['0']['sideDishes']!=[]){
			      				dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'] = dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'].concat(dishAllocationsWeekResponse[0]['servings']['0']['sideDishes']);

				      		console.debug('dishAllocationsWeekResponse[0][servings][0]', JSON.stringify(dishAllocationsWeekResponse[0]['servings'][0]));
			      			}

				      		console.debug('this.dishAllocationsOrderDaysInitTuesday', this.dishAllocationsOrderDaysInitTuesday);
				      		//-------------------------------------------------------------
				      	/*dishAllocationsWeekResponse[0]['servings'][0].orderNr = dishAllocationsWeekResponse[0]['orderNr'];
				      	dishAllocationsWeekResponse[0]['servings'][0].customerNr = dishAllocationsWeekResponse[0]['customerNr'];
				      	dishAllocationsWeekResponse[0]['servings'][0].menuNr = dishAllocationsWeekResponse[0]['menuNr'];
                  */
				      	//-------------------------------------------------------------
				      		this.dishAllocationsOrderDays['Tuesday']=dishAllocationsWeekResponse[0]['servings'][0];
				      		this.dishAllocationsOrderDays['Tuesday']['mainDishes']=this.productNrFilter(this.dishAllocationsOrderDays['Tuesday'], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Tuesday'));
				      		this.sortResponseDay(this.dishAllocationsOrderDays['Tuesday']);
				      		let dishAllocationsOrderDaysEnergitat=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Tuesday']));
				      		this.dishEnergitatAllDayFilteredDietTypeGroups['Tuesday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysEnergitat, 'Energitæt')));
				      		if(this.dishEnergitatAllDayFilteredDietTypeGroups['Tuesday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isEnergitatEmptyTuesday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyTuesday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isEnergitatEmptyTuesday = false;
				      		}
				      		let dishAllocationsOrderDaysBlod=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Tuesday']));
				      		this.dishBlodAllDayFilteredDietTypeGroups['Tuesday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysBlod, 'Blød')));
				      		if(this.dishBlodAllDayFilteredDietTypeGroups['Tuesday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isBlodEmptyTuesday = true;
				      			console.debug('[ngOninit] this.isBlodEmptyTuesday', this.isBlodEmptyTuesday);
				      		}else{
				      			this.isBlodEmptyTuesday = false;
				      		}
console.debug('[ngOninit] BlodAllDayFilteredDietTypeGroups', this.dishBlodAllDayFilteredDietTypeGroups['Tuesday']);
				      		this.dishAllDayFilteredDietTypeGroups['Tuesday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays['Tuesday'])));
				      		console.debug('[ngOninit] this.dishAllocationsOrderDaysTuesday', JSON.stringify(this.dishAllocationsOrderDays));
console.debug('[ngOninit] BlodAllDayFilteredDietTypeGroups2', this.dishBlodAllDayFilteredDietTypeGroups['Tuesday']);
				      	}else{

				      		this.dishAllocationsOrderDays['Tuesday']=dishAllocationsWeekResponse[0]['servings'][0];

				      	}
				      	//-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Tuesday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));
				      	if(_.isEmpty(this.dishAllocationsOrderDays['Tuesday'])){

				      		console.debug('this.dishAllocationsOrderDays[\'Tuesday\'].length==0')
				      		this.isEmptyTuesday=true;
				  		}



			      }else{
			      	this.isEmptyTuesday=true;
			      }

				//console.debug("[ngOninit] AFTER Tuesday", this.dishAllocationsWeekResponse)
				if((dishAllocationsWeekResponse[0]!
					&& dishAllocationsWeekResponse[0]['servings'].length!=0
			      	&& dishAllocationsWeekResponse[0]['servings']['1']!== undefined
					&& dishAllocationsWeekResponse[0]['servings']['1']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Wednesday', this.shiftWeek))
					 || (dishAllocationsWeekResponse[0]!==undefined
					&& dishAllocationsWeekResponse[0]['servings'].length!=0
					&& dishAllocationsWeekResponse[0]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Wednesday', this.shiftWeek))){
						let servingDayIndex = 1;
						//this.dateService.getISODateYYYMMDDforDay("Wednesday", this.shiftWeek)
						console.debug('WednesdayISO', this.dateService.getISODateYYYMMDDforDay('Wednesday', this.shiftWeek));
						if(dishAllocationsWeekResponse[0]['servings']['0'] &&
						 dishAllocationsWeekResponse[0]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Wednesday', this.shiftWeek)){
							servingDayIndex = 0
						}

						console.debug('servingDayIndex', servingDayIndex);
						//console.debug("dishAllocationsWeekResponseW", this.dishAllocationsWeekResponse);
			      		//this.dishAllocationsOrderDays["Wednesday"]=dishAllocationsWeekResponse[0];

			      		if(this.orderFromViewWeekPlan){
			      			if(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes']==[]){


				      			dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'] = dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes'];
				      		}else if(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes']!=[]

				      			&& dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes']!=[]){
			      				dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'] = dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['mainDishes'].concat(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]['sideDishes']);

				      		console.debug('dishAllocationsWeekResponse[0][servings][1]', JSON.stringify(dishAllocationsWeekResponse[0]['servings'][servingDayIndex]));
			      			}
				      		//-------------------------------------------------------------
				      	/*dishAllocationsWeekResponse[0]['servings'][servingDayIndex].orderNr = dishAllocationsWeekResponse[0]['orderNr'];
				      	dishAllocationsWeekResponse[0]['servings'][servingDayIndex].customerNr = dishAllocationsWeekResponse[0]['customerNr'];
				      	dishAllocationsWeekResponse[0]['servings'][servingDayIndex].menuNr = dishAllocationsWeekResponse[0]['menuNr'];
                  */
				      	//-------------------------------------------------------------
				      		this.dishAllocationsOrderDays['Wednesday']=dishAllocationsWeekResponse[0]['servings'][servingDayIndex];
				      		this.dishAllocationsOrderDays['Wednesday']['mainDishes']=this.productNrFilter(this.dishAllocationsOrderDays['Wednesday'], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Wednesday'));
				      		this.sortResponseDay(this.dishAllocationsOrderDays['Wednesday']);
				      		let dishAllocationsOrderDaysEnergitat=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Wednesday']));
				      		this.dishEnergitatAllDayFilteredDietTypeGroups['Wednesday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysEnergitat, 'Energitæt')));
				      		if(this.dishEnergitatAllDayFilteredDietTypeGroups['Wednesday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isEnergitatEmptyWednesday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyWednesday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isEnergitatEmptyWednesday = false;
				      		}
				      		let dishAllocationsOrderDaysBlod=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Wednesday']));
				      		this.dishBlodAllDayFilteredDietTypeGroups['Wednesday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysBlod, 'Blød')));
				      		if(this.dishBlodAllDayFilteredDietTypeGroups['Wednesday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isBlodEmptyWednesday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyWednesday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isBlodEmptyWednesday = false;
				      		}
				      		this.dishAllDayFilteredDietTypeGroups['Wednesday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays['Wednesday'])));
				      		console.debug('this.dishAllocationsOrderDaysTuesday', JSON.stringify(this.dishAllocationsOrderDays));
				      	}else{

				      		//this.dishAllocationsOrderDays["Wednesday"]=this.dishAllocationsWeekResponse[1];
				      		this.dishAllocationsOrderDays['Wednesday']=dishAllocationsWeekResponse[0]['servings'][servingDayIndex];
				      	}
				      	//-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Wednesday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));
			      		if(_.isEmpty(this.dishAllocationsOrderDays['Wednesday'])){
			      		console.debug('this.dishAllocationsOrderDays[\'Wednesday\'].length==0')
			      		this.isEmptyWednesday=true;
			      		}

					}else{
			      	this.isEmptyWednesday=true;
			      }
			   // break;

				    }

		   //   }


					//console.debug("[ngOninit] AFTER Wednesday", this.dishAllocationsWeekResponse)
			//case 'Week':
			//	{
					if(this.dishAllocationsWeekResponse[1] && this.dishAllocationsWeekResponse[1]['servings']){
				    console.debug('this.dishAllocationsWeekResponseAFTER FBA', JSON.stringify(this.dishAllocationsWeekResponse));
					//this.dishAllocationsWeekResponse[1]["servings"] = JSON.parse(JSON.stringify(this.filterResponseByUndefined(this.dishAllocationsWeekResponse)));
					console.debug('this.dishAllocationsWeekResponseAFTER FBR', JSON.stringify(this.dishAllocationsWeekResponse));

					if(this.dishAllocationsWeekResponse[1] && this.dishAllocationsWeekResponse[1]['servings']!=[]
						&& this.dishAllocationsWeekResponse[1]['servings']['0']!==undefined
						&& this.dishAllocationsWeekResponse[1]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Thursday', this.shiftWeek)){

						if(this.orderFromViewWeekPlan){
			      	if(this.dishAllocationsWeekResponse[1]['servings']['0']['mainDishes']==[]){

				      			this.dishAllocationsWeekResponse[1]['servings']['0']['mainDishes'] = this.dishAllocationsWeekResponse[1]['servings']['0']['sideDishes'];
				      		}else if(this.dishAllocationsWeekResponse[1]['servings']['0']['mainDishes']!=[]

				      			&& this.dishAllocationsWeekResponse[1]['servings']['0']['sideDishes']!=[]){
			      				this.dishAllocationsWeekResponse[1]['servings']['0']['mainDishes'] = this.dishAllocationsWeekResponse[1]['servings']['0']['mainDishes'].concat(this.dishAllocationsWeekResponse[1]['servings']['0']['sideDishes']);

				      		console.debug('this.dishAllocationsWeekResponse[1][servings][0]', JSON.stringify(this.dishAllocationsWeekResponse[1]['servings'][0]));
			      			}

				      		//-------------------------------------------------------------
				      	/*this.dishAllocationsWeekResponse[1]['servings'][0].orderNr = this.dishAllocationsWeekResponse[1]['orderNr'];
				      	this.dishAllocationsWeekResponse[1]['servings'][0].customerNr = this.dishAllocationsWeekResponse[1]['customerNr'];
				      	this.dishAllocationsWeekResponse[1]['servings'][0].menuNr = this.dishAllocationsWeekResponse[1]['menuNr'];
                  */
				      	//-------------------------------------------------------------
				      		this.dishAllocationsOrderDays['Thursday']=this.dishAllocationsWeekResponse[1]['servings'][0];
				      		this.dishAllocationsOrderDays['Thursday']['mainDishes']=this.productNrFilter(this.dishAllocationsOrderDays['Thursday'], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Thursday'));
				      		this.sortResponseDay(this.dishAllocationsOrderDays['Thursday']);
				      		let dishAllocationsOrderDaysEnergitat=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Thursday']));
				      		this.dishEnergitatAllDayFilteredDietTypeGroups['Thursday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysEnergitat, 'Energitæt')));
				      		if(this.dishEnergitatAllDayFilteredDietTypeGroups['Thursday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isEnergitatEmptyThursday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyThursday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isEnergitatEmptyThursday = false;
				      		}
				      		let dishAllocationsOrderDaysBlod=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Thursday']));
				      		this.dishBlodAllDayFilteredDietTypeGroups['Thursday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysBlod, 'Blød')));
				      		if(this.dishBlodAllDayFilteredDietTypeGroups['Thursday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isBlodEmptyThursday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyThursday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isBlodEmptyThursday = false;
				      		}
				      		this.dishAllDayFilteredDietTypeGroups['Thursday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays['Thursday'])));
				      		console.debug('this.dishAllocationsOrderDaysTuesday', JSON.stringify(this.dishAllocationsOrderDays));
				      	}else{

				      		this.dishAllocationsOrderDays['Thursday']=this.dishAllocationsWeekResponse[1]['servings'][0];
				      	}
				    //-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Thursday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));
			      	if(_.isEmpty(this.dishAllocationsOrderDays['Thursday'])){
			      		console.debug('this.dishAllocationsOrderDays[\'Thursday\'].length==0')
			      		this.isEmptyThursday=true;
			      	}
					}else{
			      	this.isEmptyThursday=true;
			      }
			      	console.debug('this.dishAllocationsWeekResponseAFTER FRI', JSON.stringify(this.dishAllocationsWeekResponse));
			      	console.debug('[ngOninit]  IN Friday this.dishAllocationsWeekResponse', this.dishAllocationsWeekResponse);
					//console.debug("[ngOninit]  IN Friday this.dishAllocationsWeekResponse[1][servings][0][servingDate]", this.dishAllocationsWeekResponse[1]["servings"]["0"]["servingDate"]);
			      	/*if(this.dishAllocationsWeekResponse[1]!==undefined
			      		&& this.dishAllocationsWeekResponse[1]["servings"].length!=0){ */
			      	if((this.dishAllocationsWeekResponse[1]
			      	&& this.dishAllocationsWeekResponse[1]['servings'].length!=0
			      	&& this.dishAllocationsWeekResponse[1]['servings']['1']!== undefined
			      	&& this.dishAllocationsWeekResponse[1]['servings']['1']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Friday', this.shiftWeek))
					 || (this.dishAllocationsWeekResponse[1]!==undefined
					&& this.dishAllocationsWeekResponse[1]['servings'].length!=0
					&& this.dishAllocationsWeekResponse[1]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Friday', this.shiftWeek))){
			      		console.debug('[ngOninit]  IN Friday', );
			      		console.debug('Err', this.dishAllocationsWeekResponse[1]['servings']);
			      		let servingDayIndex = 1;
						//this.dateService.getISODateYYYMMDDforDay("Friday", this.shiftWeek)
						console.debug('FridayISO', this.dateService.getISODateYYYMMDDforDay('Friday', this.shiftWeek));
						if(this.dishAllocationsWeekResponse[1]['servings']['0']!==undefined &&
							this.dishAllocationsWeekResponse[1]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Friday', this.shiftWeek)){
							servingDayIndex = 0
						}

						console.debug('servingDayIndex', servingDayIndex);
				    //this.dishAllocationsOrderDays["Friday"]=this.dishAllocationsWeekResponse[1];
				    if(this.orderFromViewWeekPlan){
				   if(this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['mainDishes']==[]){

				      			this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['mainDishes'] = this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['sideDishes'];
				      		}else if(this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['mainDishes']!=[]

				      			&& this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['sideDishes']!=[]){
			      				this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['mainDishes'] = this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['mainDishes'].concat(this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['sideDishes']);

				      		console.debug('this.dishAllocationsWeekResponse[1][servings][servingDayIndex]', JSON.stringify(this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]));
			      			}


				      		console.debug('this.dishAllocationsOrderDaysInitTuesday', this.dishAllocationsOrderDaysInitTuesday);
				      		//-------------------------------------------------------------
				      	/*this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex].orderNr = this.dishAllocationsWeekResponse[1]['orderNr'];
				      	this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex].customerNr = this.dishAllocationsWeekResponse[1]['customerNr'];
				      	this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex].menuNr = this.dishAllocationsWeekResponse[1]['menuNr'];
                  */
				      	//-------------------------------------------------------------
				      		this.dishAllocationsOrderDays['Friday']=this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex];
				      		this.dishAllocationsOrderDays['Friday']['mainDishes']=this.productNrFilter(this.dishAllocationsOrderDays['Friday'], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Friday'));
				      		this.sortResponseDay(this.dishAllocationsOrderDays['Friday']);
				      		let dishAllocationsOrderDaysEnergitat=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Friday']));
				      		this.dishEnergitatAllDayFilteredDietTypeGroups['Friday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysEnergitat, 'Energitæt')));
				      		if(this.dishEnergitatAllDayFilteredDietTypeGroups['Friday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isEnergitatEmptyFriday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyFriday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isEnergitatEmptyFriday = false;
				      		}
				      		let dishAllocationsOrderDaysBlod=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Friday']));
				      		this.dishBlodAllDayFilteredDietTypeGroups['Friday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysBlod, 'Blød')));
				      		if(this.dishBlodAllDayFilteredDietTypeGroups['Friday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isBlodEmptyFriday = true;
				      			console.debug('[ngOninit]  this.isBlodEmptyFriday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isBlodEmptyFriday = false;
				      		}
				      		this.dishAllDayFilteredDietTypeGroups['Friday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays['Friday'])));
				      		console.debug('this.dishAllocationsOrderDaysTuesday', JSON.stringify(this.dishAllocationsOrderDays));
				      	}else{

				      		this.dishAllocationsOrderDays['Friday']=this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex];
				      	}
			      		//-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Friday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));

				      	if(_.isEmpty(this.dishAllocationsOrderDays['Friday'])){
				      		console.debug('this.dishAllocationsOrderDays[\'Friday\'].length==0')
				      		this.isEmptyFriday=true;
				      	}
			      	}else{
			      	this.isEmptyFriday=true;
			      }

//----------------------------------------------------------------------------------
					/*if(this.dishAllocationsWeekResponse[1]!==undefined
						&& this.dishAllocationsWeekResponse[1]["servings"].length!=0){
			      	*/
			      	if((this.dishAllocationsWeekResponse[1]
			      	&& this.dishAllocationsWeekResponse[1]['servings'].length!=0
			      	&& this.dishAllocationsWeekResponse[1]['servings']['2']!== undefined
			      	&& this.dishAllocationsWeekResponse[1]['servings']['2']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Saturday', this.shiftWeek))
					||(this.dishAllocationsWeekResponse[1]!==undefined
					&& this.dishAllocationsWeekResponse[1]['servings'].length!=0
			      	&& this.dishAllocationsWeekResponse[1]['servings']['1']!== undefined
					&& this.dishAllocationsWeekResponse[1]['servings']['1']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Saturday', this.shiftWeek))
					 || (this.dishAllocationsWeekResponse[1]!==undefined
					&& this.dishAllocationsWeekResponse[1]['servings'].length!=0
					&& this.dishAllocationsWeekResponse[1]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Saturday', this.shiftWeek))){
			      		let servingDayIndex = 2;
						//this.dateService.getISODateYYYMMDDforDay("Saturday", this.shiftWeek)
						console.debug('FridayISO', this.dateService.getISODateYYYMMDDforDay('Saturday', this.shiftWeek));
						if(this.dishAllocationsWeekResponse[1]['servings']['0'] &&
							this.dishAllocationsWeekResponse[1]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Saturday', this.shiftWeek)){
							servingDayIndex = 0
						}else if(this.dishAllocationsWeekResponse[1]['servings']['1'] &&
							this.dishAllocationsWeekResponse[1]['servings']['1']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Saturday', this.shiftWeek)){
							servingDayIndex = 1
						}

						console.debug('servingDayIndex', servingDayIndex);
				    //this.dishAllocationsOrderDays["Saturday"]=this.dishAllocationsWeekResponse[1];
				    if(this.orderFromViewWeekPlan){
				   if(this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['mainDishes']==[]){

				      			this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['mainDishes'] = this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['sideDishes'];
				      		}else if(this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['mainDishes']!=[]

				      			&& this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['sideDishes']!=[]){
			      				this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['mainDishes'] = this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['mainDishes'].concat(this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]['sideDishes']);

				      		console.debug('this.dishAllocationsWeekResponse[1][servings][servingDayIndex]', JSON.stringify(this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex]));
			      			}


				      		console.debug('this.dishAllocationsOrderDaysInitTuesday', this.dishAllocationsOrderDaysInitTuesday);
				      		//-------------------------------------------------------------
				      	/*this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex].orderNr = this.dishAllocationsWeekResponse[1]['orderNr'];
				      	this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex].customerNr = this.dishAllocationsWeekResponse[1]['customerNr'];
				      	this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex].menuNr = this.dishAllocationsWeekResponse[1]['menuNr'];
                  */
				      	//-------------------------------------------------------------
				      		this.dishAllocationsOrderDays['Saturday']=this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex];
				      		this.dishAllocationsOrderDays['Saturday']['mainDishes']=this.productNrFilter(this.dishAllocationsOrderDays['Saturday'], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Saturday'));
				      		this.sortResponseDay(this.dishAllocationsOrderDays['Saturday']);
				      		let dishAllocationsOrderDaysEnergitat=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Saturday']));
				      		this.dishEnergitatAllDayFilteredDietTypeGroups['Saturday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysEnergitat, 'Energitæt')));
				      		if(this.dishEnergitatAllDayFilteredDietTypeGroups['Saturday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isEnergitatEmptySaturday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptySaturday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isEnergitatEmptySaturday = false;
				      		}
				      		let dishAllocationsOrderDaysBlod=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Saturday']));
				      		this.dishBlodAllDayFilteredDietTypeGroups['Saturday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysBlod, 'Blød')));
				      		if(this.dishBlodAllDayFilteredDietTypeGroups['Saturday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isBlodEmptySaturday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptySaturday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isBlodEmptySaturday = false;
				      		}
				      		this.dishAllDayFilteredDietTypeGroups['Saturday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays['Saturday'])));
				      		console.debug('this.dishAllocationsOrderDaysTuesday', JSON.stringify(this.dishAllocationsOrderDays));
				      	}else{

				      		this.dishAllocationsOrderDays['Saturday']=this.dishAllocationsWeekResponse[1]['servings'][servingDayIndex];
				      	}
			      		//-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Saturday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));

				      	if(_.isEmpty(this.dishAllocationsOrderDays['Saturday'])){
				      		console.debug('this.dishAllocationsOrderDays[\'Saturday\'].length==0')
				      		this.isEmptyFriday=true;
				      	}
			      	}else{
			      	this.isEmptyFriday=true;
			      }


				//	break;
				  }

			//	}
			//case 'Week':
			//	{
					if(this.dishAllocationsWeekResponse[2] && this.dishAllocationsWeekResponse[2]['servings']){

					//this.dishAllocationsWeekResponse[2]["servings"] = JSON.parse(JSON.stringify(this.filterResponseByUndefined(this.dishAllocationsWeekResponse)));
					if(this.dishAllocationsWeekResponse[2] && this.dishAllocationsWeekResponse[2]['servings']!=[]
						&& this.dishAllocationsWeekResponse[2]['servings']['0']!==undefined
						&& this.dishAllocationsWeekResponse[2]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Sunday', this.shiftWeek +1)){
						if(this.orderFromViewWeekPlan){
				      	 if(this.dishAllocationsWeekResponse[2]['servings']['0']['mainDishes']==[]){

				      			this.dishAllocationsWeekResponse[2]['servings']['0']['mainDishes'] = this.dishAllocationsWeekResponse[2]['servings']['0']['sideDishes'];
				      		}else if(this.dishAllocationsWeekResponse[2]['servings']['0']['mainDishes']!=[]

				      			&& this.dishAllocationsWeekResponse[2]['servings']['0']['sideDishes']!=[]){
			      				this.dishAllocationsWeekResponse[2]['servings']['0']['mainDishes'] = this.dishAllocationsWeekResponse[2]['servings']['0']['mainDishes'].concat(this.dishAllocationsWeekResponse[2]['servings']['0']['sideDishes']);

				      		console.debug('this.dishAllocationsWeekResponse[2][servings][0]', JSON.stringify(this.dishAllocationsWeekResponse[2]['servings'][0]));
			      			}


				      		//console.debug("this.dishAllocationsOrderDaysInitSunday", this.dishAllocationsOrderDaysInitTuesday);
				      		//-------------------------------------------------------------
				      	/*this.dishAllocationsWeekResponse[2]['servings'][0].orderNr = this.dishAllocationsWeekResponse[2]['orderNr'];
				      	this.dishAllocationsWeekResponse[2]['servings'][0].customerNr = this.dishAllocationsWeekResponse[2]['customerNr'];
				      	this.dishAllocationsWeekResponse[2]['servings'][0].menuNr = this.dishAllocationsWeekResponse[2]['menuNr'];
                  */
				      	//-------------------------------------------------------------
				      		this.dishAllocationsOrderDays['Sunday']=this.dishAllocationsWeekResponse[2]['servings'][0];
				      		this.sortResponseDay(this.dishAllocationsOrderDays['Sunday']);
				      		let dishAllocationsOrderDaysEnergitat=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Sunday']));
				      		this.dishEnergitatAllDayFilteredDietTypeGroups['Sunday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysEnergitat, 'Energitæt')));
				      		if(this.dishEnergitatAllDayFilteredDietTypeGroups['Sunday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isEnergitatEmptySunday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptySunday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isEnergitatEmptySunday = false;
				      		}
				      		let dishAllocationsOrderDaysBlod=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Sunday']));
				      		this.dishBlodAllDayFilteredDietTypeGroups['Sunday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysBlod, 'Blød')));
				      		if(this.dishBlodAllDayFilteredDietTypeGroups['Sunday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isBlodEmptySunday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptySunday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isBlodEmptySunday = false;
				      		}
				      		this.dishAllDayFilteredDietTypeGroups['Sunday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays['Sunday'])));
				      		console.debug('this.dishAllocationsOrderDaysSunday', JSON.stringify(this.dishAllocationsOrderDays));
				      	}else{

				      		this.dishAllocationsOrderDays['Sunday']=this.dishAllocationsWeekResponse[2]['servings'][0];
				    }
				    	//-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Sunday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));

				      	if(_.isEmpty(this.dishAllocationsOrderDays['Sunday'])){
				      		console.debug('this.dishAllocationsOrderDays[\'Sunday\'].length==0')
				      		this.isEmptySunday=true;
				      	}
					}else{
			      	this.isEmptySunday=true;
			      }

			      if((this.dishAllocationsWeekResponse[2]
			      	&& this.dishAllocationsWeekResponse[2]['servings'].length!=0
			      	&& this.dishAllocationsWeekResponse[2]['servings']['1']!== undefined
			      	&& this.dishAllocationsWeekResponse[2]['servings']['1']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Monday', this.shiftWeek+1))
					 || (this.dishAllocationsWeekResponse[2]!==undefined
					&& this.dishAllocationsWeekResponse[2]['servings'].length!=0
					&& this.dishAllocationsWeekResponse[2]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Monday', this.shiftWeek+1))){
			      		let servingDayIndex = 1;
						//this.dateService.getISODateYYYMMDDforDay("Monday", this.shiftWeek)
						console.debug('MondayISO', this.dateService.getISODateYYYMMDDforDay('Monday', this.shiftWeek));
						if(this.dishAllocationsWeekResponse[2]['servings']['0'] &&
							this.dishAllocationsWeekResponse[2]['servings']['0']['servingDate']==this.dateService.getISODateYYYMMDDforDay('Monday', this.shiftWeek+1)){
							servingDayIndex = 0
						}
				     //this.dishAllocationsOrderDays["Monday"]=this.dishAllocationsWeekResponse[2];
				    if(this.orderFromViewWeekPlan){
				    if(this.dishAllocationsWeekResponse[2]['servings'][servingDayIndex]['mainDishes']==[]){

				      			this.dishAllocationsWeekResponse[2]['servings'][servingDayIndex]['mainDishes'] = this.dishAllocationsWeekResponse[2]['servings'][servingDayIndex]['sideDishes'];
				      		}else if(this.dishAllocationsWeekResponse[2]['servings'][servingDayIndex]['mainDishes']!=[]

				      			&& this.dishAllocationsWeekResponse[2]['servings'][servingDayIndex]['sideDishes']!=[]){
			      				this.dishAllocationsWeekResponse[2]['servings'][servingDayIndex]['mainDishes'] = this.dishAllocationsWeekResponse[2]['servings'][servingDayIndex]['mainDishes'].concat(this.dishAllocationsWeekResponse[2]['servings'][servingDayIndex]['sideDishes']);

				      		console.debug('this.dishAllocationsWeekResponse[2][servings][servingDayIndex]', JSON.stringify(this.dishAllocationsWeekResponse[2]['servings'][servingDayIndex]));
			      			}


				      		//console.debug("this.dishAllocationsOrderDaysInitMonday", this.dishAllocationsOrderDaysInitTuesday);
				      		//-------------------------------------------------------------
				      	/*this.dishAllocationsWeekResponse[2]['servings'][servingDayIndex].orderNr = this.dishAllocationsWeekResponse[2]['orderNr'];
				      	this.dishAllocationsWeekResponse[2]['servings'][servingDayIndex].customerNr = this.dishAllocationsWeekResponse[2]['customerNr'];
				      	this.dishAllocationsWeekResponse[2]['servings'][servingDayIndex].menuNr = this.dishAllocationsWeekResponse[2]['menuNr'];
                  */
				      	//-------------------------------------------------------------
				      		this.dishAllocationsOrderDays['Monday']=this.dishAllocationsWeekResponse[2]['servings'][servingDayIndex];
				      		this.dishAllocationsOrderDays['Monday']['mainDishes']=this.productNrFilter(this.dishAllocationsOrderDays['Monday'], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Monday'));
				      		this.sortResponseDay(this.dishAllocationsOrderDays['Monday']);
				      		let dishAllocationsOrderDaysEnergitat=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Monday']));
				      		this.dishEnergitatAllDayFilteredDietTypeGroups['Monday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysEnergitat, 'Energitæt')));
				      		if(this.dishEnergitatAllDayFilteredDietTypeGroups['Monday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isEnergitatEmptyMonday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyMonday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isEnergitatEmptyMonday = false;
				      		}
				      		let dishAllocationsOrderDaysBlod=JSON.parse(JSON.stringify(this.dishAllocationsOrderDays['Monday']));
				      		this.dishBlodAllDayFilteredDietTypeGroups['Monday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDaysBlod, 'Blød')));
				      		if(this.dishBlodAllDayFilteredDietTypeGroups['Monday']['mainDishes'][0]['dietTypeGroups'].length==0){
				      			this.isBlodEmptyMonday = true;
				      			console.debug('[ngOninit]  this.isEnergitatEmptyMonday', this.isEnergitatEmptyTuesday);
				      		}else{
				      			this.isBlodEmptyMonday = false;
				      		}
				      		this.dishAllDayFilteredDietTypeGroups['Monday'] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays['Monday'])));
				      		console.debug('this.dishAllocationsOrderDaysMonday', JSON.stringify(this.dishAllocationsOrderDays));
				      	}else{

				      		this.dishAllocationsOrderDays['Monday']=this.dishAllocationsWeekResponse[2]['servings'][servingDayIndex];
				    }
				    //-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Monday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));

				      	if(_.isEmpty(this.dishAllocationsOrderDays['Monday'])){
				      		console.debug('this.dishAllocationsOrderDays[\'Monday\'].length==0')
				      		this.isEmptyMonday=true;
				      	}
			      	}else{
			      	this.isEmptyMonday=true;
			      }


					//break;

					}
				console.debug('[ngOninit] All WEEK this.dishAllocationsOrderDays', this.dishAllocationsOrderDays)
				break;
				}

			//	}
			default:
				{
					console.log('[ngOninit] In DEFAULT');
					if(dishAllocationsWeekResponse[0]){
						//this.dishAllocationsOrderDays["Tuesday"]=(this.categoryFilter(0,this.dishAllocationsWeekResponse, this.categoryForFilter));
				      	dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'] = dishAllocationsWeekResponse[0]['servings']['0']['mainDishes'].concat(dishAllocationsWeekResponse[0]['servings']['0']['sideDishes']);

				      	console.debug('dishAllocationsWeekResponse[0][servings][0]', JSON.stringify(dishAllocationsWeekResponse[0]['servings'][0]));
				      	//console.debug("this.dishAllocationsOrderDaysInitTuesday", this.dishAllocationsOrderDaysInitTuesday);
				      	//-------------------------------------------------------------
				      	/*dishAllocationsWeekResponse[0]['servings'][0].orderNr = dishAllocationsWeekResponse[0]['orderNr'];
				      	dishAllocationsWeekResponse[0]['servings'][0].customerNr = dishAllocationsWeekResponse[0]['customerNr'];
				      	dishAllocationsWeekResponse[0]['servings'][0].menuNr = dishAllocationsWeekResponse[0]['menuNr'];
                */
				      	//-------------------------------------------------------------
				      	this.dishAllocationsOrderDays['Tuesday']=dishAllocationsWeekResponse[0]['servings'][0];
				      	this.dishAllocationsOrderDays['Tuesday']['mainDishes']=this.productNrFilter(this.dishAllocationsOrderDays['Tuesday'], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Tuesday'));
				      	this.sortResponseDay(this.dishAllocationsOrderDays['Tuesday']);
				      	console.debug('this.dishAllocationsOrderDaysTuesday', JSON.stringify(this.dishAllocationsOrderDays));

				      	//-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Tuesday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));



				      	if(_.isEmpty(this.dishAllocationsOrderDays['Tuesday'])){

				      		console.debug('this.dishAllocationsOrderDays[\'Tuesday\'].length==0')
				      		this.isEmptyTuesday=true;
				      	}
			      }
					if(dishAllocationsWeekResponse[0]){
						dishAllocationsWeekResponse[0]['servings']['1']['mainDishes'] = dishAllocationsWeekResponse[0]['servings']['1']['mainDishes'].concat(dishAllocationsWeekResponse[0]['servings']['1']['sideDishes']);

				      	console.debug('dishAllocationsWeekResponse[0][servings][1]', JSON.stringify(dishAllocationsWeekResponse[0]['servings'][1]));
				      	//console.debug("this.dishAllocationsOrderDaysInitWednesday", this.dishAllocationsOrderDaysInitWednesday);
				      	//-------------------------------------------------------------
				      	/*dishAllocationsWeekResponse[0]['servings'][1].orderNr = dishAllocationsWeekResponse[0]['orderNr'];
				      	dishAllocationsWeekResponse[0]['servings'][1].customerNr = dishAllocationsWeekResponse[0]['customerNr'];
				      	dishAllocationsWeekResponse[0]['servings'][1].menuNr = dishAllocationsWeekResponse[0]['menuNr'];
                */
				      	//-------------------------------------------------------------
				      	this.dishAllocationsOrderDays['Wednesday']=dishAllocationsWeekResponse[0]['servings'][1];
				      	this.dishAllocationsOrderDays['Wednesday']['mainDishes']=this.productNrFilter(this.dishAllocationsOrderDays['Wednesday'], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Wednesday'));
				      	this.sortResponseDay(this.dishAllocationsOrderDays['Wednesday']);
				      	console.debug('this.dishAllocationsOrderDaysWednesday', JSON.stringify(this.dishAllocationsOrderDays));

			      		//-----------------------------------------Order----------------------------------------------------------------------------------
						this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
						this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
						if(!this.simpleOderModelArray){
							this.simpleOderModelArray = new Array();
							this.sumOder = 0;
						}

						this.updateOrdersInLocalStorageFromResponse('Wednesday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));

			      		if(_.isEmpty(this.dishAllocationsOrderDays['Wednesday'])){
			      		console.debug('this.dishAllocationsOrderDays[\'Wednesday\'].length==0')
			      		this.isEmptyWednesday=true;
			      		}

					}
		    		break;
				}

	}
	  return dishAllocationsWeekResponse;
  }
//-----------------------------Methods--------------------------------------------------------------
	filterResponseByUndefined(responseDay:any){

console.debug('responseDay', JSON.stringify(responseDay));
		let responseDayFiltered= [];

			if(responseDay && responseDay[0] && responseDay[0]['servings']){
				//console.debug("IfUnd");
				responseDayFiltered = responseDay[0]['servings'].filter(el =>{
					console.debug('IfUndEl', el);
				if((
					(el['mainDishes']!==undefined && el['sideDishes']!=undefined)
					|| (el['mainDishes']==undefined && el['sideDishes']!=undefined)
					|| (el['mainDishes']!==undefined && el['sideDishes']==undefined)
					)
				 && ((el['mainDishes'].length!=0 && el['sideDishes'].length!=0)
				 	||(el['mainDishes'].length!=0 && el['sideDishes'].length==0)
				 	||(el['mainDishes'].length==0 && el['sideDishes'].length!=0)
				 	)

				  ){

					return true;
				}
				});
			}





			console.debug('responseDayFiltered', responseDayFiltered);
			return responseDayFiltered;
		}

	categoryFilter(day:number, response:any[], category:any){
		var dishArrForCategoty = Object.create({category:[], categories:[]});
		var exit_loops = false;
		var lowerCaseCategory = category.toLowerCase();

		console.debug('In filterResponseByCategory lowerCaseCategory=' + lowerCaseCategory);
		for(var n=0; n < response[day].length; n++ ){
		//	console.debug("In filterResponseByCategory response[day][n][category][name]="+ response[day][n]["category"][name]);
			if((response[day][n]['category']['name']==category) || (response[day][n]['category']['name']==lowerCaseCategory) ){
				//	console.debug("In categoryFilter. Category found")

					dishArrForCategoty.category=(response[day][n]['category']);
					dishArrForCategoty.categories=(response[day][n]['categories']);
				exit_loops = true;
	            break;
			}else{
			//	console.debug("In categoryFilter. Category not found")
		}

		}

		// console.debug("RETURN" + JSON.stringify(dishArrForCategoty));
		return dishArrForCategoty;
	}



	categoryDietTypeArrCatFilter(day:number, response:any[], categoryArr:any[]){
		//var menuLineArrForCategory = Object.create({category:[], categories:[]});
		var menuLineArrForCategory = [];
		//var exit_loops = false;
		//var lowerCaseCategory = category.toLowerCase();

		//console.debug("In filterResponseByCategory lowerCaseCategory=" + lowerCaseCategory);
		for(var n=0; n < response[day]['mainDishes'].length; n++ ){
			for(var k=0; k < response[day]['mainDishes'][n]['dietTypeGroups'].length; k++){

				for(var m=0; m < response[day]['mainDishes'][n]['dietTypeGroups'][k]['dietTypes'].length; m++){

					//console.debug("nAndet", n);
					//console.debug("kAndet", k);
					//console.debug("mAndet", m);
					// console.debug("response[day]22="+JSON.stringify(response[day]["mainDishes"][n]["dietTypeGroups"][k]["dietTypes"][m]["dietType"]));
				 for(var catAndet in categoryArr){
				 	//console.debug("catAndet", catAndet);
				 	if(response[day]['mainDishes'][n]['dietTypeGroups'][k]['dietTypes'][m]['dietType']['name']==categoryArr[catAndet] ){

						//	console.debug("In categoryFilter. Category found")

							menuLineArrForCategory.push(response[day]['mainDishes'][n]['dietTypeGroups'][k]['dietTypes'][m]['menuLine']);
						//	console.debug("menuLineArrForCategoryAndet", menuLineArrForCategory);

						//exit_loops = true;
			            //break;
				}else{
				//	console.debug("In categoryFilter. Category not found")
			}
					}
				 }


			}


		}

		// console.debug("RETURNAndet" + JSON.stringify(menuLineArrForCategory));
		return menuLineArrForCategory;
	}

	categoryDietTypeFuldkostArrFilter(response:any[]){
		//console.debug("response", response);
		//var menuLineArrForCategory = Object.create({category:[], categories:[]});
		var categoryArr = ['Fuldkost', 'Fuldkost m. kogte kartof.', 'Fuldkost m. rå kartof.', 'Fuldkost m/mos', 'Fuldkost % kartofler'];
		var menuLineArrForCategory = [];
		//var exit_loops = false;
		//var lowerCaseCategory = category.toLowerCase();

		//console.debug("In filterResponseByCategory lowerCaseCategory=" + lowerCaseCategory);
		//for(var n=0; n < response[day]['mainDishes'].length; n++ ){
			for(var k=0; k < response.length; k++){

				for(var m=0; m < response[k]['dietTypes'].length; m++){

					//console.debug("nAndet", n);
					//console.debug("kAndet", k);
					//console.debug("mAndet", m);
					// console.debug("response[day]22="+JSON.stringify(response[day]["mainDishes"][n]["dietTypeGroups"][k]["dietTypes"][m]["dietType"]));
				 for(var catAndet in categoryArr){
				 	//console.debug("catAndet", catAndet);
				 	if(response[k]['dietTypes'][m]['dietType']['name']==categoryArr[catAndet] ){

						//	console.debug("In categoryFilter. Category found")

							menuLineArrForCategory.push(response[k]['dietTypes'][m]['menuLine']);
						//	console.debug("menuLineArrForCategoryAndet", menuLineArrForCategory);

						//exit_loops = true;
			            //break;
				}else{
				//	console.debug("In categoryFilter. Category not found")
			}
					}
				 }


			}


		//}

		 console.debug('RETURNFuldkost' + JSON.stringify(menuLineArrForCategory));
		this.menuLineArrForCategoryTemp = menuLineArrForCategory;
		return menuLineArrForCategory;
	}



	getKodCategoryArray(dishAllocationsOrderDaysDayForModal:any){

//console.debug("dishAllocationsOrderDaysDayForModal", dishAllocationsOrderDaysDayForModal);
		let categoryArr = ['Fuldkost', 'Fuldkost m. kogte kartof.', 'Fuldkost m. rå kartof.', 'Fuldkost m/mos', 'Fuldkost % kartofler'];
		let mapKodCategoryArray = new Map();
		let meatSet = new Set();
		let starchSet = new Set();
		for(let itemMenuLine in dishAllocationsOrderDaysDayForModal['mainDishes']){
			for(let itemdietTypeGroups in dishAllocationsOrderDaysDayForModal['mainDishes'][itemMenuLine]['dietTypeGroups']){
				for(let cat in categoryArr){
					if(dishAllocationsOrderDaysDayForModal['mainDishes'][itemMenuLine]['dietTypeGroups'][itemdietTypeGroups]['dietTypeGroup']['name']==categoryArr[cat] ){

						for(let itemDietType in dishAllocationsOrderDaysDayForModal['mainDishes'][itemMenuLine]['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){

						//console.debug("meat", dishAllocationsOrderDaysDayForModal["mainDishes"][itemMenuLine]["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["dietType"]["meat"]);
				 		if(dishAllocationsOrderDaysDayForModal['mainDishes'][itemMenuLine]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['dietType']['meat']!==undefined){
				 			meatSet.add(dishAllocationsOrderDaysDayForModal['mainDishes'][itemMenuLine]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['dietType']['meat']);

				 		}

				}
				console.debug('meatSet', meatSet);

				//for(let itemMeatSet in meatSet){
				meatSet.forEach(function(itemMeatSet, key,ownerSet){
					console.debug('itemMeatSet', itemMeatSet);
					for(let itemDietType in dishAllocationsOrderDaysDayForModal['mainDishes'][itemMenuLine]['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){


				 		if(dishAllocationsOrderDaysDayForModal['mainDishes'][itemMenuLine]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['dietType']['meat']==itemMeatSet){
				 			console.debug('itemMeatSetName', dishAllocationsOrderDaysDayForModal['mainDishes'][itemMenuLine]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['dietType']['meat']);
				 			mapKodCategoryArray.set(itemMeatSet, dishAllocationsOrderDaysDayForModal['mainDishes'][itemMenuLine]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['dietType']['starch']);
				 		}

				 	}
					});


				//}
					}

				}


			}

		}
		console.debug('mapKodCategoryArray', mapKodCategoryArray);
		return mapKodCategoryArray;

	}

	getKodCategoryArrayForMenuLine(menuLineDayForModal:any, productNr:number){

		console.debug('[getKodCategoryArrayForMenuLine] menuLineDayForModal', menuLineDayForModal);
		console.debug('[getKodCategoryArrayForMenuLine] productNr', productNr);
		let categoryArr = ['Fuldkost', 'Fuldkost m. kogte kartof.', 'Fuldkost m. rå kartof.', 'Fuldkost m/mos', 'Fuldkost % kartofler'];
		let starchRange = [[16,25], [166,175], [316,325],[466,475], [616,625]];
		let mapKodCategoryArray = new Map();
		let meatSet = new Set();

		//for(let itemMenuLine in menuLineDayForModal["mainDishes"]){
			for(let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']){
				for(let cat in categoryArr){
					if(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypeGroup']['name']==categoryArr[cat] ){

						for(let itemDietType in menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){
							for(let cat in categoryArr){

								if((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['dietType']['meat']!==undefined)
							&& (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeName']==categoryArr[cat])
				 			/*&& ((menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]<=starchRange[0][0]
				 				|| menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]>=starchRange[0][1])
				 			&& (menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]<= starchRange[1][0]
				 				|| menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]>=starchRange[1][1])
				 			&& (menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]<= starchRange[2][0]
				 				|| menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]>=starchRange[2][1])
				 			&& (menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]<= starchRange[3][0]
				 				|| menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]>=starchRange[3][1])
				 			&& (menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]<= starchRange[4][0]
				 				|| menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]>=starchRange[4][1])
				 			)*/
				 			&& !((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[0][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[0][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[1][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[1][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[2][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[2][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[3][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[3][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[4][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[4][1])
				 			)
				 			&& (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productNr']==productNr)){

				 			//console.debug("line", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]);
				 			//console.debug("productNr", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["productNr"])
				 			//console.debug("productNr", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["productNr"])
				 			meatSet.add(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['dietType']['meat']);

				 		}


							}
						//console.debug("meat", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["dietType"]["meat"]);


				}
				console.debug('meatSet', meatSet);

				//for(let itemMeatSet in meatSet){
				meatSet.forEach(function(itemMeatSet, key,ownerSet){
					console.debug('itemMeatSet', itemMeatSet);
					let starchSet = new Set();
					let tibehorFuldkostArr: TibehorFuldkost []
					tibehorFuldkostArr = [];
					for(let itemDietType in menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){
						for(let cat in categoryArr){

							let itemLineSetArr = [];
				 		if((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['dietType']['meat']==itemMeatSet)
				 		&& (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeName']==categoryArr[cat])
				 			/*&& ((menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]<=starchRange[0][0]
				 				|| menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]>=starchRange[0][1])
				 			&& (menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]<= starchRange[1][0]
				 				|| menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]>=starchRange[1][1])
				 			&& (menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]<= starchRange[2][0]
				 				|| menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]>=starchRange[2][1])
				 			&& (menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]<= starchRange[3][0]
				 				|| menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]>=starchRange[3][1])
				 			&& (menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]<= starchRange[4][0]
				 				|| menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]["line"]>=starchRange[4][1])
				 			)*/
				 			&& !((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[0][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[0][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[1][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[1][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[2][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[2][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[3][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[3][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[4][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[4][1])
				 			)
				 		&& (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productNr']==productNr)){
				 			starchSet.add(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['dietType']['starch']);

				 		}

						}


				 	}
				 	console.debug('[getKodCategoryArrayForMenuLine] itemMeatstarchSet', starchSet);
				 	//mapKodCategoryArray.set(itemMeatSet, starchSet);



				 	starchSet.forEach(function(itemStarchSet, key,ownerSet){
				 		tibehorFuldkostArr.push(new TibehorFuldkost(itemStarchSet, 0));

				 	});
				 	mapKodCategoryArray.set(itemMeatSet, tibehorFuldkostArr);
					});


				//}
					}

				}


			}

		//}
		console.debug('[getKodCategoryArrayForMenuLine] mapKodCategoryArray', mapKodCategoryArray);
		return mapKodCategoryArray;

	}



	getFilteredMapByQuantities(modalKodCategoryMap:any, itemMenuLines:any){
		console.debug('[getFilteredMapByQuantities] modalKodCategoryMap', modalKodCategoryMap);

		modalKodCategoryMap.forEach(function(value, key, ownerMap){
			//console.log("[getFilteredMapByQuantities] key", key);
			//console.log("[getFilteredMapByQuantities] value", value);
			/*for(let itemTibehor in value){
			let itemSelected = this.findBtKodTilbehorFromMenuLine(itemMenuLines,
			key,
			value[itemTibehor]["tibehorCategory"],
			itemMenuLines['dietTypeGroups'][0]['dietTypes'][0]['menuLine']['productNr'],
			0);
			value[itemTibehor]["tibehorQuantity"] = Number(itemSelected['quantity']);
			//value[itemTibehor]["tibehorQuantity"]=100;

			}*/

			value = value.filter(itemTibehor => {

				if(itemTibehor['tibehorQuantity']!=0){
					return true;
				}else{
					return false;
				}

			} );

			console.debug('[getFilteredMapByQuantities] value', value);
			ownerMap.set(key, value);
			if(ownerMap.get(key).length==0){
				console.debug('[getFilteredMapByQuantities] value=0', ownerMap.get(key));
				ownerMap.delete(key)
			}


		}, this);

		console.debug('[getFilteredMapByQuantities] modalKodCategoryMap', modalKodCategoryMap);
		return modalKodCategoryMap
	}

	getFilteredMapByQuantitiesSoft(modalKodCategoryMap:any, itemMenuLines:any){
		console.debug('[getFilteredMapByQuantities] modalKodCategoryMap', modalKodCategoryMap);
		let filteredMap = new Map();
		modalKodCategoryMap.forEach(function(value, key, ownerMap){
			//console.log("[getFilteredMapByQuantities] key", key);
			//console.log("[getFilteredMapByQuantities] value", value);
			/*for(let itemTibehor in value){
			let itemSelected = this.findBtKodTilbehorFromMenuLine(itemMenuLines,
			key,
			value[itemTibehor]["tibehorCategory"],
			itemMenuLines['dietTypeGroups'][0]['dietTypes'][0]['menuLine']['productNr'],
			0);
			value[itemTibehor]["tibehorQuantity"] = Number(itemSelected['quantity']);
			//value[itemTibehor]["tibehorQuantity"]=100;

			}*/
			let valueArr = [];
			value = value.forEach(itemTibehor => {

				if(itemTibehor['tibehorQuantity']!=0){
				 valueArr.push(itemTibehor);
				}

			} );
			filteredMap.set(key, valueArr);

			/*console.debug("[getFilteredMapByQuantities] value", value);
			ownerMap.set(key, value);
			if(ownerMap.get(key).length==0){
				console.debug("[getFilteredMapByQuantities] value=0", ownerMap.get(key));
				ownerMap.delete(key)
			}
			*/

		}, this);

		console.debug('[getFilteredMapByQuantities] modalKodCategoryMap', modalKodCategoryMap);
		//return modalKodCategoryMap
		console.debug('[getFilteredMapByQuantities] filteredMap', filteredMap);
		return filteredMap
	}

	updateQuontityFromSimpleOderModelArray(menuLineDayForModal:any, simpleOderModelArray:any, day:string){
		//let menuLine;
		for(let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']){
				//for(let cat in categoryArr){
				//	if(menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypeGroup"]["name"]==categoryArr[cat] ){

						for(let itemDietType in menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){
							//let itemLineSetArr = [];
							for(let order in simpleOderModelArray){


								if(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']!==undefined
				 				&& (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productNr']==simpleOderModelArray[order]['dishId'])
				 				&& (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']==simpleOderModelArray[order]['line'])
				 				&& (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productName']==simpleOderModelArray[order]['name'])
				 				&& (day==simpleOderModelArray[order]['Day'])
				 				){

console.log('simpleOderModelArray[order][sumOder]', simpleOderModelArray[order]['sumOder']);
								//console.debug("[updateQuontityFromSimpleOderModelArray] menuLine1", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]);
				 				menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity']=simpleOderModelArray[order]['sumOder'];
				 				//menuLine = menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"];


				 		}
							}


				}



				//	}

				//}


			}
			console.debug('[updateQuontityFromSimpleOderModelArray] menuLineDayForModal2', menuLineDayForModal);
			return menuLineDayForModal;

	}

	getSubLinesSetForMenuLineFuldcostBulk(menuLineDayForModal:any){

console.debug('menuLineSetForMenuLine', menuLineDayForModal);
		let categoryArr = ['Fuldkost', 'Fuldkost m. kogte kartof.', 'Fuldkost m. rå kartof.', 'Fuldkost m/mos', 'Fuldkost % kartofler'];
		let category = menuLineDayForModal['menuLine']['line'];
		let starchRange = [[122,129], [272,279], [422,426],[572,579], [722,729],
		 [132,140], [282,290], [432,440], [582,590], [732-740],
		 [144,147], [294,297], [447], [594,597], [744,747],
		 [141], [291], [441], [591], [741]];
		//console.debug("category", category);
		//let mapKodCategoryArray = new Map();
		let lineSet = new Set();

		//for(let itemMenuLine in menuLineDayForModal["mainDishes"]){
			for(let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']){
				for(let cat in categoryArr){
					if(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypeGroup']['name']==categoryArr[cat] ){

						for(let itemDietType in menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){
							let itemLineSetArr = [];

//console.debug("menuLineDayForModalW0", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]);
						//console.debug("meat", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["dietType"]["meat"]);
				 		if((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']!==undefined)
				 			&& ((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[0][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[0][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[1][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[1][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[2][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[2][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[3][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[3][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[4][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[4][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[5][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[5][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[6][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[6][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[7][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[7][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[8][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[8][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[9][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[9][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[10][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[10][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[11][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[11][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']==starchRange[12])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[13][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[13][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[14][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[14][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']==starchRange[15])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']==starchRange[16])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']==starchRange[17])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']==starchRange[18])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']==starchRange[19])


				 			)){

							//console.debug("In if");
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productName']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeNr']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productNr']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeName']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['portionSize']);

				 			lineSet.add(itemLineSetArr);

				 		}else{

//console.debug("menuLineDayForModalW", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]);

//console.debug("menuLineDayForModalW2", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]);
				 		}

				}
				console.debug('lineSet', lineSet);


					}

				}


			}



		return lineSet;

	}


	updateMenuLineByProductNr(menuLineDayForModal:any, categoryArr:string[], subLinesSetRow:any){
		let menuLine;
		if(subLinesSetRow){

			for(let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']){
				for(let cat in categoryArr){
					if(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypeGroup']['name']==categoryArr[cat] ){

						for(let itemDietType in menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){
							let itemLineSetArr = [];


				 		if(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']!==undefined
				 			&& (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']==subLinesSetRow[0])
				 			&& (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productName']==subLinesSetRow[1])
				 			&& (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productNr']==subLinesSetRow[4])
				 			){

							console.debug('[updateMenuLineByProductNr] menuLine1', menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']);
				 			menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity']=subLinesSetRow[3];
				 			menuLine = menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine'];


				 		}

				}



					}

				}


			}

		}

			console.debug('[updateMenuLineByProductNr] menuLine2', menuLine);
			return menuLine;


	}
	getSubLinesSetForMenuLineFuldcostKod(menuLineDayForModal:any){

console.debug('menuLineSetForMenuLine', menuLineDayForModal);
		let categoryArr = ['Fuldkost', 'Fuldkost m. kogte kartof.', 'Fuldkost m. rå kartof.', 'Fuldkost m/mos', 'Fuldkost % kartofler'];
		let category = menuLineDayForModal['menuLine']['line'];
		let kodRange = [[122,129], [272,279], [422,429],[572,579], [722,729]];
		//console.debug("category", category);
		//let mapKodCategoryArray = new Map();
		let lineSet = new Set();

		//for(let itemMenuLine in menuLineDayForModal["mainDishes"]){
			for(let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']){
				for(let cat in categoryArr){
					if(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypeGroup']['name']==categoryArr[cat] ){

						for(let itemDietType in menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){
							let itemLineSetArr = [];

//console.debug("menuLineDayForModalW0", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]);
						//console.debug("meat", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["dietType"]["meat"]);
				 		if((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']!==undefined)
				 			&& (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity']!=0)
				 			&& ((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=kodRange[0][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=kodRange[0][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=kodRange[1][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=kodRange[1][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=kodRange[2][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=kodRange[2][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=kodRange[3][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=kodRange[3][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=kodRange[4][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=kodRange[4][1])
				 			)){

							//console.debug("In if");
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productName']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeNr']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productNr']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeName']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['portionSize']);

				 			lineSet.add(itemLineSetArr);

				 		}else{

//console.debug("menuLineDayForModalW", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]);

//console.debug("menuLineDayForModalW2", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]);
				 		}

				}
				console.debug('lineSet', lineSet);


					}

				}


			}



		return lineSet;

	}

	getSubLinesSetForMenuLineFuldcostTilbehor(menuLineDayForModal:any){

console.debug('[getSubLinesSetForMenuLineFuldcostTilbehor] menuLineSetForMenuLine', menuLineDayForModal);
		let categoryArr = ['Fuldkost', 'Fuldkost m. kogte kartof.', 'Fuldkost m. rå kartof.', 'Fuldkost m/mos', 'Fuldkost % kartofler'];
		let category = menuLineDayForModal['menuLine']['line'];
		let tilbehorRange = [[132,140], [282,290], [432,440], [582,590], [732-740]];
		//console.debug("category", category);
		//let mapKodCategoryArray = new Map();
		let lineSet = new Set();

		//for(let itemMenuLine in menuLineDayForModal["mainDishes"]){
			for(let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']){
				for(let cat in categoryArr){
					if(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypeGroup']['name']==categoryArr[cat] ){

						for(let itemDietType in menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){
							let itemLineSetArr = [];

//console.debug("menuLineDayForModalW0", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]);
						//console.debug("meat", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["dietType"]["meat"]);
				 		if((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']!==undefined)
				 			&& (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity']!=0)
				 			&& ((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=tilbehorRange[0][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=tilbehorRange[0][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=tilbehorRange[1][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=tilbehorRange[1][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=tilbehorRange[2][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=tilbehorRange[2][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=tilbehorRange[3][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=tilbehorRange[3][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=tilbehorRange[4][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=tilbehorRange[4][1])

				 			)){

							//console.debug("In if");
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productName']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeNr']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productNr']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeName']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['portionSize']);

				 			lineSet.add(itemLineSetArr);

				 		}else{

//console.debug("menuLineDayForModalW", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]);

//console.debug("menuLineDayForModalW2", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]);
				 		}

				}
				console.debug('[getSubLinesSetForMenuLineFuldcostTilbehor] lineSet', lineSet);


					}

				}


			}



		return lineSet;

	}

	getSubLinesSetForMenuLineFuldcostGront(menuLineDayForModal:any){

console.debug('menuLineSetForMenuLine', menuLineDayForModal);
		let categoryArr = ['Fuldkost', 'Fuldkost m. kogte kartof.', 'Fuldkost m. rå kartof.', 'Fuldkost m/mos', 'Fuldkost % kartofler'];
		let category = menuLineDayForModal['menuLine']['line'];
		let grontRange = [[144,147], [294,297], [447], [594,597], [744,747]];
		//console.debug("category", category);
		//let mapKodCategoryArray = new Map();
		let lineSet = new Set();

		//for(let itemMenuLine in menuLineDayForModal["mainDishes"]){
			for(let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']){
				for(let cat in categoryArr){
					if(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypeGroup']['name']==categoryArr[cat] ){

						for(let itemDietType in menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){
							let itemLineSetArr = [];

//console.debug("menuLineDayForModalW0", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]);
						//console.debug("meat", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["dietType"]["meat"]);
				 		if( (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']!==undefined)
				 			&& (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity']!=0)
				 			&&((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=grontRange[0][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=grontRange[0][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=grontRange[1][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=grontRange[1][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']==grontRange[2])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=grontRange[3][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=grontRange[3][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=grontRange[4][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=grontRange[4][1])


				 			)){

							//console.debug("In if");
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productName']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeNr']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productNr']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeName']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['portionSize']);

				 			lineSet.add(itemLineSetArr);

				 		}else{

//console.debug("menuLineDayForModalW", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]);

//console.debug("menuLineDayForModalW2", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]);
				 		}

				}
				console.debug('lineSet', lineSet);


					}

				}


			}



		return lineSet;

	}

	getSubLinesSetForMenuLineFuldcostSovs(menuLineDayForModal:any){

console.debug('menuLineSetForMenuLine', menuLineDayForModal);
		let categoryArr = ['Fuldkost', 'Fuldkost m. kogte kartof.', 'Fuldkost m. rå kartof.', 'Fuldkost m/mos', 'Fuldkost % kartofler'];
		let category = menuLineDayForModal['menuLine']['line'];
		let SovsRange = [[141], [291], [441], [591], [741]];
		//console.debug("category", category);
		//let mapKodCategoryArray = new Map();
		let lineSet = new Set();

		//for(let itemMenuLine in menuLineDayForModal["mainDishes"]){
			for(let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']){
				for(let cat in categoryArr){
					if(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypeGroup']['name']==categoryArr[cat] ){

						for(let itemDietType in menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){
							let itemLineSetArr = [];

//console.debug("menuLineDayForModalW0", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]);
						//console.debug("meat", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["dietType"]["meat"]);
				 		if(	(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']!==undefined)
				 			&& (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity']!=0)
				 			&& ((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']==SovsRange[0])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']==SovsRange[1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']==SovsRange[2])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']==SovsRange[3])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']==SovsRange[4])

				 			)){

							//console.debug("In if");
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productName']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeNr']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productNr']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeName']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['portionSize']);

				 			lineSet.add(itemLineSetArr);

				 		}else{

//console.debug("menuLineDayForModalW", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]);

//console.debug("menuLineDayForModalW2", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]);
				 		}

				}
				console.debug('lineSet', lineSet);


					}

				}


			}



		return lineSet;

	}

	getSubLinesSetForMenuLineFuldkostEnkel(menuLineDayForModal:any){

console.debug('menuLineSetForMenuLine', menuLineDayForModal);
		let categoryArr = ['Fuldkost', 'Fuldkost m. kogte kartof.', 'Fuldkost m. rå kartof.', 'Fuldkost m/mos', 'Fuldkost % kartofler'];
		//let categoryArr = ["Energitæt"]
		let category = menuLineDayForModal['menuLine']['line'];
		let starchRange = [[16,25], [166,175], [316,325],[466,475], [616,625]];
		//console.debug("category", category);
		//let mapKodCategoryArray = new Map();
		let lineSet = new Set();

		//for(let itemMenuLine in menuLineDayForModal["mainDishes"]){
			for(let itemdietTypeGroups in menuLineDayForModal['dietTypeGroups']){
				for(let cat in categoryArr){
					if(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypeGroup']['name']==categoryArr[cat] ){

						for(let itemDietType in menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){
							let itemLineSetArr = [];

//console.debug("menuLineDayForModalW0", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]);
						//console.debug("meat", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["dietType"]["meat"]);
				 		if((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']!==undefined)
				 			&& ((menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[0][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[0][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[1][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[1][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[2][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[2][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[3][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[3][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']>=starchRange[4][0]
				 				&& menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']<=starchRange[4][1])
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeName'] =='Energitæt')
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeName'] =='Energitæt m/mos')
				 			|| (menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeName'] =='Energi m/ rå kart.')
				 				)
				 			){

							//console.debug("In if");
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['line']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productName']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeNr']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['productNr']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeName']);
				 			itemLineSetArr.push(menuLineDayForModal['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['portionSize']);

				 			lineSet.add(itemLineSetArr);

				 		}else{

//console.debug("menuLineDayForModalW", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]);

//console.debug("menuLineDayForModalW2", menuLineDayForModal["dietTypeGroups"][itemdietTypeGroups]["dietTypes"][itemDietType]["menuLine"]);
				 		}

				}
				console.debug('lineSet', lineSet);


					}

				}


			}



		return lineSet;

	}


	categoryDietTypeEnrgitatArrFilter(response:any[]){
		console.debug('response', response);
		//var menuLineArrForCategory = Object.create({category:[], categories:[]});
		var categoryArr = ['Energitæt', 'Energi m/ rå kart.'];
		var menuLineArrForCategory = [];
		//var exit_loops = false;
		//var lowerCaseCategory = category.toLowerCase();

		//console.debug("In filterResponseByCategory lowerCaseCategory=" + lowerCaseCategory);
		//for(var n=0; n < response[day]['mainDishes'].length; n++ ){
			for(var k=0; k < response.length; k++){

				for(var m=0; m < response[k]['dietTypes'].length; m++){

					//console.debug("nAndet", n);
					//console.debug("kAndet", k);
					//console.debug("mAndet", m);
					// console.debug("response[day]22="+JSON.stringify(response[day]["mainDishes"][n]["dietTypeGroups"][k]["dietTypes"][m]["dietType"]));
				 for(var catAndet in categoryArr){
				 	console.debug('catAndet', catAndet);
				 	if(response[k]['dietTypes'][m]['dietType']['name']==categoryArr[catAndet] ){

							console.debug('In categoryFilter. Category found')

							menuLineArrForCategory.push(response[k]['dietTypes'][m]['menuLine']);
						//	console.debug("menuLineArrForCategoryAndet", menuLineArrForCategory);

						//exit_loops = true;
			            //break;
				}else{
				//	console.debug("In categoryFilter. Category not found")
			}
					}
				 }


			}


		//}

		// console.debug("RETURNEnrgitat" + JSON.stringify(menuLineArrForCategory));
		this.menuLineArrForCategoryTemp = menuLineArrForCategory;
		return menuLineArrForCategory;
	}

	categoryDietTypeBlodArrFilter(response:any[]){
		//console.debug("response", response);
		//var menuLineArrForCategory = Object.create({category:[], categories:[]});
		var categoryArr = ['Blød'];
		var menuLineArrForCategory = [];
		//var exit_loops = false;
		//var lowerCaseCategory = category.toLowerCase();

		//console.debug("In filterResponseByCategory lowerCaseCategory=" + lowerCaseCategory);
		//for(var n=0; n < response[day]['mainDishes'].length; n++ ){
			for(var k=0; k < response.length; k++){

				for(var m=0; m < response[k]['dietTypes'].length; m++){

					//console.debug("nAndet", n);
					//console.debug("kAndet", k);
					//console.debug("mAndet", m);
					// console.debug("response[day]22="+JSON.stringify(response[day]["mainDishes"][n]["dietTypeGroups"][k]["dietTypes"][m]["dietType"]));
				 for(var catAndet in categoryArr){
				 	//console.debug("catAndet", catAndet);
				 	if(response[k]['dietTypes'][m]['dietType']['name']==categoryArr[catAndet] ){

						//	console.debug("In categoryFilter. Category found")

							menuLineArrForCategory.push(response[k]['dietTypes'][m]['menuLine']);
						//	console.debug("menuLineArrForCategoryAndet", menuLineArrForCategory);

						//exit_loops = true;
			            //break;
				}else{
				//	console.debug("In categoryFilter. Category not found")
			}
					}
				 }


			}


		//}

		// console.debug("RETURNBlod" + JSON.stringify(menuLineArrForCategory));
		this.menuLineArrForCategoryTemp = menuLineArrForCategory;
		return menuLineArrForCategory;
	}

	categoryDietTypeAndetArrFilter(response:any[]){
		//console.debug("response", response);
		//var menuLineArrForCategory = Object.create({category:[], categories:[]});
		var categoryArr = ['Gratinkost', 'Cremet energitæt'];
		var menuLineArrForCategory = [];
		//var exit_loops = false;
		//var lowerCaseCategory = category.toLowerCase();

		//console.debug("In filterResponseByCategory lowerCaseCategory=" + lowerCaseCategory);
		//for(var n=0; n < response[day]['mainDishes'].length; n++ ){
			for(var k=0; k < response.length; k++){

				for(var m=0; m < response[k]['dietTypes'].length; m++){

					//console.debug("nAndet", n);
					//console.debug("kAndet", k);
					//console.debug("mAndet", m);
					// console.debug("response[day]22="+JSON.stringify(response[day]["mainDishes"][n]["dietTypeGroups"][k]["dietTypes"][m]["dietType"]));
				 for(var catAndet in categoryArr){
				 	//console.debug("catAndet", catAndet);
				 	if(response[k]['dietTypes'][m]['dietType']['name']==categoryArr[catAndet] ){

						//	console.debug("In categoryFilter. Category found")

							menuLineArrForCategory.push(response[k]['dietTypes'][m]['menuLine']);
						//	console.debug("menuLineArrForCategoryAndet", menuLineArrForCategory);

						//exit_loops = true;
			            //break;
				}else{
				//	console.debug("In categoryFilter. Category not found")
			}
					}
				 }


			}


		//}

		 console.debug('RETURNAndet' + JSON.stringify(menuLineArrForCategory));
		this.menuLineArrForCategoryTemp = menuLineArrForCategory;
		return menuLineArrForCategory;
	}

	productNrForDaysimpleOderModelArrayFilter(simpleOderModelArray:any, day:string){
		let productNrArray = [];
		for(let orderBulk in simpleOderModelArray){
			if((simpleOderModelArray[orderBulk]['Day']==day) && (simpleOderModelArray[orderBulk]['sumOder']!=0)){

				productNrArray.push(simpleOderModelArray[orderBulk]['dishId']);
			}
		}

		console.debug('productNrArray', productNrArray);
		return productNrArray;

	}
	productNrFilter(responseDay:any[], productNrArray:number[]){
		console.debug('productNrArray', productNrArray);
		console.debug('responseDay', responseDay);
		if(this.isProductNrFilterOn){

			let menuLineArrForCategory = [];

			for(let itemMenuLine in  responseDay['mainDishes']){
				//console.debug("productNrFiltermenuLine", responseDay["mainDishes"][itemMenuLine]["menuLine"]);
				if(responseDay['mainDishes'][itemMenuLine]['menuLine']!==undefined){
					//console.debug("responseDay[\"mainDishes\"][itemMenuLine][\"menuLine\"]", responseDay["mainDishes"][itemMenuLine]["menuLine"]);

					for(let productNr in productNrArray){
					//console.debug("productNrFilterproductNr", productNr);
					//console.debug("responseDayproductNrArray", responseDay["mainDishes"][itemMenuLine]["menuLine"]);
				if(responseDay['mainDishes'][itemMenuLine]['menuLine']['productNr']==productNrArray[productNr]){

					//console.debug("productNrArray[productNr]", productNrArray[productNr]);

					menuLineArrForCategory.push(responseDay['mainDishes'][itemMenuLine]);

				}else{}

			}
				}

		}

		console.debug('menuLineArrForCategory', menuLineArrForCategory);
		return menuLineArrForCategory;

		}else{
			console.debug('responseDay[mainDishes]', responseDay['mainDishes']);
			return responseDay['mainDishes'];

		}


	}

	sortResponseDay(responseDay:any[]){

		console.debug('[sortResponseDay]', responseDay);

			//let filteredResponseDay;
			let menuLineArrForCategory = new Array(3);

			for(let itemMenuLine in  responseDay['mainDishes']){


				responseDay['mainDishes'][itemMenuLine] = this.sortResponseDayMainDish(responseDay['mainDishes'][itemMenuLine])


				}




		console.debug('[sortResponseDay] responseDay', responseDay);

		return menuLineArrForCategory;




	}

	sortResponseDayMainDish(responseDayMainDish:any[]){

		console.debug('[sortResponseDayMainDish]', responseDayMainDish);
			let responseDayMainDishAndet = JSON.parse(JSON.stringify(responseDayMainDish));
			//let filteredResponseDay;
			let menuLineArrForCategory = new Array(3);
			//let menuLineArrForCategory = Array.from(undefined, undefined, undefined);
			menuLineArrForCategory.fill(undefined);
			console.debug('[sortResponseDayMainDish] menuLineArrForCategory', menuLineArrForCategory);



				if(responseDayMainDishAndet['dietTypeGroups']!==undefined){
					responseDayMainDishAndet['dietTypeGroups'] = responseDayMainDishAndet['dietTypeGroups'].filter( el =>{
						if((el['dietTypeGroup']['name']!='Fuldkost')
				&& (el['dietTypeGroup']['name']!='Energitæt')
				&& (el['dietTypeGroup']['name']!='Blød')){

							return true;
						}else{
							switch(el['dietTypeGroup']['name']){
								case 'Fuldkost':
									menuLineArrForCategory[0]=el;
								break;
								case 'Energitæt':
									menuLineArrForCategory[1]=el;
								break;
								case 'Blød':
									menuLineArrForCategory[2]=el;
								break;
								default:{}

							/*if(el["dietTypeGroup"]["name"]=='Fuldkost'){



								}else if(el["dietTypeGroup"]["name"]=='Energitæt'){

									}else if(el["dietTypeGroup"]["name"]=='Blød'){

									}*/
							}

							return false;

						}
					});
					}
					menuLineArrForCategory=_.compact(menuLineArrForCategory);
					responseDayMainDish['dietTypeGroups']=menuLineArrForCategory;

					responseDayMainDish['dietTypeGroups'] = responseDayMainDish['dietTypeGroups'].concat(responseDayMainDishAndet['dietTypeGroups']);



		console.debug('[sortResponseDayMainDish] menuLineArrForCategory', menuLineArrForCategory);

		console.debug('[sortResponseDayMainDish] responseDay', responseDayMainDish);

		return responseDayMainDish;




	}

	arrayDishesViewForDayByCategoryFilter(day:string, dishAllocationsOrderDays:DishAllocationsOrderDays, category:any){

		var viewArrayForCategoty = new Array();
		var exit_loops = false;

	  		for(var cat in dishAllocationsOrderDays[day]['categories']){


	  			for (var property in dishAllocationsOrderDays[day]['categories'][cat]['category']){


					if(dishAllocationsOrderDays[day]['categories'][cat]['category'][property]==category){

					//console.debug("Category is Found ");

					viewArrayForCategoty = dishAllocationsOrderDays[day]['categories'][cat]['arrayDishesViewForDay'];


					exit_loops = true;
	            	break;

					}else{
						//console.debug("Category is NotFound ");

					}
				}

				if (exit_loops)
		        break;

	  		}

	  		return viewArrayForCategoty;

	}
//----------------------Filter contentFuldkostAntal -----------------------------------
/*	filterContentFuldkostAntal(itemMenuLines:any){

		for(itemdietTypes in itemMenuLines['dietTypeGroups'][0]['dietTypes']){
			if(itemMenuLines['dietTypeGroups'][0]['dietTypes'][itemdietTypes])
		}
	}
*/
//-------------------------------------------------------------------------------------------------
sub(cat:any, element:number, nameDay:string, ){
	 		let catNumber;
	 		if(cat=='Froko'){
				catNumber = 1;
			}else if(cat=='Extra'){
			 	catNumber = 2;
			}else if(cat='Editweekplan'){
			catNumber = 3;
			}
	 		//var orderDishIdTCur = nameDay + cat + element.toString();
	 			var orderDishIdTCur = this.getOrderDishId(
	 				this.localStorageService.get( 'currentChildRoute'), this.shiftWeek,
	 				nameDay, catNumber, element);
	 		for(var oder in this.simpleOderModelArray){
		  		if(this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur){

		  			if(this.simpleOderModelArray[oder].orderNumber!=0){
			  			this.simpleOderModelArray[oder].orderNumber-=1;
					  	if(this.simpleOderModelArray[oder].orderNumber<0){
					  		this.simpleOderModelArray[oder].orderNumber = 0;
					  	}
				  		this.simpleOderModelArray[oder].sumOder-=1;
					  	if(this.simpleOderModelArray[oder].sumOder<0){
					  		this.simpleOderModelArray[oder].sumOder = 0;
					  	}
				  }
		  		this.simpleOderModelArray[oder].changed = true;
		  		}

	  		}

	  	 	this.localStorageService.set(this.simpleOderModelArrayId, this.simpleOderModelArray);

	  		//console.debug("dishAllocationsOrderDays[nameDay][0][element]="+JSON.stringify(this.dishAllocationsOrderDays[nameDay][0][element]));


	 		//this.subProductToCart(this.dishAllocationsOrderDays[nameDay]["categories"][cat]["dishes"][element],1)
	 		this.updateCartFromLocalStorage();

	 		/*this.sumOder-=1;
	  		if(this.sumOder<0){
	  		this.sumOder = 0;
	  		}*/
	  		this.sumOder = this.getSumOrder(this.simpleOderModelArray);



	  }

	add(cat:any, element:number, nameDay:string){
		//console.debug("nameDay", nameDay);
		//console.debug("element", element);
		//console.debug("cat", cat);
		let catNumber;
		if(cat=='Froko'){
			catNumber = 1;
			}else if(cat=='Extra'){
			catNumber = 2;
			}else if(cat='Editweekplan'){
				catNumber = 3;
			}
		var orderFound = false;

	  //	var orderDishIdTCur = nameDay + cat + element.toString();

	  	var orderDishIdTCur = this.getOrderDishId(
	  		this.localStorageService.get( 'currentChildRoute'), this.shiftWeek,
	  		nameDay, catNumber, element);
	  	console.debug('orderDishIdTCurNumber='+orderDishIdTCur);

	  	if(this.simpleOderModelArray.length==0){
	  		//console.debug("this.simpleOderModelArray.length="+this.simpleOderModelArray.length);
	  		this.simpleOderModelArray.push(new  SimpleOderModel(
	  				orderDishIdTCur,
	  				1,
	  				1,
	  				nameDay,
	  		this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['menuLine']['productNr'],
		  	this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['menuLine']['productName'],
		  	this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['dish']['dishPhotos'][0]['imageUrl'],
		  	'no categoreis',
		  	'no subcategoreis',
		  	this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['menuLine']['dietTypeNr'],
		  	this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['menuLine']['dietTypeName'],
		  	this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['menuLine']['portionSize'],
		  	this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['menuLine']['line'],
		  	this.dishAllocationsOrderDays[nameDay].orderNr,
			this.dishAllocationsOrderDays[nameDay].customerNr,
			this.dishAllocationsOrderDays[nameDay].menuNr,
		  	true,
		  	this.orderNotPermit,
		  	false


	  		));
	  	}else{


	  	for(var oder in this.simpleOderModelArray){
	  		if(this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur){
	  			orderFound = true;
	  		 	this.simpleOderModelArray[oder].orderNumber+=1;
	  		 	this.simpleOderModelArray[oder].sumOder+=1;
	  		 	this.simpleOderModelArray[oder].changed = true;

	  		}
	  	}

	  	if(!orderFound){
	  	//		console.debug("Else="+element);
	  		this.simpleOderModelArray.push(new  SimpleOderModel(
	  		orderDishIdTCur,
	  		1,
	  		1,
	  		nameDay,
	  		this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['menuLine']['productNr'],
		  	this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['menuLine']['productName'],
		  	this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['dish']['dishPhotos'][0]['imageUrl'],
		  	'no categoreis',
		  	'no subcategoreis',
		  	this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['menuLine']['dietTypeNr'],
		  	this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['menuLine']['dietTypeName'],
		  	this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['menuLine']['portionSize'],
		  	this.dishAllocationsOrderDays[nameDay]['mainDishes'][element]['menuLine']['line'],
		  	/*this.orderNr,
		  	this.customerNr,
		  	this.menuNr,*/
		  	this.dishAllocationsOrderDays[nameDay].orderNr,
			this.dishAllocationsOrderDays[nameDay].customerNr,
			this.dishAllocationsOrderDays[nameDay].menuNr,
		  	true,
		  	this.orderNotPermit,
		  	false
	  			));

	  		}
	  	}



	  	this.localStorageService.set(this.simpleOderModelArrayId, this.simpleOderModelArray);

	  //this.addProductToCart(this.dishAllocationsOrderDays[nameDay]["categories"][cat]["dishes"][element]);
	  	this.updateCartFromLocalStorage();
	  	//this.sumOder+=1;
	  	this.sumOder = this.getSumOrder(this.simpleOderModelArray);

	  }
//this.dishAllocationsOrderDays['Tuesday']['mainDishes'][j]

	updateSimpleOderModelArray(cat:any, menuLine:any, nameDay:string, changed:boolean){
		console.info('[updateSimpleOderModelArray] menuLine', menuLine);
		//console.debug("nameDay", nameDay);

		let catNumber;
		if(cat=='Froko'){
			catNumber = 1;
			}else if(cat=='Extra'){
			catNumber = 2;
			}else if(cat='Editweekplan'){
				catNumber = 3;
			}
		var orderFound = false;

	  //	var orderDishIdTCur = nameDay + cat + element.toString();
	  	let element = Number(menuLine['productNr']) + Number(menuLine['line']);
	  	var orderDishIdTCur = this.getOrderDishId(
	  		this.localStorageService.get( 'currentChildRoute'), this.shiftWeek,
	  		nameDay, catNumber, element);
	  	console.debug('[updateSimpleOderModelArray] orderDishIdTCurNumber='+orderDishIdTCur);

	  	if((this.simpleOderModelArray.length==0) && menuLine['quantity']!=0){
	  		console.debug('[updateSimpleOderModelArray] this.simpleOderModelArray.length=', menuLine);
	  		console.debug('[updateSimpleOderModelArray] menuLine[quantity]', menuLine['quantity']);
	  		this.simpleOderModelArray.push(new  SimpleOderModel(
	  				orderDishIdTCur,
	  				Number(menuLine['quantity']),
	  				//3,
	  				Number(menuLine['quantity']),
	  				//3,
	  				nameDay,
	  		menuLine['productNr'],
		  	menuLine['productName'],
		  	'',
		  	'no categoreis',
		  	'no subcategoreis',
		  	menuLine['dietTypeNr'],
		  	menuLine['dietTypeName'],
		  	menuLine['portionSize'],
		  	menuLine['line'],
		  	//this.orderNr,
		  	//this.customerNr,
		  	//this.menuNr,
		  	this.dishAllocationsOrderDays[nameDay].orderNr,
			this.dishAllocationsOrderDays[nameDay].customerNr,
			this.dishAllocationsOrderDays[nameDay].menuNr,
		  	changed,
		  	this.orderNotPermit,
		  	false


	  		));
	  		console.debug('[updateSimpleOderModelArray] length==0 this.simpleOderModelArray',  this.simpleOderModelArray);
	  	}else{


	  	for(var oder in this.simpleOderModelArray){
	  		if(this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur){
	  			console.debug('[updateSimpleOderModelArray] orderFound', menuLine);
	  			orderFound = true;
	  		 	this.simpleOderModelArray[oder].orderNumber = Number(menuLine['quantity']);
	  		 	this.simpleOderModelArray[oder].sumOder =  Number(menuLine['quantity']);
	  		 	console.log('[updateSimpleOderModelArray] this.simpleOderModelArray[oder].sumOder', this.simpleOderModelArray[oder].sumOder);
	  		 	this.simpleOderModelArray[oder].changed = changed;
	  		}
	  	}

	  	if(!orderFound && menuLine['quantity']!=0){
	  		console.debug('[updateSimpleOderModelArray] !orderFound', menuLine);
	  		this.simpleOderModelArray.push(new  SimpleOderModel(
	  		orderDishIdTCur,
	  		Number(menuLine['quantity']),
	  		Number(menuLine['quantity']),
	  		nameDay,
	  		menuLine['productNr'],
		  	menuLine['productName'],
		  	'',
		  	'no categoreis',
		  	'no subcategoreis',
		  	menuLine['dietTypeNr'],
		  	menuLine['dietTypeName'],
		  	menuLine['portionSize'],
		  	menuLine['line'],
		  	/*this.orderNr,
		  	this.customerNr,
		  	this.menuNr,*/
		  	this.dishAllocationsOrderDays[nameDay].orderNr,
			this.dishAllocationsOrderDays[nameDay].customerNr,
			this.dishAllocationsOrderDays[nameDay].menuNr,
		  	changed,
		  	this.orderNotPermit,
		  	false
	  			));

	  		}
	  	}



	  	this.localStorageService.set(this.simpleOderModelArrayId, this.simpleOderModelArray);


		console.debug('[updateSimpleOderModelArray] this.simpleOderModelArray',  this.simpleOderModelArray);
	  	//this.updateCartFromLocalStorage();
	  	//this.sumOder+=1;

	  }

	checkQuantitiesChanged(simpleOderModelArray:any){
		let isQuantitiesChanged:boolean = false;
		let toggledDay = this.localStorageService.get( 'toggledDayBulkOrder');
		switch(toggledDay){
			case 'Tuesday':
			for(var oder in simpleOderModelArray){
	  		if((simpleOderModelArray[oder]['Day']=='Tuesday')
	  			|| (simpleOderModelArray[oder]['Day']=='Wednesday') )
	  			if(simpleOderModelArray[oder]['changed']==true){
	  				console.debug('[checkQuantitiesChanged] simpleOderModelArray[oder]', simpleOderModelArray[oder]);
	  				isQuantitiesChanged = true;
	  				break;
	  			}

	  		}
			break;
			case 'Thursday':
			for(var oder in simpleOderModelArray){
	  		if((simpleOderModelArray[oder]['Day']=='Thursday')
	  			|| (simpleOderModelArray[oder]['Day']=='Friday')
	  			|| (simpleOderModelArray[oder]['Day']=='Saturday') )
	  			if(simpleOderModelArray[oder]['changed']==true){
	  				console.debug('[checkQuantitiesChanged] simpleOderModelArray[oder]', simpleOderModelArray[oder]);
	  				isQuantitiesChanged = true;
	  				break;
	  			}

	  		}

			break;
			case 'Saturday':
			for(var oder in simpleOderModelArray){
	  		if((simpleOderModelArray[oder]['Day']=='Sunday')
	  			|| (simpleOderModelArray[oder]['Day']=='Monday') )
	  			if(simpleOderModelArray[oder]['changed']==true){

					console.debug('[checkQuantitiesChanged] simpleOderModelArray[oder]', simpleOderModelArray[oder]);
	  				isQuantitiesChanged = true;
	  				break;
	  			}

	  		}

			break;
			default:{}



		}

		console.debug('[checkQuantitiesChanged] isQuantitiesChanged', isQuantitiesChanged);
	  	return isQuantitiesChanged;
	}

	clearChangedOrder(simpleOderModelArray:any, toggledDay:string){


		switch(toggledDay){
			case 'Tuesday':
			for(var oder in simpleOderModelArray){
	  		if((simpleOderModelArray[oder]['Day']=='Tuesday')
	  			|| (simpleOderModelArray[oder]['Day']=='Wednesday') )
	  			if(simpleOderModelArray[oder]['changed']==true){
	  				simpleOderModelArray[oder]['changed'] = false;

	  			}

	  		}
			break;
			case 'Thursday':
			for(var oder in simpleOderModelArray){
	  		if((simpleOderModelArray[oder]['Day']=='Thursday')
	  			|| (simpleOderModelArray[oder]['Day']=='Friday')
	  			|| (simpleOderModelArray[oder]['Day']=='Saturday') )
	  			if(simpleOderModelArray[oder]['changed']==true){
	  				simpleOderModelArray[oder]['changed'] = false;

	  			}

	  		}

			break;
			case 'Saturday':
			for(var oder in simpleOderModelArray){
	  		if((simpleOderModelArray[oder]['Day']=='Sunday')
	  			|| (simpleOderModelArray[oder]['Day']=='Monday') )
	  			if(simpleOderModelArray[oder]['changed']==true){
	  				simpleOderModelArray[oder]['changed'] = false;

	  			}

	  		}

			break;
			default:{}



		}

		console.debug('simpleOderModelArray', simpleOderModelArray);
		return simpleOderModelArray;

	}

	setSendedOrderClearChanged(simpleOderModelArray:any, toggledDay:string){


		switch(toggledDay){
			case 'Tuesday':
			for(var oder in simpleOderModelArray){
	  		if((simpleOderModelArray[oder]['Day']=='Tuesday')
	  			|| (simpleOderModelArray[oder]['Day']=='Wednesday') )
	  			if(simpleOderModelArray[oder]['changed']==true){
	  				simpleOderModelArray[oder]['changed'] = false;
	  				simpleOderModelArray[oder]['sended'] = true;

	  			}

	  		}
			break;
			case 'Thursday':
			for(var oder in simpleOderModelArray){
	  		if((simpleOderModelArray[oder]['Day']=='Thursday')
	  			|| (simpleOderModelArray[oder]['Day']=='Friday')
	  			|| (simpleOderModelArray[oder]['Day']=='Saturday') )
	  			if(simpleOderModelArray[oder]['changed']==true){
	  				simpleOderModelArray[oder]['changed'] = false;
	  				simpleOderModelArray[oder]['sended'] = true;

	  			}

	  		}

			break;
			case 'Saturday':
			for(var oder in simpleOderModelArray){
	  		if((simpleOderModelArray[oder]['Day']=='Sunday')
	  			|| (simpleOderModelArray[oder]['Day']=='Monday') )
	  			if(simpleOderModelArray[oder]['changed']==true){
	  				simpleOderModelArray[oder]['changed'] = false;
	  				simpleOderModelArray[oder]['sended'] = true;

	  			}

	  		}

			break;
			default:{}



		}

		console.debug('[setSendedOrderClearChanged] simpleOderModelArray', simpleOderModelArray);
		return simpleOderModelArray;

	}

	setSendedOrderClearChangedAll(simpleOderModelArray:any){

		for(var oder in simpleOderModelArray){

	  		if(simpleOderModelArray[oder]['changed']==true){
	  			simpleOderModelArray[oder]['changed'] = false;
	  			simpleOderModelArray[oder]['sended'] = true;

	  		}

	  	}
		console.debug('[setSendedOrderClearChanged] simpleOderModelArray', simpleOderModelArray);
		return simpleOderModelArray;

	}

	getSumOrder(simpleOderModelArray:any){
		let sumOder = 0;
		for(var oder in simpleOderModelArray){

	  		sumOder += simpleOderModelArray[oder].sumOder;

	  	}
	  	return sumOder;
	}

	getSumOder(cat:any, element:number, nameDay:string){
		let catNumber;
		if(cat=='Froko'){
			catNumber = 1;
		}else if(cat=='Extra'){
			catNumber = 2;
		}else if(cat='Editweekplan'){
			catNumber = 3;
		}
		var sumOder = 0;
		//var orderDishIdTCur = nameDay + cat + element.toString();
		var orderDishIdTCur = this.getOrderDishId(
			this.localStorageService.get( 'currentChildRoute'), this.shiftWeek,
			nameDay, catNumber, element);
		for(var oder in this.simpleOderModelArray){
	  		if(this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur){

	  		 	sumOder=this.simpleOderModelArray[oder].sumOder;

	  		}
	  	}

	  	return sumOder;

	}

//-----------------------------Update SimpleOrderModelArray from respnse---------------------



updateOrdersInLocalStorageFromResponse(toggledDay:string, dishAllocationsOrderDays:any){


console.debug('[updateOrdersInLocalStorageFromResponse] dishAllocationsOrderDays', dishAllocationsOrderDays);
	for(let day in dishAllocationsOrderDays){
	  		if(dishAllocationsOrderDays[day]['mainDishes'] && (day == toggledDay)){

	  		for(let i=0; i< dishAllocationsOrderDays[day]['mainDishes'].length; i++){
	  		console.debug('[updateOrdersInLocalStorageFromResponse] mainDishes[i]', i);

	  		for(let itemdietTypeGroups in dishAllocationsOrderDays[day]['mainDishes'][i]['dietTypeGroups']){
				//for(let cat in categoryArr){
					//if(dishAllocationsOrderDays[day]['mainDishes']["dietTypeGroups"][itemdietTypeGroups]["dietTypeGroup"]["name"]==categoryArr[cat] ){

						for(let itemDietType in dishAllocationsOrderDays[day]['mainDishes'][i]['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){


console.debug('[updateOrdersInLocalStorageFromResponse] menuLine ', dishAllocationsOrderDays[day]['mainDishes'][i]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine'] );

				 		if(dishAllocationsOrderDays[day]['mainDishes'][i]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']
				 			&& dishAllocationsOrderDays[day]['mainDishes'][i]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity']>0){

							this.updateSimpleOderModelArrayFromResponse(
								'Editweekplan',
								dishAllocationsOrderDays[day]['mainDishes'][i]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine'],
								day,
								false);


				 		}

				}



					//}

				//}


			}


	  	  }

	  	}
	}
	this.sumOder = this.getSumOrder(this.simpleOderModelArray);


}

//-------------------------Update SimpleOrderModelArray from response If Exist---------------------



updateOrdersInLocalStorageFromResponseIfExist(toggledDay:string, dishAllocationsOrderDays:any){
console.debug('[updateOrdersInLocalStorageFromResponse] toggledDay', toggledDay);


console.debug('[updateOrdersInLocalStorageFromResponse] dishAllocationsOrderDays', dishAllocationsOrderDays);




	for(let day in dishAllocationsOrderDays){
	  		if(dishAllocationsOrderDays[day]['mainDishes'] && (day == toggledDay)){

	  		for(let i=0; i< dishAllocationsOrderDays[day]['mainDishes'].length; i++){
	  		console.debug('[updateOrdersInLocalStorageFromResponse] mainDishes[i]', i);

	  		for(let itemdietTypeGroups in dishAllocationsOrderDays[day]['mainDishes'][i]['dietTypeGroups']){
				//for(let cat in categoryArr){
					//if(dishAllocationsOrderDays[day]['mainDishes']["dietTypeGroups"][itemdietTypeGroups]["dietTypeGroup"]["name"]==categoryArr[cat] ){

						for(let itemDietType in dishAllocationsOrderDays[day]['mainDishes'][i]['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){


console.debug('[updateOrdersInLocalStorageFromResponse] menuLine ', dishAllocationsOrderDays[day]['mainDishes'][i]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine'] );

				 		if(dishAllocationsOrderDays[day]['mainDishes'][i]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine']
				 			){

							this.updateSimpleOderModelArrayFromResponseIfExist(
								'Editweekplan',
								dishAllocationsOrderDays[day]['mainDishes'][i]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietType]['menuLine'],
								day,
								false);


				 		}

				}



					//}

				//}


			}


	  	  }

	  	}
	}
	this.sumOder = this.getSumOrder(this.simpleOderModelArray);


}


updateSimpleOderModelArrayFromResponse(cat:any, menuLine:any, nameDay:string, changed:boolean){

console.debug('[updateSimpleOderModelArrayFromResponse]');
console.debug('[updateSimpleOderModelArrayFromResponse] nameDay', nameDay);
console.debug('[updateSimpleOderModelArrayFromResponse] cat', cat);
		console.info('[updateSimpleOderModelArray] menuLine', menuLine);
		//console.debug("nameDay", nameDay);

		let catNumber;
		if(cat=='Froko'){
			catNumber = 1;
			}else if(cat=='Extra'){
			catNumber = 2;
			}else if(cat='Editweekplan'){
				catNumber = 3;
			}
		var orderFound = false;

	  //	var orderDishIdTCur = nameDay + cat + element.toString();
	  	let element = Number(menuLine['productNr']) + Number(menuLine['line']);
	  	var orderDishIdTCur = this.getOrderDishId(
	  		this.localStorageService.get( 'currentChildRoute'), this.shiftWeek,
	  		nameDay, catNumber, element);
	  	console.debug('[updateSimpleOderModelArrayFromResponse] orderDishIdTCurNumber='+orderDishIdTCur);

	  	if((this.simpleOderModelArray.length==0) && menuLine['quantity']!=0){
	  		console.debug('[updateSimpleOderModelArrayFromResponse] this.simpleOderModelArray.length=', menuLine);
	  		console.debug('[updateSimpleOderModelArrayFromResponse] menuLine[quantity]', menuLine['quantity']);
	  		this.simpleOderModelArray.push(new  SimpleOderModel(
	  				orderDishIdTCur,
	  				Number(menuLine['quantity']),
	  				//3,
	  				Number(menuLine['quantity']),
	  				//3,
	  				nameDay,
	  		menuLine['productNr'],
		  	menuLine['productName'],
		  	'',
		  	'no categoreis',
		  	'no subcategoreis',
		  	menuLine['dietTypeNr'],
		  	menuLine['dietTypeName'],
		  	menuLine['portionSize'],
		  	menuLine['line'],
		  	/*this.orderNr,
		  	this.customerNr,
		  	this.menuNr,
		  	changed*/
		  	this.dishAllocationsOrderDays[nameDay].orderNr,
			this.dishAllocationsOrderDays[nameDay].customerNr,
			this.dishAllocationsOrderDays[nameDay].menuNr,
			changed,
			this.orderNotPermit,
			false

	  		));
	  		console.debug('[updateSimpleOderModelArrayFromResponse] length==0 this.simpleOderModelArray',  this.simpleOderModelArray);
	  	}else{


	  	for(var oder in this.simpleOderModelArray){
	  		if(this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur){
	  			console.debug('[updateSimpleOderModelArrayFromResponse] orderFound', menuLine);
	  			orderFound = true;
	  			if((this.simpleOderModelArray[oder].changed!=true)
	  				&& (this.simpleOderModelArray[oder].sended!=true)){

	  				this.simpleOderModelArray[oder].orderNumber = Number(menuLine['quantity']);
	  		 		this.simpleOderModelArray[oder].sumOder =  Number(menuLine['quantity']);
	  		 		console.log('[updateSimpleOderModelArrayFromResponse] this.simpleOderModelArray[oder].sumOder', this.simpleOderModelArray[oder].sumOder);
	  		 		this.simpleOderModelArray[oder].changed = changed;

	  			}

	  		}
	  	}

	  	if(!orderFound){
	  		console.debug('[updateSimpleOderModelArrayFromResponse] !orderFound', menuLine);
	  		this.simpleOderModelArray.push(new  SimpleOderModel(
	  		orderDishIdTCur,
	  		Number(menuLine['quantity']),
	  		Number(menuLine['quantity']),
	  		nameDay,
	  		menuLine['productNr'],
		  	menuLine['productName'],
		  	'',
		  	'no categoreis',
		  	'no subcategoreis',
		  	menuLine['dietTypeNr'],
		  	menuLine['dietTypeName'],
		  	menuLine['portionSize'],
		  	menuLine['line'],
		  	/*this.orderNr,
		  	this.customerNr,
		  	this.menuNr,*/
		  	this.dishAllocationsOrderDays[nameDay].orderNr,
			this.dishAllocationsOrderDays[nameDay].customerNr,
			this.dishAllocationsOrderDays[nameDay].menuNr,
		  	changed,
		  	this.orderNotPermit,
		  	false
	  			));

	  		}
	  	}



	  	this.localStorageService.set(this.simpleOderModelArrayId, this.simpleOderModelArray);


		console.debug('[updateSimpleOderModelArrayFromResponse] this.simpleOderModelArray',  this.simpleOderModelArray);

	  }
//------------------------------------------------------------------------------------------------------------

updateSimpleOderModelArrayFromResponseIfExist(cat:any, menuLine:any, nameDay:string, changed:boolean){

console.debug('[updateSimpleOderModelArrayFromResponseIfExist]');
console.debug('[updateSimpleOderModelArrayFromResponseIfExist] nameDay', nameDay);
console.debug('[updateSimpleOderModelArrayFromResponseIfExist] cat', cat);
		console.info('[updateSimpleOderModelArrayFromResponseIfExist] menuLine', menuLine);
		//console.debug("nameDay", nameDay);

		let catNumber;
		if(cat=='Froko'){
			catNumber = 1;
			}else if(cat=='Extra'){
			catNumber = 2;
			}else if(cat='Editweekplan'){
				catNumber = 3;
			}
		var orderFound = false;

	  //	var orderDishIdTCur = nameDay + cat + element.toString();
	  	let element = Number(menuLine['productNr']) + Number(menuLine['line']);
	  	var orderDishIdTCur = this.getOrderDishId(
	  		this.localStorageService.get( 'currentChildRoute'), this.shiftWeek,
	  		nameDay, catNumber, element);
	  	console.debug('[updateSimpleOderModelArrayFromResponseIfExist] orderDishIdTCurNumber='+orderDishIdTCur);

	  //	if((this.simpleOderModelArray.length==0) && menuLine["quantity"]!=0){
	  //	}else{


	  	for(var oder in this.simpleOderModelArray){
	  		if(this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur){
	  			console.debug('[updateSimpleOderModelArrayFromResponseIfExist] orderFound', menuLine);
	  			orderFound = true;
	  			if(this.simpleOderModelArray[oder].changed=true){

	  				this.simpleOderModelArray[oder].orderNumber = Number(menuLine['quantity']);
	  		 		this.simpleOderModelArray[oder].sumOder =  Number(menuLine['quantity']);
	  		 		console.log('[updateSimpleOderModelArrayFromResponseIfExist] this.simpleOderModelArray[oder].sumOder', this.simpleOderModelArray[oder].sumOder);
	  		 		this.simpleOderModelArray[oder].changed = changed;

	  			}

	  		}
	  	}
	  	/*
	  	if(!orderFound){
	  		console.debug("[updateSimpleOderModelArrayFromResponseIfExist] !orderFound", menuLine);
	  		this.simpleOderModelArray.push(new  SimpleOderModel(
	  		orderDishIdTCur,
	  		Number(menuLine["quantity"]),
	  		Number(menuLine["quantity"]),
	  		nameDay,
	  		menuLine["productNr"],
		  	menuLine["productName"],
		  	'',
		  	"no categoreis",
		  	"no subcategoreis",
		  	menuLine["dietTypeNr"],
		  	menuLine["dietTypeName"],
		  	menuLine["portionSize"],
		  	menuLine["line"],

		  	this.dishAllocationsOrderDays[nameDay].orderNr,
			this.dishAllocationsOrderDays[nameDay].customerNr,
			this.dishAllocationsOrderDays[nameDay].menuNr,
		  	changed,
		  	this.orderNotPermit
	  			));

	  		}*/
	  	//}



	  	this.localStorageService.set(this.simpleOderModelArrayId, this.simpleOderModelArray);


		console.debug('[updateSimpleOderModelArrayFromResponseIfExist] this.simpleOderModelArray',  this.simpleOderModelArray);
	  	console.debug('[updateSimpleOderModelArrayFromResponseIfExist] this.simpleOderModelArray',  JSON.stringify(this.simpleOderModelArray));
	  }

////----------------------------------------------------------------------------------------------------------

	prevWeek(){
		this.shiftWeek--;
		this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'currentChildRoute'), this.shiftWeek );
		this.updateCartFromLocalStorage();
		this.needNgOninit = true;
		//this.needUnsubscribe = false;

		this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
		//this.ngOnInit();


	  }
	nextWeek(){
	  	this.shiftWeek++;
		this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'currentChildRoute'), this.shiftWeek );
		this.updateCartFromLocalStorage();
		this.needNgOninit = true;
		//this.needUnsubscribe = false;

		this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
		//this.ngOnInit();
	  }

	getImgUrl(imageUrl:string):string{

	  //	console.debug("IMAGEUrl:"+ imageUrl);
	  var imgUrl = this.dataSource.baseUrl + imageUrl
	  //	console.debug("IMG:"+ imgUrl);
	 // 	return imgUrl.toString();
	 return imgUrl;
	}

/*	public getSantizeUrl(imageUrl : string) {

    //return this.sanitizer.bypassSecurityTrustUrl(this.getImgUrl(url));
   // return 'https://testapi.minmenu.com/Content/Upload/DishImages/331/496197e5-4161-4aab-bee3-7f66d5c1fe78.jpg'

	var imgUrl = this.dataSource.baseUrl + imageUrl
	//  	console.debug("IMG:"+ imgUrl);
	  	return imgUrl;

	}
*/

	getTargetId(cat:any, nameDay:string, itemDish:any):string{
	//  console.debug("In getTargetId cat:"+ cat);
	//  console.debug("In getTargetId nameDay:"+ nameDay);
	//  console.debug("In getTargetId itemDish:"+ itemDish);
	  var id ='#' + cat +nameDay + itemDish;
	//  	console.debug("In getTargetId IMG:"+ id);
	  	return id.toString();
	}

	getId(cat:any, nameDay:string, itemDish:any):string{


	  var id =cat +nameDay + itemDish;
	//  	console.debug("In getId IMG:"+ id);
	  	return id.toString();
	}


/*
    addProductToCart(dish: SimpleOderModel) {
        this.cart.addLine(dish);


    }
*/
   /* getOrderDishId(){
    	return this.orderDishId++;
    }
  */

  getOrderDishId(fromPage:string, shiftWeek:number, day:string, cat:number, dishPlace:number){
  		/*console.debug("fromPage="+fromPage);
  		console.debug("shiftWeek="+shiftWeek);
  		console.debug("day="+day);
  		console.debug("dishPlace="+dishPlace);
  		console.debug("cat="+cat);*/

  		var pageNumber:number;
  		let orderDishId:number=0;
  		var week = ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  		switch(fromPage){
  			case 'frokostpakker':{ pageNumber = 1;
  			//	console.debug("frokostpakker");
  				break;}
  			case 'extra':{pageNumber = 2;
  			//console.debug("extra");
  				break;}
  			case 'bulkorder/editweekplan':{pageNumber = 3;
  			//console.debug("extra");
  				break;}

  		}

  		orderDishId = Number(pageNumber*1000000 + (Number(this.dateService.getCurrentWeek()) + Number(shiftWeek))*1000 + Number((week.indexOf(day))*1000) + cat*100 + dishPlace);

  		console.debug('InorderDishId', orderDishId);
  		return orderDishId;
    }

/*
    subProductToCart(dish: SimpleOderModel, quantity: number){
     	this.cart.subLine(dish, quantity);
    }
*/
    updateCartFromLocalStorage(){

    	this.cart.updateLinesFromLocalStorage(this.shiftWeekNumber);
    }



    accept(){

   		//this.shiftWeek--;
		//this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'currentChildRoute'), this.shiftWeek );
		//this.needNgOninit = true;
		//this.updateCartFromLocalStorage();
	//	this.localStorageService.set(this.simpleOderModelArrayId, null);
		this.router.navigateByUrl('/minmenu/cart');


    }

/*    getRowsFordishesArray(numberElementsInRow:number){
    	var rowsNumber = Math.trunc(this.dishesArray.length/numberElementsInRow);
    	if(this.dishesArray.length%numberElementsInRow!=0){
    		rowsNumber++;
    	}
    	return new Array(rowsNumber);

    	///return [1, 2, 3]
    }
*/
/*    getRowsNumber(numberElementsInRow:number){
    	var rowsNumber = Math.trunc(this.dishesArray.length/numberElementsInRow);
    	if(this.dishesArray.length%numberElementsInRow!=0){
    		rowsNumber++;
    	}
    	return rowsNumber;


    }
*/
    getRowsNumberForDay(numberElementsInRow:number, dishesArraylength:number){

   		var rowsNumber = Math.trunc(dishesArraylength/numberElementsInRow);
    //	console.debug("In getRowsNumberForDay rowsNumber="+rowsNumber);

  		if(dishesArraylength%numberElementsInRow!=0){
    		rowsNumber++;
    	}
    	if(rowsNumber==0){
    		rowsNumber = 1;
    	}
    	return rowsNumber;


    }



	createSumMatrixForDays(dishAllocationsOrderDays:any){
		let sumMatrixForDaes = new SumMatrixForDays();
		let numberElementsInRow = 5;
		console.debug('[createSumMatrixForDays] dishAllocationsOrderDaysSum', dishAllocationsOrderDays);
		for(let curDay in dishAllocationsOrderDays){

			if(dishAllocationsOrderDays[curDay]!==undefined && dishAllocationsOrderDays[curDay]['mainDishes']!==undefined){
				//console.debug("this.dishAllocationsOrderDays[day][\"mainDishes\"]!==undefined", dishAllocationsOrderDays[curDay]);

				for (let i = 0; i < dishAllocationsOrderDays[curDay]['mainDishes'].length; i++) {
			  sumMatrixForDaes[curDay][i] = new Array(numberElementsInRow);
			  //console.debug("sumMatrixForDaesInit", sumMatrixForDaes);
			  //arrayViewController[i] = new Array(numberElementsInRow);
			}
			for (let i = 0; i < sumMatrixForDaes[curDay].length; i++){
				for (let j = 0; j < sumMatrixForDaes[curDay][i].length; j++){


					sumMatrixForDaes[curDay][i][j] = 0;

				}
			}
		}

			}

		console.debug('RETURNsumMatrixForDays', sumMatrixForDaes);
		return sumMatrixForDaes;
	}

	createSumMatrixForCurrentDay(dishAllocationsOrderDays:any, day:string){
		let sumMatrixForDaes = new SumMatrixForDays();
		let numberElementsInRow = 5;
		//console.debug("[createSumMatrixForCurrentDay] dishAllocationsOrderDaysSum", dishAllocationsOrderDays);
		for(let curDay in dishAllocationsOrderDays){
		//for(let curDay = 0; curDay < 7; curDay++ ){
			//console.debug("curDay", curDay);

			//console.debug("this.dishAllocationsOrderDaysSum[day]", dishAllocationsOrderDays[curDay]);

			//var arrayViewController = new Array(rows);
			if(dishAllocationsOrderDays[curDay]['mainDishes']!==undefined){
				//console.debug("this.dishAllocationsOrderDays[day][\"mainDishes\"]!==undefined", dishAllocationsOrderDays[curDay]);

				for (let i = 0; i < dishAllocationsOrderDays[curDay]['mainDishes'].length; i++) {
			  sumMatrixForDaes[curDay][i] = new Array(numberElementsInRow);
			  //console.debug("sumMatrixForDaesInit", sumMatrixForDaes);
			  //arrayViewController[i] = new Array(numberElementsInRow);
			}
			for (let i = 0; i < sumMatrixForDaes[curDay].length; i++){
				for (let j = 0; j < sumMatrixForDaes[curDay][i].length; j++){

					//console.debug("j", j);
					//console.debug("i", i);
					//console.debug("sumMatrixForDaes[curDay]", sumMatrixForDaes[curDay]);
					sumMatrixForDaes[curDay][i][j] = 0;

				}
			}
		}

			}

		console.debug('RETURNsumMatrixForDays', sumMatrixForDaes);
		return sumMatrixForDaes;
	}
    getArrayDishesViewForDayController(numberElementsInRow:number, dishesArraylength:number){

    	var rows=this.getRowsNumberForDay(numberElementsInRow, dishesArraylength);
    //	console.debug("rows="+rows);

    	var arrayViewController = new Array(rows);
		for (var i = 0; i < arrayViewController.length; i++) {
		  arrayViewController[i] = new Array(numberElementsInRow);
		}
		for (var i = 0; i < arrayViewController.length; i++){
			for (var j = 0; j < arrayViewController[i].length; j++){
				arrayViewController[i][j] = -1;
			}
		}

	//	console.debug("array="+JSON.stringify(arrayViewController));


    	var k = numberElementsInRow;
    	var ref = 0;
    	for(var i=0; i<rows; i++){
    		if(dishesArraylength>numberElementsInRow){
	    		if(i == rows-1){
	    			if(dishesArraylength%numberElementsInRow!=0){
	    			k = dishesArraylength%numberElementsInRow;
	    			console.debug('k='+k);

	    			arrayViewController[i].length = k;      // trim last row to the number of items remainder
	    			}
	    		}
    		}else{
    			k = dishesArraylength;
    			arrayViewController[i].length = k;
    		}

    		for(var j=0; j<k; j++){

    			arrayViewController[i][j]=ref;
    			ref++;
    		}

    	}

    //	console.debug("array="+JSON.stringify(arrayViewController));




     return arrayViewController;
    }

    toggleMonday(){
    this.toggledMonday = true;
	this.toggledWednesday = false;
	this.toggledFriday = false;
	//console.debug("Monday Wednesday Friday" + this.toggledMonday+this.toggledWednesday+this.toggledFriday);

    }

     toggleWednesday(){
     	this.toggledMonday = false;
		this.toggledWednesday = true;
		this.toggledFriday = false;
	//	console.debug("Monday Wednesday Friday" + this.toggledMonday+this.toggledWednesday+this.toggledFriday);
     }


     toggleFriday(){
     	this.toggledMonday = false;
		this.toggledWednesday = false;
		this.toggledFriday = true;
	//	console.debug("Monday Wednesday Friday" + this.toggledMonday+this.toggledWednesday+this.toggledFriday);

     }

     toggleTuesday(content:any){

     	this.nextDay = 'Tuesday';
		//this.needNgOninit = true;
		//this.needUnsubscribe = false;

		this.isQuantitiesChanged = this.checkQuantitiesChanged(this.simpleOderModelArray);
		if(this.isQuantitiesChanged){
			this.openWindowCustomClass(content);
		}else{
			this.switchTuesday();
		 }


    }

    switchTuesday(){
    	if((!this.isQuantitiesChanged) || this.continueSwitching){
			this.toggledTuesday = true;
			this.toggledThursday = false;
			this.toggledSaturday = false;
			this.localStorageService.set( 'toggledDayBulkOrder', 'Tuesday');
			this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
		}
    }

     toggleThursday(content:any){

     	this.nextDay = 'Thursday';
		//this.needNgOninit = true;
		//this.needUnsubscribe = false;

		 	this.isQuantitiesChanged = this.checkQuantitiesChanged(this.simpleOderModelArray);
		if(this.isQuantitiesChanged){
			this.openWindowCustomClass(content);
		}else{
			this.switchThursday();
		 }


     }
      switchThursday(){
    	if((!this.isQuantitiesChanged) || this.continueSwitching){
			this.toggledTuesday = false;
			this.toggledThursday = true;
			this.toggledSaturday = false;
			this.localStorageService.set( 'toggledDayBulkOrder', 'Thursday');
			this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
		}
    }

     toggleSaturday(content:any){

     	this.nextDay = 'Saturday';
		//this.needNgOninit = true;
		//this.needUnsubscribe = false;

			this.isQuantitiesChanged = this.checkQuantitiesChanged(this.simpleOderModelArray);
		if(this.isQuantitiesChanged){
			this.openWindowCustomClass(content);
		}else{
			this.switchSaturday();
		 }





     }

  	 switchSaturday(){
    	if((!this.isQuantitiesChanged) || this.continueSwitching){
			this.toggledTuesday = false;
			this.toggledThursday = false;
			this.toggledSaturday = true;
			this.localStorageService.set( 'toggledDayBulkOrder', 'Saturday');
			this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
		}
    }
     isDayForOrder(orderingDates:any[], nameDay:string,  shiftWeek:number){
     //	if(this.filterOn){
     	var isDayForOrderFlag = false;
     	var date = this.dateService.getISODateYYYMMDDforDay(nameDay,  shiftWeek);
     //	console.debug("DATE ="+date);
     	for(var day in this.orderingDates){
     		if(date==this.orderingDates[day]){
     			isDayForOrderFlag = true;
     			}
     		}

     	return isDayForOrderFlag;

     //return true;


     }

     filterToogle(){
     	this.filterOn =!this.filterOn;
     }

     isOrderingTime(userInfoOrderingModel:any[], categoryForFilter:string){
     //	console.debug("In isOrderingTime");
     	var startServingTime = '';
     	var endServingTime = '';
     	var currentTime=0;
     	for(var userCategory in userInfoOrderingModel['userCategories']){
     //		console.debug("In isOrderingTime cat="+userInfoOrderingModel["userCategories"][userCategory]["category"]["name"]);
     		if(userInfoOrderingModel['userCategories'][userCategory]['category']['name']==categoryForFilter){

     			startServingTime = userInfoOrderingModel['userCategories'][userCategory]['startServingTime'];
     		//	var dateStart =  this.dateService.getDateFromTimeHHmmss(startServingTime);

     		//	console.debug("IsstartServingTime=" + this.dateService.returnCurrentDateForTimeHHmmss(dateStart));
     			endServingTime = userInfoOrderingModel['userCategories'][userCategory]['endServingTime'];

     		//	var dateEnd =  this.dateService.getDateFromTimeHHmmss(endServingTime);

     		//	console.debug("IsendServingTime=" + this.dateService.returnCurrentDateForTimeHHmmss(dateEnd));

     		//	currentTime = this.dateService.getCurrentTime();
     		//	console.debug("currentTime=" + currentTime);
     		//	if(this.dateService.returnCurrentDateForTimeHHmmss(dateStart)<=currentTime &&
     		//	currentTime<=this.dateService.returnCurrentDateForTimeHHmmss(dateEnd)){
     			if( this.dateService.isCurrentTimeBetween(startServingTime, endServingTime)){

     			return this.isOrderingTimeFlag=true;
     		//	}else return this.isOrderingTimeFlag=false;
     			}else return this.isOrderingTimeFlag=true;

     		}
     	}



     }



/*    open(content) {
		    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
		      this.closeResult = `Closed with: ${result}`;
		    }, (reason) => {
		    //  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		    });
  		}
*/
  	openVerticallyCentered(content) {
    	this.modalService.open(content, { centered: true} );
  		}

  	private getDismissReason(reason: any): string {
		    if (reason === ModalDismissReasons.ESC) {
		      return 'by pressing ESC';
		    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		      return 'by clicking on a backdrop';
		    } else {
		      return  `with: ${reason}`;
		    }
	  }

	openWindowCustomClass(content) {
    	this.modalService.open(content, {centered: true, windowClass: 'modalWindowEditWeekPlan' });
     }

	editTableWeekplan(content:any, itemMenuLines:any, day:string){
		this.currentDayForModal = day;
		console.log('this.currentDayForModal', this.currentDayForModal);
		this.currentItemMenuLines = itemMenuLines;
		console.log('this.currentItemMenuLines', JSON.stringify(this.currentItemMenuLines));
//------------------------------GET modalKodCategoryMap---------------------------------
		this.modalKodCategoryMap = this.getKodCategoryArrayForMenuLine(itemMenuLines, itemMenuLines['menuLine']['productNr']);

		console.debug('[editTableWeekplan] this.modalKodCategoryMap', this.modalKodCategoryMap);
		console.debug('[editTableWeekplan] map.size', this.modalKodCategoryMap.size);
		if(this.modalKodCategoryMap.size!=0){
			this.isModalKodCategoryMapNonEmpty = true;
			this.modalKodCategoryMap.forEach(function(value, key,ownerSet){
			console.log('[editTableWeekplan] key', key);
			console.log('[editTableWeekplan] value', value);
			for(let itemTibehor in value){
			let itemSelected = this.findBtKodTilbehorFromMenuLine(itemMenuLines,
			key,
			value[itemTibehor]['tibehorCategory'],
			itemMenuLines['dietTypeGroups'][0]['dietTypes'][0]['menuLine']['productNr'],
			0);
			value[itemTibehor]['tibehorQuantity'] = Number(itemSelected['quantity']);
			//value[itemTibehor]["tibehorQuantity"]=100;

			}

		}, this);

		console.debug('[editTableWeekplan] modalKodCategoryMap2', this.modalKodCategoryMap);

		this.modalKodCategoryArrayFiltered = Array.from(this.getFilteredMapByQuantitiesSoft(this.modalKodCategoryMap, itemMenuLines));

		/*let arrTemp = JSON.parse(JSON.stringify(Array.from(this.modalKodCategoryMap)));
		console.debug("[editTableWeekplan] arrTemp", arrTemp);

		let modalKodCategoryMapClone = new Map(arrTemp);
		console.debug("[editTableWeekplan] modalKodCategoryMapClone2", modalKodCategoryMapClone);

		//this.modalKodCategoryArrayFiltered = Array.from(this.getFilteredMapByQuantities(modalKodCategoryMapClone, itemMenuLines));
		this.modalKodCategoryArrayFiltered = Array.from(this.getFilteredMapByQuantitiesSoft(modalKodCategoryMapClone, itemMenuLines));
		*/
		console.debug('[editTableWeekplan] modalKodCategoryArrayFiltered', this.modalKodCategoryArrayFiltered);

		console.debug('[editTableWeekplan] this.modalKodCategoryMap After Filter', this.modalKodCategoryMap);




		this.modalKodCategoryArray = Array.from(this.modalKodCategoryMap);
		console.debug('[editTableWeekplan] modalKodCategoryArray', this.modalKodCategoryArray);



		this.defaultKod = this.modalKodCategoryArray[0][0];

		this.selectedKodFuldkost = this.defaultKod;

		this.currentTilbehorArr = this.modalKodCategoryArray[0][1];  //Kod Category
		console.debug('[editTableWeekplan] this.currentTilbehorArr', this.currentTilbehorArr);

		this.defaultTilbehor = this.currentTilbehorArr[0]['tibehorCategory'];

		this.selectedStivelseFuldkost = this.defaultTilbehor;

		console.debug('[editTableWeekplan] itemMenuLines', itemMenuLines);


		//this.contentFuldkostAntal = itemMenuLines['dietTypeGroups'][0]['dietTypes'][0]['menuLine']['quantity']

		//console.debug("[editTableWeekplan] itemSelected", itemSelected);
		this.contentFuldkostAntal = this.currentTilbehorArr[0]['tibehorQuantity'];
		//this.contentFuldkostAntalNew = 0;
		console.debug('this.content',content);
		}else{

			this.isModalKodCategoryMapNonEmpty = false;
		}


//------------------Lines Arr--------------------------------------------

		this.subLinesSetArr =Array.from(this.getSubLinesSetForMenuLineFuldcostBulk(itemMenuLines))
		console.debug('subLinesSetArr', this.subLinesSetArr);
		this.subLinesSetKodArr =Array.from(this.getSubLinesSetForMenuLineFuldcostKod(itemMenuLines))
		console.debug('subLinesSetKodArr', this.subLinesSetKodArr);
		this.subLinesSetTilbehorArr =Array.from(this.getSubLinesSetForMenuLineFuldcostTilbehor(itemMenuLines))
		console.debug('subLinesSetTilbehorArr', this.subLinesSetTilbehorArr);
		this.subLinesSetGrontArr =Array.from(this.getSubLinesSetForMenuLineFuldcostGront(itemMenuLines))
		console.debug('subLinesSetGrontArr', this.subLinesSetGrontArr);
		this.subLinesSetSovsArr =Array.from(this.getSubLinesSetForMenuLineFuldcostSovs(itemMenuLines))
		console.debug('subLinesSetSovsArr', this.subLinesSetSovsArr);

		this.subLinesSetArrEnkel =Array.from(this.getSubLinesSetForMenuLineFuldkostEnkel(itemMenuLines))
		console.debug('subLinesSetArrEnkel', this.subLinesSetArrEnkel);


		this.openWindowCustomClass(content);


	}

	editTableWeekplanEnergitat(content:any, itemMenuLines:any, day?:string){
		//this.contentFuldkostAntal = this.dishAllocationsOrderDays['Tuesday']['mainDishes'][0]['menuLine']['quantity'];
		this.currentDayForModal = day;
		this.currentItemMenuLines = itemMenuLines;
		console.debug('[editTableWeekplanEnergitat] this.currentDayForModal', this.currentDayForModal);

		for(let itemMainDishes in this.sumMatrixForDays[day]){
			this.contentEnergitatAntalArr[itemMainDishes] = new Array(this.sumMatrixForDays[this.currentDayForModal].length);
			console.debug('this.contentEnergitatAntalArr', this.contentEnergitatAntalArr);

			if(this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][1]){

			for(let itemDietTypes in this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][1]['dietTypes']){
			if(this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][1]['dietTypes'][itemDietTypes]!=undefined){
				this.contentEnergitatAntalArr[itemMainDishes][itemDietTypes] =	this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][1]['dietTypes'][itemDietTypes]['menuLine']['quantity'];
			}else{
				this.contentEnergitatAntalArr[itemMainDishes][itemDietTypes] = 0;
			}



		}
			}



		}

		/*for(let itemMainDishes in this.sumMatrixForDays[day]){
			this.contentEnergitatAntalArr[itemMainDishes] = new Array(this.sumMatrixForDays[this.currentDayForModal].length);
			console.debug("this.contentEnergitatAntalArr", this.contentEnergitatAntalArr);

			if(this.dishEnergitatAllDayFilteredDietTypeGroups[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][0]!=undefined){

			for(let itemDietTypes in this.dishEnergitatAllDayFilteredDietTypeGroups[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][0]['dietTypes']){
			if(this.dishEnergitatAllDayFilteredDietTypeGroups[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][0]['dietTypes'][itemDietTypes]!=undefined){
				this.contentEnergitatAntalArr[itemMainDishes][itemDietTypes] =	this.dishEnergitatAllDayFilteredDietTypeGroups[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['menuLine']['quantity'];
			}else{
				this.contentEnergitatAntalArr[itemMainDishes][itemDietTypes] = 0;
			}



		}
			}



		}*/

		console.debug('[editTableWeekplanEnergitat] this.contentEnergitatAntalArr', this.contentEnergitatAntalArr);
		this.openWindowCustomClass(content);




	}

	editTableWeekplanBlod(content:any, itemMenuLines:any, day?:string){

		this.currentDayForModal = day;
		this.currentItemMenuLines = itemMenuLines;
		console.debug('[editTableWeekplanBlod] this.currentDayForModal', this.currentDayForModal);

		for(let itemMainDishes in this.sumMatrixForDays[day]){
			this.contentBlodAntalArr[itemMainDishes] = new Array(this.sumMatrixForDays[this.currentDayForModal].length);
			console.debug('this.contentBlodAntalArr', this.contentBlodAntalArr);

			if(this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][2]){

			for(let itemDietTypes in this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][2]['dietTypes']){
			if(this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][2]['dietTypes'][itemDietTypes]!=undefined){
				this.contentBlodAntalArr[itemMainDishes][itemDietTypes] =	this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][2]['dietTypes'][itemDietTypes]['menuLine']['quantity'];
			}else{
				this.contentBlodAntalArr[itemMainDishes][itemDietTypes] = 0;
			}



		}
			}



		}
		console.debug('[editTableWeekplanBlod] this.contentBlodAntalArr', this.contentBlodAntalArr);
		this.openWindowCustomClass(content);


	}

	editTableWeekplanAndet(content:any, itemMenuLines:any, day?:string){

		//this.contentFuldkostAntal = this.dishAllocationsOrderDays['Tuesday']['mainDishes'][0]['menuLine']['quantity'];
		this.currentDayForModal = day;
		this.currentItemMenuLines = itemMenuLines;
		console.debug('this.currentItemMenuLines', this.currentItemMenuLines);

		//this.dishAllDayFilteredDietTypeGroups[day] = JSON.parse(JSON.stringify(this.getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays[day])));

console.debug('this.dishAllDayFilteredDietTypeGroups1', JSON.stringify(this.dishAllDayFilteredDietTypeGroups));
console.debug('this.dishAllDayFilteredDietTypeGroups1', this.dishAllDayFilteredDietTypeGroups);
		for(let itemMainDishes in this.dishAllDayFilteredDietTypeGroups[day]['mainDishes']){

				this.contentAndetAntalArr[itemMainDishes] = new Array(this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][itemMainDishes]['dietTypeGroups'].length);
				console.debug('this.contentAndetAntalArr1', this.contentAndetAntalArr);

			for(let itemdietTypeGroups in this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][itemMainDishes]['dietTypeGroups']){

				//this.contentAndetAntalArr[itemMainDishes] = new Array(this.sumMatrixForDays[this.currentDayForModal].length);
				//this.contentAndetAntalArr[itemMainDishes] = new Array(this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes]['dietTypeGroups'].length);


				//console.debug("this.contentAndetAntalArrL", this.getFilteredDietTypeGroupsAndet(this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes]['dietTypeGroups']).length);

				if(this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][itemdietTypeGroups]!==undefined){

					this.contentAndetAntalArr[itemMainDishes][itemdietTypeGroups] = new Array(this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'].length);
					console.debug('this.contentAndetAntalArr', this.contentAndetAntalArr);
				for(let itemDietTypes in this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][itemdietTypeGroups]['dietTypes']){
				if(this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietTypes]!=undefined){
				this.contentAndetAntalArr[itemMainDishes][itemdietTypeGroups][itemDietTypes] =	this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][itemdietTypeGroups]['dietTypes'][itemDietTypes]['menuLine']['quantity'];
				}else{
					this.contentAndetAntalArr[itemMainDishes][itemdietTypeGroups][itemDietTypes] = 0;
				}



		}
			}

			}




		}
		console.debug('this.contentAndetAntalArr', this.contentAndetAntalArr);
		/*for(let itemMainDishes in this.sumMatrixForDays[day]){
			this.contentAndetAntalArr2[itemMainDishes] = new Array(this.sumMatrixForDays[this.currentDayForModal].length);
			console.debug("this.contentAndetAntalArr", this.contentAndetAntalArr);

			if(this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][4]!=undefined){

			for(let itemDietTypes in this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][4]['dietTypes']){
			if(this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][4]['dietTypes'][itemDietTypes]!=undefined){
				this.contentAndetAntalArr2[itemMainDishes][itemDietTypes] =	this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][4]['dietTypes'][itemDietTypes]['menuLine']['quantity'];
			}else{
				this.contentAndetAntalArr2[itemMainDishes][itemDietTypes] = 0;
			}



		}
			}



		}*/
		//console.debug("this.contentAndetAntalArr", this.contentAndetAntalArr2);
		//console.debug("this.contentAndetAntalArr", this.contentAndetAntalArr);
		this.openWindowCustomClass(content);


	}


	getDishAllocationsOrderDayFilteredDietTypeGroupsCat(dishAllocationsOrderDay:any, category:string){

//console.debug("DietTypeGeoups", DietTypeGeoups);
		let filteredDietTypeGroups = [];
		filteredDietTypeGroups = JSON.parse(JSON.stringify(dishAllocationsOrderDay));
		//filteredDietTypeGroups = dishAllocationsOrderDay;
		for(let itemMainDishes in dishAllocationsOrderDay['mainDishes']){
			let dietTypeGroupsArr = [];
			dietTypeGroupsArr = (dishAllocationsOrderDay['mainDishes'][itemMainDishes]['dietTypeGroups'].filter( el =>{
			if(el['dietTypeGroup']['name']==category){

//console.debug("DietTypeGeoupsEl", el);
				return true;
			}else{
				return false;
			}

				}))

				filteredDietTypeGroups['mainDishes'][itemMainDishes]['dietTypeGroups'] = JSON.parse(JSON.stringify(dietTypeGroupsArr));
		}



		console.debug('[getDishAllocationsOrderDayFilteredDietTypeGroupsCat] filteredDietTypeGroups', filteredDietTypeGroups);
		return filteredDietTypeGroups;
	}

	getDishAllocationsOrderDayFilteredDietTypeGroupsAndet(dishAllocationsOrderDay:any){

//console.debug("DietTypeGeoups", DietTypeGeoups);
		let filteredDietTypeGroups = [];
		filteredDietTypeGroups = JSON.parse(JSON.stringify(dishAllocationsOrderDay));
		//filteredDietTypeGroups = dishAllocationsOrderDay;
		for(let itemMainDishes in dishAllocationsOrderDay['mainDishes']){
			let dietTypeGroupsArr = [];
			dietTypeGroupsArr = (dishAllocationsOrderDay['mainDishes'][itemMainDishes]['dietTypeGroups'].filter( el =>{
			if((el['dietTypeGroup']['name']!='Fuldkost')
				&& (el['dietTypeGroup']['name']!='Energitæt')
				&& (el['dietTypeGroup']['name']!='Blød')){

//console.debug("DietTypeGeoupsEl", el);
				return true;
			}else{
				return false;
			}

				}))

				filteredDietTypeGroups['mainDishes'][itemMainDishes]['dietTypeGroups'] = JSON.parse(JSON.stringify(dietTypeGroupsArr));
		}



		console.debug('filteredDietTypeGroups', filteredDietTypeGroups);
		return filteredDietTypeGroups;
	}

	subNumberFuldkost(){
		let row = 0;
		let menuLineNumber = 0;
		console.debug('In subNumberEnergitat');
		let quantity = this.contentFuldkostAntalNew - 1;
		if(quantity<0){
			quantity = 0;
		}
		this.updateQuantityFuldkost(quantity);
	}



	addNumberFuldkost(){
		let row = 0;
		let menuLineNumber = 0;
		console.debug('[addNumberFuldkost] In addNumberEnergitat');
		let quantity = this.contentFuldkostAntalNew + 1;

		this.updateQuantityFuldkost(quantity);
	}

	subNumberFilteredFuldkost(selectedKodFuldkost:string, selectedStivelseFuldkost:string){
		let row = 0;
		let menuLineNumber = 0;
		let quantity = 0;
		console.debug('[subNumberFilteredFuldkost] In subNumberEnergitat');

		let value = this.modalKodCategoryMap.get(selectedKodFuldkost);
			console.log('[updateQuantityFuldkost] value', value);
			for(let itemTibehor in value){
				if( value[itemTibehor]['tibehorCategory']==selectedStivelseFuldkost){

					//value[itemTibehor]["tibehorQuantity"] = value[itemTibehor]["tibehorQuantity"] + this.contentFuldkostAntalNew;
					quantity = value[itemTibehor]['tibehorQuantity'] - 1;
					if(quantity<0){
						quantity = 0;
					}
					value[itemTibehor]['tibehorQuantity'] = quantity;
					console.log('[subNumberFilteredFuldkost] value[itemTibehor][tibehorQuantity]', value[itemTibehor]['tibehorQuantity']);


				}


		}
		this.modalKodCategoryMap.set(this.selectedKodFuldkost, value);
    	console.debug('[updateQuantityFuldkost] this.modalKodCategoryMap', this.modalKodCategoryMap);

		/*
		let quantity = this.contentFuldkostAntalNew - 1;
		if(quantity<0){
			quantity = 0;
		}
		*/

		//this.updateQuantityFuldkost(quantity, selectedKodFuldkost, selectedStivelseFuldkostg);
	}



	addNumberFilteredFuldkost(selectedKodFuldkost:string, selectedStivelseFuldkost:string){
		let row = 0;
		let menuLineNumber = 0;
		console.debug('[addNumberFilteredFuldkost] In addNumberEnergitat');
		//let quantity = this.contentFuldkostAntalNew + 1;
		let value = this.modalKodCategoryMap.get(selectedKodFuldkost);
			console.log('[updateQuantityFuldkost] value', value);
			for(let itemTibehor in value){
				if( value[itemTibehor]['tibehorCategory']==selectedStivelseFuldkost){

					//value[itemTibehor]["tibehorQuantity"] = value[itemTibehor]["tibehorQuantity"] + this.contentFuldkostAntalNew;
					value[itemTibehor]['tibehorQuantity'] = value[itemTibehor]['tibehorQuantity'] + 1;
					console.log('[subNumberFilteredFuldkost] value[itemTibehor][tibehorQuantity]', value[itemTibehor]['tibehorQuantity']);


				}


		}
		this.modalKodCategoryMap.set(this.selectedKodFuldkost, value);
    	console.debug('[updateQuantityFuldkost] this.modalKodCategoryMap', this.modalKodCategoryMap);

		//this.updateQuantityFuldkost(quantity, selectedKodFuldkost, selectedStivelseFuldkostg);
	}

	subNumberLineFuldkost(subcat:string, row:number){
		console.debug('In subNumberLineFuldkost');
		switch(subcat){
			case 'Kød':
			let quantityKod = this.subLinesSetKodArr[row]['3'] - 1;
			if(quantityKod<0){
				quantityKod = 0;
			}
			this.updateQuantityLinesFuldkos(quantityKod, subcat,  row)
			break;
			case 'Tilbehør':
			let quantityTilbehor = this.subLinesSetTilbehorArr[row]['3'] - 1;
			if(quantityTilbehor<0){
				quantityTilbehor = 0;
			}
			this.updateQuantityLinesFuldkos(quantityTilbehor, subcat, row)

			break;
			case 'Grønt':
			let quantityGront = this.subLinesSetGrontArr[row]['3'] - 1;
			if(quantityGront<0){
				quantityGront = 0;
			}
			this.updateQuantityLinesFuldkos(quantityGront, subcat, row)

			break;
			case 'Sovs':
			let quantitySovs = this.subLinesSetSovsArr[row]['3'] - 1;
			if(quantitySovs<0){
				quantitySovs = 0;
			}
			this.updateQuantityLinesFuldkos(quantitySovs, subcat, row)

			break;
			default:
			{}

		}




	}

	addNumberLineFuldkost(subcat:string, row:number){
		console.debug('In addNumberLineFuldkost');
		switch(subcat){
			case 'Kød':
			let quantityKod = this.subLinesSetKodArr[row]['3'] + 1;

			this.updateQuantityLinesFuldkos(quantityKod, subcat, row)
			break;
			case 'Tilbehør':
			let quantityTilberhor = this.subLinesSetTilbehorArr[row]['3'] + 1;

			this.updateQuantityLinesFuldkos(quantityTilberhor, subcat, row)

			break;
			case 'Grønt':
			let quantityGront = this.subLinesSetGrontArr[row]['3'] + 1;

			this.updateQuantityLinesFuldkos(quantityGront, subcat, row)

			break;
			case 'Sovs':
			let quantitySovs = this.subLinesSetSovsArr[row]['3'] + 1;

			this.updateQuantityLinesFuldkos(quantitySovs, subcat,  row)

			break;
			default:
			{}

		}
		/*let quantity = this.subLinesSetArr[row]["3"] + 1;

		this.updateQuantityLinesFuldkos(quantity, row)*/

	}

	subNumberLineFuldkostEnkel(row:number){
		console.debug('In subNumberLineFuldkostEnkel');

		let quantity = this.subLinesSetArrEnkel[row]['3'] - 1;
		if(quantity<0){
			quantity = 0;
		}
		this.updateQuantityLinesFuldkosEnkel(quantity, row)

	}

	addNumberLineFuldkostEnkel(row:number){
		console.debug('In addNumberLineFuldkostEnkel');
		let quantity = this.subLinesSetArrEnkel[row]['3'] + 1;

		this.updateQuantityLinesFuldkosEnkel(quantity, row)

	}

	subNumberEnergitat(row:number, menuLineNumber:number){
		console.debug('In subNumberEnergitat');
		let quantity = this.contentEnergitatAntalArr[0][menuLineNumber] - 1;
		if(quantity<0){
			quantity = 0;
		}
		this.updateQuantityEnergitat(quantity, row, menuLineNumber)
	}

	addNumberEnergitat(row:number, menuLineNumber:number){
		console.debug('In addNumberEnergitat');
		let quantity = this.contentEnergitatAntalArr[row][menuLineNumber] + 1;

		this.updateQuantityEnergitat(quantity, row, menuLineNumber)
	}

	subNumberBlod(row:number, menuLineNumber:number){
		console.debug('In subNumberBlod');
		let quantity = this.contentBlodAntalArr[row][menuLineNumber] - 1;
		if(quantity<0){
			quantity = 0;
		}
		this.updateQuantityBlod(quantity,  row, menuLineNumber)
	}

	addNumberBlod(row:number, menuLineNumber:number){
		console.debug('In addNumberBlod');
		let quantity = this.contentBlodAntalArr[row][menuLineNumber] + 1;

		this.updateQuantityBlod(quantity,  row, menuLineNumber)
	}

	subNumberAndet(row:number, indexTypeGroups:number, menuLineNumber:number){
		console.debug('In subNumberAndet');
		let quantity = this.contentAndetAntalArr[row][indexTypeGroups][menuLineNumber] - 1;
		if(quantity<0){
			quantity = 0;
		}
		this.updateQuantityAndet(quantity,  row, indexTypeGroups, menuLineNumber)
	}

	addNumberAndet(row:number, indexTypeGroups:number, menuLineNumber:number){
		console.debug('In addNumberAndet');
		let quantity = this.contentAndetAntalArr[row][indexTypeGroups][menuLineNumber] + 1;

		this.updateQuantityAndet(quantity,  row, indexTypeGroups, menuLineNumber)
	}

	subNumberAndet2(row:number, menuLineNumber:number){
		console.debug('In subNumberAndet');
		let quantity = this.contentAndetAntalArr2[row][menuLineNumber] - 1;
		if(quantity<0){
			quantity = 0;
		}
		this.updateQuantityAndet2(quantity,  row, menuLineNumber)
	}

	addNumberAndet2(row:number, menuLineNumber:number){
		console.debug('In addNumberAndet');
		let quantity = this.contentAndetAntalArr2[row][menuLineNumber] + 1;

		this.updateQuantityAndet2(quantity,  row, menuLineNumber)
	}


	 updateQuantityFuldkost(quantity: number) {
        console.debug('[updateQuantityFuldkost] updateQuantity='+quantity);

		//this.contentFuldkostAntal = quantity;
		this.contentFuldkostAntalNew = Number(quantity);
		/*let value = this.modalKodCategoryMap.get(this.selectedKodFuldkost);
			console.log("[updateQuantityFuldkost] value", value);
			for(let itemTibehor in value){
				if( value[itemTibehor]["tibehorCategory"]==this.selectedStivelseFuldkost){
					//value[itemTibehor]["tibehorQuantity"]=35;
					value[itemTibehor]["tibehorQuantity"] = this.contentFuldkostAntalNew;
					console.log("[updateQuantityFuldkost] value[itemTibehor][tibehorQuantity]", value[itemTibehor]["tibehorQuantity"]);

				}


		}
		this.modalKodCategoryMap.set(this.selectedKodFuldkost, value);*/
    	console.debug('[updateQuantityFuldkost] this.modalKodCategoryMap', this.modalKodCategoryMap);


    }

    updateQuantityFilteredFuldkost(quantity: number,
    	selectedKodFuldkost:string, selectedStivelseFuldkost:string) {
        console.debug('[updateQuantityFuldkost] updateQuantity='+quantity);

		//this.contentFuldkostAntal = quantity;
		//this.contentFuldkostAntalNew = quantity;
		let value = this.modalKodCategoryMap.get(selectedKodFuldkost);
			console.log('[updateQuantityFuldkost] value', value);
			for(let itemTibehor in value){
				if( value[itemTibehor]['tibehorCategory']==selectedStivelseFuldkost){
					//value[itemTibehor]["tibehorQuantity"]=35;
					value[itemTibehor]['tibehorQuantity'] = Number(quantity);
					console.log('[updateQuantityFuldkost] value[itemTibehor][tibehorQuantity]', value[itemTibehor]['tibehorQuantity']);


				}


		}
		this.modalKodCategoryMap.set(this.selectedKodFuldkost, value);
    	console.debug('[updateQuantityFuldkost] this.modalKodCategoryMap', this.modalKodCategoryMap);


    }

    updateQuantityLinesFuldkos(quantity: number, subcat:string, row:number){
    	//console.debug("row", row);
    	//console.debug("updateQuantity="+quantity);
    	switch(subcat){
			case 'Kød':
			this.subLinesSetKodArr[row]['3'] = Number(quantity);
			console.debug('this.subLinesSetKodArr', this.subLinesSetKodArr);
			break;
			case 'Tilbehør':
			this.subLinesSetTilbehorArr[row]['3'] = Number(quantity);
			console.debug('this.subLinesSetTilbehorArr', this.subLinesSetTilbehorArr);

			break;
			case 'Grønt':
			this.subLinesSetGrontArr[row]['3'] = Number(quantity);
			console.debug('this.subLinesSetGrontArr', this.subLinesSetGrontArr);

			break;
			case 'Sovs':
			this.subLinesSetSovsArr[row]['3'] = Number(quantity);
			console.debug('this.subLinesSetSovsArr', this.subLinesSetSovsArr);

			break;
			default:
			{}

		}
    	/*this.subLinesSetArr[row]["3"] = quantity;
    	console.debug("this.subLinesSetArr", this.subLinesSetArr);*/
    }

     updateQuantityLinesFuldkosEnkel(quantity: number, row:number){
    	//console.debug("row", row);
    	//console.debug("updateQuantity="+quantity);
    	this.subLinesSetArrEnkel[row]['3'] = Number(quantity);
    	console.debug('this.subLinesSetArrEnkel', this.subLinesSetArrEnkel);
    }

    updateQuantityEnergitat(quantity: number, row:number, menuLineNumber:number){
    	console.debug('menuLineNumber', menuLineNumber);
    	console.debug('updateQuantity='+quantity);
    	this.contentEnergitatAntalArr[row][menuLineNumber] = Number(quantity);
    	console.debug('this.contentEnergitatAntalArr', this.contentEnergitatAntalArr);
    }

     updateQuantityBlod(quantity: number, row:number, menuLineNumber:number){
    	console.debug('menuLineNumberBlod', menuLineNumber);
    	console.debug('updateQuantityBlod='+quantity);
    	this.contentBlodAntalArr[row][menuLineNumber] = Number(quantity);
    	console.debug('this.contentBlodAntalArr', this.contentBlodAntalArr);
    }
    updateQuantityAndet(quantity: number, row:number, indexTypeGroups:number, menuLineNumber:number){
    	//console.debug("menuLineNumberAndet", menuLineNumber);
    	//console.debug("updateQuantityAndet="+quantity);
    	this.contentAndetAntalArr[row][indexTypeGroups][menuLineNumber] = Number(quantity);
    	console.debug('this.contentAndetAntalArr', JSON.stringify(this.contentAndetAntalArr));
    }

    updateQuantityAndet2(quantity: number, row:number, menuLineNumber:number){
    	console.debug('menuLineNumberAndet', menuLineNumber);
    	console.debug('updateQuantityAndet='+quantity);
    	this.contentAndetAntalArr2[row][menuLineNumber] = Number(quantity);
    	console.debug('this.contentAndetAntalArr', JSON.stringify(this.contentAndetAntalArr2));
    }

   /* updateMenuLineBtKodTilbehor(itemMainDishes:any, itemMeat:string, itemStarch:string, productNr:number, quantity:number){


    	console.debug("[updateMenuLineBtKodTilbehor] itemMainDishes", itemMainDishes);
    	console.debug("[updateMenuLineBtKodTilbehor] itemMeat", itemMeat);
    	console.debug("[updateMenuLineBtKodTilbehor] itemStarch", itemStarch);
    	console.debug("[updateMenuLineBtKodTilbehor] productNr", productNr);
    	console.debug("[updateMenuLineBtKodTilbehor] quantity", quantity);

    	//itemMainDishes['dietTypeGroups'][0]['dietTypes'][0]['menuLine']['quantity']
    	//itemMainDishes['dietTypeGroups'][0]['dietTypes'][0]['menuLine']['productNr']
    	let itemMenuLine;
    	for (let itemDietTypes in itemMainDishes['dietTypeGroups'][0]['dietTypes']){
    	//	console.debug("[getMenuLineBtKodTilbehor] itemDietTypes", itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['dietType']);

    		if(itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes]
    			&& (itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['menuLine']['productNr']==productNr)
    			&& (itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['dietType']['meat']==itemMeat)
    			&& (itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['dietType']['starch']==itemStarch)){
    			itemMenuLine = [];
    			itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['menuLine']['quantity'] = quantity;

				console.debug("[updateMenuLineBtKodTilbehor] itemMainDishes", itemMainDishes);
    			itemMenuLine = JSON.parse(JSON.stringify(itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['menuLine']));
    			console.debug("[updateMenuLineBtKodTilbehor] itemMenuLine", itemMenuLine);
    			return itemMenuLine;
    		}

    	}
    	//console.debug("[getMenuLineBtKodTilbehor] itemMenuLine", itemMenuLine);
    	//return itemMenuLine;

    }*/
    updateMenuLineBtKodTilbehor(itemMainDishes:any, itemMeat:string, itemStarch:string, productNr:number, quantity:number, day:string){


    	console.debug('[updateMenuLineBtKodTilbehor] itemMainDishes', itemMainDishes);
    	console.debug('[updateMenuLineBtKodTilbehor] itemMeat', itemMeat);
    	console.debug('[updateMenuLineBtKodTilbehor] itemStarch', itemStarch);
    	console.debug('[updateMenuLineBtKodTilbehor] productNr', productNr);
    	console.debug('[updateMenuLineBtKodTilbehor] quantity', quantity);

    	let itemMenuLine;
	for(let itemMainDishes in this.dishAllocationsOrderDays[day]['mainDishes']){


		for (let itemDietTypes in this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][0]['dietTypes']){
    	//	console.debug("[getMenuLineBtKodTilbehor] itemDietTypes", this.dishAllocationsOrderDays[day]["mainDishes"][itemMainDishes]['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['dietType']);

    		if(this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][0]['dietTypes'][itemDietTypes]
    			&& (this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['menuLine']['productNr']==productNr)
    			&& (this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['dietType']['meat']==itemMeat)
    			&& (this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['dietType']['starch']==itemStarch)){
    			itemMenuLine = [];
    			this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['menuLine']['quantity'] = Number(quantity);

				console.debug('[updateMenuLineBtKodTilbehor] this.dishAllocationsOrderDays[mainDishes][itemMainDishes]', this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['menuLine']);
    			itemMenuLine = JSON.parse(JSON.stringify(this.dishAllocationsOrderDays[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['menuLine']));
    			console.debug('[updateMenuLineBtKodTilbehor] itemMenuLine', itemMenuLine);
    			return itemMenuLine;
    		}

    	}
	}



    	//console.debug("[getMenuLineBtKodTilbehor] itemMenuLine", itemMenuLine);
    	//return itemMenuLine;

    }

    findBtKodTilbehorFromMenuLine(itemMainDishes:any, itemMeat:string, itemStarch:string, productNr:number, quantity:number){


    	console.debug('[updateBtKodTilbehorFromMenuLine] itemMainDishes', itemMainDishes);
    	console.debug('[updateBtKodTilbehorFromMenuLine] itemMeat', itemMeat);
    	console.debug('[updateBtKodTilbehorFromMenuLine] itemStarch', itemStarch);
    	console.debug('[updateBtKodTilbehorFromMenuLine] productNr', productNr);
    	console.debug('[updateBtKodTilbehorFromMenuLine] quantity', quantity);

    	//itemMainDishes['dietTypeGroups'][0]['dietTypes'][0]['menuLine']['quantity']
    	//itemMainDishes['dietTypeGroups'][0]['dietTypes'][0]['menuLine']['productNr']
    	let itemMenuLine;
    	for (let itemDietTypes in itemMainDishes['dietTypeGroups'][0]['dietTypes']){
    	//	console.debug("[getMenuLineBtKodTilbehor] itemDietTypes", itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['dietType']);

    		if(itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes]
    			&& (itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['menuLine']['productNr']==productNr)
    			&& (itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['dietType']['meat']==itemMeat)
    			&& (itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['dietType']['starch']==itemStarch)){
    			itemMenuLine = [];
    			//itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['menuLine']['quantity'] = quantity;
    			itemMenuLine = itemMainDishes['dietTypeGroups'][0]['dietTypes'][itemDietTypes]['menuLine'];
    			console.debug('[getMenuLineBtKodTilbehor] itemMenuLine', itemMenuLine);
    			return itemMenuLine;
    		}

    	}
    	//console.debug("[getMenuLineBtKodTilbehor] itemMenuLine", itemMenuLine);
    	//return itemMenuLine;

    }


    clearFuldkost(){
    	this.contentFuldkostAntal = this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][0]['menuLine']['quantity'];

   		this.selectedKodFuldkost = ''
   		this.modalService.dismissAll();
    }

    saveFuldkost(day:string, itemMainDishes:any){

    	console.debug('In saveFuldkost');
    	this.currentDayForModal = day;
    	console.log('this.currentDayForModal', this.currentDayForModal);


//-------------------put to LocalStorage Bulk Antal quantity---------------------

console.log(' this.contentFuldkostAntal',  this.contentFuldkostAntal);


this.modalKodCategoryMap.forEach(function(value, key,ownerSet){
			console.log('[saveFuldkost] key', key);
			console.log('[saveFuldkost] value', value);
			for(let itemTibehor in value){
			let itemSelected = this.findBtKodTilbehorFromMenuLine(itemMainDishes,
			key,
			value[itemTibehor]['tibehorCategory'],
			itemMainDishes['dietTypeGroups'][0]['dietTypes'][0]['menuLine']['productNr'],
			0);


			itemSelected['quantity']  = value[itemTibehor]['tibehorQuantity'];
			console.debug('[saveFuldkost] itemSelected', itemSelected);
			this.updateSimpleOderModelArray('Editweekplan', itemSelected, day);
			//value[itemTibehor]["tibehorQuantity"]=100;

			}

		}, this);

  this.contentFuldkostAntalNew = 0;


//-------------------put to LocalStorage Kod quantity-----------------
    	if(this.subLinesSetKodArr.length!=0){
    		for(let row=0; row < this.subLinesSetKodArr.length; row++){

			this.updateSimpleOderModelArray('Editweekplan',
				this.updateMenuLineByProductNr(itemMainDishes,

				this.fuldkostCategoryArr, this.subLinesSetKodArr[row]),

				day, true);
    		}
    	}

//---------------- put to LocalStorage Tilbehor quantity-----------------
		if(this.subLinesSetTilbehorArr.length!=0){
			console.debug('[saveFuldkost] this.subLinesSetTilbehorArr', this.subLinesSetTilbehorArr);
		for(let row=0; row < this.subLinesSetTilbehorArr.length; row++){

			this.updateSimpleOderModelArray('Editweekplan',
				this.updateMenuLineByProductNr(itemMainDishes,
				//this.fuldkostCategoryArr, this.subLinesSetTilbehorArr[row]["4"],
				this.fuldkostCategoryArr, this.subLinesSetTilbehorArr[row]),
				//this.subLinesSetTilbehorArr[row]["3"]),
				day, true);
    		}
		}

//---------------- put to LocalStorage Gront quantity-----------------
		if(this.subLinesSetGrontArr.length!=0){
			for(let row=0; row < this.subLinesSetGrontArr.length; row++){

			this.updateSimpleOderModelArray('Editweekplan',
				this.updateMenuLineByProductNr(itemMainDishes,
				//this.fuldkostCategoryArr, this.subLinesSetGrontArr[row]["4"],
				this.fuldkostCategoryArr, this.subLinesSetGrontArr[row]),
				//this.subLinesSetGrontArr[row]["3"]),
				day, true);
    		}
		}

//---------------- put to LocalStorage Sovs quantity-----------------
		if(this.subLinesSetSovsArr.length!=0){

			for(let row=0; row < this.subLinesSetSovsArr.length; row++){

			this.updateSimpleOderModelArray('Editweekplan',
				this.updateMenuLineByProductNr(itemMainDishes,
				//this.fuldkostCategoryArr, this.subLinesSetSovsArr[row]["4"],
				this.fuldkostCategoryArr, this.subLinesSetSovsArr[row]),
				//this.subLinesSetSovsArr[row]["3"]),
				day, true);
    		}

		}


//-------------------put to LocalStorage Enkel quantity-----------------
    	if(this.subLinesSetArrEnkel.length!=0){

    		for(let row=0; row < this.subLinesSetArrEnkel.length; row++){

			this.updateSimpleOderModelArray('Editweekplan',
				this.updateMenuLineByProductNr(itemMainDishes,
				//this.fuldkostCategoryArr, this.subLinesSetArrEnkel[row]["4"],
				this.fuldkostCategoryArr, this.subLinesSetArrEnkel[row]),
				//this.subLinesSetArrEnkel[row]["3"]),
				day, true);
    		}
    	}



    	console.debug('this.dishAllocationsOrderDaysQuantity\']', this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][0]['menuLine']['quantity']);
    	console.debug('this.contentFuldkostAntal', this.contentFuldkostAntal);
    	//this.isQuantitiesChanged = this.checkQuantitiesChanged(this.simpleOderModelArray);
    	this.dataShareService.canToogleFromPopUp =!(this.checkQuantitiesChanged(this.simpleOderModelArray));
    	this.modalService.dismissAll();

    }



    clearEnergitat(){

   		this.modalService.dismissAll();
   		this.contentEnergitatAntalArr = [];
    }

     saveEnergitat(day:string, itemMainDishes:any){
     	console.debug('[saveEnergitat]');
     	this.currentDayForModal = day;


		console.debug('[saveEnergitat] this.contentEnergitatAntalArr', this.contentEnergitatAntalArr);
    	for(let itemMainDishes in this.contentEnergitatAntalArr){

console.debug('[saveEnergitat] itemMainDishes', this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]);
    		for(let menuLines in this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][1]['dietTypes']){

			this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][1]['dietTypes'][menuLines]['menuLine']['quantity'] = this.contentEnergitatAntalArr[itemMainDishes][menuLines];
			this.updateSimpleOderModelArray('Editweekplan', this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][1]['dietTypes'][menuLines]['menuLine'], day, true);
			console.debug('this.dishAllocationsOrderDaysSave', this.dishAllocationsOrderDays);

		}


	/*	for(let itemMainDishes in this.dishEnergitatAllDayFilteredDietTypeGroups[day]['mainDishes']){
    		for(let indexDietTypeGroups in this.dishEnergitatAllDayFilteredDietTypeGroups[day]['mainDishes'][itemMainDishes]['dietTypeGroups']){
    			for(let menuLines in this.dishEnergitatAllDayFilteredDietTypeGroups[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][indexDietTypeGroups]['dietTypes']){

    		for(let itemDietTypeGroups in  this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups']){
    			for(let itemDietType in this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][itemDietTypeGroups]['dietTypes']){

    				if(this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][itemDietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeNr']
    					==this.dishEnergitatAllDayFilteredDietTypeGroups[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][indexDietTypeGroups]['dietTypes'][menuLines]['menuLine']['dietTypeNr']){

this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][itemDietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity'] = this.contentEnergitatAntalArr[itemMainDishes][menuLines];
						this.dishEnergitatAllDayFilteredDietTypeGroups[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][indexDietTypeGroups]['dietTypes'][menuLines]['menuLine']['quantity'] = this.contentEnergitatAntalArr[itemMainDishes][menuLines];
						this.updateSimpleOderModelArray("Editweekplan", this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][itemDietTypeGroups]['dietTypes'][itemDietType]['menuLine'], day, true);
    				}
    			}
    		}


				}
    		}

			console.debug("this.dishEnergitatAllDayFilteredDietTypeGroups", this.dishEnergitatAllDayFilteredDietTypeGroups);
    		console.debug("this.dishAllocationsOrderDaysSave", this.dishAllocationsOrderDays);

	}*/
		this.dataShareService.canToogleFromPopUp =!(this.checkQuantitiesChanged(this.simpleOderModelArray));
    	this.modalService.dismissAll();
   	}


    }

    clearBlod(){

   		this.modalService.dismissAll();
   		this.contentBlodAntalArr = [];
    }

     saveBlod(day:string){
     	console.debug('saveBlod');
     	this.currentDayForModal = day;
    	//this.dishAllocationsOrderDays['Tuesday']['mainDishes'][0]['menuLine']['quantity'] = this.contentEnergitatAntal;
    	for(let itemMainDishes in this.contentBlodAntalArr){
    		for(let menuLines in this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][2]['dietTypes']){
			this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][2]['dietTypes'][menuLines]['menuLine']['quantity'] = this.contentBlodAntalArr[itemMainDishes][menuLines];
			this.updateSimpleOderModelArray('Editweekplan', this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][2]['dietTypes'][menuLines]['menuLine'], day, true);
			console.debug('this.dishAllocationsOrderDaysSave', this.dishAllocationsOrderDays);

		}


    }
    this.dataShareService.canToogleFromPopUp =!(this.checkQuantitiesChanged(this.simpleOderModelArray));
    this.modalService.dismissAll();
}

    clearAndet(){

   		this.modalService.dismissAll();
   		this.contentAndetAntalArr = [];
    }

     saveAndet(day:string){
     	console.debug('saveAndet');
     	this.currentDayForModal = day;

    	//for(let itemMainDishes in this.contentAndetAntalArr){
    	for(let itemMainDishes in this.dishAllDayFilteredDietTypeGroups[day]['mainDishes']){
    		for(let indexDietTypeGroups in this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][itemMainDishes]['dietTypeGroups']){
    			for(let menuLines in this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][itemMainDishes]['dietTypeGroups'][indexDietTypeGroups]['dietTypes']){

    		for(let itemDietTypeGroups in  this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups']){
    			for(let itemDietType in this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][itemDietTypeGroups]['dietTypes']){

    				if(this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][itemDietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeNr']
    					==this.dishAllDayFilteredDietTypeGroups[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][indexDietTypeGroups]['dietTypes'][menuLines]['menuLine']['dietTypeNr']){

//console.debug("dietTypeNr1", this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][itemDietTypeGroups]['dietTypes'][itemDietType]['menuLine']['dietTypeNr']);

//console.debug("dietTypeNr2", this.dishAllDayFilteredDietTypeGroups[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][indexDietTypeGroups]['dietTypes'][menuLines]['menuLine']['dietTypeNr']);
    					this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][itemDietTypeGroups]['dietTypes'][itemDietType]['menuLine']['quantity'] = this.contentAndetAntalArr[itemMainDishes][indexDietTypeGroups][menuLines];
						this.dishAllDayFilteredDietTypeGroups[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][indexDietTypeGroups]['dietTypes'][menuLines]['menuLine']['quantity'] = this.contentAndetAntalArr[itemMainDishes][indexDietTypeGroups][menuLines];
						this.updateSimpleOderModelArray('Editweekplan', this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][itemDietTypeGroups]['dietTypes'][itemDietType]['menuLine'], day, true);
    				}
    			}
    		}


				}
    		}

			console.debug('this.dishAllDayFilteredDietTypeGroups', this.dishAllDayFilteredDietTypeGroups);
    		console.debug('this.dishAllocationsOrderDaysSave', this.dishAllocationsOrderDays);

	}

	/*	for(let itemMainDishes in this.contentBlodAntalArr){
    		for(let menuLines in this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][4]['dietTypes']){
			this.dishAllocationsOrderDays[this.currentDayForModal]['mainDishes'][itemMainDishes]['dietTypeGroups'][4]['dietTypes'][menuLines]['menuLine']['quantity'] = this.contentAndetAntalArr2[itemMainDishes][menuLines];
			console.debug("this.dishAllocationsOrderDaysSave", this.dishAllocationsOrderDays);

		}
	}*/ this.dataShareService.canToogleFromPopUp =!(this.checkQuantitiesChanged(this.simpleOderModelArray));
    	this.modalService.dismissAll();
    	//this.selectedKodFuldkost = this.formKodFuldkost.value.Kod;
		//console.debug("this.selectedKodFuldkost", this.formKodFuldkost);
    }


//-------------Modal Fildkost---------------------------------------------------
	/*selectKod(formKodFuldkost:ngForm){
		this.selectedKodFuldkost = this.formKodFuldkost.value.kod;
		console.debug("this.selectedKodFuldkost", this.selectedKodFuldkost);
	}    */
	selectKodFuldkost(kod: any){
		this.selectedKodFuldkost = kod;
		console.debug('this.selectedKodFuldkost', kod);


		this.currentTilbehorArr = Array.from(this.modalKodCategoryMap.get(kod));
		console.debug('this.currentTilbehorArr2', this.currentTilbehorArr);
		//console.debug("this.currentTilbehorArr", this.currentTilbehorArr);
		this.defaultTilbehor = this.currentTilbehorArr[0]['tibehorCategory'];

		console.log('[selectKodFuldkost] this.defaultTilbehor', this.defaultTilbehor);
		console.log('[selectKodFuldkost] this.currentTilbehorArr', this.currentTilbehorArr);



			let value = this.modalKodCategoryMap.get(this.selectedKodFuldkost);
			console.log('[selectKodFuldkost] value', value);
			for(let itemTibehor in value){
				if( value[itemTibehor]['tibehorCategory']==this.selectedStivelseFuldkost){
					//value[itemTibehor]["tibehorQuantity"]=25;
					this.contentFuldkostAntalNew = value[itemTibehor]['tibehorQuantity'];


				}


			}




		console.debug('[selectKodFuldkost] this.modalKodCategoryMap', this.modalKodCategoryMap);



	}

	selectStivelseFuldkost(stivelse: any){
		this.selectedStivelseFuldkost = stivelse;
		console.debug('[selectStivelseFuldkost] selectedStivelseFuldkost', stivelse);

			let value = this.modalKodCategoryMap.get(this.selectedKodFuldkost);
			console.log('[selectStivelseFuldkost] value', value);
			for(let itemTibehor in value){
				if( value[itemTibehor]['tibehorCategory']==this.selectedStivelseFuldkost){
					//value[itemTibehor]["tibehorQuantity"]=35;
					//this.contentFuldkostAntal = value[itemTibehor]["tibehorQuantity"];
					this.contentFuldkostAntalNew = value[itemTibehor]['tibehorQuantity'];

				}


			}




		console.debug('[selectStivelseFuldkost] this.modalKodCategoryMap', this.modalKodCategoryMap);


		/*let itemSelected = this.findBtKodTilbehorFromMenuLine(this.currentItemMenuLines,
			this.selectedKodFuldkost,
			this.selectedStivelseFuldkost,
			this.currentItemMenuLines['dietTypeGroups'][0]['dietTypes'][0]['menuLine']['productNr'],
			0);

	console.log("[selectStivelseFuldkost] itemSelected", itemSelected);
		this.contentFuldkostAntal =  Number(itemSelected['quantity']);
		*/
	}

	/*selectSovsFuldkost(sovs: any){
		this.selectedKodFuldkost = sovs;
		console.debug("this.selectedKodFuldkost", sovs);
	}
*/
	checkTibehorFuldkost(event:any){
		console.debug('event', event.checked);

	}

	sumAndet(energitatQuontityAndet1:number,
		energitatQuontityAndet2:number):number{

		return  (Number(energitatQuontityAndet1) + Number(energitatQuontityAndet2));
	}

	/*sumRow(fuldkostQuontity:number, energitatQuontity:number,
		energitatBlod:number, energitatQuontityAndet1:number,
		energitatQuontityAndet2:number):number{
		console.debug("fuldkostQuontity", fuldkostQuontity);
		console.debug("energitatQuontity", energitatQuontity);
		console.debug("energitatBlod", energitatBlod);
		console.debug("energitatQuontityAndet1", energitatQuontityAndet1);
		console.debug("energitatQuontityAndet2", energitatQuontityAndet2);
		console.debug("sumRow", (Number(fuldkostQuontity) + Number(energitatQuontity) + Number(energitatBlod) + Number(energitatQuontityAndet1) + Number(energitatQuontityAndet2)));
		return  (Number(fuldkostQuontity) + Number(energitatQuontity) + Number(energitatBlod) + Number(energitatQuontityAndet1) + Number(energitatQuontityAndet2));


	}*/

	sumTd(itemDietTypes:any, day:string, j:number, cat:number):number{

		console.debug('itemDietTypes', itemDietTypes);

		var sumItemDietTypes = 0;
		for (var itemDietType in itemDietTypes){
			console.debug('itemDietTypes[itemDietType][\'menuLine\'][\'quantity\']', itemDietTypes[itemDietType]['menuLine']['quantity']);
			sumItemDietTypes += Number(itemDietTypes[itemDietType]['menuLine']['quantity']);


		}

		console.debug('sumItemDietTypes', sumItemDietTypes);
		this.sumMatrixForDays[day][j][cat] = Number(sumItemDietTypes);
		console.debug('this.sumMatrixForDays', this.sumMatrixForDays);



		return  Number(sumItemDietTypes);


	}
	sumTdFuldkost(itemDietTypes:any, day:string, j:number, cat:number):number{

		console.debug('itemDietTypes', itemDietTypes);
		let starchRange = [[122,129], [272,279], [422,429],[572,579], [722,729],
		 [132,140], [282,290], [432,440], [582,590], [732-740],
		 [144,147], [294,297], [447], [594,597], [744,747],
		 [141], [291], [441], [591], [741]];
		let sumItemDietTypes = 0;
		for (let itemDietType in itemDietTypes){
			console.debug('itemDietTypes[itemDietType][\'menuLine\'][\'quantity\']', itemDietTypes[itemDietType]['menuLine']['quantity']);
			if(!((itemDietTypes[itemDietType]['menuLine']['line']>=starchRange[0][0]
				 				&& itemDietTypes[itemDietType]['menuLine']['line']<=starchRange[0][1])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']>=starchRange[1][0]
				 				&& itemDietTypes[itemDietType]['menuLine']['line']<=starchRange[1][1])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']>=starchRange[2][0]
				 				&& itemDietTypes[itemDietType]['menuLine']['line']<=starchRange[2][1])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']>=starchRange[3][0]
				 				&& itemDietTypes[itemDietType]['menuLine']['line']<=starchRange[3][1])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']>=starchRange[4][0]
				 				&& itemDietTypes[itemDietType]['menuLine']['line']<=starchRange[4][1])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']>=starchRange[5][0]
				 				&& itemDietTypes[itemDietType]['menuLine']['line']<=starchRange[5][1])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']>=starchRange[6][0]
				 				&& itemDietTypes[itemDietType]['menuLine']['line']<=starchRange[6][1])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']>=starchRange[7][0]
				 				&& itemDietTypes[itemDietType]['menuLine']['line']<=starchRange[7][1])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']>=starchRange[8][0]
				 				&& itemDietTypes[itemDietType]['menuLine']['line']<=starchRange[8][1])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']>=starchRange[9][0]
				 				&& itemDietTypes[itemDietType]['menuLine']['line']<=starchRange[9][1])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']>=starchRange[10][0]
				 				&& itemDietTypes[itemDietType]['menuLine']['line']<=starchRange[10][1])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']>=starchRange[11][0]
				 				&& itemDietTypes[itemDietType]['menuLine']['line']<=starchRange[11][1])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']==starchRange[12])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']>=starchRange[13][0]
				 				&& itemDietTypes[itemDietType]['menuLine']['line']<=starchRange[13][1])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']>=starchRange[14][0]
				 				&& itemDietTypes[itemDietType]['menuLine']['line']<=starchRange[14][1])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']==starchRange[15])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']==starchRange[16])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']==starchRange[17])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']==starchRange[18])
				 			|| (itemDietTypes[itemDietType]['menuLine']['line']==starchRange[19])


				 			)){
				sumItemDietTypes += Number(itemDietTypes[itemDietType]['menuLine']['quantity']);
			}



		}

		//sumItemDietTypes = itemDietTypes[0]['menuLine']['quantity']

		console.debug('sumItemDietTypes', sumItemDietTypes);
		this.sumMatrixForDays[day][j][cat] = Number(sumItemDietTypes);
		console.debug('this.sumMatrixForDays', this.sumMatrixForDays);



		return  Number(sumItemDietTypes);


	}

	getSumFuldkostAll(){


	}

	sumTdAndet(itemDietTypes:any, day:string, j:number, cat:number):number{
		console.debug('[sumTdAndet] itemDietTypes', itemDietTypes);
		var sumItemDietTypes = 0;
		for (let itemDietType in itemDietTypes){
			console.debug('itemDietTypes[itemDietType][\'menuLine\'][\'quantity\']', itemDietTypes[itemDietType]['menuLine']['quantity']);
			//sumItemDietTypes += Number(itemDietTypes[itemDietType]['menuLine']['quantity']);
			//sumItemDietTypes = Number(itemDietTypes[itemDietType]['menuLine']['quantity']);
			sumItemDietTypes = Number(itemDietTypes[0]['menuLine']['quantity']);


		}
		console.debug('[sumTdAndet] sumItemDietTypes', sumItemDietTypes);

		let sumAllfilteredItemDietTypes = 0;
		for(let itemDietTypeGroups in  this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][j]['dietTypeGroups']){
			for(let itemDietTypes in this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][j]['dietTypeGroups'][itemDietTypeGroups]['dietTypes']){
				sumAllfilteredItemDietTypes += this.dishAllDayFilteredDietTypeGroups[day]['mainDishes'][j]['dietTypeGroups'][itemDietTypeGroups]['dietTypes'][itemDietTypes]['menuLine']['quantity'];
			}
		}

		this.sumMatrixForDays[day][j][cat] = Number(sumAllfilteredItemDietTypes);

		console.debug('[sumTdAndet] this.sumMatrixForDays', this.sumMatrixForDays);



		return  Number(sumItemDietTypes);
	}

	sumRow(itemDdietTypeGroups:any):number{

		console.debug('itemDdietTypeGroups', itemDdietTypeGroups);


		let fuldkostQuontity = itemDdietTypeGroups[0]['dietTypes'][0]['menuLine']['quantity'];
		console.debug('fuldkostQuontity', fuldkostQuontity);
		//let energitatQuontity = itemMainDishes['mainDishes'][j]['dietTypeGroups'][1]['dietTypes'][0]['menuLine']['quantity'];
		var sumEnergitatQuontity  = 0;
		for (var itemDietType in itemDdietTypeGroups[1]['dietTypes']){
			console.debug('itemDdietTypeGroups[itemDietType][\'menuLine\'][\'quantity\']', itemDdietTypeGroups[1]['dietTypes'][itemDietType]['menuLine']['quantity']);
			sumEnergitatQuontity += Number(itemDdietTypeGroups[1]['dietTypes'][itemDietType]['menuLine']['quantity']);


		}
		console.debug('sumEnergitatQuontity', sumEnergitatQuontity);

		//let sumBlodQuontity = itemMainDishes['mainDishes'][j]['dietTypeGroups'][2]['dietTypes'][0]['menuLine']['quantity'];
		var sumBlodQuontity  = 0;
		for (var itemDietType in itemDdietTypeGroups[2]['dietTypes']){
			console.debug('itemDdietTypeGroups[itemDietType][\'menuLine\'][\'quantity\']', itemDdietTypeGroups[2]['dietTypes'][itemDietType]['menuLine']['quantity']);
			sumBlodQuontity += Number(itemDdietTypeGroups[2]['dietTypes'][itemDietType]['menuLine']['quantity']);


		}
		console.debug('sumBlodQuontity', sumBlodQuontity);

		//let sumAndet1Quontity = itemMainDishes['mainDishes'][j]['dietTypeGroups'][3]['dietTypes'][0]['menuLine']['quantity'];
		var sumAndet1Quontity  = 0;
		if((itemDdietTypeGroups[3]['dietTypes']!==undefined) && (itemDdietTypeGroups[3]['dietTypes'][itemDietType]!==undefined) && (itemDdietTypeGroups[3]['dietTypes'][itemDietType]['menuLine']!==undefined)){
				for (var itemDietType in itemDdietTypeGroups[3]['dietTypes']){
			console.debug('itemDdietTypeGroups[itemDietType][\'menuLine\'][\'quantity\']', itemDdietTypeGroups[3]['dietTypes'][itemDietType]['menuLine']['quantity']);
			sumAndet1Quontity += Number(itemDdietTypeGroups[3]['dietTypes'][itemDietType]['menuLine']['quantity']);


		}
			}

		console.debug('sumAndet1Quontity', sumAndet1Quontity);
		//let sumAndet2Quontity = itemMainDishes['mainDishes'][j]['dietTypeGroups'][4]['dietTypes'][0]['menuLine']['quantity'];
		var sumAndet2Quontity  = 0;
		if((itemDdietTypeGroups[4]['dietTypes']!==undefined) && (itemDdietTypeGroups[4]['dietTypes'][itemDietType]!==undefined) && (itemDdietTypeGroups[4]['dietTypes'][itemDietType]['menuLine']!==undefined)){

		for (var itemDietType in itemDdietTypeGroups[4]['dietTypes']){
			console.debug('itemDdietTypeGroups[itemDietType][\'menuLine\'][\'quantity\']', itemDdietTypeGroups[4]['dietTypes'][itemDietType]['menuLine']['quantity']);
			sumAndet2Quontity += Number(itemDdietTypeGroups[4]['dietTypes'][itemDietType]['menuLine']['quantity']);


		}
		}
		console.debug('sumAndet2Quontity', sumAndet2Quontity);

		return  (Number(fuldkostQuontity)
				+ Number(sumEnergitatQuontity)
				+ Number(sumBlodQuontity)
				+ Number(sumAndet1Quontity)
				+ Number(sumAndet2Quontity));


	}

	/*sumIalt(fuldkostQuontity:number, energitatQuontity:number,
		energitatBlod:number, energitatQuontityAndet1:number,
		energitatQuontityAndet2:number):number{

		return  (Number(fuldkostQuontity) + Number(energitatQuontity) + Number(energitatBlod) + Number(energitatQuontityAndet1) + Number(energitatQuontityAndet2));

	}*/

	sumRowMatrix(day:string, j:number){
		//console.debug("sumRowMatrixj", j);
		//console.debug("sumRowMatrixday", day);
		if(this.sumMatrixForDays!==undefined){
			let sumRowIalt = 0;
		for (let col = 0; col < 5; col++){
			//console.debug("this.sumMatrixForDays[day][j][col]", this.sumMatrixForDays[day][j][col]);
			sumRowIalt += this.sumMatrixForDays[day][j][col];



			}

		return Number(sumRowIalt);
		}else{
			return 0;
		}

	}

	sumIaltFuldcost(day:string){
		let sumColIalt = 0;
		for (let i = 0; i < this.sumMatrixForDays[day].length; i++){

			sumColIalt += this.sumMatrixForDays[day][i][0];

			}
		return Number(sumColIalt);

	}
	sumIaltEnergitat(day:string){
		let sumColIalt = 0;
		for (let i = 0; i < this.sumMatrixForDays[day].length; i++){

			sumColIalt += this.sumMatrixForDays[day][i][1];

			}
		return Number(sumColIalt);


	}
	sumIaltBlod(day:string){
		let sumColIalt = 0;
		for (let i = 0; i < this.sumMatrixForDays[day].length; i++){

			sumColIalt += this.sumMatrixForDays[day][i][2];

			}
		return Number(sumColIalt);

	}
	sumIaltAndet(day:string){
		let sumColIalt = 0;
		for (let i = 0; i < this.sumMatrixForDays[day].length; i++){

			sumColIalt += this.sumMatrixForDays[day][i][3] + this.sumMatrixForDays[day][i][4];

			}
		return Number(sumColIalt);

	}

	toogleModalBulk(){
		console.log('[toogleModalBulk] this.toogleModalBulk');
		this.toogledModalBulk = true;
		this.toogledModalEnkel = false;
		this.modalBtnBackgroundColor = '#FFFFFF';
		this.modalBtnTextColor = '#000000';
		this.modalBtnTextWeight = 'bold'

	}

	toogleModalEnkel(){
		console.log('[toogleModalEnkel] In this.toogleModalEnkel');
		this.toogledModalBulk = false;
		this.toogledModalEnkel = true;
		this.modalBtnBackgroundColor = '#5B7B12';
		this.modalBtnTextColor = '#FFFFFF';
		this.modalBtnTextWeight = 'normal'

	}

	formMenuLineModelArr(){
    //    console.log("this.simpleOderModelArray.length = :" +this.simpleOderModelArray.length );

     let orderDishModelArr = new Array();

   //   console.log("OrderDishModelArr = :" +JSON.stringify(orderDishModelArr) );
      for(var i = 0; i<this.simpleOderModelArray.length; i++){
         let orderMenuLineDay = new OrderMenuLineDay(new MenuLine(
          this.simpleOderModelArray[i]['dishId'],
          this.simpleOderModelArray[i]['name'],
          this.simpleOderModelArray[i]['line'],
          this.simpleOderModelArray[i]['dietTypeNr'],
          this.simpleOderModelArray[i]['dietTypeName'],
          this.simpleOderModelArray[i]['portionSize'],
          this.simpleOderModelArray[i]['sumOder'],
          this.simpleOderModelArray[i]['enkeltPortion']),
          this.simpleOderModelArray[i]['Day'],
          this.simpleOderModelArray[i]['orderNr'],
          this.simpleOderModelArray[i]['customerNr'],
          this.simpleOderModelArray[i]['menuNr'],
          this.simpleOderModelArray[i]['orderNotPermit']
      );

        console.log('orderMenuLineDay', orderMenuLineDay);
         orderDishModelArr.push(orderMenuLineDay);

      }
      console.log('OrderDishModelArr1' +JSON.stringify(orderDishModelArr) );
      return orderDishModelArr;
    }

     formBulkOrderModel(){

console.log('InformBulkOrderModel');
      let orderDishModelArr = this.formMenuLineModelArr();
      console.log('InformBulkOrderModel2');
      let orderModel = new BulkOrderMenuLineEditModelDays(orderDishModelArr);


      console.log('OrderDishModelArr2' +JSON.stringify(orderModel) );
      return orderModel;

    }

	doOrder(content){
		console.debug('[doOrder] In doOrder');
     //  this.dishRepository.doOrder(this.cart.formOrderModel(),  this.localStorageService.get( 'savedUserId'))
      this.dishRepository.sendBulkOrderMenuLine(this.shiftWeek, this.formBulkOrderModel())
      .subscribe( (response) => {console.log('The order is done successfully');

       this.openWindowCustomClassDone(content);
       this.disabledAfterOrder = true;


		this.simpleOderModelArray = this.setSendedOrderClearChangedAll(this.simpleOderModelArray);
        this.localStorageService.set(this.simpleOderModelArrayId, this.simpleOderModelArray);

       	this.setTimer(this.delay).subscribe(update => {

          console.log('time for modal is expired' );
          this.modalService.dismissAll();

        });

       },
        (error)=> {console.log('The order is invalid')});

    }


    setTimer(interval:number):Observable<number> {

       return timer(interval);

    }
     openWindowCustomClassDone(content) {
    this.modalService.open(content, { centered: true, windowClass: 'darkModalCart' });
     }
//------------------------------ addNewItemMap ---------------------------------------------------
  addNewItemMap(day:string, itemMenuLines:any){

	console.log('[addNewItemMap] In addNewItemMap');
	//this.modalKodCategoryMap.set(this.selectedKodFuldkost, this.currentTilbehorArr);
	console.debug('[addNewItemMap] this.modalKodCategoryMap', this.modalKodCategoryMap);

	console.debug('[addNewItemMap] this.selectedStivelseFuldkost', this.selectedStivelseFuldkost);
	console.debug('[addNewItemMap] this.selectedKodFuldkost', this.selectedKodFuldkost);

	console.debug('[addNewItemMap] this.contentFuldkostAntalNew', this.contentFuldkostAntalNew);
	if(this.contentFuldkostAntalNew!=0){

		let value = JSON.parse(JSON.stringify(this.modalKodCategoryMap.get(this.selectedKodFuldkost)));
			console.log('[addNewItemMap] value', value);
			for(let itemTibehor in value){
				if( value[itemTibehor]['tibehorCategory']==this.selectedStivelseFuldkost){

					value[itemTibehor]['tibehorQuantity'] = this.contentFuldkostAntalNew;
					//value[itemTibehor]["tibehorQuantity"]=35;
					//console.log("[addNewItemMap] value[itemTibehor][tibehorQuantity]", value[itemTibehor]["tibehorQuantity"]);

				}


		}
	console.log('[addNewItemMap] value After', value);
	this.modalKodCategoryMap.set(this.selectedKodFuldkost, value);
    console.debug('[addNewItemMap] this.modalKodCategoryMapUPT', this.modalKodCategoryMap);

	this.modalKodCategoryArrayFiltered = Array.from(this.getFilteredMapByQuantitiesSoft(this.modalKodCategoryMap, itemMenuLines));
	console.debug('[addNewItemMap] modalKodCategoryArrayFiltered', this.modalKodCategoryArrayFiltered);

	console.debug('[addNewItemMap] this.modalKodCategoryMap After Filter', this.modalKodCategoryMap);

	this.contentFuldkostAntalNew = 0;

	}


  	return false;
  }
//------------------------------------------------------------------------------------
//------------------------------Check Permittions-------------------------------------

	checkPermittionsForOrder(
    dishAllocationsWeekResponse: any
  ) {
    return this.dishResponseService.checkPermittionsForOrder(dishAllocationsWeekResponse);
  }
//---------------------------PopUp-----------------------------------


	goBack(){
		this.continueSwitching = false;
		if(this.isLeavingPage){
			this.dataShareService.canToogleFromPopUp = false;
		}

		this.isLeavingPage = false;

		//this.isClosedPopUp = true;
		//this.isContinuePopUp=false;
		this.modalService.dismissAll();
	}

	continue(){
		let toggledDay = this.localStorageService.get( 'toggledDayBulkOrder');
		//this.simpleOderModelArray = this.clearChangedOrder(this.simpleOderModelArray, toggledDay);
		//console.debug("[continue] this.simpleOderModelArray", this.simpleOderModelArray);

		this.dishAllocationsOrderDays = JSON.parse(JSON.stringify(this.dishAllocationsOrderDaysAll));
		switch(toggledDay){
			case 'Tuesday':

			this.updateOrdersInLocalStorageFromResponseIfExist('Tuesday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));
			this.updateOrdersInLocalStorageFromResponseIfExist('Wednesday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));
			break;
			case 'Thursday':
			this.updateOrdersInLocalStorageFromResponseIfExist('Thursday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));
			this.updateOrdersInLocalStorageFromResponseIfExist('Friday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));
			this.updateOrdersInLocalStorageFromResponseIfExist('Saturday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));


			break;
			case 'Saturday':
			this.updateOrdersInLocalStorageFromResponseIfExist('Sunday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));
			this.updateOrdersInLocalStorageFromResponseIfExist('Monday', JSON.parse(JSON.stringify(this.dishAllocationsOrderDays)));

			break;
			default:
			{}

		}


		console.debug('[continue] this.dishAllocationsOrderDays', JSON.stringify(this.simpleOderModelArray));
		this.continueSwitching = true;
		if(!this.isLeavingPage){
			switch(this.nextDay){
			case 'Tuesday':
				this.switchTuesday();
				this.continueSwitching = false;
			break;
			case 'Thursday':

				this.switchThursday();
				this.continueSwitching = false;
			break;
			case 'Saturday':
				this.switchSaturday();
				this.continueSwitching = false;

			break;
			default:{}

			}
			this.dataShareService.canToogleFromPopUp = true;
			this.modalService.dismissAll();
		}else{
			//this.canDeactivate()
			this.isLeavingPage = false;
			this.dataShareService.canToogleFromPopUp = true;
			let nextStateUrl = this.localStorageService.get('nextState');
			console.log('[continue] nextState', nextStateUrl);
			this.modalService.dismissAll();
			this.router.navigateByUrl(nextStateUrl);
		}
		//this.isClosedPopUp = true;
		//this.isContinuePopUp=true;

	}
//--------------------------------------------------------------------

canDeactivate() : boolean | Observable<boolean> {
		this.isLeavingPage = true;
     	this.isQuantitiesChanged = this.checkQuantitiesChanged(this.simpleOderModelArray);
        if(this.isQuantitiesChanged){
            //this.isQuantitiesChanged = this.checkQuantitiesChanged(this.simpleOderModelArray);
			//if(this.isQuantitiesChanged){
			//this.isClosedPopUp=false;

			this.openWindowCustomClass(this.contentPopUp);

			//}
			/*while(this.isClosedPopUp==false){

			}*/
			//console.log("[canDeactivate] this.activatedRoute", this.activatedRoute);
            //return confirm("Вы хотите покинуть страницу?");
          /* if(this.isContinuePopUp==true){
           	return true
           }else{
           	return false;
           }*/
          	return false;
        }
        else{
        	this.isLeavingPage = false;
            return true;
        }
    }

 //---------------------------------For Template Day-------------------
 translateDayToDanish(day:string){
  		return this.localeDaDKService.translateDayToDanishsFull(day);

  	}

  	getDayDate(day:string){
  		if(day!='Sunday' && day!='Monday'){
  			return this.dateService.getISODateDDMMMforDay(day,  this.shiftWeek);
  			}else{
  			return this.dateService.getISODateDDMMMforDay(day, this.shiftWeek+1);
  			}

  	}





 //------------------------------------------------------------------


//-----------------------------SubLInes-------------------------------------------
createSubLinesSetKodArr(itemMenuLines:any){
		let subLinesSetKodArr =Array.from(this.getSubLinesSetForMenuLineFuldcostKod(itemMenuLines))
		console.debug('[create] subLinesSetKodArr', subLinesSetKodArr);
		return subLinesSetKodArr;

	}

createSubLinesSetTilbehorArr(itemMenuLines:any){
		let subLinesSetTilbehorArr =Array.from(this.getSubLinesSetForMenuLineFuldcostTilbehor(itemMenuLines))
		console.debug('[create] subLinesSetTilbehorArr', subLinesSetTilbehorArr);
		return subLinesSetTilbehorArr;
	}

	createSubLinesSetGrontArr(itemMenuLines:any){
		let subLinesSetGrontArr =Array.from(this.getSubLinesSetForMenuLineFuldcostGront(itemMenuLines))
		console.debug('[create] subLinesSetGrontArr', subLinesSetGrontArr);
		return subLinesSetGrontArr;
	}

	createSubLinesSetSovsArr(itemMenuLines:any){
		let subLinesSetSovsArr =Array.from(this.getSubLinesSetForMenuLineFuldcostSovs(itemMenuLines))
		console.debug('[create] subLinesSetSovsArr', subLinesSetSovsArr);
		return subLinesSetSovsArr;
	}
//--------------------------------------------------------------------------------
getSimpleOderModelBulkArray(simpleOderModelArray:SimpleOderModel[]){
	let simpleOderModelBulkArray = [];
	for(let item in simpleOderModelArray){
		if(simpleOderModelArray[item]['dietTypeName']=='Fuldkost'){
			simpleOderModelBulkArray.push(simpleOderModelArray[item]);

		}
	}

	return simpleOderModelBulkArray;

}
getSimpleOderModelOtherArray(simpleOderModelArray:SimpleOderModel[]){
	let simpleOderModelOtherArray = [];
	for(let item in simpleOderModelArray){
		if(simpleOderModelArray[item]['dietTypeName']!='Fuldkost'){
			simpleOderModelOtherArray.push(simpleOderModelArray[item]);

		}
	}

	return simpleOderModelOtherArray;

}


}

