import { Injectable } from '@angular/core';
//import { Http, Request, RequestMethod , Headers} from "@angular/http";
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { Product } from '../model/product.model';
import { map } from 'rxjs/operators';
import { Cart } from '../cart/cart-model/cart.model';
//import { Order } from "./order.model";
import { LocalStorageService } from '../service/localStorage.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateService } from '../service/date.service';
import { OrderModel } from '../model/orderModel.model';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/delay';
import { QuestionModel } from '../model/questionModel.model';
import { environment } from '../../environments/environment';
import { NewsItem } from '../model/newsItemModel.model';

@Injectable()
export class RestDataSource {
  baseUrl: string;
  baseUrlTemp: string;
  baseUrlDishallocations: string;
  auth_token: string;
  status_text: string;
  username: string;
  password: string;
  error: string;
  expires_in: number;
  userId: string;
  baseUrlCommon: string;
  responseDish: any;
  departmentId: number;

  constructor(
    //private http: Http,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private pipe: DatePipe,
    private dateService: DateService
  ) {
    this.baseUrl = environment.baseUrl;
    //-----------------------------------------------------------------------
    this.auth_token = ' ';
    this.status_text = ' ';
    this.expires_in = 3600000; // 1h default
    this.departmentId = this.localStorageService.get('currentdepartmentId');
  }

  authenticate(user: string, pass: string): Observable<boolean> | any {
    // const myHeaders =  new Headers({
    const myHeaders = new HttpHeaders({
      'Content-type': 'application/x-www-form-urlencoded',
      'Application-Name': 'exampleApp',
    });

    const body = 'grant_type=password&username=' + user + '&password=' + pass;
    /*  return this.http.request(new HttpRequest({
            method: "POST",
            url: this.baseUrl+'token',
            body: body,
            headers: myHeaders
        }))*/
    return this.http
      .request('POST', this.baseUrl + 'token', {
        body: body,
        headers: myHeaders,
      })

      .pipe(
        map((response: any) => {
          //  let r = response.json();
          let r = response;
          this.auth_token = r.access_token;
          this.expires_in = r.expires_in;
          this.userId = r.userId;

          return true;
        })
      )

      .catch((error: Response) =>
        Observable.throw(`Network Error: ${error.statusText} (${error.status})`)
      );
  }

  getDishAllocationsCurrentDate(currentISODate: string): Observable<any> {
    console.log('In DATASOURCE currentISODate=' + currentISODate);
    this.auth_token = this.localStorageService.get('auth_token');
    this.userId = this.localStorageService.get('savedUserId');
    let urlDish =
      this.baseUrl +
      'api/dishallocations/available/' +
      currentISODate +
      '?userId=' +
      this.userId +
      '&ignoreDiets=false';

    return this.sendRequest('GET', urlDish, true, true);
  }

  getUserInfo(userId: string): Observable<any> {
    this.auth_token = this.localStorageService.get('auth_token');
    let urlUserInfo = this.baseUrl + 'api/account/userinfo?' + 'id=' + userId;

    return this.sendRequest('GET', urlUserInfo, true, true);
  }

  getUserInfoOrderingModel(userId: string) {
    this.auth_token = this.localStorageService.get('auth_token');
    let urlUserInfoOrderingModel =
      this.baseUrl + 'api/account/userordering/' + userId;

    return this.sendRequest('GET', urlUserInfoOrderingModel, true, true);
  }

  doOder(orderModel: OrderModel, userId: string): Observable<any> {
    let authBearer = 'Bearer ' + this.auth_token;
    const body = orderModel;

    const myHeaders = new HttpHeaders({
      Authorization: authBearer,
      'Application-Name': 'exampleApp',
    });

    this.auth_token = this.localStorageService.get('auth_token');
    let urlOrder = this.baseUrl + 'api/order?' + 'userId=' + userId;

    /*return this.http.request(new HttpRequest({
            method: "POST",
            url: urlOrder,
            body: body,
headers: myHeaders
        }));*/

    return this.http.request('POST', urlOrder, {
      body: body,
      headers: myHeaders,
    });
  }

  sendQuestionToOnlineSupport(questionModel: QuestionModel) {
    let authBearer = 'Bearer ' + this.auth_token;
    const body = questionModel;
    const myHeaders = new HttpHeaders({
      Authorization: authBearer,
      'Application-Name': 'exampleApp',
    });

    this.auth_token = this.localStorageService.get('auth_token');
    let urlOrder = this.baseUrl + 'api/modules/online-support/questions';

    /*  return this.http.request(new HttpRequest({
            method: "POST",
            url: urlOrder,
            body: body,
headers: myHeaders
        }));*/
    return this.http.request('POST', urlOrder, {
      body: body,
      headers: myHeaders,
    });
  }

  getAllQuestionsFromOnlineSupport() {
    this.auth_token = this.localStorageService.get('auth_token');
    let urlAllQuestions = this.baseUrl + 'api/modules/online-support/questions';
    return this.sendRequest('GET', urlAllQuestions, true, true);
  }

  getAnswerFromOnlineSupportById(
    questionId: number
  ): Observable<QuestionModel> {
    this.auth_token = this.localStorageService.get('auth_token');
    let urlAnswer =
      this.baseUrl + 'api/modules/online-support/questions/' + questionId;
    return this.sendRequest('GET', urlAnswer, true, true);
  }

  getBulkDishsCurrentDate(currentISODate: string): Observable<any> {
    this.departmentId = this.localStorageService.get('currentdepartmentId');
    this.auth_token = this.localStorageService.get('auth_token');
    this.userId = this.localStorageService.get('savedUserId');
    let urlBulkDish =
      this.baseUrl +
      'api/anova/institution-solution/bulk-dishes/' +
      currentISODate +
      '/' +
      this.departmentId;
    return this.sendRequest('GET', urlBulkDish, true, true);
  }

  getBulkOrderCurrentDate(currentISODate: string): Observable<any> {
    this.departmentId = this.localStorageService.get('currentdepartmentId');
    this.auth_token = this.localStorageService.get('auth_token');
    this.userId = this.localStorageService.get('savedUserId');
    let urlBulkOrder =
      this.baseUrl +
      'api/anova/institution-solution/bulk-order/' +
      currentISODate +
      '/' +
      this.departmentId;

    console.log(urlBulkOrder)  
    return this.sendRequest('GET', urlBulkOrder, true, true);
  }

  sendBulkOrderCurrentDate(currentISODate: string, productNr: number) {
    console.log('In rest sendBulkOrder');
    this.auth_token = this.localStorageService.get('auth_token');
    this.auth_token =
      'dRKPHgKVffId7JlwdcK156G05UTt-S_3zDRgYO-uVDPEpexsEVJwwHOTFGrhqZ6Q-C7-zImTw6dqysIyIJjcm0tXMAo0gPbo4ZwFaiWkC32btmQbgNMUxYMh69dqzlZg41gD6wB5OgBtNbD0_jFjZuCI1NtOBv5UbTMWCb6u0BEOcO4afDXd3RX7TI0k-4PMfzG-pV0N5TxFSq7YmBlSlg4dAFtSSx19Bk3vS0_CLbINpIMT_q_txnqaIRAOpZdFinT2gilDk5KMlrmikunH9rY_WdNRs2zmCtXZIwBz8qARFvXHNbYy1KNXzRJtYRD3lWwVLSaVHtZOe65zMtoR8MWZ_eAZ4aHnPYAAoVJQLwvCLn-Iq4OWkUVyw-N0fDwqHWci4ei1oKLCErN8mGgWLRQG8k_RJDJhapKoqOK0F-FYibYCNijOWX3L8NF5AllIt-kyXd3s4eWOnsn3lCCf0w0QyOyf9Dfbxjbr7SItIUZvhdaGG41rlFHi-U0-elh5DGIva1WAfWBNFELg8qaY74QjqYWl1rA324ouF8Y6LIVNV7jUvCe2uI9DYnDxaxj2jLiXoCrhv6UH1ErFeI4I0iCuJzLppG4f3M6kF0suNnQkPWUkvGrP4i1kS-SnlIb3sVmUINoh0JQuNHYqWTephV-xy6yGImVf_SAID_RfyoC7pXXGlx1yw-463YFDZXB5cim-cQZXwGqdHCOVUE9AGWFKkSQhFD-MIvlCRaIGlfrFLlM75nF30uuvNKNuet47lKJa-t6KxWWi2Wo4hoatjGuocu8xWjZbiVOPQw1A3ggrQh0Ek9_oxhAEUtt1Xj4e';
    let authBearer = 'Bearer ' + this.auth_token;
    const body = productNr;
    const myHeaders = new HttpHeaders({
      Authorization: authBearer,
      'Application-Name': 'exampleApp',
    });

    this.auth_token = this.localStorageService.get('auth_token');
    let urlOrder =
      this.baseUrlTemp +
      'api/anova/institution-solution/select-bulk-dish/' +
      currentISODate +
      '/' +
      productNr;
    console.log('urlOrder', urlOrder);
    /* return this.http.request(new HttpRequest({
            method: "PUT",
            url: urlOrder,
            body: body,
headers: myHeaders
        }));*/
    return this.http.request('PUT', urlOrder, {
      body: body,
      headers: myHeaders,
    });
  }

  deleteBulkOrderCurrentDate(currentISODate: string, productNr: number) {
    console.log('In rest deleteBulkOrder');
    this.auth_token = this.localStorageService.get('auth_token');
    this.auth_token =
      'dRKPHgKVffId7JlwdcK156G05UTt-S_3zDRgYO-uVDPEpexsEVJwwHOTFGrhqZ6Q-C7-zImTw6dqysIyIJjcm0tXMAo0gPbo4ZwFaiWkC32btmQbgNMUxYMh69dqzlZg41gD6wB5OgBtNbD0_jFjZuCI1NtOBv5UbTMWCb6u0BEOcO4afDXd3RX7TI0k-4PMfzG-pV0N5TxFSq7YmBlSlg4dAFtSSx19Bk3vS0_CLbINpIMT_q_txnqaIRAOpZdFinT2gilDk5KMlrmikunH9rY_WdNRs2zmCtXZIwBz8qARFvXHNbYy1KNXzRJtYRD3lWwVLSaVHtZOe65zMtoR8MWZ_eAZ4aHnPYAAoVJQLwvCLn-Iq4OWkUVyw-N0fDwqHWci4ei1oKLCErN8mGgWLRQG8k_RJDJhapKoqOK0F-FYibYCNijOWX3L8NF5AllIt-kyXd3s4eWOnsn3lCCf0w0QyOyf9Dfbxjbr7SItIUZvhdaGG41rlFHi-U0-elh5DGIva1WAfWBNFELg8qaY74QjqYWl1rA324ouF8Y6LIVNV7jUvCe2uI9DYnDxaxj2jLiXoCrhv6UH1ErFeI4I0iCuJzLppG4f3M6kF0suNnQkPWUkvGrP4i1kS-SnlIb3sVmUINoh0JQuNHYqWTephV-xy6yGImVf_SAID_RfyoC7pXXGlx1yw-463YFDZXB5cim-cQZXwGqdHCOVUE9AGWFKkSQhFD-MIvlCRaIGlfrFLlM75nF30uuvNKNuet47lKJa-t6KxWWi2Wo4hoatjGuocu8xWjZbiVOPQw1A3ggrQh0Ek9_oxhAEUtt1Xj4e';
    let authBearer = 'Bearer ' + this.auth_token;
    const body = productNr;
    const myHeaders = new HttpHeaders({
      Authorization: authBearer,
      'Application-Name': 'exampleApp',
    });

    this.auth_token = this.localStorageService.get('auth_token');
    // let urlOrder =  this.baseUrl +
    let urlOrder =
      this.baseUrlTemp +
      'api/anova/institution-solution/delete-selection-bulk-dish/' +
      currentISODate +
      '/' +
      productNr;
    console.log('urlOrder', urlOrder);
    /*  return this.http.request(new HttpRequest({
            method: "DELETE",
            url: urlOrder,
            body: body,
headers: myHeaders
        }));*/
    return this.http.request('DELETE', urlOrder, {
      body: body,
      headers: myHeaders,
    });
  }

  /*  putBulkOrderCurrentDate(menuNr:number, productNr:number, line:number,
    deliveryISODate:string, servingISODate:string){
        this.departmentId = this.localStorageService.get('currentdepartmentId');

        this.auth_token = this.localStorageService.get( 'auth_token');
        let authBearer = 'Bearer ' +  this.auth_token;
         const body = productNr;
         const myHeaders =
              new HttpHeaders({
                "Authorization": authBearer,
                "Application-Name": "exampleApp"
              })


       let urlPutOrder =  this.baseUrl +
        'api/anova/institution-solution/product-serving-date/'
        + this.departmentId + "/"
        + menuNr + "/"
        + productNr + "/"
        + line + "/"
        + deliveryISODate + "/"
        + servingISODate;
        console.log("urlPutOrder", urlPutOrder);

        return this.http.request(
            "PUT",
            urlPutOrder,
            {
              body: body,
              headers:myHeaders
            }
       );
         } */
  putBulkOrderCurrentDate(
    menuNr: number,
    productNr: number,
    line: number,
    deliveryISODate: string,
    servingISODate: string,
    print?: boolean,
    customerNr?: number
  ) {
    console.log('[putBulkOrderCurrentDate] print', print);
    this.departmentId = this.localStorageService.get('currentdepartmentId');

    this.auth_token = this.localStorageService.get('auth_token');
    let authBearer = 'Bearer ' + this.auth_token;
    const body = productNr;
    const myHeaders = new HttpHeaders({
      Authorization: authBearer,
      'Application-Name': 'exampleApp',
    });

    let urlPutOrder =
      this.baseUrl +
      'api/anova/institution-solution/product-serving-date/' +
      this.departmentId +
      '/' +
      customerNr +
      '/' +
      menuNr +
      '/' +
      productNr +
      '/' +
      line +
      '/' +
      deliveryISODate +
      '/' +
      servingISODate;
    if (servingISODate != '' && print !== undefined) {
      urlPutOrder = urlPutOrder + '/' + print;
      console.log('[putBulkOrderCurrentDate] print urlPutOrder', urlPutOrder);
    }
    console.log('[putBulkOrderCurrentDate] urlPutOrder', urlPutOrder);

    return this.http.request('PUT', urlPutOrder, {
      body: body,
      headers: myHeaders,
    });
  }

  putBulkOrderPrintCurrentDate(
    menuNr: number,
    productNr: number,
    line: number,
    deliveryISODate: string,
    print: boolean
  ) {
    this.departmentId = this.localStorageService.get('currentdepartmentId');

    this.auth_token = this.localStorageService.get('auth_token');
    let authBearer = 'Bearer ' + this.auth_token;
    const body = productNr;
    const myHeaders = new HttpHeaders({
      Authorization: authBearer,
      'Application-Name': 'exampleApp',
    });

    let urlPutOrder =
      this.baseUrl +
      'api/anova/institution-solution/product-print/' +
      this.departmentId +
      '/' +
      menuNr +
      '/' +
      productNr +
      '/' +
      line +
      '/' +
      deliveryISODate +
      '/' +
      print;
    console.log('urlPutOrder', urlPutOrder);
    /* return this.http.request(new HttpRequest({
            method: "PUT",
            url: urlPutOrder,
            body: body,
headers: myHeaders
        }));*/
    return this.http.request('PUT', urlPutOrder, {
      body: body,
      headers: myHeaders,
    });
  }

  getBulkOrderBreakfastCurrentDate(currentISODate: string): Observable<any> {
    this.departmentId = this.localStorageService.get('currentdepartmentId');
    this.auth_token = this.localStorageService.get('auth_token');

    this.userId = this.localStorageService.get('savedUserId');

    let urlBulkOrder =
      this.baseUrl +
      'api/anova/institution-solution/bulk-order-breakfast/' +
      currentISODate +
      '/' +
      this.departmentId;

    return this.sendRequest('GET', urlBulkOrder, true, true);
  }

  getBulkOrderOptionalCurrentDate(currentISODate: string): Observable<any> {
    this.departmentId = this.localStorageService.get('currentdepartmentId');
    this.auth_token = this.localStorageService.get('auth_token');

    this.userId = this.localStorageService.get('savedUserId');

    let urlBulkOrder =
      this.baseUrl +
      'api/anova/institution-solution/bulk-order-optional/' +
      currentISODate +
      '/' +
      this.departmentId;

    return this.sendRequest('GET', urlBulkOrder, true, true);
  }

  postBulkOrderMenuLineCurrentDate(
    bulkOrderMenuLineEditModel: any,
    servingISODate: string
  ) {
    this.departmentId = this.localStorageService.get('currentdepartmentId');
    console.log('In rest putBulkOrder');
    this.auth_token = this.localStorageService.get('auth_token');
    let authBearer = 'Bearer ' + this.auth_token;
    const body = bulkOrderMenuLineEditModel;
    const myHeaders = new HttpHeaders({
      Authorization: authBearer,
      'Application-Name': 'exampleApp',
    });

    let urlPutOrder =
      this.baseUrl +
      'api/anova/institution-solution/bulk-order/menuline/' +
      servingISODate +
      '/' +
      this.departmentId;
    console.log('urlPutOrder', urlPutOrder);
    /* return this.http.request(new HttpRequest({
            method: "POST",
            url: urlPutOrder,
            body: body,
            headers: myHeaders
        }));*/
    return this.http.request('POST', urlPutOrder, {
      body: body,
      headers: myHeaders,
    });
  }

  getProductPrice(
    productNr: number,
    dietTypeNr: number,
    portionSize: number,
    priceGroup: number,
    currentISODate: string
  ): Observable<any> {
    //this.departmentId = this.localStorageService.get('currentdepartmentId');
    this.auth_token = this.localStorageService.get('auth_token');

    //this.userId = this.localStorageService.get( 'savedUserId');

    let urlBulkOrder =
      this.baseUrl +
      'api/anova/institution-solution/product-price/' +
      productNr +
      '/' +
      dietTypeNr +
      '/' +
      portionSize +
      '/' +
      priceGroup +
      '/' +
      currentISODate;

    return this.sendRequest('GET', urlBulkOrder, true, true);
  }

  getNews(): Observable<NewsItem[]> {
    this.departmentId = this.localStorageService.get('currentdepartmentId');
    this.auth_token = this.localStorageService.get('auth_token');
    let urlGetNews: string;

    if (false && this.departmentId !== null) {
		  urlGetNews = environment.baseUrl + 'api/modules/news/' + this.departmentId;
    } else {
      urlGetNews = environment.baseUrl + 'api/modules/news';
    }

    return this.sendRequest('GET', urlGetNews, true, true);
  }

  private sendRequest(
    verb: string,
    url: string,
    body?: any,
    auth: boolean = false
  ): Observable<any> {

    let myHeaders;

    if (auth && this.auth_token != null) {
      let authBearer = 'Bearer ' + this.auth_token;
      myHeaders = new HttpHeaders({
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: authBearer,
        'Application-Name': 'exampleApp',
      });

    }

    return this.http.request(verb, url, {
      body: body,
      headers: myHeaders,
    });

  }


  getSelection(date:any,departmentId:any): Observable<any> {
    this.auth_token = this.localStorageService.get('auth_token');
    let url =
      this.baseUrl +
      'api/anova-fe/guestChef/getSelection/'+departmentId+'/'+date
      console.log(url)  
    return this.sendRequest('GET', url, true, true);
  }

  saveSelection(body:any) {
    let authBearer = 'Bearer ' + this.auth_token;
    const myHeaders = new HttpHeaders({
      Authorization: authBearer,
      'Application-Name': 'exampleApp',
    });

    this.auth_token = this.localStorageService.get('auth_token');
    let url = this.baseUrl + 'api/anova-fe/guestChef/saveSelection';
    return this.http.request('POST', url, {
      body: body,
      headers: myHeaders,
    });
  }

  getCompany(companyId:any): Observable<any> {
    this.auth_token = this.localStorageService.get('auth_token');
    let url =
      this.baseUrl +
      'api/companies/'+companyId
      console.log(url)  
    return this.sendRequest('GET', url, true, true);
  }

  
  getBulkOrderFromDepartmentCurrentDate(currentISODate: string,departmentId:string): Observable<any> {
    this.auth_token = this.localStorageService.get('auth_token');
    this.userId = this.localStorageService.get('savedUserId');
    let urlBulkOrder =
      this.baseUrl +
      'api/anova/institution-solution/bulk-order/' +
      currentISODate +
      '/' +
      departmentId;

    console.log(urlBulkOrder)  
    return this.sendRequest('GET', urlBulkOrder, true, true);
  }

}
