import {Injectable} from '@angular/core';
import {Component, Inject} from '@angular/core';
import { Router,
		 ActivatedRoute,
		 NavigationEnd,
		 ActivationEnd,
  		 Event,
		 NavigationCancel,
		 NavigationError,
		 NavigationStart } from '@angular/router';
import {LocalStorageService} from '../../service/localStorage.service';
import { Cart } from '../../cart/cart-model/cart.model';
import {DateService} from '../../service/date.service';
import { AllocatedDishModel } from '../../model/allocatedDishModel.model';
import { OrderDish } from '../../model/orderDish.model';
import { DishAllocationWeekDataBase } from '../../model/dishAllocationWeek.database';
import {DishRepository} from '../../repository/dish.repository';
import { RestDataSource } from '../../repository/rest.datasource';
import {ModelResolver} from '../../resolver/model.resolver';
import { timer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import {of, from } from 'rxjs';
import {Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/concatAll';
import 'rxjs/add/operator/concatMap';
//import "rxjs/add/operator/map";
import { map } from 'rxjs/operators';
import { _ } from 'underscore';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { SafePipeModule } from 'safe-pipe';
import { AuthService } from '../../service/auth.service';
import * as moment from 'moment';
import { environment } from './../../../environments/environment';
import {NavigationService} from '../../service/navigation.service';
import {LocaleDaDKService} from '../../service/locale-da-dk.service';
import { DishAllocationsOrderDays } from '../../model/dishAllocationsOrderDays.model'


class SimpleOderModel{
	//orderDishId:string;
	orderDishId:number;
	orderNumber:number;
	sumOder:number;
	Day:string;
	dishId:number;
	name:string;
	imageUrl:string;
	category:string;
	subcategory:string;


	// constructor(orderDishId:string, orderNumber:number, sumOder:number, Day:string,
	constructor(orderDishId:number, orderNumber:number, sumOder:number, Day:string,
	 	dishId:number, name:string, imageUrl:string, category:string, subcategory:string) {
	 	this.orderDishId = orderDishId;
	 	this.orderNumber = orderNumber;
	 	this.sumOder = sumOder;
	 	this.Day = Day;
	 	this.dishId = dishId;
	 	this.name = name;
	 	this.imageUrl = imageUrl;
	 	this.category = category;
	 	this.subcategory = subcategory;

	 }

}
class SelectedWeekYear{

	 week: number;
	 yearYYYY: string;
	 mainDishOnly:boolean;

	constructor() {
	 this.week = undefined;
	 this.yearYYYY = undefined;
	 this.mainDishOnly = true;

	 }

}
class SelectedWeekForPrintModel{

	 week_0: SelectedWeekYear;

	 week_1: SelectedWeekYear;

	 week_2: SelectedWeekYear;

	 week_3: SelectedWeekYear;

	 week_4: SelectedWeekYear;



	constructor(selectedWeekYear_0, selectedWeekYear_1, selectedWeekYear_2, selectedWeekYear_3, selectedWeekYear_4 ) {
	 this.week_0 = selectedWeekYear_0;

	 this.week_1 = selectedWeekYear_1;

	 this.week_2 = selectedWeekYear_2;

	 this.week_3 = selectedWeekYear_3;

	 this.week_4 = selectedWeekYear_4;


	 }

}



@Component({
    selector: 'printselectweek-root',
    templateUrl:  'printselectweek.component.html',
  	styleUrls: ['printselectweek.component.css']
})
export class PrintSelectWeekComponent {

	public sumOder:number;

	public weeks:string[];
	//private dishesArray: AllocatedDishModel[] = [];
	//private dishesForDayArray: any[] = [];
	public dishAllocationsWeekResponse: any[] = [];
	//private orderDishArray: OrderDish[] = [];

    public currentWeek:string;
	//private showUserName: boolean;
	public lastIndex:number;
	//private rowArray:any[];
	//private itemArray:any[];
	obsSubscription: Subscription;
	public numberElementsInRow:number;
	//private arrayDishesView: number[];
	public arrayDishesViewForDay: any[];
	public orderDishId:number;
	public orderDaysLeftInWeekArray:string[];
	public dishAllocationsOrderDays:DishAllocationsOrderDays;
	//private dishAllocationsOrderDaysCategory:DishViewController[] = [];
	public currentToogledDay:string;
	public toggledMonday: boolean;
	public toggledWednesday: boolean;
	public toggledFriday: boolean;
	public SundayDate:string;
	public MondayDate:string;
	public TuesdayDate:string;
	public WednesdayDate:string;
	public ThursdayDate:string;
	public FridayDate:string;
	public SaturdayDate:string;
	public shiftWeekNumber: string;
	public shiftWeek: number;
	public needNgOninit:boolean;
	public  simpleOderModelArray: SimpleOderModel[];
	public imgUrl:string;
	public tempArray:any[]=[];
	public categoryForFilter:any;
	//private ToogledDays:string;
	public simpleOderModelArrayId:string;
	public orderingDates:any[];
	public userInfoOrderingModel:any[] = [];
	//obsUserInfoOrderingModelSubscription: Subscription;
	public currentDayNumber:number;
	public filterOn:boolean;
	public isOrderingTimeFlag: boolean;
	public isEmptyMonday:boolean;
	public isEmptyTuesday:boolean;
	public isEmptyWednesday:boolean;
	public isEmptyThursday:boolean;
	public isEmptyFriday:boolean;
	public isEmptySaturday:boolean;
	public isEmptySunday:boolean;
	private departmentId: number;
	public shiftMonthNumber: string;
	public shiftMonthName: string;
	public shiftMonth: number;
	public currentMonth:number;
	public shiftWeekNumber_0: number;
	public shiftWeekNumber_1: number;
	public shiftWeekNumber_2: number;
	public shiftWeekNumber_3: number;
	public shiftWeekNumber_4: number;
	public MondayDate_0 : string;
	public SundayDate_0: string;
	public MondayDate_1: string;
	public SundayDate_1: string;
	public MondayDate_2: string;
	public SundayDate_2: string;
	public MondayDate_3: string;
	public SundayDate_3: string;
	public MondayDate_4: string;
	public SundayDate_4: string;
	public selectedWeekForPrint: number;
	public selectedWeekForPrintModel: SelectedWeekForPrintModel;
	public selectedVisWeekForPrintModel: SelectedWeekForPrintModel;
	public fromPage:string;

	closeResult: string;

	constructor( private dataSource: RestDataSource,
		private localStorageService: LocalStorageService,
	 	private cart: Cart, private router: Router,
        private dateService:DateService, private dishRepository: DishRepository,
        private activatedRoute: ActivatedRoute,
        private modalService: NgbModal,
        private sanitizer: DomSanitizer,
        private auth: AuthService,
        public navigationService:NavigationService,
        private localeDaDKService:LocaleDaDKService) {



	 	this.localStorageService.set( 'currentChildRoute', 'print/printselectweek');
	 	this.localStorageService.set( 'parentPrintRoute', 'print/printselectweek');

	 	}

	ngOnInit(): void{
		//console.log("frokostpakker in NGONINIT");


		/*var t1 = moment().valueOf();
		console.log("1st block="+t1);*/
		this.needNgOninit = false;


		//NavTabs init
		this.toggledMonday = true;
		this.toggledWednesday = false;
		this.toggledFriday = false;
//--------------------------------Routing---------------------------
		this.fromPage = this.localStorageService.get( 'fromPage');

		console.log('[ngOnInit] this.fromPage', this.fromPage);
//-----------------------------Depatments --------------------------------------------
		this.departmentId = this.localStorageService.get('currentdepartmentId');

//----------------------------------------------------------------------------------


	 	this.sumOder = this.localStorageService.get('sumOder');


	 	this.dishAllocationsOrderDays = new DishAllocationsOrderDays();

	 	this.dishAllocationsWeekResponse = [];

		//---------------------Date------------------------------------

			//this.shiftWeek = 0;
		// if NgOnInit after chabging week load from LocalStorage
		//if(this.needNgOninit){
	 	this.shiftWeek = this.localStorageService.get('shiftWeek'+ this.localStorageService.get( 'currentChildRoute'));
	 	this.shiftMonth = this.localStorageService.get('shiftMonth'+ this.localStorageService.get( 'currentChildRoute'));
	 	if(this.shiftMonth==undefined || this.shiftMonth==null){
	 		this.localStorageService.set('shiftMonth'+ this.localStorageService.get( 'currentChildRoute'), 0);
	 		this.shiftMonth = 0;
	 	}
	 	console.log('[ngOnInit] this.shiftMonth', this.shiftMonth);

	 	//	}
		console.log('[ngOnInit] this.shiftWeek='+this.shiftWeek);
		this.currentDayNumber = this.dateService.getCurrentDayNumber();

     	this.currentWeek = this.dateService.getCurrentWeek();
     	console.log('[ngOnInit] this.currentWeek', this.currentWeek);
     	this.currentMonth = this.dateService.getCurrentMonth();
     	console.log('[ngOnInit] this.currentMonth', this.currentMonth);



     	this.shiftWeekNumber = (this.dateService.getShiftWeek(this.shiftWeek));
     	console.log('[ngOnInit] this.shiftWeekNumber', this.shiftWeekNumber);

     	this.shiftMonthNumber = this.dateService.getShiftMonth(this.shiftMonth);
	 	console.log('[ngOnInit] this.shiftMonthNumber', this.shiftMonthNumber);

     	this.shiftWeekNumber_0 = Number(this.dateService.getShiftWeekForMonth(this.shiftWeek, this.shiftMonth ));
     	console.log('[ngOnInit] this.shiftWeekNumber_0', this.shiftWeekNumber_0);

     	this.shiftWeekNumber_1 = Number(this.dateService.getShiftWeekForMonth((this.shiftWeek + 1), this.shiftMonth));
     	console.log('[ngOnInit] this.shiftWeekNumber_1', this.shiftWeekNumber_1);

     	this.shiftWeekNumber_2 = Number(this.dateService.getShiftWeekForMonth((this.shiftWeek + 2), this.shiftMonth));
     	console.log('[ngOnInit] this.shiftWeekNumber_2', this.shiftWeekNumber_2);

     	this.shiftWeekNumber_3 = Number(this.dateService.getShiftWeekForMonth((this.shiftWeek + 3), this.shiftMonth));
     	console.log('[ngOnInit] this.shiftWeekNumber_3', this.shiftWeekNumber_3);

     	this.shiftWeekNumber_4 = Number(this.dateService.getShiftWeekForMonth((this.shiftWeek + 4), this.shiftMonth));
     	console.log('[ngOnInit] this.shiftWeekNumber_4', this.shiftWeekNumber_4);



	 	this.shiftMonthName = this.dateService.getShiftMonthName(this.shiftMonth);
	 	console.log('[ngOnInit] this.shiftMonthName', this.shiftMonthName);


	 	this.SundayDate = this.dateService.getISODateDDMMMforDay('Sunday', this.shiftWeek);
	 	this.MondayDate = this.dateService.getISODateDDMMMforDay('Monday',  this.shiftWeek);
	 	this.TuesdayDate = this.dateService.getISODateDDMMMforDay('Tuesday',  this.shiftWeek);
	 	this.WednesdayDate = this.dateService.getISODateDDMMMforDay('Wednesday',  this.shiftWeek);
	 	this.ThursdayDate = this.dateService.getISODateDDMMMforDay('Thursday',  this.shiftWeek);
	 	this.FridayDate = this.dateService.getISODateDDMMMforDay('Friday',  this.shiftWeek);
	 	this.SaturdayDate = this.dateService.getISODateDDMMMforDay('Saturday',  this.shiftWeek);
	//--------------------------------------Days for Table---------------------------
		this.MondayDate_0 = this.dateService.getISODateDDMMMforDayMonth('Monday',  this.shiftWeek, this.shiftMonth);
	 	this.SundayDate_0 = this.dateService.getISODateDDMMMforDayMonth('Sunday', this.shiftWeek, this.shiftMonth);
	 	this.MondayDate_1 = this.dateService.getISODateDDMMMforDayMonth('Monday',  (this.shiftWeek + 1), this.shiftMonth);
	 	this.SundayDate_1 = this.dateService.getISODateDDMMMforDayMonth('Sunday', (this.shiftWeek + 1), this.shiftMonth);
	 	this.MondayDate_2 = this.dateService.getISODateDDMMMforDayMonth('Monday',  (this.shiftWeek + 2), this.shiftMonth);
	 	this.SundayDate_2 = this.dateService.getISODateDDMMMforDayMonth('Sunday', (this.shiftWeek + 2), this.shiftMonth);
	 	this.MondayDate_3 = this.dateService.getISODateDDMMMforDayMonth('Monday',  (this.shiftWeek + 3), this.shiftMonth);
	 	this.SundayDate_3 = this.dateService.getISODateDDMMMforDayMonth('Sunday', (this.shiftWeek + 3), this.shiftMonth);
	 	this.MondayDate_4 = this.dateService.getISODateDDMMMforDayMonth('Monday',  (this.shiftWeek + 4), this.shiftMonth);
	 	this.SundayDate_4 = this.dateService.getISODateDDMMMforDayMonth('Sunday', (this.shiftWeek + 4), this.shiftMonth);
	 	console.log('[ngOnInit] this.SundayDate_4', this.SundayDate_4);
	 	//console.log("[ngOnInit] this.SundayDate_4", this.dateService.getMonthForISODateDDMMM(this.SundayDate_4));
     	/*let selectedWeekYear_0 = new SelectedWeekYear();
     	let selectedWeekYear_1 = new SelectedWeekYear();
     	let selectedWeekYear_2 = new SelectedWeekYear();
     	let selectedWeekYear_3 = new SelectedWeekYear();
     	let selectedWeekYear_4 = new SelectedWeekYear();
     	this.selectedWeekForPrintModel = new SelectedWeekForPrintModel(selectedWeekYear_0, selectedWeekYear_1, selectedWeekYear_2, selectedWeekYear_3, selectedWeekYear_4);
     	this.selectedVisWeekForPrintModel = new SelectedWeekForPrintModel(selectedWeekYear_0, selectedWeekYear_1, selectedWeekYear_2, selectedWeekYear_4, selectedWeekYear_4);
     	*/
     	this.selectedWeekForPrintModel = new SelectedWeekForPrintModel(new SelectedWeekYear(), new SelectedWeekYear(), new SelectedWeekYear(), new SelectedWeekYear(), new SelectedWeekYear());
     	this.selectedVisWeekForPrintModel = new SelectedWeekForPrintModel(new SelectedWeekYear(), new SelectedWeekYear(), new SelectedWeekYear(), new SelectedWeekYear(), new SelectedWeekYear());
		console.log('[ngOnInit] this.selectedWeekForPrintModel', this.selectedWeekForPrintModel);




	//---------------------------------------------------------------------------



	    //--------------------- GET dishAllocations for All days in Week-----------------------------

	    /*	this.obsSubscription = this.activatedRoute.data.subscribe(data => {


	      	this.dishAllocationsWeekResponse=(data['dishes']);
			console.log("dishAllocationsWeekResponse="+JSON.stringify(this.dishAllocationsWeekResponse));


	      	this.categoryForFilter = "Frokost";
	      	this.dishAllocationsOrderDays["Sunday"]=(this.categoryFilter(0,this.dishAllocationsWeekResponse, this.categoryForFilter));
	      	if(_.isEmpty(this.dishAllocationsOrderDays["Sunday"])){
	      		console.log("this.dishAllocationsOrderDays['Sunday'].length==0")

				this.isEmptySunday=true;
	      	}

	      	this.dishAllocationsOrderDays["Monday"]=(this.categoryFilter(1,this.dishAllocationsWeekResponse, this.categoryForFilter));
	      	if(_.isEmpty(this.dishAllocationsOrderDays["Monday"])){
	      		console.log("this.dishAllocationsOrderDays['Monday'].length==0")
	      		this.isEmptyMonday=true;
	      	}

	      	this.dishAllocationsOrderDays["Tuesday"]=(this.categoryFilter(2,this.dishAllocationsWeekResponse, this.categoryForFilter));
	      	if(_.isEmpty(this.dishAllocationsOrderDays["Sunday"])){

	      		console.log("this.dishAllocationsOrderDays['Sunday'].length==0")
	      		this.isEmptyTuesday=true;
	      	}

	      	this.dishAllocationsOrderDays["Wednesday"]=(this.categoryFilter(3,this.dishAllocationsWeekResponse, this.categoryForFilter));
	      	if(_.isEmpty(this.dishAllocationsOrderDays["Wednesday"])){
	      		console.log("this.dishAllocationsOrderDays['Wednesday'].length==0")
	      	}
	      	this.dishAllocationsOrderDays["Thursday"]=(this.categoryFilter(4,this.dishAllocationsWeekResponse, this.categoryForFilter));
	      	if(_.isEmpty(this.dishAllocationsOrderDays["Thursday"])){
	      		console.log("this.dishAllocationsOrderDays['Thursday'].length==0")
	      		this.isEmptyThursday=true;
	      	}
	      	this.dishAllocationsOrderDays["Friday"]=(this.categoryFilter(5,this.dishAllocationsWeekResponse, this.categoryForFilter));
	      	if(_.isEmpty(this.dishAllocationsOrderDays["Friday"])){
	      		console.log("this.dishAllocationsOrderDays['Friday'].length==0")
	      		this.isEmptyFriday=true;
	      	}
	      	this.dishAllocationsOrderDays["Saturday"]=(this.categoryFilter(6,this.dishAllocationsWeekResponse, this.categoryForFilter));
	      	if(_.isEmpty(this.dishAllocationsOrderDays["Saturday"])){
	      		console.log("this.dishAllocationsOrderDays['Saturday'].length==0")
	      		this.isEmptySaturday=true;

	      	}

	      	console.log("dishAllocationsOrderDays="+JSON.stringify(this.dishAllocationsOrderDays));


	      	});

	    	 this.obsSubscription.unsubscribe();

//-----------------------GET  UserInfoOrderingModel ---------------------------------------------------------




		this.userInfoOrderingModel = this.localStorageService.get('userInfoOrderingModel');
		//console.log("FROKOCOMPONENT userInfoOrderingModel="+JSON.stringify( this.userInfoOrderingModel));
	 	this.orderingDates =  this.userInfoOrderingModel["orderingDates"];
	 	console.log("FROKOCOMPONENT this.orderingDates="+JSON.stringify( this.orderingDates));

	 	//this.filterOn = false;
 //---------------------- get ArrayDishesViewController for Order Days------------


	  	this.lastIndex = 0;
	  	this.numberElementsInRow = 3;
	  	this.arrayDishesViewForDay = new Array();

	  	for(var day in this.dishAllocationsOrderDays){
	  		for(var cat in this.dishAllocationsOrderDays[day]["categories"]){

		  	this.dishAllocationsOrderDays[day]["categories"][cat].arrayDishesViewForDay=(this.getArrayDishesViewForDayController(
		  	this.numberElementsInRow, this.dishAllocationsOrderDays[day]["categories"][cat]["dishes"].length));

	  		}


	  	}



//-------------------------------------------------------------------------------------------------------------
	  	this.orderDishId = 0;
	  	this.currentToogledDay = "Mandag";

//-------------------------------------------Order------------------------------------------------------

		this.isOrderingTimeFlag = false;
		this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'currentChildRoute') + this.shiftWeekNumber + this.departmentId;
	//	console.log("FROCO this.simpleOderModelArrayId="+this.simpleOderModelArrayId);
		this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
		if(this.simpleOderModelArray==null){
			this.simpleOderModelArray = new Array();
			this.sumOder = 0;
		}else{

			for(var oder in this.simpleOderModelArray){
	  		 	this.sumOder += this.simpleOderModelArray[oder].sumOder;
	  		  	}
		}


	 	console.log("this.isOrderingTimeFlag=" +this.isOrderingTime(this.userInfoOrderingModel, this.categoryForFilter));


	 */



	 	}
//-----------------------------------------------------------------------------------------------------
//*****************************************************************************************************
//------------------------------- Component Internal Methods------------------------------------------
	 	ngAfterContentChecked() {

	 		if(this.needNgOninit){
	 			this.ngOnInit();
	 		}

	 		if(!environment.debugMode){
          	console.clear();
       		 }

		}
	 	 ngOnDestroy() {
	 		 var loader = document.getElementById('loader');
          	 loader.style.visibility = 'visible';
	 	 	//this.obsSubscription.unsubscribe();
	 	 }


//-----------------------------Methods--------------------------------------------------------------

/*	getDishesFromFilteredResponseByCategory(day:number, response:any[], categoryId:any){
		var dishArrForCategoty = new Array();
		var exit_loops = false;
		//console.log("In filterResponseByCategory categoryId=" + categoryId);
		for(var n=0; n < response[day].length; n++ ){
		//	console.log("In filterResponseByCategory response[0][day].length=" + response[0][day].length);
		 for (var i=0; i<response[day][n]["categories"].length; i++){
		//console.log("response[0][day][n][categories][i]="+JSON.stringify(response[0][day][n]["categories"][i]));

			for (var property in response[day][n]["categories"][i]["category"]){


				if(response[day][n]["categories"][i]["category"][property]==categoryId){

			//	console.log("Category is Found ");
				//console.log("DAY" + day);
				dishArrForCategoty = response[day][n]["categories"][i]["dishes"];

				// console.log("dishArrForCategoty" + JSON.stringify(dishArrForCategoty));
				exit_loops = true;
            	break;

				}else{
			//		console.log("Category is NotFound ");
					dishArrForCategoty = response[day][n]["categories"][0]["dishes"];
				}

			}

			if (exit_loops)
		        break;
			 }

			 if (exit_loops)
        	break;
		}
		// console.log("RETURN" + JSON.stringify(dishArrForCategoty));
		return dishArrForCategoty
	}
*/
/*	universalFilter(day:number, response:any[], category:any){
		console.log("In UniversalFilter ");
		var result=_.chain(response[day])
		.map(function(element) { return _.flatten(element)})


		//.map(function(element) { return _.where(element, {name:category}); })

		//.map(function(element) { return _.indexBy(element, 'name'); })
		//.map(function(element) { return _.compact(element)})
		.map(function(element) { return _.flatten(element)})
		.map(function(element) { return _.indexBy(element, 'name'); })

		.value();
				console.log("result="+JSON.stringify(result));

		//var resIndexBy = _.groupBy(result, category);
		//console.log("resIndexBy="+JSON.stringify(resIndexBy));
		//console.log("response.flatten="+JSON.stringify(_.flatten(response, true)));

	}
*/
	categoryFilter(day:number, response:any[], category:any){
		var dishArrForCategoty = Object.create({category:[], categories:[]});
		var exit_loops = false;
		var lowerCaseCategory = category.toLowerCase();

		console.log('In filterResponseByCategory lowerCaseCategory=' + lowerCaseCategory);
		for(var n=0; n < response[day].length; n++ ){
		//	console.log("In filterResponseByCategory response[day][n][category][name]="+ response[day][n]["category"][name]);
			if((response[day][n]['category']['name']==category) || (response[day][n]['category']['name']==lowerCaseCategory) ){
				//	console.log("In categoryFilter. Category found")

					dishArrForCategoty.category=(response[day][n]['category']);
					dishArrForCategoty.categories=(response[day][n]['categories']);
				exit_loops = true;
	            break;
			}else{
			//	console.log("In categoryFilter. Category not found")
		}

		}

		// console.log("RETURN" + JSON.stringify(dishArrForCategoty));
		return dishArrForCategoty;
	}

	arrayDishesViewForDayByCategoryFilter(day:string, dishAllocationsOrderDays:DishAllocationsOrderDays, category:any){

		var viewArrayForCategoty = new Array();
		var exit_loops = false;

	  		for(var cat in dishAllocationsOrderDays[day]['categories']){


	  			for (var property in dishAllocationsOrderDays[day]['categories'][cat]['category']){


					if(dishAllocationsOrderDays[day]['categories'][cat]['category'][property]==category){

					//console.log("Category is Found ");

					viewArrayForCategoty = dishAllocationsOrderDays[day]['categories'][cat]['arrayDishesViewForDay'];


					exit_loops = true;
	            	break;

					}else{
						//console.log("Category is NotFound ");

					}
				}

				if (exit_loops)
		        break;

	  		}

	  		return viewArrayForCategoty;

	}

	 	sub(cat:any, element:number, nameDay:string, ){

	 		//var orderDishIdTCur = nameDay + cat + element.toString();
	 			var orderDishIdTCur = this.getOrderDishId(
	 				this.localStorageService.get( 'currentChildRoute'), this.shiftWeek,
	 				nameDay, cat, element);
	 		for(var oder in this.simpleOderModelArray){
		  		if(this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur){

		  			if(this.simpleOderModelArray[oder].orderNumber!=0){
			  			this.simpleOderModelArray[oder].orderNumber-=1;
					  	if(this.simpleOderModelArray[oder].orderNumber<0){
					  		this.simpleOderModelArray[oder].orderNumber = 0;
					  	}
				  		this.simpleOderModelArray[oder].sumOder-=1;
					  	if(this.simpleOderModelArray[oder].sumOder<0){
					  		this.simpleOderModelArray[oder].sumOder = 0;
					  	}
				  }

		  		}

	  		}

	  	 	this.localStorageService.set(this.simpleOderModelArrayId, this.simpleOderModelArray);

	  		//console.log("dishAllocationsOrderDays[nameDay][0][element]="+JSON.stringify(this.dishAllocationsOrderDays[nameDay][0][element]));


	 		//this.subProductToCart(this.dishAllocationsOrderDays[nameDay]["categories"][cat]["dishes"][element],1)
	 		this.updateCartFromLocalStorage();

	 		this.sumOder-=1;
	  		if(this.sumOder<0){
	  		this.sumOder = 0;
	  		}



	  }

	add(cat:any, element:number, nameDay:string){

		var orderFound = false;

	  //	var orderDishIdTCur = nameDay + cat + element.toString();

	  	var orderDishIdTCur = this.getOrderDishId(
	  		this.localStorageService.get( 'currentChildRoute'), this.shiftWeek,
	  		nameDay, cat, element);
	  	//console.log("orderDishIdTCurNumber="+orderDishIdTCur);

	  	if(this.simpleOderModelArray.length==0){
	  		//console.log("this.simpleOderModelArray.length="+this.simpleOderModelArray.length);
	  		this.simpleOderModelArray.push(new  SimpleOderModel(
	  				orderDishIdTCur,
	  				1,
	  				1,
	  				nameDay,
	  		//		this.dishAllocationsOrderDays[nameDay][0][element]["id"]));
	  		this.dishAllocationsOrderDays[nameDay]['categories'][cat]['dishes'][element]['id'],
	  		this.dishAllocationsOrderDays[nameDay]['categories'][cat]['dishes'][element]['name'],
	  		this.dishAllocationsOrderDays[nameDay]['categories'][cat]['dishes'][element]['dishPhotos'][0]['imageUrl'],
	  		this.dishAllocationsOrderDays[nameDay]['category']['name'],
	  		this.dishAllocationsOrderDays[nameDay]['categories'][cat]['category']['name']

	  		));
	  	}else{


	  	for(var oder in this.simpleOderModelArray){
	  		if(this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur){
	  			orderFound = true;
	  		 	this.simpleOderModelArray[oder].orderNumber+=1;
	  		 	this.simpleOderModelArray[oder].sumOder+=1;

	  		}
	  	}

	  	if(!orderFound){
	  	//		console.log("Else="+element);
	  			this.simpleOderModelArray.push(new  SimpleOderModel(
	  				orderDishIdTCur,
	  				1,
	  				1,
	  				nameDay,
	  			//	this.dishAllocationsOrderDays[nameDay][0][element]["id"]));
	  			this.dishAllocationsOrderDays[nameDay]['categories'][cat]['dishes'][element]['id'],
	  			this.dishAllocationsOrderDays[nameDay]['categories'][cat]['dishes'][element]['name'],
	  			this.dishAllocationsOrderDays[nameDay]['categories'][cat]['dishes'][element]['dishPhotos'][0]['imageUrl'],
	  			this.dishAllocationsOrderDays[nameDay]['category']['name'],
	  			this.dishAllocationsOrderDays[nameDay]['categories'][cat]['category']['name']
	  			));

	  		}
	  	}



	  	this.localStorageService.set(this.simpleOderModelArrayId, this.simpleOderModelArray);

	  //this.addProductToCart(this.dishAllocationsOrderDays[nameDay]["categories"][cat]["dishes"][element]);
	  	this.updateCartFromLocalStorage();
	  	this.sumOder+=1;

	  }

	getSumOder(cat:any, element:number, nameDay:string){

		var sumOder = 0;
		//var orderDishIdTCur = nameDay + cat + element.toString();
		var orderDishIdTCur = this.getOrderDishId(
			this.localStorageService.get( 'currentChildRoute'), this.shiftWeek,
			nameDay, cat, element);
		for(var oder in this.simpleOderModelArray){
	  		if(this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur){

	  		 	sumOder=this.simpleOderModelArray[oder].sumOder;

	  		}
	  	}

	  	return sumOder;

	}
/*
	prevWeek(){
		this.shiftWeek--;
		this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'currentChildRoute'), this.shiftWeek );
		this.needNgOninit = true;
	//	this.updateCartFromLocalStorage();
	//	this.localStorageService.set(this.simpleOderModelArrayId, null);
		this.router.navigateByUrl("/minmenu/print/printselectweek");
		//this.ngOnInit();


	  }
*/
	prevMonth(){
		this.shiftMonth--;
		console.log('[prevMonth] this.shiftMonth', this.shiftMonth);
		this.localStorageService.set('shiftMonth'+ this.localStorageService.get( 'currentChildRoute'), this.shiftMonth );
		let selectedWeekYear = new SelectedWeekYear();
     	this.selectedWeekForPrintModel = new SelectedWeekForPrintModel(selectedWeekYear, selectedWeekYear, selectedWeekYear, selectedWeekYear, selectedWeekYear);
     	this.selectedVisWeekForPrintModel = new SelectedWeekForPrintModel(selectedWeekYear, selectedWeekYear, selectedWeekYear, selectedWeekYear, selectedWeekYear);

     	//let selectedWeekForPrintModelFiltered = this.filterSelectedWeekForPrintModel(JSON.parse(JSON.stringify(this.selectedWeekForPrintModel)));
        //this.localStorageService.set('selectedWeekForPrintModel'+ this.localStorageService.get( 'parentPrintRoute'), selectedWeekForPrintModelFiltered);

		this.needNgOninit = true;
	//	this.updateCartFromLocalStorage();
	//	this.localStorageService.set(this.simpleOderModelArrayId, null);
		this.router.navigateByUrl('/minmenu/print/printselectweek');
		//this.ngOnInit();


	  }
/*
	nextWeek(){
	  	this.shiftWeek++;
		this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'currentChildRoute'), this.shiftWeek );
		this.needNgOninit = true;
	//	this.updateCartFromLocalStorage();
	//	this.localStorageService.set(this.simpleOderModelArrayId, null);
		this.router.navigateByUrl("/minmenu/print/printselectweek");
		//this.ngOnInit();
	  }
*/
	nextMonth(){
	  	this.shiftMonth++;

		console.log('[nextMonth] this.shiftMonth', this.shiftMonth);
		this.localStorageService.set('shiftMonth'+ this.localStorageService.get( 'currentChildRoute'), this.shiftMonth );
		let selectedWeekYear = new SelectedWeekYear();
     	this.selectedWeekForPrintModel = new SelectedWeekForPrintModel(selectedWeekYear, selectedWeekYear, selectedWeekYear, selectedWeekYear, selectedWeekYear);
     	this.selectedVisWeekForPrintModel = new SelectedWeekForPrintModel(selectedWeekYear, selectedWeekYear, selectedWeekYear, selectedWeekYear, selectedWeekYear);

		this.needNgOninit = true;
	//	this.updateCartFromLocalStorage();
	//	this.localStorageService.set(this.simpleOderModelArrayId, null);
		this.router.navigateByUrl('/minmenu/print/printselectweek');
		//this.ngOnInit();
	  }

	getImgUrl(imageUrl:string):string{

	  //	console.log("IMAGEUrl:"+ imageUrl);
	  var imgUrl = this.dataSource.baseUrl + imageUrl
	  //	console.log("IMG:"+ imgUrl);
	 // 	return imgUrl.toString();
	 return imgUrl;
	}

/*	public getSantizeUrl(imageUrl : string) {

    //return this.sanitizer.bypassSecurityTrustUrl(this.getImgUrl(url));
   // return 'https://testapi.minmenu.com/Content/Upload/DishImages/331/496197e5-4161-4aab-bee3-7f66d5c1fe78.jpg'

	var imgUrl = this.dataSource.baseUrl + imageUrl
	//  	console.log("IMG:"+ imgUrl);
	  	return imgUrl;

	}
*/

	getTargetId(cat:any, nameDay:string, itemDish:any):string{
	//  console.log("In getTargetId cat:"+ cat);
	//  console.log("In getTargetId nameDay:"+ nameDay);
	//  console.log("In getTargetId itemDish:"+ itemDish);
	  var id ='#' + cat +nameDay + itemDish;
	//  	console.log("In getTargetId IMG:"+ id);
	  	return id.toString();
	}

	getId(cat:any, nameDay:string, itemDish:any):string{


	  var id =cat +nameDay + itemDish;
	//  	console.log("In getId IMG:"+ id);
	  	return id.toString();
	}


/*
    addProductToCart(dish: SimpleOderModel) {
        this.cart.addLine(dish);


    }
*/
   /* getOrderDishId(){
    	return this.orderDishId++;
    }
  */

  getOrderDishId(fromPage:string, shiftWeek:number, day:string, cat:number, dishPlace:number){
  		/*console.log("fromPage="+fromPage);
  		console.log("shiftWeek="+shiftWeek);
  		console.log("day="+day);
  		console.log("dishPlace="+dishPlace);*/
  		//console.log("cat="+cat);

  		var pageNumber;
  		var orderDishId:number;
  		var week = ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  		switch(fromPage){
  			case 'frokostpakker':{ pageNumber = 1;
  			//	console.log("frokostpakker");
  				break;}
  			case 'extra':{pageNumber = 2;
  			//console.log("extra");
  				break;}

  		}
  		//console.log("EEE"+this.dateService.getCurrentWeek())
  		//var w = ((this.dateService.getCurrentWeek() +shiftWeek))*100;
  		//console.log("w="+w);
  		orderDishId = pageNumber*1000000 + Number((this.dateService.getCurrentWeek() +shiftWeek))*1000 + (week.indexOf(day))*1000 + cat*100+dishPlace;
  		return orderDishId;
    }

/*
    subProductToCart(dish: SimpleOderModel, quantity: number){
     	this.cart.subLine(dish, quantity);
    }
*/
    updateCartFromLocalStorage(){

    	this.cart.updateLinesFromLocalStorage(this.shiftWeekNumber);
    }



    accept(){

   		//this.shiftWeek--;
		//this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'currentChildRoute'), this.shiftWeek );
		//this.needNgOninit = true;
		//this.updateCartFromLocalStorage();
	//	this.localStorageService.set(this.simpleOderModelArrayId, null);
		this.router.navigateByUrl('/minmenu/cart');


    }

/*    getRowsFordishesArray(numberElementsInRow:number){
    	var rowsNumber = Math.trunc(this.dishesArray.length/numberElementsInRow);
    	if(this.dishesArray.length%numberElementsInRow!=0){
    		rowsNumber++;
    	}
    	return new Array(rowsNumber);

    	///return [1, 2, 3]
    }
*/
/*    getRowsNumber(numberElementsInRow:number){
    	var rowsNumber = Math.trunc(this.dishesArray.length/numberElementsInRow);
    	if(this.dishesArray.length%numberElementsInRow!=0){
    		rowsNumber++;
    	}
    	return rowsNumber;


    }
*/
    getRowsNumberForDay(numberElementsInRow:number, dishesArraylength:number){

   		var rowsNumber = Math.trunc(dishesArraylength/numberElementsInRow);
    //	console.log("In getRowsNumberForDay rowsNumber="+rowsNumber);

  		if(dishesArraylength%numberElementsInRow!=0){
    		rowsNumber++;
    	}
    	if(rowsNumber==0){
    		rowsNumber = 1;
    	}
    	return rowsNumber;


    }



/*    getArrayDishesViewController(numberElementsInRow:number, dishesArraylength:number){

    	var rows=this.getRowsNumber(numberElementsInRow);
    	console.log("rows="+rows);


    	var arrayViewController = new Array(rows);

		for (var i = 0; i < arrayViewController.length; i++) {
		  arrayViewController[i] = new Array(numberElementsInRow);
		}
		for (var i = 0; i < arrayViewController.length; i++){
			for (var j = 0; j < arrayViewController[i].length; j++){
				arrayViewController[i][j] = -1;
			}
		}

	//	console.log("array="+JSON.stringify(arrayViewController));
    	var k = numberElementsInRow;
    	var ref = 0;
    	for(var i=0; i<rows; i++){
    		if(i == rows-1){
    			if(dishesArraylength%numberElementsInRow!=0){
    			k = dishesArraylength%numberElementsInRow;
    			console.log("k="+k);

    			arrayViewController[i].length = k;      // trim last row to the number of items remainder
    			}
    		}
    		for(var j=0; j<k; j++){

    			arrayViewController[i][j]=ref;
    			ref++;
    		}

    	}

    	console.log("array="+JSON.stringify(arrayViewController));

    return arrayViewController;
    }

	*/
    getArrayDishesViewForDayController(numberElementsInRow:number, dishesArraylength:number){

    	var rows=this.getRowsNumberForDay(numberElementsInRow, dishesArraylength);
    //	console.log("rows="+rows);

    	var arrayViewController = new Array(rows);
		for (var i = 0; i < arrayViewController.length; i++) {
		  arrayViewController[i] = new Array(numberElementsInRow);
		}
		for (var i = 0; i < arrayViewController.length; i++){
			for (var j = 0; j < arrayViewController[i].length; j++){
				arrayViewController[i][j] = -1;
			}
		}

	//	console.log("array="+JSON.stringify(arrayViewController));


    	var k = numberElementsInRow;
    	var ref = 0;
    	for(var i=0; i<rows; i++){
    		if(dishesArraylength>numberElementsInRow){
	    		if(i == rows-1){
	    			if(dishesArraylength%numberElementsInRow!=0){
	    			k = dishesArraylength%numberElementsInRow;
	    			console.log('k='+k);

	    			arrayViewController[i].length = k;      // trim last row to the number of items remainder
	    			}
	    		}
    		}else{
    			k = dishesArraylength;
    			arrayViewController[i].length = k;
    		}

    		for(var j=0; j<k; j++){

    			arrayViewController[i][j]=ref;
    			ref++;
    		}

    	}

    //	console.log("array="+JSON.stringify(arrayViewController));




     return arrayViewController;
    }

    toggleMonday(){
    this.toggledMonday = true;
	this.toggledWednesday = false;
	this.toggledFriday = false;
	//console.log("Monday Wednesday Friday" + this.toggledMonday+this.toggledWednesday+this.toggledFriday);

    }

     toggleWednesday(){
     	this.toggledMonday = false;
		this.toggledWednesday = true;
		this.toggledFriday = false;
	//	console.log("Monday Wednesday Friday" + this.toggledMonday+this.toggledWednesday+this.toggledFriday);
     }


     toggleFriday(){
     	this.toggledMonday = false;
		this.toggledWednesday = false;
		this.toggledFriday = true;
	//	console.log("Monday Wednesday Friday" + this.toggledMonday+this.toggledWednesday+this.toggledFriday);

     }


     isDayForOrder(orderingDates:any[], nameDay:string,  shiftWeek:number){
     //	if(this.filterOn){
     	var isDayForOrderFlag = false;
     	var date = this.dateService.getISODateYYYMMDDforDay(nameDay,  shiftWeek);
     //	console.log("DATE ="+date);
     	for(var day in this.orderingDates){
     		if(date==this.orderingDates[day]){
     			isDayForOrderFlag = true;
     			}
     		}

     	return isDayForOrderFlag;

     //return true;


     }

     filterToogle(){
     	this.filterOn =!this.filterOn;
     }

     isOrderingTime(userInfoOrderingModel:any[], categoryForFilter:string){
     //	console.log("In isOrderingTime");
     	var startServingTime = '';
     	var endServingTime = '';
     	var currentTime=0;
     	for(var userCategory in userInfoOrderingModel['userCategories']){
     //		console.log("In isOrderingTime cat="+userInfoOrderingModel["userCategories"][userCategory]["category"]["name"]);
     		if(userInfoOrderingModel['userCategories'][userCategory]['category']['name']==categoryForFilter){

     			startServingTime = userInfoOrderingModel['userCategories'][userCategory]['startServingTime'];
     		//	var dateStart =  this.dateService.getDateFromTimeHHmmss(startServingTime);

     		//	console.log("IsstartServingTime=" + this.dateService.returnCurrentDateForTimeHHmmss(dateStart));
     			endServingTime = userInfoOrderingModel['userCategories'][userCategory]['endServingTime'];

     		//	var dateEnd =  this.dateService.getDateFromTimeHHmmss(endServingTime);

     		//	console.log("IsendServingTime=" + this.dateService.returnCurrentDateForTimeHHmmss(dateEnd));

     		//	currentTime = this.dateService.getCurrentTime();
     		//	console.log("currentTime=" + currentTime);
     		//	if(this.dateService.returnCurrentDateForTimeHHmmss(dateStart)<=currentTime &&
     		//	currentTime<=this.dateService.returnCurrentDateForTimeHHmmss(dateEnd)){
     			if( this.dateService.isCurrentTimeBetween(startServingTime, endServingTime)){

     			return this.isOrderingTimeFlag=true;
     		//	}else return this.isOrderingTimeFlag=false;
     			}else return this.isOrderingTimeFlag=true;

     		}
     	}



     }



/*    open(content) {
		    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
		      this.closeResult = `Closed with: ${result}`;
		    }, (reason) => {
		    //  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		    });
  		}
*/
  	openVerticallyCentered(content) {
    	this.modalService.open(content, { centered: true} );
  		}

  	private getDismissReason(reason: any): string {
		    if (reason === ModalDismissReasons.ESC) {
		      return 'by pressing ESC';
		    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		      return 'by clicking on a backdrop';
		    } else {
		      return  `with: ${reason}`;
		    }
	  }



	  Sunday:	any[];
    Monday: any[];
    Tuesday:any[];
    Wednesday: any[];
    Thursday:any[];
    Friday:any[];
    Saturday:any[];

selectPrintDay(day:string){

	console.log('In selectPrintDay');

      	//this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'currentChildRoute'), this.shiftWeek );
       	//this.localStorageService.set('shiftWeek'+ "bulkorder/editweekplan", this.shiftWeek );
       	//this.localStorageService.set('shiftWeek'+ "print/printweekplan", this.shiftWeek );
       	//this.localStorageService.set('shiftWeek'+ 'bulkorder/printweekplan/printchoosebulk', this.shiftWeek);
		this.localStorageService.set( 'toggledChooseDayBulkOrder', day);
		//this.router.navigateByUrl("/minmenu/bulkorder/printweekplan/printchoosebulk");
     	this.navigationService.goToLinkInNewTab('minmenu/bulkorder/printweekplan/printchoosebulk');

	}

filterSelectedWeekForPrintModel(selectedWeekForPrintModel:SelectedWeekForPrintModel){

	console.log('[filterSelectedWeekForPrintModel] selectedWeekForPrintModel', selectedWeekForPrintModel);
	let selectedWeekForPrintModelArr = [];
	for(let item in selectedWeekForPrintModel){
		if(selectedWeekForPrintModel[item]['week']!==undefined){
			selectedWeekForPrintModelArr.push(selectedWeekForPrintModel[item])
		}
	}

	console.log('[filterSelectedWeekForPrintModel] selectedWeekForPrintModelArr', selectedWeekForPrintModelArr);
	return selectedWeekForPrintModelArr;
}

printSelectedWeek(day){



      	//let currentShiftWeek = this.getShiftWeekForWeekNumber(this.selectedWeekForPrint);

		//console.log("[printSelectedWeek] currentShiftWeek", currentShiftWeek);
		//this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'parentPrintRoute'), currentShiftWeek);
        let selectedWeekForPrintModelFiltered = this.filterSelectedWeekForPrintModel(JSON.parse(JSON.stringify(this.selectedWeekForPrintModel)));
        this.localStorageService.set('selectedWeekForPrintModel'+ this.localStorageService.get( 'parentPrintRoute'), selectedWeekForPrintModelFiltered);

		this.localStorageService.set( 'toggledChooseDayBulkOrder', day);
		//this.localStorageService.set( 'toggledChooseDayBulkOrder', 'Week');
		//this.router.navigateByUrl("/minmenu/bulkorder/printweekplan/printchoosebulk");
     	this.navigationService.goToLinkInNewTab('minmenu/bulkorder/printweekplan/printchoosebulk');

	}

printSelectedWeekVis(day, shiftWeekNumber:number, selectedWeek:string){

		this.selectedForPrintVis(true, shiftWeekNumber, selectedWeek);

      	//let currentShiftWeek = this.getShiftWeekForWeekNumber(this.selectedWeekForPrint);

		//console.log("[printSelectedWeekVis] currentShiftWeek", currentShiftWeek);
		//this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'parentPrintRoute'), currentShiftWeek);


        let selectedVisWeekForPrintModelFiltered = this.filterSelectedWeekForPrintModel(JSON.parse(JSON.stringify(this.selectedVisWeekForPrintModel)));


        this.localStorageService.set('selectedVisWeekForPrintModel'+ this.localStorageService.get( 'parentPrintRoute'), selectedVisWeekForPrintModelFiltered);

		this.localStorageService.set( 'toggledChooseDayBulkOrder', day);
		//this.localStorageService.set( 'toggledChooseDayBulkOrder', 'Week');
		//this.router.navigateByUrl("/minmenu/bulkorder/printweekplan/printchoosebulk");
     	//let selectedWeekYear = new SelectedWeekYear();
     	//this.selectedVisWeekForPrintModel = new SelectedWeekForPrintModel(selectedWeekYear, selectedWeekYear, selectedWeekYear, selectedWeekYear, selectedWeekYear);

     	//this.selectedWeekForPrintModel = new SelectedWeekForPrintModel(new SelectedWeekYear(), new SelectedWeekYear(), new SelectedWeekYear(), new SelectedWeekYear(), new SelectedWeekYear());
     	this.selectedVisWeekForPrintModel = new SelectedWeekForPrintModel(new SelectedWeekYear(), new SelectedWeekYear(), new SelectedWeekYear(), new SelectedWeekYear(), new SelectedWeekYear());

		console.log('this.selectedVisWeekForPrintModel', this.selectedVisWeekForPrintModel);
     	this.navigationService.goToLinkInNewTab('minmenu/bulkorder/printweekplan/printchoosebulk');

	  	//this.selectedForPrint(false, shiftWeekNumber, selectedWeek); // return to previos state

	}

selectedForPrint(eventChecked:any, shiftWeekNumber:number, selectedWeek:string){

console.log('[selectedForPrint] event', event);
console.log('[selectedForPrint] eventchecked ', eventChecked );
console.log('[selectedForPrint] this.selectedWeekForPrintModel', JSON.stringify(this.selectedWeekForPrintModel));
	if(eventChecked == true){


			console.log('[selectedForPrint] this.selectedWeekForPrintModel[selectedWeek][\'week\']', this.selectedWeekForPrintModel[selectedWeek]['week']);
			this.selectedWeekForPrintModel[selectedWeek]['week'] = Number(shiftWeekNumber);
			this.selectedWeekForPrintModel[selectedWeek]['yearYYYY'] = this.getYearForWeekNumber(shiftWeekNumber);


			console.log('[selectedForPrint] True this.selectedWeekForPrintModel[selectedWeek]', this.selectedWeekForPrintModel[selectedWeek]);

			let selectedWeekForPrintModelFiltered = this.filterSelectedWeekForPrintModel(JSON.parse(JSON.stringify(this.selectedWeekForPrintModel)));
        	this.localStorageService.set('selectedWeekForPrintModel'+ this.localStorageService.get( 'parentPrintRoute'), selectedWeekForPrintModelFiltered);

		}else{


			this.selectedWeekForPrintModel[selectedWeek]['week'] = undefined;
			this.selectedWeekForPrintModel[selectedWeek]['yearYYYY'] = undefined;

			console.log('[selectedForPrint] False this.selectedWeekForPrintModel[selectedWeek]', this.selectedWeekForPrintModel[selectedWeek]);
			let selectedWeekForPrintModelFiltered = this.filterSelectedWeekForPrintModel(JSON.parse(JSON.stringify(this.selectedWeekForPrintModel)));
        	this.localStorageService.set('selectedWeekForPrintModel'+ this.localStorageService.get( 'parentPrintRoute'), selectedWeekForPrintModelFiltered);

		}

	console.debug('[selectedForPrint] this.selectedWeekForPrint shiftWeekNumber', this.selectedWeekForPrintModel);
}

selectedMainDishOnly(eventChecked:any, shiftWeekNumber:number, selectedWeek:string){

console.log('[selectedMainDishOnly] shiftWeekNumber', shiftWeekNumber);
console.log('[selectedMainDishOnly] selectedWeek', selectedWeek);
console.log('[selectedMainDishOnly] eventChecked', eventChecked);
console.log('[selectedMainDishOnly] this.selectedWeekForPrintModel', this.selectedWeekForPrintModel);
	//if(event.checked == true){
	  if(eventChecked == true){

			//this.selectedWeekForPrintModel[selectedWeek] = this.getShiftWeekForWeekNumber(shiftWeekNumber, true);
			this.selectedWeekForPrintModel[selectedWeek]['mainDishOnly'] = true;
			this.selectedVisWeekForPrintModel[selectedWeek]['mainDishOnly'] = true;
console.log('[selectedMainDishOnly] True selectedWeekForPrintModel[selectedWeek][\'mainDishOnly\']', this.selectedWeekForPrintModel[selectedWeek]['mainDishOnly']);
		}else{

			//this.selectedWeekForPrintModel[selectedWeek] = undefined;
			this.selectedWeekForPrintModel[selectedWeek]['mainDishOnly'] = false;
			this.selectedVisWeekForPrintModel[selectedWeek]['mainDishOnly'] = false;
			console.log('[selectedMainDishOnly] False selectedWeekForPrintModel[selectedWeek][\'mainDishOnly\']', this.selectedWeekForPrintModel[selectedWeek]['mainDishOnly']);

		}
	//let selectedWeekForPrintModelFiltered = this.filterSelectedWeekForPrintModel(JSON.parse(JSON.stringify(this.selectedWeekForPrintModel)));
    //this.localStorageService.set('selectedWeekForPrintModel'+ this.localStorageService.get( 'parentPrintRoute'), selectedWeekForPrintModelFiltered);

	console.debug('[selectedMainDishOnly] this.selectedWeekForPrint shiftWeekNumber', this.selectedWeekForPrintModel);

}

selectedForPrintVis(eventChecked:any, shiftWeekNumber:number, selectedWeek:string){

console.log('[selectedForPrintVis] eventChecked', eventChecked);
	//if(event.checked == true){
	  if(eventChecked == true){

			//this.selectedWeekForPrintModel[selectedWeek] = this.getShiftWeekForWeekNumber(shiftWeekNumber);

			/*let isMainDishOnly = this.selectedWeekForPrintModel[selectedWeek]['isMainDishOnly'];
			this.selectedWeekForPrintModel[selectedWeek] = this.getShiftWeekYearForWeekNumber(shiftWeekNumber, isMainDishOnly);
			*/

      console.log('[selectedForPrintVis] BEFORE this.selectedWeekForPrintModel', this.selectedWeekForPrintModel);
			this.selectedVisWeekForPrintModel[selectedWeek]['week'] = Number(shiftWeekNumber);
			this.selectedVisWeekForPrintModel[selectedWeek]['yearYYYY'] = this.getYearForWeekNumber(shiftWeekNumber);

      console.log('[selectedForPrintVis] AFTER this.selectedWeekForPrintModel', this.selectedWeekForPrintModel);
			/*
			let isMainDishOnly = this.isMainDishOnly(shiftWeekNumber, selectedWeek);
       		console.log("[selectedForPrintVis] isMainDishOnly", isMainDishOnly);
        	this.selectedVisWeekForPrintModel[selectedWeek]['mainDishOnly']=isMainDishOnly;
        	*/
			let selectedVisWeekForPrintModelFiltered = this.filterSelectedWeekForPrintModel(JSON.parse(JSON.stringify(this.selectedVisWeekForPrintModel)));
        	this.localStorageService.set('selectedVisWeekForPrintModel'+ this.localStorageService.get( 'parentPrintRoute'), selectedVisWeekForPrintModelFiltered);

		}else{

			/*
			this.selectedVisWeekForPrintModel[selectedWeek] = undefined;
			let selectedVisWeekForPrintModelFiltered = this.filterSelectedWeekForPrintModel(JSON.parse(JSON.stringify(this.selectedVisWeekForPrintModel)));
        	this.localStorageService.set('selectedVisWeekForPrintModel'+ this.localStorageService.get( 'parentPrintRoute'), selectedVisWeekForPrintModelFiltered);
        	*/
        	this.selectedVisWeekForPrintModel[selectedWeek]['week'] = undefined;
			this.selectedVisWeekForPrintModel[selectedWeek]['yearYYYY'] = undefined;

		}
	console.debug('[selectedForPrintVis] this.selectedWeekForPrint ', this.selectedVisWeekForPrintModel);
}

isProductSelectedForPrint(shiftWeekNumber:number, selectedWeek:string){
	//	if(this.selectedWeekForPrint==shiftWeekNumber){
		if((this.selectedWeekForPrintModel[selectedWeek]!==undefined) && (this.selectedWeekForPrintModel[selectedWeek]['week']==shiftWeekNumber)){
			return true;
		}else{
			return false;
		}

	}
isMainDishOnly(shiftWeekNumber:number, selectedWeek:string){
	console.log('[isMainDishOnly] shiftWeekNumber', shiftWeekNumber);
	console.log('[isMainDishOnly] selectedWeek', selectedWeek);
	console.log('[isMainDishOnly] this.selectedWeekForPrintModel', this.selectedWeekForPrintModel);

	//	if(this.selectedWeekForPrint==shiftWeekNumber){
		if(this.selectedWeekForPrintModel[selectedWeek]['mainDishOnly']==true){

			return true;
		}else{
			return false;
		}

	}

 getShiftWeekForWeekNumber(uge_x_number:number){

console.log('[getShiftWeekForWeekNumber]  shiftWeekNumber', uge_x_number);

console.log('[getShiftWeekForWeekNumber] this.shiftMonth', this.shiftMonth);

console.log('[getShiftWeekForWeekNumber] this.currentWeek', this.currentWeek);
//console.log("[getShiftWeekForWeekNumber] Delta", (Number(shiftedWeekNumber) - Number(this.currentWeek)));
// 	return (Number(shiftedWeekNumber) - Number(this.currentWeek))
/*let curShiftForWeek = 0;
	if((uge_x_number > this.currentWeek)&&(this.shiftMonth > 0)){
		curShiftForWeek = uge_x_number - this.currentWeek;

	}else if((uge_x_number > this.currentWeek)&&(this.shiftMonth == 0)){
		curShiftForWeek = uge_x_number - 1;
	}else if((uge_x_number > this.currentWeek)&&(this.shiftMonth < 0)){
		curShiftForWeek = this.currentWeek - uge_x_number;
	}
 */
return Number(uge_x_number);
 }


/*
 getShiftWeekYearForWeekNumber(uge_x_number:number, isMainDishOnly: boolean){

console.log("[getShiftWeekForWeekNumber]  shiftWeekNumber", uge_x_number);

console.log("[getShiftWeekForWeekNumber] this.shiftMonth", this.shiftMonth);

console.log("[getShiftWeekForWeekNumber] this.currentWeek", this.currentWeek);
let curshiftMonth = this.shiftMonth;
if((this.shiftMonthName == 'December') && (uge_x_number == 1)){
	curshiftMonth = curshiftMonth + 1;
}
let curYear = this.dateService.getYearYYYYforShiftMonth(curshiftMonth);
console.log("[getShiftWeekForWeekNumber] curYear", curYear);
let selectedWeekYear = new SelectedWeekYear();
selectedWeekYear.week = Number(uge_x_number);
selectedWeekYear.yearYYYY = curYear;
selectedWeekYear.mainDishOnly = isMainDishOnly
console.log("[getShiftWeekForWeekNumber] selectedWeekYear", selectedWeekYear);
return selectedWeekYear;
 } */
getYearForWeekNumber(uge_x_number:number){

  console.log('[getShiftWeekForWeekNumber]  shiftWeekNumber', uge_x_number);

  console.log('[getShiftWeekForWeekNumber] this.shiftMonth', this.shiftMonth);

  console.log('[getShiftWeekForWeekNumber] this.currentWeek', this.currentWeek);
  let curshiftMonth = this.shiftMonth;
  if((this.shiftMonthName == 'December') && (uge_x_number == 1)){
    //curshiftMonth = curshiftMonth + 1;
  }
  if (this.shiftMonthName == 'January' && uge_x_number == 53) {
    curshiftMonth = 0;
  }
  let curYear = this.dateService.getYearYYYYforShiftMonth(curshiftMonth);
  console.log('[getShiftWeekForWeekNumber] curYear', curYear);
  /*let selectedWeekYear = new SelectedWeekYear();
  selectedWeekYear.week = Number(uge_x_number);
  selectedWeekYear.yearYYYY = curYear;
  selectedWeekYear.mainDishOnly = isMainDishOnly
  console.log("[getShiftWeekForWeekNumber] selectedWeekYear", selectedWeekYear);
  return selectedWeekYear;*/
    return curYear;
 }


 translateDayToDanishsShort(day:string){

		return this.localeDaDKService.translateDayToDanishsShort(day);
  	}

  	getMontInDanish(DDMMM:string){
  		let currentMonth = this.dateService.getMonthForISODateDDMMM(DDMMM);
  		return this.translateMonthToDanish(currentMonth);
  	}
  	translateMonthToDanish(month:string){

		return this.localeDaDKService.translateMonthToDanish(month);
  	}

}




