import { Injectable } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { LocalStorageService } from '../../service/localStorage.service';
import { DataShareService } from '../../service/dataShare.service';
import { Router } from '@angular/router';
import { NavigationService } from '../../service/navigation.service';
@Component({
  selector: "print-root",
  templateUrl: 'print.component.html',
  styleUrls: ['print.component.css'],
})
export class PrintComponent {
  constructor(
    private localStorageService: LocalStorageService,
    private dataShareService: DataShareService,
    private router: Router,
    public navigationService: NavigationService
  ) {
    this.localStorageService.set('currentChildRoute', 'print');
    this.localStorageService.set('fromPage', 'print');
  }

  ngOnDestroy() {
    //this.dataShareService.dispayIfPageForPrinting = 'none';
  }

  /*  toPrintweekplan(){

	  	 console.log("this.dataShareService.goToPageForPrinting", this.dataShareService.dispayIfPageForPrinting);
	  	 //this.router.navigateByUrl("/minmenu/print/printweekplan");

	  }*/

  goToprintPrintweekplan() {
    //this.navigationService.goToLinkInNewTab('minmenu/print/printweekplan');
    this.router.navigateByUrl('/minmenu/print/printselectweek');
  }

  goToPrinttodaysmenu() {
    this.navigationService.goToLinkInNewTab('minmenu/print/printtodaysmenu');
  }

  goToPrintselectbulk() {
    //this.navigationService.goToLinkInNewTab('minmenu/print/printselectbulk');
    this.router.navigateByUrl('minmenu/print/printselectbulk');
  }
}
