import { UserIdentityModel } from "./userIdentityModel.model";


export class AnswerModel {
    constructor(
            public	id:number,
			public	message:string,
			public	isNew?:boolean,
			public	isSolving?:boolean,
			public	createdOnUtc?:Date,
			public	CreatedBy?:UserIdentityModel
			
           	) { }
}
