import { Injectable } from '@angular/core';
import {
  Component,
  Inject,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  ActivationEnd,
  Event,
  NavigationCancel,
  NavigationError,
  NavigationStart,
} from '@angular/router';
import { LocalStorageService } from '../../service/localStorage.service';
import { Cart } from '../../cart/cart-model/cart.model';
import { DateService } from '../../service/date.service';
import { AllocatedDishModel } from '../../model/allocatedDishModel.model';
import { OrderDish } from '../../model/orderDish.model';
import { DishAllocationWeekDataBase } from '../../model/dishAllocationWeek.database';
import { DishRepository } from '../../repository/dish.repository';
import { RestDataSource } from '../../repository/rest.datasource';
import { ModelResolver } from '../../resolver/model.resolver';
import { timer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { of, from } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/concatAll';
import 'rxjs/add/operator/concatMap';
import { map, finalize } from 'rxjs/operators';
import { _ } from 'underscore';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { SafePipeModule } from 'safe-pipe';
import { AuthService } from '../../service/auth.service';
import * as moment from 'moment';
import { ComponentCanDeactivate } from '../../guard/unsaved.guard';
import { DataShareService } from '../../service/dataShare.service';
import { LocaleDaDKService } from '../../service/locale-da-dk.service';
import { NavigationService } from '../../service/navigation.service';
import { environment } from './../../../environments/environment';
import { DishAllocationsOrderDays } from '../../model/dishAllocationsOrderDays.model';
import { SimpleOderModel } from '../../model/simpleOder.model';
import { MessageModalComponent } from 'src/modal/message-modal/message-modal.component';
import { GuestChefModel } from 'src/app/model/guestChefModel';
import { GuestChefService } from 'src/app/service/guest-chef.service';
import { ThrowStmt } from '@angular/compiler';



class SimpleBulkOderModel {
  productNr: number;
  day: string;
  date: Date;
  constructor(productNr: number, day: string, date: Date) {
    this.productNr = productNr;
    this.day = day;
    this.date = date;
  }
}

class DeliveryDaysOrdered {
  Tuesday: boolean;
  Wednesday: boolean;
  Thursday: boolean;
  Friday: boolean;
  Saturday: boolean;
  Sunday: boolean;
  Monday: boolean;

  constructor(
    Tuesday: boolean,
    Wednesday: boolean,
    Thursday: boolean,
    Friday: boolean,
    Saturday: boolean,
    Sunday: boolean,
    Monday: boolean
  ) {
    this.Tuesday = Tuesday;
    this.Wednesday = Wednesday;
    this.Thursday = Thursday;
    this.Friday = Friday;
    this.Saturday = Saturday;
    this.Sunday = Sunday;
    this.Monday = Monday;
  }
}

class DeliveryDaysNumberOrdered {
  Tuesday: number;
  Wednesday: number;
  Thursday: number;
  Friday: number;
  Saturday: number;
  Sunday: number;
  Monday: number;

  constructor(
    Tuesday: number,
    Wednesday: number,
    Thursday: number,
    Friday: number,
    Saturday: number,
    Sunday: number,
    Monday: number
  ) {
    this.Tuesday = Tuesday;

    this.Thursday = Thursday;

    this.Saturday = Saturday;
  }
}

@Component({
  selector: "viewweekplan-root",
  templateUrl: 'viewweekplan.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['viewweekplan.component.css'],
})
export class ViewweekplanComponent implements ComponentCanDeactivate {
  public sumOder: number;

  public weeks: string[];
  public dishAllocationsWeekResponse: any[] = [];
  public currentWeek: string;
  public lastIndex: number;
  public obsSubscription: Subscription;
  public numberElementsInRow: number;
  public arrayDishesViewForDay: any[];
  public orderDishId: number;
  public orderDaysLeftInWeekArray: string[];
  public dishAllocationsOrderDays: DishAllocationsOrderDays;
  public currentToogledDay: string;
  public toggledMonday: boolean;
  public toggledWednesday: boolean;
  public toggledFriday: boolean;
  public toggledTuesday: boolean;
  public toggledThursday: boolean;
  public toggledSaturday: boolean;
  public SundayDate: string;
  public SundayNextWeekDate: string;
  public MondayDate: string;
  public MondayNextWeekDate: string;
  public TuesdayDate: string;
  public WednesdayDate: string;
  public ThursdayDate: string;
  public FridayDate: string;
  public SaturdayDate: string;
  public shiftWeekNumber: string;
  public shiftWeek: number;
  public needNgOninit: boolean;
  public simpleOderModelArray: SimpleOderModel[];
  public simpleBulkOderModelArray: SimpleBulkOderModel[];
  public imgUrl: string;
  public tempArray: any[] = [];
  public categoryForFilter: any;
  public simpleOderModelArrayId: string;
  public orderingDates: any[];
  public userInfoOrderingModel: any[] = [];
  public currentDayNumber: number;
  public filterOn: boolean;
  public isOrderingTimeFlag: boolean;
  public isEmptyMonday: boolean;
  public isEmptyTuesday: boolean;
  public isEmptyWednesday: boolean;
  public isEmptyThursday: boolean;
  public isEmptyFriday: boolean;
  public isEmptySaturday: boolean;
  public isEmptySunday: boolean;
  private modalBtnFlag: string;
  public modalBtnBackgroundColor: string;
  public modalBtnTextColor: string;
  public modalBtnTextWeight: string;
  public ifWeHaveCategory: boolean;
  private needUnsubscribe: boolean;
  private departmentId: number;
  public closeResult: string;
  public isQuantitiesChanged: boolean = false;
  public continueSwitching: boolean = false;
  public nextDay: string;
  public isLeavingPage: boolean = false;
  public groupsArr: string[];
  public groupsArrText: string;
  public templateDays: string[];
  public servingDays: string[];
  public menuNr: number;
  private delay: number;
  public isSaved: boolean;
  public nextShiftWeek: number;
  public changedShiftWeek: boolean;
  public maxRetries: number;
  public deliveryDaysOrdered: DeliveryDaysOrdered;
  public deliveryDaysNumberOrdered: DeliveryDaysNumberOrdered;
  public deliveryDaysOrderedId: string;
  public deliveryDaysNumberOrderedId: string;
  public numberOfSelectedServDays: number;
  public currentServingDayForDietTypeNr30Arr: string[];
  public curNumberOfServingDayForDietTypeNr30: number;
  public previosPrNr: number;
  public previosLine: number;
  public previosLine2: number;
  public numberOfDishesForDietTypeNr30: number;
  public reqestInProgress: boolean = false;
  public guestChefModel: GuestChefModel;
  public customerNr: number
  public GuestChefDishesObj:any = []
  private user:any
  private company:any
  private currentGuestChefDate:any
  private isItallowedToChangeGuestChefDishes:boolean=true
  public loader:boolean = false

  @ViewChild(MessageModalComponent, { static: false })
  messageModalWindowh: MessageModalComponent;
  @ViewChild('contentPopUp', { static: false }) contentPopUp: ElementRef;
  @ViewChild('modalGuestChefSelectDish', { static: false }) modalGuestChefSelectDish: ElementRef;
  @ViewChild('contentInfoPopUp', { static: false }) contentInfoPopUp: ElementRef;

  constructor(
    private dataSource: RestDataSource,
    private localStorageService: LocalStorageService,
    private cart: Cart,
    private router: Router,
    private dateService: DateService,
    private dishRepository: DishRepository,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private auth: AuthService,
    public dataShareService: DataShareService,
    public navigationService: NavigationService,
    private localeDaDKService: LocaleDaDKService,
    public guestChefService: GuestChefService
  ) {
    this.localStorageService.set('currentChildRoute', 'bulkorder/viewweekplan');
    this.localStorageService.set('parentPrintRoute', 'bulkorder/viewweekplan');
    this.localStorageService.set('fromPage', 'bulkorder');
    // this.localStorageService.set( 'toggledDayBulk', 'Tuesday');
    this.departmentId = this.localStorageService.get('currentdepartmentId');
    this.user = this.localStorageService.get('user');
    this.company = this.localStorageService.get('company');

    this.needUnsubscribe = false;
    this.dishAllocationsOrderDays = new DishAllocationsOrderDays();

    this.dishAllocationsWeekResponse = [];
  }

  ngOnInit(): void {
    this.ifWeHaveCategory = false;
    this.needNgOninit = false;
    this.maxRetries = 2;
    this.currentServingDayForDietTypeNr30Arr = [];
    this.curNumberOfServingDayForDietTypeNr30 = 1;
    this.reqestInProgress = false;
    // ----------------------------------------------------------------------------------
    let toggledDay = this.localStorageService.get('toggledDayBulk');
    this.initTabs(toggledDay);

    this.sumOder = this.localStorageService.get('sumOder');
    // ---------------------Date------------------------------------
    this.initDate();

    // --------------------- GET dishAllocations for All days in Week-----------------------------
    if (!this.needUnsubscribe) {
      this.obsSubscription = this.activatedRoute.data.subscribe((data) => {
        console.log('data')
        console.log(data)
        this.dishAllocationsWeekResponse = [];
        this.dishAllocationsOrderDays = new DishAllocationsOrderDays();
        this.dishAllocationsWeekResponse = data['dishes'];
       
       // console.log(this.dishAllocationsWeekResponse)
       // console.log(
       //   'dishAllocationsWeekResponse=' +
       //   JSON.stringify(this.dishAllocationsWeekResponse)
       // );
        this.deliveryDaysNumberOrdered = new DeliveryDaysNumberOrdered(
          0,
          0,
          0,
          0,
          0,
          0,
          0
        );
        this.numberOfDishesForDietTypeNr30 = 0;

        this.deliveryDaysNumberOrderedId =
          'deliveryDaysNumberOrdered' +
          this.localStorageService.get('currentChildRoute') +
          this.shiftWeekNumber +
          this.departmentId;
        // ---------------------------------------------------------------------------------------------
        if (environment.setAllDeliveryDaysOrderBug == true) {
          this.initServingDaysOrdered(this.dishAllocationsWeekResponse);

          let toggledDay1 = this.localStorageService.get('toggledDayBulk');
          this.mergeDeliveryDaysResponses(toggledDay1, this.dishAllocationsWeekResponse);

        }
        // ---------------------------------------------------------------------------------------
        this.departmentId = this.localStorageService.get('currentdepartmentId');
        // ------------------------------------------Order Changed-----------------------------------------------
        this.initDeliveryDaysOrdered();

        // -------------------------------------------Order------------------------------------------------------

        this.isOrderingTimeFlag = false;
        this.simpleOderModelArrayId =
          'simpleOderModelArray' +
          this.localStorageService.get('currentChildRoute') +
          this.shiftWeekNumber +
          this.departmentId;
        this.simpleOderModelArray = this.localStorageService.get(
          this.simpleOderModelArrayId
        );
        if (!this.simpleOderModelArray) {
          this.simpleOderModelArray = new Array();
          this.sumOder = 0;
        } else {
          for (var oder in this.simpleOderModelArray) {
            this.sumOder += this.simpleOderModelArray[oder].sumOder;
          }
        }

        if (this.ifWeHaveCategory) {
          this.categoryFilterAndSetEmptyDays();
        } else {
          let toggledDay1 = this.localStorageService.get('toggledDayBulk');
          this.initDishAllocationsWeekResponse(toggledDay1, this.dishAllocationsWeekResponse);
          console.log(
            'dishAllocationsOrderDays1=' +
            JSON.stringify(this.dishAllocationsOrderDays)
          );
        }

        // ---------------------- get ArrayDishesViewController for Order Days------------

        let toggledDay = this.localStorageService.get('toggledDayBulk');
        this.lastIndex = 0;
        this.numberElementsInRow = 3;
        this.arrayDishesViewForDay = new Array();

        if (this.ifWeHaveCategory) {
          console.log('this.ifWeHaveCategory', this.ifWeHaveCategory);

          for (var day in this.dishAllocationsOrderDays) {
            for (var cat in this.dishAllocationsOrderDays[day]['categories']) {
              this.dishAllocationsOrderDays[day]['categories'][
                cat
              ].arrayDishesViewForDay = this.getArrayDishesViewForDayController(
                this.numberElementsInRow,
                this.dishAllocationsOrderDays[day]['categories'][cat]['dishes']
                  .length
              );
            }
          }
        } else {
          console.log('toggledDayARV', toggledDay);
          this.createArrayDishesViewForDay(toggledDay);
        }
        this.filterForDishWithServingDateDay(toggledDay);

        // ----------------------------------------------------------------------------------

        this.needNgOninit = true;
        this.needUnsubscribe = true;

        console.log(
          'this.dishAllocationsOrderDays22=' +
          JSON.stringify(this.dishAllocationsOrderDays)
        );
      });
    }

    // -----------------------GET  UserInfoOrderingModel ---------------------------------------------------------

    this.userInfoOrderingModel = this.localStorageService.get(
      'userInfoOrderingModel'
    );
    // console.log("FROKOCOMPONENT userInfoOrderingModel="+JSON.stringify( this.userInfoOrderingModel));
    this.orderingDates = this.userInfoOrderingModel['orderingDates'];
    console.log(
      'FROKOCOMPONENT this.orderingDates=' + JSON.stringify(this.orderingDates)
    );

    // -------------------------------------------------------------------------------------------------------------
    this.orderDishId = 0;
    this.currentToogledDay = 'Tuesday';
    console.log(
      'this.isOrderingTimeFlag=' +
      this.isOrderingTime(this.userInfoOrderingModel, this.categoryForFilter)
    );

    // ----------------------Modal Window init -----------------------

    this.modalBtnFlag = 'dishInfo';
    this.modalBtnBackgroundColor = '#FFFFFF';
    this.modalBtnTextColor = '#000000';
    this.modalBtnTextWeight = 'bold';
    // ----------------------------Modal Done----------------------------------------
    this.delay = 2000;
    // ---------------------------Info Pop Up-------------------------------------------
    this.groupsArr = [];
    this.groupsArrText = '';

    if (!environment.debugMode) {
      console.clear();
    }
  }
  // -----------------------------------------------------------------------------------------------------
  // *****************************************************************************************************
  // ------------------------------- Component Internal Methods------------------------------------------

  ngAfterContentChecked() {
    if (this.needNgOninit) {
      this.ngOnInit();
    }

    if (!environment.debugMode) {
      console.clear();
    }
  }
  ngOnDestroy() {
    this.obsSubscription.unsubscribe();
  }

  // -----------------------------Methods--------------------------------------------------------------
  initTabs(toggledDay: string): void {
    switch (toggledDay) {
      case 'Tuesday':
        this.toggledTuesday = true;
        this.toggledThursday = false;
        this.toggledSaturday = false;
        this.templateDays = ['Tuesday'];
        this.servingDays = ['Tuesday', 'Wednesday'];
        break;
      case 'Thursday':
        this.toggledTuesday = false;
        this.toggledThursday = true;
        this.toggledSaturday = false;
        this.templateDays = ['Thursday'];
        this.servingDays = ['Thursday', 'Friday', 'Saturday'];

        break;
      case 'Saturday':
        this.toggledTuesday = false;
        this.toggledThursday = false;
        this.toggledSaturday = true;
        this.templateDays = ['Sunday'];
        this.servingDays = ['Sunday', 'Monday'];

        break;
      default:
        this.localStorageService.set('toggledDayBulk', 'Tuesday');
        this.toggledTuesday = true;
        this.toggledThursday = false;
        this.toggledSaturday = false;
        this.servingDays = ['Tuesday', 'Wednesday'];
    }
  }
  initDate(): void {
    this.shiftWeek = this.localStorageService.get(
      'shiftWeek' + this.localStorageService.get('currentChildRoute')
    );

    console.log('this.shiftWeek=' + this.shiftWeek);
    this.currentDayNumber = this.dateService.getCurrentDayNumber();

    this.currentWeek = this.dateService.getCurrentWeek();

    this.shiftWeekNumber = this.dateService.getShiftWeek(this.shiftWeek);

    this.SundayDate = this.dateService.getISODateDDMMMforDay(
      'Sunday',
      this.shiftWeek
    );
    this.MondayDate = this.dateService.getISODateDDMMMforDay(
      'Monday',
      this.shiftWeek
    );
    this.SundayNextWeekDate = this.dateService.getISODateDDMMMforDay(
      'Sunday',
      this.shiftWeek + 1
    );
    this.MondayNextWeekDate = this.dateService.getISODateDDMMMforDay(
      'Monday',
      this.shiftWeek + 1
    );
    this.TuesdayDate = this.dateService.getISODateDDMMMforDay(
      'Tuesday',
      this.shiftWeek
    );
    this.WednesdayDate = this.dateService.getISODateDDMMMforDay(
      'Wednesday',
      this.shiftWeek
    );
    this.ThursdayDate = this.dateService.getISODateDDMMMforDay(
      'Thursday',
      this.shiftWeek
    );
    this.FridayDate = this.dateService.getISODateDDMMMforDay(
      'Friday',
      this.shiftWeek
    );
    this.SaturdayDate = this.dateService.getISODateDDMMMforDay(
      'Saturday',
      this.shiftWeek
    );
  }

  initServingDaysOrdered(dishAllocationsWeekResponse: any) {
    if (dishAllocationsWeekResponse[0]) {
      // --------------------------------------------Serving Says Ordered-------------------------------

      // ----------------------------------------------------------------------------------
      this.deliveryDaysNumberOrdered[
        'Tuesday'
      ] = this.setIfAllOrdered(
        this.dishAllocationsWeekResponse[0]['bulkDishes'],
        'Tuesday'
      );
      console.debug(
        '[ngOnInit] this.deliveryDaysNumberOrdered[Tuesday]',
        this.deliveryDaysNumberOrdered['Tuesday']
      );

      if (this.dishAllocationsWeekResponse[1]) {
        this.deliveryDaysNumberOrdered[
          'Thursday'
        ] = this.setIfAllOrdered(
          this.dishAllocationsWeekResponse[1]['bulkDishes'],
          'Thursday'
        );
        console.debug(
          '[ngOnInit] this.deliveryDaysNumberOrdered[Thursday]',
          this.deliveryDaysNumberOrdered['Thursday']
        );
      } else {
        this.deliveryDaysNumberOrdered['Thursday'] = 0;
      }
      if (this.dishAllocationsWeekResponse[2]) {
        this.deliveryDaysNumberOrdered[
          'Saturday'
        ] = this.setIfAllOrdered(
          this.dishAllocationsWeekResponse[2]['bulkDishes'],
          'Saturday'
        );
        console.debug(
          '[ngOnInit] this.deliveryDaysNumberOrdered[Thursday]',
          this.deliveryDaysNumberOrdered['Thursday']
        );
      } else {
        this.deliveryDaysNumberOrdered['Saturday'] = 0;
      }
      console.debug(
        '[ngOnInit] this.deliveryDaysNumberOrdered',
        this.deliveryDaysNumberOrdered
      );
    }

  }

  mergeDeliveryDaysResponses(toggledDay: string, dishAllocationsWeekResponse: any) {
    switch (toggledDay) {
      case 'Tuesday':
        break;
      case 'Thursday':
        dishAllocationsWeekResponse[0] = dishAllocationsWeekResponse[1];
        break;
      case 'Saturday':
        dishAllocationsWeekResponse[0] = dishAllocationsWeekResponse[2];
        break;
      default: { }
    }
    dishAllocationsWeekResponse.pop();
    dishAllocationsWeekResponse.pop();
    console.log(
      '[ngOnInit] dishAllocationsWeekResponse AFTER=' +
      JSON.stringify(dishAllocationsWeekResponse)
    );
  }
  initDeliveryDaysOrdered() {
    this.deliveryDaysOrderedId =
      'deliveryDaysOrdered' +
      this.localStorageService.get('currentChildRoute') +
      this.shiftWeekNumber +
      this.departmentId;
    console.debug(
      '[ngOnInit] this.deliveryDaysOrderedId',
      this.deliveryDaysOrderedId
    );
    this.deliveryDaysOrdered = this.localStorageService.get(
      this.deliveryDaysOrderedId
    );
    if (
      this.deliveryDaysOrdered == null ||
      this.deliveryDaysOrdered == undefined
    ) {
      this.deliveryDaysOrdered = new DeliveryDaysOrdered(
        false,
        false,
        false,
        false,
        false,
        false,
        false
      );
    }
    console.debug(
      '[ngOnInit] this.deliveryDaysOrdered',
      this.deliveryDaysOrdered
    );
  }
  categoryFilterAndSetEmptyDays() {
    this.categoryForFilter = 'Frokost';
    this.dishAllocationsOrderDays['Sunday'] = this.categoryFilter(
      0,
      this.dishAllocationsWeekResponse,
      this.categoryForFilter
    );
    if (_.isEmpty(this.dishAllocationsOrderDays['Sunday'])) {
      console.log('this.dishAllocationsOrderDays[\'Sunday\'].length==0');

      this.isEmptySunday = true;
    }

    this.dishAllocationsOrderDays['Monday'] = this.categoryFilter(
      1,
      this.dishAllocationsWeekResponse,
      this.categoryForFilter
    );
    if (_.isEmpty(this.dishAllocationsOrderDays['Monday'])) {
      console.log('this.dishAllocationsOrderDays[\'Monday\'].length==0');
      this.isEmptyMonday = true;
    }

    this.dishAllocationsOrderDays['Tuesday'] = this.categoryFilter(
      2,
      this.dishAllocationsWeekResponse,
      this.categoryForFilter
    );
    if (_.isEmpty(this.dishAllocationsOrderDays['Sunday'])) {
      console.log('this.dishAllocationsOrderDays[\'Sunday\'].length==0');
      this.isEmptyTuesday = true;
    }

    this.dishAllocationsOrderDays['Wednesday'] = this.categoryFilter(
      3,
      this.dishAllocationsWeekResponse,
      this.categoryForFilter
    );
    if (_.isEmpty(this.dishAllocationsOrderDays['Wednesday'])) {
      console.log('this.dishAllocationsOrderDays[\'Wednesday\'].length==0');
    }
    this.dishAllocationsOrderDays['Thursday'] = this.categoryFilter(
      4,
      this.dishAllocationsWeekResponse,
      this.categoryForFilter
    );
    if (_.isEmpty(this.dishAllocationsOrderDays['Thursday'])) {
      console.log('this.dishAllocationsOrderDays[\'Thursday\'].length==0');
      this.isEmptyThursday = true;
    }
    this.dishAllocationsOrderDays['Friday'] = this.categoryFilter(
      5,
      this.dishAllocationsWeekResponse,
      this.categoryForFilter
    );
    if (_.isEmpty(this.dishAllocationsOrderDays['Friday'].length == 0)) {
      console.log('this.dishAllocationsOrderDays[\'Friday\'].length==0');
      // this.isEmptyFriday=true;
    }
    this.dishAllocationsOrderDays['Saturday'] = this.categoryFilter(
      6,
      this.dishAllocationsWeekResponse,
      this.categoryForFilter
    );
    if (_.isEmpty(this.dishAllocationsOrderDays['Saturday'])) {
      console.log('this.dishAllocationsOrderDays[\'Saturday\'].length==0');
      this.isEmptySaturday = true;
    }

    console.log(
      'dishAllocationsOrderDays=' +
      JSON.stringify(this.dishAllocationsOrderDays)
    );
  }
  setEmptyDay(toggledDay: string, value: boolean) {
    switch (toggledDay) {
      case 'Monday':
        this.isEmptyMonday = value;
        break;
      case 'Tuesday':
        this.isEmptyTuesday = value;
        break;
      case 'Wednesday':
        this.isEmptyWednesday = value;
        break;
      case 'Thursday':
        this.isEmptyThursday = value;
        break;
      case 'Friday':
        this.isEmptyFriday = value;
        break;
      case 'Saturday':
        this.isEmptySaturday = value;
        break;
      case 'Sunday':
        this.isEmptySunday = value;
        break;


    }
  }
  initDishAllocationsWeekResponse(toggledDay: string, dishAllocationsWeekResponse: any) {
    this.guestChefModel = this.initGuestChefModel(dishAllocationsWeekResponse);
    if (dishAllocationsWeekResponse[0]
      && dishAllocationsWeekResponse[0]['customerNr']) {
      this.customerNr = dishAllocationsWeekResponse[0]['customerNr'];
    }

    switch (toggledDay) {
      case 'Tuesday':
        this.initDishAllocationsDayResponse('Tuesday', dishAllocationsWeekResponse);
        this.initDishAllocationsDayResponse('Wednesday', dishAllocationsWeekResponse);

        break;
      case 'Thursday':
        this.initDishAllocationsDayResponse('Thursday', dishAllocationsWeekResponse);
        this.initDishAllocationsDayResponse('Friday', dishAllocationsWeekResponse);
        this.initDishAllocationsDayResponse('Saturday', dishAllocationsWeekResponse);
        break;
      case 'Saturday':
        this.initDishAllocationsDayResponse('Sunday', dishAllocationsWeekResponse);
        this.initDishAllocationsDayResponse('Monday', dishAllocationsWeekResponse);
        break;
    }
  }
  initDishAllocationsDayResponse(day: string, dishAllocationsWeekResponse: any) {
    if (dishAllocationsWeekResponse[0]) {
      this.setEmptyDay(day, false);
      this.dishAllocationsOrderDays[
        day
      ] = dishAllocationsWeekResponse[0]['bulkDishes'];
      // -------------------------------------------------------------------------
      this.menuNr = dishAllocationsWeekResponse[0]['menuNr'];
      if (dishAllocationsWeekResponse[0]['isGuestChef']) {
        this.setGuestChefModelDay(this.guestChefModel, day, dishAllocationsWeekResponse[0]['isGuestChef']);
      }
      // -------------------------------------------------------------------------
      this.numberOfDishesForDietTypeNr30 = this.getNumberOfDishesForDietTypeNr30(
        this.dishAllocationsOrderDays[day]
      );
      if (_.isEmpty(this.dishAllocationsOrderDays[day])) {
        this.setEmptyDay(day, true);
      }
    } else {
      this.setEmptyDay(day, true);
    }

  }

  createArrayDishesViewForDay(toggledDay: string){
    if (toggledDay == 'Saturday') {
      toggledDay = 'Sunday';
    }
    let dishAllocationsOrderDaysArray = Array.from(
              this.dishAllocationsOrderDays[toggledDay]
            );

    let dishAllocationsOrderDaysLength = dishAllocationsOrderDaysArray.length;
    this.dishAllocationsOrderDays[
              toggledDay
            ].arrayDishesViewForDay = this.getArrayDishesViewForDayController(
              this.numberElementsInRow,
              dishAllocationsOrderDaysLength
            );
  }
  filterForDishWithServingDateDay(toggledDay: string){
    let filterDay = toggledDay;
    if (filterDay == 'Saturday') {
      filterDay = 'Sunday'
    }
    this.filterForDishWithServingDate(
      this.dishAllocationsOrderDays[filterDay],
      filterDay
    );
  }
  initGuestChefModel(dishAllocationsWeekResponse: any): GuestChefModel {
    return this.guestChefService.initGuestChefModel(dishAllocationsWeekResponse);
  }
  setGuestChefModelDay(guestChefModel: GuestChefModel, day: string, value: boolean): void {
    this.guestChefService.setGuestChefModelDay(guestChefModel, day, value);
  }
  getGuestChefModelDay(guestChefModel: GuestChefModel, day: string): boolean {
    return this.guestChefService.getGuestChefModelDay(guestChefModel, day);
  }

  categoryFilter(day: number, response: any[], category: any) {
    var dishArrForCategoty = Object.create({ category: [], categories: [] });
    var exit_loops = false;
    var lowerCaseCategory = category.toLowerCase();

    for (var n = 0; n < response[day].length; n++) {
      if (
        response[day][n]['category']['name'] == category ||
        response[day][n]['category']['name'] == lowerCaseCategory
      ) {
        dishArrForCategoty.category = response[day][n]['category'];
        dishArrForCategoty.categories = response[day][n]['categories'];
        exit_loops = true;
        break;
      } else {
      }
    }

    return dishArrForCategoty;
  }

  arrayDishesViewForDayByCategoryFilter(
    day: string,
    dishAllocationsOrderDays: DishAllocationsOrderDays,
    category: any
  ) {
    var viewArrayForCategoty = new Array();
    var exit_loops = false;
    for (var cat in dishAllocationsOrderDays[day]['categories']) {
      for (var property in dishAllocationsOrderDays[day]['categories'][cat][
        'category'
      ]) {
        if (
          dishAllocationsOrderDays[day]['categories'][cat]['category'][
            property
          ] == category
        ) {
          viewArrayForCategoty =
            dishAllocationsOrderDays[day]['categories'][cat][
              'arrayDishesViewForDay'
            ];
          exit_loops = true;
          break;
        } else {
        }
      }

      if (exit_loops) break;
    }

    return viewArrayForCategoty;
  }

  filterForDishWithServingDate(
    dishAllocationsOrderDaysForDay: any,
    day: string
  ) {
    for (let i = 0; i < dishAllocationsOrderDaysForDay.length; i++) {

      if (
        dishAllocationsOrderDaysForDay[i] !== undefined &&
        dishAllocationsOrderDaysForDay[i]['servingDate'] !== undefined
      ) {
        let orderDay = this.dateService.getNameDayFromISODateYYYYMMDD(
          dishAllocationsOrderDaysForDay[i]['servingDate']
        );

        this.addBulk(
          'Bulk',
          i,
          orderDay,
          true,
          dishAllocationsOrderDaysForDay[i]['print'],
          this.menuNr
        );
      } else if (dishAllocationsOrderDaysForDay[i]['print'] == true) {
        this.addBulk(
          'Bulk',
          i,
          day,
          true,
          dishAllocationsOrderDaysForDay[i]['print'],
          this.menuNr
        );
      }
    }
  }

  subBulk(cat: any, element: number, nameDay: string) {

    this.isQuantitiesChanged = true;
    if (cat == 'Bulk') {
      var catNumber = 3;
    }
    let orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      catNumber,
      element
    );

    for (var oder in this.simpleOderModelArray) {
      if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
        this.simpleOderModelArray[oder].orderNumber = 0;
        this.simpleOderModelArray[oder].sumOder = 0;
        this.simpleOderModelArray[oder].doSend = false;
        // let deliveryDay = this.getDeliveryDay(nameDay);
        if (
          !this.isServingDaysChecked(
            this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
              'productNr'
            ],
            nameDay,
            this.dishAllocationsOrderDays[nameDay][element]['menuLine']['line']
          )
        ) {
          this.simpleOderModelArray[oder].print = false;
        }
      }
    }

    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );
  }

  addBulk(
    cat: any,
    element: number,
    nameDay: string,
    isInit?: boolean,
    isPrint?: boolean,
    menuNr?: number
  ) {
    if (isInit) {
      this.isQuantitiesChanged = false;
      this.dataShareService.canToogleFromPopUp = true;
    } else {
      this.isQuantitiesChanged = true;
      this.dataShareService.canToogleFromPopUp = false;
    }

    if (!isPrint) {
      isPrint = false;
    }

    let orderFound = false;
    if (cat == 'Bulk') {
      var catNumber = 3;
    }
    if (!menuNr) {
      menuNr = this.menuNr;
    }
    let orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      catNumber,
      element
    );


    if (this.simpleOderModelArray.length == 0) {
      this.simpleOderModelArray.push(
        new SimpleOderModel(
          orderDishIdTCur,
          1,
          1,
          nameDay,
          this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
            'productNr'
          ],
          this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
            'productName'
          ],
          'null',
          this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
            'dietTypeName'
          ],
          'null',
          {
            print: isPrint,
            doSend: true,
            menuNr: menuNr,
            sideDish: this.dishAllocationsOrderDays[nameDay][element][
              'sideDish'
            ],
            line: this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
              'line'
            ],
          }
        )
      );
    } else {
      for (var oder in this.simpleOderModelArray) {
        if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
          orderFound = true;
          this.simpleOderModelArray[oder].orderNumber = 1;
          this.simpleOderModelArray[oder].sumOder = 1;
        }
      }

      if (!orderFound) {
        this.simpleOderModelArray.push(
          new SimpleOderModel(
            orderDishIdTCur,
            1,
            1,
            nameDay,
            this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
              'productNr'
            ],
            this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
              'productName'
            ],
            'null',
            this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
              'dietTypeName'
            ],
            'null',
            {
              print: isPrint,
              doSend: true,
              menuNr: menuNr,
              sideDish: this.dishAllocationsOrderDays[nameDay][element][
                'sideDish'
              ],
              line: this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
                'line'
              ],
            }
          )
        );
      }
    }
    // ----------------------Binary Check --------------------------------------------------
    this.binaryCheckState(
      this.dishAllocationsOrderDays[nameDay][element]['menuLine']['productNr'],
      nameDay,
      this.dishAllocationsOrderDays[nameDay][element]['menuLine']['line']
    );
    // ----------------------Binary Check Print --------------------------------------------------
    if (isPrint == true) {
      // this.binaryCheckStatePrint(orderDishIdTCur, nameDay);
    }
    // ---------------------------------------------------------------------------------------
    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );
  }

  // ----------------------------------Set Printing------------------------------
  setPrint(
    cat: any,
    element: number,
    nameDay: string,
    isInit?: boolean,
    isPrint?: boolean,
    menuNr?: number
  ) {
    if (isInit) {
      this.isQuantitiesChanged = false;
      this.dataShareService.canToogleFromPopUp = true;
    } else {
      this.isQuantitiesChanged = true;
      this.dataShareService.canToogleFromPopUp = false;
    }

    let orderFound = false;
    if (cat == 'Bulk') {
      var catNumber = 3;
    }

    // 	var orderDishIdTCur = orderDishIdTCur;
    let servingDay = this.findServingDayForPrint(
      this.dishAllocationsOrderDays[nameDay][element]['menuLine']['productNr'],
      nameDay,
      this.dishAllocationsOrderDays[nameDay][element]['menuLine']['line']
    );
    let orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      servingDay,
      catNumber,
      element
    );

    if (
      !isPrint ||
      this.isServingDaysChecked(
        this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
          'productNr'
        ],
        nameDay,
        this.dishAllocationsOrderDays[nameDay][element]['menuLine']['line']
      )
    ) {
      if (this.simpleOderModelArray.length == 0) {
        this.simpleOderModelArray.push(
          new SimpleOderModel(
            orderDishIdTCur,
            0,
            0,
            nameDay,

            this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
              'productNr'
            ],
            this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
              'productName'
            ],
            'null',
            this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
              'dietTypeName'
            ],
            'null',
            {
              print: isPrint,
              doSend: true,
              menuNr: menuNr,
              sideDish: this.dishAllocationsOrderDays[nameDay][element][
                'sideDish'
              ],
              line: this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
                'line'
              ],
            }
          )
        );
      } else {
        for (var oder in this.simpleOderModelArray) {
          if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
            orderFound = true;
            this.simpleOderModelArray[oder].print = isPrint;
            this.simpleOderModelArray[oder].doSend = true;
          }
        }

        if (!orderFound) {
          this.simpleOderModelArray.push(
            new SimpleOderModel(
              orderDishIdTCur,
              0,
              0,
              nameDay,
              this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
                'productNr'
              ],
              this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
                'productName'
              ],
              'null',
              this.dishAllocationsOrderDays[nameDay][element]['menuLine'][
                'dietTypeName'
              ],
              'null',
              {
                print: isPrint,
                doSend: true,
                menuNr: menuNr,
                sideDish: this.dishAllocationsOrderDays[nameDay][element][
                  'sideDish'
                ],
                line: this.dishAllocationsOrderDays[nameDay][element][
                  'menuLine'
                ]['line'],
              }
            )
          );
        }
      }
    }

    // ----------------------Binary Check Print --------------------------------------------------
    if (isPrint == true) {
      this.binaryCheckStatePrint(
        orderDishIdTCur,
        servingDay,
        this.dishAllocationsOrderDays[nameDay][element]['sideDish'],
        this.dishAllocationsOrderDays[nameDay][element]['menuLine']['line']
      );
    }
    // ---------------------------------------------------------------------------------------
    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );
  }
  // -----------------------------------------------------------------------------------
  canselChangingsForDay(
    simpleOderModelArray: SimpleOderModel[],
    nameDay: string
  ) {

    for (var oder in simpleOderModelArray) {
      if (simpleOderModelArray[oder]['Day'] == nameDay) {
        simpleOderModelArray[oder].orderNumber = 0;
        simpleOderModelArray[oder].sumOder = 0;
      }
    }

    return simpleOderModelArray;
  }

  // ----------------------------------------------------------------------------

  getSumOder(cat: any, element: number, nameDay: string) {
    var sumOder = 0;

    var orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      cat,
      element
    );
    for (var oder in this.simpleOderModelArray) {
      if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
        sumOder = this.simpleOderModelArray[oder].sumOder;
      }
    }
    return sumOder;
  }

  // --------------------Get Nomber of Delivery Day selected For Day----------------------------
  // ------------------------GetNumberOfSelected-----------------------------
  getNumberOfSelected(
    dishAllocationsOrderDaysForDay: any,
    deliveryDay: string
  ): number {
    let sumOder = 0;
    let curServingDate = '';
    switch (deliveryDay) {
      case 'Tuesday':
        for (let i = 0; i < dishAllocationsOrderDaysForDay.length; i++) {

          if (
            dishAllocationsOrderDaysForDay[i] !== undefined &&
            dishAllocationsOrderDaysForDay[i]['servingDate'] !== undefined
          ) {
            if (curServingDate == '') {
              sumOder += 1;
              curServingDate = dishAllocationsOrderDaysForDay[i]['servingDate'];
            } else if (
              curServingDate != dishAllocationsOrderDaysForDay[i]['servingDate']
            ) {
              sumOder += 1;
            }
          }
        }

        break;
      case 'Thursday':
        let curServingDate2 = '';
        for (let i = 0; i < dishAllocationsOrderDaysForDay.length; i++) {

          if (
            dishAllocationsOrderDaysForDay[i] !== undefined &&
            dishAllocationsOrderDaysForDay[i]['servingDate'] !== undefined
          ) {
            if (curServingDate == '') {
              sumOder += 1;
              curServingDate = dishAllocationsOrderDaysForDay[i]['servingDate'];
            }
            if (
              curServingDate2 == '' &&
              curServingDate != dishAllocationsOrderDaysForDay[i]['servingDate']
            ) {
              sumOder += 1;
              curServingDate2 =
                dishAllocationsOrderDaysForDay[i]['servingDate'];
            } else if (
              curServingDate !=
                dishAllocationsOrderDaysForDay[i]['servingDate'] &&
              curServingDate2 !=
                dishAllocationsOrderDaysForDay[i]['servingDate']
            ) {
              sumOder += 1;
            }
          }
        }

        break;
      case 'Saturday':
        for (let i = 0; i < dishAllocationsOrderDaysForDay.length; i++) {

          if (
            dishAllocationsOrderDaysForDay[i] !== undefined &&
            dishAllocationsOrderDaysForDay[i]['servingDate'] !== undefined
          ) {
            if (curServingDate == '') {
              sumOder += 1;
              curServingDate = dishAllocationsOrderDaysForDay[i]['servingDate'];
            } else if (
              curServingDate != dishAllocationsOrderDaysForDay[i]['servingDate']
            ) {
              sumOder += 1;
            }
          }
        }
        break;
      default: {
        console.error('Wrong Day');
      }
    }

    return sumOder;
  }
  // -------------------------SetIfAllOrdered----------------------------------
  setIfAllOrdered(
    dishAllocationsOrderDaysForDay: any,
    deliveryDay: string
  ): number {
    let sumOder = 1;
    let curServingDate = '';
    switch (deliveryDay) {
      case 'Tuesday':
        for (let i = 0; i < dishAllocationsOrderDaysForDay.length; i++) {

          if (
            dishAllocationsOrderDaysForDay[i] !== undefined &&
            (dishAllocationsOrderDaysForDay[i]['servingDate'] == undefined
              || dishAllocationsOrderDaysForDay[i]['servingDate'].length==0)
          ) {
            sumOder = 0;
          }
        }

        break;
      case 'Thursday':
        let curServingDate2 = '';
        for (let i = 0; i < dishAllocationsOrderDaysForDay.length; i++) {

          if (
            dishAllocationsOrderDaysForDay[i] !== undefined &&
            (dishAllocationsOrderDaysForDay[i]['servingDate'] == undefined
              || dishAllocationsOrderDaysForDay[i]['servingDate'].length == 0)
          ) {
            sumOder = 0;
          }
        }

        break;
      case 'Saturday':
        for (let i = 0; i < dishAllocationsOrderDaysForDay.length; i++) {

          if (
            dishAllocationsOrderDaysForDay[i] !== undefined &&
            (dishAllocationsOrderDaysForDay[i]['servingDate'] == undefined
              || dishAllocationsOrderDaysForDay[i]['servingDate'].length == 0)
          ) {
            sumOder = 0;
          }
        }
        break;
      default: {
        console.error('Wrong Day');
      }
    }

    return sumOder;
  }
  // --------------------------Compare Func------------------
  compareOrders(a, b) {
    if (a['sumOder'] > b['sumOder']) return 1;
    if (a['sumOder'] == b['sumOder']) return 0;
    if (a['sumOder'] < b['sumOder']) return -1;
  }
  compareOrdersByPrint(a, b) {
    if (a['print'] == true && b['print'] == false) return 1;
    if (a['print'] == false && b['print'] == false) return 0;
    if ((a['print'] == false) < (b['print'] == true)) return -1;
  }

  // -----------------------------------------------------------------
  sortSimpleOderModelArray(simpleOderModelArray: any) {
    return simpleOderModelArray.sort(this.compareOrders);
  }
  // --------------------------------------------------------------

  sortByPrintSimpleOderModelArray(simpleOderModelArray: any) {
    return simpleOderModelArray.sort(this.compareOrdersByPrint);
  }
  // ---------------------------------------------------------
  setDoSendFalse(simpleOderModelArray: any) {
    for (var oder in simpleOderModelArray) {
      if (simpleOderModelArray[oder].sumOder == 0) {
        if (
          this.isAllServingDaysFalse(
            simpleOderModelArray[oder]['dishId'],
            simpleOderModelArray[oder]['Day'],
            simpleOderModelArray[oder]['line']
          ) == true
        ) {
          this.simpleOderModelArray[oder].doSend = true;
        } else {
          simpleOderModelArray[oder].doSend = false;
        }
      } else {
        this.simpleOderModelArray[oder].doSend = true;
      }
    }

    return simpleOderModelArray;
  }
  // ---------------------SetDoSend---------------------------
  isAllServingDaysFalse(productNr: any, day: string, line: number) {
    let isAllServingDaysFalse: boolean = true;
    let servingDay = this.findServingDayForPrint(productNr, day, line);
    for (let itemOrderBulk in this.simpleOderModelArray) {
      switch (day) {
        case 'Tuesday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Wednesday' &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            (this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0 ||
              (this.simpleOderModelArray[itemOrderBulk]['sumOder'] == 0 &&
                this.simpleOderModelArray[itemOrderBulk]['doSend'] == true))
          ) {
            console.debug(
              '[isAllServingDaysFalse] Tuesday and Wednesday isAllServingDaysFalse',
              true
            );
            isAllServingDaysFalse = false;
          }
          break;
        case 'Wednesday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Tuesday' &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            (this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0 ||
              (this.simpleOderModelArray[itemOrderBulk]['sumOder'] == 0 &&
                this.simpleOderModelArray[itemOrderBulk]['doSend'] == true))
          ) {
            console.debug(
              '[isAllServingDaysFalse] Wednesday and Tuesday isAllServingDaysFalse',
              true
            );
            isAllServingDaysFalse = false;
          }
          break;
        case 'Thursday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Friday' &&
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Saturday' &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            (this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0 ||
              (this.simpleOderModelArray[itemOrderBulk]['sumOder'] == 0 &&
                this.simpleOderModelArray[itemOrderBulk]['doSend'] == true))
          ) {
            console.debug(
              '[isAllServingDaysFalse] Thursday, Friday, Saturday isAllServingDaysFalse',
              true
            );
            isAllServingDaysFalse = false;
          }

          break;
        case 'Friday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Thursday' &&
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Saturday' &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            (this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0 ||
              (this.simpleOderModelArray[itemOrderBulk]['sumOder'] == 0 &&
                this.simpleOderModelArray[itemOrderBulk]['doSend'] == true))
          ) {
            console.debug(
              '[isAllServingDaysFalse] Thursday, Friday, Saturday isAllServingDaysFalse',
              true
            );
            isAllServingDaysFalse = false;
          }

          break;
        case 'Saturday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Thursday' &&
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Friday' &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            (this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0 ||
              (this.simpleOderModelArray[itemOrderBulk]['sumOder'] == 0 &&
                this.simpleOderModelArray[itemOrderBulk]['doSend'] == true))
          ) {
            console.debug(
              '[isAllServingDaysFalse] Thursday, Friday, Saturday isAllServingDaysFalse',
              true
            );
            isAllServingDaysFalse = false;
          }

          break;

        case 'Sunday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Monday' &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            (this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0 ||
              (this.simpleOderModelArray[itemOrderBulk]['sumOder'] == 0 &&
                this.simpleOderModelArray[itemOrderBulk]['doSend'] == true))
          ) {
            console.debug(
              '[isAllServingDaysFalse] Sunday, Monday isAllServingDaysFalse',
              true
            );
            isAllServingDaysFalse = false;
          }
          break;
        case 'Monday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Sunday' &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            (this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0 ||
              (this.simpleOderModelArray[itemOrderBulk]['sumOder'] == 0 &&
                this.simpleOderModelArray[itemOrderBulk]['doSend'] == true))
          ) {
            console.debug(
              '[isAllServingDaysFalse] Sunday, Monday isAllServingDaysFalse',
              true
            );
            isAllServingDaysFalse = false;
          }
          break;
        default: {
          console.log('The Day is invalid');
        }
      }
    }
    console.debug(
      '[isAllServingDaysFalse] isAllServingDaysFalse',
      isAllServingDaysFalse
    );
    return isAllServingDaysFalse;
  }
  // ----------------------------------------
  filterIfDoSendFalse(simpleOderModelArray: any) {
    let filteredSimpleOderModelArray = [];
    filteredSimpleOderModelArray = simpleOderModelArray.filter(function (el) {
      return el.doSend != false;
    });

    return filteredSimpleOderModelArray;
  }

  // ----------------------------------------
  filterIfPrintFalse(simpleOderModelArray: any) {
    let filteredSimpleOderModelArray = [];
    filteredSimpleOderModelArray = simpleOderModelArray.filter(function (el) {
      return el.print != false || (el.doSend == true && el.sumOder != 0);
    });

    return filteredSimpleOderModelArray;
  }
  // ---------------------------------------------------------------

  prevWeek(content: any) {
    this.nextShiftWeek = this.shiftWeek - 1;
    if (this.isQuantitiesChanged) {
      this.changedShiftWeek = true;
      this.openWindowCustomClass(content);
    } else {
      this.switchWeek(this.nextShiftWeek);
    }
  }

  switchWeek(nextShiftWeek: number) {
    if (!this.isQuantitiesChanged || this.continueSwitching) {
      // this.shiftWeek--;
      this.shiftWeek = nextShiftWeek;
      this.localStorageService.set(
        'shiftWeek' + this.localStorageService.get('currentChildRoute'),
        this.shiftWeek
      );
      this.updateCartFromLocalStorage();

      this.localStorageService.set('toggledDayBulk', 'Tuesday');
      this.templateDays = ['Tuesday'];
      this.servingDays = ['Tuesday', 'Wednesday'];

      this.needNgOninit = true;
      // this.needUnsubscribe = false;

      this.router.navigateByUrl('/minmenu/bulkorder/viewweekplan');
      // this.ngOnInit();
    }
  }
  nextWeek(content: any) {
    this.nextShiftWeek = this.shiftWeek + 1;
    // this.isQuantitiesChanged = this.checkQuantitiesChanged(this.simpleOderModelArray);
    if (this.isQuantitiesChanged) {
      this.changedShiftWeek = true;
      this.openWindowCustomClass(content);
    } else {
      // this.switchNextWeek();
      this.switchWeek(this.nextShiftWeek);
    }
  }

  getImgUrl(dish: any): string {
    console.debug('[getImgUrl] dish:' + dish);
    let imgUrl;
    if (
      dish &&
      dish['dishPhotos'] &&
      dish['dishPhotos'].length != 0 &&
      dish['dishPhotos'][0] &&
      dish['dishPhotos'][0]['imageUrl'] != ''
    ) {
      imgUrl = this.dataSource.baseUrl + dish['dishPhotos'][0]['imageUrl'];
      console.debug('[getImgUrl] imgUrl', imgUrl);
      return imgUrl;
    } else {
      return 'assets/img/defaultImg.png';
    }
  }

  getProdImg(prodImg: string) {
    var img = 'data:image/jpeg;base64,' + prodImg;
    return this.sanitizer.bypassSecurityTrustResourceUrl(img);
  }

  getProdBackgroundImg(prodImg: string) {
    var imgBackground = 'data:image/jpeg;base64,' + prodImg;
    return imgBackground;
  }

  getTargetId(cat: any, nameDay: string, itemDish: any): string {
    var id = '#' + cat + nameDay + itemDish;
    return id.toString();
  }

  getId(cat: any, nameDay: string, itemDish: any): string {
    var id = cat + nameDay + itemDish;
    return id.toString();
  }

  getOrderDishId(
    fromPage: string,
    shiftWeek: number,
    day: string,
    cat: number,
    dishPlace: number
  ) {
    var pageNumber;
    var orderDishId: number;
    var week = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    switch (fromPage) {
      case 'frokostpakker': {
        pageNumber = 1;
        break;
      }
      case 'extra': {
        pageNumber = 2;
        break;
      }
      case 'bulkorder/viewweekplan': {
        pageNumber = 3;
        break;
      }
    }
    orderDishId = Number(
      pageNumber * 1000000 +
        (Number(this.dateService.getCurrentWeek()) + Number(shiftWeek)) * 1000 +
        Number(week.indexOf(day) * 1000) +
        cat * 100 +
        dishPlace
    );
    return orderDishId;
  }

  updateCartFromLocalStorage() {
    this.cart.updateLinesFromLocalStorage(this.shiftWeekNumber);
  }

  accept() {
    this.router.navigateByUrl('/minmenu/cart');
  }

  getRowsNumberForDay(numberElementsInRow: number, dishesArraylength: number) {
    var rowsNumber = Math.trunc(dishesArraylength / numberElementsInRow);
    if (dishesArraylength % numberElementsInRow != 0) {
      rowsNumber++;
    }
    if (rowsNumber == 0) {
      rowsNumber = 1;
    }
    return rowsNumber;
  }

  getArrayDishesViewForDayController(
    numberElementsInRow: number,
    dishesArraylength: number
  ) {
    var rows = this.getRowsNumberForDay(numberElementsInRow, dishesArraylength);
    console.log('rows=' + rows);

    var arrayViewController = new Array(rows);
    for (var i = 0; i < arrayViewController.length; i++) {
      arrayViewController[i] = new Array(numberElementsInRow);
    }
    for (var i = 0; i < arrayViewController.length; i++) {
      for (var j = 0; j < arrayViewController[i].length; j++) {
        arrayViewController[i][j] = -1;
      }
    }

    console.log('array=' + JSON.stringify(arrayViewController));

    var k = numberElementsInRow;
    var ref = 0;
    for (var i = 0; i < rows; i++) {
      if (dishesArraylength > numberElementsInRow) {
        if (i == rows - 1) {
          if (dishesArraylength % numberElementsInRow != 0) {
            k = dishesArraylength % numberElementsInRow;
            console.log('k=' + k);

            arrayViewController[i].length = k; // trim last row to the number of items remainder
          }
        }
      } else {
        k = dishesArraylength;
        arrayViewController[i].length = k;
      }

      for (var j = 0; j < k; j++) {
        arrayViewController[i][j] = ref;
        ref++;
      }
    }
    console.log('array=' + JSON.stringify(arrayViewController));
    return arrayViewController;
  }

  toggleMonday() {
    this.toggledMonday = true;
    this.toggledWednesday = false;
    this.toggledFriday = false;
  }

  toggleWednesday() {
    this.toggledMonday = false;
    this.toggledWednesday = true;
    this.toggledFriday = false;
  }

  toggleFriday() {
    this.toggledMonday = false;
    this.toggledWednesday = false;
    this.toggledFriday = true;
  }

  toggleTuesday(content: any) {
    this.nextDay = 'Tuesday';
    if (this.isQuantitiesChanged) {
      this.openWindowCustomClass(content);
    } else {
      this.switchTuesday();
    }
  }

  switchTuesday() {
    if (!this.isQuantitiesChanged || this.continueSwitching) {
      this.toggledTuesday = true;
      this.toggledThursday = false;
      this.toggledSaturday = false;
      // ---------------------DelivaryDay is saved---------------------------------
      this.localStorageService.set('toggledDayBulk', 'Tuesday');
      this.templateDays = ['Tuesday'];
      this.servingDays = ['Tuesday', 'Wednesday'];

      this.needNgOninit = true;

      this.router.navigateByUrl('/minmenu/bulkorder/viewweekplan');
    }
  }

  toggleThursday(content: any) {
    this.nextDay = 'Thursday';

    if (this.isQuantitiesChanged) {
      this.openWindowCustomClass(content);
    } else {
      this.switchThursday();
    }
  }

  switchThursday() {
    if (!this.isQuantitiesChanged || this.continueSwitching) {
      this.toggledTuesday = false;
      this.toggledThursday = true;
      this.toggledSaturday = false;
      // ---------------------DelivaryDay is saved---------------------------------
      this.localStorageService.set('toggledDayBulk', 'Thursday');
      this.templateDays = ['Thursday'];
      this.servingDays = ['Thursday', 'Friday', 'Saturday'];

      this.needNgOninit = true;

      this.router.navigateByUrl('/minmenu/bulkorder/viewweekplan');
    }
  }

  toggleSaturday(content: any) {
    this.nextDay = 'Saturday';

    if (this.isQuantitiesChanged) {
      this.openWindowCustomClass(content);
    } else {
      this.switchSaturday();
    }
  }

  switchSaturday() {
    if (!this.isQuantitiesChanged || this.continueSwitching) {
      this.toggledTuesday = false;
      this.toggledThursday = false;
      this.toggledSaturday = true;
      // ---------------------DelivaryDay is saved---------------------------------
      this.localStorageService.set('toggledDayBulk', 'Saturday');
      this.templateDays = ['Sunday'];
      this.servingDays = ['Sunday', 'Monday'];
      this.needNgOninit = true;
      this.router.navigateByUrl('/minmenu/bulkorder/viewweekplan');
    }
  }

  translateDayToDanish(day: string) {
    return this.localeDaDKService.translateDayToDanishsFull(day);
  }

  getNameDayFromISODateYYYYMMDDArr(DateYYYYMMDD: string) {
    let arr: string[] = [];
    arr.push(this.dateService.getNameDayFromISODateYYYYMMDD(DateYYYYMMDD));
    return arr;
  }

  getNameDayFromISODateYYYYMMDD(DateYYYYMMDD: string) {
    return this.dateService.getNameDayFromISODateYYYYMMDD(DateYYYYMMDD);
  }

  getDayDate(day: string): string {
    console.log(day);
    //if (day != 'Sunday' && day != 'Monday') {
    if (day != 'Monday') {
      return this.dateService.getISODateDDMMMforDay(day, this.shiftWeek);
    } else {
      return this.dateService.getISODateDDMMMforDay(day, this.shiftWeek + 1);
    }
  }

  isDayForOrder(orderingDates: any[], nameDay: string, shiftWeek: number) {
    var isDayForOrderFlag = false;
    var date = this.dateService.getISODateYYYMMDDforDay(nameDay, shiftWeek);

    for (var day in this.orderingDates) {
      if (date == this.orderingDates[day]) {
        isDayForOrderFlag = true;
      }
    }

    return isDayForOrderFlag;
  }

  filterToogle() {
    this.filterOn = !this.filterOn;
  }

  isOrderingTime(userInfoOrderingModel: any[], categoryForFilter: string) {
    var startServingTime = '';
    var endServingTime = '';
    var currentTime = 0;
    for (var userCategory in userInfoOrderingModel['userCategories']) {
      if (
        userInfoOrderingModel['userCategories'][userCategory]['category'][
          'name'
        ] == categoryForFilter
      ) {
        startServingTime =
          userInfoOrderingModel['userCategories'][userCategory][
            'startServingTime'
          ];
        // 	var dateStart =  this.dateService.getDateFromTimeHHmmss(startServingTime);

        // 	console.log("IsstartServingTime=" + this.dateService.returnCurrentDateForTimeHHmmss(dateStart));
        endServingTime =
          userInfoOrderingModel['userCategories'][userCategory][
            'endServingTime'
          ];

        // 	var dateEnd =  this.dateService.getDateFromTimeHHmmss(endServingTime);

        // 	console.log("IsendServingTime=" + this.dateService.returnCurrentDateForTimeHHmmss(dateEnd));

        // 	currentTime = this.dateService.getCurrentTime();
        // 	console.log("currentTime=" + currentTime);
        // 	if(this.dateService.returnCurrentDateForTimeHHmmss(dateStart)<=currentTime &&
        // 	currentTime<=this.dateService.returnCurrentDateForTimeHHmmss(dateEnd)){
        if (
          this.dateService.isCurrentTimeBetween(
            startServingTime,
            endServingTime
          )
        ) {
          return (this.isOrderingTimeFlag = true);
          // 	}else return this.isOrderingTimeFlag=false;
        } else return (this.isOrderingTimeFlag = true);
      }
    }
  }

  openVerticallyCentered(content) {
    console.log('content')
    console.log(content)
    this.modalService.open(content, { centered: true });
  }

  openVerticallyCenteredProductName(content) {
     
    this.loader = true
    var loader = document.getElementById('loader');
    loader.style.visibility = 'visible';

  console.log('-----------------------')
  console.log(this.guestChefModel)
  console.log(this.currentToogledDay)

// this.localStorageService.get('toggledDayBulk')


  //console.log(this.dateService.getISODateYYYMMDDforDay(this.currentToogledDay,this.shiftWeek))
  console.log(this.dateService.getISODateYYYMMDDforDay(this.currentToogledDay,this.shiftWeek))
  this.currentGuestChefDate = ""

  this.dataSource.getBulkOrderCurrentDate(this.dateService.getISODateYYYMMDDforDay(this.localStorageService.get('toggledDayBulk'),this.shiftWeek))
        .subscribe(
        (response) => {
          this.GuestChefDishesObj = []
          
          for(let i=0;i<response.servings.length;i++){
            if (response.servings[i].isGuestChef){
              this.currentGuestChefDate = response.servings[i].servingDate
              for(let i1=0;i1<response.servings[i].mainDishes.length;i1++){
                this.GuestChefDishesObj.push({"mainDish":response.servings[i].mainDishes[i1],"sideDish":findMatchingSideDish(response.servings[i].mainDishes[i1],response.servings[i].sideDishes)})
              }
            }
          }

          
          function findMatchingSideDish(mainDish:any,sideDishes:any){
            for(let i=0;i<sideDishes.length;i++){
              if((mainDish.menuLine.line >0 && mainDish.menuLine.line <151) && (sideDishes[i].menuLine.line>800 && sideDishes[i].menuLine.line<851)){
                return sideDishes[i]
              }
              if((mainDish.menuLine.line >150 && mainDish.menuLine.line <301) && (sideDishes[i].menuLine.line>850 && sideDishes[i].menuLine.line<901)){
                return sideDishes[i]
              }
              if((mainDish.menuLine.line >300 && mainDish.menuLine.line <451) && (sideDishes[i].menuLine.line>900)){
                return sideDishes[i]
              }
            }
            
            return
          }
         
           /*      let modalType = 'guestchefModal'
          let modalTitle = 'Gæstekok'
          let modalData = this.GuestChefDishesObj
          let modalBodyText = ""
          
          let confirmBtnText = 'Ok'
         
                 
          this.messageModalWindowh.initModal(
            modalType,
            modalTitle,
            modalBodyText,
            confirmBtnText,
            modalData
    );
      this.messageModalWindowh.openModalPopUp();*/

      //let dateYYYYMMDD =  this.dateService.getISODateYYYMMDDforDay(this.localStorageService.get('toggledDayBulk'),this.shiftWeek)
      this.dataSource.getSelection(this.currentGuestChefDate,this.departmentId).subscribe(
        (data) => {
        let selectedGuestChefDish:any = data  
        if(data){
          console.log(data)
          for(let i=0;i<this.GuestChefDishesObj.length;i++){
            if(selectedGuestChefDish.data.mainDish){
              if(this.GuestChefDishesObj[i].mainDish.menuLine.productNr == selectedGuestChefDish.data.mainDish.menuLine.productNr){
                console.log('selected something')
                this.GuestChefDishesObj[i].selected = true
              }
            }
            
  
          }
        }
        
        this.isItallowedToChangeGuestChefDishes = true
        this.isItAllowdToChangeGuestChefDish()
        this.openWindowCustomClass(this.modalGuestChefSelectDish);
      })
      },
        (error) => console.log('hello error', error)
      );

  }
  
  

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openWindowCustomClass(content) {
    this.modalService.open(content, {
      centered: true,
      windowClass: 'modalWindowEditWeekPlan',
    });
  }

  dishInfo() {
    this.modalBtnBackgroundColor = '#FFFFFF';
    this.modalBtnTextColor = '#000000';
    this.modalBtnTextWeight = 'bold';
    this.modalBtnFlag = 'dishInfo';
  }

  dishRecipe() {
    this.modalBtnBackgroundColor = '#5B7B12';
    this.modalBtnTextColor = '#FFFFFF';
    this.modalBtnTextWeight = 'normal';
    this.modalBtnFlag = 'dishRecipe';
  }

  dishVideo() {
    this.modalBtnBackgroundColor = '#5B7B12';
    this.modalBtnTextColor = '#FFFFFF';
    this.modalBtnTextWeight = 'normal';
    this.modalBtnFlag = 'dishVideo';
  }

  checkTibehorFuldkost(event: any, day: string, itemDish: any, checkboxItem: ElementRef) {
    console.log('day', day);
    console.log('event', event);
    console.log('itemDish', itemDish);

    if (event.checked) {
      this.addBulk('Bulk', itemDish, day);
    } else {
      if (this.canUncheck(itemDish, day)) {
        this.subBulk('Bulk', itemDish, day);
      } else {
        event.checked = true;
        this.openMessageModal();
      }

    }
  }
  canUncheck(element: number, nameDay: string,) {
    let currentDish = this.dishAllocationsOrderDays[nameDay][element];
    if (currentDish && currentDish.menuLine && (currentDish.menuLine.quantity == 0)) {
      return true;
    } else {
      return false;
    }


  }

  openMessageModal() {
    let message = `<b>Du kan ikke fjerne flaget da der stadigt er en ordre på retten.
    Hvis du ønsker at afbestille retten skal du sætte den til 0 på bestil siden, før end du kan fjerne den fra byg.</b>`
    this.messageModalWindowh.initModal(
      'message-modal',
      message
    );
    this.messageModalWindowh.openModalPopUp();
  }
  selectedForPrint(event: any, day: string, itemDish: any) {
    console.log('[selectedForPrint] day', day);
    console.log('[selectedForPrint] event', event.checked);
    console.log('[selectedForPrint] itemDish', itemDish);

    if (event.checked) {
      console.debug('[selectedForPrint] print', event.checked);
      this.setPrint('Bulk', itemDish, day, false, true, this.menuNr);
    } else {
      console.debug('[selectedForPrint] print', event.checked);
      this.setPrint('Bulk', itemDish, day, false, false, this.menuNr);
    }
  }
  sendBulkOrder() {
    console.log('In sendBulkOrder');

    /*
      simpleOderModelArray.forEach(itemBulkOrder =>{
        let orderDayNumber = this.dateService.getIndexForDayInWeek(itemBulkOrder["Day"]);
        console.log("itemBulkOrder[Day]", itemBulkOrder["Day"]);
        console.log("itemBulkOrder[dishId]", itemBulkOrder["dishId"]);
           this.streamsCheckedBulkOrder$.push(this.dataSource.sendBulkOrderCurrentDate(

          //   this.dataSource.sendBulkOrderCurrentDate(
             moment().add(shiftWeek, 'w').startOf('w').add(orderDayNumber, 'd').format('YYYY-MM-DD'),
             itemBulkOrder["dishId"]));
         //  console.log("this.streamsCheckedBulkOrder$", this.streamsCheckedBulkOrder$);



        });

    let obsrvCheckedBulkOrder$ = Observable.forkJoin(this.streamsSaturdayBulkOrder$);
    console.log("obsrvCheckedBulkOrder", obsrvCheckedBulkOrder$);
     return obsrvCheckedBulkOrder$;
     */
  }

  saveBulkOrder() {
    console.log('[saveBulkOrder] In saveBulkOrder');
    if (this.reqestInProgress == false) {
      this.reqestInProgress = true;
        // --------------------saveBulkOrder() ------------------------------------------
      this.isQuantitiesChanged = false;
      this.localStorageService.set(
      'shiftWeek' + this.localStorageService.get('currentChildRoute'),
      this.shiftWeek
      );
      this.localStorageService.set(
      'shiftWeek' + 'bulkorder/editweekplan',
      this.shiftWeek
      );
      this.localStorageService.set(
      'shiftWeek' + 'print/printweekplan',
      this.shiftWeek
      );
      this.localStorageService.set(
      'shiftWeek' + 'bulkorder/printweekplan/printchoosebulk',
      this.shiftWeek
      );
      this.localStorageService.set(
      'shiftWeek' + 'bulkorder/printweekplan/printtableveiw',
      this.shiftWeek
      );
      this.localStorageService.set(
      'toggledDayBulkOrder',
      this.localStorageService.get('toggledDayBulk')
      );

      this.simpleOderModelArray = this.sortSimpleOderModelArray(
      this.simpleOderModelArray
      );
      let filteredSimpleOderModelArray = this.setDoSendFalse(
      this.simpleOderModelArray
      );
      console.log(
      '[saveBulkOrder] this.simpleOderModelArray Fter setDo',
      this.simpleOderModelArray
      );
      filteredSimpleOderModelArray = this.filterIfDoSendFalse(
      JSON.parse(JSON.stringify(filteredSimpleOderModelArray))
      );
      console.log(
      '[saveBulkOrder] this.simpleOderModelArray',
      this.simpleOderModelArray
      );
      this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
      );

      this.dishRepository
      .putBulkOrder(this.shiftWeek, filteredSimpleOderModelArray, this.customerNr)
      .pipe(
        finalize(() => {
          console.log('[saveBulkOrder] finalize Sequence complete');
          this.reqestInProgress = false;
          this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
        })
      )
      .subscribe(
        (data) => {
          // this.message = "";
          console.log('data', data);

          console.log('The BulkOrder is sent');
          // ------------------------Add----------------------------------------
          this.simpleOderModelArray = [];
          this.localStorageService.set(
            this.simpleOderModelArrayId,
            this.simpleOderModelArray
          );
          this.reqestInProgress = false;
          this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
        },
        (error) => {
          console.log('The BulkOrder is not sent');
          this.reqestInProgress = false;
        }
      );

    // --------------------------------------------------------------------------------

    }

  }

  // ---------------------------Save----------------------

  save(content) {
    console.log('[save] In save');
    if (this.reqestInProgress == false) {
      this.reqestInProgress = true;
        // --------------------saveBulkOrder() ------------------------------------------

      this.localStorageService.set(
      'shiftWeek' + this.localStorageService.get('currentChildRoute'),
      this.shiftWeek
    );
      this.localStorageService.set(
      'shiftWeek' + 'bulkorder/editweekplan',
      this.shiftWeek
    );
      this.localStorageService.set(
      'shiftWeek' + 'print/printweekplan',
      this.shiftWeek
    );
      this.localStorageService.set(
      'shiftWeek' + 'bulkorder/printweekplan/printchoosebulk',
      this.shiftWeek
    );
      this.localStorageService.set(
      'shiftWeek' + 'bulkorder/printweekplan/printtableveiw',
      this.shiftWeek
    );
      this.localStorageService.set(
      'toggledDayBulkOrder',
      this.localStorageService.get('toggledDayBulk')
    );

      this.simpleOderModelArray = this.sortSimpleOderModelArray(
      this.simpleOderModelArray
    );
      let filteredSimpleOderModelArray = this.setDoSendFalse(
      this.simpleOderModelArray
    );
      console.log(
      '[save] this.simpleOderModelArray Fter setDo',
      this.simpleOderModelArray
    );
      filteredSimpleOderModelArray = this.filterIfDoSendFalse(
      JSON.parse(JSON.stringify(filteredSimpleOderModelArray))
    );
      console.log(
      '[save] filteredSimpleOderModelArray',
      filteredSimpleOderModelArray
    );

      console.log('[save] this.simpleOderModelArray', this.simpleOderModelArray);
      this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );
      this.dishRepository
      .putBulkOrder(this.shiftWeek, filteredSimpleOderModelArray, this.customerNr)
      .pipe(
        finalize(() => {
          console.log('[save] finalize Sequence complete');

          this.simpleOderModelArray = this.setSendedOrderClearChangedAll(
            this.simpleOderModelArray
          );
          this.localStorageService.set(
            this.simpleOderModelArrayId,
            this.simpleOderModelArray
          );
          this.isQuantitiesChanged = false;
          console.log(
            '[save] finalize this.isQuantitiesChanged',
            this.isQuantitiesChanged
          );

          // ---------------------DelivaryDay is saved---------------------------------

          let day = this.localStorageService.get('toggledDayBulk');
          this.deliveryDaysNumberOrdered[day] = this.setIfAllOrdered(
            this.simpleOderModelArray,
            day
          );
          this.localStorageService.set(
            this.deliveryDaysNumberOrderedId,
            this.deliveryDaysNumberOrdered
          );

          // ----------------------------------------------------------------------------
          this.reqestInProgress = false;
          this.router.navigateByUrl('/minmenu/bulkorder/viewweekplan');
        })
      )
      .subscribe(
        (data) => {
          console.log('[save] data', data);
          console.log('[save] The BulkOrder is sent');

          // ------------------From subscribe putBulkPrint----------------------
          this.simpleOderModelArray = this.setSendedOrderClearChangedAll(
            this.simpleOderModelArray
          );
          this.localStorageService.set(
            this.simpleOderModelArrayId,
            this.simpleOderModelArray
          );
          this.isQuantitiesChanged = false;
          console.log(
            '[save] putBulkOrderPrint this.isQuantitiesChanged',
            this.isQuantitiesChanged
          );

          // ---------------------DelivaryDay is saved---------------------------------

          let day = this.localStorageService.get('toggledDayBulk');
          this.deliveryDaysNumberOrdered[day] = this.setIfAllOrdered(
            this.simpleOderModelArray,
            day
          );
          this.localStorageService.set(
            this.deliveryDaysNumberOrderedId,
            this.deliveryDaysNumberOrdered
          );

          // ----------------------------------------------------------------------------

          // ------------------------Add----------------------------------------
          this.simpleOderModelArray = [];
          this.localStorageService.set(
            this.simpleOderModelArrayId,
            this.simpleOderModelArray
          );
          this.reqestInProgress = false;
          this.router.navigateByUrl('/minmenu/bulkorder/viewweekplan');
          // -------------------------------------------------------------------
        },
        (error) => {
          console.log('The BulkOrder is not sent');
          this.reqestInProgress = false;
        }
      );

    // --------------------------------------------------------------------------------

    }

  }

  // -----------------------------Print---------------------------------------------

  saveBulkOrderPrint() {
    console.log('[saveBulkOrderPrint] In saveBulkOrder');
    // --------------------saveBulkOrder() ------------------------------------------
    this.isQuantitiesChanged = false;
    this.localStorageService.set(
      'shiftWeek' + this.localStorageService.get('currentChildRoute'),
      this.shiftWeek
    );
    this.localStorageService.set(
      'shiftWeek' + 'bulkorder/editweekplan',
      this.shiftWeek
    );
    this.localStorageService.set(
      'shiftWeek' + 'print/printweekplan',
      this.shiftWeek
    );
    this.localStorageService.set(
      'shiftWeek' + 'bulkorder/printweekplan/printchoosebulk',
      this.shiftWeek
    );
    this.localStorageService.set(
      'shiftWeek' + 'bulkorder/printweekplan/printtableveiw',
      this.shiftWeek
    );
    this.localStorageService.set(
      'toggledDayBulkOrder',
      this.localStorageService.get('toggledDayBulk')
    );

    this.simpleOderModelArray = this.sortSimpleOderModelArray(
      this.simpleOderModelArray
    );
    let filteredSimpleOderModelArray = this.setDoSendFalse(
      this.simpleOderModelArray
    );
    console.log(
      '[saveBulkOrderPrint] this.simpleOderModelArray Fter setDo',
      this.simpleOderModelArray
    );

    filteredSimpleOderModelArray = this.filterIfDoSendFalse(
      JSON.parse(JSON.stringify(filteredSimpleOderModelArray))
    );
    console.log(
      '[saveBulkOrderPrint] this.simpleOderModelArray',
      this.simpleOderModelArray
    );
    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );
    this.dishRepository
      .putBulkOrder(this.shiftWeek, filteredSimpleOderModelArray, this.customerNr)

      .subscribe(
        (data) => {
          console.log('data', data);
          console.log('The BulkOrder is sent');
          // ----------------------------SavePrint-------------------------------------
          this.simpleOderModelArray = this.sortByPrintSimpleOderModelArray(
            this.simpleOderModelArray
          );
          let filteredSimpleOderModelArray = this.filterIfPrintFalse(
            JSON.parse(JSON.stringify(this.simpleOderModelArray))
          );

          console.log(
            '[saveBulkOrderPrint] this.simpleOderModelArray',
            this.simpleOderModelArray
          );
          this.localStorageService.set(
            this.simpleOderModelArrayId,
            this.simpleOderModelArray
          );
          this.dishRepository
            .putBulkOrderPrint(this.shiftWeek, filteredSimpleOderModelArray)
            .subscribe(
              (data) => {
                console.log('[saveBulkOrderPrint] data', data);
                // ------------------------Add----------------------------------------
                this.simpleOderModelArray = [];
                this.localStorageService.set(
                  this.simpleOderModelArrayId,
                  this.simpleOderModelArray
                );

                // ---------------------------------------------------------------

                this.router.navigateByUrl('/minmenu/print/printselectweek');

                console.log('[saveBulkOrderPrint] The BulkOrder is sent');
              },
              (error) => {
                console.log('[saveBulkOrderPrint] The BulkOrder is not sent');
              }
            );

          // ----------------------------------------------------
        },
        (error) => {
          console.log('The BulkOrder is not sent');
        }
      );

    // --------------------------------------------------------------------------------
  }

  // -------------------------------------------------------

  isProductChecked(productNr: any, day: string, line: number) {
    console.log('In isProductChecked');
    for (let itemOrderBulk in this.simpleOderModelArray) {
      if (
        this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0 &&
        this.simpleOderModelArray[itemOrderBulk]['Day'] == day &&
        this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
        this.simpleOderModelArray[itemOrderBulk]['line'] == line
      ) {
        return true;
      }
    }

    return false;
  }

  isProductSelectedForPrint(productNr: any, day: string, line: number) {
    console.log('[isProductSelectedForPrint] productNr', productNr);
    console.log('[isProductSelectedForPrint] productNr', day);
    let isProductSelectedForPrint: boolean = false;
    for (let itemOrderBulk in this.simpleOderModelArray) {
      switch (day) {
        case 'Tuesday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Tuesday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Wednesday') &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['print'] == true &&
            this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0
          ) {
            console.debug(
              '[isProductSelectedForPrint] Tuesday isProductChecked',
              true
            );
            isProductSelectedForPrint = true;
          }
          break;

        case 'Thursday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Thursday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Friday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Saturday') &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['print'] == true &&
            this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0
          ) {
            console.debug(
              '[isProductSelectedForPrint] Thursday isProductChecked',
              true
            );
            isProductSelectedForPrint = true;
          }

          break;

        case 'Sunday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Sunday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Monday') &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['print'] == true &&
            this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0
          ) {
            console.debug(
              '[isProductSelectedForPrint] Sunday isProductChecked',
              true
            );
            isProductSelectedForPrint = true;
          }
          break;

        default: {
          console.log('The Day is invalid');
        }
      }
    }
    console.debug('[isProductSelectedForPrint] isProductChecked', false);
    return isProductSelectedForPrint;
  }
  // ---------------------isDisabled-----------------------------------------
  isDisabledForPrint(productNr: any, day: string, line: number) {
    console.log('[isDisabledForPrint] productNr', productNr);
    console.log('[isDisabledForPrint] productNr', day);
    let isDisabledForPrint: boolean = true;
    let servingDay = this.findServingDayForPrint(productNr, day, line);
    for (let itemOrderBulk in this.simpleOderModelArray) {
      switch (day) {
        case 'Tuesday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Tuesday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Wednesday') &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0
          ) {
            console.debug(
              '[isDisabledForPrint] Tuesday isProductChecked',
              true
            );
            isDisabledForPrint = false;
          }
          break;

        case 'Thursday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Thursday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Friday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Saturday') &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0
          ) {
            console.debug(
              '[isDisabledForPrint] Thursday isProductChecked',
              true
            );
            isDisabledForPrint = false;
          }

          break;

        case 'Sunday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Sunday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Monday') &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0
          ) {
            console.debug('[isDisabledForPrint] Sunday isProductChecked', true);
            isDisabledForPrint = false;
          }
          break;

        default: {
          console.log('The Day is invalid');
        }
      }
    }
    console.debug(
      '[isDisabledForPrint] isDisabledForPrint',
      isDisabledForPrint
    );
    return isDisabledForPrint;
  }
  // --------------------Binary Check Orders --------------------------------
  binaryCheckState(productNr: number, day: string, line: number) {
    console.log('In binaryCheckState');
    for (let itemOrderBulk in this.simpleOderModelArray) {
      switch (day) {
        case 'Tuesday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Wednesday'
          ) {
            this.simpleOderModelArray[itemOrderBulk].orderNumber = 0;
            this.simpleOderModelArray[itemOrderBulk].sumOder = 0;
          }
          break;
        case 'Wednesday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Tuesday'
          ) {
            this.simpleOderModelArray[itemOrderBulk].orderNumber = 0;
            this.simpleOderModelArray[itemOrderBulk].sumOder = 0;
          }
          break;
        case 'Thursday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            (this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Friday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Saturday')
          ) {
            this.simpleOderModelArray[itemOrderBulk].orderNumber = 0;
            this.simpleOderModelArray[itemOrderBulk].sumOder = 0;
          }

          break;
        case 'Friday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            (this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Thursday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Saturday')
          ) {
            this.simpleOderModelArray[itemOrderBulk].orderNumber = 0;
            this.simpleOderModelArray[itemOrderBulk].sumOder = 0;
          }

          break;
        case 'Saturday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            (this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Thursday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Friday')
          ) {
            this.simpleOderModelArray[itemOrderBulk].orderNumber = 0;
            this.simpleOderModelArray[itemOrderBulk].sumOder = 0;
          }
          break;
        case 'Sunday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Monday'
          ) {
            this.simpleOderModelArray[itemOrderBulk].orderNumber = 0;
            this.simpleOderModelArray[itemOrderBulk].sumOder = 0;
          }
          break;
        case 'Monday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == productNr &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Sunday'
          ) {
            this.simpleOderModelArray[itemOrderBulk].orderNumber = 0;
            this.simpleOderModelArray[itemOrderBulk].sumOder = 0;
          }
          break;
        default: {
          console.log('The Day is invalid');
        }
      }
    }
  }

  // ------------------------------------------------------------------------
  // ----------------------Binary Check State Print -------------------------
  binaryCheckStatePrint(
    orderDishIdTCur: number,
    day: string,
    sideDish: boolean,
    line: number
  ) {
    console.log('[binaryCheckStatePrint]  orderDishIdTCur', orderDishIdTCur);
    console.log('[binaryCheckStatePrint] day', day);
    console.log('[binaryCheckStatePrint] sideDish', sideDish);
    for (let itemOrderBulk in this.simpleOderModelArray) {
      switch (day) {
        case 'Tuesday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['orderDishId'] !=
              orderDishIdTCur ||
              this.simpleOderModelArray[itemOrderBulk]['line'] != line) &&
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Tuesday' &&
            this.simpleOderModelArray[itemOrderBulk]['sideDish'] == sideDish
          ) {
            this.simpleOderModelArray[itemOrderBulk].print = false;
          }
          break;
        case 'Wednesday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['orderDishId'] !=
              orderDishIdTCur ||
              this.simpleOderModelArray[itemOrderBulk]['line'] != line) &&
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Wednesday' &&
            this.simpleOderModelArray[itemOrderBulk]['sideDish'] == sideDish
          ) {
            this.simpleOderModelArray[itemOrderBulk].print = false;
          }
          break;
        case 'Thursday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['orderDishId'] !=
              orderDishIdTCur ||
              this.simpleOderModelArray[itemOrderBulk]['line'] != line) &&
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Thursday' &&
            this.simpleOderModelArray[itemOrderBulk]['sideDish'] == sideDish
          ) {
            this.simpleOderModelArray[itemOrderBulk].print = false;
          }

          break;
        case 'Friday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['orderDishId'] !=
              orderDishIdTCur ||
              this.simpleOderModelArray[itemOrderBulk]['line'] != line) &&
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Friday' &&
            this.simpleOderModelArray[itemOrderBulk]['sideDish'] == sideDish
          ) {
            this.simpleOderModelArray[itemOrderBulk].print = false;
          }

          break;
        case 'Saturday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['orderDishId'] !=
              orderDishIdTCur ||
              this.simpleOderModelArray[itemOrderBulk]['line'] != line) &&
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Saturday' &&
            this.simpleOderModelArray[itemOrderBulk]['sideDish'] == sideDish
          ) {
            this.simpleOderModelArray[itemOrderBulk].print = false;
          }
          break;
        case 'Sunday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['orderDishId'] !=
              orderDishIdTCur ||
              this.simpleOderModelArray[itemOrderBulk]['line'] != line) &&
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Sunday' &&
            this.simpleOderModelArray[itemOrderBulk]['sideDish'] == sideDish
          ) {
            this.simpleOderModelArray[itemOrderBulk].print = false;
          }
          break;
        case 'Monday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['orderDishId'] !=
              orderDishIdTCur ||
              this.simpleOderModelArray[itemOrderBulk]['line'] != line) &&
            this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Monday' &&
            this.simpleOderModelArray[itemOrderBulk]['sideDish'] == sideDish
          ) {
            this.simpleOderModelArray[itemOrderBulk].print = false;
          }
          break;
        default: {
          console.log('[binaryCheckStatePrint] The Day is invalid');
        }
      }
    }
  }

  // ----------------------Binary Check State Print -------------------------

  // ----------------------Get Delivert Day From Serving---------------
  getDeliveryDay(servingDay: string) {
    let deliveryDay = servingDay;
    switch (servingDay) {
      case 'Tuesday':
        deliveryDay = 'Tuesday';

        break;
      case 'Wednesday':
        deliveryDay = 'Tuesday';

        break;

      case 'Thursday':
        deliveryDay = 'Thursday';
        break;
      case 'Friday':
        deliveryDay = 'Thursday';
        break;
      case 'Saturday':
        deliveryDay = 'Thursday';
        break;

      case 'Sunday':
        deliveryDay = 'Saturday';
        break;
      case 'Monday':
        deliveryDay = 'Saturday';
        break;

      default: {
        console.log('[getDeliveryDay] The servingDay is invalid');
      }
    }
    return deliveryDay;
  }
  // ------------------------isServingDaysChecked------------------------------------------
  isServingDaysChecked(dishIdTCur: number, deliveryDay: string, line: number) {
    console.log('[isServingDaysChecked] dishIdTCur', dishIdTCur);
    console.log('[isServingDaysChecked] deliveryDay', deliveryDay);
    let isServingDaysChecked: boolean = false;
    for (let itemOrderBulk in this.simpleOderModelArray) {
      switch (deliveryDay) {
        case 'Tuesday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Tuesday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Wednesday') &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == dishIdTCur &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0
          ) {
            console.debug(
              '[isProductSelectedForPrint] Tuesday isProductChecked',
              true
            );
            isServingDaysChecked = true;
          }
          break;

        case 'Thursday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Thursday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Friday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Saturday') &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == dishIdTCur &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0
          ) {
            console.debug(
              '[isProductSelectedForPrint] Thursday isProductChecked',
              true
            );
            isServingDaysChecked = true;
          }

          break;

        case 'Sunday':
          if (
            (this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Sunday' ||
              this.simpleOderModelArray[itemOrderBulk]['Day'] == 'Monday') &&
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == dishIdTCur &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0
          ) {
            console.debug(
              '[isServingDaysChecked] Sunday isProductChecked',
              true
            );
            isServingDaysChecked = true;
          }
          break;

        default: {
          console.log('[isServingDaysChecked] The Day is invalid');
        }
      }
    }
    console.debug(
      '[isServingDaysChecked] isServingDaysChecked',
      isServingDaysChecked
    );
    return isServingDaysChecked;
  }

  // -----------------------get servinDay for dishId----------------------------
  findServingDayForPrint(
    dishIdTCur: number,
    deliveryDay: string,
    line: number
  ) {
    console.log('[findServingDayForPrint] dishIdTCur', dishIdTCur);
    console.log('[findServingDayForPrint] deliveryDay', deliveryDay);
    let servingDayForPrint = '';
    for (let itemOrderBulk in this.simpleOderModelArray) {
      switch (deliveryDay) {
        case 'Tuesday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == dishIdTCur &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0
          ) {
            servingDayForPrint = this.simpleOderModelArray[itemOrderBulk][
              'Day'
            ];
          }
          break;

        case 'Thursday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == dishIdTCur &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0
          ) {
            servingDayForPrint = this.simpleOderModelArray[itemOrderBulk][
              'Day'
            ];
          }

          break;

        case 'Sunday':
          if (
            this.simpleOderModelArray[itemOrderBulk]['dishId'] == dishIdTCur &&
            this.simpleOderModelArray[itemOrderBulk]['line'] == line &&
            this.simpleOderModelArray[itemOrderBulk]['sumOder'] != 0
          ) {
            servingDayForPrint = this.simpleOderModelArray[itemOrderBulk][
              'Day'
            ];
          }
          break;

        default: {
          console.log('[findServingDayForPrint] The Day is invalid');
        }
      }
    }
    console.debug(
      '[findServingDayForPrint] servingDayForPrint',
      servingDayForPrint
    );
    return servingDayForPrint;
  }

  // --------------------------isServingDayNotChecked--------------

  isServingDayNotChecked(
    dishIdTCur: number,
    servingDay: string,
    line: number
  ): boolean {
    let isServingDayNotChecked = false;
    for (let oder in this.simpleOderModelArray) {
      if (
        (this.simpleOderModelArray[oder].dishId != dishIdTCur ||
          this.simpleOderModelArray[oder].line != line) &&
        this.simpleOderModelArray[oder]['Day'] == servingDay &&
        this.simpleOderModelArray[oder]['sumOder'] != 0
      ) {
        isServingDayNotChecked = false;
        break;
      } else {
        isServingDayNotChecked = true;
      }
    }

    console.log(
      '[isServingDayNotChecked] isServingDayNotChecked',
      isServingDayNotChecked
    );
    return isServingDayNotChecked;
  }

  getVideosafeSrc(videoUrl: string) {
    if (videoUrl !== undefined && videoUrl != '') {
      return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://www.youtube.com/embed/nOIwHaiojDI'
      );
    }
  }

  // ---------------------------PopUp-----------------------------------

  goBack() {
    this.continueSwitching = false;
    if (this.isLeavingPage) {
      this.dataShareService.canToogleFromPopUp = false;
    }
    this.isLeavingPage = false;
    this.modalService.dismissAll();
  }

  continue() {
    let toggledDay = this.localStorageService.get('toggledDayBulk');
    console.log('[continue] toggledDay', toggledDay);
    console.log(
      '[continue] this.simpleOderModelArray',
      this.simpleOderModelArray
    );
    // this.dishAllocationsOrderDays = JSON.parse(JSON.stringify(this.dishAllocationsOrderDaysAll));

    switch (toggledDay) {
      case 'Tuesday':
        console.log('[continue] In Tuesday');
        this.localStorageService.set(
          this.simpleOderModelArrayId,
          this.canselChangingsForDay(this.simpleOderModelArray, 'Tuesday')
        );
        this.localStorageService.set(
          this.simpleOderModelArrayId,
          this.canselChangingsForDay(this.simpleOderModelArray, 'Wednesday')
        );
        this.simpleOderModelArray.length = 0;
        this.filterForDishWithServingDate(
          this.dishAllocationsOrderDays['Tuesday'],
          'Tuesday'
        );
        break;
      case 'Thursday':
        this.localStorageService.set(
          this.simpleOderModelArrayId,
          this.canselChangingsForDay(this.simpleOderModelArray, 'Thursday')
        );
        this.localStorageService.set(
          this.simpleOderModelArrayId,
          this.canselChangingsForDay(this.simpleOderModelArray, 'Friday')
        );
        this.localStorageService.set(
          this.simpleOderModelArrayId,
          this.canselChangingsForDay(this.simpleOderModelArray, 'Saturday')
        );

        this.simpleOderModelArray.length = 0;
        this.filterForDishWithServingDate(
          this.dishAllocationsOrderDays['Thursday'],
          'Thursday'
        );

        break;
      case 'Saturday':
        this.localStorageService.set(
          this.simpleOderModelArrayId,
          this.canselChangingsForDay(this.simpleOderModelArray, 'Sunday')
        );
        this.localStorageService.set(
          this.simpleOderModelArrayId,
          this.canselChangingsForDay(this.simpleOderModelArray, 'Monday')
        );
        this.simpleOderModelArray.length = 0;
        this.filterForDishWithServingDate(
          this.dishAllocationsOrderDays['Sunday'],
          'Sunday'
        );

        break;
      default:
        this.localStorageService.set(
          this.simpleOderModelArrayId,
          this.canselChangingsForDay(this.simpleOderModelArray, 'Tuesday')
        );
        this.localStorageService.set(
          this.simpleOderModelArrayId,
          this.canselChangingsForDay(this.simpleOderModelArray, 'Wednesday')
        );
        this.simpleOderModelArray.length = 0;
        this.filterForDishWithServingDate(
          this.dishAllocationsOrderDays['Tuesday'],
          'Tuesday'
        );
    }

    this.isQuantitiesChanged = false;
    console.debug(
      '[continue] this.simpleOderModelArray',
      this.simpleOderModelArray
    );
    console.debug(
      '[continue] this.simpleOderModelArrayLOCAL',
      this.localStorageService.get(this.simpleOderModelArrayId)
    );
    this.continueSwitching = true;
    if (!this.isLeavingPage && !this.changedShiftWeek) {
      switch (this.nextDay) {
        case 'Tuesday':
          this.switchTuesday();
          this.continueSwitching = false;
          break;
        case 'Thursday':
          this.switchThursday();
          this.continueSwitching = false;
          break;
        case 'Saturday':
          this.switchSaturday();
          this.continueSwitching = false;

          break;
        default: {
        }
      }
      this.dataShareService.canToogleFromPopUp = true;
      this.modalService.dismissAll();
    } else if (this.changedShiftWeek) {
      this.changedShiftWeek = false;
      console.debug('[continue]', this.nextShiftWeek);
      this.switchWeek(this.nextShiftWeek);
      this.dataShareService.canToogleFromPopUp = true;
      this.continueSwitching = false;
      this.modalService.dismissAll();
    } else {
      this.dataShareService.canToogleFromPopUp = true;
      this.isLeavingPage = false;
      let nextStateUrl = this.localStorageService.get('nextState');
      console.log('[continue] nextState', nextStateUrl);
      this.modalService.dismissAll();
      this.router.navigateByUrl(nextStateUrl);
    }
  }
  // --------------------------------------------------------------------
  canDeactivate(): boolean | Observable<boolean> {
    this.isLeavingPage = true;

    if (this.isQuantitiesChanged) {
      this.openWindowCustomClass(this.contentPopUp);

      return false;
    } else {
      this.isLeavingPage = false;
      return true;
    }
  }

  // ------------------------------ Get Info ----------------------------------------
  getInfo(itemDishEl: any) {
    console.debug('[getInfo] itemDishEl', itemDishEl['groups']);
    this.groupsArr = [];
    this.groupsArrText = '';
    for (let itemGroup in itemDishEl['groups']) {
      this.groupsArr.push(itemDishEl['groups'][itemGroup]['name']);
      this.groupsArrText += itemDishEl['groups'][itemGroup]['name'] + '\n';
    }

    console.debug('[getInfo] this.groupsArrText', this.groupsArrText);
    console.debug('[getInfo] groupsArr', this.groupsArr);
  }

  openInfoPopUp(content) {
    this.openWindowCustomClass(content);
  }

  setTimer(interval: number): Observable<number> {
    return timer(interval);
  }
  openWindowCustomClassDone(content) {
    this.modalService.open(content, {
      centered: true,
      windowClass: 'darkModalCart',
    });
  }

  setSendedOrderClearChangedAll(simpleOderModelArray: any) {
    for (var oder in simpleOderModelArray) {
      if (simpleOderModelArray[oder]['changed'] == true) {
        simpleOderModelArray[oder]['changed'] = false;
        simpleOderModelArray[oder]['sended'] = true;
      }
    }
    console.debug(
      '[setSendedOrderClearChanged] simpleOderModelArray',
      simpleOderModelArray
    );
    return simpleOderModelArray;
  }

  clearChangedAll(simpleOderModelArray: any) {
    for (var oder in simpleOderModelArray) {
      if (simpleOderModelArray[oder]['changed'] == true) {
        simpleOderModelArray[oder]['changed'] = false;
      }
    }
    console.debug(
      '[setSendedOrderClearChanged] simpleOderModelArray',
      simpleOderModelArray
    );
    return simpleOderModelArray;
  }

  getServingDayForDietTypeNr30(
    deliveryDay: string,
    productNr: number,
    line: number,
    itemDishEl: any
  ) {
    console.log('[getServingDayForDietTypeNr30] deliveryDay', deliveryDay);
    console.log('[getServingDayForDietTypeNr30] productNr', productNr);
    console.log('[getServingDayForDietTypeNr30] line', line);
    console.log('[getServingDayForDietTypeNr30] itemDishEl', itemDishEl);

    this.currentServingDayForDietTypeNr30Arr = [];
    if (itemDishEl['servingDate'] && itemDishEl['servingDate'] != '') {
      let servDay = this.dateService.getNameDayFromISODateYYYYMMDD(
        itemDishEl['servingDate']
      );
      console.log('[getServingDayForDietTypeNr30] servDay', servDay);
      switch (servDay) {
        case 'Tuesday':
          this.previosPrNr = productNr;
          this.previosLine = line;
          this.currentServingDayForDietTypeNr30Arr.push('Tuesday');
          this.curNumberOfServingDayForDietTypeNr30 = 2;
          if (
            this.curNumberOfServingDayForDietTypeNr30 >
            this.numberOfDishesForDietTypeNr30
          ) {
            this.curNumberOfServingDayForDietTypeNr30 = 1;
          }

          break;
        case 'Wednesday':
          this.currentServingDayForDietTypeNr30Arr.push('Wednesday');
          this.curNumberOfServingDayForDietTypeNr30 = 1;

          break;
        case 'Thursday':
          this.previosPrNr = productNr;
          this.previosLine = line;
          this.currentServingDayForDietTypeNr30Arr.push('Thursday');
          this.curNumberOfServingDayForDietTypeNr30 = 2;
          if (
            this.curNumberOfServingDayForDietTypeNr30 >
            this.numberOfDishesForDietTypeNr30
          ) {
            this.curNumberOfServingDayForDietTypeNr30 = 1;
          }

          break;
        case 'Friday':
          this.previosLine2 = line;
          this.currentServingDayForDietTypeNr30Arr.push('Friday');
          this.curNumberOfServingDayForDietTypeNr30 = 3;
          if (
            this.curNumberOfServingDayForDietTypeNr30 >
            this.numberOfDishesForDietTypeNr30
          ) {
            this.curNumberOfServingDayForDietTypeNr30 = 1;
          }

          break;
        case 'Saturday':
          this.currentServingDayForDietTypeNr30Arr.push('Saturday');
          this.curNumberOfServingDayForDietTypeNr30 = 1;

          break;
        case 'Sunday':
          this.previosPrNr = productNr;
          this.previosLine = line;
          this.currentServingDayForDietTypeNr30Arr.push('Sunday');
          this.curNumberOfServingDayForDietTypeNr30 = 2;
          if (
            this.curNumberOfServingDayForDietTypeNr30 >
            this.numberOfDishesForDietTypeNr30
          ) {
            this.curNumberOfServingDayForDietTypeNr30 = 1;
          }

          break;
        case 'Monday':
          this.currentServingDayForDietTypeNr30Arr.push('Monday');
          this.curNumberOfServingDayForDietTypeNr30 = 1;

          break;
        default: {
        }
      }
    } else {
      switch (deliveryDay) {
        case 'Tuesday':
          if (this.curNumberOfServingDayForDietTypeNr30 == 1) {
            this.currentServingDayForDietTypeNr30Arr.push('Tuesday');
            this.curNumberOfServingDayForDietTypeNr30 = 2;
            if (
              this.curNumberOfServingDayForDietTypeNr30 >
              this.numberOfDishesForDietTypeNr30
            ) {
              this.curNumberOfServingDayForDietTypeNr30 = 1;
            }
          } else if (this.curNumberOfServingDayForDietTypeNr30 == 2) {
            this.currentServingDayForDietTypeNr30Arr.push('Wednesday');
            this.curNumberOfServingDayForDietTypeNr30 = 1;
          } else {
            this.currentServingDayForDietTypeNr30Arr.push('Tuesday');
            this.curNumberOfServingDayForDietTypeNr30 = 1;
          }

          break;
        case 'Thursday':
          if (this.curNumberOfServingDayForDietTypeNr30 == 1) {
            this.currentServingDayForDietTypeNr30Arr.push('Thursday');
            this.curNumberOfServingDayForDietTypeNr30 = 2;
            if (
              this.curNumberOfServingDayForDietTypeNr30 >
              this.numberOfDishesForDietTypeNr30
            ) {
              this.curNumberOfServingDayForDietTypeNr30 = 1;
            }
          } else if (this.curNumberOfServingDayForDietTypeNr30 == 2) {
            this.currentServingDayForDietTypeNr30Arr.push('Friday');
            this.curNumberOfServingDayForDietTypeNr30 = 3;
            if (
              this.curNumberOfServingDayForDietTypeNr30 >
              this.numberOfDishesForDietTypeNr30
            ) {
              this.curNumberOfServingDayForDietTypeNr30 = 1;
            }
          } else if (this.curNumberOfServingDayForDietTypeNr30 == 3) {
            this.currentServingDayForDietTypeNr30Arr.push('Saturday');
            this.curNumberOfServingDayForDietTypeNr30 = 1;
          } else {
            this.currentServingDayForDietTypeNr30Arr.push('Thursday');
            this.curNumberOfServingDayForDietTypeNr30 = 1;
          }

          break;
        case 'Sunday':
          if (this.curNumberOfServingDayForDietTypeNr30 == 1) {
            this.currentServingDayForDietTypeNr30Arr.push('Sunday');
            this.curNumberOfServingDayForDietTypeNr30 = 2;
            if (
              this.curNumberOfServingDayForDietTypeNr30 >
              this.numberOfDishesForDietTypeNr30
            ) {
              this.curNumberOfServingDayForDietTypeNr30 = 1;
            }
          } else if (this.curNumberOfServingDayForDietTypeNr30 == 2) {
            this.currentServingDayForDietTypeNr30Arr.push('Monday');
            this.curNumberOfServingDayForDietTypeNr30 = 1;
          } else {
            this.currentServingDayForDietTypeNr30Arr.push('Sunday');
            this.curNumberOfServingDayForDietTypeNr30 = 1;
          }

          break;
        default:
          if (this.curNumberOfServingDayForDietTypeNr30 == 1) {
            this.currentServingDayForDietTypeNr30Arr.push('Tuesday');
            this.curNumberOfServingDayForDietTypeNr30 = 2;
            if (
              this.curNumberOfServingDayForDietTypeNr30 >
              this.numberOfDishesForDietTypeNr30
            ) {
              this.curNumberOfServingDayForDietTypeNr30 = 1;
            }
          } else if (this.curNumberOfServingDayForDietTypeNr30 == 2) {
            this.currentServingDayForDietTypeNr30Arr.push('Wednesday');
            this.curNumberOfServingDayForDietTypeNr30 = 1;
          }
      }
    }

    console.debug(
      '[getServingDayForDietTypeNr30] this.currentServingDayForDietTypeNr30Arr',
      this.currentServingDayForDietTypeNr30Arr
    );
    return this.currentServingDayForDietTypeNr30Arr;
  }

  getNumberOfDishesForDietTypeNr30(dishAllocationsOrderDay: any) {
    console.log(
      '[getNumberOfDishesForDietTypeNr30] dishAllocationsOrderDay',
      dishAllocationsOrderDay
    );

    let numberOfDishesForDietTypeNr30 = 0;
    if (dishAllocationsOrderDay && dishAllocationsOrderDay.length != 0) {
      for (let bulkDishesItem in dishAllocationsOrderDay) {
        if (
          dishAllocationsOrderDay[bulkDishesItem]['menuLine']['dietTypeNr'] ==
          30
        ) {
          numberOfDishesForDietTypeNr30++;
        }
      }
    }
    console.log(
      '[getNumberOfDishesForDietTypeNr30] numberOfDishesForDietTypeNr30',
      numberOfDishesForDietTypeNr30
    );
    return numberOfDishesForDietTypeNr30;
  }

  selectUnselectDishChoice(dishChoice:any,dishChoices:any){


    console.log(this.user)
    //this.departmentId

    let select = dishChoice.selected
    for(let i=0;i<dishChoices.length;i++){
      dishChoices[i].selected = false
    }
    console.log('can-3')
    
    let departmentGroupId:number
    for(let i=0;i<this.company.departments.length;i++){
      if (this.company.departments[i].id == this.departmentId){
        console.log('this.user.departments[i]')
        console.log(this.company.departments[i])
        departmentGroupId = this.company.departments[i].departmentGroupId
      }
    }

    let departmentsInGoup = this.company.departments.filter(departmentInSameGroup)
    
    function departmentInSameGroup(department:any){
      if (department.departmentGroupId == departmentGroupId){
        return department
      }
    }

    //getBulkOrderCurrentDate

    console.log('departmentsInGoup')
    console.log(departmentsInGoup)
    // for eah department check if anythin was changed/ordered if yes block change

    console.log(this.dateService.getISODateYYYMMDDforDay(this.localStorageService.get('toggledDayBulk'),this.shiftWeek))
    let body = {
      date: this.currentGuestChefDate,
      departmentId:null,
      data: {}
    }

    if(select){
      dishChoice.selected = false
    }else{
      dishChoice.selected = true
      body.data = dishChoice
    }

    
    for(let i=0;i<departmentsInGoup.length;i++){
      
      body.departmentId = departmentsInGoup[i].id
      this.dataSource.saveSelection(body).subscribe(
        (data) => {
        console.log(data)
        })
      }
    }
   
    isItAllowdToChangeGuestChefDish(){
      
      //---------------------Loader-------------------
      this.loader = true
		  var loader = document.getElementById('loader');
      loader.style.visibility = 'visible';

      let departmentGroupId:number
      for(let i=0;i<this.company.departments.length;i++){
        if (this.company.departments[i].id == this.departmentId){
          console.log('this.user.departments[i]')
          console.log(this.company.departments[i])
          departmentGroupId = this.company.departments[i].departmentGroupId
        }
      }
  
      let departmentsInGoup = this.company.departments.filter(departmentInSameGroup)
      
      function departmentInSameGroup(department:any){
        if (department.departmentGroupId == departmentGroupId){
          return department
        }
      }

      let dateYYYYMMDD =  this.dateService.getISODateYYYMMDDforDay(this.localStorageService.get('toggledDayBulk'),this.shiftWeek)
      console.log('dateYYYYMMDD')
      console.log(dateYYYYMMDD)

      let httpRequstsCounter = departmentsInGoup.length
      for(let i=0;i<departmentsInGoup.length;i++){
        this.dataSource.getBulkOrderFromDepartmentCurrentDate(dateYYYYMMDD,departmentsInGoup[i].id).subscribe(
          (data) => {
          httpRequstsCounter --
          console.log(data)
          let servings:any=data.servings
          for(let i1=0;i1<servings.length;i1++){
            if(servings[i1].isGuestChef)
              for(let i2=0;i2<servings[i1].mainDishes.length;i2++){
                if(servings[i1].mainDishes[i2].menuLine.quantity>0){
                  this.isItallowedToChangeGuestChefDishes = false
                  this.loader = false
                  loader.style.visibility = 'hidden';
                  break
                }
                for(let i3=0;i3<servings[i1].mainDishes[i2].dietTypeGroups.length;i3++){
                  for(let i4=0;i4<servings[i1].mainDishes[i2].dietTypeGroups[i3].dietTypes.length;i4++){
                    if(servings[i1].mainDishes[i2].dietTypeGroups[i3].dietTypes[i4].menuLine.quantity>0){
                      this.isItallowedToChangeGuestChefDishes = false
                      this.loader = false
                      loader.style.visibility = 'hidden';
                      break
                    }
                  }
                }
              }
              for(let i2=0;i2<servings[i1].sideDishes.length;i2++){
                if(servings[i1].sideDishes[i2].menuLine.quantity>0){
                  this.isItallowedToChangeGuestChefDishes = false
                  this.loader = false
                  loader.style.visibility = 'hidden';
                  break
                }
                for(let i3=0;i3<servings[i1].sideDishes[i2].dietTypeGroups.length;i3++){
                  for(let i4=0;i4<servings[i1].sideDishes[i2].dietTypeGroups[i3].dietTypes.length;i4++){
                    if(servings[i1].sideDishes[i2].dietTypeGroups[i3].dietTypes[i4].menuLine.quantity>0){
                      this.isItallowedToChangeGuestChefDishes = false
                      this.loader = false
                      loader.style.visibility = 'hidden';
                      break
                    }
                  }
                }
              }
            }
            if (httpRequstsCounter<1){
              this.loader = false
              loader.style.visibility = 'hidden';
            }
          }
          
          )
          

        }
        
    }
    
    

}
