import { UserIdentityModel } from "./userIdentityModel.model";
import { AnswerModel } from "./answerModel.model";


export class QuestionModel {
    constructor(
            public	id:number,
			public	message:string,
			public	isNew?:boolean,
			public	createdOnUtc?:Date,
			public	createdBy?:UserIdentityModel,
			public	answers?:AnswerModel[],
			public	isAnswered?:boolean,
			public	isSolved?:boolean,
			public	isFavourite?:boolean


           	) { }
}
