import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { RestDataSource } from '../repository/rest.datasource';

import 'rxjs/add/operator/map';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from './localStorage.service';

import { Observer } from 'rxjs/Observer';
//import * as Rx from 'rxjs/Rx';
import { timer } from 'rxjs';

@Injectable()
export class AuthService {
  flagExpired: boolean = false;
  source: any;
  public userInfoOrderingModel: any[] = [];
  constructor(
    @Inject('datasource') private datasource: RestDataSource,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  authenticate(username: string, password: string): Observable<boolean> {
    console.log('In authenticate');
    return this.datasource.authenticate(username, password);
  }
  /*
    authenticatedSave (user: string, pass: string, userId:string, token: string, token_time:number){

        this.localStorageService.set( 'savedUsername', user);
        this.localStorageService.set( 'savedPassword', pass);
        this.localStorageService.set( 'savedUserId', userId);
        this.localStorageService.set( 'auth_token', token);
        this.localStorageService.set( 'tokenTimeout', token_time);


    }
    */

  authenticatedSave(userId: string, token: string, token_time: number) {
    console.log('{AuthService} [authenticatedSave] userId', userId);
    console.log('{AuthService} [authenticatedSave] token', token);
    console.log('{AuthService} [authenticatedSave] token_time', token_time);
    this.localStorageService.set('savedUserId', userId);
    this.localStorageService.set('auth_token', token);
    this.localStorageService.set('tokenTimeout', token_time);
  }

  setTimer(tokenTime: number): Observable<number> {
    this.source = timer(tokenTime);

    return this.source;
  }
  getTimeoutExpired(): number {
    console.log('this.datasource.expires_in: ' + this.datasource.expires_in);
    return this.datasource.expires_in;
  }
  authenticatedError(): any {
    return this.datasource.error;
  }

  isAuthenticated(): boolean {
    // console.log("this.localStorageService.get( 'auth_token')= " +this.localStorageService.get( 'auth_token'));
    return this.localStorageService.get('auth_token') != null;
  }

  getUserId() {
    return this.datasource.userId;
  }

  getAuthToken() {
    return this.datasource.auth_token;
  }

  getUserName(): string {
    return this.datasource.username;
  }

  logOut() {
    // if(!((this.localStorageService.get( 'isRememberMe')) && (this.localStorageService.get( 'isRememberMe')==true))){
    this.authenticationClear();
    // }
    this.router.navigateByUrl('/auth');
  }
  authenticationClear() {
    //  this.localStorageService.set( 'savedUsername', null);
    //  this.localStorageService.set( 'savedPassword', null);

    console.debug('[authenticationClear]');
    this.localStorageService.set('isRememberMe', false);
    this.localStorageService.set('savedUserId', null);
    this.localStorageService.set('auth_token', null);
    this.localStorageService.set('tokenTimeout', null);
  }
}
