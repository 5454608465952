import { Component, Input, OnInit } from '@angular/core';
import { TopMessage } from 'src/app/model/top-message.model';

@Component({
  selector: 'app-top-message',
  templateUrl: './top-message.component.html',
  styleUrls: ['./top-message.component.scss']
})
export class TopMessageComponent implements OnInit {
  public topMessage: TopMessage;

  @Input() parentTopMessage: TopMessage;
  @Input() parentTopMessageClass: string;
  constructor() { }

  ngOnInit() {
    this.topMessage = this.parentTopMessage;
  }

  initTopMessageWindow(topMessage: TopMessage, topMessageClass?: string) {
    this.topMessage = topMessage;
  }

}
