export class GuestChefModel {
    constructor(
	      public  Monday:boolean = false,
        public  Tuesday: boolean = false,
        public  Wednesday: boolean = false,
        public  Thursday: boolean = false,
        public  Friday: boolean = false,
        public  Saturday: boolean = false,
        public	Sunday:boolean = false
    ) { }


}
