import { Injectable } from '@angular/core';
import { GuestChefModel } from '../model/guestChefModel';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root'
})
export class GuestChefService {

constructor(private dateService: DateService) { }

  initEmptyGuestChefModel(): GuestChefModel {
    return new GuestChefModel();
  }
  initGuestChefModel(dishAllocationsWeekResponse: any): GuestChefModel {
    let guestChefModel = new GuestChefModel();
    if (dishAllocationsWeekResponse[0]
        && dishAllocationsWeekResponse[0]['guestChefDates']
    ) {
      let guestChefDates = dishAllocationsWeekResponse[0]['guestChefDates'];
      for (let guestChefDate of guestChefDates) {
        let day = this.dateService.getNameDayFromISODateYYYYMMDD(guestChefDate);
        if (day) {
          this.setGuestChefModelDay(guestChefModel, day, true);
        }
      }
    }
    return guestChefModel;
  }
  initGuestChefModelServings(dishAllocationsWeekResponse: any): GuestChefModel {
    console.log("🚀 ~ file: guest-chef.service.ts ~ line 31 ~ GuestChefService ~ initGuestChefModelServings ~ dishAllocationsWeekResponse", dishAllocationsWeekResponse)
    let guestChefModel = new GuestChefModel();
    if (dishAllocationsWeekResponse[0]
        && dishAllocationsWeekResponse[0]['servings']
    ) {
      for (let deliveryDay in dishAllocationsWeekResponse) {
        if (dishAllocationsWeekResponse[deliveryDay] && dishAllocationsWeekResponse[deliveryDay]['servings']) {
          let servings = dishAllocationsWeekResponse[deliveryDay]['servings'];
          for (let servingItem of servings) {
            if (servingItem && servingItem['isGuestChef'] && servingItem['servingDate']) {
              let day = this.dateService.getNameDayFromISODateYYYYMMDD(servingItem['servingDate']);
              if (day) {
                this.setGuestChefModelDay(guestChefModel, day, true);
              }
            }
          }
        }

      }

    }
    return guestChefModel;
  }
  setGuestChefModelDay(guestChefModel: GuestChefModel, day: string, value: boolean): void {
    guestChefModel[day] = value;
  }
  getGuestChefModelDay(guestChefModel: GuestChefModel, day: string): boolean {
    return guestChefModel[day];
  }
  isGuestChefModelDay(guestChefModel: GuestChefModel, day: string): boolean {
    if (guestChefModel) {
      return guestChefModel[day];
    }

  }
  isGuestChefForDeliveryDay(guestChefModel: GuestChefModel, deliveryDay: string): boolean {
    console.log('guestChefModel')
    console.log(guestChefModel)
    switch (deliveryDay) {
      case 'Tuesday':
        return (guestChefModel.Tuesday || guestChefModel.Wednesday);
        break;
      case 'Thursday':
        return (guestChefModel.Thursday || guestChefModel.Friday || guestChefModel.Saturday);
        break;
      case 'Saturday':
        return (guestChefModel.Sunday || guestChefModel.Monday);
        break;
    }
  }


}
