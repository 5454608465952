import { Dish } from "./dish.model";
import { OrderDishModel } from "./orderDishModel.model";




export class OrderModel  {
    constructor(
    		public	orderId?:number,
			public	statusId?:number,
			public	orderDate?:Date,
			public	menuCategoryId?:number,
			public	menuCategoryName?:string,
			public	menuCategorySortId?:number,
			public	parentMenuCategoryId?:number,
			public	parentMenuCategoryName?:string,
			public	user?:string,
			public	userId?:string,
			public	orderDishes?:OrderDishModel[],
			public	userFirstName?:string,
			public	userLastName?:string,
			public	userRoomNumber?:string,
			public	userRoomNumberInt?: number,
			public	servedBy?:string,
			public	isolation?:boolean	

				

           	) { }
}




