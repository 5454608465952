import { TopMessage } from './top-message.model';

export class TopMessageSimple extends TopMessage {

  constructor(
    public title: string,
    public titleClass: string = ''
  ) {
      super(
        title,
        titleClass,
        '',
        ''
      )
    }

}
