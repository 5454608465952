import { Injectable } from '@angular/core';
import { Dish } from '../model/dish.model';
import { AllocatedDishModel } from '../model/allocatedDishModel.model';
import { OrderModel } from '../model/orderModel.model';

import { RestDataSource } from './rest.datasource';
import { DateService} from '../service/date.service';
import { concat, from, Observable, Observer, Subscription } from 'rxjs';
import { BulkOrderMenuLineEditModel } from '../model/bulkOrderMenuLineEditModel.model';
import 'rxjs/add/operator/concatAll';
import 'rxjs/add/operator/concatMap';
import 'rxjs/add/operator/finally';
import 'rxjs/Rx';
import * as moment from 'moment';
import {QuestionModel} from '../model/questionModel.model';
import { MenuLine } from '../model/menuLine.model';
import { of } from 'rxjs';
import { concatAll} from 'rxjs/operators';
import { GuestChefModel } from '../model/guestChefModel';
import { GuestChefService } from '../service/guest-chef.service';

class BulkOrderMenuLineEditModelForDays{
    Sunday: BulkOrderMenuLineEditModel[];
    Monday: BulkOrderMenuLineEditModel[];
    Tuesday: BulkOrderMenuLineEditModel[];
    Wednesday: BulkOrderMenuLineEditModel[];
    Thursday: BulkOrderMenuLineEditModel[];
    Friday: BulkOrderMenuLineEditModel[];
    Saturday: BulkOrderMenuLineEditModel[];

    constructor() {

        this.Sunday = [];
        this.Monday = [];
        this.Tuesday = [];
        this.Wednesday = [];
        this.Thursday = [];
        this.Friday = [];
        this.Saturday = [];

    }

}

class DishAllocationsOrderDays{
  Sunday: any[];
    Monday: any[];
    Tuesday: any[];
    Wednesday: any[];
    Thursday: any[];
    Friday: any[];
    Saturday: any[];

    constructor() {

        this.Sunday = [];
        this.Monday = [];
        this.Tuesday = [];
        this.Wednesday = [];
        this.Thursday = [];
        this.Friday = [];
        this.Saturday = [];

    }

}

class SelectedWeekForPrintModel{

   week_0: string;

   week_1: string;

   week_2: string;

   week_3: string;

   week_4: string;



  constructor() {
   this.week_0 = undefined;

   this.week_1 = undefined;

   this.week_2 = undefined;

   this.week_3 = undefined;

   this.week_4 = undefined;


   }

}

@Injectable()
export class DishRepository {
   // private dishes: any[] = [];
    //private dishes:Observable<AllocatedDishModel[]> ;
    public dishes: AllocatedDishModel[] ;
    private allocatedDish: AllocatedDishModel;
    private categories: Observable<any>[];
    private streams$: Observable<any>[] = [];
    private streamsDaysTuesday$: Observable<any>[] = [];
    private streamsDaysThursday$: Observable<any>[] = [];
    private streamsDaysSaturday$: Observable<any>[] = [];
    private streamsTuesdayBulk$: Observable<any>[] = [];
    private streamsThursdayBulk$: Observable<any>[] = [];
    private streamsSaturdayBulk$: Observable<any>[] = [];
    private streamsTuesdayBulkOrder$: Observable<any>[] = [];
    private streamsThursdayBulkOrder$: Observable<any>[] = [];
    private streamsSaturdayBulkOrder$: Observable<any>[] = [];
    //private streamsCheckedBulkOrder$:Observable<any>[]=[];
    public  complete: boolean = false;
    public dishSubscription: Observable<any>;
  constructor(
    private dataSource: RestDataSource,
    private dateService: DateService,
    private guestChefService: GuestChefService
  ) {

    }

      getDishAllocationsCurrentDate(): Observable<any> {
        console.log('getDishAllocationsCurrentDate')
        var currentISODate = this.dateService.getCurrentISODate()
        return this.dataSource.getDishAllocationsCurrentDate(currentISODate);
    }





     getDishAllocationsAlltShiftWeek(shiftWeek: number): Observable<any> | AllocatedDishModel[] | any{
        for (var i = 0; i <= 6; i++){

           this.streams$[i] = this.dataSource.getDishAllocationsCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i).format('YYYY-MM-DD'))
             }

        const obsrv$ = Observable.forkJoin(this.streams$);

        return obsrv$;
    }

     getDishAllocationsDaysTuesdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any> | AllocatedDishModel[] | any{
      //console.log("shiftWeek", shiftWeek);
      //console.log("numberOfDays", numberOfDays);
      //console.log("dayStart", dayStart);

      let start = this.dateService.getIndexForDayInWeek(dayStart);


      for (var i = 0; i < numberOfDays; i++){

           this.streamsDaysTuesday$[i] = this.dataSource.getDishAllocationsCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + start).format('YYYY-MM-DD'))
           console.log('this.streamsDays$[i]', this.streamsDaysTuesday$[i]);
             }



      const obsrvDaysTuesday$ = Observable.forkJoin(this.streamsDaysTuesday$);

      return obsrvDaysTuesday$;
    }

    getDishAllocationsDaysThursdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any> | AllocatedDishModel[] | any{
      //console.log("shiftWeek", shiftWeek);
      //console.log("numberOfDays", numberOfDays);
      //console.log("dayStart", dayStart);

      let start = this.dateService.getIndexForDayInWeek(dayStart);


      for (var i = 0; i < numberOfDays; i++){

           this.streamsDaysThursday$[i] = this.dataSource.getDishAllocationsCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + start).format('YYYY-MM-DD'))
           console.log('this.streamsDays$[i]', this.streamsDaysThursday$[i]);
             }



      const obsrvDaysThursday$ = Observable.forkJoin(this.streamsDaysThursday$);

      return obsrvDaysThursday$;
    }

    getDishAllocationsDaysSaturdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any> | AllocatedDishModel[] | any{
      //console.log("shiftWeek", shiftWeek);
      //console.log("numberOfDays", numberOfDays);
      //console.log("dayStart", dayStart);
      let start = this.dateService.getIndexForDayInWeek(dayStart);


      for (var i = 0; i < numberOfDays; i++){

           this.streamsDaysSaturday$[i] = this.dataSource.getDishAllocationsCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + start).format('YYYY-MM-DD'))
           console.log('this.streamsDays$[i]', this.streamsDaysSaturday$[i]);
             }



      const obsrvDaysSaturday$ = Observable.forkJoin(this.streamsDaysSaturday$);

      return obsrvDaysSaturday$;
    }


     getUserInfo(userId: string): Observable<any> | AllocatedDishModel[] | any{

       return this.dataSource.getUserInfo(userId);
     }

      getUserInfoOrderingModel(userId: string){

      return this.dataSource.getUserInfoOrderingModel(userId);
    }

    doOrder(orderModel: OrderModel, userId: string){
      return this.dataSource.doOder(orderModel, userId);
    }


    sendMessage(questionModel: QuestionModel){
       return this.dataSource.sendQuestionToOnlineSupport(questionModel);
    }

    getAllQuestionsFromOnlineSupport(){
      return this.dataSource.getAllQuestionsFromOnlineSupport();
    }

     getAnswerFromOnlineSupportById(questionId: number): Observable<QuestionModel>{
       return this.dataSource.getAnswerFromOnlineSupportById(questionId);
     }

    getAllAnswerForCurrentUser(userQuestionArray: any): Observable<any>{
       let streamsAnswer$: Observable<any>[] = [];
       for (let question in userQuestionArray){
        console.log('[getAllAnswerForCurrentUser] question', question);
        streamsAnswer$[question] = this.dataSource.getAnswerFromOnlineSupportById(userQuestionArray[question]['id'])
       }
       let obsrvAllAnswers$ = Observable.forkJoin(streamsAnswer$);
       return obsrvAllAnswers$;
     }


    getBulkDishAlltShiftWeek(shiftWeek: number): Observable<any> | AllocatedDishModel[] | any{
        //for(var i=0; i<=6; i++){
          for (var i = 0; i < 3; i++){

           this.streamsTuesdayBulk$[i] = this.dataSource.getBulkDishsCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i).format('YYYY-MM-DD'))
             }

          const obsrvTuesdayBulk$ = Observable.forkJoin(this.streamsTuesdayBulk$);

          return obsrvTuesdayBulk$;
    }

    getBulkDishCurrentDayShiftWeek(shiftWeek: number, currentDay: string, numberOfDays: number): Observable<any> | AllocatedDishModel[] | any{
      let streamsCurrentDayBulk$: Observable<any>[] = [];
      let startCurrentDay = this.dateService.getIndexForDayInWeek(currentDay);
      for (var i = 0; i < numberOfDays; i++){

           streamsCurrentDayBulk$[i] = this.dataSource.getBulkDishsCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startCurrentDay).format('YYYY-MM-DD'))
             }

      const obsrvCurrentDayBulk$ = Observable.forkJoin(streamsCurrentDayBulk$);

      return obsrvCurrentDayBulk$;


    }


    getBulkDishTuesdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any> | AllocatedDishModel[] | any{

      let startTuesday = this.dateService.getIndexForDayInWeek(dayStart);
      for (var i = 0; i < numberOfDays; i++){

           this.streamsTuesdayBulk$[i] = this.dataSource.getBulkDishsCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'))
             }

      const obsrvTuesdayBulk$ = Observable.forkJoin(this.streamsTuesdayBulk$);

      return obsrvTuesdayBulk$;


    }

    getBulkDishThursdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any> | AllocatedDishModel[] | any{

       let startThursday = this.dateService.getIndexForDayInWeek(dayStart);
       for (var i = 0; i < numberOfDays; i++){

           this.streamsThursdayBulk$[i] = this.dataSource.getBulkDishsCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startThursday).format('YYYY-MM-DD'))
             }

       const obsrvThursdayBulk$ = Observable.forkJoin(this.streamsThursdayBulk$);

       return obsrvThursdayBulk$;

    }

     getBulkDishSaturdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any> | AllocatedDishModel[] | any{

        let startSaturday = this.dateService.getIndexForDayInWeek(dayStart);
        for (var i = 0; i < numberOfDays; i++){

           this.streamsSaturdayBulk$[i] = this.dataSource.getBulkDishsCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startSaturday).format('YYYY-MM-DD'))
             }

        const obsrvSaturdayBulk$ = Observable.forkJoin(this.streamsSaturdayBulk$);

        return obsrvSaturdayBulk$;

    }




    getBulkDishThreeDaysShiftWeek(shiftWeek: number, dayStart: string): Observable<any>{
      let streamsThreeDaysBulkDish$: Observable<any>[] = [];
      let startTuesday = this.dateService.getIndexForDayInWeek(dayStart);

      let i = 0;
      /*let momentShifted = moment().add(shiftWeek, 'w');
      let week = momentShifted
      let isoWeek2 = week.isoWeekday(2);
      let isoWeek = week.isoWeekday(i + startTuesday);
      let ISOdate = isoWeek.format('YYYY-MM-DD')*/
      streamsThreeDaysBulkDish$[0] = this.dataSource.getBulkDishsCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));
      i = 2;
      streamsThreeDaysBulkDish$[1] = this.dataSource.getBulkDishsCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));
      i = 4;
      streamsThreeDaysBulkDish$[2] = this.dataSource.getBulkDishsCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));

      let obsrvThreeDaysBulkDish$ = Observable.forkJoin(streamsThreeDaysBulkDish$);

      return obsrvThreeDaysBulkDish$;

    }


    getBulkOrderTuesdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any>{
      let streamsTuesdayBulkOrder$: Observable<any>[] = [];
      let startTuesday = this.dateService.getIndexForDayInWeek(dayStart);
      for (var i = 0; i < numberOfDays; i++){

           streamsTuesdayBulkOrder$[i] = this.dataSource.getBulkOrderCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'))
             }

      let obsrvTuesdayBulkOrder$ = Observable.forkJoin(streamsTuesdayBulkOrder$);

      return obsrvTuesdayBulkOrder$;

    }

    getBulkOrderThursdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any>{
       let streamsThursdayBulkOrder$: Observable<any>[] = [];
       let startThursday = this.dateService.getIndexForDayInWeek(dayStart);
       for (var i = 0; i < numberOfDays; i++){

           streamsThursdayBulkOrder$[i] = this.dataSource.getBulkOrderCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startThursday).format('YYYY-MM-DD'))
             }

       let obsrvThursdayBulkOrder$ = Observable.forkJoin(streamsThursdayBulkOrder$);

       return obsrvThursdayBulkOrder$;

    }

    getBulkOrderSaturdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any>{
      let streamsSaturdayBulkOrder$: Observable<any>[] = [];
      let startSaturday = this.dateService.getIndexForDayInWeek(dayStart);
      for (var i = 0; i < numberOfDays; i++){

           streamsSaturdayBulkOrder$[i] = this.dataSource.getBulkOrderCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startSaturday).format('YYYY-MM-DD'))
             }

      let obsrvSaturdayBulkOrder$ = Observable.forkJoin(streamsSaturdayBulkOrder$);

      return obsrvSaturdayBulkOrder$;

    }



     getBulkOrderThreeDaysShiftWeek(shiftWeek: number, dayStart: string): Observable<any>{
      let streamsThreeDaysBulkOrder$: Observable<any>[] = [];
      let startTuesday = this.dateService.getIndexForDayInWeek(dayStart);

      let i = 0;
      streamsThreeDaysBulkOrder$[0] = this.dataSource.getBulkOrderCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));
      i = 2;
      streamsThreeDaysBulkOrder$[1] = this.dataSource.getBulkOrderCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));
      i = 4;
      streamsThreeDaysBulkOrder$[2] = this.dataSource.getBulkOrderCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));

      let obsrvThreeDaysBulkOrder$ = Observable.forkJoin(streamsThreeDaysBulkOrder$);

      return obsrvThreeDaysBulkOrder$;

    }

    getBulkOrderAllDaysShiftWeek(shiftWeek: number, dayStart: string): Observable<any>{
      let streamsThreeDaysBulkOrder$: Observable<any>[] = [];
      let startTuesday = this.dateService.getIndexForDayInWeek(dayStart);

      for (let i = 0; i < 6; i++){
           streamsThreeDaysBulkOrder$[i] = this.dataSource.getBulkOrderCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));

        }
      let obsrvThreeDaysBulkOrder$ = Observable.forkJoin(streamsThreeDaysBulkOrder$);

      return obsrvThreeDaysBulkOrder$;

    }



    getBulkDishWeeksShiftWeek(dayStart: string, selectedWeekForPrintModel: SelectedWeekForPrintModel): Observable<any>{

      console.log('[getBulkDishWeeksShiftWeek] selectedWeekForPrintModel', selectedWeekForPrintModel);
      let obsrvWeeksBulkDish$ = [];
      for (let itemWeek in selectedWeekForPrintModel){
          let shiftWeek = selectedWeekForPrintModel[itemWeek]['week'];

          console.log('[getBulkDishWeeksShiftWeek] shiftWeek', shiftWeek);
          if (shiftWeek !== undefined){
            let tempYear =  selectedWeekForPrintModel[itemWeek]['yearYYYY'];
            let momentDate = moment();
            let momentDateTemp = momentDate.clone();

            momentDateTemp.set('year', tempYear);
            console.log('[getBulkDishWeeksShiftWeek] momentDateTemp.year()', momentDateTemp.year());
            momentDateTemp.isoWeek(shiftWeek);
            console.log('[getBulkDishWeeksShiftWeek] momentDateTemp.isWeek(shiftWeek)', momentDateTemp);

            let streamsThreeDaysBulkDish$: Observable<any>[] = [];
            let startTuesday = this.dateService.getIndexForDayInWeek(dayStart);

            let i = 4;
            streamsThreeDaysBulkDish$[0] = this.dataSource.getBulkDishsCurrentDate(
             momentDateTemp.add(-1, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));

            i = 0;
            streamsThreeDaysBulkDish$[1] = this.dataSource.getBulkDishsCurrentDate(
             momentDateTemp.add(1, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));
            i = 2;
            streamsThreeDaysBulkDish$[2] = this.dataSource.getBulkDishsCurrentDate(
             momentDateTemp.isoWeekday(i + startTuesday).format('YYYY-MM-DD'));
            i = 4;
            streamsThreeDaysBulkDish$[3] = this.dataSource.getBulkDishsCurrentDate(
             momentDateTemp.isoWeekday(i + startTuesday).format('YYYY-MM-DD'));
            obsrvWeeksBulkDish$ = obsrvWeeksBulkDish$.concat(streamsThreeDaysBulkDish$);
        //obsrvThreeDaysBulkDish$ = Observable.forkJoin(streamsThreeDaysBulkDish$);

          }


      }

      console.log('[getBulkDishWeeksShiftWeek] obsrvWeeksBulkDish$', obsrvWeeksBulkDish$);
      // let obsrvThreeDaysBulkDish$ = Observable.forkJoin(streamsThreeDaysBulkDish$);
      let obsrvThreeDaysBulkDish$ = Observable.forkJoin(obsrvWeeksBulkDish$);

      return obsrvThreeDaysBulkDish$;

    }




    sendBulkOrder(shiftWeek: number, simpleOderModelArray: any){


      let streamsCheckedBulkOrder$: Observable<any>[] = [];
      simpleOderModelArray.forEach(itemBulkOrder => {
        let orderDayNumber = this.dateService.getIndexForDayInWeek(itemBulkOrder['Day']);

        if (itemBulkOrder['sumOder'] != 0){
          streamsCheckedBulkOrder$.push(
            this.dataSource.sendBulkOrderCurrentDate(moment().add(shiftWeek, 'w').isoWeekday(orderDayNumber).format('YYYY-MM-DD'),
             itemBulkOrder['dishId']));
          }else{
            streamsCheckedBulkOrder$.push(
            this.dataSource.deleteBulkOrderCurrentDate(moment().add(shiftWeek, 'w').isoWeekday(orderDayNumber).format('YYYY-MM-DD'),
             itemBulkOrder['dishId']));
          }


        });



      let obsrvCheckedBulkOrder$ = Observable.forkJoin(streamsCheckedBulkOrder$);

      return obsrvCheckedBulkOrder$;


    }


    putBulkOrder(shiftWeek: number, simpleOderModelArray: any, customerNr?: number){


    console.debug('[putBulkOrder] simpleOderModelArray', simpleOderModelArray);

    let streamsCheckedBulkOrder$: Observable<any>[] = [];
    simpleOderModelArray.forEach(itemBulkOrder => {
        let servingDayNumber = this.dateService.getIndexForDayInWeek(itemBulkOrder['Day']);
        let deliveryDayNumber;
        let nextShiftWeek = 0;
        switch (itemBulkOrder['Day']){
            case 'Tuesday':
              deliveryDayNumber = this.dateService.getIndexForDayInWeek('Tuesday');
              break;
            case 'Wednesday':

              deliveryDayNumber = this.dateService.getIndexForDayInWeek('Tuesday');
              break;
            case 'Thursday':

              deliveryDayNumber = this.dateService.getIndexForDayInWeek('Thursday');
              break;
            case 'Friday':
              deliveryDayNumber = this.dateService.getIndexForDayInWeek('Thursday');
              break;
            case 'Saturday':

              deliveryDayNumber = this.dateService.getIndexForDayInWeek('Thursday');
              break;
            case 'Sunday':
              deliveryDayNumber = this.dateService.getIndexForDayInWeek('Saturday');
              //nextShiftWeek = 1;
              break;
            case 'Monday':
              deliveryDayNumber = this.dateService.getIndexForDayInWeek('Saturday');
              nextShiftWeek = 1;
              break;
            default:
            {}

          }

        if (itemBulkOrder['sumOder'] == 0){
             streamsCheckedBulkOrder$.push(
           this.dataSource.putBulkOrderCurrentDate(
              itemBulkOrder['menuNr'],
              itemBulkOrder['dishId'],
              itemBulkOrder['line'],
              moment().add(shiftWeek, 'w').isoWeekday(deliveryDayNumber).format('YYYY-MM-DD'),
             '',
             undefined,
             customerNr
             ));
          }else{

          streamsCheckedBulkOrder$.push(
            this.dataSource.putBulkOrderCurrentDate(
              itemBulkOrder['menuNr'],
              itemBulkOrder['dishId'],
              itemBulkOrder['line'],
              moment().add(shiftWeek, 'w').isoWeekday(deliveryDayNumber).format('YYYY-MM-DD'),
              moment().add(shiftWeek + nextShiftWeek, 'w').isoWeekday(servingDayNumber).format('YYYY-MM-DD'),
              itemBulkOrder['print'],
              customerNr));
          nextShiftWeek = 0;

          }


        });



    console.log('[putBulkOrder] streamsCheckedBulkOrder$', streamsCheckedBulkOrder$);
    let obsrvCheckedBulkOrder$ = Observable.forkJoin(streamsCheckedBulkOrder$);
      //const source$ = from(streamsCheckedBulkOrder$);
      //let obsrvCheckedBulkOrder$ = source$.pipe(concatAll());
      console.log('[putBulkOrder] obsrvCheckedBulkOrder$', obsrvCheckedBulkOrder$);


    return obsrvCheckedBulkOrder$;


    }
//---------------------------Print---------------------------------------------

putBulkOrderPrint(shiftWeek: number, simpleOderModelArray: any){


    console.debug('[putBulkOrder] simpleOderModelArray', simpleOderModelArray);

    let streamsCheckedBulkOrder$: Observable<any>[] = [];
    simpleOderModelArray.forEach(itemBulkOrder => {
        let servingDayNumber = this.dateService.getIndexForDayInWeek(itemBulkOrder['Day']);
        let deliveryDayNumber;
        let nextShiftWeek = 0;
        switch (itemBulkOrder['Day']){
            case 'Tuesday':
              deliveryDayNumber = this.dateService.getIndexForDayInWeek('Tuesday');
              break;
            case 'Wednesday':

              deliveryDayNumber = this.dateService.getIndexForDayInWeek('Tuesday');
              break;
            case 'Thursday':

              deliveryDayNumber = this.dateService.getIndexForDayInWeek('Thursday');
              break;
            case 'Friday':
              deliveryDayNumber = this.dateService.getIndexForDayInWeek('Thursday');
              break;
            case 'Saturday':

              deliveryDayNumber = this.dateService.getIndexForDayInWeek('Thursday');
              break;
            case 'Sunday':
              deliveryDayNumber = this.dateService.getIndexForDayInWeek('Saturday');
              nextShiftWeek = 1;
              break;
            case 'Monday':
              deliveryDayNumber = this.dateService.getIndexForDayInWeek('Saturday');
              nextShiftWeek = 1;
              break;
            default:
            {}

          }

       // if(itemBulkOrder["print"]){
        if (true){
             streamsCheckedBulkOrder$.push(
           this.dataSource.putBulkOrderPrintCurrentDate(
              itemBulkOrder['menuNr'],
              itemBulkOrder['dishId'],
              itemBulkOrder['line'],
              moment().add(shiftWeek, 'w').isoWeekday(deliveryDayNumber).format('YYYY-MM-DD'),
              itemBulkOrder['print']
             ));
          }



        });



    // let obsrvCheckedBulkOrder$ = Observable.forkJoin(streamsCheckedBulkOrder$);
    let obsrvCheckedBulkOrder$ = Observable.combineLatest(streamsCheckedBulkOrder$);

    return obsrvCheckedBulkOrder$;


    }

//--------------------Bilk Order Breakfast------------------------------------
  getBulkOrderBreakfastTuesdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any>{
      let streamsTuesdayBulkOrderBreakfast$: Observable<any>[] = [];
      let startTuesday = this.dateService.getIndexForDayInWeek(dayStart);
      for (var i = 0; i < numberOfDays; i++){

           streamsTuesdayBulkOrderBreakfast$[i] = this.dataSource.getBulkOrderBreakfastCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'))
             }

      let obsrvTuesdayBulkOrderBreakfast$ = Observable.forkJoin(streamsTuesdayBulkOrderBreakfast$);

      return obsrvTuesdayBulkOrderBreakfast$;

    }

    getBulkOrderBreakfastThursdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any>{
       let streamsThursdayBulkOrderBreakfast$: Observable<any>[] = [];
       let startThursday = this.dateService.getIndexForDayInWeek(dayStart);
       for (var i = 0; i < numberOfDays; i++){

           streamsThursdayBulkOrderBreakfast$[i] = this.dataSource.getBulkOrderBreakfastCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startThursday).format('YYYY-MM-DD'))
             }

       let obsrvThursdayBulkOrderBreakfast$ = Observable.forkJoin(streamsThursdayBulkOrderBreakfast$);

       return obsrvThursdayBulkOrderBreakfast$;

    }

    getBulkOrderBreakfastSaturdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any>{
      let streamsSaturdayBulkOrderBreakfast$: Observable<any>[] = [];
      let startSaturday = this.dateService.getIndexForDayInWeek(dayStart);
      for (var i = 0; i < numberOfDays; i++){

           streamsSaturdayBulkOrderBreakfast$[i] = this.dataSource.getBulkOrderBreakfastCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startSaturday).format('YYYY-MM-DD'))
             }

      let obsrvSaturdayBulkOrderBreakfast$ = Observable.forkJoin(streamsSaturdayBulkOrderBreakfast$);

      return obsrvSaturdayBulkOrderBreakfast$;

    }






    getBulkOrderBreakfastThreeDaysShiftWeek(shiftWeek: number, dayStart: string): Observable<any>{
      let streamsThreeDaysBulkOrderBreakfast$: Observable<any>[] = [];
      let startTuesday = this.dateService.getIndexForDayInWeek(dayStart);

      let i = 0;
      streamsThreeDaysBulkOrderBreakfast$[0] = this.dataSource.getBulkOrderBreakfastCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));
      i = 2;
      streamsThreeDaysBulkOrderBreakfast$[1] = this.dataSource.getBulkOrderBreakfastCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));
      i = 4;
      streamsThreeDaysBulkOrderBreakfast$[2] = this.dataSource.getBulkOrderBreakfastCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));

      let obsrvThreeDaysBulkOrderBreakfast$ = Observable.forkJoin(streamsThreeDaysBulkOrderBreakfast$);

      return obsrvThreeDaysBulkOrderBreakfast$;

    }


//--------------------Bilk Order Optional------------------------------------
  getBulkOrderOptionalTuesdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any>{
      let streamsTuesdayBulkOrderOptional$: Observable<any>[] = [];
      let startTuesday = this.dateService.getIndexForDayInWeek(dayStart);
      for (var i = 0; i < numberOfDays; i++){

           streamsTuesdayBulkOrderOptional$[i] = this.dataSource.getBulkOrderOptionalCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'))
             }

      let obsrvTuesdayBulkOrderOptional$ = Observable.forkJoin(streamsTuesdayBulkOrderOptional$);

      return obsrvTuesdayBulkOrderOptional$;

    }

    getBulkOrderOptionalThursdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any>{
       let streamsThursdayBulkOrderOptional$: Observable<any>[] = [];
       let startThursday = this.dateService.getIndexForDayInWeek(dayStart);
       for (var i = 0; i < numberOfDays; i++){

           streamsThursdayBulkOrderOptional$[i] = this.dataSource.getBulkOrderOptionalCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startThursday).format('YYYY-MM-DD'))
             }

       let obsrvThursdayBulkOrderOptional$ = Observable.forkJoin(streamsThursdayBulkOrderOptional$);

       return obsrvThursdayBulkOrderOptional$;

    }

    getBulkOrderOptionalSaturdayShiftWeek(shiftWeek: number, dayStart: string, numberOfDays: number): Observable<any>{
      let streamsSaturdayBulkOrderOptional$: Observable<any>[] = [];
      let startSaturday = this.dateService.getIndexForDayInWeek(dayStart);
      for (var i = 0; i < numberOfDays; i++){

           streamsSaturdayBulkOrderOptional$[i] = this.dataSource.getBulkOrderOptionalCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startSaturday).format('YYYY-MM-DD'))
             }

      let obsrvSaturdayBulkOrderOptional$ = Observable.forkJoin(streamsSaturdayBulkOrderOptional$);

      return obsrvSaturdayBulkOrderOptional$;

    }






    getBulkOrderOptionalThreeDaysShiftWeek(shiftWeek: number, dayStart: string): Observable<any>{
      let streamsThreeDaysBulkOrderOptional$: Observable<any>[] = [];
      let startTuesday = this.dateService.getIndexForDayInWeek(dayStart);

      let i = 0;
      streamsThreeDaysBulkOrderOptional$[0] = this.dataSource.getBulkOrderOptionalCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));
      i = 2;
      streamsThreeDaysBulkOrderOptional$[1] = this.dataSource.getBulkOrderOptionalCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));
      i = 4;
      streamsThreeDaysBulkOrderOptional$[2] = this.dataSource.getBulkOrderOptionalCurrentDate(
             moment().add(shiftWeek, 'w').isoWeekday(i + startTuesday).format('YYYY-MM-DD'));

      let obsrvThreeDaysBulkOrderOptional$ = Observable.forkJoin(streamsThreeDaysBulkOrderOptional$);

      return obsrvThreeDaysBulkOrderOptional$;

    }

//----------------------POST Bulk Order MeniLine from Froko and Extra--------------
sendBulkOrderMenuLine(shiftWeek: number, orderModel: any, guestChefModel?: GuestChefModel){

  console.debug('[sendBulkOrderMenuLine] InsendBulkOrderMenuLine', );

  console.debug('[sendBulkOrderMenuLine] orderModel', orderModel);
  let streamsCheckedBulkOrderMenuLine$: Observable<any>[] = [];
        //let dishAllocationsOrderDay = new BulkOrderMenuLineEditModelForDays();
  let dishAllocationsOrderDay = new DishAllocationsOrderDays();
  let menuLineTuesdayArr = [];
  let menuLineWednesdayArr = [];
  let menuLineThursdayArr = [];
  let menuLineFridayArr = [];
  let menuLineSaturdayArr = [];
  let menuLineSundayArr = [];
  let menuLineMondayArr = [];
  let orderModelTuesdayArr = [];
  let orderModelWednesdayArr = [];
  let orderModelThursdayArr = [];
  let orderModelFridayArr = [];
  let orderModelSaturdayServArr = [];
  let orderModelSaturdayArr = [];
  let orderModelSundayArr = [];
  let orderModelMondayArr = [];
  let bulkOrderMenuLineEditModel;
  orderModel['menuLines'].forEach(itemOrderModel => {

      //let bulkOrderMenuLineEditModel;

          switch (itemOrderModel['menuLineDay']){
            case 'Tuesday':
                console.debug('[sendBulkOrderMenuLine]  InTuesday');
                orderModelTuesdayArr.push(itemOrderModel);
                dishAllocationsOrderDay['Tuesday'] = orderModelTuesdayArr;
                break;
            case 'Wednesday':
              console.debug('[sendBulkOrderMenuLine]  InWednesday');
              if (this.guestChefService.isGuestChefModelDay(guestChefModel, 'Wednesday')) {
                orderModelWednesdayArr.push(itemOrderModel)
                dishAllocationsOrderDay['Wednesday'] = orderModelWednesdayArr;
              } else {
                orderModelTuesdayArr.push(itemOrderModel);
                dishAllocationsOrderDay['Tuesday'] = orderModelTuesdayArr;
              }

            break;
            case 'Thursday':
                console.debug('[sendBulkOrderMenuLine]  InThursday');
                orderModelThursdayArr.push(itemOrderModel);
                dishAllocationsOrderDay['Thursday'] = orderModelThursdayArr;
                break;
            case 'Friday':
              console.debug('[sendBulkOrderMenuLine]  InFriday');
              if (this.guestChefService.isGuestChefModelDay(guestChefModel, 'Friday')) {
                orderModelFridayArr.push(itemOrderModel)
                dishAllocationsOrderDay['Friday'] = orderModelFridayArr;
              } else {
                 orderModelThursdayArr.push(itemOrderModel);
                dishAllocationsOrderDay['Thursday'] = orderModelThursdayArr;
              }
                break;
            case 'Saturday':
              console.debug('[sendBulkOrderMenuLine]  InSaturday');
              if (this.guestChefService.isGuestChefModelDay(guestChefModel, 'Saturday')) {
                orderModelSaturdayServArr.push(itemOrderModel)
                dishAllocationsOrderDay['Saturday'] = orderModelSaturdayServArr;
              } else {
                orderModelThursdayArr.push(itemOrderModel);
                dishAllocationsOrderDay['Thursday'] = orderModelThursdayArr;
              }
              break;
            case 'Sunday':
              console.debug('[sendBulkOrderMenuLine]  InSunday');
              if (this.guestChefService.isGuestChefModelDay(guestChefModel, 'Sunday')) {
                orderModelSundayArr.push(itemOrderModel)
                dishAllocationsOrderDay['Sunday'] = orderModelSundayArr;
              } else {
                orderModelSaturdayArr.push(itemOrderModel);
                dishAllocationsOrderDay['Saturday'] = orderModelSaturdayArr;
              }

              break;
            case 'Monday':
              console.debug('[sendBulkOrderMenuLine]  InMonday');
              if (this.guestChefService.isGuestChefModelDay(guestChefModel, 'Monday')) {
                orderModelMondayArr.push(itemOrderModel)
                dishAllocationsOrderDay['Monday'] = orderModelMondayArr;
              } else {
                orderModelSaturdayArr.push(itemOrderModel);
                dishAllocationsOrderDay['Saturday'] = orderModelSaturdayArr;
              }
              break;
            default:
              { }
          }
        });
      console.debug('[sendBulkOrderMenuLine] dishAllocationsOrderDay', dishAllocationsOrderDay);

  let nextShiftWeek = 0;
        for (let day in dishAllocationsOrderDay){

          let dayNumber = this.dateService.getIndexForDayInWeek(day);
          let mapOrderModelForDay = new Map();
          if (dishAllocationsOrderDay[day].length != 0){
              console.debug('[sendBulkOrderMenuLine] day', day);

            if (day == 'Monday'){
              nextShiftWeek = 1;

             }else{
               nextShiftWeek = 0;
             }

              mapOrderModelForDay = this.getOrderModelMapForDay(dishAllocationsOrderDay[day]);

              console.debug('[sendBulkOrderMenuLine] mapOrderModelForDay', mapOrderModelForDay);
              mapOrderModelForDay.forEach(function(itemmapOrderModelForDay, key, ownerSet){
              console.debug('[sendBulkOrderMenuLine] itemmapOrderModelForDay', itemmapOrderModelForDay);

              let menuLines = itemmapOrderModelForDay;
              console.debug('[sendBulkOrderMenuLine] menuLines', menuLines);
              console.debug('[sendBulkOrderMenuLine] key', key);
              if (!key[3]){

                bulkOrderMenuLineEditModel = new BulkOrderMenuLineEditModel(
               key[2],
               key[0],
               key[1],
               menuLines
              )

                console.debug('[sendBulkOrderMenuLine] bulkOrderMenuLineEditModel', bulkOrderMenuLineEditModel);

                streamsCheckedBulkOrderMenuLine$.push(
                    this.dataSource.postBulkOrderMenuLineCurrentDate(
                      bulkOrderMenuLineEditModel,
                      moment().add(shiftWeek + nextShiftWeek, 'w').isoWeekday(dayNumber).format('YYYY-MM-DD')
               ));
              }



              }, this);


            }

        }

  const source$ = from(streamsCheckedBulkOrderMenuLine$);
  let obsrvCheckedBulkOrderMenuLine$ = source$.pipe(concatAll());

  return obsrvCheckedBulkOrderMenuLine$;


}

    findOrderMenuLineDay(orderModel: any, productNr: number, line: number, productName: string){
      let itemOrderMenuLineDay;
      orderModel['menuLines'].forEach(itemOrderModel => {

        if ((itemOrderModel['menuLine']['productNr'] == productNr)
          && (itemOrderModel['menuLine']['line'] == line)
          && (itemOrderModel['menuLine']['productName'] == productName)){
          itemOrderMenuLineDay = itemOrderModel;

        }

        });
      return itemOrderMenuLineDay;
    }

    getOrderModelMapForDay(orderModelDay: any){

    console.debug('[getOrderModelMapForDay] orderModelDay', orderModelDay);

    let mapOrderModelMapArray = new Map();
    //let dataControlSet = new Set();
    let arrSet = [];
    orderModelDay.forEach(itemorderModelDay => {
        let itemDataControl = [];
        let found = false;
        itemDataControl.push(itemorderModelDay['customerNr']);
        itemDataControl.push(itemorderModelDay['menuNr']);
        itemDataControl.push(itemorderModelDay['orderNr']);
        itemDataControl.push(itemorderModelDay['orderNotPermit']);

        if (arrSet.length != 0){
        for (let itemarrSet in arrSet){


           if ((arrSet[itemarrSet][0] == itemorderModelDay['customerNr'])
             && (arrSet[itemarrSet][1] == itemorderModelDay['menuNr'])
             && (arrSet[itemarrSet][2] == itemorderModelDay['orderNr'])
              ){

              // arrSet.push(itemDataControl);
              found = true
              break;

            }


          }
        if (!found){
            arrSet.push(itemDataControl);
          }
        }else{
          arrSet.push(itemDataControl);
        }


      });

    console.debug('[getOrderModelMapForDay] arrSet', arrSet);

    arrSet.forEach(itemdataControlSet => {
            console.debug('[getOrderModelMapForDay] itemdataControlSet', itemdataControlSet);
            let menuLineArr = [];
            for (let itemorderModelDay in orderModelDay){

            if ((orderModelDay[itemorderModelDay]['customerNr'] == itemdataControlSet[0])
              && (orderModelDay[itemorderModelDay]['menuNr'] == itemdataControlSet[1])
              && (orderModelDay[itemorderModelDay]['orderNr'] == itemdataControlSet[2])){
              menuLineArr.push(orderModelDay[itemorderModelDay]['menuLine']);

            }

          }

            mapOrderModelMapArray.set(itemdataControlSet, menuLineArr);
         });

    console.debug('[getOrderModelMapForDay] mapOrderModelMapArray', mapOrderModelMapArray);
    return mapOrderModelMapArray;

  }

  getProductPrice(productNr: number, dietTypeNr: number,
                  portionSize: number, priceGroup: number, shiftWeek: number, dayStart: string){

      let streamProductPrice$: Observable<any>;
      let startDay = this.dateService.getIndexForDayInWeek(dayStart);
      streamProductPrice$ = this.dataSource.getProductPrice(productNr, dietTypeNr, portionSize,
        priceGroup, moment().add(shiftWeek, 'w').isoWeekday(startDay).format('YYYY-MM-DD'))
      return streamProductPrice$;
  }

}
