import { Dish } from "./dish.model";
import { OrderDish } from "./orderDish.model";
import { PortionSize } from "./portionSize.model";


export class OrderDishModel  {
    constructor(
    		public	dishId?:number,
            public	orderDishId?:number,
			public	count?:number,
			public	enablePortions?:boolean,
			public	portionSize?:PortionSize,
			public  dishName?: string,
			public	parentOrderDishId?:number,
			public	dishDishGroupId?:number,
			public	childOrderDishes?:OrderDish[]
				

           	) { }
}




