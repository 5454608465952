import { Injectable } from '@angular/core';
import { Component, Inject, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  ActivationEnd,
  Event,
  NavigationCancel,
  NavigationError,
  NavigationStart,
} from '@angular/router';
import { LocalStorageService } from '../../../service/localStorage.service';
import { Cart } from '../../../cart/cart-model/cart.model';
import { DateService } from '../../../service/date.service';
import { AllocatedDishModel } from '../../../model/allocatedDishModel.model';
import { OrderDish } from '../../../model/orderDish.model';
import { DishAllocationWeekDataBase } from '../../../model/dishAllocationWeek.database';
import { DishRepository } from '../../../repository/dish.repository';
import { RestDataSource } from '../../../repository/rest.datasource';
import { ModelResolver } from '../../../resolver/model.resolver';
import { MenuLine } from '../../../model/menuLine.model';
import { timer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { of, from } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/concatAll';
import 'rxjs/add/operator/concatMap';
//import "rxjs/add/operator/map";
import { map } from 'rxjs/operators';
import { _ } from 'underscore';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { SafePipeModule } from 'safe-pipe';
import * as moment from 'moment';
import { DataShareService } from '../../../service/dataShare.service';
import { LocaleDaDKService } from '../../../service/locale-da-dk.service';
import { environment } from './../../../../environments/environment';

class DishAllocationsOrderDays {
  Sunday: any[];
  Monday: any[];
  Tuesday: any[];
  Wednesday: any[];
  Thursday: any[];
  Friday: any[];
  Saturday: any[];

  constructor() {
    this.Sunday = [];
    this.Monday = [];
    this.Tuesday = [];
    this.Wednesday = [];
    this.Thursday = [];
    this.Friday = [];
    this.Saturday = [];
  }
}

class MenuLineArrayOrderDays {
  Sunday: MenuLine[];
  Monday: MenuLine[];
  Tuesday: MenuLine[];
  Wednesday: MenuLine[];
  Thursday: MenuLine[];
  Friday: MenuLine[];
  Saturday: MenuLine[];

  constructor() {
    this.Sunday = [];
    this.Monday = [];
    this.Tuesday = [];
    this.Wednesday = [];
    this.Thursday = [];
    this.Friday = [];
    this.Saturday = [];
  }
}

class SimpleOderModel {
  //orderDishId:string;
  orderDishId: number;
  orderNumber: number;
  sumOder: number;
  Day: string;
  dishId: number;
  name: string;
  imageUrl: string;
  category: string;
  subcategory: string;
  print: boolean;

  // constructor(orderDishId:string, orderNumber:number, sumOder:number, Day:string,
  constructor(
    orderDishId: number,
    orderNumber: number,
    sumOder: number,
    Day: string,
    dishId: number,
    name?: string,
    imageUrl?: string,
    category?: string,
    subcategory?: string,
    print?: boolean
  ) {
    this.orderDishId = orderDishId;
    this.orderNumber = orderNumber;
    this.sumOder = sumOder;
    this.Day = Day;
    this.dishId = dishId;
    this.name = name;
    this.imageUrl = imageUrl;
    this.category = category;
    this.subcategory = subcategory;
    this.print = print;
  }
}

class MainSideArr {
  mainDish: any[];
  sideDish: any[];

  constructor() {
    this.mainDish = [];
    this.sideDish = [];
  }
}

class DishMainSideOrderDays {
  Sunday: MainSideArr;
  Monday: MainSideArr;
  Tuesday: MainSideArr;
  Wednesday: MainSideArr;
  Thursday: MainSideArr;
  Friday: MainSideArr;
  Saturday: MainSideArr;

  constructor(
    Sunday: MainSideArr,
    Monday: MainSideArr,
    Tuesday: MainSideArr,
    Wednesday: MainSideArr,
    Thursday: MainSideArr,
    Friday: MainSideArr,
    Saturday: MainSideArr
  ) {
    this.Sunday = Sunday;
    this.Monday = Monday;
    this.Tuesday = Tuesday;
    this.Wednesday = Wednesday;
    this.Thursday = Thursday;
    this.Friday = Friday;
    this.Saturday = Saturday;
  }
}

@Component({
  templateUrl: 'printheader.component.html',
  styleUrls: ['printheader.component.css'],
})
export class PrintHeaderComponent {
  // public currentLocationPath:string;
  // public currentWeek:string;

  public sumOder: number;

  public weeks: string[];
  //private dishesArray: AllocatedDishModel[] = [];
  //private dishesForDayArray: any[] = [];
  public dishAllocationsWeekResponse: any[] = [];
  //private orderDishArray: OrderDish[] = [];

  public currentWeek: string;
  //private showUserName: boolean;
  public lastIndex: number;
  //private rowArray:any[];
  //private itemArray:any[];
  obsSubscription: Subscription;
  public numberElementsInRow: number;
  //private arrayDishesView: number[];
  public arrayDishesViewForDay: any[];
  public orderDishId: number;
  public orderDaysLeftInWeekArray: string[];
  public dishAllocationsOrderDays: DishAllocationsOrderDays;
  public dishAllocationsOrderDaysMainDishes: DishAllocationsOrderDays;
  public dishAllocationsOrderDaysSideDishes: DishAllocationsOrderDays;
  public dishMainSideOrderDays: DishMainSideOrderDays;
  //private dishAllocationsOrderDaysCategory:DishViewController[] = [];
  public currentToogledDay: string;
  public toggledMonday: boolean;
  public toggledWednesday: boolean;
  public toggledFriday: boolean;
  public toggledTuesday: boolean;
  public toggledThursday: boolean;
  public toggledSaturday: boolean;
  public SundayDate: string;
  public SundayNextWeekDate: string;
  public MondayDate: string;
  public MondayNextWeekDate: string;
  public TuesdayDate: string;
  public WednesdayDate: string;
  public ThursdayDate: string;
  public FridayDate: string;
  public SaturdayDate: string;
  public shiftWeekNumber: string;
  public shiftWeek: number;
  public needNgOninit: boolean;
  public simpleOderModelArray: SimpleOderModel[];
  public imgUrl: string;
  public tempArray: any[] = [];
  public categoryForFilter: any;
  //private ToogledDays:string;
  public simpleOderModelArrayId: string;
  public orderingDates: any[];
  public userInfoOrderingModel: any[] = [];
  //obsUserInfoOrderingModelSubscription: Subscription;
  public currentDayNumber: number;
  public filterOn: boolean;

  public isOrderingTimeFlag: boolean;
  public isEmptyMonday: boolean;
  public isEmptyTuesday: boolean;
  public isEmptyWednesday: boolean;
  public isEmptyThursday: boolean;
  public isEmptyFriday: boolean;
  public isEmptySaturday: boolean;
  public isEmptySunday: boolean;
  private modalBtnFlag: string;
  public safeSrc: SafeResourceUrl;
  public modalBtnBackgroundColor: string;
  public modalBtnTextColor: string;
  public modalBtnTextWeight: string;
  public ifWeHaveCategory: boolean;
  private needUnsubscribe: boolean;
  //public menuLineArrForFuldkost: any[] = [];
  public menuLineArrForFuldkost: MenuLineArrayOrderDays;
  public menuLineArrForEnrgitat: MenuLineArrayOrderDays;
  public menuLineArrForBlod: MenuLineArrayOrderDays;
  public menuLineArrForAndet: MenuLineArrayOrderDays;
  public menuLineArrForCategoryTemp: any[] = [];
  public contentFuldkostAntal: number = 0;
  public contentEnergitatAntalArr: number[] = [];
  public contentBlodAntalArr: number[] = [];
  public contentAndetAntalArr: number[] = [];
  public contentAndetAntalArr2: number[] = [];
  public currentDayForModal: string;
  //public sumMatrixForDays: SumMatrixForDays;
  public dishAllocationsOrderDaysInitTuesday: any;
  public dishAllocationsOrderDaysInitThursday: any;
  public dishAllocationsOrderDaysInitSaturday: any;
  public mainMenuLineTuesday: number;
  public mainMenuLineWednesday: number;
  public mainMenuLineThursday: number;
  public mainMenuLineFriday: number;
  public mainMenuLineSaturday: number;
  public mainMenuLineSunday: number;
  public mainMenuLineMonday: number;
  private departmentId: number;
  public isfAfterPageLoaded: boolean;

  closeResult: string;
  public selectedKodFuldkost: string;
  public orderFromViewWeekPlan: boolean;
  public templateDays: string[];

  constructor(
    private dataSource: RestDataSource,
    private localStorageService: LocalStorageService,
    private cart: Cart,
    private router: Router,
    private dateService: DateService,
    private dishRepository: DishRepository,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private dataShareService: DataShareService,
    private localeDaDKService: LocaleDaDKService
  ) {
    this.localStorageService.set('currentChildRoute', 'print/printweekplan');
    //-----------------------------Depatments --------------------------------------------
    this.departmentId = this.localStorageService.get('currentdepartmentId');

    //----------------------------------------------------------------------------------
    this.isfAfterPageLoaded = true;
  }

  ngOnInit(): void {
    this.dataShareService.dispayIfPageForPrinting = 'none';

    this.ifWeHaveCategory = false;
    this.needNgOninit = false;
    this.orderFromViewWeekPlan = true;

    //------------------Main MenuLines Init ---------------------------------

    /*this.mainMenuLineTuesday = 1;
	this.mainMenuLineWednesday = 1;
	this.mainMenuLineThursday = 1;
	this.mainMenuLineFriday = 1;
	this.mainMenuLineSaturday = 1;
	this.mainMenuLineSunday = 1;
	this.mainMenuLineMonday = 1;*/

    //-------------------NavTabs init ------------------------------------

    let toggledDay = this.localStorageService.get('toggledDayBulkOrder');
    this.templateDays = [
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
      'Monday',
    ];

    //---------------------Date------------------------------------
    //this.shiftWeek = this.localStorageService.get('shiftWeek'+ "bulkorder/editweekplan");

    this.shiftWeek = this.localStorageService.get(
      'shiftWeek' + this.localStorageService.get('currentChildRoute')
    );

    console.log('this.shiftWeek=' + this.shiftWeek);
    this.currentDayNumber = this.dateService.getCurrentDayNumber();

    this.currentWeek = this.dateService.getCurrentWeek();

    this.shiftWeekNumber = this.dateService.getShiftWeek(this.shiftWeek);

    this.SundayDate = this.dateService.getISODateDDMMMforDay(
      'Sunday',
      this.shiftWeek
    );
    this.MondayDate = this.dateService.getISODateDDMMMforDay(
      'Monday',
      this.shiftWeek
    );
    this.SundayNextWeekDate = this.dateService.getISODateDDMMMforDay(
      'Sunday',
      //this.shiftWeek + 1
      this.shiftWeek
    );
    this.MondayNextWeekDate = this.dateService.getISODateDDMMMforDay(
      'Monday',
      this.shiftWeek + 1
    );
    this.TuesdayDate = this.dateService.getISODateDDMMMforDay(
      'Tuesday',
      this.shiftWeek
    );
    this.WednesdayDate = this.dateService.getISODateDDMMMforDay(
      'Wednesday',
      this.shiftWeek
    );
    this.ThursdayDate = this.dateService.getISODateDDMMMforDay(
      'Thursday',
      this.shiftWeek
    );
    this.FridayDate = this.dateService.getISODateDDMMMforDay(
      'Friday',
      this.shiftWeek
    );
    this.SaturdayDate = this.dateService.getISODateDDMMMforDay(
      'Saturday',
      this.shiftWeek
    );

    //--------------------- GET dishAllocations for All days in Week-----------------------------
    if (!this.needUnsubscribe) {
      this.obsSubscription = this.activatedRoute.data.subscribe((data) => {
        this.dishAllocationsWeekResponse = [];
        this.dishAllocationsOrderDaysInitTuesday = [];
        this.dishAllocationsOrderDaysInitThursday = [];
        this.dishAllocationsOrderDaysInitSaturday = [];
        this.dishAllocationsOrderDays = new DishAllocationsOrderDays();
        this.dishAllocationsOrderDaysMainDishes = new DishAllocationsOrderDays();
        this.dishAllocationsOrderDaysSideDishes = new DishAllocationsOrderDays();
        let mainSideArr = new MainSideArr();
        this.dishMainSideOrderDays = new DishMainSideOrderDays(
          mainSideArr,
          mainSideArr,
          mainSideArr,
          mainSideArr,
          mainSideArr,
          mainSideArr,
          mainSideArr
        );

        /*var t3 = moment().valueOf();	*/
        this.dishAllocationsWeekResponse = data['dishes'];
        console.log(
          'dishAllocationsWeekResponse=' +
            JSON.stringify(this.dishAllocationsWeekResponse)
        );

        let toggledDay1 = this.localStorageService.get('toggledDayBulkOrder');
        //-------------------------------------------Order------------------------------------------------------

        this.isOrderingTimeFlag = false;
        this.simpleOderModelArrayId =
          'simpleOderModelArray' +
          this.localStorageService.get('pageForOrderChildRoute') +
          this.shiftWeekNumber +
          this.departmentId;
        //console.log("FROCO this.simpleOderModelArrayId="+this.simpleOderModelArrayId);
        this.simpleOderModelArray = this.localStorageService.get(
          this.simpleOderModelArrayId
        );
        console.log('this.simpleOderModelArray', this.simpleOderModelArray);
        //console.log(`OrderBulk simpleOderModelArray= ${this.simpleOderModelArray}`);
        if (
          this.simpleOderModelArray == null ||
          this.simpleOderModelArray == undefined
        ) {
          this.simpleOderModelArray = new Array();
          this.sumOder = 0;
        } else {
          for (var oder in this.simpleOderModelArray) {
            this.sumOder += this.simpleOderModelArray[oder].sumOder;
          }
        }

        if (this.dishAllocationsWeekResponse[0]) {
          if (this.orderFromViewWeekPlan) {
            //this.dishAllocationsWeekResponse[0]["mainDishes"] = this.dishAllocationsWeekResponse[0]["mainDishes"].concat(this.dishAllocationsWeekResponse[0]["specialDishes"]);
            this.dishAllocationsOrderDaysInitTuesday = JSON.parse(
              JSON.stringify(this.dishAllocationsWeekResponse[0]['bulkDishes'])
            );
            console.log(
              'this.dishAllocationsWeekResponse[0]',
              JSON.stringify(this.dishAllocationsWeekResponse[0]['bulkDishes'])
            );
            console.log(
              'this.dishAllocationsOrderDaysInitTuesday',
              this.dishAllocationsOrderDaysInitTuesday
            );
            this.dishAllocationsOrderDays[
              'Tuesday'
            ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];

            //this.dishAllocationsOrderDays["Tuesday"]=this.productNrFilterForGetDishes(this.dishAllocationsOrderDays["Tuesday"], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Tuesday'), this.mainMenuLineTuesday);
            this.dishAllocationsOrderDays[
              'Tuesday'
            ] = this.filterForPrintDishes(
              this.dishAllocationsOrderDays['Tuesday']
            );
            this.dishAllocationsOrderDays[
              'Tuesday'
            ] = this.sortMenuLineArrForCategory(
              this.dishAllocationsOrderDays['Tuesday']
            );

            this.dishMainSideOrderDays['Tuesday'] = this.divideByMainSideDishes(
              this.dishAllocationsOrderDays['Tuesday'],
              'Tuesday'
            );

            console.log(
              'this.dishAllocationsOrderDaysTuesday',
              JSON.stringify(this.dishAllocationsOrderDays)
            );
          } else {
            this.dishAllocationsOrderDays[
              'Tuesday'
            ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];
          }

          if (_.isEmpty(this.dishAllocationsOrderDays['Tuesday'])) {
            console.log('this.dishAllocationsOrderDays[\'Tuesday\'].length==0');
            this.isEmptyTuesday = true;
          }
        }
        if (this.dishAllocationsWeekResponse[0]) {
          //this.dishAllocationsOrderDays["Wednesday"]=this.dishAllocationsWeekResponse[0];

          if (this.orderFromViewWeekPlan) {
            //if(false){

            //this.dishAllocationsOrderDays["Wednesday"]=this.dishAllocationsWeekResponse[1];
            this.dishAllocationsOrderDays['Wednesday'] = JSON.parse(
              JSON.stringify(this.dishAllocationsOrderDaysInitTuesday)
            );
            console.log(
              'this.dishAllocationsOrderDaysInitTuesday',
              this.dishAllocationsOrderDaysInitTuesday
            );
            console.log(
              'this.dishAllocationsOrderDaysWednesday',
              this.dishAllocationsOrderDays['Wednesday']
            );
            //this.dishAllocationsOrderDays["Tuesday"]=this.productNrFilterForGetDishes(this.dishAllocationsWeekResponse[0], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Tuesday'));
            //this.dishAllocationsOrderDays["Wednesday"]=this.productNrFilterForGetDishes(this.dishAllocationsOrderDays["Wednesday"], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Wednesday'), this.mainMenuLineWednesday);
            this.dishAllocationsOrderDays[
              'Wednesday'
            ] = this.filterForPrintDishes(
              this.dishAllocationsOrderDays['Wednesday']
            );
            this.isEmptyWednesday = true; // !!!!!!!!!
            this.dishAllocationsOrderDays['Wednesday'] = [];
            console.log(
              'this.dishAllocationsOrderDaysWednesday',
              JSON.stringify(this.dishAllocationsOrderDays)
            );
          } else {
            //this.dishAllocationsOrderDays["Wednesday"]=this.dishAllocationsWeekResponse[1];
            this.dishAllocationsOrderDays[
              'Wednesday'
            ] = this.dishAllocationsWeekResponse[0]['bulkDishes'];
          }

          if (_.isEmpty(this.dishAllocationsOrderDays['Wednesday'])) {
            console.log('this.dishAllocationsOrderDays[\'Wednesday\'].length==0');
            this.isEmptyWednesday = true;
          }
        }

        console.log(
          'dishAllocationsOrderDaysTW=' +
            JSON.stringify(this.dishAllocationsOrderDays)
        );

        if (this.dishAllocationsWeekResponse[1]) {
          //this.dishAllocationsOrderDays["Thursday"]=this.dishAllocationsWeekResponse[0];
          if (this.orderFromViewWeekPlan) {
            //this.dishAllocationsWeekResponse[1]["mainDishes"] = this.dishAllocationsWeekResponse[1]["mainDishes"].concat(this.dishAllocationsWeekResponse[1]["specialDishes"]);
            this.dishAllocationsOrderDaysInitThursday = JSON.parse(
              JSON.stringify(this.dishAllocationsWeekResponse[1]['bulkDishes'])
            );
            console.log(
              'this.dishAllocationsWeekResponse[1]Thursday',
              JSON.stringify(this.dishAllocationsWeekResponse[1]['bulkDishes'])
            );
            this.dishAllocationsOrderDays[
              'Thursday'
            ] = this.dishAllocationsWeekResponse[1]['bulkDishes'];
            //this.dishAllocationsOrderDays["Thursday"]=this.productNrFilterForGetDishes(this.dishAllocationsWeekResponse[0], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Tuesday'));
            //this.dishAllocationsOrderDays["Thursday"]=this.productNrFilterForGetDishes(this.dishAllocationsOrderDays["Thursday"], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Thursday'), this.mainMenuLineThursday);
            this.dishAllocationsOrderDays[
              'Thursday'
            ] = this.filterForPrintDishes(
              this.dishAllocationsOrderDays['Thursday']
            );
            console.log(
              'this.productNrForDaysimpleOderModelArrayFilter',
              this.productNrForDaysimpleOderModelArrayFilter(
                this.simpleOderModelArray,
                'Thursday'
              )
            );
            this.dishAllocationsOrderDays[
              'Thursday'
            ] = this.sortMenuLineArrForCategory(
              this.dishAllocationsOrderDays['Thursday']
            );

            this.dishMainSideOrderDays[
              'Thursday'
            ] = this.divideByMainSideDishes(
              this.dishAllocationsOrderDays['Thursday'],
              'Thursday'
            );

            console.log(
              'this.dishAllocationsOrderDaysThursday',
              JSON.stringify(this.dishAllocationsOrderDays)
            );
          } else {
            this.dishAllocationsOrderDays[
              'Thursday'
            ] = this.dishAllocationsWeekResponse[1]['bulkDishes'];
          }
          if (_.isEmpty(this.dishAllocationsOrderDays['Thursday'])) {
            console.log('this.dishAllocationsOrderDays[\'Thursday\'].length==0');
            this.isEmptyThursday = true;
          }
        }

        if (this.dishAllocationsWeekResponse[1]) {
          //this.dishAllocationsOrderDays["Friday"]=this.dishAllocationsWeekResponse[0];
          if (this.orderFromViewWeekPlan) {
            //	if(false){
            //this.dishAllocationsOrderDays["Friday"]=this.dishAllocationsWeekResponse[1];

            this.dishAllocationsOrderDays['Friday'] = JSON.parse(
              JSON.stringify(this.dishAllocationsOrderDaysInitThursday)
            );

            //this.dishAllocationsOrderDays["Friday"]=this.productNrFilterForGetDishes(this.dishAllocationsOrderDays["Friday"], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Friday'), this.mainMenuLineFriday);
            this.dishAllocationsOrderDays['Friday'] = this.filterForPrintDishes(
              this.dishAllocationsOrderDays['Friday']
            );
            this.isEmptyFriday = true; // !!!!!!!!!
            this.dishAllocationsOrderDays['Friday'] = [];
            console.log(
              'this.dishAllocationsOrderDaysFriday',
              JSON.stringify(this.dishAllocationsOrderDays)
            );
          } else {
            //this.dishAllocationsOrderDays["Friday"]=this.dishAllocationsWeekResponse[1];
            this.dishAllocationsOrderDays[
              'Friday'
            ] = this.dishAllocationsWeekResponse[1]['bulkDishes'];
          }

          if (_.isEmpty(this.dishAllocationsOrderDays['Friday'])) {
            console.log('this.dishAllocationsOrderDays[\'Friday\'].length==0');
            this.isEmptyFriday = true;
          }
        }

        if (this.dishAllocationsWeekResponse[1]) {
          //this.dishAllocationsOrderDays["Saturday"]=this.dishAllocationsWeekResponse[0];
          if (this.orderFromViewWeekPlan) {
            //this.dishAllocationsOrderDays["Saturday"]=this.dishAllocationsWeekResponse[1];

            this.dishAllocationsOrderDays['Saturday'] = JSON.parse(
              JSON.stringify(this.dishAllocationsOrderDaysInitThursday)
            );
            console.log(
              'this.dishAllocationsOrderDays[Saturday]',
              JSON.stringify(this.dishAllocationsOrderDays['Saturday'])
            );
            //this.dishAllocationsOrderDays["Saturday"]=this.productNrFilterForGetDishes(this.dishAllocationsOrderDays["Saturday"], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Saturday'), this.mainMenuLineSaturday);
            this.dishAllocationsOrderDays[
              'Saturday'
            ] = this.filterForPrintDishes(
              this.dishAllocationsOrderDays['Saturday']
            );
            this.isEmptySaturday = true; // !!!!!!!!!
            this.dishAllocationsOrderDays['Saturday'] = [];
            console.log(
              'this.dishAllocationsOrderDaysSunday',
              JSON.stringify(this.dishAllocationsOrderDays)
            );
          } else {
            //this.dishAllocationsOrderDays["Saturday"]=this.dishAllocationsWeekResponse[1];
            this.dishAllocationsOrderDays[
              'Saturday'
            ] = this.dishAllocationsWeekResponse[1]['bulkDishes'];
          }

          if (_.isEmpty(this.dishAllocationsOrderDays['Saturday'])) {
            console.log('this.dishAllocationsOrderDays[\'Saturday\'].length==0');

            this.isEmptySaturday = true;
          }
        }

        console.log(
          'dishAllocationsOrderDaysTS=' +
            JSON.stringify(this.dishAllocationsOrderDays)
        );

        if (this.dishAllocationsWeekResponse[2]) {
          //this.dishAllocationsOrderDays["Saturday"]=this.dishAllocationsWeekResponse[0];
          if (this.orderFromViewWeekPlan) {
            //this.dishAllocationsWeekResponse[2]["mainDishes"] = this.dishAllocationsWeekResponse[2]["mainDishes"].concat(this.dishAllocationsWeekResponse[2]["specialDishes"]);
            console.log(
              'this.dishAllocationsWeekResponse[2]Sunday',
              JSON.stringify(this.dishAllocationsWeekResponse[2]['bulkDishes'])
            );

            this.dishAllocationsOrderDaysInitSaturday = JSON.parse(
              JSON.stringify(this.dishAllocationsWeekResponse[2]['bulkDishes'])
            );
            console.log(
              'this.dishAllocationsOrderDaysInitSunday',
              JSON.stringify(this.dishAllocationsOrderDaysInitSaturday)
            );
            this.dishAllocationsOrderDays[
              'Sunday'
            ] = this.dishAllocationsWeekResponse[2]['bulkDishes'];
            //this.dishAllocationsOrderDays["Sunday"]=this.productNrFilterForGetDishes(this.dishAllocationsOrderDays["Sunday"], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Sunday'), this.mainMenuLineSunday);
            this.dishAllocationsOrderDays['Sunday'] = this.filterForPrintDishes(
              this.dishAllocationsOrderDays['Sunday']
            );
            this.dishAllocationsOrderDays[
              'Sunday'
            ] = this.sortMenuLineArrForCategory(
              this.dishAllocationsOrderDays['Sunday']
            );

            this.dishMainSideOrderDays['Sunday'] = this.divideByMainSideDishes(
              this.dishAllocationsOrderDays['Sunday'],
              'Sunday'
            );

            console.log(
              'this.dishAllocationsOrderDaysSunday',
              JSON.stringify(this.dishAllocationsOrderDays['Sunday'])
            );
          } else {
            this.dishAllocationsOrderDays[
              'Sunday'
            ] = this.dishAllocationsWeekResponse[2]['bulkDishes'];
          }

          if (_.isEmpty(this.dishAllocationsOrderDays['Sunday'])) {
            console.log('this.dishAllocationsOrderDays[\'Sunday\'].length==0');
            this.isEmptySunday = true;
          }
        }

        if (this.dishAllocationsWeekResponse[2]) {
          //this.dishAllocationsOrderDays["Monday"]=this.dishAllocationsWeekResponse[0];
          if (this.orderFromViewWeekPlan) {
            //this.dishAllocationsOrderDays["Monday"]=this.dishAllocationsWeekResponse[2];

            this.dishAllocationsOrderDays['Monday'] = JSON.parse(
              JSON.stringify(this.dishAllocationsOrderDaysInitSaturday)
            );
            console.log(
              'this.dishAllocationsOrderDays[Monday]',
              this.dishAllocationsOrderDays['Monday']
            );
            //this.dishAllocationsOrderDays["Monday"]=this.productNrFilterForGetDishes(this.dishAllocationsOrderDays["Monday"], this.productNrForDaysimpleOderModelArrayFilter(this.simpleOderModelArray, 'Monday'), this.mainMenuLineMonday);
            this.dishAllocationsOrderDays['Monday'] = this.filterForPrintDishes(
              this.dishAllocationsOrderDays['Monday']
            );
            this.isEmptyMonday = true; //!!!!!!!!!!!!!!!!
            this.dishAllocationsOrderDays['Monday'] = [];
            console.log(
              'this.dishAllocationsOrderDaysMonday',
              JSON.stringify(this.dishAllocationsOrderDays)
            );
          } else {
            //this.dishAllocationsOrderDays["Monday"]=this.dishAllocationsWeekResponse[2]['bulkDishes'];
            this.dishAllocationsOrderDays[
              'Monday'
            ] = this.dishAllocationsWeekResponse[2]['bulkDishes'];
          }
          if (_.isEmpty(this.dishAllocationsOrderDays['Monday'])) {
            console.log('this.dishAllocationsOrderDays[\'Monday\'].length==0');
            this.isEmptyMonday = true;
          }
        }

        console.log(
          'dishAllocationsOrderDaysSM=' +
            JSON.stringify(this.dishAllocationsOrderDays)
        );

        console.log(
          'dishAllocationsOrderDays1=' +
            JSON.stringify(this.dishAllocationsOrderDays)
        );

        //}
        //--------------------------Loader -----------------------------
        this.router.events.subscribe((event: Event) => {
          switch (true) {
            /*case event instanceof NavigationStart: {
        this.loading = true;
          break;
        }*/

            case event instanceof NavigationEnd:
            case event instanceof NavigationCancel:
            case event instanceof NavigationError: {
              //  this.loading = false;
              // window.onload = function(){
              var loader = document.getElementById('loader');
              loader.style.visibility = 'hidden';
              //--------------------------Open printing--------------------------------------
              if (this.isfAfterPageLoaded) {
                window.print();
                this.isfAfterPageLoaded = false;
              }
              //------------------------------------------------------------------------------
              //	}
              //  this.localStorageService.set('loading',false);

              //  this.getDismissReason(1);
              break;
            }
            default: {
              break;
            }
          }
        });

        //this.localStorageService.set( 'currentChildRoute', "bulkorder/viewweekplan");
        this.localStorageService.set(
          'currentChildRoute',
          this.localStorageService.get('parentPrintRoute')
        );
        this.needNgOninit = true;
        this.needUnsubscribe = true;

        console.log(
          'this.dishAllocationsOrderDays22=' +
            JSON.stringify(this.dishAllocationsOrderDays)
        );
      });
    }

    //this.obsSubscription.unsubscribe();
  }

  ngAfterContentChecked() {
    if (this.needNgOninit) {
      this.ngOnInit();
    }

    if (!environment.debugMode) {
      console.clear();
    }
  }

  ngOnDestroy() {
    this.dataShareService.dispayIfPageForPrinting = '';
    this.obsSubscription.unsubscribe();
  }

  /* getPath() {
	   	  console.log("this.location.path()="+this.location.path())
		  return this.location.path();
		}*/

  //-----------------------------Methods--------------------------------------------------------------

  categoryFilter(day: number, response: any[], category: any) {
    var dishArrForCategoty = Object.create({ category: [], categories: [] });
    var exit_loops = false;
    var lowerCaseCategory = category.toLowerCase();

    console.log(
      'In filterResponseByCategory lowerCaseCategory=' + lowerCaseCategory
    );
    for (var n = 0; n < response[day].length; n++) {
      //	console.log("In filterResponseByCategory response[day][n][category][name]="+ response[day][n]["category"][name]);
      if (
        response[day][n]['category']['name'] == category ||
        response[day][n]['category']['name'] == lowerCaseCategory
      ) {
        //	console.log("In categoryFilter. Category found")

        dishArrForCategoty.category = response[day][n]['category'];
        dishArrForCategoty.categories = response[day][n]['categories'];
        exit_loops = true;
        break;
      } else {
        //	console.log("In categoryFilter. Category not found")
      }
    }

    // console.log("RETURN" + JSON.stringify(dishArrForCategoty));
    return dishArrForCategoty;
  }

  categoryDietTypeArrCatFilter(
    day: number,
    response: any[],
    categoryArr: any[]
  ) {
    //var menuLineArrForCategory = Object.create({category:[], categories:[]});
    var menuLineArrForCategory = [];
    //var exit_loops = false;
    //var lowerCaseCategory = category.toLowerCase();

    //console.log("In filterResponseByCategory lowerCaseCategory=" + lowerCaseCategory);
    for (var n = 0; n < response[day]['mainDishes'].length; n++) {
      for (
        var k = 0;
        k < response[day]['mainDishes'][n]['dietTypeGroups'].length;
        k++
      ) {
        for (
          var m = 0;
          m <
          response[day]['mainDishes'][n]['dietTypeGroups'][k]['dietTypes']
            .length;
          m++
        ) {
          //console.log("nAndet", n);
          //console.log("kAndet", k);
          //console.log("mAndet", m);
          // console.log("response[day]22="+JSON.stringify(response[day]["mainDishes"][n]["dietTypeGroups"][k]["dietTypes"][m]["dietType"]));
          for (var catAndet in categoryArr) {
            //console.log("catAndet", catAndet);
            if (
              response[day]['mainDishes'][n]['dietTypeGroups'][k]['dietTypes'][
                m
              ]['dietType']['name'] == categoryArr[catAndet]
            ) {
              //	console.log("In categoryFilter. Category found")

              menuLineArrForCategory.push(
                response[day]['mainDishes'][n]['dietTypeGroups'][k][
                  'dietTypes'
                ][m]['menuLine']
              );
              //	console.log("menuLineArrForCategoryAndet", menuLineArrForCategory);

              //exit_loops = true;
              //break;
            } else {
              //	console.log("In categoryFilter. Category not found")
            }
          }
        }
      }
    }

    // console.log("RETURNAndet" + JSON.stringify(menuLineArrForCategory));
    return menuLineArrForCategory;
  }

  categoryDietTypeFuldkostArrFilter(response: any[]) {
    //console.log("response", response);
    //var menuLineArrForCategory = Object.create({category:[], categories:[]});
    var categoryArr = [
      'Fuldkost',
      'Fuldkost m. kogte kartof.',
      'Fuldkost m. rå kartof.',
      'Fuldkost m/mos',
      'Fuldkost % kartofler',
    ];
    var menuLineArrForCategory = [];
    //var exit_loops = false;
    //var lowerCaseCategory = category.toLowerCase();

    //console.log("In filterResponseByCategory lowerCaseCategory=" + lowerCaseCategory);
    //for(var n=0; n < response[day]['mainDishes'].length; n++ ){
    for (var k = 0; k < response.length; k++) {
      for (var m = 0; m < response[k]['dietTypes'].length; m++) {
        //console.log("nAndet", n);
        //console.log("kAndet", k);
        //console.log("mAndet", m);
        // console.log("response[day]22="+JSON.stringify(response[day]["mainDishes"][n]["dietTypeGroups"][k]["dietTypes"][m]["dietType"]));
        for (var catAndet in categoryArr) {
          //console.log("catAndet", catAndet);
          if (
            response[k]['dietTypes'][m]['dietType']['name'] ==
            categoryArr[catAndet]
          ) {
            //	console.log("In categoryFilter. Category found")

            menuLineArrForCategory.push(
              response[k]['dietTypes'][m]['menuLine']
            );
            //	console.log("menuLineArrForCategoryAndet", menuLineArrForCategory);

            //exit_loops = true;
            //break;
          } else {
            //	console.log("In categoryFilter. Category not found")
          }
        }
      }
    }

    //}

    console.log('RETURNFuldkost' + JSON.stringify(menuLineArrForCategory));
    this.menuLineArrForCategoryTemp = menuLineArrForCategory;
    return menuLineArrForCategory;
  }

  categoryDietTypeEnrgitatArrFilter(response: any[]) {
    console.log('response', response);
    //var menuLineArrForCategory = Object.create({category:[], categories:[]});
    var categoryArr = ['Energitæt', 'Energi m/ rå kart.'];
    var menuLineArrForCategory = [];
    //var exit_loops = false;
    //var lowerCaseCategory = category.toLowerCase();

    //console.log("In filterResponseByCategory lowerCaseCategory=" + lowerCaseCategory);
    //for(var n=0; n < response[day]['mainDishes'].length; n++ ){
    for (var k = 0; k < response.length; k++) {
      for (var m = 0; m < response[k]['dietTypes'].length; m++) {
        //console.log("nAndet", n);
        //console.log("kAndet", k);
        //console.log("mAndet", m);
        // console.log("response[day]22="+JSON.stringify(response[day]["mainDishes"][n]["dietTypeGroups"][k]["dietTypes"][m]["dietType"]));
        for (var catAndet in categoryArr) {
          console.log('catAndet', catAndet);
          if (
            response[k]['dietTypes'][m]['dietType']['name'] ==
            categoryArr[catAndet]
          ) {
            console.log('In categoryFilter. Category found');

            menuLineArrForCategory.push(
              response[k]['dietTypes'][m]['menuLine']
            );
            //	console.log("menuLineArrForCategoryAndet", menuLineArrForCategory);

            //exit_loops = true;
            //break;
          } else {
            //	console.log("In categoryFilter. Category not found")
          }
        }
      }
    }

    //}

    // console.log("RETURNEnrgitat" + JSON.stringify(menuLineArrForCategory));
    this.menuLineArrForCategoryTemp = menuLineArrForCategory;
    return menuLineArrForCategory;
  }

  categoryDietTypeBlodArrFilter(response: any[]) {
    //console.log("response", response);
    //var menuLineArrForCategory = Object.create({category:[], categories:[]});
    var categoryArr = ['Blød'];
    var menuLineArrForCategory = [];
    //var exit_loops = false;
    //var lowerCaseCategory = category.toLowerCase();

    //console.log("In filterResponseByCategory lowerCaseCategory=" + lowerCaseCategory);
    //for(var n=0; n < response[day]['mainDishes'].length; n++ ){
    for (var k = 0; k < response.length; k++) {
      for (var m = 0; m < response[k]['dietTypes'].length; m++) {
        //console.log("nAndet", n);
        //console.log("kAndet", k);
        //console.log("mAndet", m);
        // console.log("response[day]22="+JSON.stringify(response[day]["mainDishes"][n]["dietTypeGroups"][k]["dietTypes"][m]["dietType"]));
        for (var catAndet in categoryArr) {
          //console.log("catAndet", catAndet);
          if (
            response[k]['dietTypes'][m]['dietType']['name'] ==
            categoryArr[catAndet]
          ) {
            //	console.log("In categoryFilter. Category found")

            menuLineArrForCategory.push(
              response[k]['dietTypes'][m]['menuLine']
            );
            //	console.log("menuLineArrForCategoryAndet", menuLineArrForCategory);

            //exit_loops = true;
            //break;
          } else {
            //	console.log("In categoryFilter. Category not found")
          }
        }
      }
    }

    //}

    // console.log("RETURNBlod" + JSON.stringify(menuLineArrForCategory));
    this.menuLineArrForCategoryTemp = menuLineArrForCategory;
    return menuLineArrForCategory;
  }

  categoryDietTypeAndetArrFilter(response: any[]) {
    //console.log("response", response);
    //var menuLineArrForCategory = Object.create({category:[], categories:[]});
    var categoryArr = ['Gratinkost', 'Cremet energitæt'];
    var menuLineArrForCategory = [];
    //var exit_loops = false;
    //var lowerCaseCategory = category.toLowerCase();

    //console.log("In filterResponseByCategory lowerCaseCategory=" + lowerCaseCategory);
    //for(var n=0; n < response[day]['mainDishes'].length; n++ ){
    for (var k = 0; k < response.length; k++) {
      for (var m = 0; m < response[k]['dietTypes'].length; m++) {
        //console.log("nAndet", n);
        //console.log("kAndet", k);
        //console.log("mAndet", m);
        // console.log("response[day]22="+JSON.stringify(response[day]["mainDishes"][n]["dietTypeGroups"][k]["dietTypes"][m]["dietType"]));
        for (var catAndet in categoryArr) {
          //console.log("catAndet", catAndet);
          if (
            response[k]['dietTypes'][m]['dietType']['name'] ==
            categoryArr[catAndet]
          ) {
            //	console.log("In categoryFilter. Category found")

            menuLineArrForCategory.push(
              response[k]['dietTypes'][m]['menuLine']
            );
            //	console.log("menuLineArrForCategoryAndet", menuLineArrForCategory);

            //exit_loops = true;
            //break;
          } else {
            //	console.log("In categoryFilter. Category not found")
          }
        }
      }
    }

    //}

    console.log('RETURNAndet' + JSON.stringify(menuLineArrForCategory));
    this.menuLineArrForCategoryTemp = menuLineArrForCategory;
    return menuLineArrForCategory;
  }

  productNrForDaysimpleOderModelArrayFilter(
    simpleOderModelArray: any,
    day: string
  ) {
    let productNrArray = [];
    for (let orderBulk in simpleOderModelArray) {
      if (
        simpleOderModelArray[orderBulk]['Day'] == day &&
        simpleOderModelArray[orderBulk]['sumOder'] != 0
      ) {
        productNrArray.push(simpleOderModelArray[orderBulk]['dishId']);
      }
    }

    console.log('productNrArray', productNrArray);
    return productNrArray;
  }
  /*productNrFilter(responseDay:any[], productNrArray:number[]){
		console.log("productNrArray", productNrArray);
		console.log("responseDay", responseDay);

		let menuLineArrForCategory = [];

			for(let itemMenuLine in  responseDay["mainDishes"]){
				//console.log("productNrFiltermenuLine", responseDay["mainDishes"][itemMenuLine]["menuLine"]);
				if(responseDay["mainDishes"][itemMenuLine]["menuLine"]!==undefined){
					//console.log("responseDay[\"mainDishes\"][itemMenuLine][\"menuLine\"]", responseDay["mainDishes"][itemMenuLine]["menuLine"]);

					for(let productNr in productNrArray){
					//console.log("productNrFilterproductNr", productNr);
					//console.log("responseDayproductNrArray", responseDay["mainDishes"][itemMenuLine]["menuLine"]);
				if(responseDay["mainDishes"][itemMenuLine]["menuLine"]["productNr"]==productNrArray[productNr]){

					//console.log("productNrArray[productNr]", productNrArray[productNr]);

					menuLineArrForCategory.push(responseDay["mainDishes"][itemMenuLine]);

				}else{}

			}
				}

		}

		console.log("menuLineArrForCategory", menuLineArrForCategory);
		return menuLineArrForCategory;

	}
*/

  productNrFilterForGetDishes(
    responseDay: any[],
    productNrArray: number[],
    mainMenuLine: number
  ) {
    /*console.log("mainMenuLine", mainMenuLine);
		console.log("productNrArray", productNrArray);
		console.log("responseDay", responseDay);*/

    let menuLineArrForCategory = [];

    for (let itemMenuLine in responseDay) {
      //console.log("productNrFiltermenuLine", responseDay["mainDishes"][itemMenuLine]["menuLine"]);
      if (responseDay[itemMenuLine]['menuLine'] !== undefined) {
        //console.log("responseDay[\"mainDishes\"][itemMenuLine][\"menuLine\"]", responseDay["mainDishes"][itemMenuLine]["menuLine"]);
        if (mainMenuLine == 0) {
          for (let productNr in productNrArray) {
            console.log('In mainMenuLine == 0', itemMenuLine);
            if (
              responseDay[itemMenuLine]['menuLine']['productNr'] ==
              productNrArray[productNr]
            ) {
              menuLineArrForCategory.push(responseDay[itemMenuLine]);
            }
          }
        } else {
          if (responseDay[itemMenuLine]['menuLine']['line'] == mainMenuLine) {
            menuLineArrForCategory.push(responseDay[itemMenuLine]);
            console.log('In Line=1, itemMenuLine=', itemMenuLine);
          }
        }
      }
    }

    console.log('menuLineArrForCategory', menuLineArrForCategory);
    return menuLineArrForCategory;
  }

  //-------------------Filter for Printing Dishes--------------------------------
  filterForPrintDishes(responseDay: any[]) {
    /*console.log("mainMenuLine", mainMenuLine);
		console.log("productNrArray", productNrArray);
		console.log("responseDay", responseDay);*/

    let menuLineArrForCategory = [];

    for (let itemMenuLine in responseDay) {
      //console.log("productNrFiltermenuLine", responseDay["mainDishes"][itemMenuLine]["menuLine"]);
      if (
        responseDay[itemMenuLine]['menuLine'] !== undefined &&
        responseDay[itemMenuLine]['print'] == true
      ) {
        menuLineArrForCategory.push(responseDay[itemMenuLine]);
      }
    }

    console.log(
      '[filterForPrintDishes] menuLineArrForCategory',
      menuLineArrForCategory
    );
    return menuLineArrForCategory;
  }

  //------------------------------------------------------------------------------

  arrayDishesViewForDayByCategoryFilter(
    day: string,
    dishAllocationsOrderDays: DishAllocationsOrderDays,
    category: any
  ) {
    var viewArrayForCategoty = new Array();
    var exit_loops = false;

    for (var cat in dishAllocationsOrderDays[day]['categories']) {
      for (var property in dishAllocationsOrderDays[day]['categories'][cat][
        'category'
      ]) {
        if (
          dishAllocationsOrderDays[day]['categories'][cat]['category'][
            property
          ] == category
        ) {
          //console.log("Category is Found ");

          viewArrayForCategoty =
            dishAllocationsOrderDays[day]['categories'][cat][
              'arrayDishesViewForDay'
            ];

          exit_loops = true;
          break;
        } else {
          //console.log("Category is NotFound ");
        }
      }

      if (exit_loops) break;
    }

    return viewArrayForCategoty;
  }

  prevWeek() {
    this.shiftWeek--;
    this.localStorageService.set(
      'shiftWeek' + this.localStorageService.get('currentChildRoute'),
      this.shiftWeek
    );
    this.needNgOninit = true;
    this.needUnsubscribe = false;
    this.updateCartFromLocalStorage();
    this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
    //this.ngOnInit();
  }
  nextWeek() {
    this.shiftWeek++;
    this.localStorageService.set(
      'shiftWeek' + this.localStorageService.get('currentChildRoute'),
      this.shiftWeek
    );
    this.needNgOninit = true;
    this.needUnsubscribe = false;
    this.updateCartFromLocalStorage();
    this.router.navigateByUrl('/minmenu/bulkorder/editweekplan');
    //this.ngOnInit();
  }

  /*	getImgUrl(imageUrl:string):string{

	  //	console.log("IMAGEUrl:"+ imageUrl);
	  let imgUrl = this.dataSource.baseUrl + imageUrl
	  //	console.log("IMG:"+ imgUrl);
	 // 	return imgUrl.toString();
	 return imgUrl;
	}
*/
  getImgUrl(dish: any): string {
    console.debug('[getImgUrl] dish:' + dish);
    let imgUrl;
    if (
      dish &&
      dish['dishPhotos'] &&
      dish['dishPhotos'].length != 0 &&
      dish['dishPhotos'][0] &&
      dish['dishPhotos'][0]['imageUrl'] != ''
    ) {
      imgUrl = this.dataSource.baseUrl + dish['dishPhotos'][0]['imageUrl'];
      console.debug('[getImgUrl] imgUrl', imgUrl);
      return imgUrl;
    } else {
      return 'assets/img/defaultImg.png';
    }
  }
  getProdImg(prodImg: string) {
    var img = 'data:image/jpeg;base64,' + prodImg;

    //let imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(img);
    return this.sanitizer.bypassSecurityTrustResourceUrl(img);
  }

  getTargetId(cat: any, nameDay: string, itemDish: any): string {
    //  console.log("In getTargetId cat:"+ cat);
    //  console.log("In getTargetId nameDay:"+ nameDay);
    //  console.log("In getTargetId itemDish:"+ itemDish);
    var id = '#' + cat + nameDay + itemDish;
    //  	console.log("In getTargetId IMG:"+ id);
    return id.toString();
  }

  getId(cat: any, nameDay: string, itemDish: any): string {
    var id = cat + nameDay + itemDish;
    //  	console.log("In getId IMG:"+ id);
    return id.toString();
  }

  getOrderDishId(
    fromPage: string,
    shiftWeek: number,
    day: string,
    cat: number,
    dishPlace: number
  ) {
    /*console.log("fromPage="+fromPage);
  		console.log("shiftWeek="+shiftWeek);
  		console.log("day="+day);
  		console.log("dishPlace="+dishPlace);*/
    //console.log("cat="+cat);

    var pageNumber;
    var orderDishId: number;
    var week = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    switch (fromPage) {
      case 'frokostpakker': {
        pageNumber = 1;
        //	console.log("frokostpakker");
        break;
      }
      case 'extra': {
        pageNumber = 2;
        //console.log("extra");
        break;
      }
    }
    //console.log("EEE"+this.dateService.getCurrentWeek())
    //var w = ((this.dateService.getCurrentWeek() +shiftWeek))*100;
    //console.log("w="+w);
    orderDishId =
      pageNumber * 1000000 +
      Number(this.dateService.getCurrentWeek() + shiftWeek) * 1000 +
      week.indexOf(day) * 1000 +
      cat * 100 +
      dishPlace;
    return orderDishId;
  }

  /*
    subProductToCart(dish: SimpleOderModel, quantity: number){
     	this.cart.subLine(dish, quantity);
    }
*/
  updateCartFromLocalStorage() {
    this.cart.updateLinesFromLocalStorage(this.shiftWeekNumber);
  }

  getRowsNumberForDay(numberElementsInRow: number, dishesArraylength: number) {
    var rowsNumber = Math.trunc(dishesArraylength / numberElementsInRow);
    //	console.log("In getRowsNumberForDay rowsNumber="+rowsNumber);

    if (dishesArraylength % numberElementsInRow != 0) {
      rowsNumber++;
    }
    if (rowsNumber == 0) {
      rowsNumber = 1;
    }
    return rowsNumber;
  }

  getArrayDishesViewForDayController(
    numberElementsInRow: number,
    dishesArraylength: number
  ) {
    var rows = this.getRowsNumberForDay(numberElementsInRow, dishesArraylength);
    //	console.log("rows="+rows);

    var arrayViewController = new Array(rows);
    for (var i = 0; i < arrayViewController.length; i++) {
      arrayViewController[i] = new Array(numberElementsInRow);
    }
    for (var i = 0; i < arrayViewController.length; i++) {
      for (var j = 0; j < arrayViewController[i].length; j++) {
        arrayViewController[i][j] = -1;
      }
    }

    //	console.log("array="+JSON.stringify(arrayViewController));

    var k = numberElementsInRow;
    var ref = 0;
    for (var i = 0; i < rows; i++) {
      if (dishesArraylength > numberElementsInRow) {
        if (i == rows - 1) {
          if (dishesArraylength % numberElementsInRow != 0) {
            k = dishesArraylength % numberElementsInRow;
            console.log('k=' + k);

            arrayViewController[i].length = k; // trim last row to the number of items remainder
          }
        }
      } else {
        k = dishesArraylength;
        arrayViewController[i].length = k;
      }

      for (var j = 0; j < k; j++) {
        arrayViewController[i][j] = ref;
        ref++;
      }
    }

    //	console.log("array="+JSON.stringify(arrayViewController));

    return arrayViewController;
  }

  isDayForOrder(orderingDates: any[], nameDay: string, shiftWeek: number) {
    //	if(this.filterOn){
    var isDayForOrderFlag = false;
    var date = this.dateService.getISODateYYYMMDDforDay(nameDay, shiftWeek);
    //	console.log("DATE ="+date);
    for (var day in this.orderingDates) {
      if (date == this.orderingDates[day]) {
        isDayForOrderFlag = true;
      }
    }

    return isDayForOrderFlag;

    //return true;
  }

  translateDayToDanishsShort(day: string) {
    return this.localeDaDKService.translateDayToDanishsShort(day);
  }

  getServingDateForProductNr(
    productNr: number,
    deliveryDay: string,
    mainSdeDish: string
  ) {
    console.debug('[getServingDateForProductNr] productNr', productNr);
    console.debug('[getServingDateForProductNr] deliveryDay', deliveryDay);
    console.debug('[getServingDateForProductNr] mainSdeDish', mainSdeDish);

    let servingDay = '';
    for (let item in this.dishMainSideOrderDays[deliveryDay][mainSdeDish]) {
      if (
        this.dishMainSideOrderDays[deliveryDay][mainSdeDish][item] !== undefined
      ) {
        if (
          this.dishMainSideOrderDays[deliveryDay][mainSdeDish][item][
            'menuLine'
          ]['productNr'] == productNr
        ) {
          servingDay = this.dishMainSideOrderDays[deliveryDay][mainSdeDish][
            item
          ]['servingDate'];
        }
      }
    }

    console.debug('[getServingDateForProductNr] servingDay', servingDay);
    return servingDay;
  }

  getNameServingDay(DateYYYYMMDD: string) {
    return this.dateService.getNameDayFromISODateYYYYMMDD(DateYYYYMMDD);
  }
  getDayDate(day: string) {
    //if (day != 'Sunday' && day != 'Monday') {
    if (day != 'Monday') {
      return this.dateService.getISODateDDMMMforDay(day, this.shiftWeek);
    } else {
      return this.dateService.getISODateDDMMMforDay(day, this.shiftWeek + 1);
    }
  }

  //------------------------------------Compare--------------------------------
  compareDates(a, b) {
    console.log('[filterTwoDishesForEachDay] a', a['servingDate']);
    console.log('[filterTwoDishesForEachDay] b', b['servingDate']);
    if (!moment(b['servingDate']).isAfter(a['servingDate']) == true) return 1;
    if (moment(b['servingDate']).isSame(a['servingDate']) == true) return 0;
    if (moment(b['servingDate']).isAfter(a['servingDate']) == true) return -1;
  }

  //-----------------------------------Sort----------------------------------
  sortMenuLineArrForCategory(menuLineArrForCategory: any) {
    if (menuLineArrForCategory[0] && menuLineArrForCategory[0]['servingDate']) {
      console.log(
        '[sortMenuLineArrForCategory] menuLineArrForCategory',
        menuLineArrForCategory
      );
      console.log(
        '[sortMenuLineArrForCategory] menuLineArrForCategory',
        menuLineArrForCategory[0]['servingDate']
      );
      return menuLineArrForCategory.sort(this.compareDates);
    } else return (menuLineArrForCategory = []);
  }

  filterMainDishes(menuLineArrForCategory: any) {
    console.log(
      '[filterMainDishes] menuLineArrForCategory',
      JSON.stringify(menuLineArrForCategory)
    );
    let menuLineMainDishArr = [];
    if (menuLineArrForCategory.length != 0) {
      menuLineMainDishArr = menuLineArrForCategory.filter((item) => {
        return item['sideDish'] != true;
      });
    }
    return menuLineMainDishArr;
  }

  filterSideDishes(menuLineArrForCategory: any) {
    console.log(
      '[filterSideDishes] menuLineArrForCategory',
      JSON.stringify(menuLineArrForCategory)
    );
    let menuLineMainDishArr = [];
    if (menuLineArrForCategory.length != 0) {
      menuLineMainDishArr = menuLineArrForCategory.filter((item) => {
        return item['sideDish'] == true;
      });
    }
    return menuLineMainDishArr;
  }

  divideByMainSideDishes(dishAllocationsOrderDay: any, day: string) {
    console.log(
      '[divideByMainSideDishes] dishAllocationsOrderDay',
      dishAllocationsOrderDay
    );
    let mainSideArr = new MainSideArr();
    let dishMainSideOrderDays = new DishMainSideOrderDays(
      mainSideArr,
      mainSideArr,
      mainSideArr,
      mainSideArr,
      mainSideArr,
      mainSideArr,
      mainSideArr
    );

    console.log(
      '[divideByMainSideDishes] dishMainSideOrderDays',
      dishMainSideOrderDays
    );

    for (let itemDish in dishAllocationsOrderDay) {
      if (dishAllocationsOrderDay[itemDish]['sideDish'] == false) {
        dishMainSideOrderDays[day]['mainDish'].push(
          dishAllocationsOrderDay[itemDish]
        );
        if (
          dishAllocationsOrderDay.find(
            (item) =>
              item['servingDate'] ==
                dishAllocationsOrderDay[itemDish]['servingDate'] &&
              item['sideDish'] == true
          )
        ) {
        } else {
          dishMainSideOrderDays[day]['sideDish'].push(undefined);
        }
      } else if (dishAllocationsOrderDay[itemDish]['sideDish'] == true) {
        dishMainSideOrderDays[day]['sideDish'].push(
          dishAllocationsOrderDay[itemDish]
        );
        if (
          dishAllocationsOrderDay.find(
            (item) =>
              item['servingDate'] ==
                dishAllocationsOrderDay[itemDish]['servingDate'] &&
              item['sideDish'] != true
          )
        ) {
        } else {
          dishMainSideOrderDays[day]['mainDish'].push(undefined);
        }
      }
    }

    console.log(
      '[divideByMainSideDishes] dishMainSideOrderDays',
      dishMainSideOrderDays[day]
    );
    return dishMainSideOrderDays[day];
  }
}
