
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import "rxjs/add/observable/from";
import { AllocatedDishModel } from "./allocatedDishModel.model";


@Injectable()
export class DishAllocationWeekDataBase {

	public dishesArray: AllocatedDishModel[] = [];



	
   
    getDishes(): AllocatedDishModel[] {
        return this.dishesArray;
    }
/*
     saveOrder(order: Order): Observable<Order> {
        console.log(JSON.stringify(order));
        return Observable.from([order]);
    }
*/
}
