import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DishResponseService {

constructor() { }



  checkPermittionsForOrder(
    dishAllocationsWeekResponse: any
  ) {
    if (dishAllocationsWeekResponse
      && dishAllocationsWeekResponse[0]
      && dishAllocationsWeekResponse[0]['servings']
      && dishAllocationsWeekResponse[0]['servings'][0]
    ) {
      if (!dishAllocationsWeekResponse[0]['servings'][0]['deadlineStatus']
      && dishAllocationsWeekResponse[0]['servings'][0]['orderPermitStatus'] &&
      dishAllocationsWeekResponse[0]['servings'][0]['orderOperation'] != 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }



  }
}
