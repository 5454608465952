import { MenuLine } from "./menuLine.model";


export class BulkOrderMenuLineEditModel  {
    constructor(
            public  orderNr?:number,
            public  customerNr?:number,
            public  menuNr?:number,
            public  menuLines?:MenuLine[],
    
            ) { }
}




