import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable()
export class NavigationService {
  constructor() {}

  goToLinkInNewTab(path: string) {
    let url = environment.baseLocalUrl + path;
    window.open(url, '_blank');
  }
}
