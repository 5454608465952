import { Injectable } from '@angular/core';
import { Component, Inject } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  ActivationEnd,
  Event,
  NavigationCancel,
  NavigationError,
  NavigationStart,
} from '@angular/router';
import { LocalStorageService } from '../../service/localStorage.service';
import { Cart } from '../../cart/cart-model/cart.model';
import { DateService } from '../../service/date.service';
import { AllocatedDishModel } from '../../model/allocatedDishModel.model';
import { OrderDish } from '../../model/orderDish.model';
import { DishAllocationWeekDataBase } from '../../model/dishAllocationWeek.database';
import { DishRepository } from '../../repository/dish.repository';
import { RestDataSource } from '../../repository/rest.datasource';
import { ModelResolver } from '../../resolver/model.resolver';
import { timer } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { of, from } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/concatAll';
import 'rxjs/add/operator/concatMap';
//import "rxjs/add/operator/map";
import { map } from 'rxjs/operators';
import { _ } from 'underscore';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { SafePipeModule } from 'safe-pipe';
import { AuthService } from '../../service/auth.service';
import * as moment from 'moment';
import { environment } from './../../../environments/environment';
import { NavigationService } from '../../service/navigation.service';
import { DishAllocationsOrderDays } from '../../model/dishAllocationsOrderDays.model';

class SimpleOderModel {
  //orderDishId:string;
  orderDishId: number;
  orderNumber: number;
  sumOder: number;
  Day: string;
  dishId: number;
  name: string;
  imageUrl: string;
  category: string;
  subcategory: string;

  // constructor(orderDishId:string, orderNumber:number, sumOder:number, Day:string,
  constructor(
    orderDishId: number,
    orderNumber: number,
    sumOder: number,
    Day: string,
    dishId: number,
    name: string,
    imageUrl: string,
    category: string,
    subcategory: string
  ) {
    this.orderDishId = orderDishId;
    this.orderNumber = orderNumber;
    this.sumOder = sumOder;
    this.Day = Day;
    this.dishId = dishId;
    this.name = name;
    this.imageUrl = imageUrl;
    this.category = category;
    this.subcategory = subcategory;
  }
}
class MainDishOnlyPrintDays {
  Tuesday: boolean;
  Thursday: boolean;
  Sunday: boolean;

  constructor() {
    this.Tuesday = false;
    this.Thursday = false;
    this.Sunday = false;
  }
}

@Component({
  selector: "printselectbulk-root",
  templateUrl: 'printselectbulk.component.html',
  styleUrls: ['printselectbulk.component.css'],
})
export class PrintSelectBulkComponent {
  public sumOder: number;

  public weeks: string[];
  //private dishesArray: AllocatedDishModel[] = [];
  //private dishesForDayArray: any[] = [];
  public dishAllocationsWeekResponse: any[] = [];
  //private orderDishArray: OrderDish[] = [];

  public currentWeek: string;
  //private showUserName: boolean;
  public lastIndex: number;
  //private rowArray:any[];
  //private itemArray:any[];
  obsSubscription: Subscription;
  public numberElementsInRow: number;
  //private arrayDishesView: number[];
  public arrayDishesViewForDay: any[];
  public orderDishId: number;
  public orderDaysLeftInWeekArray: string[];
  public dishAllocationsOrderDays: DishAllocationsOrderDays;
  //private dishAllocationsOrderDaysCategory:DishViewController[] = [];
  public currentToogledDay: string;
  public toggledMonday: boolean;
  public toggledWednesday: boolean;
  public toggledFriday: boolean;
  public SundayDate: string;
  public MondayDate: string;
  public TuesdayDate: string;
  public WednesdayDate: string;
  public ThursdayDate: string;
  public FridayDate: string;
  public SaturdayDate: string;
  public shiftWeekNumber: string;
  public shiftWeek: number;
  public needNgOninit: boolean;
  public simpleOderModelArray: SimpleOderModel[];
  public imgUrl: string;
  public tempArray: any[] = [];
  public categoryForFilter: any;
  //private ToogledDays:string;
  public simpleOderModelArrayId: string;
  public orderingDates: any[];
  public userInfoOrderingModel: any[] = [];
  //obsUserInfoOrderingModelSubscription: Subscription;
  public currentDayNumber: number;
  public filterOn: boolean;
  public isOrderingTimeFlag: boolean;
  public isEmptyMonday: boolean;
  public isEmptyTuesday: boolean;
  public isEmptyWednesday: boolean;
  public isEmptyThursday: boolean;
  public isEmptyFriday: boolean;
  public isEmptySaturday: boolean;
  public isEmptySunday: boolean;
  private departmentId: number;
  public modalBtnBackgroundColor: string;
  public fromPage: string;
  public mainDishOnlyPrintDays: MainDishOnlyPrintDays;

  closeResult: string;

  constructor(
    private dataSource: RestDataSource,
    private localStorageService: LocalStorageService,
    private cart: Cart,
    private router: Router,
    private dateService: DateService,
    private dishRepository: DishRepository,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private auth: AuthService,
    public navigationService: NavigationService
  ) {
    this.localStorageService.set('currentChildRoute', 'print/printselectbulk');
    this.localStorageService.set('parentPrintRoute', 'print/printselectbulk');
  }

  ngOnInit(): void {
    //console.log("frokostpakker in NGONINIT");

    /*var t1 = moment().valueOf();
		console.log("1st block="+t1);*/
    this.needNgOninit = false;

    //NavTabs init
    this.toggledMonday = true;
    this.toggledWednesday = false;
    this.toggledFriday = false;
    //--------------------------------Routing---------------------------
    this.fromPage = this.localStorageService.get('fromPage');

    console.log('[ngOnInit] this.fromPage', this.fromPage);
    //-----------------------------Depatments --------------------------------------------
    this.departmentId = this.localStorageService.get('currentdepartmentId');

    //----------------------------Print All-------------------------------------------
    this.localStorageService.set('printViewWekplanAll', false);

    //----------------------------------------------------------------------

    this.sumOder = this.localStorageService.get('sumOder');

    this.dishAllocationsOrderDays = new DishAllocationsOrderDays();

    this.dishAllocationsWeekResponse = [];

    //---------------------Date------------------------------------

    //this.shiftWeek = 0;
    // if NgOnInit after chabging week load from LocalStorage
    //if(this.needNgOninit){
    this.shiftWeek = this.localStorageService.get(
      'shiftWeek' + this.localStorageService.get('currentChildRoute')
    );

    //	}
    console.log('this.shiftWeek=' + this.shiftWeek);
    this.currentDayNumber = this.dateService.getCurrentDayNumber();

    this.currentWeek = this.dateService.getCurrentWeek();

    this.shiftWeekNumber = this.dateService.getShiftWeek(this.shiftWeek);

    this.SundayDate = this.dateService.getISODateDDMMMforDay(
      'Sunday',
      this.shiftWeek
    );
    this.MondayDate = this.dateService.getISODateDDMMMforDay(
      'Monday',
      this.shiftWeek
    );
    this.TuesdayDate = this.dateService.getISODateDDMMMforDay(
      'Tuesday',
      this.shiftWeek
    );
    this.WednesdayDate = this.dateService.getISODateDDMMMforDay(
      'Wednesday',
      this.shiftWeek
    );
    this.ThursdayDate = this.dateService.getISODateDDMMMforDay(
      'Thursday',
      this.shiftWeek
    );
    this.FridayDate = this.dateService.getISODateDDMMMforDay(
      'Friday',
      this.shiftWeek
    );
    this.SaturdayDate = this.dateService.getISODateDDMMMforDay(
      'Saturday',
      this.shiftWeek
    );

    //-------------------------Btn init-------------------------
    this.modalBtnBackgroundColor = '#5B7B12';

    //------------------------mainDishOnlyPrintDays init---------
    this.mainDishOnlyPrintDays = new MainDishOnlyPrintDays();
    this.localStorageService.set(
      'mainDishOnlyPrintDays' +
        this.localStorageService.get('parentPrintRoute'),
      this.mainDishOnlyPrintDays
    );
  }
  //-----------------------------------------------------------------------------------------------------
  //*****************************************************************************************************
  //------------------------------- Component Internal Methods------------------------------------------
  ngAfterContentChecked() {
    if (this.needNgOninit) {
      this.ngOnInit();
    }

    if (!environment.debugMode) {
      console.clear();
    }
  }
  ngOnDestroy() {
    //this.obsSubscription.unsubscribe();
  }

  //-----------------------------Methods--------------------------------------------------------------

  /*	getDishesFromFilteredResponseByCategory(day:number, response:any[], categoryId:any){
		var dishArrForCategoty = new Array();
		var exit_loops = false;
		//console.log("In filterResponseByCategory categoryId=" + categoryId);
		for(var n=0; n < response[day].length; n++ ){
		//	console.log("In filterResponseByCategory response[0][day].length=" + response[0][day].length);
		 for (var i=0; i<response[day][n]["categories"].length; i++){
		//console.log("response[0][day][n][categories][i]="+JSON.stringify(response[0][day][n]["categories"][i]));

			for (var property in response[day][n]["categories"][i]["category"]){


				if(response[day][n]["categories"][i]["category"][property]==categoryId){

			//	console.log("Category is Found ");
				//console.log("DAY" + day);
				dishArrForCategoty = response[day][n]["categories"][i]["dishes"];

				// console.log("dishArrForCategoty" + JSON.stringify(dishArrForCategoty));
				exit_loops = true;
            	break;

				}else{
			//		console.log("Category is NotFound ");
					dishArrForCategoty = response[day][n]["categories"][0]["dishes"];
				}

			}

			if (exit_loops)
		        break;
			 }

			 if (exit_loops)
        	break;
		}
		// console.log("RETURN" + JSON.stringify(dishArrForCategoty));
		return dishArrForCategoty
	}
*/
  /*	universalFilter(day:number, response:any[], category:any){
		console.log("In UniversalFilter ");
		var result=_.chain(response[day])
		.map(function(element) { return _.flatten(element)})


		//.map(function(element) { return _.where(element, {name:category}); })

		//.map(function(element) { return _.indexBy(element, 'name'); })
		//.map(function(element) { return _.compact(element)})
		.map(function(element) { return _.flatten(element)})
		.map(function(element) { return _.indexBy(element, 'name'); })

		.value();
				console.log("result="+JSON.stringify(result));

		//var resIndexBy = _.groupBy(result, category);
		//console.log("resIndexBy="+JSON.stringify(resIndexBy));
		//console.log("response.flatten="+JSON.stringify(_.flatten(response, true)));

	}
*/
  categoryFilter(day: number, response: any[], category: any) {
    var dishArrForCategoty = Object.create({ category: [], categories: [] });
    var exit_loops = false;
    var lowerCaseCategory = category.toLowerCase();

    console.log(
      'In filterResponseByCategory lowerCaseCategory=' + lowerCaseCategory
    );
    for (var n = 0; n < response[day].length; n++) {
      //	console.log("In filterResponseByCategory response[day][n][category][name]="+ response[day][n]["category"][name]);
      if (
        response[day][n]['category']['name'] == category ||
        response[day][n]['category']['name'] == lowerCaseCategory
      ) {
        //	console.log("In categoryFilter. Category found")

        dishArrForCategoty.category = response[day][n]['category'];
        dishArrForCategoty.categories = response[day][n]['categories'];
        exit_loops = true;
        break;
      } else {
        //	console.log("In categoryFilter. Category not found")
      }
    }

    // console.log("RETURN" + JSON.stringify(dishArrForCategoty));
    return dishArrForCategoty;
  }

  arrayDishesViewForDayByCategoryFilter(
    day: string,
    dishAllocationsOrderDays: DishAllocationsOrderDays,
    category: any
  ) {
    var viewArrayForCategoty = new Array();
    var exit_loops = false;

    for (var cat in dishAllocationsOrderDays[day]['categories']) {
      for (var property in dishAllocationsOrderDays[day]['categories'][cat][
        'category'
      ]) {
        if (
          dishAllocationsOrderDays[day]['categories'][cat]['category'][
            property
          ] == category
        ) {
          //console.log("Category is Found ");

          viewArrayForCategoty =
            dishAllocationsOrderDays[day]['categories'][cat][
              'arrayDishesViewForDay'
            ];

          exit_loops = true;
          break;
        } else {
          //console.log("Category is NotFound ");
        }
      }

      if (exit_loops) break;
    }

    return viewArrayForCategoty;
  }

  sub(cat: any, element: number, nameDay: string) {
    //var orderDishIdTCur = nameDay + cat + element.toString();
    var orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      cat,
      element
    );
    for (var oder in this.simpleOderModelArray) {
      if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
        if (this.simpleOderModelArray[oder].orderNumber != 0) {
          this.simpleOderModelArray[oder].orderNumber -= 1;
          if (this.simpleOderModelArray[oder].orderNumber < 0) {
            this.simpleOderModelArray[oder].orderNumber = 0;
          }
          this.simpleOderModelArray[oder].sumOder -= 1;
          if (this.simpleOderModelArray[oder].sumOder < 0) {
            this.simpleOderModelArray[oder].sumOder = 0;
          }
        }
      }
    }

    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );

    //console.log("dishAllocationsOrderDays[nameDay][0][element]="+JSON.stringify(this.dishAllocationsOrderDays[nameDay][0][element]));

    //this.subProductToCart(this.dishAllocationsOrderDays[nameDay]["categories"][cat]["dishes"][element],1)
    this.updateCartFromLocalStorage();

    this.sumOder -= 1;
    if (this.sumOder < 0) {
      this.sumOder = 0;
    }
  }

  add(cat: any, element: number, nameDay: string) {
    var orderFound = false;

    //	var orderDishIdTCur = nameDay + cat + element.toString();

    var orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      cat,
      element
    );
    //console.log("orderDishIdTCurNumber="+orderDishIdTCur);

    if (this.simpleOderModelArray.length == 0) {
      //console.log("this.simpleOderModelArray.length="+this.simpleOderModelArray.length);
      this.simpleOderModelArray.push(
        new SimpleOderModel(
          orderDishIdTCur,
          1,
          1,
          nameDay,
          //		this.dishAllocationsOrderDays[nameDay][0][element]["id"]));
          this.dishAllocationsOrderDays[nameDay]['categories'][cat]['dishes'][
            element
          ]['id'],
          this.dishAllocationsOrderDays[nameDay]['categories'][cat]['dishes'][
            element
          ]['name'],
          this.dishAllocationsOrderDays[nameDay]['categories'][cat]['dishes'][
            element
          ]['dishPhotos'][0]['imageUrl'],
          this.dishAllocationsOrderDays[nameDay]['category']['name'],
          this.dishAllocationsOrderDays[nameDay]['categories'][cat]['category'][
            'name'
          ]
        )
      );
    } else {
      for (var oder in this.simpleOderModelArray) {
        if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
          orderFound = true;
          this.simpleOderModelArray[oder].orderNumber += 1;
          this.simpleOderModelArray[oder].sumOder += 1;
        }
      }

      if (!orderFound) {
        //		console.log("Else="+element);
        this.simpleOderModelArray.push(
          new SimpleOderModel(
            orderDishIdTCur,
            1,
            1,
            nameDay,
            //	this.dishAllocationsOrderDays[nameDay][0][element]["id"]));
            this.dishAllocationsOrderDays[nameDay]['categories'][cat]['dishes'][
              element
            ]['id'],
            this.dishAllocationsOrderDays[nameDay]['categories'][cat]['dishes'][
              element
            ]['name'],
            this.dishAllocationsOrderDays[nameDay]['categories'][cat]['dishes'][
              element
            ]['dishPhotos'][0]['imageUrl'],
            this.dishAllocationsOrderDays[nameDay]['category']['name'],
            this.dishAllocationsOrderDays[nameDay]['categories'][cat][
              'category'
            ]['name']
          )
        );
      }
    }

    this.localStorageService.set(
      this.simpleOderModelArrayId,
      this.simpleOderModelArray
    );

    //this.addProductToCart(this.dishAllocationsOrderDays[nameDay]["categories"][cat]["dishes"][element]);
    this.updateCartFromLocalStorage();
    this.sumOder += 1;
  }

  getSumOder(cat: any, element: number, nameDay: string) {
    var sumOder = 0;
    //var orderDishIdTCur = nameDay + cat + element.toString();
    var orderDishIdTCur = this.getOrderDishId(
      this.localStorageService.get('currentChildRoute'),
      this.shiftWeek,
      nameDay,
      cat,
      element
    );
    for (var oder in this.simpleOderModelArray) {
      if (this.simpleOderModelArray[oder].orderDishId == orderDishIdTCur) {
        sumOder = this.simpleOderModelArray[oder].sumOder;
      }
    }

    return sumOder;
  }

  prevWeek() {
    this.shiftWeek--;
    this.localStorageService.set(
      'shiftWeek' + this.localStorageService.get('currentChildRoute'),
      this.shiftWeek
    );
    this.needNgOninit = true;
    this.updateCartFromLocalStorage();
    //	this.localStorageService.set(this.simpleOderModelArrayId, null);
    this.router.navigateByUrl('/minmenu/print/printselectbulk');
    //this.ngOnInit();
  }
  nextWeek() {
    this.shiftWeek++;
    this.localStorageService.set(
      'shiftWeek' + this.localStorageService.get('currentChildRoute'),
      this.shiftWeek
    );
    this.needNgOninit = true;
    this.updateCartFromLocalStorage();
    //	this.localStorageService.set(this.simpleOderModelArrayId, null);
    this.router.navigateByUrl('/minmenu/print/printselectbulk');
    //this.ngOnInit();
  }

  getImgUrl(imageUrl: string): string {
    //	console.log("IMAGEUrl:"+ imageUrl);
    var imgUrl = this.dataSource.baseUrl + imageUrl;
    //	console.log("IMG:"+ imgUrl);
    // 	return imgUrl.toString();
    return imgUrl;
  }

  /*	public getSantizeUrl(imageUrl : string) {

    //return this.sanitizer.bypassSecurityTrustUrl(this.getImgUrl(url));
   // return 'https://testapi.minmenu.com/Content/Upload/DishImages/331/496197e5-4161-4aab-bee3-7f66d5c1fe78.jpg'

	var imgUrl = this.dataSource.baseUrl + imageUrl
	//  	console.log("IMG:"+ imgUrl);
	  	return imgUrl;

	}
*/

  getTargetId(cat: any, nameDay: string, itemDish: any): string {
    //  console.log("In getTargetId cat:"+ cat);
    //  console.log("In getTargetId nameDay:"+ nameDay);
    //  console.log("In getTargetId itemDish:"+ itemDish);
    var id = '#' + cat + nameDay + itemDish;
    //  	console.log("In getTargetId IMG:"+ id);
    return id.toString();
  }

  getId(cat: any, nameDay: string, itemDish: any): string {
    var id = cat + nameDay + itemDish;
    //  	console.log("In getId IMG:"+ id);
    return id.toString();
  }

  /*
    addProductToCart(dish: SimpleOderModel) {
        this.cart.addLine(dish);


    }
*/
  /* getOrderDishId(){
    	return this.orderDishId++;
    }
  */

  getOrderDishId(
    fromPage: string,
    shiftWeek: number,
    day: string,
    cat: number,
    dishPlace: number
  ) {
    /*console.log("fromPage="+fromPage);
  		console.log("shiftWeek="+shiftWeek);
  		console.log("day="+day);
  		console.log("dishPlace="+dishPlace);*/
    //console.log("cat="+cat);

    var pageNumber;
    var orderDishId: number;
    var week = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    switch (fromPage) {
      case 'frokostpakker': {
        pageNumber = 1;
        //	console.log("frokostpakker");
        break;
      }
      case 'extra': {
        pageNumber = 2;
        //console.log("extra");
        break;
      }
    }
    //console.log("EEE"+this.dateService.getCurrentWeek())
    //var w = ((this.dateService.getCurrentWeek() +shiftWeek))*100;
    //console.log("w="+w);
    orderDishId =
      pageNumber * 1000000 +
      Number(this.dateService.getCurrentWeek() + shiftWeek) * 1000 +
      week.indexOf(day) * 1000 +
      cat * 100 +
      dishPlace;
    return orderDishId;
  }

  /*
    subProductToCart(dish: SimpleOderModel, quantity: number){
     	this.cart.subLine(dish, quantity);
    }
*/
  updateCartFromLocalStorage() {
    this.cart.updateLinesFromLocalStorage(this.shiftWeekNumber);
  }

  accept() {
    //this.shiftWeek--;
    //this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'currentChildRoute'), this.shiftWeek );
    //this.needNgOninit = true;
    //this.updateCartFromLocalStorage();
    //	this.localStorageService.set(this.simpleOderModelArrayId, null);
    this.router.navigateByUrl('/minmenu/cart');
  }

  /*    getRowsFordishesArray(numberElementsInRow:number){
    	var rowsNumber = Math.trunc(this.dishesArray.length/numberElementsInRow);
    	if(this.dishesArray.length%numberElementsInRow!=0){
    		rowsNumber++;
    	}
    	return new Array(rowsNumber);

    	///return [1, 2, 3]
    }
*/
  /*    getRowsNumber(numberElementsInRow:number){
    	var rowsNumber = Math.trunc(this.dishesArray.length/numberElementsInRow);
    	if(this.dishesArray.length%numberElementsInRow!=0){
    		rowsNumber++;
    	}
    	return rowsNumber;


    }
*/
  getRowsNumberForDay(numberElementsInRow: number, dishesArraylength: number) {
    var rowsNumber = Math.trunc(dishesArraylength / numberElementsInRow);
    //	console.log("In getRowsNumberForDay rowsNumber="+rowsNumber);

    if (dishesArraylength % numberElementsInRow != 0) {
      rowsNumber++;
    }
    if (rowsNumber == 0) {
      rowsNumber = 1;
    }
    return rowsNumber;
  }

  /*    getArrayDishesViewController(numberElementsInRow:number, dishesArraylength:number){

    	var rows=this.getRowsNumber(numberElementsInRow);
    	console.log("rows="+rows);


    	var arrayViewController = new Array(rows);

		for (var i = 0; i < arrayViewController.length; i++) {
		  arrayViewController[i] = new Array(numberElementsInRow);
		}
		for (var i = 0; i < arrayViewController.length; i++){
			for (var j = 0; j < arrayViewController[i].length; j++){
				arrayViewController[i][j] = -1;
			}
		}

	//	console.log("array="+JSON.stringify(arrayViewController));
    	var k = numberElementsInRow;
    	var ref = 0;
    	for(var i=0; i<rows; i++){
    		if(i == rows-1){
    			if(dishesArraylength%numberElementsInRow!=0){
    			k = dishesArraylength%numberElementsInRow;
    			console.log("k="+k);

    			arrayViewController[i].length = k;      // trim last row to the number of items remainder
    			}
    		}
    		for(var j=0; j<k; j++){

    			arrayViewController[i][j]=ref;
    			ref++;
    		}

    	}

    	console.log("array="+JSON.stringify(arrayViewController));

    return arrayViewController;
    }

	*/
  getArrayDishesViewForDayController(
    numberElementsInRow: number,
    dishesArraylength: number
  ) {
    var rows = this.getRowsNumberForDay(numberElementsInRow, dishesArraylength);
    //	console.log("rows="+rows);

    var arrayViewController = new Array(rows);
    for (var i = 0; i < arrayViewController.length; i++) {
      arrayViewController[i] = new Array(numberElementsInRow);
    }
    for (var i = 0; i < arrayViewController.length; i++) {
      for (var j = 0; j < arrayViewController[i].length; j++) {
        arrayViewController[i][j] = -1;
      }
    }

    //	console.log("array="+JSON.stringify(arrayViewController));

    var k = numberElementsInRow;
    var ref = 0;
    for (var i = 0; i < rows; i++) {
      if (dishesArraylength > numberElementsInRow) {
        if (i == rows - 1) {
          if (dishesArraylength % numberElementsInRow != 0) {
            k = dishesArraylength % numberElementsInRow;
            console.log('k=' + k);

            arrayViewController[i].length = k; // trim last row to the number of items remainder
          }
        }
      } else {
        k = dishesArraylength;
        arrayViewController[i].length = k;
      }

      for (var j = 0; j < k; j++) {
        arrayViewController[i][j] = ref;
        ref++;
      }
    }

    //	console.log("array="+JSON.stringify(arrayViewController));

    return arrayViewController;
  }

  toggleMonday() {
    this.toggledMonday = true;
    this.toggledWednesday = false;
    this.toggledFriday = false;
    //console.log("Monday Wednesday Friday" + this.toggledMonday+this.toggledWednesday+this.toggledFriday);
  }

  toggleWednesday() {
    this.toggledMonday = false;
    this.toggledWednesday = true;
    this.toggledFriday = false;
    //	console.log("Monday Wednesday Friday" + this.toggledMonday+this.toggledWednesday+this.toggledFriday);
  }

  toggleFriday() {
    this.toggledMonday = false;
    this.toggledWednesday = false;
    this.toggledFriday = true;
    //	console.log("Monday Wednesday Friday" + this.toggledMonday+this.toggledWednesday+this.toggledFriday);
  }

  isDayForOrder(orderingDates: any[], nameDay: string, shiftWeek: number) {
    //	if(this.filterOn){
    var isDayForOrderFlag = false;
    var date = this.dateService.getISODateYYYMMDDforDay(nameDay, shiftWeek);
    //	console.log("DATE ="+date);
    for (var day in this.orderingDates) {
      if (date == this.orderingDates[day]) {
        isDayForOrderFlag = true;
      }
    }

    return isDayForOrderFlag;

    //return true;
  }

  filterToogle() {
    this.filterOn = !this.filterOn;
  }

  isOrderingTime(userInfoOrderingModel: any[], categoryForFilter: string) {
    //	console.log("In isOrderingTime");
    var startServingTime = '';
    var endServingTime = '';
    var currentTime = 0;
    for (var userCategory in userInfoOrderingModel['userCategories']) {
      //		console.log("In isOrderingTime cat="+userInfoOrderingModel["userCategories"][userCategory]["category"]["name"]);
      if (
        userInfoOrderingModel['userCategories'][userCategory]['category'][
          'name'
        ] == categoryForFilter
      ) {
        startServingTime =
          userInfoOrderingModel['userCategories'][userCategory][
            'startServingTime'
          ];
        //	var dateStart =  this.dateService.getDateFromTimeHHmmss(startServingTime);

        //	console.log("IsstartServingTime=" + this.dateService.returnCurrentDateForTimeHHmmss(dateStart));
        endServingTime =
          userInfoOrderingModel['userCategories'][userCategory][
            'endServingTime'
          ];

        //	var dateEnd =  this.dateService.getDateFromTimeHHmmss(endServingTime);

        //	console.log("IsendServingTime=" + this.dateService.returnCurrentDateForTimeHHmmss(dateEnd));

        //	currentTime = this.dateService.getCurrentTime();
        //	console.log("currentTime=" + currentTime);
        //	if(this.dateService.returnCurrentDateForTimeHHmmss(dateStart)<=currentTime &&
        //	currentTime<=this.dateService.returnCurrentDateForTimeHHmmss(dateEnd)){
        if (
          this.dateService.isCurrentTimeBetween(
            startServingTime,
            endServingTime
          )
        ) {
          return (this.isOrderingTimeFlag = true);
          //	}else return this.isOrderingTimeFlag=false;
        } else return (this.isOrderingTimeFlag = true);
      }
    }
  }

  /*    open(content) {
		    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
		      this.closeResult = `Closed with: ${result}`;
		    }, (reason) => {
		    //  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		    });
  		}
*/
  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  Sunday: any[];
  Monday: any[];
  Tuesday: any[];
  Wednesday: any[];
  Thursday: any[];
  Friday: any[];
  Saturday: any[];

  selectPrintDay(day: string) {
    console.log('In selectPrintDay');

    //this.localStorageService.set('shiftWeek'+ this.localStorageService.get( 'currentChildRoute'), this.shiftWeek );
    //this.localStorageService.set('shiftWeek'+ "bulkorder/editweekplan", this.shiftWeek );
    //this.localStorageService.set('shiftWeek'+ "print/printweekplan", this.shiftWeek );
    //this.localStorageService.set('shiftWeek'+ 'bulkorder/printweekplan/printchoosebulk', this.shiftWeek);
    this.localStorageService.set('toggledChooseDayBulkOrder', day);
    this.localStorageService.set('printViewWekplanAll', false);
    //this.router.navigateByUrl("/minmenu/bulkorder/printweekplan/printchoosebulk");
    this.navigationService.goToLinkInNewTab(
      'minmenu/bulkorder/printweekplan/printchoosebulk'
    );
  }

  goToPrinttodaysmenu(day: string) {
    this.localStorageService.set('toggledChooseDayBulkOrder', day);
    this.localStorageService.set('printViewWekplanAll', true);
    this.navigationService.goToLinkInNewTab(
      'minmenu/bulkorder/printweekplan/printchoosebulk'
    );
  }

  selectedMainDishOnly(eventChecked: any, day: string) {
    console.log('[selectedMainDishOnly] eventChecked', eventChecked);
    console.log('[selectedMainDishOnly] day', day);
    if (day == 'Saturday') {
      day = 'Sunday';
    }
    if (eventChecked == true) {
      /*this.selectedWeekForPrintModel[selectedWeek]['mainDishOnly'] = true;
			let selectedWeekForPrintModelFiltered = this.filterSelectedWeekForPrintModel(JSON.parse(JSON.stringify(this.selectedWeekForPrintModel)));
        	this.localStorageService.set('selectedWeekForPrintModel'+ this.localStorageService.get( 'parentPrintRoute'), selectedWeekForPrintModelFiltered);
        	*/
      if (day == 'Week') {
        for (let tag in this.mainDishOnlyPrintDays) {
          this.mainDishOnlyPrintDays[tag] = true;
        }
      } else {
        this.mainDishOnlyPrintDays[day] = true;
        //this.localStorageService.set('mainDishOnlyPrintDays'+ this.localStorageService.get( 'parentPrintRoute'), this.mainDishOnlyPrintDays);
      }
    } else {
      /*
			this.selectedWeekForPrintModel[selectedWeek]['mainDishOnly'] = false;
			let selectedWeekForPrintModelFiltered = this.filterSelectedWeekForPrintModel(JSON.parse(JSON.stringify(this.selectedWeekForPrintModel)));
        	this.localStorageService.set('selectedWeekForPrintModel'+ this.localStorageService.get( 'parentPrintRoute'), selectedWeekForPrintModelFiltered);
        */
      if (day == 'Week') {
        for (let tag in this.mainDishOnlyPrintDays) {
          this.mainDishOnlyPrintDays[tag] = false;
        }
      } else {
        this.mainDishOnlyPrintDays[day] = false;
        //this.localStorageService.set('mainDishOnlyPrintDays'+ this.localStorageService.get( 'parentPrintRoute'), this.mainDishOnlyPrintDays);
      }
    }
    this.localStorageService.set(
      'mainDishOnlyPrintDays' +
        this.localStorageService.get('parentPrintRoute'),
      this.mainDishOnlyPrintDays
    );
    console.debug(
      '[selectedMainDishOnly] this.mainDishOnlyPrintDays',
      this.mainDishOnlyPrintDays
    );
  }

  isMainDishOnly(day: string) {
    if (day == 'Saturday') {
      day = 'Sunday';
    }
    if (day == 'Week') {
      return this.isAllDaysMainDishOnly(this.mainDishOnlyPrintDays);
    } else {
      if (this.mainDishOnlyPrintDays[day] == true) {
        return true;
      } else {
        return false;
      }
    }
  }

  isAllDaysMainDishOnly(mainDishOnlyPrintDays: MainDishOnlyPrintDays): boolean {
    console.log(
      '[isAllDaysMainDishOnly] mainDishOnlyPrintDays',
      mainDishOnlyPrintDays
    );
    for (let tag in mainDishOnlyPrintDays) {
      if (mainDishOnlyPrintDays[tag] == false) {
        console.log('[isAllDaysMainDishOnly] false');
        return false;
      }
    }
    console.log('[isAllDaysMainDishOnly] true');
    return true;
  }
}
