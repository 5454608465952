import { Component, Inject} from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthService } from "../service/auth.service";
import { RestDataSource } from "../repository/rest.datasource";
import { Http, Request, RequestMethod } from "@angular/http";
import { OnInit, OnChanges } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import {  ActivatedRoute,
      Event,
      NavigationCancel,
      NavigationEnd,
      NavigationError,
      NavigationStart, Router } from "@angular/router";
import {LocalStorageService} from "../service/localStorage.service";
import { environment } from './../../environments/environment';

@Component({

    templateUrl: "auth.component.html",
    styleUrls: ["auth.component.css"],

})
export class AuthComponent implements OnInit{


    public username: string;
    public password: string;
    public admUserName:string;
    public usernameAuthInvalid: boolean;
    public passwordAuthInvalid: boolean;
    public errorMessage: string;
    public showPassword: boolean;
    public isChecked: boolean;
    public isRememberMe: boolean;
    public isAuthenticated: boolean;
    public usernameS: string;
    public passwordS: string;
    public samlLoginUrlVoresmenu: string;


  constructor( private router: Router, private auth: AuthService,
    activeRoute: ActivatedRoute, private localStorageService: LocalStorageService) {


   }

   ngOnInit(): void {
    this.init();
    if(!environment.debugMode){
    console.clear();
    }
   }

    ngOnDestroy():void{

    }




    toShowPassword(){
        this.showPassword = !this.showPassword;

    }


    authenticate(form: NgForm) {

        if (form.valid) {

            this.auth.authenticate(this.username, this.password)
                .subscribe(response => {
                    if (response) {

                      this.passwordAuthInvalid = false;
                      this.usernameAuthInvalid = false;
                      let userId =  this.auth.getUserId();
                      let token_temp=this.auth.getAuthToken();
                      let token_time=this.auth.getTimeoutExpired();
                      this.auth.authenticatedSave (userId, token_temp,token_time);
                      this.auth.setTimer(token_time);
                      this.router.navigateByUrl("/minmenu");
                      if(this.isChecked){
                        this.localStorageService.set( 'isRememberMe', true);
                        }else{
                        this.localStorageService.set( 'isRememberMe', false);
                      }
                    }else{

                            this.usernameAuthInvalid = true;
                            this.passwordAuthInvalid = true;


                    }

                }, (error) => {console.log("error:The user name or password is incorrect.");
                            this.usernameAuthInvalid = true;
                            this.passwordAuthInvalid = true;})

        } else {

            this.errorMessage = "Form Data Invalid";
        }


    }


    toRememberMe(){
        this.isChecked = !this.isChecked;

    }

    logOutBtn(){

      console.log("[logOutBtn]");
      this.auth.logOut();
      this.init();
    }

    init(){

      console.log("[init]");
      var loader = document.getElementById('loader');
      loader.style.visibility = 'hidden';
      this.samlLoginUrlVoresmenu = environment.samlLoginUrlVoresmenu;
      this.usernameAuthInvalid = false;
      this.passwordAuthInvalid = false;
      this.showPassword = false;
      this.isAuthenticated = false;
      this.isRememberMe = false;
      this.isChecked = this.isRememberMe;
      this.localStorageService.set( 'isRememberMe', this.isRememberMe);
      this.auth.authenticationClear();
      this.localStorageService.clear();
//---------------------Departments -----------------------------------------------

      this.localStorageService.set('currentdepartmentId', null);
      this.localStorageService.set( 'frokostpakker'+ 'disabledAfterOrder', false);
      this.localStorageService.set( 'extra'+ 'disabledAfterOrder', false);
      this.localStorageService.set( 'currentChildRoute', "menu");
      this.localStorageService.set( 'toggledDay', "Tuesday");
  //--------------bulkorder/printweekplan/printtableveiw---------------
      this.localStorageService.set('shiftWeek'+ 'bulkorder/printweekplan/printtableveiw', 0);
      this.localStorageService.set( 'toggledPrintTableDayBulkOrder','Tuesday');

      }

}
