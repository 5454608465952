export class TopMessage {
  public title: string;
  public titleClass: string;
  public text: string;
  public textClass: string;

  constructor( title: string,
                     titleClass: string,
                     text: string,
                     textClass: string) {
    this.title = title;
    this.titleClass = titleClass;
    this.text = text;
    this.textClass = textClass;
  }

  static TopMessageFactory(title?: string,
                           titleClass?: string,
                           text?: string,
                           textClass?: string):TopMessage
  {
    let _title: string;
    let _titleClass: string = '';
    let _text: string;
    let _textClass: string = '';

    if (arguments.length == 0) {
      console.warn("No arguments for TopMessageFactory")
      return null;
    } else {
      if (title) {
        _title = title;
      }
      if (titleClass) {
        _titleClass = titleClass;
      }
      if (text) {
        _text = text;
      }
      if (textClass) {
        _textClass = textClass;
      }
    }
    return new this(_title, _titleClass, _text, _textClass);
  }

  getTitle(): string {
    return this.title;
  }
  setTitle(newTitle: string): void {
    this.title = newTitle;
  }
  getTitleClass(): string {
    return this.titleClass;
  }
  setTitleClass(newTitleClass: string): void {
    this.titleClass = newTitleClass;
  }
  getText(): string {
    return this.text;
  }
  setText(newText: string): void {
    this.text = newText;
  }
  getTextClass(): string {
    return this.textClass;
  }
  setTextClass(newTextClass: string): void {
    this.textClass = newTextClass;
  }
}
