import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, Observer, Subscription, interval } from 'rxjs';
import { DishRepository } from '../repository/dish.repository';
import { RestDataSource } from '../repository/rest.datasource';
import { AllocatedDishModel } from '../model/allocatedDishModel.model';
import { LocalStorageService } from '../service/localStorage.service';

@Injectable()
export class AdminResolver implements Resolve<any> {
  constructor(
    private dishRepository: DishRepository,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    console.log("AdminResolver -> route", route);
    var id = this.localStorageService.get('savedUserId');
    console.log('In AdminResolver id=' + id);
    return true;
  }
}
