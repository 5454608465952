import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Observer } from 'rxjs/Observer';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SafePipeModule } from 'safe-pipe';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { LocalStorageService } from './service/localStorage.service';
import { DataShareService } from './service/dataShare.service';
import { NavigationService } from './service/navigation.service';

import { AuthService } from './service/auth.service';
import { NewsService } from './service/news.service';
import { RestDataSource } from './repository/rest.datasource';
import { DateService } from './service/date.service';
import { LocaleDaDKService } from './service/locale-da-dk.service';
import { DishRepository } from './repository/dish.repository';
import { DishAllocationWeekDataBase } from './model/dishAllocationWeek.database';
import { ModelModule } from './cart/cart-model/model.module';
import { CartModule } from './cart/cart-detail/cart.module';
import { LoginGuard } from './guard/login.guard';
import { UnsavedGuard } from './guard/unsaved.guard';
import { ModelResolver } from './resolver/model.resolver';
import { AdminResolver } from './resolver/admin.resolver';
import { AuthComponent } from './admin/auth.component';
import { LoginRedirectComponent } from './admin/login-redirect/login-redirect.component';
import { AdminComponent } from './admin/admin.component';
import { MenuComponent } from './minmenu/menu.component';
import { BulkorderComponent } from './minmenu/bulkorder/bulkorder.component';
import { FrokostpakkerComponent } from './minmenu/frokostpakker/frokostpakker.component';
import { ExtraComponent } from './minmenu/extra/extra.component';
import { PrintComponent } from './minmenu/print/print.component';
import { NewsComponent } from './minmenu/news/news.component';
import { DialogComponent } from './minmenu/dialog/dialog.component';
import { ViewweekplanComponent } from './minmenu/bulkorder/viewweekplan.component';
import { EditweekplanComponent } from './minmenu/bulkorder/editweekplan.component';
import { PrintweekplanComponent } from './minmenu/bulkorder/printweekplan.component';
import { CartDetailComponent } from './cart/cart-detail/cartDetail.component';
import { PrintHeaderComponent } from './minmenu/print/printPages/printheader.component';
import { PrintTodaysmenuComponent } from './minmenu/print/printPages/printtodaysmenu.component';
import { PrintSelectBulkComponent } from './minmenu/print/printselectbulk.component';
import { PrintSelectWeekComponent } from './minmenu/print/printselectweek.component';

import { PrintChooseBulkComponent } from './minmenu/bulkorder/printPages/printchoosebulk.component';
import { PrintTableVeiwComponent } from './minmenu/bulkorder/printPages/printtableveiw.component';
import { PrintConfirmTable } from './minmenu/bulkorder/printPages/printconfirmtable.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { PrintCartComponent } from './minmenu/print/printPages/print-cart/print-cart.component';
import { TruncatePipe } from './pipe/truncate.pipe';
import { SpDietTextBoldPipe } from './pipe/sp-diet-text-bold.pipe';
import { TopMessageComponent } from './components/top-message/top-message.component';
import { MessageModalComponent } from 'src/modal/message-modal/message-modal.component';

const minmenuChildRoutes: Routes = [
  {
    path: 'bulkorder/printweekplan',
    component: PrintweekplanComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'bulkorder/editweekplan',
    component: EditweekplanComponent,
    resolve: { dishes: ModelResolver },
    runGuardsAndResolvers: 'always',
    canActivate: [LoginGuard],
    canDeactivate: [UnsavedGuard],
  },
  {
    path: 'bulkorder/viewweekplan',
    component: ViewweekplanComponent,
    resolve: { dishes: ModelResolver },
    runGuardsAndResolvers: 'always',
    canActivate: [LoginGuard],
    canDeactivate: [UnsavedGuard],
  },
  {
    path: 'bulkorder/printweekplan/printtableveiw',
    component: PrintTableVeiwComponent,
    resolve: { dishes: ModelResolver },
    runGuardsAndResolvers: 'always',
    canActivate: [LoginGuard],
  },
  {
    path: 'bulkorder/printweekplan/printconfirmtable',
    component: PrintConfirmTable,
    resolve: { dishes: ModelResolver },
    runGuardsAndResolvers: 'always',
    canActivate: [LoginGuard],
  },
  {
    path: 'bulkorder/printweekplan/printchoosebulk',
    component: PrintChooseBulkComponent,
    resolve: { dishes: ModelResolver },
    runGuardsAndResolvers: 'always',
    canActivate: [LoginGuard],
  },
  {
    path: 'menu',
    component: MenuComponent,
    resolve: { userName: AdminResolver },
    canActivate: [LoginGuard],
  },
  {
    path: 'bulkorder',
    component: BulkorderComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'frokostpakker',
    component: FrokostpakkerComponent,
    resolve: { dishes: ModelResolver },
    runGuardsAndResolvers: 'always',
    canActivate: [LoginGuard],
  },
  {
    path: 'extra',
    component: ExtraComponent,
    resolve: { dishes: ModelResolver },
    runGuardsAndResolvers: 'always',
    canActivate: [LoginGuard],
  },

  {
    path: 'print/printselectbulk',
    component: PrintSelectBulkComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'print/printselectweek',
    component: PrintSelectWeekComponent,
    canActivate: [LoginGuard],
  },
  {
    path: 'print/printweekplan',
    component: PrintHeaderComponent,
    resolve: { dishes: ModelResolver },
    canActivate: [LoginGuard],
  },
  {
    path: 'print/printtodaysmenu',
    component: PrintTodaysmenuComponent,
    resolve: { dishes: ModelResolver },
    runGuardsAndResolvers: 'always',
    canActivate: [LoginGuard],
  },
  {
    path: 'print/printcartcomponent',
    component: PrintCartComponent,
    resolve: { dishes: ModelResolver },
    runGuardsAndResolvers: 'always',
    canActivate: [LoginGuard],
  },
  { path: 'print', component: PrintComponent, canActivate: [LoginGuard] },

  { path: 'news', component: NewsComponent, resolve: { news: ModelResolver },
    runGuardsAndResolvers: 'always', canActivate: [LoginGuard] },
  { path: 'dialog', component: DialogComponent, canActivate: [LoginGuard] },
  { path: 'cart', component: CartDetailComponent },
  { path: '**', redirectTo: 'menu', canActivate: [LoginGuard] },
];

const appRoutes: Routes = [
  {
    path: "printtableveiw",
    component: PrintTableVeiwComponent,
    canActivate: [LoginGuard],
  },
  //  {path:'printconfirmtable', component: PrintConfirmTable, canActivate: [LoginGuard]},
  {
    path: "printchoosebulk",
    component: PrintChooseBulkComponent,
    canActivate: [LoginGuard],
  },
  {
    path: "minmenu",
    component: AdminComponent,
    children: minmenuChildRoutes,
    canActivate: [LoginGuard],
  },
  { path: "auth", component: AuthComponent },
  {
    path: "account/login/:loginInfo",
    component: LoginRedirectComponent,
    canActivate: [LoginGuard],
  },
  //{ path: "**", redirectTo: "/auth" },
  //{ path: "#", component: AuthComponent, canActivate: [LoginGuard] },
  { path: "**", component: AuthComponent, canActivate: [LoginGuard] },

];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, { onSameUrlNavigation: "reload" }),
    ModelModule,
    CartModule,
    NgbModule,
    SafePipeModule
  ],

  declarations: [
    AppComponent,
    AuthComponent,
    LoginRedirectComponent,
    AdminComponent,
    MenuComponent,
    BulkorderComponent,
    FrokostpakkerComponent,
    ExtraComponent,
    PrintComponent,
    NewsComponent,
    DialogComponent,
    ViewweekplanComponent,
    EditweekplanComponent,
    PrintweekplanComponent,
    PrintHeaderComponent,
    PrintTodaysmenuComponent,
    PrintSelectBulkComponent,
    PrintSelectWeekComponent,
    PrintChooseBulkComponent,
    PrintTableVeiwComponent,
    PrintConfirmTable,
    PrintCartComponent,
    TruncatePipe,
    SpDietTextBoldPipe,
    TopMessageComponent,
    MessageModalComponent
  ],

  providers: [
    AuthService,
    { provide: "datasource", useClass: RestDataSource },
    LocalStorageService,
    LoginGuard,
    UnsavedGuard,
    RestDataSource,
    DatePipe,
    DateService,
    LocaleDaDKService,
    DishRepository,
    DishAllocationWeekDataBase,
    ModelResolver,
    AdminResolver,
    DataShareService,
    NavigationService,
    NewsService
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
