import { Injectable, Inject } from '@angular/core';
import { NewsItem } from '../model/newsItemModel.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs';
import { LocalStorageService } from './localStorage.service';
import { RestDataSource } from '../repository/rest.datasource';

@Injectable()
export class NewsService {
  constructor(private http: HttpClient,
              private localStorageService: LocalStorageService,
              private restDataSource: RestDataSource) { }

  getNews(): Observable<NewsItem[]> {

    return this.restDataSource.getNews() as Observable<NewsItem[]>;
  }
}
