import { Injectable } from '@angular/core';
import { OnInit, DoCheck, OnDestroy } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as d3 from 'd3';
import * as moment from 'moment';

@Injectable()
export class DateService {
  public locale: string;
  public date: Date;
  public dateTemp: Date;
  public now: number;
  public pipeLocale: DatePipe;
  public week: string[];
  public daysInMonth: number[];
  public momentDate: any;
  public momentDateTemp: any;

  constructor(private pipe: DatePipe) {
    //  this.locale = "uk-UA";
    //moment.locale('da');
    this.momentDate = moment();
    this.momentDateTemp = this.momentDate.clone();

    this.locale = 'da_DK';
    this.date = new Date();
    this.dateTemp = new Date();
    this.pipeLocale = new DatePipe(this.locale);
    this.now = Date.now();
    this.daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 30];
    this.week = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];

  }

  getRightNow(){
    //let dkRightNow = ;
    return moment().locale('da-DK').format('llll')
  }

  getCurrentDate() {
    return moment().date();
  }

  getCurrentDayNumber(): number {
    return moment().isoWeekday();
  }

  getCurrentDayString(): string {
    // return this.pipe.transform(this.now, 'EEEE');
    return moment().format('dddd');
  }

  getCurrentDayMonth(): string {
    // return this.pipe.transform(this.now, 'EEEE');
    return moment().format('dddd D MMM.');
  }

  getCurrentDayMonthShifted(shiftWeek: number): string {
    // return this.pipe.transform(this.now, 'EEEE');
    return moment().add(shiftWeek, 'w').format('dddd D MMM.');
  }

  getCurrentDayMonthShiftedDanish(shiftWeek: number): string {
    let da_dk = moment().locale('da-DK');
    return da_dk.add(shiftWeek, 'w').format('dddd D MMM.');
  }

  getISODateDDMMMforCurrentDay(shiftWeek: number): string {
    return moment().add(shiftWeek, 'w').format('YYYY-MM-DD');
  }
  getCurrentWeek(): string {
    // return this.pipe.transform(this.now, 'w');
    return moment().format('W');
  }
  /*  getCurrentMonth():string{


    return moment().format('M');
  }
*/
  getCurrentWeekNumber(): number {
    //return this.pipe.transform(this.now, 'w');
    return moment().isoWeek();
  }

  getCurrentMonth(): number {
    //return this.date.getMonth();
    return moment().month();
  }
  getCurrentFullYear(): number {
    //return this.date.getFullYear();
    return moment().year();
  }
  getCurrentTime(): number {
    //return this.date.getTime();
    return moment().unix();
  }

  getCurrentTimeHHmmss(): string {
    // return this.pipe.transform(this.now, 'HH:mm:ss');
    return moment().format('HH:mm:ss');
  }

  getDateFromTimeHHmmss(TimeHHmmss: string) {
    var parseTime = d3.timeParse('%H:%M:%S');
    console.log('In getDateFromTimeHHmmss' + parseTime(TimeHHmmss));
    return parseTime(TimeHHmmss);
  }

  returnCurrentDateForTimeHHmmss(timeHHmmss1: Date) {
    var currentFullYear = this.getCurrentFullYear();
    var currentMonth = this.getCurrentMonth();
    var currentDate = this.getCurrentDate();
    var currentDateForTimeHHmmss = timeHHmmss1.setFullYear(
      currentFullYear,
      currentMonth,
      currentDate
    );

    this.setCurrentDateForDateTemp();

    return currentDateForTimeHHmmss;
  }

  isCurrentTimeBetween(startTime: string, endTime: string): boolean {
    var format = 'hh:mm:ss';
    //var time = moment(format);
    var beforeTime = moment(startTime, format);
    var afterTime = moment(endTime, format);
    return moment().isBetween(beforeTime, afterTime);
  }

  isEndTimeAfterStartTime(startTime: string, endTime: string): boolean {
    console.log('[filterTwoDishesForEachDay] startTime', startTime);
    console.log('[filterTwoDishesForEachDay] startTime', endTime);
    console.log(
      '[filterTwoDishesForEachDay] isAfter',
      moment(endTime).isSame(startTime)
    );

    return moment(endTime).isAfter(startTime);
  }

  isEndTimeIsSameStartTime(startTime: string, endTime: string): boolean {
    console.log('[filterTwoDishesForEachDay] startTime', startTime);
    console.log('[filterTwoDishesForEachDay] startTime', endTime);
    console.log(
      '[filterTwoDishesForEachDay] isSame',
      moment(endTime).isSame(startTime)
    );
    return moment(endTime).isSame(startTime);
  }
  getCurrentISODate(): string {
    // return this.pipe.transform(this.now, 'yyyy-MM-dd');
    return moment().format('YYYY-MM-DD');
  }

  getISODateDDMMMforDay(nameDay: string, shiftWeek: number): string {
    return moment()
      .add(shiftWeek, 'w')
      .isoWeekday(nameDay)
      .locale('da-DK')
      .format('DD.MMM');

  }

  getISODateDDMMMforDayMonth(
    nameDay: string,
    shiftWeek: number,
    shiftMonth: number
  ): string {
    console.log('[getISODateDDMMMforDayMonth] shiftWeek', shiftWeek);
    console.log('[getISODateDDMMMforDayMonth] shiftMonth', shiftMonth);
    let currentMonth = Number(moment().month());
    console.log('[getISODateDDMMMforDayMonth] currentMonth', currentMonth);

    let shiftedMonth = currentMonth + Number(shiftMonth);
    console.log('[getISODateDDMMMforDayMonth] shiftedMonth', shiftedMonth);
    let momentDate = moment();
    let momentDateTemp = momentDate.clone();

    momentDateTemp.set('month', shiftedMonth);
    console.log(
      '[getISODateDDMMMforDayMonth] momentDateTemp.month()',
      momentDateTemp.month()
    );
    momentDateTemp.set('date', 1);
    console.log(
      '[getISODateDDMMMforDayMonth] momentDateTemp.month()',
      momentDateTemp.date()
    );

    let curDay = momentDateTemp
      .add(shiftWeek, 'w')
      .isoWeekday(nameDay)
      .format('DD.MMM');
    console.log('[getISODateDDMMMforDayMonth] curWeek', curDay);

    return curDay;
  }

  getISODateYYYYMMDDforDayMonth(
    nameDay: string,
    weekNumber: number,
    yearYYYY: string
  ): string {
    console.log('[getISODateDDMMMforDayMonth] weekNumber', weekNumber);

    let tempYear = Number(yearYYYY);
    let momentDate = moment();
    let momentDateTemp = momentDate.clone();

    momentDateTemp.set('year', tempYear);
    console.log(
      '[getBulkDishWeeksShiftWeek] momentDateTemp.year()',
      momentDateTemp.year()
    );
    momentDateTemp.isoWeek(weekNumber);
    console.log(
      '[getBulkDishWeeksShiftWeek] momentDateTemp.isoWeek(shiftWeek)',
      momentDateTemp.isoWeek()
    );

    let curDay = momentDateTemp.isoWeekday(nameDay).format('YYYY-MM-DD');
    console.log('[getISODateDDMMMforDayMonth] curWeek', curDay);

    return curDay;
  }

  getYearYYYYforShiftMonth(shiftMonth: number): string {
    console.log('[getISODateDDMMMforDayMonth] shiftMonth', shiftMonth);
    let currentMonth = Number(moment().month());
    console.log('[getISODateDDMMMforDayMonth] currentMonth', currentMonth);

    let shiftedMonth = currentMonth + Number(shiftMonth);
    console.log('[getISODateDDMMMforDayMonth] shiftedMonth', shiftedMonth);
    let momentDate = moment();
    let momentDateTemp = momentDate.clone();

    momentDateTemp.set('month', shiftedMonth);
    console.log(
      '[getISODateDDMMMforDayMonth] momentDateTemp.month()',
      momentDateTemp.month()
    );

    let curYear = momentDateTemp.format('YYYY');
    console.log('[getISODateDDMMMforDayMonth] curYear', curYear);

    return curYear;
  }

  getISODateYYYMMDDforDay(nameDay: string, shiftWeek: number): string {
    return moment().add(shiftWeek, 'w').isoWeekday(nameDay).format('YYYY-MM-DD');
  }

  getISODate(setDate: Date) {
    return this.pipe.transform(setDate, 'yyyy-MM-dd');
  }


  getNameDayFromISODateYYYYMMDD(DateYYYYMMDD: string) {
    return moment(DateYYYYMMDD, 'YYYY-MM-DD').format('dddd');
  }

  getISODateYYYYMMDDFromDDMMM(dateDDMMM: string): string {
    return moment(dateDDMMM, 'DD-MM').format('YYYY-MM-DD');
  }

  getShiftWeek(shift: number): string {
    return moment().add(shift, 'w').format('W');
  }

  getShiftWeekForMonth(shiftWeek: number, shiftMonth: number): string {
    console.log('[getShiftWeekForMonth] shiftWeek', shiftWeek);
    console.log('[getShiftWeekForMonth] shiftMonth', shiftMonth);
    let currentMonth = Number(moment().month());
    console.log('[getShiftWeekForMonth] currentMonth', currentMonth);

    let shiftedMonth = currentMonth + Number(shiftMonth);
    console.log('[getShiftWeekForMonth] shiftedMonth', shiftedMonth);
    let momentDate = moment();
    let momentDateTemp = momentDate.clone();

    momentDateTemp.set('month', shiftedMonth);
    console.log(
      '[getShiftWeekForMonth] momentDateTemp.month()',
      momentDateTemp.month()
    );
    momentDateTemp.set('date', 1);
    console.log(
      '[getShiftWeekForMonth] momentDateTemp.month()',
      momentDateTemp.date()
    );

    let curWeek = momentDateTemp.add(shiftWeek, 'w').format('W');
    console.log('[getShiftWeekForMonth] curWeek', curWeek);
    return curWeek;
  }

  getMonthForISODateDDMMM(DateDDMMM: string): string {
    console.log('[getMonthForISODateDDMMM] DateYYYYMMDD', DateDDMMM);

    let momentDate = moment();
    // let momentDateTemp = momentDate.clone();
    let momentDateTemp = moment(DateDDMMM, 'DD-MMM');
    //momentDateTemp = momentDateTemp(DateDDMMM,'DD-MMM');
    console.log('[getMonthForISODateDDMMM] momentDateTemp', momentDateTemp);

    console.log(
      '[getMonthForISODateDDMMM] momentDateTemp.format(MMMM)',
      momentDateTemp.format('MMMM')
    );
    return momentDateTemp.format('MMMM');
  }

  getWeekForISODateYYYYMMDD(DateYYYYMMDD: string): string {
    console.log('[getWeekForISODateYYYYMMDD] DateYYYYMMDD', DateYYYYMMDD);

    let momentDate = moment();
    // let momentDateTemp = momentDate.clone();
    let momentDateTemp = moment(DateYYYYMMDD, 'YYYY-MM-DD');
    //momentDateTemp = momentDateTemp(DateDDMMM,'DD-MMM');
    console.log('[getWeekForISODateYYYYMMDD] momentDateTemp', momentDateTemp);

    console.log(
      '[getWeekForISODateYYYYMMDD] momentDateTemp.format(w)',
      momentDateTemp.format('MMMM')
    );
    return momentDateTemp.format('W');
  }

  getMonthForISODateYYYYMMDD(DateYYYYMMDD: string): string {
    console.log('[getMonthForISODateYYYYMMDD] DateYYYYMMDD', DateYYYYMMDD);

    let momentDate = moment();
    // let momentDateTemp = momentDate.clone();
    let momentDateTemp = moment(DateYYYYMMDD, 'YYYY-MM-DD');
    //momentDateTemp = momentDateTemp(DateDDMMM,'DD-MMM');
    console.log('[getMonthForISODateYYYYMMDD] momentDateTemp', momentDateTemp);

    console.log(
      '[getMonthForISODateYYYYMMDD] momentDateTemp.format(MMMM)',
      momentDateTemp.format('MMMM')
    );
    return momentDateTemp.format('MMMM');
  }

  getShiftMonth(shift: number): string {
    console.log('[getShiftMonth] shift', shift);
    // console.log("[getShiftMonth] shift", moment().add(0, 'M').format('M'));
    return moment().add(shift, 'M').format('M');
  }

  getShiftMonthName(shift: number): string {
    return moment().add(shift, 'M').format('MMMM');
  }

  getIndexForDayInWeek(dayName: string) {
    return (this.week.indexOf(dayName)+1);
  }

  getDaysLeftInWeek(): number {
    return 6 - this.getCurrentDayNumber();
  }

  getDaysLeftInWeekArray(currentDay: number): string[] {
    let DaysLeftInWeekArray = this.week;
    return DaysLeftInWeekArray.slice(currentDay, DaysLeftInWeekArray.length);
  }

  getOrderDaysLeftInWeekArray(currentDay: number): string[] {
    let DaysLeftInWeekArray = this.week;
    return DaysLeftInWeekArray.slice(
      currentDay,
      DaysLeftInWeekArray.length
    ).filter(function (x) {
      return x == 'Monday' || x == 'Wednesday' || x == 'Friday';
    });
  }

  getDateTemp(setDate: number): Date {

    return moment().date(setDate).toDate();
  }

  setCurrentDateForDateTemp(): Date {

    return moment().toDate();
  }

  getShiftDate(currentDate: number, currentMonth: number, inc: number) {
    var currentFullYear = this.dateTemp.getFullYear();
    var incForLeapYear = 0;
    var nextMonth: number;
    var nextDate: number;
    if (this.isLeapYear()) {
      var incForLeapYear = 1;
    }

    var daysInMonthTemp = this.daysInMonth[currentMonth] + incForLeapYear;
    if (currentMonth == 1) {
      daysInMonthTemp = daysInMonthTemp + incForLeapYear;
    }

    if (inc >= 0) {
      nextDate = currentDate + inc;

      if (nextDate > daysInMonthTemp) {
        nextMonth = currentMonth + 1;
        if (nextMonth == 12) {
          // if Desember + 1
          nextMonth = 0; //set January
          currentFullYear = currentFullYear + 1;
        }
        nextDate = nextDate - daysInMonthTemp;

        this.dateTemp.setFullYear(currentFullYear, nextMonth, nextDate);
      }
    } else {
      nextDate = currentDate + inc;

      if (nextDate < 0) {
        nextMonth = currentMonth - 1;
        if (currentMonth == 0) {
          nextMonth = 12;
          currentFullYear = currentFullYear - 1;
        }
        daysInMonthTemp = this.daysInMonth[nextMonth];
        if (nextMonth == 1) {
          daysInMonthTemp = daysInMonthTemp + incForLeapYear;
        }

        nextDate = daysInMonthTemp + nextDate;
        this.dateTemp.setFullYear(currentFullYear, nextMonth, nextDate);
      }
    }

    var nextDateTemp = this.getDateTemp(nextDate);
    var ISODateTemp = this.getISODate(nextDateTemp);
    return ISODateTemp;
  }

  isLeapYear() {
    if (this.date.getFullYear() % 4 == 0) {
      return true;
    } else return false;
  }

  getISODateDDMMMforDayTest(nameDay: string, shiftWeek: number): string {
    return moment().add(shiftWeek, 'w').isoWeekday(nameDay).format('YYYY-MM-DD');
  }
}
