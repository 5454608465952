import { Injectable } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { LocalStorageService } from '../../service/localStorage.service';

@Component({
  selector: "bulkorder-root",
  templateUrl: 'bulkorder.component.html',
  styleUrls: ['bulkorder.component.css'],
})
export class BulkorderComponent {
  admUserName: string;

  constructor(private localStorageService: LocalStorageService) {
    this.localStorageService.set('currentChildRoute', 'bulkorder');
  }
}
