import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import { AuthService } from "../service/auth.service";
import { Observable } from "rxjs/Observable";
import { LocalStorageService } from "../service/localStorage.service";

 @Injectable()
 export class LoginGuard implements CanActivate {
   public currentLocationPath: string;
   constructor(
     private router: Router,
     private auth: AuthService,
     private location: Location,
     private localStorageService: LocalStorageService
   ) {
     this.currentLocationPath = this.getPath();
     // console.log("LoginGuard -> this.currentLocationPath", this.currentLocationPath)
   }

   canActivate(
     route: ActivatedRouteSnapshot,
     state: RouterStateSnapshot
   ): boolean {
     this.currentLocationPath = this.getPath();
    // console.log( "LoginGuard -> this.currentLocationPath",this.currentLocationPath);
     const path1 = decodeURIComponent(this.currentLocationPath);
     //console.log("LoginGuard -> path", path1);
     const fragment = route.fragment;
     if (((fragment &&
       fragment.match("/account/login/"))
       || path1.match("/account/login/"))
       &&
       !this.localStorageService.get("isAuthByAccount") == true
     ) {
       let substr = fragment.substr("/account/login/".length);
      // console.log("LoginGuard -> substr", substr);

       //let param = substr.replace(/\//g, "");
       let param = substr;
       //param = param.slice(1, param.length - 1);
     //  console.log("LoginGuard -> param", param);
       this.localStorageService.set("isAuthByAccount", true);
       this.router.navigateByUrl("/account/login/" + param);
       return true;
     } else if (path1.match("/auth")) {
        return true;
     } else if (!this.auth.isAuthenticated() && !this.localStorageService.get("isAuthByAccount") == true)
     {
        console.log("LoginGuard: You are not authenticated ");
        this.router.navigateByUrl("/auth");
        return false;
     }

     return true;
   }

   getPath() {
     return this.location.path();
   }
 }
