import { Injectable } from "@angular/core";
import { Product } from "../../model/product.model";
import { AllocatedDishModel } from "../../model/allocatedDishModel.model";
import {LocalStorageService} from "../../service/localStorage.service";
import {DateService} from "../../service/date.service";
import { _ } from 'underscore';
import { OrderModel } from "../../model/orderModel.model";
import { MenuLine } from "../../model/menuLine.model";
import { BulkOrderMenuLineEditModel } from "../../model/bulkOrderMenuLineEditModel.model";
import { OrderDishModel } from "../../model/orderDishModel.model";
import { PortionSize } from "../../model/portionSize.model";
import { SimpleOderModel } from '../../model/simpleOder.model'

class OrderMenuLineDay{
  menuLine:MenuLine;
  menuLineDay:string;
  orderNr:number;
  customerNr:number;
  menuNr:number;
  orderNotPermit:boolean;

  constructor(menuLine:MenuLine,  menuLineDay:string,
    orderNr:number, customerNr:number, menuNr:number,
    orderNotPermit:boolean){
    this.menuLine = menuLine;
    this.menuLineDay = menuLineDay;
    this.orderNr = orderNr;
    this.customerNr = customerNr;
    this.menuNr = menuNr;
    this.orderNotPermit = orderNotPermit;



  }
}
class BulkOrderMenuLineEditModelDays{

  menuLines:OrderMenuLineDay[];

  constructor(menuLines:OrderMenuLineDay[]){

    this.menuLines = menuLines;

  }
}


@Injectable()
export class Cart {
   public lines:any[]=[];
    public itemCount: number = 0;
    public cartPrice: number = 0;
    public  simpleOderModelArray: SimpleOderModel[];
    public simpleOderModelArrayId:string;
    public currentWeek:string;
    public shiftWeekNumber:string;
    public shiftWeek: number;
    private week:string[];
    private categories:string[];
    private subcategories:string[];
    public cartOrderNotPermit: boolean=true;
    public sumOrderDisabled:number=0;
    private departmentId: number;
    private currentToogledDay:string;
    public isQuantityChanged:boolean;
    constructor(private localStorageService: LocalStorageService, private dateService:DateService){

      this.week = [];
      this.categories = [];
      this.subcategories = [];
      this.simpleOderModelArray = [];
      this.shiftWeek = this.localStorageService.get('shiftWeek'+ this.localStorageService.get( 'parentCartRoute'));
      this.dateService.getCurrentDayNumber();
      this.currentWeek = this.dateService.getCurrentWeek();
      this.shiftWeekNumber = this.dateService.getShiftWeek(this.shiftWeek);
      this.departmentId = this.localStorageService.get('currentdepartmentId');
      this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'parentCartRoute') + this.shiftWeekNumber + this.departmentId;
      console.debug("[Cart] this.simpleOderModelArrayId", this.simpleOderModelArrayId);
      this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
      console.debug("[Cart] InCart INIT="+JSON.stringify(this.simpleOderModelArray));
      this.isQuantityChanged = false;

     }

  getServingDayFromToogledDay(currentChildRoute:string){
      let deliveryDay:string;
       if(currentChildRoute=="extra"){
            deliveryDay = this.localStorageService.get('toggledDayExtra');

          }else if(currentChildRoute=="frokostpakker"){

            deliveryDay = this.localStorageService.get('toggledDayFroko');

          }

      console.log("[Cart] [getServingDayFromToogledDay] deliveryDay", deliveryDay);
      switch(deliveryDay){
      case 'Tuesday':
        return "Tuesday";
      break;
      case 'Thursday':
        return "Thursday";

      break;
      case 'Saturday':
        return "Sunday";

      break;
      default:
      return "Tuesday";

    }

     }

     sub(dish:SimpleOderModel){
      console.log("InCart  SUB dish="+JSON.stringify(dish));
       let line = this.simpleOderModelArray.find(line => line["orderDishId"] == dish["orderDishId"]);
        console.log("InCart  SUB line="+JSON.stringify(line));
        if (line != undefined) {

            if( line.orderNumber!=0){
               line.orderNumber-=1;
              if( line.orderNumber<=0){
                 line.orderNumber = 0;

              }

          }

            line.sumOder = line.orderNumber;
            console.log("InCart  SUB line.quantity="+JSON.stringify(line.orderNumber));
            this.updateLocalStorageFromLines(dish,  line.orderNumber)
        }

        this.recalculate();
        this.isQuantityChanged = true;
    }

  add(dish: SimpleOderModel){

     console.log("InCart  ADD dish="+JSON.stringify(dish));

        let line = this.simpleOderModelArray.find(line => line["orderDishId"] == dish["orderDishId"]);
         console.log("InCart  ADD line="+JSON.stringify(line));
        if (line != undefined) {
          line.orderNumber+=1;
          line.sumOder+=1;
          //  line.quantity += 1;
             console.log("InCart  ADD line.quantity="+JSON.stringify(line.sumOder));
            this.updateLocalStorageFromLines(dish,  line.orderNumber)
        }

        this.recalculate();
        this.isQuantityChanged = true;
    }




  updateLinesFromLocalStorage(shiftWeekNumber:string){
    this.lines = [];
    this.shiftWeekNumber = shiftWeekNumber;
    this.departmentId = this.localStorageService.get('currentdepartmentId');
    this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'parentCartRoute') + this.shiftWeekNumber + this.departmentId;
    this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
    this.recalculate();

    }

  updateLinesFromLocalStorageForDeliveryDay(shiftWeekNumber:string){
    this.lines = [];
    this.shiftWeekNumber = shiftWeekNumber;
    this.departmentId = this.localStorageService.get('currentdepartmentId');
    this.simpleOderModelArrayId = 'simpleOderModelArray' + this.localStorageService.get( 'parentCartRoute') + this.shiftWeekNumber + this.departmentId;
    this.simpleOderModelArray = this.localStorageService.get(this.simpleOderModelArrayId);
    let filteredDay = this.getServingDayFromToogledDay(this.localStorageService.get( 'parentCartRoute'))
    console.log("[Cart] [updateLinesFromLocalStorageForDeliveryDay] filteredDay", filteredDay);
    this.simpleOderModelArray = this.filterByDay(JSON.parse(JSON.stringify(this.simpleOderModelArray)), filteredDay);
    this.recalculate();

    }

  updateQuantity(dish: SimpleOderModel, quantity: number) {
         console.log("InCart  updateQuantity="+quantity);

        let line = this.simpleOderModelArray.find(line => line["orderDishId"] == dish["orderDishId"]);

        if (line != undefined) {
            line.sumOder = Number(quantity);

            this.updateLocalStorageFromLines(dish,  line.sumOder)
        }



        this.recalculate();
        this.isQuantityChanged = true;
    }

  updateLocalStorageFromLines(dish: SimpleOderModel, quantity: number){
         console.log("InCart  updateLocalStorageFromLines quantity="+quantity);

        this.localStorageService.set(this.simpleOderModelArrayId,  this.simpleOderModelArray);


         console.log("InCart  updateLocalStorageFromLines arr="+JSON.stringify(this.simpleOderModelArray));

    }


     deleteDishesWithZeroSumsFromLocalStorage(){
      console.log("In deleteDishesWithZeroSumsFromLocalStorage");
       if(this.simpleOderModelArray!=null){

        this.simpleOderModelArray.forEach(el=>{
        if(el["sumOder"]==0){
          var index =  this.simpleOderModelArray.indexOf(el);
          this.simpleOderModelArray.splice(index, 1);
        }});

        this.localStorageService.set(this.simpleOderModelArrayId,  this.simpleOderModelArray);

       }


    }

  subLine(dish: SimpleOderModel, quantity: number) {

        let line = this.lines.find(line => line.dish["dishId"] == dish["dishId"]);

        if (line != undefined) {

            line.quantity -= quantity;
            if(line.quantity <= 0){
              line.quantity = 0;
               this.removeLine(line.dish["dishId"]);
            }
        }
        this.recalculate();
    }

  removeLine(id: number) {
        let index = this.lines.findIndex(line => line["orderDishId"] == id);
        this.lines.splice(index);

        this.recalculate();
    }

    clear() {
        this.simpleOderModelArray = [];
        this.localStorageService.set(this.simpleOderModelArrayId,  null);
    }




  recalculate() {
         this.itemCount = 0;
         this.cartPrice = 0;
        var sumOder = 0;

        for(var oder in this.simpleOderModelArray){


                sumOder+=this.simpleOderModelArray[oder].sumOder;


        }

         this.cartPrice = sumOder
         console.log("in recalculate() this.cartPrice :" +this.cartPrice );

    }


    getDaysArrForView(simpleOderModelArray: SimpleOderModel[]){

      function daySort(dayA:string, dayB:string){
      var week = ["Sunday","Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      return (week.indexOf(dayA) - week.indexOf(dayB));
        }
      this.week = _.pluck(simpleOderModelArray, "Day");
      this.week=_.uniq(this.week);
      this.week = _.sortBy(this.week, daySort);
      return this.week;
    }

    filterByDay(simpleOderModelArray: SimpleOderModel[],
     day:string){
      if(simpleOderModelArray!=null
        && simpleOderModelArray!==undefined
        && simpleOderModelArray.length!=0){
         return simpleOderModelArray
      .filter(el => el["Day"] == day);
      }else{
        return simpleOderModelArray;
      }


    }


    getCategoryArrForView(simpleOderModelArray: SimpleOderModel[], day:string){

      simpleOderModelArray = simpleOderModelArray.filter(el => el["Day"] == day);
      this.categories = _.pluck(simpleOderModelArray, "category");
      this.categories=_.uniq(this.categories);
      this.categories =  this.categories.sort();
      return this.categories;
    }

    filterByCategory(simpleOderModelArray: SimpleOderModel[],
     category:string){
     return simpleOderModelArray
      .filter(el => el["category"] == category);

    }

    getSubcategoryArrForView(simpleOderModelArray: SimpleOderModel[], day:string, category:string){

      simpleOderModelArray.sort();
      simpleOderModelArray = simpleOderModelArray
      .filter(el => el["Day"] == day)
      .filter(el => el["category"] == category);
      this.subcategories = _.pluck(simpleOderModelArray, "subcategory");
      this.subcategories=_.uniq(this.subcategories);
       this.subcategories =  this.subcategories.sort();
      return this.subcategories;
    }

    filterBySubcategory(simpleOderModelArray: SimpleOderModel[],
     subcategory:string){
     return simpleOderModelArray
      .filter(el => el["subcategory"] == subcategory);

    }



    filterByDayCategorySubcategory(simpleOderModelArray: SimpleOderModel[],
     day:string, category:string, subcategory:string){
      return simpleOderModelArray
      .filter(el => el["Day"] == day)
      .filter(el => el["category"] == category)
      .filter(el => el["subcategory"] == subcategory);

    }

    formOrderDishModelArr(){
      var enablePortions = false;
      var portionSize = new PortionSize(1);
      let orderDishModelArr = new Array();
      for(var i = 0; i<this.simpleOderModelArray.length; i++){

        let orderDishModel= new OrderDishModel(
          this.simpleOderModelArray[i]["dishId"],
          this.simpleOderModelArray[i]["orderDishId"],
          this.simpleOderModelArray[i]["sumOder"],
          enablePortions,
          portionSize,
          this.simpleOderModelArray[i]["dishName"]);
          orderDishModelArr.push(orderDishModel);

      }
      console.log("OrderDishModelArr = :" +JSON.stringify(orderDishModelArr) );
      return orderDishModelArr;
    }

    formMenuLineModelArr(){
      let orderDishModelArr = new Array();

      for(var i = 0; i<this.simpleOderModelArray.length; i++){
         let orderMenuLineDay = new OrderMenuLineDay(new MenuLine(
          this.simpleOderModelArray[i]["dishId"],
          this.simpleOderModelArray[i]["name"],
          this.simpleOderModelArray[i]["line"],
          this.simpleOderModelArray[i]["dietTypeNr"],
          this.simpleOderModelArray[i]["dietTypeName"],
          this.simpleOderModelArray[i]["portionSize"],
          this.simpleOderModelArray[i]["sumOder"],
          this.simpleOderModelArray[i]["enkeltPortion"]),
          this.simpleOderModelArray[i]["Day"],
          this.simpleOderModelArray[i]["orderNr"],
          this.simpleOderModelArray[i]["customerNr"],
          this.simpleOderModelArray[i]["menuNr"],
          this.simpleOderModelArray[i]["orderNotPermit"],



      );

        console.log("orderMenuLineDay", orderMenuLineDay);
         orderDishModelArr.push(orderMenuLineDay);

      }
      console.log("OrderDishModelArr1" +JSON.stringify(orderDishModelArr) );
      return orderDishModelArr;
    }

    formOrderModel(){

      var statusId = 1;
      var menuCategoryId = 45;
      var menuCategorySortId = 1;
      var parentMenuCategoryId = 1;
      var parentMenuCategoryName = "";
      var user = "gtestpleje";
      var orderDishModelArr = this.formMenuLineModelArr();
      let orderModel = new OrderModel(
      this.orderIdGenerator(),
      statusId,
      new Date(),
      menuCategoryId,
      this.localStorageService.get( 'parentCartRoute'),
      menuCategorySortId,
      parentMenuCategoryId,
      parentMenuCategoryName,
      user,
      this.localStorageService.get( 'savedUserId'),
      orderDishModelArr
    )
      console.log("OrderDishModelArr1" +JSON.stringify(orderModel) );
      return orderModel;


    }

  formBulkOrderModel(){

    console.log("InformBulkOrderModel");
    let orderDishModelArr = this.formMenuLineModelArr();
    console.log("InformBulkOrderModel2");
    let orderModel = new BulkOrderMenuLineEditModelDays(orderDishModelArr);
    console.log("OrderDishModelArr2" +JSON.stringify(orderModel) );
    return orderModel;
    }

  orderIdGenerator():number{
      return this.simpleOderModelArray[0]["orderDishId"]*10;
    }

  getPermittionsForDay(simpleOderModelArray:SimpleOderModel[], day:string, fromPage:string){

    console.debug("[getPermittionsForDay]");
      let cartOrderDayNotPermit:boolean = false;
      if(fromPage=='frokostpakker'){

        for(let order in simpleOderModelArray){

          if(simpleOderModelArray[order]['Day']==day){

            cartOrderDayNotPermit = simpleOderModelArray[order]['Day']['orderNotPermit'];
            break;
          }

        }

       }else if(fromPage=='extra'){
          let subcategories = [];
          let subcategoriesPermitArr = [];
          let found:boolean;
          subcategories = this.getSubcategoryArrForView(simpleOderModelArray, day, "Extra")
          console.debug("[getPermittionsForDay] subcategories", subcategories);
         for(let subcategory in subcategories){
          for(let order in this.simpleOderModelArray){
            found = false;
            if(this.simpleOderModelArray[order]['Day']==day){



                 if(this.simpleOderModelArray[order]['subcategory']==subcategories[subcategory]){

            console.debug("[getPermittionsForDay] subcategories[subcategory]", subcategories[subcategory]);

            console.debug("[getPermittionsForDay] orderNotPermit", this.simpleOderModelArray[order]['orderNotPermit']);

                  subcategoriesPermitArr.push(this.simpleOderModelArray[order]['orderNotPermit']);
                  found = true;
                }

             }

             if(found){
              break;
            }
          }

        }

        console.debug("[getPermittionsForDay] subcategoriesPermitArr", subcategoriesPermitArr);
        cartOrderDayNotPermit =subcategoriesPermitArr.every(subcatPermittions => subcatPermittions == true)
        console.debug("[getPermittionsForDay] cartOrderDayNotPermit", cartOrderDayNotPermit);

          }
        console.debug("[getPermittionsForDay] cartOrderDayNotPermitR", cartOrderDayNotPermit);
        return  cartOrderDayNotPermit;

    }

  getPermittionsForSubcategory(simpleOderModelArray:SimpleOderModel[], day:string, fromPage:string, subcategory:string){
      this.sumOrderDisabled = 0;
      let cartOrderDayNotPermit:boolean = false;
      if(fromPage=='extra'){
          let found:boolean;
          for(let order in this.simpleOderModelArray){
            found = false;
            if((this.simpleOderModelArray[order]['Day']==day)
              && (this.simpleOderModelArray[order]['subcategory']==subcategory)){

                  cartOrderDayNotPermit = this.simpleOderModelArray[order]['orderNotPermit'];
                  //found = true;
                  if(cartOrderDayNotPermit){
                    //this.simpleOderModelArray[order]['sumOder']=0;
                    this.sumOrderDisabled+=this.simpleOderModelArray[order]['sumOder'];
                  }
             }

          }

          }
          return cartOrderDayNotPermit;
    }


}




export class CartLine {
   constructor(public dish: SimpleOderModel,
        public quantity: number) {}

           get lineTotal() {

        return this.quantity;
    }
}
